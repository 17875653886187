import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import {
  allowOnlyAplhabetNumber,
  allowOnlyNumbers,
  allowOnlyUniqueAlphabetsNoSpace,
  capitalizeWordsAfterSpace,
} from "../../../../../../../InputUtils/onInput";
import AlertMessage from "../../../../../../../components/Alert/AlertMessage";
import TextArea from "../../../../../../../components/BasicFormComponent/TextArea";
import DateInput from "../../../../../../../components/InputComponents/DateInput";
import { SubmitButton } from "../../../../../../../components/InputComponents/InputComponents.style";
import Select from "../../../../../../../components/InputComponents/Select";
import TextInput from "../../../../../../../components/InputComponents/TextInput";
import {
  newPaymentOptions,
  payeeOptions,
  payementType,
  settlementOptions,
} from "../../../../../../../constants/options";
import { usePincodeValidations } from "../../../../../../../custom-hooks/usePincodeValidations";
import { useSettleClaim } from "../../../../../../../services/claims.service";
import { useGetStateCityFromPincode } from "../../../../../../../services/pincode.service";
import { ButtonWrapper } from "../../../../../../masters/components/MasterStyle";
import {
  ClaimHeading,
  MainPaperContainer,
} from "../../../../../styles/claim.styles";
import { claimSettlementSchema } from "./validations/claimSettlement.validations";

function ClaimSettlementForm() {
  const { claim_detail_id } = useParams("claim_detail_id");

  const { settleClaim, isSuccess, alertMaster, resetMaster, isError } =
    useSettleClaim();

  const methods = useForm({
    resolver: yupResolver(claimSettlementSchema),
    defaultValues: {
      claim_settlement_remark: "",
    },
  });

  const onSubmit = (data) => {
    data.claim_detail_id = claim_detail_id;
    settleClaim(data);
  };

  const { watch, setValue } = methods;

  const workshop_pincode = watch("workshop_pincode");
  const workshop_state = watch("workshop_state");
  const workshop_city = watch("workshop_city");

  const {
    getCityState,
    state_list,
    city_list,
    resetStateCity,
    isStateCitySuccess,
  } = useGetStateCityFromPincode(workshop_pincode);

  useEffect(() => {
    if (workshop_pincode?.length === 6) {
      setValue("workshop_state", "");
      setValue("workshop_city", "");
      getCityState(workshop_pincode);
    } else {
      if (workshop_state || workshop_city) {
        setValue("workshop_state", "");
        setValue("workshop_city", "");
        resetStateCity();
      }
    }
  }, [workshop_pincode]);

  usePincodeValidations({
    pincode_field_name: "workshop_pincode",
    workshop_pincode,
    state_list,
    city_list,
    formMethods: methods,
    isSuccess: isStateCitySuccess,
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <MainPaperContainer>
          <ClaimHeading>Claim Settlement Details</ClaimHeading>
          <AlertMessage
            alert={alertMaster}
            reset={resetMaster}
            isError={isError}
            isSuccess={isSuccess}
          />
          <Grid container spacing={2} mt={0} px={2}>
            <Grid item lg={4} xxs={6}>
              <Select
                label="Payment Mode"
                name="payment_mode"
                options={newPaymentOptions}
                required
              />
            </Grid>
            <Grid item lg={4} xxs={6}>
              <DateInput
                label="Payment Date"
                name="payment_date"
                required
                maxDate={moment()}
              />
            </Grid>
            <Grid item lg={4} xxs={6}>
              <Select
                label="Payee Type"
                name="payee_type"
                options={payeeOptions}
                required
              />
            </Grid>
            <Grid item lg={4} xxs={6}>
              <TextInput
                label="Payee Name"
                name="payee_name"
                onInput={(e) => {
                  allowOnlyUniqueAlphabetsNoSpace(e);
                  capitalizeWordsAfterSpace(e);
                }}
                required
              />
            </Grid>
            <Grid item lg={4} xxs={6}>
              <TextInput
                label="Workshop Code"
                name="workshop_code"
                onInput={allowOnlyAplhabetNumber}
                required
              />
            </Grid>
            <Grid item lg={4} xxs={6}>
              <TextInput
                label="Workshop Pincode"
                name="workshop_pincode"
                onInput={allowOnlyNumbers}
                inputProps={{ maxLength: 6 }}
                required
              />
            </Grid>
            <Grid item lg={4} xxs={6}>
              <Select
                label="Workshop State"
                name="workshop_state"
                options={state_list}
                required
              />
            </Grid>
            <Grid item lg={4} xxs={6}>
              <Select
                label="Workshop City"
                name="workshop_city"
                options={city_list}
                required
              />
            </Grid>
            <Grid item lg={4} xxs={6}>
              <Select
                label="Settlement Mode"
                name="settlement_mode"
                options={settlementOptions}
                required
              />
            </Grid>
            <Grid item lg={4} xxs={6}>
              <TextInput
                label="Settled Amount"
                name="claim_settled_amount"
                onInput={allowOnlyNumbers}
                required
              />
            </Grid>
            <Grid item lg={4} xxs={6}>
              <Select
                label="Payment Type"
                name="payment_type"
                options={payementType}
                required
              />
            </Grid>
            <Grid item lg={4} xxs={6}>
              <TextInput
                label="Payment Transaction ID"
                name="payment_transaction_id"
                onInput={allowOnlyAplhabetNumber}
                required
              />
            </Grid>
            <Grid item lg={4} xxs={6}>
              <TextInput
                label="Nominee Name"
                name="nominee_name"
                onInput={(e) => {
                  allowOnlyUniqueAlphabetsNoSpace(e);
                  capitalizeWordsAfterSpace(e);
                }}
                required
              />
            </Grid>
            <Grid item xxs={8}>
              <TextArea
                label="Remark"
                name="claim_settlement_remark"
                standard
                maxLength={300}
              />
            </Grid>
          </Grid>
          <ButtonWrapper>
            <SubmitButton variant="contained" type="submit">
              Submit
            </SubmitButton>
          </ButtonWrapper>
        </MainPaperContainer>
      </form>
    </FormProvider>
  );
}

export default ClaimSettlementForm;
