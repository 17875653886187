import { styled } from "@mui/material/styles";
import { Typography, Box, Button, Drawer } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { BOTTOM_NAVIGATION_HEIGHT } from "../constants/navbar.constant";

export const NavBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "showHover",
})(() => ({
  flexGrow: 1,
  top: 0,
  width: "100%",
  zIndex: "3",
  position: "sticky",
}));

export const NavAppBar = styled(MuiAppBar)(({ theme }) => ({
  width: "100%",
  background: "white",
  color: "blue",
  padding: "0px",
  minHeight: "64px",
  justifyContent: "center",
  position: "static",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

export const NavToolbar = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const NavToolBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "10px 10px",
}));

export const NavTypography = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  color: "#000",
  fontSize: "24px",
  fontWeight: "semibold",
  [theme.breakpoints.down("xs")]: {
    display: "none",
  },
}));

export const BackNavigationBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "3px 3px",
  marginRight: "10px",
  borderRadius: "5px",
  border: "solid 1px #e6f2f2",
  marginLeft: "10px",
}));

export const LocationName = styled(Typography)(({ primary }) => ({
  color: "#4f6781",
  fontSize: primary ? "20px" : "16px",
  position: "relative",
  marginLeft: "10px",
  fontWeight: primary ? "bold" : "normal",
  textTransform: "capitalize",
  "&:not(:last-child)": {
    "&:after": {
      content: "'/'",
      position: "absolute",
      fontSize: primary ? "24px" : "20px",
      top: "-4px",
    },
  },
}));

export const LoginButton = styled(Button)(() => ({
  color: "primary",
  textTransform: "none",
  variant: "contained",
}));

export const DrawerWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
}));

export const DrawerBox = styled(Drawer)(({ theme }) => ({
  width: "130px",
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: "130px",
    boxSizing: "border-box",
  },
  "::-webkit-scrollbar": {
    display: "none",
  },
  minHeight: "calc(100vh - 70px)",
  boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
  zIndex: 5,
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 2),
  height: "64px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "center",
}));

export const MobileMenuIcon = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "3px 3px",
  margin: 0,
  borderRadius: "5px",
  border: "solid 1px #e6f2f2",
  color: "#4f6781",
  cursor: "pointer",
  [theme.breakpoints.up("560")]: {
    display: "none",
  },
}));

export const NavbarLogo = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  height: "25px",
  objectFit: "contain",
  cursor: "pointer",
  "& img": {
    height: "100%",
    minWidth: "25px",
  },
  [theme.breakpoints.up("xs")]: {},
}));

export const SegmentImageContainer = styled(Box)({
  maxHeight: "50px",
});
export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingBottom: BOTTOM_NAVIGATION_HEIGHT,
  [theme.breakpoints.up("md")]: {
    width: `100%`,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
