import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputContainer,
  ResetButton,
} from "../../../servicing/cancellation/CancellationSearch/CancellationSearchStyle";
import {
  ButtonWrapper,
  GridChild,
  Heading,
  Papers,
  StyledGrid,
} from "../../components/MasterStyle";
import Buttons from "../../../../components/BasicFormComponent/Buttons";
import { allowOnlyNumbers } from "../../../../InputUtils/onInput";
import Textfield from "../../../../components/BasicFormComponent/TextFieldInput";
import { useGetAddMobileNoApproval } from "../../../../services/addmobilenoapproval.service";
import AlertMessage from "../../../../components/Alert/AlertMessage";
import { mobileNumberRequired } from "../../../../utils/Validations/mobileNumberValidations";
import Navbar from "../../../../components/Navbar";

const schema = yup.object().shape({
  mobile_number: mobileNumberRequired(),
  count: yup.string().nullable().required("Count in required"),
});

const AddMobileNoApproval = () => {
  const {
    addMobileNoApproval,
    alertMaster,
    resetMaster,
    isError,
    isSuccess,
    isLoading,
  } = useGetAddMobileNoApproval();
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    addMobileNoApproval(data);
    methods.reset();
  };

  const handleReset = () => {
    methods.reset();
  };

  return (
    <Navbar>
      <Papers elevation={3} style={{ width: "50%" }}>
        <div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Heading variant="h5" align="left">
                Mobile Number Request
              </Heading>
              <AlertMessage
                alert={alertMaster}
                reset={resetMaster}
                isError={isError}
                isSuccess={isSuccess}
              />
              <InputContainer>
                <StyledGrid mt={1}>
                  <GridChild sm={6} md={6} xs={12} xxs={12}>
                    <Textfield
                      label={"Mobile No."}
                      name={"mobile_number"}
                      onInput={allowOnlyNumbers}
                      inputProps={{ maxLength: 10 }}
                      required={true}
                      standard
                    />
                  </GridChild>

                  <GridChild sm={6} md={6} xs={12} xxs={12}>
                    <Textfield
                      label={"Count"}
                      name={"count"}
                      onInput={allowOnlyNumbers}
                      inputProps={{ maxLength: 3 }}
                      required={true}
                      standard
                    />
                  </GridChild>
                </StyledGrid>
              </InputContainer>
              <GridChild sm={4} md={4} xs={12} xxs={12}>
                <ButtonWrapper gap={2} style={{ marginRight: "0px" }}>
                  <Buttons
                    label={"Add"}
                    type="submit"
                    disabled={isLoading || isSuccess}
                    standard
                  />

                  <ResetButton onClick={handleReset}>
                    <ReplayIcon />
                    Reset
                  </ResetButton>
                </ButtonWrapper>
              </GridChild>
            </form>
          </FormProvider>
          <Box
            sx={{
              px: "20px",
              marginTop: "20px",
              borderRadius: "14px",
              boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
              width: "100%",
              height: "100%",
            }}
          ></Box>
        </div>
      </Papers>
    </Navbar>
  );
};

export default AddMobileNoApproval;
