import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Button, FormHelperText, FormLabel, Link } from "@mui/material";
import { useFormContext } from "react-hook-form";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import PdfIcon from "@mui/icons-material/PictureAsPdf";
import ExcelIcon from "@mui/icons-material/InsertDriveFile";
const fileTypeBasedOnExtension = {
  pdf: "application/pdf",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  jpg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
};
function Docupload({ label, name, required = false, ...otherProps }) {
  const {
    register,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext();

  const file = watch(name) ? watch(name) : "";
  const file_name = typeof file === "string" && file?.split("/").at(-1);
  const file_type = typeof file === "string" && file_name?.split(".").at(-1);

  const baseUrl = file && file instanceof File && URL.createObjectURL(file);

  const typeOfFile = file.type?.split("/")?.at(-1);

  const isPdf = typeOfFile === "pdf";
  const isExcel =
    typeOfFile === "xlsx" ||
    typeOfFile === "xls" ||
    typeOfFile === "vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  useEffect(() => {
    async function createFile() {
      let file_sample = await fetch(`${file}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      let data = await file_sample.blob();
      let metadata = {
        type: fileTypeBasedOnExtension[file_type],
      };
      let file_blob = new File([data], `${file_name}`, metadata);
      file_name && setValue(name, file_blob);
    }
    if (file && !(file instanceof File)) createFile();
  }, [file]);

  return (
    <div>
      <DocuploadWrapper>
        <FormLabel
          sx={{
            fontSize: "15px",
            color: "#6e8185",
            mb: "10px",
          }}
        >
          {label}{" "}
          <span style={{ fontSize: "15px", color: "red" }}>
            {required && label ? "*" : ""}
          </span>
        </FormLabel>
        <InputWrapper>
          <UploadButton
            component="label"
            htmlFor={name}
            id={`${name || "customFile"}_button`}
          >
            <CloudUploadOutlinedIcon fontSize="small"></CloudUploadOutlinedIcon>{" "}
            Choose File
          </UploadButton>
          {baseUrl ? (
            <FileName key={baseUrl} title={watch(name)?.name}>
              {isPdf ? (
                <>
                  <FileIcon>
                    <PdfIcon fontSize="small" />
                  </FileIcon>
                </>
              ) : isExcel ? (
                <>
                  <FileIcon>
                    <ExcelIcon fontSize="small" />
                  </FileIcon>
                </>
              ) : (
                <>
                  <img
                    src={baseUrl}
                    alt={watch(name)?.name}
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "contain",
                      borderRadius: "50%",
                      padding: "2px",
                    }}
                  />
                </>
              )}

              {truncateFilename(watch(name)?.name, 10)}

              <OpenInNewLink
                key={baseUrl}
                component={Link}
                target="_blank"
                href={baseUrl}
                rel="noopener noreferrer"
                title={watch(name)?.name}
              >
                <OpenInNewIcon fontSize="small" />
              </OpenInNewLink>
            </FileName>
          ) : (
            <FileName>No Chosen File</FileName>
          )}
        </InputWrapper>
        <input
          type="file"
          className="form-control"
          id={name || "customFile"}
          hidden
          {...register(name)}
          onChange={(e) => {
            setValue(name, e.target.files[0]);
            clearErrors(name);
          }}
          {...otherProps}
        />

        <FormHelperText
          error={Boolean(errors[name])}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 10px",
            color: "red",
          }}
        >
          <span>{errors[name]?.message}</span>
        </FormHelperText>
      </DocuploadWrapper>
    </div>
  );
}

export default Docupload;

// Truncate filename if it exceeds the specified length
function truncateFilename(filename, maxLength) {
  if (filename.length > maxLength) {
    return filename.slice(0, maxLength - 3) + "...";
  }
  return filename;
}

const DocuploadWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  width: "100%",
  justifyContent: "center",
  "& > :not(style)": {
    width: "auto",
    height: "auto",
  },
});

const InputWrapper = styled(Box)({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  alignItems: "space-between",
  justifyContent: "space-between",
});
const UploadButton = styled(Button)({
  display: "flex",
  fontSize: "15px",
  color: "#6e8185",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  padding: "10px 18px",
  textTransform: "capitalize",
  backgroundColor: "#e6f2f2",
  borderRadius: "7px",
  gap: "6px",
});
const FileName = styled(Box)({
  display: "flex",
  fontSize: "15px",
  color: "#6e8185",
  alignItems: "center",
  justifyContent: "center",
  padding: "10px 18px",
  flex: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const OpenInNewLink = styled(Box)({
  marginLeft: "8px",
  color: "#6e8185",
  cursor: "pointer",
});

const FileIcon = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: "#e6f2f2",
  marginRight: "8px",
});
