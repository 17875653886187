import { combineReducers } from "@reduxjs/toolkit";
import addonConfigSlice from "../modules/addon_config.slice";
import inputSlice from "../modules/input.slice";
import dialogReducer from "../modules/dialogSlice";
import proposalSlice from "../modules/proposal.slice";
import quoteSlice from "../modules/quote.slice";
import reportConfigSlice from "../modules/report_config.slice";
import alertSlice from "../modules/alert.slice";
import endorsementSlice from "../modules/endorsment.slice";
import paymentSlice from "../modules/payment.slice";
import dashboardSlice from "../modules/dashboard.slice";

export default combineReducers({
  addon_config: addonConfigSlice,
  input: inputSlice,
  dialog: dialogReducer,
  proposal: proposalSlice,
  quote: quoteSlice,
  alert: alertSlice,
  reportConfig: reportConfigSlice,
  endorsement: endorsementSlice,
  payment: paymentSlice,
  dashboard: dashboardSlice,
});
