import { createContext, useEffect } from "react";
import { Navigate, useParams } from "react-router";
// import Error from "../components/Error";
import NotFound from "../components/NotFound";
import { useGetUser } from "../services/AuthenticationServices";

export const AuthContext = createContext();

/* eslint-disable react/display-name */

// Add slugs for master tables where top bar add action button is required for readonly access
const showWithReadAccessSlugs = ["apd", "mmv_request"];

// TODO: master form is same for edit & add. Edit case gets access when forcefull access given for add case. It should allow only add & prevent edit case

const withRouteProtection =
  (Component, isTestMode) =>
  ({ ...props }) => {
    const { master_name } = useParams();
    const showWithReadAccess = showWithReadAccessSlugs.includes(master_name);
    const {
      isAuthorized,
      isLoading,
      accessControlData,
      data,
      isTransactionBlocked,
    } = useGetUser();
    const url = new URL(window.location.href);
    const pathWithQuery = url?.pathname + url?.search;
    useEffect(() => {
      if (!isLoading && !isAuthorized)
        localStorage.setItem("pathname", pathWithQuery);
    }, [pathWithQuery, isLoading, isAuthorized]);
    if (!isLoading && isAuthorized) {
      if (accessControlData.pathAccess || isTestMode || showWithReadAccess) {
        return (
          <>
            <AuthContext.Provider value={accessControlData}>
              <Component showWithReadAccess={showWithReadAccess} {...props} />
            </AuthContext.Provider>
          </>
        );
      } else {
        return (
          <>
            <NotFound isTransactionBlocked={isTransactionBlocked} />
          </>
        );
      }
    }

    if (!isLoading && !isAuthorized) {
      return (
        <>
          <Navigate to="/login" />
        </>
      );
    }

    return <></>;
  };

export default withRouteProtection;
