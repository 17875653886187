import { useEffect } from "react";
import { useParams } from "react-router";
import {
  useGetPolicyDetails,
  useGetPolicyDetailsRegistration,
} from "../../../services/claims.service";

export const useDefaultValues = ({
  formMethods,
  claim_details_from_claim_id,
  showEditableFields,
}) => {
  const { setValue, reset } = formMethods;
  const { claim_detail_id, booking_id } = useParams();

  const { policy_details_list: claimDetailsFromClaimId } =
    useGetPolicyDetailsRegistration(claim_detail_id);

  const { policy_details_list: claimDetailsFromBookingId } =
    useGetPolicyDetails(booking_id);

  useEffect(() => {
    const claim_details_from_claim_id = claimDetailsFromClaimId?.[0];
    const claim_details_from_booking_id = claimDetailsFromBookingId?.[0];

    const claimStatus = claim_detail_id
      ? claim_details_from_claim_id?.claim_status
      : claim_details_from_booking_id?.claim_status;

    // do not change reset method sequence
    reset({
      idv:
        claim_details_from_claim_id?.idv ||
        claim_details_from_booking_id?.idv ||
        "",
      vehicle_premium:
        claim_details_from_claim_id?.vehicle_premium ||
        claim_details_from_booking_id?.vehicle_premium ||
        "",
      voluntary_deductible:
        claim_details_from_claim_id?.voluntary_access_foramount ||
        claim_details_from_booking_id?.voluntary_access_foramount ||
        "",
    });

    if (claimStatus?.toLowerCase() !== "nil") {
      reset({
        // accident details
        nature_of_accident:
          claim_details_from_claim_id?.nature_of_accident || "",
        is_tp_loss: claim_details_from_claim_id?.is_tp_loss || "",
        pincode_of_accident:
          claim_details_from_claim_id?.pincode_of_accident || "",
        accident_state: claim_details_from_claim_id?.accident_state || "",
        accident_city: claim_details_from_claim_id?.accident_city || "",
        place_of_accident: claim_details_from_claim_id?.place_of_accident || "",
        date_of_accident: claim_details_from_claim_id?.date_of_accident || "",
        is_vehicle_parked: claim_details_from_claim_id?.is_vehicle_parked || "",
        catastrophic_code: claim_details_from_claim_id?.catastrophic_code || "",
        part_of_vehicle: claim_details_from_claim_id?.part_of_vehicle || [],
        description_of_accident:
          claim_details_from_claim_id?.description_of_accident || "",

        // contact details
        claim_intimated_by:
          claim_details_from_claim_id?.claim_intimated_by?.toLowerCase() || "",
        intimator_name: claim_details_from_claim_id?.intimator_name || "",
        intimator_mobile_no:
          claim_details_from_claim_id?.intimator_mobile_no || "",
        customer_current_mobile_no:
          claim_details_from_claim_id?.customer_current_mobile_no || "",
        customer_current_email_id:
          claim_details_from_claim_id?.customer_current_email_id || "",
        driver_name: claim_details_from_claim_id?.driver_name || "",

        // Driver details
        driver_age: claim_details_from_claim_id?.driver_age || "",
        driver_license_no: claim_details_from_claim_id?.driver_license_no || "",
        license_type: claim_details_from_claim_id?.license_type || "",
        valid_upto: claim_details_from_claim_id?.valid_upto || "",
        place_of_issue_state_id:
          claim_details_from_claim_id?.place_of_issue_state_id || "",
        place_of_issue_city_id:
          claim_details_from_claim_id?.place_of_issue_city_id || "",

        // estimation details
        // note: some fields are not available in details from claim id
        idv:
          claim_details_from_claim_id?.idv ||
          claim_details_from_booking_id?.idv ||
          "",
        vehicle_premium:
          claim_details_from_claim_id?.vehicle_premium ||
          claim_details_from_booking_id?.vehicle_premium ||
          "",
        voluntary_deductible:
          claim_details_from_claim_id?.voluntary_access_foramount ||
          claim_details_from_booking_id?.voluntary_access_foramount ||
          "",
        loss_type: claim_details_from_claim_id?.loss_type || "",
        labour_cost: claim_details_from_claim_id?.labour_cost || "",
        parts_cost: claim_details_from_claim_id?.parts_cost || "",
        metal_cost: claim_details_from_claim_id?.metal_cost || "",
        paint_cost: claim_details_from_claim_id?.paint_cost || "",
        total_other_cost: claim_details_from_claim_id?.total_other_cost || "",
        total_estimation: claim_details_from_claim_id?.total_estimation || "",

        //  surveyor appointment details
        claim_reg_no: claim_details_from_claim_id?.claim_reg_no || "",
        total_estimation_cost:
          claim_details_from_claim_id?.total_estimation_cost || "",
        appointment_dealer:
          claim_details_from_claim_id?.appointment_dealer || "",
        surveyor_id: claim_details_from_claim_id?.surveyor_id || "",
        surveyor_license_no:
          claim_details_from_claim_id?.surveyor_license_no || "",
        license_expiry_date:
          claim_details_from_claim_id?.license_expiry_date || "",
        srv_contact_no: claim_details_from_claim_id?.srv_contact_no || "",
        srv_email_id: claim_details_from_claim_id?.srv_email_id || "",

        //  surveyor assessment details
        assessed_idv: claim_details_from_claim_id?.assessed_idv || "",
        assessed_vehicle_premium:
          claim_details_from_claim_id?.assessed_vehicle_premium || "",
        assessed_voluntary_deductible:
          claim_details_from_claim_id?.assessed_voluntary_deductible || "",
        assessed_loss_type:
          claim_details_from_claim_id?.assessed_loss_type || "",
        assessed_labour_cost:
          claim_details_from_claim_id?.assessed_labour_cost || "",
        assessed_parts_cost:
          claim_details_from_claim_id?.assessed_parts_cost || "",
        assessed_metal_cost:
          claim_details_from_claim_id?.assessed_metal_cost || "",
        assessed_paint_cost:
          claim_details_from_claim_id?.assessed_paint_cost || "",
        assessed_total_other_cost:
          claim_details_from_claim_id?.assessed_total_other_cost || "",
        assessed_total_estimation:
          claim_details_from_claim_id?.assessed_total_estimation || "",
        assessed_document: claim_details_from_claim_id?.assessed_document,

        // proforma invoice details
        proforma_invoice_no:
          claim_details_from_claim_id?.proforma_invoice_no || "",
        proforma_invoice_date:
          claim_details_from_claim_id?.proforma_invoice_date || "",
        proforma_invoice_amount:
          claim_details_from_claim_id?.proforma_invoice_amount || "",
        proforma_invoice_document:
          claim_details_from_claim_id?.proforma_invoice_document || "",
        proforma_invoice_remark:
          claim_details_from_claim_id?.proforma_invoice_remark || "",

        // claim hold status
        claim_status: claim_details_from_claim_id?.claim_status || "",
        hold_remark: claim_details_from_claim_id?.hold_remark || "",

        // claim invoice details
        claim_invoice_no:
          claim_details_from_claim_id?.proforma_invoice_no || "",
        claim_invoice_date:
          claim_details_from_claim_id?.claim_invoice_date || "",
        claim_invoice_amount:
          claim_details_from_claim_id?.claim_invoice_amount || "",
        claim_invoice_document:
          claim_details_from_claim_id?.claim_invoice_document || "",
        claim_invoice_remark:
          claim_details_from_claim_id?.claim_invoice_remark || "",

        // delivery order details
        delivery_order_no: claim_details_from_claim_id?.delivery_order_no || "",
        approved_amount: claim_details_from_claim_id?.approved_amount || "",
        delivery_order_document:
          claim_details_from_claim_id?.delivery_order_document || "",
        delivery_order_remark:
          claim_details_from_claim_id?.delivery_order_remark || "",

        // claim settlement details
        payment_mode: claim_details_from_claim_id?.payment_mode || "",
        payment_date: claim_details_from_claim_id?.payment_date || "",
        payee_type: claim_details_from_claim_id?.payee_type || "",
        payee_name: claim_details_from_claim_id?.payee_name || "",
        workshop_code: claim_details_from_claim_id?.workshop_code || "",
        workshop_pincode: claim_details_from_claim_id?.workshop_pincode || "",
        workshop_state: claim_details_from_claim_id?.workshop_state || "",
        workshop_city: claim_details_from_claim_id?.workshop_city || "",
        settlement_mode: claim_details_from_claim_id?.settlement_mode || "",
        claim_settled_amount:
          claim_details_from_claim_id?.claim_settled_amount || "",
        payment_type: claim_details_from_claim_id?.payment_type || "",
        payment_transaction_id:
          claim_details_from_claim_id?.payment_transaction_id || "",
        nominee_name: claim_details_from_claim_id?.nominee_name || "",
        claim_settlement_remark:
          claim_details_from_claim_id?.claim_settlement_remark || "",

        // documents
        registration_certificate:
          claim_details_from_claim_id?.registration_certificate || "",
        driving_license:
          claim_details_from_claim_id?.driver_license_certificate || "",
        estimated_certificate:
          claim_details_from_claim_id?.estimated_certificate || "",
        fir_certificate: claim_details_from_claim_id?.fir_certificate || "",
        other_certificate: claim_details_from_claim_id?.other_certificate || "",
      });

      // intentionally setting value
      setValue(
        "part_of_vehicle",
        claim_details_from_claim_id?.part_of_vehicle || []
      );
    }
  }, [
    JSON.stringify(claim_details_from_claim_id),
    JSON.stringify(claimDetailsFromBookingId),
    JSON.stringify(claimDetailsFromClaimId),
    showEditableFields,
  ]);
};
