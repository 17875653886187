import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  IcDealerMapTableCell,
  IcDealerMapTableHeadCell,
  IcDealerMapTableMainContainer,
  IcDealerMapTableNoRecordsContainer,
} from "../ICConfigurator/forms/ICDealerMapping/IcDealerMappingStyled";
import { ButtonWrapper } from "../../masters/components/MasterStyle";
import Buttons from "../../../components/BasicFormComponent/Buttons";
import Select from "../../../components/InputComponents/Select";

function LSODealerMappingTable({
  row_data,
  column_data,
  sales_list,
  workshop_list,
}) {
  return (
    <IcDealerMapTableMainContainer>
      {row_data?.length > 0 ? (
        <>
          <TableContainer style={{ maxHeight: "400px" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {column_data?.map((item) => {
                    return (
                      <IcDealerMapTableHeadCell key={item?.header}>
                        {item?.header}
                      </IcDealerMapTableHeadCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {row_data?.map((item) => {
                  return (
                    <TableRow key={item?.dealer_id}>
                      <IcDealerMapTableCell>
                        {item?.groups_name}
                      </IcDealerMapTableCell>
                      <IcDealerMapTableCell>
                        {item?.dealer_name}
                      </IcDealerMapTableCell>
                      <IcDealerMapTableCell>
                        {item?.dealer_type}
                      </IcDealerMapTableCell>
                      <IcDealerMapTableCell>
                        {item?.dealer_code}
                      </IcDealerMapTableCell>
                      <IcDealerMapTableCell>
                        {item?.state_name}
                      </IcDealerMapTableCell>
                      <IcDealerMapTableCell>
                        {item?.city_name}
                      </IcDealerMapTableCell>
                      <IcDealerMapTableCell
                        style={{
                          minWidth: "200px",
                        }}
                      >
                        <Select
                          name={item?.dealer_id}
                          saveDisplayName={false}
                          bordered
                          options={
                            item?.dealer_type === "workshop"
                              ? workshop_list
                              : sales_list
                          }
                        />
                      </IcDealerMapTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <ButtonWrapper
            style={{
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Buttons label={"submit"} type="submit" standard />
          </ButtonWrapper>
        </>
      ) : (
        <IcDealerMapTableNoRecordsContainer>
          No Records Found
        </IcDealerMapTableNoRecordsContainer>
      )}
    </IcDealerMapTableMainContainer>
  );
}

export default LSODealerMappingTable;
