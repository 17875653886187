import { Paper, styled } from "@mui/material";
import { Heading } from "../masters/components/MasterStyle";

export const MainContainer = styled(Paper)({
  margin: "15px",
  marginBottom: 0,
  padding: "15px",
  borderRadius: "14px",
  width: "40%",
  boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
});

export const CardHeading = styled(Heading)({
  fontSize: "15px",
  fontWeight: "bold",
});
