import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import React from "react";
import { useGetMenuService } from "../../services/dashboard.service";
import { useNavigate } from "react-router";
import {
  AccordionChild,
  AccordionChildWrapper,
  MenuAccordianWrapper,
} from "./MobileMenuStyle";

const MobileMenu = ({ toggleMenu, showMenu }) => {
  const { menuItems } = useGetMenuService();
  const navigate = useNavigate();

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: "rgba(255, 255, 255)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(-90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0),
    // borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const renderMenuItems = (menuItems, parentItem) => {
    return menuItems?.map((menu_item, index) => {
      return menu_item?.child?.length ? (
        <>
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{menu_item?.menu_name}</Typography>
            </AccordionSummary>

            <AccordionChildWrapper hasParent={!!parentItem}>
              <AccordionDetails>
                <AccordionChild>
                  {renderMenuItems(menu_item?.child, menu_item)}
                </AccordionChild>
              </AccordionDetails>
            </AccordionChildWrapper>
          </Accordion>
        </>
      ) : (
        <AccordionChildWrapper key={index} hasParent={!!parentItem}>
          <AccordionDetails>
            <AccordionChild>
              <Typography
                onClick={() => {
                  navigate(
                    parentItem
                      ? "/" + parentItem?.menu_slug + "/" + menu_item?.menu_slug
                      : "/" + menu_item?.menu_slug
                  );
                  toggleMenu();
                }}
              >
                {menu_item?.menu_name}
              </Typography>
            </AccordionChild>
          </AccordionDetails>
        </AccordionChildWrapper>
      );
    });
  };

  return (
    <>
      <MenuAccordianWrapper className="mobileMenu">
        {renderMenuItems(menuItems)}
      </MenuAccordianWrapper>
    </>
  );
};

export default MobileMenu;
