import * as yup from "yup";

export const icConfigMiscellaneousMappingsSchema = yup.object().shape({
  short_term_period_days: yup
    .string()
    .required("Short term period days is required"),
  status: yup.string().required("Status is required"),
  allow_raise_discount: yup
    .string()
    .required("Allow discount raise is required"),
});
