export function premiumCalculations(
  quoteData,
  additionalPremiumWithIncluded,
  allAddons
) {
  const addonGstArray = [];
  allAddons?.map((ad) => {
    ad.addon_gst && addonGstArray.push(ad.addon_gst);
    ad.addon_cgst && addonGstArray.push(ad.addon_cgst);
    ad.addon_sgst && addonGstArray.push(ad.addon_sgst);
  });
  const totalAddonGst = addonGstArray.reduce((acc, curr) => acc + curr, 0);

  const addonIgstArray = [];
  allAddons?.map((ad) => {
    ad.addon_gst && addonIgstArray.push(ad.addon_gst);
  });
  const totalAddonIgst = addonIgstArray.reduce((acc, curr) => acc + curr, 0);

  const addonSgstArray = [];
  allAddons?.map((ad) => {
    ad.addon_sgst && addonSgstArray.push(ad.addon_sgst);
  });
  const totalAddonSgst = addonSgstArray.reduce((acc, curr) => acc + curr, 0);

  const addonCgstArray = [];
  allAddons?.map((ad) => {
    ad.addon_cgst && addonCgstArray.push(ad.addon_cgst);
  });
  const totalAddonCgst = addonCgstArray.reduce((acc, curr) => acc + curr, 0);

  let nonElectricSum = quoteData?.premium_arr?.accessories
    ?.non_electrical_accessories
    ? quoteData?.premium_arr?.accessories?.non_electrical_accessories
        ?.map((x) => x?.prem)
        ?.reduce((a, b) => a + b, 0)
    : 0;

  let electricSum = quoteData?.premium_arr?.accessories?.electrical_accessories
    ? quoteData?.premium_arr?.accessories?.electrical_accessories
        ?.map((x) => x?.prem)
        ?.reduce((a, b) => a + b, 0)
    : 0;

  const ncbdiscounts = quoteData?.premium_arr?.ncb_discount_amt || 0;
  const antiTheft = quoteData?.premium_arr?.discounts?.anti_theft || 0;
  const voluntoryExcess =
    quoteData?.premium_arr?.discounts?.voluntary_excess || 0;
  const aaMembership = quoteData?.premium_arr?.discounts?.aa_membership || 0;
  const handicapDiscount =
    quoteData?.premium_arr?.discounts?.handicap_discount || 0;
  const confinedToSite =
    quoteData?.premium_arr?.discounts?.confine_to_site_discount || 0;
  const allDiscounts =
    ncbdiscounts +
    antiTheft +
    voluntoryExcess +
    aaMembership +
    handicapDiscount +
    confinedToSite;
  const imtSum = (quoteData?.premium_arr?.selected_imt || [])?.reduce(
    (acc, item) => acc + item?.imt_value,
    0
  );

  const additionalOD =
    (quoteData?.premium_arr?.country_od_prem || 0) +
    (quoteData?.premium_arr?.accessories?.bi_fuel?.bi_fuel_od_prem || 0) +
    electricSum +
    nonElectricSum +
    imtSum;

  const totalODwithDiscount =
    //  isGst
    //   ? quoteData?.premium_arr?.od_premium +
    //     additionalPremiumWithIncluded +
    //     additionalOD -
    //     allDiscounts +
    //     totalAddonGst
    //   :
    quoteData?.premium_arr?.od_premium +
    additionalPremiumWithIncluded +
    additionalOD -
    allDiscounts;

  const totalTP =
    (+quoteData?.premium_arr?.tp_premium || 0) +
    (+quoteData?.premium_arr?.pa_cover?.unnamed_person || 0) +
    (+quoteData?.premium_arr?.pa_cover?.owner_driver || 0) +
    (+quoteData?.premium_arr?.pa_cover?.paid_driver || 0) +
    (+quoteData?.premium_arr?.legal_liability_driver || 0) +
    (+quoteData?.premium_arr?.pa_cover?.legal_liability_driver || 0) +
    (+quoteData?.premium_arr?.pa_cover
      ?.legal_liability_paid_conductor_cleaner || 0) +
    (+quoteData?.premium_arr?.pa_cover?.legal_liability_employee || 0) +
    (+quoteData?.premium_arr?.country_tp_prem || 0) +
    (+quoteData?.premium_arr?.accessories?.bi_fuel?.bi_fuel_tp_prem || 0) -
    (+quoteData?.premium_arr?.pa_cover?.tppd_discount || 0);

  return {
    electricSum,
    nonElectricSum,
    ncbdiscounts,
    antiTheft,
    voluntoryExcess,
    aaMembership,
    handicapDiscount,
    confinedToSite,
    allDiscounts,
    additionalOD,
    totalODwithDiscount,
    totalTP,
    totalAddonGst,
    totalAddonIgst,
    totalAddonSgst,
    totalAddonCgst,
  };
}
