import { yupResolver } from "@hookform/resolvers/yup";
import ReplayIcon from "@mui/icons-material/Replay";
import { Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import AlertMessage from "../../../../../components/Alert/AlertMessage";
import Buttons from "../../../../../components/BasicFormComponent/Buttons";
import Select from "../../../../../components/InputComponents/Select";
import Navbar from "../../../../../components/Navbar";
import {
  dealerTypeOptions,
  solicitationTypeOptions,
} from "../../../../../constants/options";
import withRouteProtection from "../../../../../hoc/withRouteProtection";
import {
  useGetIcFromOem,
  useGetOemFromBrokerId,
} from "../../../../../services/icConfigurator.service";
import {
  useGetDealersFromMultipleFilters,
  useSearchIcDealerMapData,
  useSendIcDealerMapTableData,
} from "../../../../../services/icMapping.service";
import {
  useGetDealerGroupFromOEM,
  useGetMaster,
} from "../../../../../services/master.service";
import { useGetCityFromState } from "../../../../../services/pincode.service";
import {
  MainContainer,
  ResetButton,
} from "../../../../Claim/claimStyledComponents";
import {
  ButtonWrapper,
  Heading,
} from "../../../../masters/components/MasterStyle";
import IcDealerMappingSkeleton from "./Components/IcDealerMappingSkeleton";
import IcDealerMappingTable from "./IcDealerMappingTable";

function IcDealerMappingSearch() {
  const {
    sendIcDealerMapData,
    column_data,
    row_data,
    isLoading,
    isSuccess,
    resetMaster,
  } = useSearchIcDealerMapData();

  const {
    sendIcDealerMapTableData,
    isSuccess: isSuccessForTableData,
    isError: isErrorForTableData,
    alertMaster: alertMasterForTableData,
    resetMaster: resetMasterForTableData,
  } = useSendIcDealerMapTableData();

  const searchFormSchema = yup.object().shape({
    mapping_by: yup.string().required("Mapping type is required"),
    insurance_company: yup.string().when(["mapping_by"], {
      is: (mapping_by) => mapping_by === "ic",
      then: yup.string().required("Insurance company name is required"),
    }),
    broker_id: yup.string().required("Broker is required"),
    oem_id: yup.string().required("OEM is required"),
    dealer_name: yup.string().when(["mapping_by"], {
      is: (mapping_by) => mapping_by === "dealer",
      then: yup.string().required("Dealer name is required"),
    }),
  });

  // methods for search form
  const methodsForSearchForm = useForm({
    resolver: yupResolver(searchFormSchema),
    defaultValues: {
      mapping_by: "",
      insurance_company: "",
      dealer_state: "",
      dealer_city: "",
      solicitation_type: "",
      dealer_name: "",
      dealer_type: "",
      dealer_group: "",
      broker_id: "",
      oem_id: "",
    },
    shouldUnregister: true,
  });

  const ic_id = methodsForSearchForm.watch("insurance_company");
  const mapping_by = methodsForSearchForm.watch("mapping_by");
  const map_by_ic = mapping_by === "ic";
  const map_by_dealer = mapping_by === "dealer";
  const broker_id = methodsForSearchForm.watch("broker_id");
  const oem_id = methodsForSearchForm.watch("oem_id");
  const dealer_state = methodsForSearchForm.watch("dealer_state");
  const dealer_city = methodsForSearchForm.watch("dealer_city");
  const solicitation_type = methodsForSearchForm.watch("solicitation_type");
  const dealer_type = methodsForSearchForm.watch("dealer_type");
  const dealer_group = methodsForSearchForm.watch("dealer_group");
  const dealer_name = methodsForSearchForm.watch("dealer_name");

  const { dealer_items } = useGetDealersFromMultipleFilters({
    ic_id,
    oem_id,
    dealer_state,
    dealer_city,
    solicitation_type,
    dealer_type,
    dealer_group,
    broker_id,
  });

  const dealer_list = useMemo(() => {
    return dealer_items?.map((item) => ({
      code: item?.dealer_id,
      display_name: `${item?.dealer_name} (${item?.dealer_code})`,
    }));
  }, [dealer_items]);

  const defaultValuesForIcDealerMappingColumn = row_data?.reduce(
    (prev, item) => {
      return {
        ...prev,
        [map_by_ic ? item.dealer_id : item?.insurance_id]: item.quote_access,
      };
    },
    {}
  );

  const defaultValuesForNewPaymentModes = row_data?.reduce((prev, item) => {
    return {
      ...prev,
      [map_by_ic ? item.dealer_id : item?.insurance_id]:
        item.new_payment_mode?.reduce((prev, subItem) => {
          return {
            ...prev,
            [subItem.payment_type_id]: subItem.isChecked,
          };
        }, {}),
    };
  }, {});

  const defaultValuesForRenewPaymentModes = row_data?.reduce((prev, item) => {
    return {
      ...prev,
      [map_by_ic ? item.dealer_id : item?.insurance_id]:
        item.renew_payment_mode?.reduce((prev, subItem) => {
          return {
            ...prev,
            [subItem.payment_type_id]: subItem.isChecked,
          };
        }, {}),
    };
  }, {});

  // methods for table
  const methodsForIcDealerMappingTable = useForm({
    defaultValues: {
      quote_access: {
        ...defaultValuesForIcDealerMappingColumn,
      },
      payment: {
        new_payment_mode: { ...defaultValuesForNewPaymentModes },
        renew_payment_mode: { ...defaultValuesForRenewPaymentModes },
      },
    },
  });

  useEffect(() => {
    methodsForIcDealerMappingTable.reset({
      quote_access: {
        ...defaultValuesForIcDealerMappingColumn,
      },
      payment: {
        new_payment_mode: { ...defaultValuesForNewPaymentModes },
        renew_payment_mode: { ...defaultValuesForRenewPaymentModes },
      },
    });
  }, [row_data]);

  const quote_access_select_all = methodsForIcDealerMappingTable.watch(
    "parent_quote_access"
  );

  const payment = methodsForIcDealerMappingTable.watch("payment");

  /*
  **************** NOTE ******************

  following has to be done for any new payment mode:

  1. new & renew payment ids. (Example for online : const newPaymentOnline =  payment?.parent_payment_mode?.new_payment_mode?.online )
  2. useEffects for new & renew payments to handle common checkbox for check/uncheck


  TODO: solution to make this dynamic based on payment type ids
  */

  const newPaymentOnline =
    payment?.parent_payment_mode?.new_payment_mode?.online;
  const newPaymentApd = payment?.parent_payment_mode?.new_payment_mode?.apd;
  const newPaymentCheque =
    payment?.parent_payment_mode?.new_payment_mode?.cheque;
  const newPaymentRazorpay =
    payment?.parent_payment_mode?.new_payment_mode?.razorpay;
  const newPaymentAxis = payment?.parent_payment_mode?.new_payment_mode?.axis;

  const renewPaymentOnline =
    payment?.parent_payment_mode?.renew_payment_mode?.online;
  const renewPaymentApd = payment?.parent_payment_mode?.renew_payment_mode?.apd;
  const renewPaymentCheque =
    payment?.parent_payment_mode?.renew_payment_mode?.cheque;
  const renewPaymentRazorpay =
    payment?.parent_payment_mode?.renew_payment_mode?.razorpay;
  const renewPaymentAxis =
    payment?.parent_payment_mode?.renew_payment_mode?.axis;

  // To change all values of IC dealer mapping checkboxes
  useEffect(() => {
    row_data?.map((item) => {
      return methodsForIcDealerMappingTable.setValue(
        `quote_access.${map_by_ic ? item?.dealer_id : item?.insurance_id}`,
        quote_access_select_all
      );
    });
  }, [quote_access_select_all]);

  // Reusable function to update payment mode values if checked select all
  const updatePaymentMode = (paymentMode, paymentTypeId, paymentValue) => {
    useEffect(() => {
      row_data?.forEach((item) => {
        item[paymentMode]?.forEach((subItem) => {
          if (subItem?.payment_type_id === paymentTypeId) {
            methodsForIcDealerMappingTable.setValue(
              `payment.${paymentMode}.${
                map_by_ic ? item?.dealer_id : item?.insurance_id
              }.${subItem?.payment_type_id}`,
              paymentValue
            );
          }
        });
      });
    }, [paymentValue]);
  };

  const paymentType = {
    apd: {
      id: "1",
      name: "apd",
    },
    online: {
      id: "2",
      name: "online",
    },
    razorpay: {
      id: "3",
      name: "razorpay",
    },
    cheque: {
      id: "4",
      name: "cheque",
    },
    axis: {
      id: "41",
      name: "axis",
    },
  };
  const newPayment = "new_payment_mode";
  const renewPayment = "renew_payment_mode";

  updatePaymentMode(newPayment, paymentType?.apd?.id, newPaymentApd);
  updatePaymentMode(newPayment, paymentType?.online?.id, newPaymentOnline);
  updatePaymentMode(newPayment, paymentType?.razorpay?.id, newPaymentRazorpay);
  updatePaymentMode(newPayment, paymentType?.cheque?.id, newPaymentCheque);
  updatePaymentMode(newPayment, paymentType?.axis?.id, newPaymentAxis);

  updatePaymentMode(renewPayment, paymentType?.apd?.id, renewPaymentApd);
  updatePaymentMode(renewPayment, paymentType?.online?.id, renewPaymentOnline);
  updatePaymentMode(
    renewPayment,
    paymentType?.razorpay?.id,
    renewPaymentRazorpay
  );
  updatePaymentMode(renewPayment, paymentType?.cheque?.id, renewPaymentCheque);
  updatePaymentMode(renewPayment, paymentType?.axis?.id, renewPaymentAxis);

  // data from masters

  const { table_list: dealer_group_items } = useGetDealerGroupFromOEM(oem_id);

  const dealer_group_list = useMemo(() => {
    return dealer_group_items.map((item) => {
      return {
        code: item.dealer_group_id,
        display_name: item.groups_name,
      };
    });
  }, [dealer_group_items]);
  const { table_list: state_items } = useGetMaster({
    master_name: "state",
  });

  const { table_list: city_items } = useGetCityFromState(
    methodsForSearchForm.watch("dealer_state")
  );

  // insurance company list
  const { ic_items_by_oem } = useGetIcFromOem(oem_id);

  const ic_list_by_oem = useMemo(() => {
    return ic_items_by_oem?.map((item) => {
      return {
        code: item.insurance_id,
        display_name: item.insurance_name,
      };
    });
  }, [ic_items_by_oem]);

  // broker list
  const { table_list: broker_items } = useGetMaster({
    master_name: "broker",
  });

  const broker_list = useMemo(() => {
    return broker_items?.map((item) => {
      return {
        code: item?.broker_id,
        display_name: item?.broker_name,
      };
    });
  }, [broker_items]);

  // oem list by broker
  const { oem_items_by_broker } = useGetOemFromBrokerId(broker_id);

  const oem_list = useMemo(() => {
    return oem_items_by_broker?.map((item) => {
      return {
        code: item?.oem_id,
        display_name: item?.oem_name,
      };
    });
  }, [oem_items_by_broker]);

  // state list
  const state_list = useMemo(() => {
    return state_items?.map((item) => {
      return {
        code: item.state_id,
        display_name: item.state_name,
      };
    });
  }, [state_items]);

  // city list
  const city_list = useMemo(() => {
    return city_items?.map((item) => {
      return {
        code: item.city_id,
        display_name: item.city_name,
      };
    });
  }, [city_items]);

  const onSubmitForSearchForm = (data) => {
    sendIcDealerMapData(data);
  };

  const onSubmitForIcDealerMappingTable = (data) => {
    data.ic_id = ic_id;
    data.mapping_by = mapping_by;
    data.dealer_id = dealer_name;
    sendIcDealerMapTableData(data);
  };

  const handleReset = () => {
    methodsForSearchForm.reset({
      mapping_by: "",
      insurance_company: "",
      dealer_state: "",
      dealer_city: "",
      solicitation_type: "",
      dealer_name: "",
      dealer_type: "",
      dealer_group: "",
      broker_id: "",
      oem_id: "",
    });
  };

  useEffect(() => {
    if (isSuccessForTableData) {
      setTimeout(() => {
        handleReset();
        resetMasterForTableData();
      }, 3000);
    }
  }, [isSuccessForTableData]);

  useEffect(() => {
    const fieldsToReset = [
      "insurance_company",
      "dealer_state",
      "dealer_city",
      "solicitation_type",
      "dealer_name",
      "broker_id",
      "oem_id",
      "dealer_type",
    ];
    fieldsToReset.forEach((fieldName) => {
      methodsForSearchForm.setValue(fieldName, "");
    });

    if (row_data?.length) {
      // handleReset();
      const fieldsToReset = [
        "insurance_company",
        "dealer_state",
        "dealer_city",
        "solicitation_type",
        "dealer_name",
        "dealer_type",
        "dealer_group",
        "broker_id",
        "oem_id",
      ];
      fieldsToReset.forEach((fieldName) => {
        methodsForSearchForm.setValue(fieldName, "");
      });
      resetMaster();
    }
  }, [mapping_by]);

  return (
    <Navbar>
      <FormProvider {...methodsForSearchForm}>
        <form
          onSubmit={methodsForSearchForm.handleSubmit(onSubmitForSearchForm)}
        >
          <MainContainer style={{ margin: "30px 30px 0 30px" }}>
            <Heading variant="h5" align="left" mb={2}>
              IC - Dealer Mapping
            </Heading>
            <AlertMessage
              alert={alertMasterForTableData}
              reset={resetMasterForTableData}
              isError={isErrorForTableData}
              isSuccess={isSuccessForTableData}
            />
            <Grid container columnSpacing={4} rowSpacing={2}>
              <Grid item xxs={12} xs={6} md={3} lg={3}>
                <Select
                  label={"Mapping By"}
                  name={"mapping_by"}
                  options={[
                    { code: "ic", display_name: "Insurance Company" },
                    { code: "dealer", display_name: "Dealer" },
                  ]}
                  required
                  saveDisplayName={false}
                />
              </Grid>
              <Grid item xxs={12} xs={6} md={3} lg={3}>
                <Select
                  label={"Broker"}
                  name={"broker_id"}
                  options={broker_list}
                  required
                  saveDisplayName={false}
                />
              </Grid>
              <Grid item xxs={12} xs={6} md={3} lg={3}>
                <Select
                  label={"OEM"}
                  name={"oem_id"}
                  options={oem_list}
                  required
                  saveDisplayName={false}
                />
              </Grid>
              {map_by_ic && (
                <Grid item xxs={12} xs={6} md={3} lg={3}>
                  <Select
                    name={"insurance_company"}
                    label={"Insurance Company"}
                    options={ic_list_by_oem}
                    required
                  />
                </Grid>
              )}
              <Grid item xxs={12} xs={6} md={3} lg={3}>
                <Select
                  name={"dealer_state"}
                  label={"Dealer State"}
                  options={state_list}
                />
              </Grid>
              <Grid item xxs={12} xs={6} md={3} lg={3}>
                <Select
                  name={"dealer_city"}
                  label={"Dealer City"}
                  options={city_list}
                />
              </Grid>
              <Grid item xxs={12} xs={6} md={3} lg={3}>
                <Select
                  name={"solicitation_type"}
                  label={"Solicitation Type"}
                  options={solicitationTypeOptions}
                />
              </Grid>
              <Grid item xxs={12} xs={6} md={3} lg={3}>
                <Select
                  name={"dealer_type"}
                  label={"Dealer Type"}
                  options={dealerTypeOptions}
                />
              </Grid>
              {map_by_ic && (
                <Grid item xxs={12} xs={6} md={3} lg={3}>
                  <Select
                    name={"dealer_group"}
                    label={"Dealer Group"}
                    options={dealer_group_list}
                  />
                </Grid>
              )}
              <Grid item xxs={12} xs={6} md={3} lg={3}>
                <Select
                  name={"dealer_name"}
                  label={"Dealer Name"}
                  options={dealer_list}
                  required={map_by_dealer}
                />
              </Grid>
            </Grid>
            <ButtonWrapper style={{ gap: "15px" }}>
              <Buttons
                id={"ic_dealer_mapping_search_button"}
                label={"Search"}
                type="submit"
                standard
              />
              <ResetButton
                id={"ic_dealer_mapping_reset_button"}
                onClick={handleReset}
              >
                <ReplayIcon />
                Reset
              </ResetButton>
            </ButtonWrapper>
          </MainContainer>
        </form>
      </FormProvider>
      <FormProvider {...methodsForIcDealerMappingTable}>
        <form
          onSubmit={methodsForIcDealerMappingTable.handleSubmit(
            onSubmitForIcDealerMappingTable
          )}
        >
          {isLoading ? (
            <IcDealerMappingSkeleton />
          ) : (
            isSuccess && (
              <IcDealerMappingTable
                column_data={column_data}
                row_data={row_data}
                methodsForIcDealerMappingTable={methodsForIcDealerMappingTable}
                map_by_ic={map_by_ic}
              />
            )
          )}
        </form>
      </FormProvider>
    </Navbar>
  );
}

export default withRouteProtection(IcDealerMappingSearch);
