import moment from "moment";
import * as yup from "yup";

export const accidentDetailsSchema = yup.object().shape({
  nature_of_accident: yup.string().required("Nature of Accident is required"),
  is_tp_loss: yup.string().nullable().required("Third party loss is required"),
  is_vehicle_parked: yup
    .string()
    .nullable()
    .required("Is vehicle parked is required"),
  catastrophic_code: yup.string().nullable(),
  pincode_of_accident: yup
    .string()
    .required("Pincode of accident is required")
    .min(6, "Pincode must be 6 digits"),
  accident_state: yup.string().required("Accident state is required"),
  accident_city: yup.string().required("Accident city is required"),
  place_of_accident: yup
    .string()
    .required("Place of accident is required")
    .matches(/.*[a-zA-Z].*/, "Alphabets are required"),
  part_of_vehicle: yup
    .array()
    .nullable()
    .test("check_empty", "Part of vehicle is required", function (value) {
      return value?.length > 0;
    }),
  description_of_accident: yup
    .string()
    .nullable()
    .required("Brief description of accident is required")
    .min(
      20,
      "A minimum of twenty characters is required for a brief description of the accident"
    )
    .matches(/.*[a-zA-Z].*/, "Alphabets are required")
    .test(
      "description_of_accident",
      "Acceptable maximum character length(300) exceeded",
      (val) => {
        return val?.length <= 300;
      }
    ),
  date_of_accident: yup
    .string()
    .nullable()
    .required("Date of accident is required")
    .test(
      "date_of_accident",
      "Proper date and time format is required",
      (val) => {
        if (moment(val).format("YYYY-MM-DD hh:mm A")?.length === 19) {
          return true;
        }
        return false;
      }
    )
    .test(
      "date_of_accident",
      "Date and time of accident should be before current date and time",
      (val) => {
        const currentDateTime = moment();
        const accidentDateTime = moment(val);
        if (accidentDateTime.isSameOrAfter(currentDateTime)) {
          return false;
        }
        return true;
      }
    )
    .test("is-time-selected", "Format is invalid ", (value) => {
      return value !== "Invalid date";
    })
    .test(
      "date_of_accident",
      "Date and time should be between past 90 days",
      (val) => {
        let threeMonthsAgo = moment()
          .subtract(3, "months")
          .startOf("")
          .format("YYYY-MM-DD hh:mm A");
        let accidentDateTime = moment(val).format("YYYY-MM-DD hh:mm A");
        if (accidentDateTime <= threeMonthsAgo) {
          return false;
        }
        return true;
      }
    ),
});
