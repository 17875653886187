import React from "react";
import { StyledGrid } from "../../../../masters/components/MasterStyle";
import {
  SummaryChild,
  SummaryHeading,
  SummarySubContainer,
  SummarySubHeading,
} from "../../../../summary/summarystyle";
import { ClaimHeading, MainPaperContainer } from "../../../styles/claim.styles";

function ClaimApprovalDetails({ claimDetails }) {
  return (
    <MainPaperContainer>
      <ClaimHeading>Claim Approval Details</ClaimHeading>
      <SummarySubContainer>
        <StyledGrid container columns={12} spacing={0}>
          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Approval Status</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.approval_status}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Remark</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.approval_remark}
            </SummarySubHeading>
          </SummaryChild>
        </StyledGrid>
      </SummarySubContainer>
    </MainPaperContainer>
  );
}

export default ClaimApprovalDetails;
