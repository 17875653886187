import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { Box, Button, Grid, styled } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import {
  allowOnlyAlphabetsSpace,
  allowOnlyNumbers,
  toCapitalize,
} from "../../../InputUtils/onInput";
import Buttons from "../../../components/BasicFormComponent/Buttons";
import DialogBox from "../../../components/DialogBox/DialogBox";
import DateInput from "../../../components/InputComponents/DateInput";
import Select from "../../../components/InputComponents/Select";
import TextInput from "../../../components/InputComponents/TextInput";
import Navbar from "../../../components/Navbar";
import withRouteProtection, {
  AuthContext,
} from "../../../hoc/withRouteProtection";
import { useGetSortedMaster } from "../../../services/master.service";
import { ClaimActionButton } from "../../Claim/claimStyledComponents";
import MasterTable from "../../masters/MasterTable/MasterTable";
import {
  AtleastOnefieldError,
  ButtonWrapper,
  FormContainer,
  Heading,
  Papers,
} from "../../masters/components/MasterStyle";
import RenewalLeadPopup from "./RenewalLeadPopup";
function RenewalLeadSearch() {
  const [customSearch, setCustomSearch] = useState({});
  const [openRenewal, setOpenRenewal] = useState(false);
  const [leadData, setLeadData] = useState();
  const [policyNo, setPolicyNo] = useState();
  const [bookingId, setBookingId] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const methods = useForm({
    defaultValues: {
      dealer_id: "",
      customer_name: "",
      contact_no: "",
      from_date: "",
      to_date: "",
      policy_no: "",
    },
  });
  const allfields = methods.watch();
  useEffect(() => {
    const isAnyFieldSelectedSecond = Object?.values(allfields).some(
      (item) => item !== ""
    );
    if (isAnyFieldSelectedSecond) {
      methods.clearErrors();
    }
  }, [JSON.stringify(allfields)]);

  const onSubmit = (data) => {
    const isAnyFieldSelected = Object?.values(data).some((item) => item !== "");
    if (isAnyFieldSelected) {
      setCustomSearch(data);
    } else {
      methods.setError("atleast_one_field", {
        type: "custom",
        message: "Atleast one field is required",
      });
    }
  };

  const { writable } = useContext(AuthContext);
  const handleReset = () => {
    if (searchParams.get("column_filters")) {
      searchParams.delete("column_filters");
    }
    setCustomSearch({});
    methods.reset({
      dealer_id: "",
      customer_name: "",
      contact_no: "",
      from_date: "",
      to_date: "",
      policy_no: "",
    });
  };

  const { table_list: dealer_items } = useGetSortedMaster({
    master_name: "dealer",
    sortBy: "dealer_name",
  });

  const dealer_list = useMemo(() => {
    return dealer_items.map((item) => {
      return {
        code: item.dealer_id,
        display_name: item.dealer_name,
      };
    });
  }, [dealer_items]);

  const err = methods.formState.errors?.atleast_one_field?.message;

  return (
    <Navbar>
      <Box>
        <FormContainer>
          <Papers elevation={3} sx={{ width: "100%" }}>
            <Heading variant="h5" align="left">
              Renewal Lead
            </Heading>
            {err && <AtleastOnefieldError>{err}</AtleastOnefieldError>}
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={1} columnSpacing={3} mt={1}>
                  <Grid item xxs={12} xs={6} md={3} lg={4}>
                    <Select
                      name={"dealer_id"}
                      label={"Dealer Name"}
                      variant="standard"
                      options={dealer_list}
                    />
                  </Grid>
                  <Grid item xxs={12} xs={6} md={3} lg={4}>
                    <TextInput
                      name={"policy_no"}
                      label={"Policy No."}
                      inputProps={{ maxLength: 10 }}
                      standard
                      onInput={(e) => {
                        allowOnlyNumbers(e);
                      }}
                    />
                  </Grid>
                  <Grid item xxs={12} xs={6} md={3} lg={4}>
                    <TextInput
                      name={"customer_name"}
                      label={"Customer Name"}
                      standard
                      onInput={(e) => {
                        toCapitalize(e);
                        allowOnlyAlphabetsSpace(e);
                      }}
                    />
                  </Grid>

                  <Grid item xxs={12} xs={6} md={4} lg={4}>
                    <TextInput
                      name={"contact_no"}
                      label={"Mobile No."}
                      onInput={allowOnlyNumbers}
                      inputProps={{ maxLength: 10 }}
                    />
                  </Grid>

                  <Grid item xxs={12} xs={6} md={4} lg={4}>
                    <DateInput
                      name={"from_date"}
                      label={"From Date"}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xxs={12} xs={6} md={4} lg={4}>
                    <DateInput
                      name={"to_date"}
                      label={"To Date"}
                      variant="standard"
                    />
                  </Grid>
                </Grid>
                <ButtonWrapper
                  style={{
                    gap: "10px",
                    margin: 0,
                  }}
                >
                  <Buttons type="submit" label="Search" />
                  <ResetButton onClick={(e) => handleReset(e)}>
                    <ReplayRoundedIcon />
                    Reset
                  </ResetButton>
                </ButtonWrapper>
              </form>
            </FormProvider>
          </Papers>
        </FormContainer>
      </Box>
      <Box>
        <MasterTable
          master_name={"lead"}
          writable={writable}
          columnFilters={Object.keys(customSearch).map((item) => ({
            id: item,
            value: customSearch[item],
          }))}
          customRowAction={({ row }) => (
            <ClaimActionButton
              onClick={() => {
                setOpenRenewal(true);
                setLeadData(row?.original?.lead_id);
                setPolicyNo(row?.original?.policy_no);
                setBookingId(row?.original?.booking_id);
              }}
            >
              Add Request
            </ClaimActionButton>
          )}
        />
      </Box>
      <DialogBox
        title="Choose the Customer Type"
        open={openRenewal}
        setOpen={setOpenRenewal}
      >
        <RenewalLeadPopup
          lead_id={leadData}
          policy_no={policyNo}
          booking_id={bookingId}
          setOpen={setOpenRenewal}
        />
      </DialogBox>
    </Navbar>
  );
}

export default withRouteProtection(RenewalLeadSearch);

// const MainContainer = styled(Paper)(({ theme }) => ({
//   padding: "15px",
//   margin: "30px 30px 15px 30px",
//   borderRadius: "14px",
//   boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
//   [theme.breakpoints.down("md")]: {
//     margin: "20px 20px 15px 20px",
//   },
// }));

const ResetButton = styled(Button)({
  opacity: "0.69",
  fontFamily: "Lato",
  fontSize: "12px",
  color: "#6e8185",
  background: "none",
  borderRadius: "10px",
  padding: "10px 15px",
  height: "36px",
});

// const TableContainer = styled(Box)({
//   margin: "0 15px",
// });
