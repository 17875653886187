import * as yup from "yup";
import { REGEX } from "../../../../../../constants/regex";
import { lsolandlineNumberBasic } from "../../../../../../utils/Validations/landLineNumberValidation";

export const claimLsoValidationSchema = yup.object().shape({
  ic_id: yup.string().nullable().required("Insurance Company is required"),
  office_id: yup.string().nullable().required("Office ID is required"),
  lso_code: yup.string().nullable().required("LSO code is required"),
  lso_name: yup.string().nullable().required("LSO name is required"),
  address1: yup
    .string()
    .nullable()
    .required("Address is required")
    .min(5, "Address must contain at least five characters"),
  lso_landline_no: lsolandlineNumberBasic(),
  lso_pincode: yup.string().nullable().required("LSO pincode is required"),
  lso_state_id: yup.string().nullable().required("LSO state is required"),
  lso_city_id: yup.string().nullable().required("LSO city is required"),
  ro_name: yup
    .string()
    .nullable()
    .required("RO Name is required")
    .matches(REGEX.FULL_NAME, "Enter valid RO name"),
  ro_code: yup
    .string()
    .nullable()
    .required("RO code is required")
    .matches(REGEX.ALPHANUMERIC, "Enter valid RO code"),
  regional_manager: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .nullable()
        .test("check_others", "RM name is required", function (value) {
          const isAnyFieldNotEmpty = Object.values(this.parent)?.some(
            (value) => !!value
          );
          if (!isAnyFieldNotEmpty) return true;
          return value;
        })
        .matches(REGEX.FULL_NAME, "Enter valid RM name"),

      contact_no: yup
        .string()
        .nullable()
        .test("check_others", "RM contact no. is required", function (value) {
          const isAnyFieldNotEmpty = Object.values(this.parent)?.some(
            (value) => !!value
          );
          if (!isAnyFieldNotEmpty) return true;
          return value;
        })
        .matches(REGEX.MOBILE, "Enter valid RM contact no."),

      email: yup
        .string()
        .nullable()
        .test("check_others", "RM email ID is required", function (value) {
          const isAnyFieldNotEmpty = Object.values(this.parent)?.some(
            (value) => !!value
          );
          if (!isAnyFieldNotEmpty) return true;
          return value;
        })
        .matches(REGEX.EMAIL, "Enter valid RM email ID"),
    })
  ),
  office_incharge: yup.array().of(
    yup.object().shape({
      designation: yup
        .string()
        .nullable()
        .test(
          "check_others",
          "Office incharge designation is required",
          function (value) {
            const isAnyFieldNotEmpty = Object.values(this.parent)?.some(
              (value) => !!value
            );
            if (!isAnyFieldNotEmpty) return true;
            return value;
          }
        )
        .matches(REGEX.FULL_NAME, "Enter valid office incharge designation"),

      name: yup
        .string()
        .nullable()
        .test(
          "check_others",
          "Office incharge name is required",
          function (value) {
            const isAnyFieldNotEmpty = Object.values(this.parent)?.some(
              (value) => !!value
            );
            if (!isAnyFieldNotEmpty) return true;
            return value;
          }
        )
        .matches(REGEX.FULL_NAME, "Enter valid office incharge name"),

      contact_no: yup
        .string()
        .nullable()
        .test(
          "check_others",
          "Office incharge contact no. is required",
          function (value) {
            const isAnyFieldNotEmpty = Object.values(this.parent)?.some(
              (value) => !!value
            );
            if (!isAnyFieldNotEmpty) return true;
            return value;
          }
        )
        .matches(REGEX.MOBILE, "Enter valid office incharge contact no."),

      email: yup
        .string()
        .nullable()
        .test(
          "check_others",
          "Office incharge email ID is required",
          function (value) {
            const isAnyFieldNotEmpty = Object.values(this.parent)?.some(
              (value) => !!value
            );
            if (!isAnyFieldNotEmpty) return true;
            return value;
          }
        )
        .matches(REGEX.EMAIL, "Enter valid office incharge email ID"),
    })
  ),
  broker_manager: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .nullable()
        .test(
          "check_others",
          "Broker manager name is required",
          function (value) {
            const isAnyFieldNotEmpty = Object.values(this.parent)?.some(
              (value) => !!value
            );
            if (!isAnyFieldNotEmpty) return true;
            return value;
          }
        )
        .matches(REGEX.FULL_NAME, "Enter valid broker manager name"),

      contact_no: yup
        .string()
        .nullable()
        .test(
          "check_others",
          "Broker manager contact no. is required",
          function (value) {
            const isAnyFieldNotEmpty = Object.values(this.parent)?.some(
              (value) => !!value
            );
            if (!isAnyFieldNotEmpty) return true;
            return value;
          }
        )
        .matches(REGEX.MOBILE, "Enter valid broker manager contact no."),

      email: yup
        .string()
        .nullable()
        .test(
          "check_others",
          "Broker manager email ID is required",
          function (value) {
            const isAnyFieldNotEmpty = Object.values(this.parent)?.some(
              (value) => !!value
            );
            if (!isAnyFieldNotEmpty) return true;
            return value;
          }
        )
        .matches(REGEX.EMAIL, "Enter valid broker manager email ID"),
    })
  ),
});
