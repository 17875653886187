import { useRef, useState, useEffect } from "react";
import { useGetMenuService } from "../../../../services/dashboard.service";
import { BackgroundOverlay, BottomNavWrapper } from "./BottomNavigation.style";
import MainMenu from "../MainMenu/MainMenu";
import { useOnClickOutside } from "../../../../custom-hooks/useOutside";

const BottomNavigation = () => {
  const { menuItems, isMenuLoading } = useGetMenuService();
  const menuItemRefs = useRef([]);
  const bottomNavRef = useRef();
  const onMouseEnterMainMenuItem = (index) => {
    menuItemRefs.current[index].classList.add("menu-active");
    index > 0 &&
      menuItemRefs.current[index - 1].classList.add("menu-active-prev-1");
    index > 1 &&
      menuItemRefs.current[index - 2].classList.add("menu-active-prev-2");
    index < menuItems.length - 1 &&
      menuItemRefs.current[index + 1].classList.add("menu-active-next-1");
    index < menuItems.length - 2 &&
      menuItemRefs.current[index + 2].classList.add("menu-active-next-2");
  };
  const onMouseLeaveMainMenuItem = (index) => {
    menuItemRefs.current[index].classList.remove("menu-active");
    index > 0 &&
      menuItemRefs.current[index - 1].classList.remove("menu-active-prev-1");
    index > 1 &&
      menuItemRefs.current[index - 2].classList.remove("menu-active-prev-2");
    index < menuItems.length - 1 &&
      menuItemRefs.current[index + 1].classList.remove("menu-active-next-1");
    index < menuItems.length - 2 &&
      menuItemRefs.current[index + 2].classList.remove("menu-active-next-2");
  };
  const [openedMenuSlug, setOpenedMenuSlug] = useState();
  useOnClickOutside(bottomNavRef, () => {
    setOpenedMenuSlug(null);
  });
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setOpenedMenuSlug(null);
      }
    };
    window.addEventListener("keydown", close);
    return () => {
      window.removeEventListener("keydown", close);
    };
  }, []);
  if (isMenuLoading || !menuItems?.length) return <></>;

  return (
    <>
      {openedMenuSlug && <BackgroundOverlay />}
      <BottomNavWrapper ref={bottomNavRef}>
        <>
          {menuItems.map((item, index) => (
            <>
              <MainMenu
                menuItem={item}
                ref={(ref) => {
                  menuItemRefs.current[index] = ref;
                }}
                onMouseEnter={() => {
                  onMouseEnterMainMenuItem(index);
                }}
                onMouseLeave={() => {
                  onMouseLeaveMainMenuItem(index);
                }}
                openedMenuSlug={openedMenuSlug}
                setOpenedMenuSlug={setOpenedMenuSlug}
              />
            </>
          ))}
        </>
      </BottomNavWrapper>
    </>
  );
};

export default BottomNavigation;
