import styled from "@emotion/styled";
import { Text } from "../../Claim/AppointSurveyor/AppointSurveyorStyled";

export const SurveyorDetailsNameContainer = styled(Text)({
  fontSize: "12px",
});

export const SurveyorDetailsValueContainer = styled(Text)({
  fontSize: "12px",
  fontWeight: "bold",
});
