import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  useCreateMaster,
  useGetAddonFromSegment,
  useGetOemList,
  useGetSegmentFromOem,
  useGetSortedMaster,
} from "../../../../../services/master.service";
import { useEffect, useMemo } from "react";
import MasterFormWrapper from "../../../../masters/components/MasterFormWrapper/MasterFormWrapper";
import { Grid } from "@mui/material";
import Select from "../../../../../components/InputComponents/Select";
import DateInput from "../../../../../components/InputComponents/DateInput";
import TextArea from "../../../../../components/BasicFormComponent/TextArea";
import { addonWordingsValidationSchema } from "../../../../masters/forms/AddonWording/AddonWordingsValidation";
import { statusOptions } from "../../../../../constants/options";
import Radio from "../../../../../components/InputComponents/Radio";
import moment from "moment";
import Navbar from "../../../../../components/Navbar";

const AddonWordings = ({ values }) => {
  const methods = useForm({
    resolver: yupResolver(addonWordingsValidationSchema),
    defaultValues: {
      status: "Y",
      ...values,
    },
  });

  useEffect(() => {
    methods.reset({ ...values });
  }, [values]);

  const {
    createItem,
    isSuccess,
    alertMaster,
    resetMaster,
    isError,
    isLoading,
  } = useCreateMaster("addon_wording");

  useEffect(() => {
    if (isSuccess) {
      methods.reset({
        oem_id: "",
        segment_id: "",
        addon_type: "",
        effective_from: "",
        effective_to: "",
        addon_wording_details: "",
      });
    }
  }, [isSuccess]);

  const { oem_items } = useGetOemList();

  const { table_list: ic_items } = useGetSortedMaster({
    master_name: "insurance",
    sortBy: "insurance_name",
  });

  const ic_list = useMemo(() => {
    return ic_items.map((item) => {
      return {
        code: item.insurance_id,
        display_name: item.insurance_name,
      };
    });
  }, [ic_items]);

  const oem_list = useMemo(() => {
    return oem_items.map((item) => {
      return {
        code: item.oem_id,
        display_name: item.oem_name,
      };
    });
  }, [oem_items]);

  const { table_list: segment_items } = useGetSegmentFromOem(
    methods.watch("oem_id")
  );

  const segment_list = useMemo(() => {
    return segment_items.map((item) => {
      return {
        code: item.segment_id,
        display_name: item.segment_name,
      };
    });
  }, [segment_items]);

  const { table_list: addon_items } = useGetAddonFromSegment([
    methods.watch("segment_id"),
  ]);

  const addon_list = useMemo(() => {
    return addon_items.map((item) => {
      return {
        code: item.addon_id,
        display_name: item.addon_name,
      };
    });
  }, [addon_items]);

  const onSubmit = (data) => {
    createItem(data);
  };

  const oemId = methods.watch("oem_id");
  const segmentId = methods.watch("segment_id");

  useEffect(() => {
    if (!oemId) {
      methods.setValue("segment_id", "");
    }
  }, [oemId]);

  useEffect(() => {
    if (oemId && segmentId) {
      methods.clearErrors(["segment_id"]);
    }
  }, [segmentId]);

  return (
    <Navbar>
      <FormProvider {...methods}>
        <MasterFormWrapper
          title={"Addon Wording"}
          description={"Add Addon Wordings Details"}
          onSubmit={onSubmit}
          alert={alertMaster}
          reset={resetMaster}
          isError={isError}
          isSuccess={isSuccess}
          isLoading={isLoading}
        >
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Select
              label="Insurance Company"
              name="ic_id"
              required={true}
              options={ic_list}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Select
              label="OEM"
              name="oem_id"
              required={true}
              options={oem_list}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Select
              label="Segment"
              name="segment_id"
              required={true}
              options={segment_list}
            />
          </Grid>

          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Select
              label="Addon Name"
              name="addon_type"
              required={true}
              options={addon_list}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <DateInput
              label="Effective From Date"
              name="effective_from"
              required={true}
              minDate={moment()}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <DateInput
              label="Effective To Date"
              name="effective_to"
              required={true}
              minDate={moment()}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <TextArea
              style={{ overflow: "auto" }}
              label="Addon Wording"
              name="addon_wording_details"
              required={true}
              maxHeight={"100px"}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Radio
              name="status"
              label="Status"
              required
              row
              color="opposite"
              options={statusOptions}
            />
          </Grid>
        </MasterFormWrapper>
      </FormProvider>
    </Navbar>
  );
};

export default AddonWordings;
