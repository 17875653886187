import { useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router";
import Navbar from "../../../../components/Navbar";
import withRouteProtection from "../../../../hoc/withRouteProtection";
import { useGetPolicyDetailsRegistration } from "../../../../services/claims.service";
import { Container, Wrapper } from "../../claimStyledComponents";
import ClaimDetailsFooter from "../../components/ClaimDetailsFooter";
import ClaimSummary from "../../components/ClaimSummary";
import ClaimSummaryPopup from "../../components/ClaimSummaryPopup";
import ClaimIntimation from "../../forms/claimIntimation/components/ClaimIntimation";
import ClaimDetailsViewRight from "./ClaimDetailsViewRight";
import { useGetUser } from "../../../../services/AuthenticationServices";

const ClaimDetailsView = () => {
  const { claim_detail_id } = useParams();
  const { policy_details_list } =
    useGetPolicyDetailsRegistration(claim_detail_id);

  const { data } = useGetUser();

  const claimDetails = policy_details_list[0];
  const isClaimAdminUser =
    claimDetails?.claim_admin_user ||
    data?.role_name?.toLowerCase() === "claim admin";

  const [openDetails, setOpenDetails] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Navbar>
      {isClaimAdminUser ? (
        // TODO: name change of component
        <ClaimIntimation />
      ) : (
        <>
          <Wrapper>
            <Container>
              {!isMobile ? (
                <ClaimSummary />
              ) : (
                <>
                  <ClaimDetailsFooter
                    openDetails={openDetails}
                    setOpenDetails={setOpenDetails}
                  />
                  {openDetails && (
                    <>
                      <ClaimSummaryPopup
                        setOpenDetails={setOpenDetails}
                        openDetails={openDetails}
                      />
                      <div className="mobileProposalBlur"></div>
                    </>
                  )}
                </>
              )}
              <ClaimDetailsViewRight
                policy_details_list={policy_details_list}
              />
            </Container>
          </Wrapper>
        </>
      )}
    </Navbar>
  );
};

export default withRouteProtection(ClaimDetailsView);
