import { Grid } from "@mui/material";
import moment from "moment";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  allCapitalize,
  allowOnlyAlphabetsNumberSpaceHyphen,
  allowOnlyNumbers,
} from "../../../../../../InputUtils/onInput";
import DateInput from "../../../../../../components/InputComponents/DateInput";
import Select from "../../../../../../components/InputComponents/Select";
import TextInput from "../../../../../../components/InputComponents/TextInput";
import { licenseTypeOptions } from "../../../../../../constants/options";
import { useGetSortedMaster } from "../../../../../../services/master.service";
import { useGetCityFromState } from "../../../../../../services/pincode.service";

function DriverDetailsForm() {
  const { watch } = useFormContext();

  const { table_list: state_items } = useGetSortedMaster({
    master_name: "state",
    sortBy: "state_name",
  });
  const state_lists = useMemo(() => {
    return state_items.map((item) => {
      return {
        code: item.state_id,
        display_name: item.state_name,
      };
    });
  }, [state_items]);

  const { table_list: city_items } = useGetCityFromState(
    watch("place_of_issue_state_id")
  );
  const city_lists = useMemo(() => {
    return city_items.map((item) => {
      return {
        code: item.city_id,
        display_name: item.city_name,
      };
    });
  }, [city_items]);

  return (
    <Grid container spacing={2} mt={0} px={2}>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Driver Age"
          name="driver_age"
          required={true}
          standard
          onInput={allowOnlyNumbers}
          inputProps={{
            maxLength: 2,
            inputMode: "numeric",
          }}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Driver License No."
          required={true}
          name="driver_license_no"
          standard
          placeholder="AB1234567890123"
          onInput={(e) => {
            allCapitalize(e);
            allowOnlyAlphabetsNumberSpaceHyphen(e);
          }}
          inputProps={{ maxLength: 20 }}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <Select
          label="License Type"
          name="license_type"
          required={true}
          variant="standard"
          options={licenseTypeOptions}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <Select
          label="State"
          name="place_of_issue_state_id"
          required={true}
          variant="standard"
          options={state_lists}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <Select
          label="Place of Issue"
          name="place_of_issue_city_id"
          required={true}
          variant="standard"
          options={city_lists}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <DateInput
          label="Valid Upto"
          name="valid_upto"
          modifyInvalidDate
          required={true}
          variant="standard"
          minDate={moment()}
          maxDate={moment().add(20, "years").subtract(1, "day")}
          disablePast
        />
      </Grid>
    </Grid>
  );
}

export default DriverDetailsForm;
