import * as yup from "yup";
import { MultiDocumentUploadsImageAndPdfLessThan2MB } from "../../../../utils/Validations/documentUploadsValidations";

export const RightCancellationApprovalValidationSchema = () =>
  yup.object().shape({
    ic_status: yup
      .string()
      .nullable()
      .required("Cancellation status is required"),
    approved_refund: yup
      .string()
      .nullable()
      .required("Approved refund amount is required"),

    vehicle_delivered: yup
      .string()
      .nullable()
      .required("Vehicle delivery status is required"),
    cancellation_reason: yup
      .string()
      .nullable()
      .required("Cancellation reason is required"),
    proof: yup.string().nullable().required("Proof is required"),
    refund_favour: yup
      .string()
      .nullable()
      .required("Refund in favour is required"),

    refund_method: yup
      .string()
      .nullable()
      .when("refund_favour", {
        is: (refund_favour) => refund_favour !== "no_refund",
        then: yup.string().nullable().required("Refund method is required"),
      }),
    first_year_od_premium: yup
      .string()
      .nullable()
      .required("OD Premium is required"),
    first_year_tp_premium: yup
      .string()
      .nullable()
      .required("TP Premium is required"),
    remarks: yup.string().nullable().required("Remark is required"),
    mode_of_refund: yup
      .string()
      .nullable()
      .required("Mode of refund is required"),
    document_upload: MultiDocumentUploadsImageAndPdfLessThan2MB(),
    ic_remark: yup
      .string()
      .nullable()
      .required("Cancellation remark is required"),
  });

export const RightCancellationRefundValidationSchema = yup.object().shape({
  mode_of_refund: yup
    .string()
    .nullable()
    .required("Mode of refund is required"),
  refund_in_favour: yup
    .string()
    .nullable()
    .required("Refund in favour is required"),
  payment_no: yup
    .string()
    .nullable()
    .required("Cheque no. / Transaction ID is required")
    .min(6, "Cheque no. / Transaction ID should be 6 digits"),
  refund_date: yup
    .string()
    .nullable()
    .nullable()
    .required("Refund date is required"),
});
