import { Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  addressValidationOnly,
  allowOnlyNumbers,
  toCapitalize,
} from "../../../../../../InputUtils/onInput";
import TextArea from "../../../../../../components/BasicFormComponent/TextArea";
import DateAndTimePicker from "../../../../../../components/InputComponents/DateTimePicker";
import MultiSelect from "../../../../../../components/InputComponents/MultiSelect";
import Select from "../../../../../../components/InputComponents/Select";
import TextInput from "../../../../../../components/InputComponents/TextInput";
import {
  natureOfAccident,
  statusOptions3,
  vehiclePartOptions,
} from "../../../../../../constants/options";
import { usePincodeValidations } from "../../../../../../custom-hooks/usePincodeValidations";
import { useGetSortedMaster } from "../../../../../../services/master.service";
import { useGetStateCityFromPincode } from "../../../../../../services/pincode.service";

export default function AccidentDetailsForm() {
  const methods = useFormContext();
  const { watch } = methods;
  const pincode = watch("pincode_of_accident");

  const {
    getCityState,
    state_list,
    city_list,
    resetStateCity,
    isStateCitySuccess,
  } = useGetStateCityFromPincode(pincode);

  useEffect(() => {
    if (pincode?.length === 6) {
      methods.setValue("accident_state", "");
      methods.setValue("accident_city", "");
      getCityState(pincode);
    } else {
      resetStateCity();
    }
    if (pincode === "") {
      methods.setValue("accident_state", "");
      methods.setValue("accident_city", "");
    }
  }, [pincode]);

  usePincodeValidations({
    pincode_field_name: "pincode_of_accident",
    pincode,
    state_list,
    city_list,
    formMethods: methods,
    isSuccess: isStateCitySuccess,
  });

  const { table_list: catastrophic_items } = useGetSortedMaster({
    master_name: "catastrophic",
  });
  const catastrophic_list = useMemo(() => {
    return catastrophic_items?.map((item) => {
      return {
        code: item?.catastrophic_id,
        display_name: `${item?.catastrophic_name} - ${item?.catastrophic_code}`,
      };
    });
  }, [catastrophic_items]);

  return (
    <Grid container spacing={2} mt={0} px={2}>
      <Grid item lg={4} xxs={6}>
        <Select
          label="Nature of Accident"
          name="nature_of_accident"
          variant="standard"
          required={true}
          options={natureOfAccident}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <Select
          label="Third Party Loss"
          name="is_tp_loss"
          required={true}
          variant="standard"
          options={statusOptions3}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Accident City Pincode"
          name="pincode_of_accident"
          required={true}
          standard
          onInput={allowOnlyNumbers}
          inputProps={{ maxLength: 6 }}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <Select
          label="Accident State"
          name="accident_state"
          required={true}
          variant="standard"
          options={state_list}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <Select
          label="Accident City"
          name="accident_city"
          required={true}
          variant="standard"
          options={city_list}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Place of Accident"
          name="place_of_accident"
          required={true}
          standard
          onInput={(e) => {
            toCapitalize(e);
            addressValidationOnly(e);
          }}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <DateAndTimePicker
          label="Date and Time of Accident"
          name="date_of_accident"
          variant="standard"
          disableFuture={true}
          minDate={moment().subtract(3, "months")}
          // maxTime={isToday && moment().add(0, "hours")} (was not allowing to select current and upcoming time of past dates)
          required={true}
          maxDateTime={moment().toDate()}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <Select
          label="Was Vehicle Parked ?"
          name="is_vehicle_parked"
          required={true}
          variant="standard"
          options={statusOptions3}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <Select
          label="Catastrophic Code"
          name="catastrophic_code"
          variant="standard"
          options={catastrophic_list}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <MultiSelect
          label="Part of Vehicle"
          name="part_of_vehicle"
          required={true}
          variant="standard"
          options={vehiclePartOptions}
        />
      </Grid>
      <Grid item lg={8} md={12} xxs={12}>
        <TextArea
          label="Brief Description of Accident"
          name="description_of_accident"
          standard
          required={true}
          onInput={(e) => {
            toCapitalize(e);
          }}
          maxLength={300}
        />
      </Grid>
    </Grid>
  );
}
