import { Box, Tabs, Typography, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  background:
    "linear-gradient(188deg, rgba(255,255,255,1) 20%, rgba(157,186,224,1) 60%)",
  //   backgroundColor: "#2C303F",
  height: "100vh",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
export const LeftSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "50%",
  justifyContent: "center",
  height: "100%",
  padding: "40px",
  [theme.breakpoints.down("1135")]: {
    padding: "40px 20px 40px 20px",
  },
  [theme.breakpoints.down("920")]: {
    width: "45%",
  },
  [theme.breakpoints.down("840")]: {
    width: "40%",
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
export const LeftHeading = styled(Typography)(({ theme }) => ({
  fontSize: "3rem",
  color: "808080",
  fontWeight: "bold",
  [theme.breakpoints.down("1055")]: {
    fontSize: "2.8rem",
  },
  [theme.breakpoints.down("995")]: {
    fontSize: "2.6rem",
  },
  [theme.breakpoints.down("930")]: {
    fontSize: "2.4rem",
  },
  [theme.breakpoints.down("920")]: {
    fontSize: "2.2rem",
  },
  [theme.breakpoints.down("885")]: {
    fontSize: "2.0rem",
  },
}));
export const RightSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  width: "50%",
  height: "100%",
  boxShadow: "grey 0px 4px 4px 2px",
  padding: "20px 50px",
  borderRadius: "20px 0 0 20px",
  overflow: "auto",
  [theme.breakpoints.down("920")]: {
    width: "55%",
  },
  [theme.breakpoints.down("840")]: {
    width: "60%",
  },
  [theme.breakpoints.down("500")]: {
    padding: "20px 20px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",
    borderRadius: "0",
    boxShadow: "grey 0px -4px 4px 2px",
  },
}));
export const BorderedWrapper = styled(Box)(({ theme, step }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid #727272",
  borderRadius: "20px 0 0 20px",
  width: "100%",
  height: step > 1 ? "auto" : "100%",
  gap: "15px",
  padding: "15px",
  position: "relative",
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
export const UserTypeSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
  width: "100%",
}));

export const UserTypeCard = styled(Box)(({ user }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "10px",
  cursor: "pointer",
  alignItems: "center",
  border: `2px solid ${user?.color}`,
  textAlign: "center",
  height: "140px",
  backgroundColor: user?.bgcolor,
  transition: "all 0.2s ease",
  ":hover": {
    boxShadow: `grey 2px 2px 4px`,
  },
}));

export const UserTypeIcon = styled(Box)(() => ({
  padding: "0 10px",
  height: "80%",
  width: "auto",
}));
export const UserTypeName = styled(Typography)(({ theme }) => ({
  height: "20%",
  fontWeight: "bold",
  overflow: "hidden",
  fontSize: "14px",
  [theme.breakpoints.down("560")]: {
    fontSize: "12px",
  },
}));

export const HelperText = styled(Typography)({
  fontWeight: "bold",
  color: "#727272",
  textAlign: "center",
  fontSize: "16px",
});

export const LoginFormSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
}));
export const StyledTabs = styled(Tabs)(({ theme }) => ({
  bgcolor: "white",
  color: "#6f6f6f",
  width: "50%",
  margin: "0 auto 10px auto",
  [theme.breakpoints.between("768", "1030")]: {
    width: "70%",
  },
  [theme.breakpoints.down("430")]: {
    width: "70%",
  },
}));

export const HelperText2 = styled(Typography)(({ hover }) => ({
  color: "#006eff",
  fontSize: "14px",
  fontWeight: "normal",
  ":hover": {
    cursor: hover && "pointer",
    textDecoration: hover && "underline",
  },
}));
export const OtpButtonWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  width: "130px",
  margin: "auto",
  marginTop: "10px",
  gap: "10px",
}));
