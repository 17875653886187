import { useLocation, useNavigate, useParams } from "react-router";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import { useState } from "react";
import { findPathToCurrentNode, routes } from "../../../routes/Routes";

const useNavbar = () => {
  const location = useLocation();
  const { segment_slug } = useParams();
  const { getUrlQuery } = useUrlQuery();
  const trace_id = getUrlQuery("traceId");
  const isFromProposalListing = getUrlQuery("from_proposal_listing") === "true";
  const isFromQuoteListing = getUrlQuery("from_quotes_listing") === "true";
  const [isTickerOpen, setIsTickerOpen] = useState(
    localStorage.getItem("tickerClosed") === "true" ? false : true
  );
  const navigate = useNavigate();
  const isFromDraftedProposalListing =
    getUrlQuery("fromDraftedList") === "true";

  const breadCrumbs = findPathToCurrentNode(
    routes[0].children,
    location.pathname
  );
  const currentPath = breadCrumbs && breadCrumbs[breadCrumbs?.length - 1];
  const previousPath =
    (breadCrumbs && breadCrumbs[breadCrumbs?.length - 2]) || currentPath;
  const pathForBackButton = previousPath?.resolvedPath;

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const isPolicyJourney = location.pathname.split("/").includes("policy");

  const showSegmentImage = segment_slug && isPolicyJourney;

  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const handleNavbarBackButton = () => {
    if (breadCrumbs?.length === 1) navigate("/");
    else if (isFromProposalListing)
      navigate("/policy_support/proposal_listing");
    else if (isFromQuoteListing) navigate("/policy_support/quote_listing");
    else if (location.pathname.split("/").includes("compare-quotes"))
      navigate(`/policy/${segment_slug}/quotes?traceId=${trace_id}`);
    else if (
      location.pathname.split("/").includes("policy") &&
      location.pathname.split("/").includes("proposal") &&
      !isFromDraftedProposalListing
    )
      navigate(`/policy/${segment_slug}/quotes?traceId=${trace_id}`);
    else if (
      location.pathname.split("/").includes("policy") &&
      location.pathname.split("/").includes("proposal") &&
      isFromDraftedProposalListing
    )
      navigate(`/policy_support/drafted_proposal_listing`);
    else if (pathForBackButton) navigate(pathForBackButton);
    else navigate(-1);
  };
  return {
    toggleMenu,
    breadCrumbs,
    handleNavbarBackButton,
    showMobileMenu,
    showSegmentImage, // used to determine whether to show images of segment in breadcrumbs
    tickerState: {
      isTickerOpen,
      setIsTickerOpen,
    },
  };
};

export default useNavbar;
