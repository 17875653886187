import { Box } from "@mui/material";
import React from "react";
import { CLAIM_WITHDRAW } from "../../../../constants/claimStage";
import ClaimApprovalForm from "../../forms/claimIntimation/components/forms/ClaimApprovalForm";
import ClaimSettlementForm from "../../forms/claimIntimation/components/forms/ClaimSettlementForm";
import ClaimInvoiceForm from "../../forms/claimIntimation/components/forms/DocumentForm/forms/ClaimInvoiceForm";
import DeliveryOrderForm from "../../forms/claimIntimation/components/forms/DocumentForm/forms/DeliveryOrderForm";
import ProformaForm from "../../forms/claimIntimation/components/forms/DocumentForm/forms/ProformaForm";
import SurveyorAssesmentForm from "../../forms/claimIntimation/components/forms/SurveyorAssessmentForm";
import { useClaimStage } from "../../hooks/useClaimStage";
import ClaimDetailsViewNotification from "./ClaimDetailsViewNotification";
import ClaimDetailsViewRegistration from "./ClaimDetailsViewRegistration";
import ClaimDetailsViewSurveyor from "./ClaimDetailsViewSurveyor";
import ClaimDetailsViewSurveyorAssessmentDetails from "./ClaimDetailsViewSurveyorAssessmentDetails";
import ClaimApprovalDetails from "./components/ClaimApprovalDetails";
import ClaimInvoiceDetails from "./components/ClaimInvoiceDetails";
import ClaimSettlementDetails from "./components/ClaimSettlementDetails";
import DeliveryOrderDetails from "./components/DeliveryOrderDetails";
import ProformaInvoiceDetails from "./components/ProformaInvoiceDetails";

const ClaimDetailsViewRight = ({ policy_details_list }) => {
  // props coming from ClaimDetailsView
  const withdrawn = policy_details_list[0]?.claim_stage === CLAIM_WITHDRAW;

  const claimDetails = policy_details_list[0];
  const claimStage = policy_details_list[0]?.claim_stage;

  const {
    component_visibility_conditions: {
      showClaimIntimationDetails,
      showClaimRegistrationDetails,
      showSurveyorAppointmentDetails,
      showSurveyorAssessmentDetails,
      showClaimSettlementForm,
      showProformaInvoiceDetails,
      showClaimApprovalDetails,
      showDeliveryOrderDetails,
      showClaimInvoiceDetails,
      showClaimSettlementDetails,
      showClaimApprovalForm,
      showClaimInvoiceForm,
      showDeliveryOrderForm,
      showProformaForm,
      showSurveyorAssessmentForm,
    },
  } = useClaimStage({
    claim_stage: claimStage,
  });

  // TODO: renaming of components
  return (
    <Box sx={{ width: "100%" }}>
      {showClaimIntimationDetails && (
        <ClaimDetailsViewNotification claimDetails={claimDetails} />
      )}
      {showClaimRegistrationDetails && (
        <ClaimDetailsViewRegistration claimDetails={claimDetails} />
      )}
      {showSurveyorAppointmentDetails && (
        <ClaimDetailsViewSurveyor claimDetails={claimDetails} />
      )}
      {showSurveyorAssessmentDetails && (
        <ClaimDetailsViewSurveyorAssessmentDetails
          claimDetails={claimDetails}
        />
      )}
      {showProformaInvoiceDetails && (
        <ProformaInvoiceDetails claimDetails={claimDetails} />
      )}

      {showClaimApprovalDetails && (
        <ClaimApprovalDetails claimDetails={claimDetails} />
      )}

      {showDeliveryOrderDetails && (
        <DeliveryOrderDetails claimDetails={claimDetails} />
      )}

      {showClaimInvoiceDetails && (
        <ClaimInvoiceDetails claimDetails={claimDetails} />
      )}

      {showClaimSettlementDetails && (
        <ClaimSettlementDetails claimDetails={claimDetails} />
      )}
      {/* TODO: check visibility conditions and remove repeatability */}
      {withdrawn && (
        <>
          <ClaimDetailsViewNotification claimDetails={claimDetails} />

          {(claimDetails?.driver_age ||
            claimDetails?.driver_license_no ||
            claimDetails?.total_estimation) && (
            <ClaimDetailsViewRegistration claimDetails={claimDetails} />
          )}

          {(claimDetails?.surveyor_name ||
            claimDetails?.srv_contact_no ||
            claimDetails?.srv_email_id) && (
            <ClaimDetailsViewSurveyor claimDetails={claimDetails} />
          )}
        </>
      )}

      {showSurveyorAssessmentForm && (
        <SurveyorAssesmentForm claimDetails={claimDetails} />
      )}

      {showClaimApprovalForm && <ClaimApprovalForm />}

      {showClaimSettlementForm && <ClaimSettlementForm />}

      {showProformaForm && <ProformaForm />}

      {showDeliveryOrderForm && <DeliveryOrderForm />}

      {showClaimInvoiceForm && <ClaimInvoiceForm />}
    </Box>
  );
};

export default ClaimDetailsViewRight;
