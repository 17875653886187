import moment from "moment";
import * as yup from "yup";

export const exceptionLogValidationSchema = yup.object().shape(
  {
    from_date: yup
      .string()
      .nullable()
      .when(["to_date"], {
        is: (to_date) => !!to_date,
        then: yup.string().nullable().required("From date is required"),
      })
      .test(
        "check_before",
        "From date should be before to date",
        function (value) {
          if (!value) return true;
          return moment(value).isBefore(this.parent.to_date);
        }
      ),
    to_date: yup
      .string()
      .nullable()
      .when(["from_date"], {
        is: (from_date) => !!from_date,
        then: yup.string().nullable().required("From date is required"),
      })
      .test(
        "check_after",
        "To date should be after from date",
        function (value) {
          if (!value) return true;
          return moment(value).isAfter(this.parent.from_date);
        }
      ),
  },
  [["from_date", "to_date"]]
);
