import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import Buttons from "../../components/BasicFormComponent/Buttons";
import { RefreshButton } from "../Configurator/DiscountConfigurator/DiscountConfiguratorStyle";
import {
  AtleastOnefieldError,
  ButtonWrapper,
  FormContainer,
  Heading,
  Papers,
} from "../masters/components/MasterStyle";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { Box } from "@mui/system";
import MasterTable from "../masters/MasterTable/MasterTable";
import { Button, ButtonGroup, Grid } from "@mui/material";
import withRouteProtection from "../../hoc/withRouteProtection";
import api from "../../api/api";
import { useQueryClient } from "@tanstack/react-query";
import {
  allCapitalize,
  allowOnlyAplhabetNumber,
  allowOnlyNumbers,
  allowRegistrationNumber,
} from "../../InputUtils/onInput";
import TextInput from "../../components/InputComponents/TextInput";
import Select from "../../components/InputComponents/Select";
import DateInput from "../../components/InputComponents/DateInput";
import { policyStageOptions } from "../../constants/options";
import Navbar from "../../components/Navbar";
import {
  PlicyListingRetryPdfButton,
  PolicyListingButtonGroup,
} from "./styles/listing.styles";

const PolicyListing = ({ values }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const columnFilters = searchParams.get("column_filters");
  const customSearchFromParam = JSON.parse(columnFilters)?.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.id]: curr.value,
    }),
    {}
  );
  const methods = useForm({
    defaultValues: {
      registration_no: "",
      mobile_no: "",
      policy_no: "",
      chassis_no: "",
      engine_no: "",
      proposal_stage: customSearchFromParam?.proposal_stage || "",
      from_date: customSearchFromParam?.from_date || "",
      to_date: customSearchFromParam?.to_date || "",
      ...values,
    },
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [customSearch, setCustomSearch] = useState(customSearchFromParam || {});
  const allfields = methods.watch();
  useEffect(() => {
    const isAnyFieldSelectedSecond = Object?.values(allfields).some(
      (item) => item !== ""
    );
    if (isAnyFieldSelectedSecond) {
      methods.clearErrors();
    }
  }, [JSON.stringify(allfields)]);
  const onSubmit = (data) => {
    const isAnyFieldSelected = Object?.values(data).some((item) => item !== "");
    if (isAnyFieldSelected) {
      setCustomSearch(data);
    } else {
      methods.setError("atleast_one_field", {
        type: "custom",
        message: "Atleast one field is required",
      });
    }
  };
  const handleReset = (e) => {
    if (searchParams.get("column_filters")) {
      searchParams.delete("column_filters");
    }
    setCustomSearch({});
    e.preventDefault();
    methods.reset({
      proposal_stage: "",
      from_date: "",
      to_date: "",
      registration_no: "",
      mobile_no: "",
      policy_no: "",
      chassis_no: "",
      engine_no: "",
    });
  };
  const err = methods.formState.errors?.atleast_one_field?.message;
  return (
    <Navbar>
      <Box>
        <FormContainer>
          <Papers elevation={3} sx={{ width: "100%" }}>
            <Heading variant="h5" align="left">
              Policy Listing
            </Heading>
            {err && <AtleastOnefieldError>{err}</AtleastOnefieldError>}
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={1} columnSpacing={3} mt={1}>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <TextInput
                      label={"Policy No."}
                      name={"policy_no"}
                      inputProps={{ maxLength: 10 }}
                      onInput={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      standard
                    />
                  </Grid>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <Select
                      name="proposal_stage"
                      label="Policy Stage"
                      options={policyStageOptions}
                      variant="standard"
                      saveDisplayName={false}
                    />
                  </Grid>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <DateInput
                      name="from_date"
                      label="Risk Start Date"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <DateInput
                      name="to_date"
                      label="Risk End Date"
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <TextInput
                      label={"Registration No."}
                      name={"registration_no"}
                      inputProps={{ maxLength: 17 }}
                      onInput={allowRegistrationNumber}
                      standard
                    />
                  </Grid>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <TextInput
                      label={"Chassis No."}
                      name={"chassis_no"}
                      inputProps={{ maxLength: 25 }}
                      onInput={(e) => {
                        allCapitalize(e);
                        allowOnlyAplhabetNumber(e);
                      }}
                      standard
                    />
                  </Grid>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <TextInput
                      label={"Engine No."}
                      name={"engine_no"}
                      inputProps={{ maxLength: 25 }}
                      onInput={(e) => {
                        allCapitalize(e);
                        allowOnlyAplhabetNumber(e);
                      }}
                      standard
                    />
                  </Grid>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <TextInput
                      label={"Mobile No."}
                      name={"mobile_no"}
                      inputProps={{ maxLength: 10 }}
                      onInput={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      standard
                    />
                  </Grid>
                </Grid>
                <ButtonWrapper marginLeft="auto">
                  <Buttons
                    type="submit"
                    label="Search"
                    id="search_for_policy_listing"
                  ></Buttons>
                  <RefreshButton onClick={(e) => handleReset(e)}>
                    <ReplayRoundedIcon fontSize="small" color="#475f63" />
                    Reset
                  </RefreshButton>
                </ButtonWrapper>
              </form>
            </FormProvider>
          </Papers>
        </FormContainer>
        <Box>
          <MasterTable
            master_name={"policy_listing"}
            cellModifier={(cellData) => {
              if (cellData.type === "href") {
                return {
                  Cell: ({ cell, row }) => {
                    if (
                      row.original.proposal_stage === "Policy Issued" ||
                      row.original.proposal_stage === "Policy Cancelled"
                    ) {
                      const retryApiURL = row?.original?.policy_url;
                      const policyNo = row?.original?.policy_no;
                      const segmentId = row?.original?.segment_id;
                      const data = {
                        policy_no: policyNo,
                        segment_id: segmentId,
                      };

                      const conditionalButtonForMandateOrOtp =
                        row?.original?.mandate_url;

                      const proposalSummaryPathButton =
                        row?.original?.summary_doc_path;

                      const policyPdfButton = row?.original?.policy_url;

                      return row?.original?.policy_doc_path ? (
                        <PolicyListingButtonGroup
                          variant="outlined"
                          aria-label="outlined primary button group"
                        >
                          <>
                            <Button
                              disabled={policyPdfButton ? false : true}
                              rel="noreferrer"
                              onClick={() => {
                                window.open(
                                  row?.original?.policy_url,
                                  "_blank"
                                );
                              }}
                            >
                              Policy
                            </Button>
                            <Button
                              disabled={
                                proposalSummaryPathButton ? false : true
                              }
                              rel="noreferrer"
                              onClick={() => {
                                window.open(
                                  row?.original?.summary_doc_path,
                                  "_blank"
                                );
                              }}
                            >
                              Proposal
                            </Button>
                            {conditionalButtonForMandateOrOtp ? (
                              <Button
                                rel="noreferrer"
                                onClick={() => {
                                  window.open(
                                    row?.original?.mandate_url,
                                    "_blank"
                                  );
                                }}
                              >
                                Mandate
                              </Button>
                            ) : (
                              <Button disabled>OTP</Button>
                            )}
                          </>
                        </PolicyListingButtonGroup>
                      ) : (
                        <PlicyListingRetryPdfButton
                          onClick={async () => {
                            await api.post(retryApiURL, data);
                            queryClient.invalidateQueries(["policy_listing"]);
                          }}
                        >
                          Retry PDF
                        </PlicyListingRetryPdfButton>
                      );
                    }
                    if (row.original.proposal_stage === "Policy Submitted") {
                      return (
                        <PlicyListingRetryPdfButton
                          onClick={() => {
                            navigate(cell.getValue());
                          }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Review & Pay
                        </PlicyListingRetryPdfButton>
                      );
                    }
                    if (
                      row.original.proposal_stage === "IC Approval Pending" ||
                      row.original.proposal_stage ===
                        "Self Inspection Initiated"
                    ) {
                      return (
                        <Box sx={{ ml: "8px", cursor: "default" }}>
                          {row.original.proposal_stage}{" "}
                        </Box>
                      );
                    }
                  },
                };
              }
            }}
            columnFilters={Object.keys(customSearch).map((item) => {
              return {
                id: item,
                value: customSearch[item],
              };
            })}
          />
        </Box>
      </Box>
    </Navbar>
  );
};

export default withRouteProtection(PolicyListing);
