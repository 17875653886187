export const SPOT_INTIMATED = "spot_intimated"; //TODO: remove
export const CLAIM_INTIMATED = "claim_intimated";
export const SURVEYOR_APPOINTED = "surveyor_appointed";
export const SURVEYOR_ASSESSMENT = "surveyor_assessment";
export const CLAIM_WITHDRAW = "claim_withdrawn";
export const CLAIM_SETTLED = "claim_settled";
export const CLAIM_REGISTRERED = "claim_registered";
export const CLAIM_APPROVED = "claim_approved";
export const CLAIM_REJECTED = "claim_rejected";
export const PROFORMA_INVOICE_RECEIVED = "proforma_invoice_received";
export const DELIVERY_ORDER_RECEIVED = "delivery_order_received";
export const CLAIM_INVOICE_RAISED = "claim_invoice_raised";
