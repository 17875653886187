import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api/api";
import { errorHandler } from "../utils/errorHandler";
import { convertToFormData } from "../utils/convertToFormData";

export const useUpdateRenewalLead = () => {
  const queryClient = useQueryClient();
  const { mutate, data, error, isSuccess } = useMutation(
    (data) => {
      const formData = convertToFormData(data);
      return api.post("/lead/update", formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["renewal_lead"]);
      },
    }
  );

  const errList = errorHandler(error, data);

  return {
    updateRenewalLead: mutate,
    alertMaster: errList,
    success: isSuccess,
  };
};

export const useProposalCreated = () => {
  const queryClient = useQueryClient();
  const { mutate, data, error, isSuccess } = useMutation(
    (data) => {
      return api.post("drafted_proposal/create_renewal_proposal", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["renewal_lead"]);
      },
    }
  );
  const errList = errorHandler(error, data);
  return {
    proposalCreated: mutate,
    alertMaster: errList,
    success: isSuccess,
  };
};
