import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Box from "@mui/material/Box";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, useFormContext } from "react-hook-form";
import { InputLabel, TextField } from "./InputComponents.style";

export default function DateAndTimePicker({
  label,
  name,
  required,
  readOnly,
  ...otherProps
}) {
  const {
    control,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();

  const dateTimeValue = watch(name);

  return (
    <Box component="div">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <InputLabel title={label} required={required}>
          {label}
        </InputLabel>
        <Controller
          name={name}
          control={control || {}}
          render={({ field: { onChange } }) => (
            <DateTimePicker
              views={["year", "month", "day", "hours", "minutes"]}
              inputFormat="DD-MM-YYYY hh:mm a"
              value={dateTimeValue || null}
              readOnly={readOnly}
              renderInput={(params) => (
                <TextField
                  readOnly={readOnly}
                  helperText={errors[name]?.message}
                  {...params}
                  error={!!errors[name]}
                />
              )}
              onChange={(newValue) => {
                onChange(newValue);
                trigger(name);
              }}
              {...otherProps}
              errors={Boolean(errors)}
              helpertext={Boolean(errors?.message)}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}
