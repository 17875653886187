import * as yup from "yup";
export const IMTConfiguratorValidation = yup.object().shape({
  imt_name: yup.string().required("IMT name is required"),
  insurance_id: yup
    .array()
    .test(
      "check_empty_array",
      "Insurance company is required",
      function (value) {
        return value?.length > 0;
      }
    ),
  segment_id: yup
    .array()
    .test("check_empty_array", "Segment is required", function (value) {
      return value?.length > 0;
    }),
  amount_type: yup.string().required("Tarrif amount type is required"),
  applied_on: yup.string().required("Applied on is required"),
  tariff_rate: yup.string().required("Tariff rate is required"),
  status: yup.string().required("Status is required"),
  calculation_type: yup.string().required("Calculation type is required"),
  amount: yup
    .string()
    .nullable()
    .test("checkamount", "Flat amount is required", function (val) {
      const { on_condition } = this.parent;
      return on_condition && !val ? false : true;
    })
    .test(
      "amount value",
      "Flat amount must be greater than zero",
      function (val) {
        if (!val) return true;
        return val > 0;
      }
    ),

  on_condition: yup
    .string()
    .nullable()
    .test("checkamount", "On condition is required", function (val) {
      const { amount } = this.parent;
      return amount && !val ? false : true;
    }),
  min_cc: yup
    .string()
    .min(0, "Enter valid minimum cubic capacity")
    .test(
      "check_calculation_type",
      "Minimum cubic capacity is required",
      function (value) {
        if (this.parent.calculation_type !== "cc") return true;
        return value;
      }
    )
    .test(
      "less_than_max",
      "Minimum cubic capacity must be less than maximum cubic capacity",
      function (value) {
        if (this.parent.calculation_type !== "cc" || !this.parent.max_cc)
          return true;
        return +this.parent.max_cc > +value;
      }
    ),

  max_cc: yup
    .string()
    .min(0, "Enter valid maximum cubic capacity")
    .test(
      "check_calculation_type",
      "Maximum cubic capacity is required",
      function (value) {
        if (this.parent.calculation_type !== "cc") return true;
        return value;
      }
    )
    .test(
      "more_than_min",
      "Maximum cubic capacity should be greater than minimum cubic capacity",
      function (value) {
        if (this.parent.calculation_type !== "cc") return true;
        return +this.parent.min_cc < +value;
      }
    ),
  min_sc: yup
    .string()
    .min(0, "Enter valid minimum seating capacity")
    .test(
      "check_calculation_type",
      "Minimum seating capacity is required",
      function (value) {
        if (this.parent.calculation_type !== "sc") return true;
        return value;
      }
    )
    .test(
      "less_than_max",
      "Minimum seating capacity must be less than maximum seating capacity",
      function (value) {
        if (this.parent.calculation_type !== "sc" || !this.parent.max_sc)
          return true;
        return +this.parent.max_sc > +value;
      }
    ),

  max_sc: yup
    .string()
    .min(0, "Enter valid maximum seating capacity")
    .test(
      "check_calculation_type",
      "Maximum seating capacity is required",
      function (value) {
        if (this.parent.calculation_type !== "sc") return true;
        return value;
      }
    )
    .test(
      "more_than_min",
      "Maximum seating capacity should be greater than minimum seating capacity",
      function (value) {
        if (this.parent.calculation_type !== "sc") return true;
        return +this.parent.min_sc < +value;
      }
    ),

  min_gvw: yup
    .string()
    .min(0, "Enter valid minimum weight")
    .test(
      "check_calculation_type",
      "Maximum weight is required",
      function (value) {
        if (this.parent.calculation_type !== "gvw") return true;
        return value;
      }
    )
    .test(
      "less_than_max",
      "Minimum weight must be less than maximum weight",
      function (value) {
        if (this.parent.calculation_type !== "gvw" || !this.parent.max_gvw)
          return true;
        return +this.parent.max_gvw > +value;
      }
    ),

  max_gvw: yup
    .string()
    .min(0, "Enter valid maximum weight")
    .test(
      "check_calculation_type",
      "Maximum weight is required",
      function (value) {
        if (this.parent.calculation_type !== "gvw") return true;
        return value;
      }
    )
    .test(
      "more_than_min",
      "Maximum weight should be greater than minimum weight",
      function (value) {
        if (this.parent.calculation_type !== "gvw") return true;
        return +this.parent.min_gvw < +value;
      }
    ),

  min_kw: yup
    .string()
    .min(0, "Enter valid minimum kilowatts")
    .test(
      "check_calculation_type",
      "Minimum kilowatts is required",
      function (value) {
        if (this.parent.calculation_type !== "kw") return true;
        return value;
      }
    )
    .test(
      "less_than_max",
      "Minimum kilowatts must be less than maximum kilowatts",
      function (value) {
        if (this.parent.calculation_type !== "kw" || !this.parent.max_kw)
          return true;
        return +this.parent.max_kw > +value;
      }
    ),

  max_kw: yup
    .string()
    .min(0, "Enter valid maximum kilowatts")
    .test(
      "check_calculation_type",
      "Maximum kilowatts is required",
      function (value) {
        if (this.parent.calculation_type !== "kw") return true;
        return value;
      }
    )
    .test(
      "more_than_min",
      "Maximum kilowatts should be greater than minimum kilowatts",
      function (value) {
        if (this.parent.calculation_type !== "kw") return true;
        return +this.parent.min_kw < +value;
      }
    ),
});
