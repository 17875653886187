import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import theme from "../styles/Theme";
import { Box, styled } from "@mui/material";

function MultipleLoginRestriction() {
  return (
    <MainContainer>
      <InfoOutlinedIcon
        sx={{
          fontSize: "150px",
          color: theme.palette.primary.main,
        }}
      />
      <Box>OEM APP is already open.</Box>
      <Box>Please close the application in other tab to resume here</Box>
    </MainContainer>
  );
}

export default MultipleLoginRestriction;

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100vh",
  color: "rgb(0,0,0,0.6)",
  fontSize: "18px",
});
