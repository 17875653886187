import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Paper, Box, Typography, Grid } from "@mui/material";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { Heading } from "../../masters/components/MasterStyle";
import { useParams } from "react-router";
import { useGetPisDetails } from "../../../services/payment.service";
import { inWords } from "../../../utils/NumbersToWords";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import { formatPrice } from "../../../utils/formatPrice";
import Navbar from "../../../components/Navbar";

function PayInSlipPrintPreview() {
  const { pis_number } = useParams();
  const { getUrlQuery } = useUrlQuery();

  const print = getUrlQuery("print");
  const { pis_details_list, isPISDetailsLoading } =
    useGetPisDetails(pis_number);

  useEffect(() => {
    if (print == "true" && !isPISDetailsLoading) {
      window.print();
    }
  }, [isPISDetailsLoading]);

  // console.log("pis---", pis_details_list);

  const TableHeading = () => {
    return (
      <>
        <th style={styledTh}>Cheque/DD/NEFT Data</th>
        <th style={styledTh}>Cheque/DD/NEFT No.</th>
        <th style={styledTh}>Insured Name</th>
        <th style={styledTh}>Drawee Bank Name</th>
        <th style={styledTh}>Cheque Amount</th>
        <th style={styledTh}>Payer Type</th>
        <th style={styledLastTh}>Common Ref No./Policy No.</th>
      </>
    );
  };

  const TableData = () => {
    return pis_details_list?.detail?.map((data, index) => {
      return (
        <tr key={index}>
          <td style={styledTd}>{data?.payment_mode}</td>
          <td style={styledTd}>{data?.cheque_number}</td>
          {data?.proposer_full_name ? (
            <td style={styledTd}>{data?.proposer_full_name}</td>
          ) : (
            <td
              style={{
                padding: "10px 5px",
                borderBottom: "1px solid #d4e8e8",
                whiteSpace: "pre-wrap",
                minWidth: "150px",
                maxWidth: "150px",
              }}
            >
              {data?.company_name}
            </td>
          )}
          <td style={styledTd}>{data?.bank_name}</td>
          <td style={styledTd}>{data?.amount}</td>
          <td style={styledTd}>{data?.payer_type}</td>
          <td style={styledTd}>{data?.policy_no}</td>
        </tr>
      );
    });
  };

  // Pay-in-slip total
  let total = 0;
  const amountArray = pis_details_list?.detail;

  amountArray?.forEach((item) => {
    total += Number(item?.amount);
  });

  return (
    <>
      <Navbar>
        <MainContainer>
          <Heading style={{ position: "relative" }}>
            Policy Pay In Slip
            <ButtonContainer className="printout" onClick={window.print}>
              <PrintOutlinedIcon />
            </ButtonContainer>
          </Heading>

          <TitleContainer>
            <IcContainer>
              <Box
                sx={{
                  height: "50px",
                  objectFit: "fit-content",
                }}
              >
                <img
                  style={{
                    height: "100%",
                  }}
                  // src={pis_details_list?.ic_logo}

                  //sample image will be dynamic from above
                  src={pis_details_list?.ic_logo}
                />
              </Box>
              <Box>
                <Typography>{pis_details_list?.insurance_name}</Typography>
                <Typography>
                  {pis_details_list?.address1} - {pis_details_list?.pincode_id}{" "}
                </Typography>
              </Box>
            </IcContainer>
            <Box>IRDA Region No. {pis_details_list?.irda_registration_no}</Box>
          </TitleContainer>

          <Grid container style={DetailsGridStyle}>
            <Grid>
              <Details>
                <Box>Pay In Slip Date:</Box>
                <Box>
                  {/* <b>{pis_details_list?.pay_slip_date}</b> */}
                  <b>{pis_details_list?.pay_slip_date}</b>
                </Box>
              </Details>
              <Details>
                <Box>Pay In Slip No.</Box>
                <Box>
                  <b>{pis_details_list?.pis_number}</b>
                </Box>
              </Details>
            </Grid>

            <Grid>
              <Details>
                <Box>Dealer Name:</Box>
                <Box>{pis_details_list?.dealer_name}</Box>
              </Details>
              <Details>
                <Box>Dealer Code:</Box>
                <Box>{pis_details_list?.dealer_code}</Box>
              </Details>
            </Grid>

            <Grid>
              <Details>
                <Box>Dealer City/District:</Box>
                <Box>{pis_details_list?.dealer_city}</Box>
              </Details>

              <Details>
                <Box>Payment Mode:</Box>
                <Box>{pis_details_list?.payment_mode}</Box>
              </Details>
            </Grid>
          </Grid>

          <div
            style={{
              overflow: "auto",
              width: "auto",
            }}
          >
            <table style={styledTable}>
              <thead>
                <tr className="printColor" style={styledTr}>
                  {TableHeading()}
                </tr>
              </thead>
              <tbody style={styledTBody}>{TableData()}</tbody>
            </table>
          </div>

          <TotalContainer>
            <Typography variant="p">
              Total: <b>{formatPrice(total)}</b>
            </Typography>
            <Typography variant="h6">
              <b>{inWords(total)}</b>
            </Typography>
          </TotalContainer>
        </MainContainer>

        <NoteContainer>
          <b>Note:</b> This deposit is not verified at the time of acceptance.
          Final credit is subject to verification by the bank and subject to the
          terms & conditions of the Collection Service Agreement
        </NoteContainer>
      </Navbar>
    </>
  );
}

export default PayInSlipPrintPreview;

const MainContainer = styled(Paper)({
  padding: "15px",
  position: "relative",
  borderRadius: "14px",
  margin: "25px 25px 15px 25px",
  boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
  // width: "90%"
});

const ButtonContainer = styled(Paper)({
  position: "absolute",
  top: "0",
  right: "0",
  color: "#006eff",
  padding: "2px",
  margin: "2px",
  cursor: "pointer",
});

const TitleContainer = styled(Box)({
  border: "2px solid #4f67812e",
  borderRadius: "14px",
  margin: "15px 13px",
  padding: "10px 30px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const IcContainer = styled(Box)({
  // border: "1px solid red",
  width: "80%",
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

const DetailsGridStyle = {
  justifyContent: "space-around",
  padding: "5px",
  marginBottom: "10px",
  // gap: "15px",
};

const Details = styled(Box)({
  width: "100%",
  display: "flex",
  gap: "50px",
  justifyContent: "space-between",
  margin: "2px",
  padding: "2px",
  fontSize: "11px",
  fontWeight: 450,
});

const TotalContainer = styled(Box)({
  textAlign: "right",
  marginTop: "10px",
});

const NoteContainer = styled(Box)({
  margin: "0 30px",
  textAlign: "center",
  color: "#535a5c",
});

const styledTh = {
  padding: "10px 10px",
  borderRight: "1px solid #b8b8b8",
  fontWeight: 500,
};
const styledLastTh = {
  padding: "10px 10px",
  borderRight: "none",
  fontWeight: 500,
};

const styledTd = {
  padding: "10px 5px",
  borderBottom: "1px solid #d4e8e8",
  whiteSpace: "nowrap",
};

const styledTable = {
  borderCollapse: "collapse",
  width: "100%",
  overflowX: "scroll",
};

const styledTr = {
  backgroundColor: "#ebf2f6",
  color: "#6e8185",
  fontSize: "11px",
  textAlign: "center",
  printColorAdjust: "exact",
};

const styledTBody = {
  color: "#6e8185",
  fontSize: "11px",
  textAlign: "center",
};
