import { Button, Dialog, Paper, Stack, styled } from "@mui/material";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  margin: "auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    // height:
    //   selectedButton === "Insurer"
    //     ? "60%"
    //     : selectedButton === "Basic"
    //     ? "45%"
    //     : "25%",
  },
}));

export const HeaderStackStyled = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
export const SubHeaderActionStyled = styled(Stack)(({ theme }) => ({
  width: "100%",
  marginTop: "1.5rem",
  [theme.breakpoints.down("md")]: {
    marginTop: "0.2rem",
  },
}));

export const SubHeaderPaperStyled = styled(Paper)(({ theme }) => ({
  borderRadius: "2rem",
  padding: "0.4rem",
  [theme.breakpoints.down("md")]: {
    padding: "0rem",
  },
}));

export const TabButtonStyled = styled(Button)(({ theme }) => ({
  borderRadius: "2rem",
  textTransform: "capitalize",
  [theme.breakpoints.down("md")]: {
    padding: "4px 12px",
    fontSize: "0.6rem",
    margin: "0.3rem",
    textAlign: "center",
  },
}));
