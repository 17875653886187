/*

This file includes  base validations for cin no.
Utilize these & add custom validations afterwards in respective files

*/

import * as yup from "yup";
import { REGEX } from "../../constants/regex";

export const cinNumberBasic = () => {
  return yup.string().nullable().matches(REGEX.CIN, "Enter valid CIN no.");
};

export const cinNumberRequired = () => {
  return yup
    .string()
    .nullable()
    .required("CIN no. is required")
    .matches(REGEX.CIN, "Enter valid CIN no.");
};
