import React, { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { policyWordingsValidationSchema } from "./PolicyWordingValidation";
import { Grid } from "@mui/material";
import MasterFormWrapper from "../../../../masters/components/MasterFormWrapper/MasterFormWrapper";
import TextInput from "../../../../../components/InputComponents/TextInput";
import TextArea from "../../../../../components/BasicFormComponent/TextArea";
import {
  useCreateMaster,
  useGetSortedMaster,
} from "../../../../../services/master.service";
import Select from "../../../../../components/InputComponents/Select";
import { statusOptions } from "../../../../../constants/options";
import Radio from "../../../../../components/InputComponents/Radio";
import { useGetProductsFromSegmentId } from "../../../../../services/icConfigurator.service";
import Navbar from "../../../../../components/Navbar";

const PolicyWordings = ({ values }) => {
  const methods = useForm({
    resolver: yupResolver(policyWordingsValidationSchema),
    defaultValues: {
      status: "Y",
      ...values,
    },
  });

  const segment_id = methods.watch("segment_id");

  const {
    createItem,
    isSuccess,
    alertMaster,
    resetMaster,
    isError,
    isLoading,
  } = useCreateMaster("policy_wording");

  const { table_list: ic_items } = useGetSortedMaster({
    master_name: "insurance",
    sortBy: "insurance_name",
  });

  const { product_items } = useGetProductsFromSegmentId(segment_id);

  const product_list = useMemo(() => {
    return product_items?.map((item) => {
      return {
        code: item?.product_id,
        display_name: item?.product_name,
      };
    });
  }, [product_items]);

  const ic_list = useMemo(() => {
    return ic_items.map((item) => {
      return {
        code: item.insurance_id,
        display_name: item.insurance_name,
      };
    });
  }, [ic_items]);

  const { table_list: segment_items } = useGetSortedMaster({
    master_name: "segment",
    sortBy: "segment_name",
  });
  const segment_list = useMemo(() => {
    return segment_items?.map((item) => {
      return {
        code: item?.segment_id,
        display_name: item?.segment_name,
      };
    });
  }, [segment_items]);

  useEffect(() => {
    methods.reset({ ...values });
  }, [values]);

  useEffect(() => {
    if (isSuccess) {
      methods.reset({
        limits_of_liability_clause: "",
        drivers_clause: "",
        important_notice: "",
        limitations_as_to_use: "",
        drivers_clause_details: "",
        ncb_clause: "",
        important_notice_details: "",
      });
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    createItem(data);
  };

  return (
    <Navbar>
      <FormProvider {...methods}>
        <MasterFormWrapper
          title={"Policy Wording"}
          description={"Add Policy Wording Details"}
          onSubmit={onSubmit}
          alert={alertMaster}
          reset={resetMaster}
          isError={isError}
          isSuccess={isSuccess}
          isLoading={isLoading}
        >
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Select
              label="Insurance Company"
              name="ic_id"
              required={true}
              options={ic_list}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Select
              label="Segment"
              name="segment_id"
              required={true}
              options={segment_list}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Select
              label="Product"
              name="product_id"
              required={true}
              options={product_list}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={2} lg={4}>
            <TextInput
              label="Limits of Liability Clause"
              name="limits_of_liability_clause"
              required={true}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={2} lg={4}>
            <TextInput
              label="Driver's Clause"
              name="drivers_clause"
              required={true}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={2} lg={4}>
            <TextInput
              label="Important Notice"
              name="important_notice"
              required={true}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <TextArea
              label="Limitations as to use"
              name="limitations_as_to_use"
              required={true}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <TextArea
              label="Driver's Clause Description"
              name="drivers_clause_details"
              required={true}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <TextArea label="NCB Clause" name="ncb_clause" required={true} />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <TextArea
              label="Important Notice Description"
              name="important_notice_details"
              required={true}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Radio
              name="status"
              label="Status"
              required
              row
              color="opposite"
              options={statusOptions}
            />
          </Grid>
        </MasterFormWrapper>
      </FormProvider>
    </Navbar>
  );
};

export default PolicyWordings;
