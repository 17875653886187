import { useContext } from "react";
import withRouteProtection, {
  AuthContext,
} from "../../../hoc/withRouteProtection";
import MasterTable from "../../masters/MasterTable/MasterTable";
import { Box } from "@mui/material";
import Navbar from "../../../components/Navbar";

const ICConfigurator = ({ isBrokerLogin }) => {
  const { writable } = useContext(AuthContext);
  return (
    <Navbar>
      <Box>
        <MasterTable
          master_name={"insurance"}
          writable={writable}
          isBrokerLogin={isBrokerLogin}
        />
      </Box>
    </Navbar>
  );
};

export default withRouteProtection(ICConfigurator);
