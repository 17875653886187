import React from "react";
import { styled } from "@mui/material/styles";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { Box } from "@mui/system";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";

const ClaimDetailsFooter = ({ setOpenDetails, openDetails }) => {
  const { getUrlQuery } = useUrlQuery();
  const customer_link = getUrlQuery("customer_link");
  return (
    <DetailsFooterWrapper customer_link={!!customer_link}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginLeft: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 0,
          }}
        ></Box>
        <DetailsButton onClick={() => setOpenDetails(!openDetails)}>
          View Details
          <ChevronRightOutlinedIcon fontSize="small"></ChevronRightOutlinedIcon>
        </DetailsButton>
      </Box>
    </DetailsFooterWrapper>
  );
};

export default ClaimDetailsFooter;

const DetailsFooterWrapper = styled("div")(({ theme, customer_link }) => ({
  position: "fixed",
  //   position: "relative",
  bottom: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  height: "50px",
  width: "100%",
  padding: "5px 20px",
  backgroundColor: "white",
  boxShadow: "0 -3px 6px 0 rgba(0, 0, 0, 0.16)",
  zIndex: 1,
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
  [theme.breakpoints.between("561", "md")]: {
    bottom: !customer_link && "51px",
  },
}));

const DetailsButton = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  padding: "5px 0px 5px 10px",
  borderRadius: "15px",
  backgroundColor: "white",
  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.18)",
  border: "1px solid lightGrey",
  height: "25px",
  margin: "auto 5px",
  color: "#006eff",
  fontSize: "14px",
  cursor: "pointer",
}));
