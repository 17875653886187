import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  IconButton,
  Paper,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import {
  COVER_TYPE_SAOD,
  COVER_TYPE_SATP,
} from "../../../constants/coverTypeSlugs";
import {
  useGetPolicyDetails,
  useGetPolicyDetailsRegistration,
} from "../../../services/claims.service";
import ClaimCommonDocuments from "./ClaimCommonDocuments";

function ClaimSummary({
  showDocumentsection,
  isMobile,
  setOpenDetails,
  openDetails,
}) {
  // props coming from parent, ClaimNotification.js
  const { booking_id, claim_detail_id } = useParams();
  const { policy_details_list } = useGetPolicyDetails(booking_id);

  const { policy_details_list: claim_details } =
    useGetPolicyDetailsRegistration(claim_detail_id);

  const policy_data = policy_details_list[0];
  const workshopDetails = policy_details_list[0]?.workshop_detail;
  const CoverTypeOD = policy_data?.cover_type_slug === COVER_TYPE_SAOD;
  const CoverTypeTP = policy_data?.cover_type_slug === COVER_TYPE_SATP;

  const conditionalClaimStage = () => {
    if (claim_detail_id) {
      return claim_details[0]?.claim_stage;
    } else return policy_details_list[0]?.claim_stage;
  };

  const claimStage = conditionalClaimStage()
    ?.replace(/_/g, " ")
    .replace(/\b\w/g, (c) => c.toUpperCase());

  return (
    <ClaimSummWrapper>
      <Paper
        elevation={0}
        sx={{
          borderRadius: "18px",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
          width: "100%",
        }}
      >
        <Box
          sx={{
            padding: "15px",
          }}
        >
          <ClaimHeading>Policy Details</ClaimHeading>
          <IconButton
            sx={{
              display: { xxs: "flex", md: "none" },
              position: "absolute",
              right: "8px",
              top: "8px",
              marginLeft: "20px",
              cursor: "pointer",
            }}
            onClick={() => setOpenDetails(!openDetails)}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <ClaimSummContent>
            <ContentWrap>
              <Text>Insured Name</Text>
              <Value>{policy_data?.insured_name} </Value>
            </ContentWrap>
            <ContentWrap>
              <Text>Mobile No.</Text>
              <Value>{policy_data?.mobile}</Value>
            </ContentWrap>
            <ContentWrap>
              <Text>Email ID</Text>
              <Value>{policy_data?.email}</Value>
            </ContentWrap>
            <ContentWrap>
              <Text>Policy No.</Text>
              <Value>{policy_data?.policy_no}</Value>
            </ContentWrap>
            <ContentWrap>
              <Text>Policy Issuing Dealer</Text>
              <Value>{policy_data?.dealer_name}</Value>
            </ContentWrap>
            <ContentWrap>
              <Text>Insurance Company</Text>
              <Value>{policy_data?.insurance_name}</Value>
            </ContentWrap>
            <ContentWrap>
              <Text>Cover Type</Text>
              <Value>{policy_data?.cover_type}</Value>
            </ContentWrap>
            {!CoverTypeTP && (
              <ContentWrap>
                <Text>OD Policy Period</Text>
                <Value>
                  {policy_data?.od_policy_start_date} to &nbsp;
                  {policy_data?.od_policy_end_date}
                </Value>
              </ContentWrap>
            )}
            {!CoverTypeOD && (
              <ContentWrap>
                <Text>TP Policy Period</Text>
                <Value>
                  {policy_data?.tp_policy_start_date} to &nbsp;
                  {policy_data?.tp_policy_end_date}
                </Value>
              </ContentWrap>
            )}
            <ContentWrap>
              <Text>64 VB Status</Text>
              <Value>{policy_data?.vb64}</Value>
            </ContentWrap>
            <ContentWrap>
              <Text>Dealer State</Text>
              <Value>{policy_data?.dealer_state_name}</Value>
            </ContentWrap>
            <ContentWrap>
              <Text>Dealer City</Text>
              <Value>{policy_data?.dealer_city_name}</Value>
            </ContentWrap>
            <ContentWrap>
              <Text>Vehicle Class</Text>
              <Value>{policy_data?.segment_name}</Value>
            </ContentWrap>
            <ContentWrap>
              <Text>Model-Variant</Text>
              {policy_data?.variant_name?.length > 25 ? (
                <Tooltip
                  arrow
                  placement="right"
                  title={policy_data?.variant_name}
                >
                  <Value>
                    {policy_data?.variant_name?.slice(0, 20) + "..."}
                  </Value>
                </Tooltip>
              ) : (
                <Value>{policy_data?.variant_name}</Value>
              )}
            </ContentWrap>
            <ContentWrap>
              <Text>Chassis No.</Text>
              <Value>{policy_data?.chassis_no || "---"}</Value>
            </ContentWrap>
            <ContentWrap>
              <Text>Engine No.</Text>
              <Value>{policy_data?.engine_no || "---"}</Value>
            </ContentWrap>
            <ContentWrap>
              <Text>Registration No.</Text>
              <Value>{policy_data?.vehicle_registration_no || "---"}</Value>
            </ContentWrap>
            {claimStage && (
              <ContentWrap>
                <Text>Claim Stage</Text>
                <Value>{claimStage}</Value>
              </ContentWrap>
            )}
            <ContentWrap>
              <Text>Claim Status</Text>
              <Value>{policy_data?.claim_status || "---"}</Value>
            </ContentWrap>
          </ClaimSummContent>
        </Box>
      </Paper>
      {workshopDetails && (
        <>
          <Paper
            elevation={0}
            sx={{
              borderRadius: "18px",
              boxShadow: "0 2px 3px 0 rgba(189, 213, 218, 0.57)",
              width: "100%",
            }}
          >
            <Box
              sx={{
                padding: "15px",
              }}
            >
              <ClaimHeading>Workshop Details</ClaimHeading>
              <ClaimSummContent>
                <ContentWrap>
                  <Text>Workshop Name</Text>
                  <Value>{workshopDetails?.workshop_name || "---"}</Value>
                </ContentWrap>
                <ContentWrap>
                  <Text>Workshop Address</Text>
                  <Value>{workshopDetails?.workshop_address || "---"}</Value>
                </ContentWrap>
                <ContentWrap>
                  <Text>Workshop State</Text>
                  <Value>{workshopDetails?.workshop_state_name || "---"}</Value>
                </ContentWrap>
                <ContentWrap>
                  <Text>Workshop City</Text>
                  <Value>{workshopDetails?.workshop_city_name || "---"}</Value>
                </ContentWrap>
                <ContentWrap>
                  <Text>Workshop Mobile No.</Text>
                  <Value>
                    {workshopDetails?.workshop_mobile_number || "---"}
                  </Value>
                </ContentWrap>
                <ContentWrap>
                  <Text>Communication Mobile No.</Text>
                  <Value>
                    {workshopDetails?.workshop_communication_mobile_number ||
                      "---"}
                  </Value>
                </ContentWrap>
                <ContentWrap>
                  <Text>Communication Email</Text>
                  <Value>
                    {workshopDetails?.workshop_communication_email || "---"}
                  </Value>
                </ContentWrap>
              </ClaimSummContent>
            </Box>
          </Paper>
        </>
      )}
      {showDocumentsection && !isMobile && (
        <Paper
          elevation={0}
          sx={{
            borderRadius: "18px",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
            width: "100%",
            padding: "1.2rem",
          }}
        >
          <ClaimCommonDocuments />
        </Paper>
      )}
    </ClaimSummWrapper>
  );
}

export default ClaimSummary;

const ClaimSummWrapper = styled(Box)(({ theme }) => ({
  width: "450px",
  minWidth: "450px",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  // flex: 1.5,
  position: "sticky",
  // top: "79px",
  [theme.breakpoints.up("lg")]: {
    width: "450px",
    minWidth: "450px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "400px",
    minWidth: "400px",
  },
  [theme.breakpoints.down("xmd")]: {
    width: "350px",
    minWidth: "350px",
  },
  [theme.breakpoints.down("md")]: {
    position: "relative",
    top: "0",
    width: "100%",
    minWidth: "100%",
  },
}));

const ClaimHeading = styled(Typography)({
  fontWeight: "600",
  padding: "0.4rem 0.6rem 0.2rem 0.8rem",
  backgroundImage: "linear-gradient(to right, #03cc83, #fff 30px)",
  borderRadius: "8px",
  opacity: "0.8",
  fontSize: "17px",
});

const ClaimSummContent = styled(Box)({
  padding: "0.8rem",
});

const ContentWrap = styled(Box)({
  display: "flex",
  gap: "10px",
  alignItems: "center",
  justifyContent: "space-between",
});

const Text = styled(Typography)({
  color: "#6f6f6f",
  fontSize: "13px",
  whiteSpace: "nowrap",
  marginBottom: "auto",
});

const Value = styled(Typography)({
  fontSize: "13px",
  fontWeight: "bold",
  textAlign: "right",
});
