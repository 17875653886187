import React, { useContext, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  AtleastOnefieldError,
  ButtonWrapper,
  Papers,
} from "../../masters/components/MasterStyle";
import { FormProvider, useForm } from "react-hook-form";
import {
  Heading,
  HeadingLabel,
} from "../DiscountConfigurator/DiscountConfiguratorStyle";
import withRouteProtection, {
  AuthContext,
} from "../../../hoc/withRouteProtection";
import MasterTable from "../../masters/MasterTable/MasterTable";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import {
  useGetOemList,
  useGetSortedMaster,
} from "../../../services/master.service";
import { activeInactiveOptions } from "../../../constants/options";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../../components/InputComponents/TextInput";
import DateInput from "../../../components/InputComponents/DateInput";
import Select from "../../../components/InputComponents/Select";
import moment from "moment";
import { useLocation } from "react-router";
import { dateFormat } from "../../../utils/minDateForEndDate";
import Buttons from "../../../components/BasicFormComponent/Buttons";
import { ResetButton } from "../../servicing/cancellation/CancellationSearch/CancellationSearchStyle";
import Navbar from "../../../components/Navbar";

function AddOnConfigurator({ values }) {
  const addonConfiguratorSchema = yup.object().shape({
    effective_date_from: yup
      .string()
      // .test("date_range", "Enter valid effective from date", function (date) {
      //   if (!date) return true;
      //   return moment(date, dateFormat).isAfter(moment().subtract(1, "d"));
      // })
      .test(
        "check_dates",
        "Effective from date should be equal or before effective to date",
        function (date) {
          if (!this.parent.effective_date_to) return true;
          return moment(date, dateFormat).isBefore(
            moment(this.parent.effective_date_to, dateFormat).add(1, "day")
          );
        }
      ),
    effective_date_to: yup
      .string()
      .test(
        "check_dates",
        "Effective to date should be equal or after effective from date",
        function (date) {
          if (!this.parent.effective_date_from) return true;
          return moment(date, dateFormat).isAfter(
            moment(this.parent.effective_date_from, dateFormat).subtract(
              1,
              "day"
            )
          );
        }
      ),
  });

  const methods = useForm({
    resolver: yupResolver(addonConfiguratorSchema),

    defaultValues: {
      package_name: "",
      effective_date_from: "",
      effective_date_to: "",
      insurance_company: "",
      segment_id: "",

      oem_id: "",
      status: "",

      ...values,
    },
  });
  //Segment Id in Search
  const { writable } = useContext(AuthContext);
  const { table_list: segment_id } = useGetSortedMaster({
    master_name: "segment",
    sortBy: "segment_name",
  });

  const segment_list = useMemo(() => {
    return segment_id.map((item) => {
      return {
        code: item.segment_id,
        display_name: item.segment_name,
      };
    });
  }, [segment_id]);

  const { table_list: insurance_items } = useGetSortedMaster({
    master_name: "insurance",
    sortBy: "insurance_name",
  });

  const insurance_list = useMemo(() => {
    return insurance_items.map((item) => {
      return {
        code: item.insurance_id,
        display_name: item.insurance_name,
      };
    });
  }, [insurance_items]);

  const { oem_items } = useGetOemList();

  const oem_list = useMemo(() => {
    return oem_items?.map((item) => {
      return {
        code: item.oem_id,
        display_name: item.oem_name,
      };
    });
  }, [oem_items]);

  const [customSearch, setCustomSearch] = useState({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const onSubmit = (data) => {
    const isAnyFieldSelected = Object?.values(data).some((item) => item !== "");
    if (isAnyFieldSelected) {
      setCustomSearch({ ...data });
    } else {
      methods.setError("atleast_one_field", {
        type: "custom",
        message: "Atleast one field is required",
      });
    }
  };

  const handleReset = (e) => {
    if (searchParams.get("customSearch")) {
      searchParams.delete("customSearch");
    }
    setCustomSearch({});
    e.preventDefault();
    methods.reset({
      package_name: "",
      effective_date_from: "",
      effective_date_to: "",
      insurance_company: "",
      segment_id: "",
      oem_id: "",
      status: "",
    });
  };

  const err = methods.formState.errors?.atleast_one_field?.message;

  return (
    <Navbar>
      <Box>
        <Papers elevation={3}>
          <Heading variant="h5" align="left">
            <HeadingLabel variant="h5" align="left">
              Addon Configurator
            </HeadingLabel>
          </Heading>
          {err && <AtleastOnefieldError>{err}</AtleastOnefieldError>}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={1} columnSpacing={3} mt={1}>
                <Grid item xxs={12} xs={6} md={3} lg={4}>
                  <TextInput
                    label="Package Name"
                    name="package_name"
                    // onInput={allowOnlyAlphabetsUnderscoreNumber}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Grid>
                <Grid item xxs={12} xs={6} md={3} lg={4}>
                  <DateInput
                    label="Effective From Date"
                    name="effective_date_from"
                    // minDate={moment()}
                  />
                </Grid>
                <Grid item xxs={12} xs={6} md={3} lg={4}>
                  <DateInput
                    label="Effective To Date"
                    name="effective_date_to"
                    // minDate={moment()}
                  />
                </Grid>
                <Grid item xxs={12} xs={6} md={3} lg={4}>
                  <Select
                    label="Insurance Company"
                    name="insurance_company"
                    options={[...insurance_list]}
                  />
                </Grid>
                <Grid item xxs={12} xs={6} md={3} lg={4}>
                  <Select
                    label="Segment"
                    name="segment_id"
                    options={[...segment_list]}
                  />
                </Grid>
                <Grid item xxs={12} xs={6} md={3} lg={4}>
                  <Select label="OEM" name="oem_id" options={[...oem_list]} />
                </Grid>
                <Grid item xxs={12} xs={6} md={3} lg={4}>
                  <Select
                    label="Status"
                    name="status"
                    options={activeInactiveOptions}
                  />
                </Grid>
              </Grid>
              <ButtonWrapper
                style={{
                  gap: "10px",
                  margin: 0,
                }}
              >
                <Buttons
                  type="submit"
                  label="Search"
                  id="search_button_for_addon_configurator"
                />
                <ResetButton onClick={(e) => handleReset(e)}>
                  <ReplayRoundedIcon />
                  Reset
                </ResetButton>
              </ButtonWrapper>
            </form>
          </FormProvider>
        </Papers>
        <Box>
          <MasterTable
            master_name={"addon_configurator"}
            writable={writable}
            customSearch={customSearch}
          />
        </Box>
      </Box>
    </Navbar>
  );
}
export default withRouteProtection(AddOnConfigurator);
