import * as yup from "yup";
import { documentUploadsLessThan2MBRequired } from "../../../../../../../../utils/Validations/documentUploadsValidations";
import moment from "moment";
import { dateFormat } from "../../../../../../../../utils/minDateForEndDate";

export const claimInvoiceSchema = ({ isPreviousDetailsAvailable }) =>
  yup.object().shape({
    claim_invoice_no: yup
      .string()
      .nullable()
      .required("Claim invoice no. is required"),
    claim_invoice_date: yup
      .string()
      .nullable()
      .required("Claim invoice date is required")
      .test("is-past", "Please enter a valid date", (val) => {
        if (!moment(val, dateFormat).isValid()) return false;
        return moment(val, dateFormat).isSameOrBefore(moment());
      }),
    claim_invoice_amount: yup
      .string()
      .nullable()
      .required("Claim invoice amount is required")
      .test("check_value", "Enter valid amount", function (value) {
        return +value > 0;
      }),
    claim_invoice_document:
      !isPreviousDetailsAvailable && documentUploadsLessThan2MBRequired(),
  });
