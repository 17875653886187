import { alpha, styled } from "@mui/material";
import { BOTTOM_NAVIGATION_HEIGHT } from "../../constants/navbar.constant";

export const BottomNavWrapper = styled("div")({
  position: "fixed",
  bottom: 0,
  height: BOTTOM_NAVIGATION_HEIGHT,
  width: "100%",
  background: "#fff",
  boxShadow:
    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "3",
});
const BASESIZE = 1.4;

export const MainMenuWrapper = styled("div")({
  cursor: "pointer",
  position: "relative",
  "& img": {
    width: "30px",
    height: "30px",
    transition: "all 0.2s ease-in",
    border: "1px solid rgba(147, 147, 147,0.50)",
    borderRadius: "5px",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  "& p": {
    display: "none",
    position: "absolute",
    borderRadius: "6px",
    background: alpha("#000", 0.5),
    color: "white",
    fontSize: "12px",
    padding: "4px",
    left: "50%",
    whiteSpace: "nowrap",
  },
  paddingLeft: "8px",
  paddingRight: "8px",
  "&.menu-active > img": {
    width: `${30 * BASESIZE}px`,
    height: `${30 * BASESIZE}px`,
    transform: "translateY(-25px)",
  },
  "&.menu-active > p ": {
    display: "inline-block",
    transform: "translateX(-50%) translateY(-60px)",
  },
  "&.menu-active-prev-1 > img": {
    width: `${30 * (BASESIZE - (BASESIZE - 1) / 2)}px`,
    height: `${30 * (BASESIZE - (BASESIZE - 1) / 2)}px`,
    transform: "translateY(-18px)",
  },
  "&.menu-active-prev-2 > img": {
    width: `${40 * 1}px`,
    height: `${40 * 1}px`,
    transform: "translateY(-12px)",
  },
  "&.menu-active-next-1 > img": {
    width: `${40 * (BASESIZE - (BASESIZE - 1) / 2)}px`,
    height: `${40 * (BASESIZE - (BASESIZE - 1) / 2)}px`,

    transform: "translateY(-18px)",
  },
  "&.menu-active-next-2 > img": {
    width: `${40 * 1}px`,
    height: `${40 * 1}px`,
    transform: "translateY(-12px)",
  },
  transition: "all 0.2s",
});
export const BackgroundOverlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  backgroundColor: alpha("#000", 0.8),
  width: "100vw",
  height: "100vh",
  zIndex: 3,
});
