import { FormProvider, useForm } from "react-hook-form";
import {
  ButtonWrapper,
  Heading,
  StyledGrid,
  GridChild,
  MasterTableContainer,
} from "../../masters/components/MasterStyle";
import {
  InputContainer,
  SearchContainer,
} from "../cancellation/CancellationSearch/CancellationSearchStyle";
import { Box, IconButton } from "@mui/material";
import Buttons from "../../../components/BasicFormComponent/Buttons";
import { MainContainer, ResetButton } from "../../Claim/claimStyledComponents";
import ReplayIcon from "@mui/icons-material/Replay";
import SelectAutoComplete from "../../../components/BasicFormComponent/SelectAutoComplete";
import MasterTable from "../../masters/MasterTable/MasterTable";
import { useContext, useMemo, useState } from "react";
import withRouteProtection, {
  AuthContext,
} from "../../../hoc/withRouteProtection";
import {
  useGetSortedMaster,
  useSearchCustomerMandateData,
} from "../../../services/master.service";
import { useNavigate } from "react-router";
import { EditOutlined } from "@mui/icons-material";
import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { dateFormat } from "../../../utils/minDateForEndDate";
import Navbar from "../../../components/Navbar";

function DealerMandateSearch() {
  const [searchParam, setSearchParam] = useState({});

  const { writable } = useContext(AuthContext);
  const { table_list: dealer_list } = useGetSortedMaster({
    master_name: "dealer",
    sortBy: "dealer_name",
  });
  const { searchMandateData } = useSearchCustomerMandateData();

  const dealer_group_list = useMemo(() => {
    return dealer_list.map((item) => {
      return {
        code: item.dealer_id,
        display_name: item.dealer_name,
      };
    });
  }, [dealer_list]);

  const customerMandateSchema = yup.object().shape({
    dealer_id: yup.string().required("Dealer name is required"),
  });

  const methods = useForm({
    resolver: yupResolver(customerMandateSchema),
    defaultValues: {
      dealer_id: "",
    },
  });

  const handleReset = () => {
    methods.reset({
      dealer_id: "",
    });
  };
  const navigate = useNavigate();

  const onSubmit = (data) => {
    const newData = {
      dealer_id: data.dealer_id,
    };
    setSearchParam(newData);
    searchMandateData(newData);
  };

  return (
    <Navbar>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MainContainer
            style={{ width: "50%", margin: "30px 30px 15px 30px" }}
          >
            <Heading variant="h5" align="left">
              Customer Mandate Form
            </Heading>
            <SearchContainer>
              <InputContainer>
                <StyledGrid columns={1}>
                  <GridChild>
                    <SelectAutoComplete
                      name={"dealer_id"}
                      label={"Dealer"}
                      variant={"standard"}
                      required={true}
                      options={dealer_group_list}
                    />
                  </GridChild>
                </StyledGrid>
              </InputContainer>
              <Box>
                <ButtonWrapper gap={2} alignItems="center" display={"flex"}>
                  <Buttons label={"Search"} type="submit" standard />

                  <ResetButton onClick={handleReset}>
                    <ReplayIcon />
                    Reset
                  </ResetButton>
                </ButtonWrapper>
              </Box>
            </SearchContainer>
          </MainContainer>
          <MasterTableContainer>
            <MasterTable
              writable={writable}
              master_name={"customer_mandate_form"}
              columnFilters={Object.keys(searchParam).map((item) => ({
                id: item,
                value: searchParam[item],
              }))}
              customRowAction={({ row }) =>
                moment(row?.original?.formatted_created_at).format(
                  dateFormat
                ) === moment().format(dateFormat) ? (
                  <IconButton
                    style={{ margin: "2px" }}
                    onClick={() => {
                      navigate(`./update/${row?.original?.dealer_mandate_id}`);
                    }}
                  >
                    <EditOutlined style={{ fontSize: "15px" }} />
                  </IconButton>
                ) : (
                  <></>
                )
              }
            />
          </MasterTableContainer>
        </form>
      </FormProvider>
    </Navbar>
  );
}

export default withRouteProtection(DealerMandateSearch);
