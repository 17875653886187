import { forwardRef } from "react";
import { MainMenuWrapper } from "../BottomNavigation/BottomNavigation.style";
import { useNavigate } from "react-router";
import downArrow from "../../../../assets/images/downArrow.png";
import SubMenuItems from "../SubMenuItems";
import { IconMap } from "../../constants/navbar.constant";

const MainMenu = forwardRef(
  (
    { menuItem, onMouseEnter, onMouseLeave, openedMenuSlug, setOpenedMenuSlug },
    ref
  ) => {
    const navigate = useNavigate();
    return (
      <>
        <MainMenuWrapper
          id={menuItem?.menu_slug}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          ref={ref}
          onClick={() => {
            if (menuItem?.child.length) {
              if (openedMenuSlug === menuItem?.menu_slug) {
                setOpenedMenuSlug("");
              } else setOpenedMenuSlug(menuItem?.menu_slug);
            } else {
              setOpenedMenuSlug("");
              navigate(`/${menuItem?.menu_slug}`);
            }
          }}
        >
          {openedMenuSlug === menuItem?.menu_slug ? (
            <>
              <img src={downArrow} alt={menuItem?.menu_name} />
              <SubMenuItems
                list={menuItem?.child}
                parent={menuItem?.menu_slug}
              />
            </>
          ) : (
            <>
              <p>{menuItem?.menu_name}</p>
              <img
                src={IconMap[menuItem?.menu_slug]}
                alt={menuItem?.menu_name}
              />
            </>
          )}
        </MainMenuWrapper>
      </>
    );
  }
);

export default MainMenu;
