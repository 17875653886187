import React, { Fragment, useEffect, useMemo } from "react";
import {
  // Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import MasterFormWrapper from "../../../../masters/components/MasterFormWrapper/MasterFormWrapper";
import ICFormStepper from "../../components/ICFormStepper";
import { Box, Grid, IconButton } from "@mui/material";
import TextInput from "../../../../../components/InputComponents/TextInput";
import { allowOnlyNumbers } from "../../../../../InputUtils/onInput";
import { useGetSortedMaster } from "../../../../../services/master.service";
import MultiSelect from "../../../../../components/InputComponents/MultiSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICConfigPACoverValidationSchema } from "./ICConfigPACoverValidation";
import { useSendIcDetails } from "../../../../../services/icConfigurator.service";
import ClearIcon from "@mui/icons-material/Clear";
import {
  ResetButton,
  SubmitButton,
} from "../../../../../components/InputComponents/InputComponents.style";
import { useNavigate, useParams } from "react-router";
import {
  getActiveStep,
  stepsOtherThanRoot,
} from "../../../../../constants/icConfigConstants";

const ICConfigPACover = ({ values, isBrokerLogin }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const activeStep = getActiveStep();
  const nextPath = stepsOtherThanRoot[1 + activeStep]?.path;
  const url = `/ic_configurator/ic_onboard/update/${id + nextPath}`;

  const handleSkip = () => navigate(url);

  const { table_list: segment_items } = useGetSortedMaster({
    master_name: "segment",
    sortBy: "segment_name",
  });
  const segment_list = useMemo(() => {
    return segment_items?.map((item) => {
      return {
        code: item?.segment_slug?.toLowerCase(),
        display_name: item?.segment_name,
      };
    });
  }, [segment_items]);

  const methods = useForm({
    resolver: yupResolver(ICConfigPACoverValidationSchema),
    defaultValues: {
      pa_cover: [
        {
          premium_total: "",
          segment_slug: [],
        },
      ],
      ...values,
    },
  });

  useEffect(() => {
    methods.reset({
      pa_cover: [
        {
          premium_total: "",
          segment_slug: [],
        },
      ],
      ...values,
    });
  }, [JSON.stringify(values)]);
  const { fields, append, remove } = useFieldArray({
    control: methods?.control,
    name: "pa_cover",
  });
  const { sendIcDetails, isSuccess, alertMaster, resetMaster, isError } =
    useSendIcDetails("ic_pa_cover");

  const onSubmit = (data) => {
    sendIcDetails(data);
  };

  const pa_cover = methods.watch("pa_cover");
  const slugsSelectedInPACover = pa_cover
    .map((item) => item.segment_slug)
    .flat();
  const showAddMore = slugsSelectedInPACover.length <= segment_list.length;
  return (
    <FormProvider {...methods}>
      <MasterFormWrapper
        title="Insurance Company Details"
        alert={alertMaster}
        reset={resetMaster}
        isError={isError}
        isSuccess={isSuccess}
        description={"CPA Cover Details"}
        onSubmit={onSubmit}
        additionalContent={<ICFormStepper isBrokerLogin={isBrokerLogin} />}
        showSkipButton
        customSubmission
      >
        <Grid container spacing={2} paddingLeft={1} paddingTop={1}>
          {fields.map((item, index) => {
            let select_list = segment_list;
            for (let i = 0; i < index; i++) {
              select_list = select_list?.filter((segment) => {
                return !(pa_cover[i]?.segment_slug || [])?.some(
                  (select) => select === segment?.code
                );
              });
            }

            return (
              <Fragment key={item.id}>
                <Grid item xxs={12} xs={6} md={6} lg={5}>
                  <MultiSelect
                    key={item.id}
                    label={`Segment ${index + 1} Name`}
                    name={`pa_cover.${index}.segment_slug`}
                    required={true}
                    options={select_list}
                    readOnly={isBrokerLogin}
                  />
                </Grid>
                <Grid item xxs={12} xs={6} md={6} lg={5}>
                  <TextInput
                    label="Price"
                    name={`pa_cover.${index}.premium_total`}
                    required={true}
                    onInput={allowOnlyNumbers}
                    inputProps={{ maxLength: 10 }}
                    readOnly={isBrokerLogin}
                  />
                </Grid>
                <Box>
                  <IconButton onClick={() => remove(index)}>
                    <ClearIcon
                      style={{ fontSize: "12px", marginTop: "12px" }}
                    />
                  </IconButton>
                </Box>
              </Fragment>
            );
          })}
        </Grid>
        <Grid
          display="flex"
          gap="15px"
          item
          xs={12}
          justifyContent={"flex-end"}
        >
          {showAddMore && (
            <ResetButton
              onClick={() =>
                append({
                  segment_slug: [],
                  premium_total: "",
                })
              }
            >
              Add More
            </ResetButton>
          )}
          {id && <ResetButton onClick={handleSkip}>Skip</ResetButton>}
          <SubmitButton>Submit</SubmitButton>
        </Grid>
      </MasterFormWrapper>
    </FormProvider>
  );
};

export default ICConfigPACover;
