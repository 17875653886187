import React, { useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import error from "../assets/images/500Error.png";
import { useRouteError } from "react-router-dom";
import Navbar from "./Navbar";

const Error = () => {
  const err = useRouteError();
  useEffect(() => {
    if (err?.message && /Loading chunk [\d]+ failed/.test(err.message)) {
      window.location.reload();
    }
  }, [err?.message]);

  return (
    <Navbar>
      <Box height="95vh">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Box sx={{ width: "40%" }}>
            <img src={error} alt="errorImg" />
          </Box>
          <Box sx={{ paddingLeft: "60px" }}>
            <Typography variant="h3" fontWeight="bold">
              Something went wrong
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <Typography variant="subtitle1" color="#4f6781">
                {err?.message}
              </Typography>
              <Button
                variant="contained"
                sx={{ width: "180px" }}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Retry
              </Button>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Navbar>
  );
};

export default Error;
