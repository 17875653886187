import CloseIcon from "@mui/icons-material/Close";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { Box, Button, Paper, styled } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { SubmitButton } from "../../../../../components/InputComponents/InputComponents.style";
import { ButtonWrapper } from "../../../../masters/components/MasterStyle";
import { SumInsuredEditButton } from "../../../../quotes/component/QuoteCard/QuoteCardStyle";
import SurveyorDetailsForm from "../../../AppointSurveyor/components/SurveyorDetailsForm";
import AccidentDetails from "../../../ClaimDetails/ClaimDeatilsView/components/AccidentDetails";
import ClaimApprovalDetails from "../../../ClaimDetails/ClaimDeatilsView/components/ClaimApprovalDetails";
import ClaimInvoiceDetails from "../../../ClaimDetails/ClaimDeatilsView/components/ClaimInvoiceDetails";
import ClaimSettlementDetails from "../../../ClaimDetails/ClaimDeatilsView/components/ClaimSettlementDetails";
import ContactDetails from "../../../ClaimDetails/ClaimDeatilsView/components/ContactDetails";
import DeliveryOrderDetails from "../../../ClaimDetails/ClaimDeatilsView/components/DeliveryOrderDetails";
import DriverDetails from "../../../ClaimDetails/ClaimDeatilsView/components/DriverDetails";
import EstimationDetails from "../../../ClaimDetails/ClaimDeatilsView/components/EstimationDetails";
import ProformaInvoiceDetails from "../../../ClaimDetails/ClaimDeatilsView/components/ProformaInvoiceDetails";
import SurveyorAssessmentDetails from "../../../ClaimDetails/ClaimDeatilsView/components/SurveyorAssessmentDetails";
import SurveyorDetails from "../../../ClaimDetails/ClaimDeatilsView/components/SurveyorDetails";
import ClaimCommonDocuments from "../../../components/ClaimCommonDocuments";
import { useClaimStage } from "../../../hooks/useClaimStage";
import {
  ClaimHeading,
  ClaimSummaryTitle,
  EditButtonContainer,
  MainPaperContainer,
} from "../../../styles/claim.styles";
import AccidentDetailsForm from "./forms/AccidentDetailsForm";
import ClaimStatusForm from "./forms/ClaimStatusForm";
import ContactDetailsForm from "./forms/ContactDetailsForm";
import ClaimInvoiceFormFields from "./forms/DocumentForm/components/ClaimInvoiceFormFields";
import DeliveryOrderFormFields from "./forms/DocumentForm/components/DeliveryOrderFormFields";
import ProformaFormFields from "./forms/DocumentForm/components/ProformaFormFields";
import DriverDetailsForm from "./forms/DriverDetailsForm";
import EstimationForm from "./forms/EstimationForm";
import SurveyorAssesmentFormFields from "./forms/SurveyorAssessmentForm/SurveyorAssessmentFormFields";

function RegistrationAddForm({
  isMobile,
  alertMsg,
  claimAdminUser,
  bookingData,
  showEditableFields,
  setShowEditableFields,
  isSuccessForEdit,
  isErrorForEdit,
  alertMasterForEdit,
  resetMasterForEdit,
  claimStage,
  claimDetails,
}) {
  const { reset } = useFormContext();
  // Already multiple props are passed hence using useClaimStage repeatedly here rather than passing props
  const {
    component_visibility_conditions: {
      showClaimIntimationDetails,
      showClaimRegistrationDetails,
      showSurveyorAppointmentDetails,
      showSurveyorAssessmentDetails,
      showProformaInvoiceDetails,
      showClaimHoldStatusForm,
      showClaimApprovalDetails,
      showDeliveryOrderDetails,
      showClaimInvoiceDetails,
      showClaimSettlementDetails,
    },
    roles: { isDealer },
  } = useClaimStage({
    claim_stage: claimStage,
  });

  const handleReset = () => {
    reset({
      driver_age: "",
      license_no: "",
      license_type: "",
      dealer_state: "",
      place_of_issue_city_id: "",
      valid_upto: "",
      labour_cost: "",
      parts_cost: "",
      metal_cost: "",
      paint_cost: "",
      total_other_cost: "",
      registration_certificate: "",
      driving_license: "",
      estimated_certificate: "",
      fir_certificate: "",
      other_certificate: "",
      pincode_of_accident: "",
      accident_state: "",
      accident_city: "",
      place_of_accident: "",
      customer_current_mobile_no: "",
      customer_current_email_id: "",
      driver_name: "",
      description_of_accident: "",
      idv: bookingData?.idv || "",
      vehicle_premium: bookingData?.total_premium || "",
      voluntary_deductible: bookingData?.voluntary_access_foramount || "",
      total_estimation: "0",
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {/* TODO: separate this into a component */}
      {isDealer && (
        <>
          <MainPaperContainer>
            <ClaimSummaryTitle>Claim Intimation Details</ClaimSummaryTitle>
            <ClaimHeading>Accident Details</ClaimHeading>
            <AccidentDetailsForm />

            {/* contact details */}
            <Box mt={2}>
              <ClaimHeading>Contact Details</ClaimHeading>
              <ContactDetailsForm />
            </Box>
          </MainPaperContainer>

          <MainPaperContainer>
            <ClaimSummaryTitle>Claim Registration Details</ClaimSummaryTitle>
            {/* driver details */}
            <ClaimHeading>Driver Details</ClaimHeading>
            <DriverDetailsForm />

            {/* estimation details */}
            <Box mt={2}>
              <ClaimHeading>Estimation Details</ClaimHeading>
              <EstimationForm />
            </Box>
          </MainPaperContainer>
        </>
      )}

      {claimAdminUser && !showClaimSettlementDetails && (
        <EditButtonContainer
          onClick={() => setShowEditableFields(!showEditableFields)}
        >
          {!showEditableFields && (
            <SumInsuredEditButton sx={{ fontSize: "20px" }} />
          )}
          {showEditableFields && <CloseIcon sx={{ fontSize: "20px" }} />}
        </EditButtonContainer>
      )}

      {/* accident details */}
      {showClaimIntimationDetails && !isDealer && (
        <MainPaperContainer>
          <ClaimSummaryTitle>Claim Intimation Details</ClaimSummaryTitle>
          <ClaimHeading>Accident Details</ClaimHeading>
          {showEditableFields ? (
            <AccidentDetailsForm />
          ) : (
            <AccidentDetails claimDetails={claimDetails} />
          )}

          {/* contact details */}
          <Box mt={2}>
            <ClaimHeading>Contact Details</ClaimHeading>
            {showEditableFields ? (
              <ContactDetailsForm />
            ) : (
              <ContactDetails claimDetails={claimDetails} />
            )}
          </Box>
        </MainPaperContainer>
      )}

      {showClaimRegistrationDetails && (
        <MainPaperContainer>
          <ClaimSummaryTitle>Claim Registration Details</ClaimSummaryTitle>
          {/* driver details */}
          <ClaimHeading>Driver Details</ClaimHeading>
          {showEditableFields ? (
            <DriverDetailsForm />
          ) : (
            <DriverDetails claimDetails={claimDetails} />
          )}

          {/* estimation details */}
          <Box mt={2}>
            <ClaimHeading>Estimation Details</ClaimHeading>
            {showEditableFields ? (
              <EstimationForm />
            ) : (
              <EstimationDetails claimDetails={claimDetails} />
            )}
          </Box>
        </MainPaperContainer>
      )}

      {/* surveyor details */}
      {showSurveyorAppointmentDetails && (
        <MainPaperContainer>
          <ClaimHeading>Surveyor Appointment Details</ClaimHeading>
          {showEditableFields ? (
            <SurveyorDetailsForm />
          ) : (
            <SurveyorDetails claimDetails={claimDetails} />
          )}
        </MainPaperContainer>
      )}

      {/* surveyor assesment details */}
      {showSurveyorAssessmentDetails && (
        <MainPaperContainer>
          <ClaimHeading>Surveyor Assesment Details</ClaimHeading>
          {showEditableFields ? (
            <SurveyorAssesmentFormFields />
          ) : (
            <SurveyorAssessmentDetails claimDetails={claimDetails} />
          )}
        </MainPaperContainer>
      )}

      {/* proforma invoice details */}
      {showProformaInvoiceDetails && (
        <>
          {showEditableFields ? (
            <MainPaperContainer>
              <ClaimHeading>Proforma Invoice</ClaimHeading>
              <ProformaFormFields />
            </MainPaperContainer>
          ) : (
            <ProformaInvoiceDetails claimDetails={claimDetails} />
          )}
        </>
      )}

      {showClaimApprovalDetails && (
        <ClaimApprovalDetails claimDetails={claimDetails} />
      )}

      {/* delivery order details */}
      {showDeliveryOrderDetails && (
        <>
          {showEditableFields ? (
            <MainPaperContainer>
              <ClaimHeading>Delivery Order</ClaimHeading>
              <DeliveryOrderFormFields />
            </MainPaperContainer>
          ) : (
            <DeliveryOrderDetails claimDetails={claimDetails} />
          )}
        </>
      )}

      {/* claim invoice details */}
      {showClaimInvoiceDetails && (
        <>
          {showEditableFields ? (
            <MainPaperContainer>
              <ClaimHeading>Claim Invoice Details</ClaimHeading>
              <ClaimInvoiceFormFields />
            </MainPaperContainer>
          ) : (
            <ClaimInvoiceDetails claimDetails={claimDetails} />
          )}
        </>
      )}

      {/* claim settlement details */}
      {showClaimSettlementDetails && (
        <ClaimSettlementDetails claimDetails={claimDetails} />
      )}

      {claimAdminUser && showClaimHoldStatusForm && (
        <ClaimStatusForm
          isSuccessForEdit={isSuccessForEdit}
          isErrorForEdit={isErrorForEdit}
          alertMasterForEdit={alertMasterForEdit}
          resetMasterForEdit={resetMasterForEdit}
        />
      )}

      {isMobile && (
        <Paper
          elevation={0}
          sx={{
            borderRadius: "18px",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
            height: "fit-content",
            padding: "1.2rem",
            margin: "15px 0",
          }}
        >
          <ClaimCommonDocuments />
        </Paper>
      )}
      {!showClaimSettlementDetails && (
        <ClaimButtonWrapper>
          <SubmitButton
            variant="contained"
            type="submit"
            disabled={alertMsg?.isLoading || alertMsg?.isSuccess}
          >
            Submit
          </SubmitButton>
          {claimAdminUser ? (
            <></>
          ) : (
            <ResetButton id="reset" onClick={handleReset}>
              <ReplayRoundedIcon fontSize="medium" color="#475f63" />
              Reset
            </ResetButton>
          )}
        </ClaimButtonWrapper>
      )}
    </Box>
  );
}

export default RegistrationAddForm;

const ClaimButtonWrapper = styled(ButtonWrapper)({
  display: "flex",
  gap: "10px",
  justifyContent: "center",
  alignItems: "center",
});

const ResetButton = styled(Button)({
  color: "#475f63",
  display: "flex",
  gap: "0px",
  fontSize: "14px",
  textTransform: "capitalize",
  padding: "8px 0px !important",
  ":active": {
    border: "none",
    backgroundColor: "#fff",
  },
});

ResetButton.defaultProps = {
  color: "secondary",
  size: "large",
};
