import React from "react";
import { capitalizeAndReplaceUnderscore } from "../../../../../utils/underscoreToSpaceSeparation";
import { StyledGrid } from "../../../../masters/components/MasterStyle";
import {
  SummaryChild,
  SummaryHeading,
  SummarySubContainer,
  SummarySubHeading,
} from "../../../../summary/summarystyle";
import { DocumentWrapper } from "../../../../servicing/cancellation/CancellationApproval/CancellationApprovalStyles";

function SurveyorAssessmentDetails({ claimDetails }) {
  return (
    <SummarySubContainer>
      <StyledGrid container columns={12} spacing={0}>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Loss Type</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {capitalizeAndReplaceUnderscore(claimDetails?.assessed_loss_type)}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">IDV</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.idv}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Vehicle Premium</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.vehicle_premium}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Voluntary Deductible
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.assessed_voluntary_deductible}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Labour Cost</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.assessed_labour_cost}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Part Cost</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.assessed_parts_cost}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Metal Cost</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.assessed_metal_cost}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Paint Cost</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.assessed_paint_cost}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Total Other Cost</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.assessed_total_other_cost}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Total Estimation</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.assessed_total_estimation}
          </SummarySubHeading>
        </SummaryChild>
        {claimDetails?.assessed_document && (
          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">
              Surveyor Assessment Report
            </SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              <a
                rel="noreferrer"
                target={"_blank"}
                href={claimDetails?.assessed_document}
                style={{ color: "#394d51" }}
              >
                <DocumentWrapper>View</DocumentWrapper>
              </a>
            </SummarySubHeading>
          </SummaryChild>
        )}
      </StyledGrid>
    </SummarySubContainer>
  );
}

export default SurveyorAssessmentDetails;
