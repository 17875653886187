import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import api from "../../api/api";
import Buttons from "../../components/BasicFormComponent/Buttons";
import withRouteProtection from "../../hoc/withRouteProtection";
import { downloadFile } from "../../utils/downloadFile";
import MasterTable from "../masters/MasterTable/MasterTable";
import {
  ButtonWrapper,
  GridChild,
  StyledGrid,
} from "../masters/components/MasterStyle";
import { CardHeading, MainContainer } from "./ReportsStyled";
import DateInput from "../../components/InputComponents/DateInput";
import Navbar from "../../components/Navbar";

function DealerReport() {
  const today = moment().format("MM-YYYY");
  const [searchParam, setSearchParam] = useState({ report_month: today });

  const BookingDateSchema = yup.object().shape({
    report_month: yup.string().required("Report Month is required"),
  });
  const methods = useForm({
    resolver: yupResolver(BookingDateSchema),
    defaultValues: {
      report_month: today,
    },
  });
  const queryClient = useQueryClient();
  const onSubmit = (data) => {
    setSearchParam(data);
  };

  return (
    <Navbar>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MainContainer>
            <CardHeading>Dealer Invoicing</CardHeading>
            <StyledGrid spacing={1} columns={6} columnSpacing={2}>
              <GridChild>
                <DateInput
                  name="report_month"
                  variant="standard"
                  disableFuture={true}
                  views={["year", "month"]}
                  inputFormat="MMMM-YYYY"
                  outputFormat="MM-YYYY"
                />
              </GridChild>
              <GridChild>
                <ButtonWrapper sx={{ mt: -1.5 }}>
                  <Buttons label={"Search"} type="submit" standard />
                </ButtonWrapper>
              </GridChild>
            </StyledGrid>
          </MainContainer>
          <Box>
            <MasterTable
              master_name={"dealer_invoicing"}
              customSearch={searchParam}
              searchParam={searchParam}
              cellModifier={(cellData) => {
                if (cellData.type === "href") {
                  return {
                    Cell: ({ row }) => {
                      const apiURL = "/dealer_invoicing/invoice";
                      const dealerId = row?.original?.dealer_id;

                      const data = {
                        id: dealerId,
                        custom_search: {
                          report_month: searchParam.report_month,
                        },
                      };
                      return (
                        <Button
                          onClick={async () => {
                            const res = await api.post(apiURL, data);
                            queryClient.invalidateQueries([
                              "dealer_invoicing/invoice",
                            ]);
                            if (res?.data?.status === 200) {
                              downloadFile(res?.data?.PolicyPdf, false, true);
                            }
                          }}
                        >
                          Generate PDF
                        </Button>
                      );
                    },
                  };
                }
              }}
            />
          </Box>
        </form>
      </FormProvider>
    </Navbar>
  );
}

export default withRouteProtection(DealerReport);
