import moment from "moment";

export const toCapitalize = (e) => {
  const cursorPosition = e.target.selectionStart;
  e.target.value = e.target.value[0].toUpperCase() + e.target.value.slice(1);
  e.target.setSelectionRange(cursorPosition, cursorPosition);
};

// Capitalize the letter if it's the first letter or after a space

export const capitalizeWordsAfterSpace = (e) => {
  const cursorPosition = e.target.selectionStart;
  const inputValue = e.target.value;

  const words = inputValue.split(" ");

  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      return word[0].toUpperCase() + word.slice(1);
    } else {
      return "";
    }
  });

  const newValue = capitalizedWords.join(" ");
  e.target.value = newValue;
  e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allCapitalize = (e) => {
  const cursorPosition = e.target.selectionStart;
  e.target.value = e.target.value.toUpperCase();
  e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyNumbers = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyNumbersAndSlash = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^0-9/]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyPincode = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^4]{1}[0-9]{6}/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyNumbersWithDecimal = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  const [num, ...remaining] = e.target.value.split(".");
  const decimalpart = remaining.join("");
  if (decimalpart || e.target.value.includes(".")) {
    e.target.value = num + "." + decimalpart;
  }
  e.target.value = e.target.value.replace(/[^0-9.]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

const decimalAfterFirstIndex = /^([0-9]{1})([0-9]{3}$)$/;

export const allowOnlyNumbersWithDecimalAfterFirstIndex = (e) => {
  let value = e.target.value;
  if (!decimalAfterFirstIndex.test(value)) {
    value = value.substring(0, value.length);
  }
  e.target.value = value.replace(decimalAfterFirstIndex, (match, ...group) => {
    const [first, second] = group;
    let decimalvalue = "";
    if (!first) return decimalvalue;

    if (!second || first.length < 1) {
      return first;
    } else {
      decimalvalue = first;
      for (let i = 1; i < 2; i++) {
        if (group[i]) {
          decimalvalue = decimalvalue + "." + group[i];
        }
      }
      return decimalvalue;
    }
  });
};

export const isDigits = (str = "") => !!str.match(/^[0-9]*$/);

export const allowOnlyAlphabets = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-z]/gi, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};
export const allowOnlyAlphabetsSpace = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-z\s]/gi, "");
  e.target.value = e.target.value.replace("  ", " ");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};
export const allowOnlyAlphabetsSpaceUnderscore = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-z,_\s]/gi, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};
export const allowOnlyAlphabetsNumberSpaceHyphen = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s-]/gi, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};
export const allowOnlyAlphabetsNumberSpaceHyphenDot = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9 -.]/gi, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};
export const allowOnlyAlphabetsNumberSpace = (e) => {
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/gi, "");
};
export const allowOnlySlug = (e) => {
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9_]/gi, "");
};
export const allowOnlyAlphabetsUnderScore = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-z,_]/gi, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};
export const allowOnlyUniqueAlphabetsNoSpace = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  let last = e.target.value[e.target.value.length - 1];
  e.target.value = e.target.value.replace(/([a-zA-Z])\1\1/gi, last + last);
  if (last === " ") {
    e.target.value = e.target.value.replace("  ", " ");
  }
  if (last) {
    e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
  }
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyAlphabetsUnderscoreNumber = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9_]/g, "");
  e.target.setSelectionRange(cursorPosition, cursorPosition);
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyCapitalizeAlphaNumeric = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.toUpperCase();
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyAplhabetNumber = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

// export const alphaNumericInCaps = (e) => {
//   e.target.value = e.target.value.replace(/[^A-Z0-9]/g, "");
// };

export const allowOnlyAplhabetNumberUnderscore = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9,_]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyAplhabetNumberSpaceAndLimitedSpecialCharacters = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9',-.&\s]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyAplhabetNumberHyphen = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.toUpperCase();
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9,-]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const addressValidationOnly = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  let last = e.target.value[e.target.value.length - 1];
  if (last === " ") {
    e.target.value = e.target.value.replace("  ", " ");
  }
  if (last) {
    e.target.value = e.target.value.replace(/[^a-zA-Z0-9,()/.'\s-]/g, "");
  }
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9,()/.'\s-]/g, "");

  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const ifsc = /^([a-zA-Z]{4}[0]{1})([0-9a-zA-Z]{6})$|^$/;

export const allowOnlyAlphabetsSpaceFirstLetterCapital = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-z\s]/gi, "");
  e.target.value = e.target.value
    .replace(/\s{2,}/g, " ")
    .replace(/(?:^|\s)\S/g, (c) => c.toUpperCase());
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else {
    e.target.setSelectionRange(cursorPosition, cursorPosition);
  }
};

export const driving_license =
  /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;

export const validateDateRange = (value) => {
  return moment().diff(moment(value, "DD/MM/YYYY"), "days");
};

const registrationNumberPattern =
  /(^[A-Z]{0,2})([0-9]{0,2})([A-Z]{0,3})([0-9]{0,4}$)$/;
const nonMaskingRegNoPattern =
  /(^[A-Z]{2})([0-9]{1,2})([A-Z]{0,3})([0-9]{4}$)$/;
const startsWithNum = /^\d+/;
const bhSeriesPattern = /^([2-9]{1}[1-9]{0,1})([0-9]{0,4})([A-Z]{0,2}$)$/;
const militaryVehiclePattern =
  /(^[0-9]{0,2})(A|B|C|D|E|X|)([0-9]{0,6})([A-Z]{0,1}$)$/;

const allowBhSeries = (e) => {
  let value = e.target.value.replace(/(^\d{1,2})(BH|B|)/, (match, ...group) => {
    return group[0];
  });
  if (!bhSeriesPattern.test(value)) {
    value = value.substring(0, value.length - 1);
  }
  e.target.value = value.replace(bhSeriesPattern, (match, ...group) => {
    const [first, second, third] = group;
    let regNo = first;
    if (!first) return regNo;
    if (first.length < 2) {
      regNo = first;
      return first;
    }
    if (first.length == 2) {
      regNo = first + "BH";
    }
    if (first && second) {
      regNo = regNo + "-" + second;
    }
    if (second && third) {
      regNo = regNo + "-" + third;
    }
    if (
      (!e.target.value.includes("BH") || first.length < 2) &&
      e.target.value.length > 2
    )
      return "";
    return regNo;
  });
};
const allowNormalRegNo = (e) => {
  let value = e.target.value;

  if (!registrationNumberPattern.test(value)) {
    value = value.substring(0, value.length - 1);
  }
  e.target.value = value.replace(
    registrationNumberPattern,
    (match, ...group) => {
      const [first, second] = group;
      let regNo = "";
      if (!first) return regNo;

      if (!second || first.length < 2) {
        return first;
      } else {
        regNo = first;
        for (let i = 1; i < 4; i++) {
          if (group[i]) {
            regNo = regNo + "-" + group[i];
          }
        }
        return regNo;
      }
    }
  );
};
export const allowRegistrationNumber = (e) => {
  e.target.value = e.target.value.replace(/-/g, "").toUpperCase();

  if (startsWithNum.test(e.target.value)) {
    allowBhSeries(e);
  } else {
    allowNormalRegNo(e);
  }
};
export const allowMilitaryVehicle = (e) => {
  let value = (e.target.value = e.target.value.replace(/-/g, "").toUpperCase());
  if (!militaryVehiclePattern.test(value)) {
    value = value.substring(0, value.length - 1);
  }

  e.target.value = value.replace(militaryVehiclePattern, (match, ...group) => {
    const [first, second, third, fourth] = group;
    let regNo = first;
    if (!first) return regNo;
    if (first.length === 2) {
      regNo = first + "-";
    }
    if (first && second) {
      regNo = first + "-" + second;
    }
    if (second && third) {
      regNo = regNo + "-" + third;
    }
    if (third && fourth) {
      regNo = regNo + "-" + fourth;
    }
    return regNo;
  });
};
export const onPasteRegistrationNumber = (e) => {
  e.preventDefault();
  let value = e.clipboardData.getData("text");
  value = value.replace(/-/g, "").toUpperCase();
  if (startsWithNum.test(value)) {
    if (!/^([2-9]{1}[1-9]{1}BH)([0-9]{0,4})([A-Z]{0,2}$)$/.test(value)) {
      e.target.value = "";
      return;
    }
    e.target.value = value.replace(
      /^([2-9]{1}[1-9]{1}BH)([0-9]{0,4})([A-Z]{0,2}$)$/,
      (match, ...group) => {
        const [first, second, third] = group;

        let regNo = first;
        if (!first) return regNo;
        if (first.length < 2) {
          regNo = first;
          return first;
        }
        if (first.length == 2) {
          regNo = first + "BH";
        }
        if (first && second) {
          regNo = regNo + "-" + second;
        }
        if (second && third) {
          regNo = regNo + "-" + third;
        }
        return regNo;
      }
    );
  } else {
    if (!nonMaskingRegNoPattern.test(value)) {
      e.target.value = "";
      return;
    }
    e.target.value = value.replace(
      nonMaskingRegNoPattern,
      (match, ...group) => {
        let res = group[0] || "";
        if (group[0])
          for (let i = 1; i < 4; ++i) {
            if (group[i]) {
              res = res + "-" + group[i];
            }
          }
        return res;
      }
    );
  }
};

// write a function to allow only alphabets number , plus , minus , space , roundbrackets

export const allowOnlyAlphabetsNumberSpaceRoundBrackets = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ()+-]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyAlphabetsNumberSpecialcharacterCatastrophic = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9 _()+-@!#%^&<>|]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const scrollToError = (errors, isNamePassed) => {
  if (isNamePassed) {
    const el = document.querySelector(`[id=${errors}]`);
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    return;
  }

  const firstError = Object.keys(errors)[0] || "";
  const el = document.querySelector(`[name="${firstError}"]`);

  if (el) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};
export const allowOnlyAlphabetsWithSpecialcharacter = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-z0-9,.'&\s]/gi, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyAlphabetsNumberSpecialcharacterForEmail = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9.@_-]/gi, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyMathFormula = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9()*+-/\s]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyNumbersAndComma = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^0-9,]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyAplhabetNumberComma = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9,]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyAplhabetNumberHyphenDots = (e) => {
  const cursorPosition = e.target.selectionStart;
  const prevLength = e.target.value.length;
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9.-\s]/g, "");
  const newLength = e.target.value.length;
  if (prevLength !== newLength) {
    e.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
  } else e.target.setSelectionRange(cursorPosition, cursorPosition);
};
