/*

Used to set pincode error if value is present & state-city data is not available. Also, Clears error for valid pincode with state & city data

1. pincode: formvalue of pincode field i.e. watch('pincode')
2. state_list: state data returned from api response
3. city_list: city data returned from api response

*/
import { useEffect } from "react";

export const usePincodeValidations = ({
  pincode_field_name,
  pincode,
  state_list,
  city_list,
  formMethods,
  isSuccess,
}) => {
  const { setError, clearErrors } = formMethods;

  useEffect(() => {
    const stateAndCityAvailable =
      state_list?.length > 0 && city_list?.length > 0;
    if (isSuccess) {
      if (!stateAndCityAvailable) {
        setError(`${pincode_field_name}`, {
          type: "custom",
          message: "Enter valid pincode",
        });
      }
    } else {
      if (stateAndCityAvailable) {
        clearErrors(`${pincode_field_name}`);
      }
    }
  }, [
    pincode,
    JSON.stringify(state_list),
    JSON.stringify(city_list),
    isSuccess,
  ]);
};
