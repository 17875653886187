import { IcDealerMapTableMainContainer } from "../IcDealerMappingStyled";
import { Skeleton } from "@mui/material";

function IcDealerMappingSkeleton() {
  return (
    <IcDealerMapTableMainContainer>
      <Skeleton animation="wave" height={"50px"} />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </IcDealerMapTableMainContainer>
  );
}

export default IcDealerMappingSkeleton;
