import { FormProvider, useForm } from "react-hook-form";
import MasterFormWrapper from "../../../../masters/components/MasterFormWrapper/MasterFormWrapper";
import { useSendIcDetails } from "../../../../../services/icConfigurator.service";
import { Grid } from "@mui/material";
import ICFormStepper from "../../components/ICFormStepper";
import { useEffect } from "react";
import { allowOnlyNumbers } from "../../../../../InputUtils/onInput";
import TextInput from "../../../../../components/InputComponents/TextInput";
import { icConfigMiscellaneousMappingsSchema } from "./IcConfigMiscellaneousValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import Radio from "../../../../../components/InputComponents/Radio";
import {
  statusOptions,
  statusOptions2,
} from "../../../../../constants/options";
import {
  MiscellaneousFieldsContainer,
  MiscellaneousFieldsNotes,
  MiscellaneousNoteContainer,
} from "../../IcConfiguratorStyle";

function IcConfigMiscellaneousMappings({ values, isBrokerLogin }) {
  const methods = useForm({
    resolver: yupResolver(icConfigMiscellaneousMappingsSchema),
    defaultValues: {
      status: "Y",
      allow_raise_discount: "Y",
      ...values,
    },
  });

  useEffect(() => {
    methods.reset({ ...values });
  }, [values]);

  const { sendIcDetails, isSuccess, alertMaster, resetMaster, isError } =
    useSendIcDetails("ic_miscellaneous_mapping");

  const onSubmit = (data) => {
    sendIcDetails(data);
  };

  return (
    <FormProvider {...methods}>
      <MasterFormWrapper
        title="Insurance Company Details"
        alert={alertMaster}
        reset={resetMaster}
        isError={isError}
        isSuccess={isSuccess}
        description={"Miscellaneous mapping can be here"}
        onSubmit={onSubmit}
        additionalContent={<ICFormStepper isBrokerLogin={isBrokerLogin} />}
      >
        <MiscellaneousFieldsContainer container>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <TextInput
              label="Short Term Period Days"
              name="short_term_period_days"
              required={true}
              inputProps={{ maxLength: 2 }}
              onInput={allowOnlyNumbers}
            />
          </Grid>
          <MiscellaneousNoteContainer item xxs={12} xs={6} md={6} lg={8}>
            <MiscellaneousFieldsNotes>
              Note: This is the minimum duration for which premium will be
              charged in the event of policy cancellation.
            </MiscellaneousFieldsNotes>
          </MiscellaneousNoteContainer>
        </MiscellaneousFieldsContainer>
        <MiscellaneousFieldsContainer container>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Radio
              name="allow_raise_discount"
              label="Allow Discount Raise"
              color="opposite"
              required={true}
              options={statusOptions2}
            />
          </Grid>
          <MiscellaneousNoteContainer item xxs={12} xs={6} md={6} lg={8}>
            <MiscellaneousFieldsNotes>
              Note: This will allow dealers to request an additional discount
              from the insurance company (subject to approval from the insurance
              company).
            </MiscellaneousFieldsNotes>
          </MiscellaneousNoteContainer>
        </MiscellaneousFieldsContainer>
        <MiscellaneousFieldsContainer container>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Radio
              name="status"
              label="Status"
              color="opposite"
              required={true}
              options={statusOptions}
            />
          </Grid>
        </MiscellaneousFieldsContainer>
      </MasterFormWrapper>
    </FormProvider>
  );
}

export default IcConfigMiscellaneousMappings;
