import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ExcelIcon from "@mui/icons-material/InsertDriveFile";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Button,
  FormHelperText,
  FormLabel,
  Link,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
const fileTypeBasedOnExtension = {
  pdf: "application/pdf",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  jpg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
};

function SummaryDocupload({
  label,
  name,
  id,
  required = false,
  ...otherProps
}) {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const file = watch(name) ? watch(name) : "";
  const file_name = typeof file === "string" && file?.split("/").at(-1);
  const file_type = typeof file === "string" && file_name?.split(".").at(-1);

  const baseUrl = file && file instanceof File && URL.createObjectURL(file);

  const typeOfFile = file.type?.split("/")?.at(-1);

  const isPdf = typeOfFile === "pdf";
  const isExcel =
    typeOfFile === "xlsx" ||
    typeOfFile === "xls" ||
    typeOfFile === "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  useEffect(() => {
    async function createFile() {
      let file_sample = await fetch(`${file}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      let data = await file_sample.blob();
      let metadata = {
        type: fileTypeBasedOnExtension[file_type],
      };
      let file_blob = new File([data], `${file_name}`, metadata);
      file_name && setValue(name, file_blob);
    }
    if (typeof file === "string") createFile();
  }, [file]);
  return (
    <div>
      <DocuploadWrapper>
        <FormLabel
          sx={{
            fontSize: "12px",
            color: "#6e8185",
            mb: "10px",
          }}
        >
          {label}{" "}
          <span style={{ fontSize: "12px", color: "red" }}>
            {required && label ? "*" : ""}
          </span>
        </FormLabel>

        <InputWrapper>
          <UploadButton
            id={`${name || "customFile"}_button`}
            component="label"
            htmlFor={name || "customFile"}
          >
            <CloudUploadOutlinedIcon fontSize="small"></CloudUploadOutlinedIcon>{" "}
            Choose File
          </UploadButton>
          {baseUrl ? (
            <>
              <FileName
                key={baseUrl}
                // component={Link}
                // target="_blank"
                // href={baseUrl}
                // rel="noopener noreferrer"
              >
                {isPdf ? (
                  <>
                    <FileIcon>
                      <PdfIcon fontSize="small" />
                    </FileIcon>
                  </>
                ) : isExcel ? (
                  <>
                    <FileIcon>
                      <ExcelIcon fontSize="small" />
                    </FileIcon>
                  </>
                ) : (
                  <>
                    <img
                      src={baseUrl}
                      alt={watch(name)?.name}
                      style={{
                        width: "30px",
                        height: "30px",
                        objectFit: "contain",
                        borderRadius: "50%",
                        padding: "1px",
                      }}
                    />
                  </>
                )}
                {truncateFilename(watch(name)?.name, 8)}

                <Tooltip title="View" placement="top" arrow>
                  <OpenInNewLink
                    key={baseUrl}
                    component={Link}
                    target="_blank"
                    href={baseUrl}
                    rel="noopener noreferrer"
                  >
                    <OpenInNewIcon fontSize="small" />
                  </OpenInNewLink>
                </Tooltip>
                <Tooltip title="Remove" placement="top" arrow>
                  <ClearButton onClick={() => setValue(name, "")}>
                    <ClearIcon />
                  </ClearButton>
                </Tooltip>
              </FileName>
            </>
          ) : (
            <FileName>No File Chosen</FileName>
          )}
        </InputWrapper>
        <input
          type="file"
          className="form-control"
          id={name || "customFile"}
          hidden
          {...register(name)}
          onChange={(e) => {
            setValue(name, e.target.files[0]);
          }}
          {...otherProps}
        />
        <FormHelperText
          error={Boolean(errors[name])}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 10px",
            color: "red",
          }}
        >
          <span>{errors[name]?.message}</span>
        </FormHelperText>
      </DocuploadWrapper>
    </div>
  );
}

export default SummaryDocupload;

function truncateFilename(filename, maxLength) {
  if (filename.length > maxLength) {
    return filename.slice(0, maxLength - 3) + "...";
  }
  return filename;
}

export const convertToBase64 = (file, setImage) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    setImage(reader.result.toString());
  };
  reader.readAsDataURL(file);
};

const DocuploadWrapper = styled(Box)({
  display: "flex",
  alignItems: "start",
  flexDirection: "column",
  padding: "0",
  justifyContent: "center",
  "& > :not(style)": {
    width: "auto",
    height: "auto",
  },
});

const InputWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
});
const UploadButton = styled(Button)({
  display: "flex",
  fontSize: "10px",
  color: "#6e8185",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",

  textTransform: "capitalize",
  backgroundColor: "#e6f2f2",
  borderRadius: "7px",
  gap: "6px",
});
const FileName = styled(Box)({
  display: "flex",
  fontSize: "12px",
  margin: "0 5px",
  color: "#6e8185",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  flex: 1,
  width: "100px",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "5px",
});

const OpenInNewLink = styled(Box)({
  marginLeft: "5px",
  color: "#6e8185",
  cursor: "pointer",
});

const FileIcon = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: "#e6f2f2",
  marginRight: "8px",
});

export const ClearButton = styled(Box)({
  cursor: "pointer",
  marginLeft: "5px",
});
