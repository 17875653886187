import moment from "moment";
import React from "react";
import { dateFormat } from "../../../../../utils/minDateForEndDate";
import { StyledGrid } from "../../../../masters/components/MasterStyle";
import {
  SummaryChild,
  SummaryHeading,
  SummarySubContainer,
  SummarySubHeading,
} from "../../../../summary/summarystyle";
import { ClaimHeading, MainPaperContainer } from "../../../styles/claim.styles";
import { DocumentWrapper } from "../../../../servicing/cancellation/CancellationApproval/CancellationApprovalStyles";

function ProformaInvoiceDetails({ claimDetails }) {
  return (
    <MainPaperContainer>
      <ClaimHeading>Proforma Invoice Details</ClaimHeading>
      <SummarySubContainer>
        <StyledGrid container columns={12} spacing={0}>
          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">
              Proforma Invoice No.
            </SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.proforma_invoice_no}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">
              Proforma Invoice Date
            </SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {moment(claimDetails?.proforma_invoice_date, dateFormat).format(
                "DD-MMM-YYYY"
              )}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">
              Proforma Invoice Amount
            </SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.proforma_invoice_amount}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">
              Proforma Invoice Document
            </SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              <a
                rel="noreferrer"
                target={"_blank"}
                href={claimDetails?.proforma_invoice_document}
                style={{ color: "#394d51" }}
              >
                <DocumentWrapper>View</DocumentWrapper>
              </a>
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Remarks</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.proforma_invoice_remark}
            </SummarySubHeading>
          </SummaryChild>
        </StyledGrid>
      </SummarySubContainer>
    </MainPaperContainer>
  );
}

export default ProformaInvoiceDetails;
