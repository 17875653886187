import * as yup from "yup";
import { mobileNumberRequired } from "../../../../utils/Validations/mobileNumberValidations";

const BrokerValidation = yup.object().shape({
  dealer_id: yup
    .mixed()
    .test("check_dealer_id", "Dealer is required", function (value) {
      return value.length > 0;
    }),
  mobile_number: mobileNumberRequired(),
  count: yup
    .string()
    .nullable()
    .required("Count is required.")
    .min(1, "Count must be greater than or equal to 1.")
    .max(99, "Count must be less than or equal to 99."),
});

export { BrokerValidation };
