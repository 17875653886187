import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api/api";
import { errorHandler } from "../utils/errorHandler";
import { useNavigate } from "react-router";
export const useGetAddMobileNoApproval = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, data, isSuccess, isLoading, error, reset } = useMutation(
    (formData) => {
      return api.post("/mobile_request/add", formData);
    },
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          queryClient.invalidateQueries(["master"]);
          const timer = setTimeout(() => {
            navigate("../../servicing/mobile_request");
          }, 3000);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);
  return {
    addMobileNoApproval: mutate,
    isSuccess: isSuccess,
    isLoading: isLoading,
    alertMaster: errList,
    resetMaster: reset,
    errorMessage: error?.response?.data?.message,
  };
};

export const useGetAddMobileNoApprovalForbroker = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, data, isSuccess, isLoading, error, reset } = useMutation(
    (formData) => {
      return api.post("/broker_mobile_request/add", formData);
    },
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            queryClient.invalidateQueries(["master"]);
            navigate("../../servicing/broker_mobile_request");
          }, 3000);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);
  return {
    addMobileNoApproval: mutate,
    isSuccess: isSuccess,
    isFeedLoading: isLoading,
    alertMaster: errList,
    resetMaster: reset,
    errorMessage: error?.response?.data?.message,
  };
};

export const useSendMobileApprovalStatus = () => {
  const queryClient = useQueryClient();
  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => {
      return api.post("rm_mobile_approval/list", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["master"]);
      },
    }
  );
  const errList = errorHandler(error, data);
  return {
    sendMobileApprovalStatus: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
    errorMessage: error?.response?.data?.message,
  };
};
