import { Box, InputAdornment, OutlinedInput, styled } from "@mui/material";
const _OutlinedInput = (props) => (
  <OutlinedInput
    {...props}
    startAdornment={<InputAdornment position="start">/</InputAdornment>}
  />
);
export const SearchInput = styled(_OutlinedInput)({
  height: "40px",
  "& .MuiOutlinedInput-input": {
    padding: "6px",
    height: "40px",
  },
  "& .MuiInputAdornment-root": {
    border: "1px solid rgba(0, 0, 0, 0.4)",
    height: "18px",
    padding: "12px 6px",
    borderRadius: "20px",
    "& .MuiTypography-root": {
      color: "rgba(0, 0, 0, 0.4)",
    },
  },
  "& fieldset": {
    borderColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: "20px",
  },
});
export const SuggestionWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  minHeight: "41px",
  width: "inherit",
  background: "white",
  top: "100%",
  maxHeight: "164px",
  overflow: "scroll",
  boxShadow: theme.shadows[2],
  borderRadius: "6px",
}));

export const SuggestionItem = styled(Box)(({ theme, active }) => ({
  padding: "8px 12px",
  borderBottom: "1px solid rgba(0,0,0,0.3)",
  color: active ? "white" : theme.palette.text.secondary,
  background: active && theme.palette.primary.main,
  cursor: "pointer",
  "&:hover": {
    color: "white",
    background: theme.palette.primary.main,
  },
}));

export const GlobalSearchContainer = styled(Box)({
  width: "340px",
  marginRight: "10px",
});
