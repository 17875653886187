import { yupResolver } from "@hookform/resolvers/yup";
import { Box, useMediaQuery } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import AlertMessage from "../../../../../components/Alert/AlertMessage";
import withRouteProtection from "../../../../../hoc/withRouteProtection";
import {
  useClaimNotification,
  useGetPolicyDetails,
} from "../../../../../services/claims.service";
import { Container, Wrapper } from "../../../claimStyledComponents";
import ClaimDetailsFooter from "../../../components/ClaimDetailsFooter";
import ClaimSummary from "../../../components/ClaimSummary";
import ClaimSummaryPopup from "../../../components/ClaimSummaryPopup";
import { accidentDetailsSchema } from "../../../validations/accidentDetails.validations";
import { contactDetailsSchema } from "../../../validations/contactDetails.validations";
import { claimDocumentValidation } from "../../../validations/claimDocumentValidation";
import SpotIntimationForm from "./ClaimIntimationForm";

import * as yup from "yup";

function ClaimIntimation() {
  const [openDetails, setOpenDetails] = useState(false);

  const combinedClaimIntimationValidation = yup.object().shape({
    ...accidentDetailsSchema.fields,
    ...contactDetailsSchema.fields,
    ...claimDocumentValidation.fields,
  });
  const methods = useForm({
    resolver: yupResolver(combinedClaimIntimationValidation),
    defaultValues: {
      is_tp_loss: "No",
    },
  });
  const { watch, reset, setValue, trigger, handleSubmit } = methods;
  const {
    createClaimNotification,
    isSuccess,
    alertMaster,
    resetMaster,
    isError,
    isLoading,
  } = useClaimNotification();
  const { booking_id } = useParams();

  const { policy_details_list: booking_data_list } =
    useGetPolicyDetails(booking_id);
  const bookingData = booking_data_list[0];

  const date_of_accident = watch("date_of_accident");

  const onSubmit = (data) => {
    let payload = {
      ...data,
      booking_id,
      date_of_accident: moment(date_of_accident).format("DD-MM-YYYY hh:mm A"),
    };
    createClaimNotification(payload);
    if (isSuccess) {
      reset({
        nature_of_accident: "",
        is_tp_loss: "Yes",
        pincode_of_accident: "",
        accident_state: "",
        accident_city: "",
        place_of_accident: "",
        date_of_accident: "",
        part_of_vehicle: [],
        description_of_accident: "",
        claim_intimated_by: "",
        intimator_name: "",
        intimator_mobile_no: "",
        customer_mobile_no: "",
        insured_address: "",
        driver_name: "",
        customer_pincode: "",
        customer_state: "",
        customer_city: "",
        isAuthorised: "Yes",
        dealer_by: "accident_place",
        dealer_state: "",
        dealer_city: "",
        dealer_id: "",
        dealer_contact_no: "",
        dealer_email_id: "",
        ic_servicing_office: "",
        workshop_city: "",
        workshop_address: "",
        workshop_id: "",
        email_id: "",
      });
    }
  };

  const intimatedByOwner = watch("claim_intimated_by") === "owner";
  useEffect(() => {
    if (intimatedByOwner) {
      setValue("intimator_name", bookingData?.insured_name);
      setValue("intimator_mobile_no", bookingData?.mobile);
      trigger("intimator_name");
      trigger("intimator_mobile_no");
    } else {
      setValue("intimator_name", "");
      setValue("intimator_mobile_no", "");
    }
  }, [intimatedByOwner]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const showDocumentsection = true;
  return (
    <Wrapper>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box style={{ padding: "0 15px" }}>
            <AlertMessage
              alert={alertMaster}
              reset={resetMaster}
              isError={isError}
              isSuccess={isSuccess}
            />
          </Box>
          <Container>
            {!isMobile ? (
              <ClaimSummary
                showDocumentsection={showDocumentsection}
                isMobile={isMobile}
              />
            ) : (
              <>
                <ClaimDetailsFooter
                  openDetails={openDetails}
                  setOpenDetails={setOpenDetails}
                />
                {openDetails && (
                  <>
                    <ClaimSummaryPopup
                      setOpenDetails={setOpenDetails}
                      openDetails={openDetails}
                      showDocumentsection={showDocumentsection}
                      isMobile={isMobile}
                    />
                    <div className="mobileProposalBlur"></div>
                  </>
                )}
              </>
            )}

            <SpotIntimationForm
              isMobile={isMobile}
              alertMsg={{
                isSuccess,
                isLoading,
              }}
            />
          </Container>
        </form>
      </FormProvider>
    </Wrapper>
  );
}

export default withRouteProtection(ClaimIntimation);
