import { yupResolver } from "@hookform/resolvers/yup";
import ReplayIcon from "@mui/icons-material/Replay";
import { Grid } from "@mui/material";
import moment from "moment";
import React, { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import {
  allowOnlyAlphabetsNumberSpace,
  allowOnlyAplhabetNumber,
} from "../../../InputUtils/onInput";
import Buttons from "../../../components/BasicFormComponent/Buttons";
import DateInput from "../../../components/InputComponents/DateInput";
import TextInput from "../../../components/InputComponents/TextInput";
import Navbar from "../../../components/Navbar";
import withRouteProtection, {
  AuthContext,
} from "../../../hoc/withRouteProtection";
import {
  ClaimActionButton,
  ResetButton,
} from "../../Claim/claimStyledComponents";
import MasterTable from "../../masters/MasterTable/MasterTable";
import {
  AtleastOnefieldError,
  ButtonWrapper,
  FormContainer,
  Heading,
  Papers,
} from "../../masters/components/MasterStyle";
import { exceptionLogValidationSchema } from "./validations/exceptionLogs.validations";

const ExceptionLogSearch = () => {
  const [customSearch, setCustomSearch] = useState({});
  const { writable } = useContext(AuthContext);
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(exceptionLogValidationSchema),
    defaultValues: {
      user_id: "",
      message: "",
      exception: "",
      file_name: "",
      from_date: "",
      to_date: "",
    },
  });

  const onSubmit = (data) => {
    const isAnyFieldSelected = Object?.values(data).some((item) => !!item);
    if (isAnyFieldSelected) {
      setCustomSearch(data);
    } else {
      methods.setError("atleast_one_field", {
        type: "custom",
        message: "Atleast one field is required",
      });
    }
  };

  const handleReset = () => {
    methods.reset({
      user_id: "",
      message: "",
      exception: "",
      file_name: "",
      from_date: "",
      to_date: "",
    });
  };

  const err = methods.formState.errors?.atleast_one_field?.message;

  return (
    <Navbar>
      <FormContainer>
        <Papers elevation={3} sx={{ width: "100%" }}>
          <Heading variant="h5" align="left">
            Exception Logs
          </Heading>
          {err && <AtleastOnefieldError>{err}</AtleastOnefieldError>}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={1} columnSpacing={3} mt={1} rowGap={2}>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"User ID"}
                    name={"user_id"}
                    inputProps={{ maxLength: 30 }}
                    onInput={allowOnlyAplhabetNumber}
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Message"}
                    name={"message"}
                    inputProps={{ maxLength: 300 }}
                    onInput={allowOnlyAlphabetsNumberSpace}
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Exception"}
                    name={"exception"}
                    inputProps={{ maxLength: 100 }}
                    onInput={allowOnlyAlphabetsNumberSpace}
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"File Name"}
                    name={"file_name"}
                    inputProps={{ maxLength: 100 }}
                    onInput={allowOnlyAlphabetsNumberSpace}
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <DateInput
                    label={"From Date"}
                    name={"from_date"}
                    maxDate={moment()}
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <DateInput
                    label={"To Date"}
                    name={"to_date"}
                    maxDate={moment()}
                  />
                </Grid>
              </Grid>
              <ButtonWrapper marginLeft="auto" gap={1}>
                <Buttons type="submit" label="Search" />
                <ResetButton
                  onClick={handleReset}
                  id="reset_button_for_endorsement"
                >
                  <ReplayIcon />
                  Reset
                </ResetButton>
              </ButtonWrapper>
            </form>
          </FormProvider>
        </Papers>
      </FormContainer>

      <MasterTable
        master_name={"exception_log"}
        writable={writable}
        columnFilters={Object.keys(customSearch).map((item) => {
          return {
            id: item,
            value: customSearch[item],
          };
        })}
        customRowAction={({ row }) =>
          writable && (
            <>
              <ClaimActionButton
                onClick={() => {
                  navigate(`./view/${row?.original?.exception_log_id}`);
                }}
              >
                View
              </ClaimActionButton>
            </>
          )
        }
      />
    </Navbar>
  );
};

export default withRouteProtection(ExceptionLogSearch);
