// TODO: sequence & respectve components
export const icConfigSteps = [
  {
    path: "/",
    label: "Company Details",
  },
  {
    path: "/ic_api_mapping",
    label: "API Mapping",
  },
  {
    path: "/ic_pa_cover",
    label: "PA Cover",
  },
  {
    path: "/policy_pdf_notes",
    label: "Policy PDF Notes Section",
  },
  {
    path: "/ic_inspection",
    label: "Inspection",
  },
  {
    path: "/claim_category_range",
    label: "Claim Category Range",
  },
  {
    path: "/ic_premium_calculation",
    label: "Policy Premium Calculation",
  },
  {
    path: "/ic_miscellaneous_mapping",
    label: "Miscellaneous",
  },
];
// eslint-disable-next-line
const [firststep, ...restSteps] = icConfigSteps;
export const stepsOtherThanRoot = restSteps;
export const getActiveStep = () => {
  const activeStep = restSteps.findIndex((step) =>
    location.pathname.toLowerCase().includes(step.path.toLowerCase())
  );
  return activeStep;
};
