import moment from "moment";
import * as yup from "yup";
import { dateFormat } from "../../../utils/minDateForEndDate";
import { REGEX } from "../../../constants/regex";

export const driverDetailsSchema = yup.object().shape({
  driver_age: yup
    .string()
    .nullable()
    .required("Driver age is required")
    .test(
      "driver_age",
      "Driver age greater than 18 and less than 100",
      function (value) {
        if (value === "") {
          return true;
        } else {
          return value >= 18 && value < 100;
        }
      }
    ),

  driver_license_no: yup
    .string()
    .nullable()
    .required("Driver license no. is required")
    .matches(REGEX.DRIVING_LICENSE, "Please enter a valid driver license no."),
  license_type: yup.string().nullable().required("License type is required"),
  place_of_issue_city_id: yup
    .string()
    .nullable()
    .required("Place of issue is required"),
  place_of_issue_state_id: yup
    .string()
    .nullable()
    .required("State of issue is required"),
  valid_upto: yup
    .string()
    .nullable()
    .required("Valid upto is required")
    .test("valid_upto", "Valid upto can not be past date", (value) => {
      if (!value) return true;
      return moment(value, dateFormat).isAfter(moment().subtract(1, "days"));
    })
    .test(
      "valid_upto_range",
      "Valid upto can not be a future date than 20 years",
      function (value) {
        if (!value) return true;
        return moment(value, dateFormat).isBetween(
          moment().subtract(1, "day"),
          moment().add(20, "years").subtract(1, "day")
        );
      }
    ),
});
