import styled from "@emotion/styled";
import { Box, Paper, TableCell } from "@mui/material";

export const IcDealerMapTableMainContainer = styled(Paper)(({ theme }) => ({
  padding: "15px",
  margin: "0 30px 30px 30px",
  borderRadius: "14px",
  boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
  [theme.breakpoints.up("sm")]: {
    margin: "30px",
  },
}));

export const IcDealerMapTableHeadCell = styled(TableCell)({
  backgroundColor: "#dbe9ed",
  padding: "5px 10px",
  textAlign: "center",
  whiteSpace: "nowrap",
  fontWeight: "bold",
});

export const IcDealerMapTableCell = styled(TableCell)({
  textAlign: "center",
  whiteSpace: "nowrap",
  padding: "5px 10px",
});

export const IcDealerMapTablePaymentHeadCell = styled(TableCell)({
  backgroundColor: "#dbe9ed",
  padding: "3px",
  textAlign: "center",
  border: "none",
  fontSize: "10px",
});

export const IcDealerMapTablePaymentCell = styled(TableCell)({
  textAlign: "center",
  border: "none",
  padding: 0,
});

export const IcDealerMapTableNoRecordsContainer = styled(Box)({
  textAlign: "center",
  fontSize: "13px",
  fontStyle: "italic",
  padding: "15px",
  color: "#6e8185",
});
