import { useEffect, useMemo, useRef, useState } from "react";
import { useGetMenuService } from "../../../../../services/dashboard.service";
import { useNavigate } from "react-router";
import { useOnClickOutside } from "../../../../../custom-hooks/useOutside";

export const useGlobalSearch = () => {
  const { menuItems } = useGetMenuService();
  const [searchText, setSearchText] = useState("");
  const [showSuggestions, setShowSuggestions] = useState();
  const flattendMenu = useMemo(() => {
    return menuItems
      .flatMap((item) => [item, ...item.child])
      .filter((item) => !item.child.length);
  }, [JSON.stringify(menuItems)]);
  const searchInputRef = useRef();
  const suggestionBoxRef = useRef();
  const filteredOptions = flattendMenu.filter((item) =>
    !searchText
      ? true
      : item.menu_name.toLowerCase().includes(searchText.toLowerCase())
  );
  const navigate = useNavigate();
  const optionsLength = filteredOptions.length;
  const [currentSelectedSuggestion, setCurrentSelectedSuggestion] = useState(0);
  const containerRef = useRef();
  useEffect(() => {
    const handleSlashPress = (e) => {
      const key = e.key;
      if (e.target.nodeName === "BODY" && key === "/") {
        e.preventDefault();
        searchInputRef.current.focus();
        setShowSuggestions(true);
      }
    };
    document.addEventListener("keydown", handleSlashPress);
    return () => {
      document.removeEventListener("keydown", handleSlashPress);
    };
  }, []);

  const scrollItemIntoView = (index) => {
    const itemElement = document.getElementById(
      `global-suggestion-item-${index}`
    );
    if (itemElement && suggestionBoxRef.current) {
      const itemOffsetTop = itemElement.offsetTop;
      const containerScrollTop = suggestionBoxRef.current.scrollTop;
      const containerHeight = suggestionBoxRef.current.clientHeight;
      if (itemOffsetTop < containerScrollTop) {
        // Item is above the visible viewport, scroll up
        suggestionBoxRef.current.scrollTop = itemOffsetTop;
      } else if (
        itemOffsetTop + itemElement.clientHeight >
        containerScrollTop + containerHeight
      ) {
        // Item is below the visible viewport, scroll down
        suggestionBoxRef.current.scrollTop =
          itemOffsetTop + itemElement.clientHeight - containerHeight;
      }
    }
  };
  const { parent_slug, menu_slug } =
    filteredOptions[currentSelectedSuggestion] || {};
  const path = parent_slug ? `/${parent_slug}/${menu_slug}` : `/${menu_slug}`;

  const handleClick = (e, item) => {
    const { parent_slug, menu_slug } = item;
    const path = parent_slug ? `/${parent_slug}/${menu_slug}` : `/${menu_slug}`;
    navigate(path);
  };
  const handleKeyDown = (e) => {
    const key = e.key;

    switch (key) {
      case "ArrowDown":
        e.preventDefault();
        setCurrentSelectedSuggestion((prev) => {
          const value = Math.min(prev + 1, optionsLength - 1);
          scrollItemIntoView(value);
          return value;
        });

        break;
      case "ArrowUp":
        e.preventDefault();
        setCurrentSelectedSuggestion((prev) => {
          const value = Math.max(prev - 1, 0);
          scrollItemIntoView(value);
          return value;
        });
        break;
      case "Enter":
        e.preventDefault();
        navigate(path);
        break;
      case "Escape":
        e.preventDefault();
        setShowSuggestions(false);
        searchInputRef.current.blur();
        break;
      default:
        return;
    }
  };
  useEffect(() => {
    setCurrentSelectedSuggestion(0);
  }, [JSON.stringify(filteredOptions)]);

  const onSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };
  const handleFocus = () => {
    if (searchText) {
      setShowSuggestions(true);
    }
  };
  useOnClickOutside(containerRef, () => {
    setShowSuggestions(false);
  });
  return {
    onSearch,
    filteredOptions: filteredOptions,
    showSuggestions,
    handleClick,
    handleFocus,
    ref: { searchInputRef, suggestionBoxRef, containerRef },
    keyboardControl: {
      handleKeyDown,
      currentSelectedSuggestion,
    },
  };
};
