export function underscoreToSpaceSeparation(string) {
  let description = "";
  string?.split("_").forEach((name) => {
    description += name + " ";
  });
  return description;
}

export function capitalizeAndReplaceUnderscore(string) {
  let modifiedString = "";
  string?.split("_")?.forEach((word) => {
    const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
    modifiedString += capitalizedWord + " ";
  });
  return modifiedString.trim();
}

export function capitalizeAfterSpace(string) {
  let modifiedString = "";
  string?.split(" ").forEach((word) => {
    const capitalizedWord =
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    modifiedString += capitalizedWord + " ";
  });
  return modifiedString.trim();
}

export function spaceToUnderscoreSeparationForId(string) {
  let result = "";
  for (let i = 0; i < string?.length; i++) {
    if (string[i] === " ") {
      result += "_";
    } else {
      result += string[i].toLowerCase();
    }
  }
  return result;
}
