import React, { useMemo, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCreateMaster,
  useGetOemList,
  useGetSortedMaster,
} from "../../../../../../services/master.service";
import { lsoValidationSchema } from "../../../../../masters/forms/LSOForm/LSOValidation";
import {
  addressValidationOnly,
  allowOnlyAlphabetsNumberSpace,
  allowOnlyAplhabetNumber,
  allowOnlyNumbers,
} from "../../../../../../InputUtils/onInput";
import { useGetStateCityFromPincode } from "../../../../../../services/pincode.service";
import {
  statusOptions,
  statusOptions2,
} from "../../../../../../constants/options";
import MasterFormWrapper from "../../../../../masters/components/MasterFormWrapper/MasterFormWrapper";
import { Grid } from "@mui/material";
import Select from "../../../../../../components/InputComponents/Select";
import TextInput from "../../../../../../components/InputComponents/TextInput";
import Radio from "../../../../../../components/InputComponents/Radio";
import MultiSelect from "../../../../../../components/InputComponents/MultiSelect";
import Navbar from "../../../../../../components/Navbar";
import { usePincodeValidations } from "../../../../../../custom-hooks/usePincodeValidations";

function PolicyLSO({ values }) {
  const methods = useForm({
    resolver: yupResolver(lsoValidationSchema),
    defaultValues: {
      is_nodal_office: "N",
      status: "Y",
      lso_pincode: values?.pincode,
      lso_state_id: values?.state_id,
      lso_city_id: values?.city_id,
      ...values,
      oem_id: values?.oem_id || [],
    },
  });

  const {
    createItem,
    isSuccess,
    alertMaster,
    resetMaster,
    isError,
    isLoading,
  } = useCreateMaster("policy_lso");

  const { oem_items } = useGetOemList();
  const oem_list = useMemo(() => {
    return oem_items.map((item) => {
      return {
        code: item.oem_id,
        display_name: item.oem_name,
      };
    });
  }, [oem_items]);

  const { table_list: ic_items } = useGetSortedMaster({
    master_name: "insurance",
    sortBy: "insurance_name",
  });

  const ic_list = useMemo(() => {
    return ic_items.map((item) => {
      return {
        code: item.insurance_id,
        display_name: item.insurance_name,
      };
    });
  }, [ic_items]);

  const onSubmit = (data) => {
    createItem(data);
  };

  useEffect(() => {
    if (isSuccess) {
      methods.reset({
        lso_code: "",
        ic_id: "",
        address1: "",
        address2: "",
        address3: "",
        lso_pincode: "",
        lso_state_id: "",
        lso_city_id: "",
        status: "Y",
      });
    }
  }, [isSuccess]);

  const {
    getCityState,
    state_list,
    city_list,
    isStateCitySuccess,
    resetStateCity,
  } = useGetStateCityFromPincode(methods.watch("pincode"));
  const pincode = methods.watch("lso_pincode");
  const state = methods.watch("lso_state_id");
  const city = methods.watch("lso_city_id");

  useEffect(() => {
    if (pincode?.length === 6) {
      methods.setValue("lso_state_id", "");
      methods.setValue("lso_city_id", "");
      getCityState(pincode);
    } else {
      resetStateCity();
      methods.setValue("lso_state_id", "");
      methods.setValue("lso_city_id", "");
    }
  }, [pincode]);

  usePincodeValidations({
    pincode_field_name: "lso_pincode",
    pincode,
    state_list,
    city_list,
    formMethods: methods,
    isSuccess: isStateCitySuccess,
  });

  useEffect(() => {
    if (
      state_list?.length > 0 &&
      city_list?.length > 0 &&
      pincode?.length === 6
    ) {
      methods.clearErrors(["pincode"]);
      if (state_list?.length > 0 && state)
        methods.clearErrors(["lso_state_id"]);
      if (city_list?.length > 0 && city) methods.clearErrors(["lso_city_id"]);
      return;
    }
  }, [state, city]);

  const is_nodal_office = methods.watch("is_nodal_office");

  return (
    <Navbar>
      <FormProvider {...methods}>
        <MasterFormWrapper
          title="Policy LSO"
          alert={alertMaster}
          reset={resetMaster}
          isError={isError}
          isSuccess={isSuccess}
          description={"Policy LSO can be created here"}
          onSubmit={onSubmit}
          isLoading={isLoading}
        >
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Select
              label="Insurance Company"
              name="ic_id"
              required={true}
              options={ic_list}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <TextInput
              label="LSO Name"
              name="lso_name"
              required={true}
              inputProps={{ maxLength: 100 }}
              onInput={allowOnlyAlphabetsNumberSpace}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <TextInput
              label="LSO Code"
              name="lso_code"
              required={true}
              inputProps={{ maxLength: 15 }}
              onInput={allowOnlyAplhabetNumber}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <TextInput
              label="Address Line 1"
              name="address1"
              required={true}
              onInput={addressValidationOnly}
              inputProps={{
                maxlength: 150,
              }}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <TextInput
              label="Address Line 2"
              name="address2"
              // required={true}
              onInput={addressValidationOnly}
              inputProps={{
                maxlength: 150,
              }}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <TextInput
              label="Address Line 3"
              name="address3"
              onInput={addressValidationOnly}
              inputProps={{
                maxlength: 150,
              }}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <TextInput
              label="LSO Pincode"
              name="lso_pincode"
              required={true}
              onInput={allowOnlyNumbers}
              inputProps={{ maxLength: 6 }}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Select
              label="LSO State Name"
              name="lso_state_id"
              required={true}
              options={state_list}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Select
              label="LSO City Name"
              name="lso_city_id"
              required={true}
              options={city_list}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Radio
              name="is_nodal_office"
              label="Is Nodal Office"
              row
              color="opposite"
              required={true}
              options={statusOptions2}
            />
          </Grid>
          {is_nodal_office === "Y" && (
            <Grid item xxs={12} xs={6} md={6} lg={4}>
              <MultiSelect
                label="OEM"
                name="oem_id"
                required={true}
                options={oem_list}
              />
            </Grid>
          )}
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Radio
              name="status"
              label="Status"
              required
              color="opposite"
              row
              options={statusOptions}
            />
          </Grid>
        </MasterFormWrapper>
      </FormProvider>
    </Navbar>
  );
}

export default PolicyLSO;
