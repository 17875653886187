import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Grid, InputAdornment } from "@mui/material";
import React, { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Form, useNavigate } from "react-router-dom";
import {
  allowOnlyNumbers,
  allowOnlyNumbersWithDecimal,
  allowOnlyNumbersWithDecimalAfterFirstIndex,
} from "../../../InputUtils/onInput";
import AlertMessage from "../../../components/Alert/AlertMessage";
import Buttons from "../../../components/BasicFormComponent/Buttons";
import MultiSelect from "../../../components/InputComponents/MultiSelect";
import Radio from "../../../components/InputComponents/Radio";
import Select from "../../../components/InputComponents/Select";
import TextInput from "../../../components/InputComponents/TextInput";
import {
  addonAppliedOptionsForImtConfigurator,
  amountTypeOptions,
  calculationTypeOptions,
  statusOptions,
} from "../../../constants/options";
import withRouteProtection from "../../../hoc/withRouteProtection";
import {
  useCreateMaster,
  useGetSortedMaster,
} from "../../../services/master.service";
import {
  ButtonWrapper,
  Heading,
  Papers,
  StyledGrid,
} from "../../masters/components/MasterStyle";
import { IMTConfiguratorValidation } from "./IMTConfiguratorValidation";
import Navbar from "../../../components/Navbar";

function IMTConfigurator({ values }) {
  const methods = useForm({
    resolver: yupResolver(IMTConfiguratorValidation),
    defaultValues: {
      imt_name: "",
      insurance_id: [],
      segment_id: [],
      status: "Y",
      calculation_type: "none",
      ...values,
    },
  });

  const calculation_type = methods.watch("calculation_type");
  const cubicCapacity = calculation_type === "cc";
  const seatingCapacity = calculation_type === "sc";
  const grossVehicleWeight = calculation_type === "gvw";
  const kiloWatt = calculation_type === "kw";

  const {
    createItem,
    isSuccess,
    alertMaster,
    resetMaster,
    isError,
    isLoading,
  } = useCreateMaster("imt_configurator");
  const navigate = useNavigate();
  const onSubmit = (data) => {
    createItem(data);
  };

  const { table_list: ic_items } = useGetSortedMaster({
    master_name: "insurance",
    sortBy: "insurance_name",
  });

  const ic_list = useMemo(() => {
    return ic_items.map((item) => {
      return {
        code: item.insurance_id,
        display_name: item.insurance_name,
      };
    });
  }, [ic_items]);

  const { table_list: segment_items } = useGetSortedMaster({
    master_name: "segment",
    sortBy: "segment_name",
  });

  const segment_list = useMemo(() => {
    return segment_items.map((item) => {
      return {
        code: item.segment_id,
        display_name: item.segment_name,
      };
    });
  }, [segment_items]);

  const tariffType = methods.watch("amount_type") === "percent";

  const amountChange = methods.watch(["amount", "on_condition"]);
  const showStarMark = amountChange?.some((item) => !!item);
  return (
    <Navbar>
      <Container>
        <Papers>
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Heading variant="h5">IMT Configurator</Heading>
              <AlertMessage
                alert={alertMaster}
                reset={resetMaster}
                isError={isError}
                isSuccess={isSuccess}
              />
              <StyledGrid>
                <Grid item xxs={12} xs={6} md={3} lg={3}>
                  <TextInput
                    label="IMT Number"
                    name="imt_name"
                    required={true}
                    inputProps={{ maxLength: 2 }}
                    onInput={allowOnlyNumbers}
                    startAdornment={
                      <InputAdornment
                        sx={{
                          marginRight: "5px",
                        }}
                        position="start"
                      >
                        IMT -
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <MultiSelect
                    label="Insurance Company"
                    name="insurance_id"
                    required={true}
                    options={[...ic_list]}
                  />
                </Grid>
                <Grid item xxs={12} xs={6} md={3} lg={3}>
                  <MultiSelect
                    label="Segment Name"
                    name="segment_id"
                    required={true}
                    options={segment_list}
                  />
                </Grid>

                <Grid item xxs={12} xs={6} md={3} lg={3}></Grid>

                <Grid item xxs={12} xs={6} md={3} lg={3}>
                  <Select
                    label="Calculation Type"
                    name="calculation_type"
                    required={true}
                    options={[
                      {
                        code: "none",
                        display_name: "None",
                      },
                      ...calculationTypeOptions,
                    ]}
                  />
                </Grid>

                {cubicCapacity && (
                  <>
                    <Grid item xxs={12} xs={6} md={3} lg={3}>
                      <TextInput
                        label="Minimum Cubic Capacity"
                        name="min_cc"
                        required={true}
                        onInput={allowOnlyNumbers}
                        inputProps={{
                          maxLength: 5,
                        }}
                      />
                    </Grid>
                    <Grid item xxs={12} xs={6} md={3} lg={3}>
                      <TextInput
                        label="Maximum Cubic Capacity"
                        name="max_cc"
                        required={true}
                        onInput={allowOnlyNumbers}
                        inputProps={{
                          maxLength: 5,
                        }}
                      />
                    </Grid>
                    {/* <Grid item xxs={12} xs={6} md={3} lg={3}></Grid> */}
                  </>
                )}

                {grossVehicleWeight && (
                  <>
                    <Grid item xxs={12} xs={6} md={3} lg={3}>
                      <TextInput
                        label="Minimum Weight"
                        name="min_gvw"
                        required={true}
                        onInput={allowOnlyNumbers}
                        inputProps={{
                          maxLength: 5,
                        }}
                      />
                    </Grid>
                    <Grid item xxs={12} xs={6} md={3} lg={3}>
                      <TextInput
                        label="Maximum Weight"
                        name="max_gvw"
                        required={true}
                        onInput={allowOnlyNumbers}
                        inputProps={{
                          maxLength: 5,
                        }}
                      />
                    </Grid>
                    {/* <Grid item xxs={12} xs={6} md={3} lg={3}></Grid> */}
                  </>
                )}

                {seatingCapacity && (
                  <>
                    <Grid item xxs={12} xs={6} md={3} lg={3}>
                      <TextInput
                        label="Minimum Seating Capacity"
                        name="min_sc"
                        required={true}
                        onInput={allowOnlyNumbers}
                        inputProps={{
                          maxLength: 2,
                        }}
                      />
                    </Grid>
                    <Grid item xxs={12} xs={6} md={3} lg={3}>
                      <TextInput
                        label="Maximum Seating Capacity"
                        name="max_sc"
                        required={true}
                        onInput={allowOnlyNumbers}
                        inputProps={{
                          maxLength: 2,
                        }}
                      />
                    </Grid>
                    {/* <Grid item xxs={12} xs={6} md={3} lg={3}></Grid> */}
                  </>
                )}

                {kiloWatt && (
                  <>
                    <Grid item xxs={12} xs={6} md={3} lg={3}>
                      <TextInput
                        label="Minimum kilowatts"
                        name="min_kw"
                        required={true}
                        onInput={allowOnlyNumbers}
                        inputProps={{
                          maxLength: 5,
                        }}
                      />
                    </Grid>
                    <Grid item xxs={12} xs={6} md={3} lg={3}>
                      <TextInput
                        label="Maximum kilowatts"
                        name="max_kw"
                        required={true}
                        onInput={allowOnlyNumbers}
                        inputProps={{
                          maxLength: 5,
                        }}
                      />
                    </Grid>
                    {/* <Grid item xxs={12} xs={6} md={3} lg={3}></Grid> */}
                  </>
                )}
                <Grid item xxs={12} xs={6} md={3} lg={3}>
                  <Select
                    label="Applied On"
                    name="applied_on"
                    required={true}
                    options={addonAppliedOptionsForImtConfigurator}
                  />
                </Grid>
                {/* <Grid item xxs={12} xs={6} md={6} lg={4}></Grid>
                <Grid item xxs={12} xs={6} md={6} lg={4}></Grid> */}
                {/* <Grid item xxs={12} xs={6} md={6} lg={4}></Grid> */}
                {/* <Grid item xxs={12} xs={6} md={3} lg={3}></Grid> */}

                {/* <Grid item xxs={12} xs={6} md={2.5} lg={2.5}>
                  <TextInput
                    label="Tariff Rate"
                    name="tariff_rate"
                    required={true}
                    onInput={(e) => {
                      return tariffType
                        ? allowOnlyNumbersWithDecimalAfterFirstIndex(e)
                        : allowOnlyNumbersWithDecimal(e);
                    }}
                    inputProps={{ maxLength: 5 }}
                  />
                </Grid>
                <Grid
                  style={{ paddingTop: "35px", paddingLeft: "15px" }}
                  item
                  xxs={1}
                  xs={1}
                  md={0.5}
                  lg={0.5}
                >
                  {"Or"}
                </Grid>
                <Grid item xxs={12} xs={6} md={3} lg={3}>
                  <TextInput
                    label="Flat Amount"
                    name="amount"
                    onInput={allowOnlyNumbers}
                    inputProps={{
                      maxLength: 9,
                    }}
                    required={showStarMark}
                  />
                </Grid>

                <Grid item xxs={12} xs={6} md={3} lg={3}>
                  <Select
                    label="On Condition"
                    name="on_condition"
                    options={[
                      {
                        code: "less",
                        display_name: "Which Ever is Less",
                      },
                      {
                        code: "more",
                        display_name: "Which Ever is More",
                      },
                    ]}
                    required={showStarMark}
                  />
                </Grid>
                <Grid item xxs={12} xs={6} md={4} lg={4}>
                  <Radio
                    label="Status"
                    name="status"
                    row
                    required={true}
                    options={statusOptions}
                  />
                </Grid> */}
              </StyledGrid>
              <StyledGrid>
                <Grid item xxs={12} xs={6} md={3} lg={3}>
                  <Select
                    label="Tarrif Amount Type"
                    name="amount_type"
                    required={true}
                    options={amountTypeOptions}
                  />
                </Grid>
                <Grid item xxs={12} xs={6} md={2.7} lg={2.7}>
                  <TextInput
                    label="Tariff Rate"
                    name="tariff_rate"
                    required={true}
                    onInput={(e) => {
                      return tariffType
                        ? allowOnlyNumbersWithDecimalAfterFirstIndex(e)
                        : allowOnlyNumbersWithDecimal(e);
                    }}
                    inputProps={{ maxLength: 5 }}
                  />
                </Grid>
                <Grid
                  style={{ paddingTop: "35px", paddingLeft: "15px" }}
                  item
                  xxs={1}
                  xs={1}
                  md={0.5}
                  lg={0.5}
                >
                  {"Or"}
                </Grid>
                <Grid item xxs={12} xs={6} md={2.7} lg={2.7}>
                  <TextInput
                    label="Flat Amount"
                    name="amount"
                    onInput={allowOnlyNumbers}
                    inputProps={{
                      maxLength: 9,
                    }}
                    required={showStarMark}
                  />
                </Grid>

                <Grid item xxs={12} xs={6} md={3} lg={3}>
                  <Select
                    label="On Condition"
                    name="on_condition"
                    options={[
                      {
                        code: "less",
                        display_name: "Which Ever is Less",
                      },
                      {
                        code: "more",
                        display_name: "Which Ever is More",
                      },
                    ]}
                    required={showStarMark}
                  />
                </Grid>
                <Grid item xxs={12} xs={6} md={4} lg={4}>
                  <Radio
                    label="Status"
                    name="status"
                    row
                    color="opposite"
                    required={true}
                    options={statusOptions}
                  />
                </Grid>
              </StyledGrid>
              <ButtonWrapper gap={2}>
                <Buttons
                  label="Cancel"
                  onClick={() => navigate(-1)}
                  disabled={isLoading || isSuccess}
                />
                <Buttons
                  label="Submit"
                  type="submit"
                  disabled={isLoading || isSuccess}
                />
              </ButtonWrapper>
            </Form>
          </FormProvider>
        </Papers>
      </Container>
    </Navbar>
  );
}

export default withRouteProtection(IMTConfigurator);
