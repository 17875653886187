import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import api from "../api/api";
import useUrlQuery from "../custom-hooks/useUrlQuery";
import { convertToFormData } from "../utils/convertToFormData";
import { errorHandler } from "../utils/errorHandler";
import { useGetCommunication } from "./quote.service";

export const useGetPaymentOptions = ({
  policy_no,
  proposal_unique_id_for_multiple_payment,
  trace_id_for_multiple_payment,
  isMultipleProposalPayment,
}) => {
  const { getUrlQuery } = useUrlQuery();
  const trace_id = getUrlQuery("traceId");
  const proposal_id = getUrlQuery("proposal_id");
  const customer_link = getUrlQuery("customer_link"); // To limit payment options when page link is shared to customer
  const { policy_endorsement_id } = useParams("policy_endorsement_id");

  const { data, isSuccess, isLoading } = useQuery(
    ["payment_options"],
    () =>
      api.post("payment_options/list", {
        customer_link: !!customer_link,
        trace_id: isMultipleProposalPayment
          ? trace_id_for_multiple_payment
          : trace_id,
        proposal_id: isMultipleProposalPayment
          ? proposal_unique_id_for_multiple_payment
          : proposal_id || policy_endorsement_id,
        policy_no,
        isEndorsement: !!policy_endorsement_id,
      }),
    { enabled: true }
  );
  return {
    payment_option: data?.data?.return_data,
    isSuccess,
    isLoading,
  };
};

export const useCreateChequePayment = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => {
      const formData = convertToFormData(data);
      return api.post("/payment_details/cheque/payment", formData);
    },
    {
      onSuccess: (res) => {
        queryClient.resetQueries(["cheque_payment"]);
        queryClient.resetQueries(["summarycard"]);
        onSuccess && onSuccess(res);
        res?.data?.return_data?.return_url &&
          navigate({
            pathname: res?.data?.return_data?.return_url,
            search: `?proposal_id=${proposal_id}`,
          });
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    createItem: mutate,
    return_url: data?.data?.return_data?.return_url,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
  };
};

export const useGetCurrentBalance = () => {
  const queryClient = useQueryClient();
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const {
    mutate,
    isError,
    isSuccess,
    data,
    error,
    reset,
    mutateAsync,
    isLoading,
  } = useMutation(
    (data) => {
      return api.post("/payment_details", {
        proposal_id,
        ...data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["current_balance"]);
      },
    }
  );
  const errList = errorHandler(error, data);
  return {
    getCurrentBalance: mutate,
    checkIsPaymentAllowed: mutateAsync,
    current_balance: data?.data?.return_data?.current_balance,
    payable_premium: data?.data?.return_data?.payable_premium,
    apd_id: data?.data?.return_data?.apd_id,
    error_msg: error?.response?.data?.message,
    error,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isLoading: isLoading,
  };
};

export const useApdPay = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const navigate = useNavigate();
  const { mutate, data } = useMutation(
    (data) => {
      return api.post("/payment_details/APD/debit", {
        proposal_id,
        ...data,
      });
    },
    {
      onSuccess: (res) => {
        queryClient.resetQueries(["current_balance"]);
        queryClient.resetQueries(["summarycard"]);
        onSuccess && onSuccess(res);
        res?.data?.return_data?.return_url &&
          // navigate(res?.data?.return_data?.return_url + `/${proposal_id}`);
          navigate({
            pathname: res?.data?.return_data?.return_url,
            search: `?proposal_id=${proposal_id}`,
          });
      },
    }
  );

  return {
    payNowApd: mutate,
    return_url: data?.data?.return_data?.return_url,
  };
};

export const useGetBookingDetails = () => {
  const queryClient = useQueryClient();
  const { pg_transaction_id } = useParams();
  const { mutate, data, isSuccess, isLoading } = useMutation(
    () => {
      return api.post("/getpolicydata", { pg_transaction_id });
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries(["booking_data"]);
      },
    }
  );

  return {
    bookingDetails: mutate,
    booking_id: data?.data?.return_data?.booking_id,
    returnData: data?.data?.return_data,
    insurance_name: data?.data?.return_data?.insurance_name,
    policy_no: data?.data?.return_data?.policy_no,
    customer_link: data?.data?.return_data?.customer_link,
    isLoading: isLoading,
    isSuccess: isSuccess,
  };
};

export const useGeneratePolicy = (booking_id) => {
  const queryClient = useQueryClient();
  const { createCommunication } = useGetCommunication();
  const { mutate, data } = useMutation(
    (data) => {
      return api.post("/generatepolicydocumnt", { ...data });
    },
    {
      onSuccess: () => {
        createCommunication({
          alias: "policy_initiated",
          type: ["email", "sms"],
          booking_id: booking_id?.booking_id,
        });
        queryClient.invalidateQueries(["booking_data"]);
      },
    }
  );
  return {
    generatePolicy: mutate,
    policyPdfURL: data?.data?.policy_pdf,
  };
};

export const useGetFeedback = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const { pg_transaction_id } = useParams();
  // const { getUrlQuery } = useUrlQuery();
  // const { proposal_id } = useParams();
  const { mutate } = useMutation(
    (values) => {
      return api.post("/feedback/add", {
        proposal_id,
        feedback_rating: values.rating,
        feedback_text: values.description,
        status: true,
        pg_transaction_id,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          toast.success("Thanks for your review");
        }
      },
    }
  );

  return {
    getRating: mutate,
  };
};

export const useGetPisDetails = (pis_number) => {
  const { data, isLoading } = useQuery(
    ["pis-details", pis_number],
    () => {
      return api.post("pay_in_slip/list", {
        pis_number,
      });
    },
    {
      enabled: true,
    }
  );

  return {
    isPISDetailsLoading: isLoading,
    pis_details_list: data?.data?.return_data || [],
  };
};

export const useGeneratePIS = () => {
  const { mutateAsync } = useMutation((data) => {
    return api.post("/pay_in_slip/list", {
      ic_id: data,
    });
  });

  return {
    generatePIS: mutateAsync,
  };
};

export const useDetailsUsingIfscCode = () => {
  const { data, mutate, error, reset, isError, isSuccess } = useMutation(
    (payload) => {
      return api.post("ifsc/list", {
        ifsc_code: payload,
      });
    }
  );
  return {
    getDetailsFromIfsc: mutate,
    bankDetails: data?.data?.return_data,
    alertMaster: error?.response?.data?.message
      ? [error?.response?.data?.message]
      : [],
    resetMaster: reset,
    isIFSCError: isError,
    isIFSCSuccess: isSuccess,
  };
};
