import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  addressValidationOnly,
  allowOnlyAlphabetsNumberSpace,
  allowOnlyAlphabetsSpace,
  allowOnlyAplhabetNumber,
  allowOnlyNumbers,
} from "../../../../../../InputUtils/onInput";
import AlertMessage from "../../../../../../components/Alert/AlertMessage";
import { SubmitButton } from "../../../../../../components/InputComponents/InputComponents.style";
import Radio from "../../../../../../components/InputComponents/Radio";
import Select from "../../../../../../components/InputComponents/Select";
import TextInput from "../../../../../../components/InputComponents/TextInput";
import { statusOptions } from "../../../../../../constants/options";
import {
  useCreateMaster,
  useGetSortedMaster,
} from "../../../../../../services/master.service";
import { useGetStateCityFromPincode } from "../../../../../../services/pincode.service";
import {
  AddNewFieldsButton,
  AddOrRemoveButtonContainer,
  ClaimLsoHeading,
  ClaimPaperSubContainer,
  RemoveAddedFieldButton,
} from "../../../../../masters/components/MasterStyle";
import { claimLsoValidationSchema } from "./ClaimLSOValidations";
import Navbar from "../../../../../../components/Navbar";
import { usePincodeValidations } from "../../../../../../custom-hooks/usePincodeValidations";

function ClaimLSOForm({ values }) {
  const [numberOfRMs, setNumberOFRMs] = useState(
    values?.regional_manager?.length || 1
  );
  const [numberOfOfficeIncharge, setNumberOfOfficeIncharge] = useState(
    values?.office_incharge?.length || 1
  );
  const [numberOfBrokerManager, setNumberOFBrokerManager] = useState(
    values?.broker_manager?.length || 1
  );

  const methods = useForm({
    resolver: yupResolver(claimLsoValidationSchema),
    defaultValues: {
      office_id: "",
      ic_id: values?.ic_id || "",
      lso_code: "",
      lso_name: "",
      lso_landline_no: "",
      address1: "",
      address2: "",
      ro_code: "",
      ro_name: "",
      status: "Y",
      lso_pincode: values?.pincode || "",
      lso_state_id: values?.state_id || "",
      lso_city_id: values?.city_id || "",
      regional_manager: [],
      office_incharge: [],
      broker_manager: [],
      ...values?.[0],
      ...values,
    },
  });

  const pincode = methods.watch("lso_pincode");
  const regional_manager = methods.watch("regional_manager") || [];
  const office_incharge = methods.watch("office_incharge") || [];
  const broker_manager = methods.watch("broker_manager") || [];

  const {
    createItem,
    isSuccess,
    alertMaster,
    resetMaster,
    isError,
    isLoading,
  } = useCreateMaster("claim_lso");

  useEffect(() => {
    if (isSuccess) {
      methods.reset({
        lso_code: "",
        ic_id: "",
        address1: "",
        address2: "",
        lso_pincode: "",
        lso_state_id: "",
        lso_city_id: "",
        status: "Y",
        regional_manager: [],
        office_incharge: [],
        broker_manager: [],
      });
    }
  }, [isSuccess]);

  const {
    getCityState,
    state_list,
    city_list,
    isStateCitySuccess,
    resetStateCity,
  } = useGetStateCityFromPincode(pincode);

  useEffect(() => {
    if (pincode?.length === 6) {
      getCityState(pincode);
    } else {
      resetStateCity();
    }
  }, [pincode]);

  usePincodeValidations({
    pincode_field_name: "lso_pincode",
    pincode,
    state_list,
    city_list,
    formMethods: methods,
    isSuccess: isStateCitySuccess,
  });

  const { table_list: ic_items } = useGetSortedMaster({
    master_name: "insurance",
    sortBy: "insurance_name",
  });

  const ic_list = useMemo(() => {
    return ic_items.map((item) => {
      return {
        code: item.insurance_id,
        display_name: item.insurance_name,
      };
    });
  }, [ic_items]);

  const onSubmit = (data) => {
    createItem(data);
  };

  return (
    <Navbar>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Container maxWidth="xl">
            <Grid container spacing={2} mt={2}>
              <Grid item xs={3}>
                <Typography variant="h4" align="left">
                  Claim LSO Form
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color={"text.secondary"}
                >
                  Claim LSO can be created here
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <AlertMessage
                  alert={alertMaster}
                  reset={resetMaster}
                  isError={isError}
                  isSuccess={isSuccess}
                />
                <ClaimPaperSubContainer>
                  <ClaimLsoHeading>LSO Details</ClaimLsoHeading>
                  <Grid container spacing={2}>
                    <Grid item xxs={12} xs={6} md={6} lg={3}>
                      <Select
                        label="Insurance Company"
                        name="ic_id"
                        required={true}
                        options={ic_list}
                      />
                    </Grid>

                    <Grid item xxs={12} xs={6} md={6} lg={3}>
                      <TextInput
                        label="Office ID"
                        name="office_id"
                        required={true}
                        inputProps={{ maxLength: 15 }}
                        onInput={allowOnlyAplhabetNumber}
                      />
                    </Grid>

                    <Grid item xxs={12} xs={6} md={6} lg={3}>
                      <TextInput
                        label="LSO Code"
                        name="lso_code"
                        required={true}
                        inputProps={{ maxLength: 15 }}
                        onInput={allowOnlyAplhabetNumber}
                      />
                    </Grid>

                    <Grid item xxs={12} xs={6} md={6} lg={3}>
                      <TextInput
                        label="LSO Name"
                        name="lso_name"
                        required={true}
                        inputProps={{ maxLength: 50 }}
                        onInput={allowOnlyAlphabetsSpace}
                      />
                    </Grid>

                    <Grid item xxs={12} xs={6} md={6} lg={3}>
                      <TextInput
                        label="LSO Landline No."
                        name="lso_landline_no"
                        // required={true}
                        onInput={allowOnlyNumbers}
                        inputProps={{ maxLength: 11 }}
                      />
                    </Grid>

                    <Grid item xxs={12} xs={6} md={6} lg={3}>
                      <TextInput
                        label="Address Line 1"
                        name="address1"
                        required={true}
                        inputProps={{ maxLength: 50 }}
                        onInput={addressValidationOnly}
                      />
                    </Grid>

                    <Grid item xxs={12} xs={6} md={6} lg={3}>
                      <TextInput
                        label="Address Line 2"
                        name="address2"
                        inputProps={{ maxLength: 50 }}
                        onInput={addressValidationOnly}
                      />
                    </Grid>
                    <Grid item xxs={12} xs={6} md={6} lg={3}>
                      <TextInput
                        label="Address Line 3"
                        name="address3"
                        inputProps={{ maxLength: 50 }}
                        onInput={addressValidationOnly}
                      />
                    </Grid>
                    <Grid item xxs={12} xs={6} md={6} lg={3}>
                      <TextInput
                        label="LSO Pincode"
                        name="lso_pincode"
                        required={true}
                        onInput={allowOnlyNumbers}
                        inputProps={{ maxLength: 6 }}
                      />
                    </Grid>

                    <Grid item xxs={12} xs={6} md={6} lg={3}>
                      <Select
                        label="LSO State Name"
                        name="lso_state_id"
                        required={true}
                        options={state_list}
                      />
                    </Grid>

                    <Grid item xxs={12} xs={6} md={6} lg={3}>
                      <Select
                        label="LSO City Name"
                        name="lso_city_id"
                        required={true}
                        options={city_list}
                      />
                    </Grid>

                    <Grid item xxs={12} xs={6} md={6} lg={3}>
                      <TextInput
                        label="RO Code"
                        name="ro_code"
                        onInput={allowOnlyAplhabetNumber}
                        inputProps={{ maxLength: 15 }}
                        required={true}
                      />
                    </Grid>

                    <Grid item xxs={12} xs={6} md={6} lg={3}>
                      <TextInput
                        label="RO Name"
                        name="ro_name"
                        inputProps={{ maxLength: 50 }}
                        onInput={allowOnlyAlphabetsNumberSpace}
                        required={true}
                      />
                    </Grid>
                  </Grid>
                </ClaimPaperSubContainer>
                <ClaimPaperSubContainer>
                  <ClaimLsoHeading>Regional Manager</ClaimLsoHeading>
                  <Grid container spacing={2}>
                    {Array.from(Array(numberOfRMs).keys())?.map((item) => {
                      const isAnySelected =
                        regional_manager[item] &&
                        Object.values(regional_manager[item])?.some(
                          (item) => !!item
                        );
                      return (
                        <>
                          <Grid item xxs={12} xs={6} md={6} lg={4}>
                            <TextInput
                              label={`RM ${item + 1} Name`}
                              name={`regional_manager.${item}.name`}
                              inputProps={{ maxLength: 50 }}
                              onInput={allowOnlyAlphabetsSpace}
                              required={isAnySelected}
                            />
                          </Grid>

                          <Grid item xxs={12} xs={6} md={6} lg={4}>
                            <TextInput
                              label={`RM ${item + 1} Contact No.`}
                              name={`regional_manager.${item}.contact_no`}
                              onInput={allowOnlyNumbers}
                              inputProps={{ maxLength: 10 }}
                              required={isAnySelected}
                            />
                          </Grid>

                          <Grid item xxs={12} xs={6} md={6} lg={4}>
                            <TextInput
                              label={`RM ${item + 1} Email ID`}
                              name={`regional_manager.${item}.email`}
                              required={isAnySelected}
                            />
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                  <AddOrRemoveButtonContainer>
                    {numberOfRMs > 1 && (
                      <RemoveAddedFieldButton
                        onClick={() => {
                          setNumberOFRMs((prevNumber) => prevNumber - 1);
                        }}
                      >
                        Remove
                      </RemoveAddedFieldButton>
                    )}
                    {numberOfRMs < 5 && (
                      <AddNewFieldsButton
                        id="add_more_claim_lso"
                        onClick={() =>
                          setNumberOFRMs((prevNumber) => prevNumber + 1)
                        }
                      >
                        Add More
                      </AddNewFieldsButton>
                    )}
                  </AddOrRemoveButtonContainer>
                </ClaimPaperSubContainer>
                <ClaimPaperSubContainer>
                  <ClaimLsoHeading>Office Incharge</ClaimLsoHeading>
                  <Grid container spacing={2}>
                    {Array.from(Array(numberOfOfficeIncharge).keys())?.map(
                      (item) => {
                        const isAnySelected =
                          office_incharge[item] &&
                          Object.values(office_incharge[item])?.some(
                            (item) => !!item
                          );
                        return (
                          <>
                            <Grid item xxs={12} xs={6} md={6} lg={3}>
                              <TextInput
                                label={`Office Incharge ${
                                  item + 1
                                } Designation`}
                                name={`office_incharge.${item}.designation`}
                                inputProps={{ maxLength: 50 }}
                                onInput={allowOnlyAlphabetsSpace}
                                required={isAnySelected}
                              />
                            </Grid>

                            <Grid item xxs={12} xs={6} md={6} lg={3}>
                              <TextInput
                                label={`Office Incharge ${item + 1} Name`}
                                name={`office_incharge.${item}.name`}
                                inputProps={{ maxLength: 50 }}
                                onInput={allowOnlyAlphabetsSpace}
                                required={isAnySelected}
                              />
                            </Grid>

                            <Grid item xxs={12} xs={6} md={6} lg={3}>
                              <TextInput
                                label={`Office Incharge ${
                                  item + 1
                                } Contact No.`}
                                name={`office_incharge.${item}.contact_no`}
                                onInput={allowOnlyNumbers}
                                inputProps={{ maxLength: 10 }}
                                required={isAnySelected}
                              />
                            </Grid>

                            <Grid item xxs={12} xs={6} md={6} lg={3}>
                              <TextInput
                                label={`Office Incharge ${item + 1} Email ID`}
                                name={`office_incharge.${item}.email`}
                                required={isAnySelected}
                              />
                            </Grid>
                          </>
                        );
                      }
                    )}
                  </Grid>
                  <AddOrRemoveButtonContainer>
                    {numberOfOfficeIncharge > 1 && (
                      <RemoveAddedFieldButton
                        onClick={() => {
                          setNumberOfOfficeIncharge(
                            (prevNumber) => prevNumber - 1
                          );
                        }}
                      >
                        Remove
                      </RemoveAddedFieldButton>
                    )}
                    {numberOfOfficeIncharge < 5 && (
                      <AddNewFieldsButton
                        onClick={() =>
                          setNumberOfOfficeIncharge(
                            (prevNumber) => prevNumber + 1
                          )
                        }
                      >
                        Add More
                      </AddNewFieldsButton>
                    )}
                  </AddOrRemoveButtonContainer>
                </ClaimPaperSubContainer>
                <ClaimPaperSubContainer>
                  <ClaimLsoHeading>Broker Manager</ClaimLsoHeading>
                  <Grid container spacing={2}>
                    {Array.from(Array(numberOfBrokerManager).keys())?.map(
                      (item) => {
                        const isAnySelected =
                          broker_manager[item] &&
                          Object.values(broker_manager[item])?.some(
                            (item) => !!item
                          );
                        return (
                          <>
                            <Grid item xxs={12} xs={6} md={6} lg={4}>
                              <TextInput
                                label={`Broker Manager ${item + 1} Name`}
                                name={`broker_manager.${item}.name`}
                                inputProps={{ maxLength: 50 }}
                                onInput={allowOnlyAlphabetsSpace}
                                required={isAnySelected}
                              />
                            </Grid>

                            <Grid item xxs={12} xs={6} md={6} lg={4}>
                              <TextInput
                                label={`Broker Manager ${item + 1} Contact No.`}
                                name={`broker_manager.${item}.contact_no`}
                                onInput={allowOnlyNumbers}
                                inputProps={{ maxLength: 10 }}
                                required={isAnySelected}
                              />
                            </Grid>

                            <Grid item xxs={12} xs={6} md={6} lg={4}>
                              <TextInput
                                label={`Broker Manager ${item + 1} Email ID`}
                                name={`broker_manager.${item}.email`}
                                required={isAnySelected}
                              />
                            </Grid>
                          </>
                        );
                      }
                    )}
                  </Grid>
                  <AddOrRemoveButtonContainer>
                    {numberOfBrokerManager > 1 && (
                      <RemoveAddedFieldButton
                        onClick={() => {
                          setNumberOFBrokerManager(
                            (prevNumber) => prevNumber - 1
                          );
                        }}
                      >
                        Remove
                      </RemoveAddedFieldButton>
                    )}
                    {numberOfBrokerManager < 5 && (
                      <AddNewFieldsButton
                        onClick={() =>
                          setNumberOFBrokerManager(
                            (prevNumber) => prevNumber + 1
                          )
                        }
                      >
                        Add More
                      </AddNewFieldsButton>
                    )}
                  </AddOrRemoveButtonContainer>
                </ClaimPaperSubContainer>
                <ClaimPaperSubContainer>
                  <ClaimLsoHeading>Status</ClaimLsoHeading>
                  <Grid item xxs={12} xs={6} md={6} lg={4}>
                    <Radio
                      name="status"
                      row
                      options={statusOptions}
                      color="opposite"
                    />
                  </Grid>
                  <Grid
                    display="flex"
                    gap="20px"
                    item
                    xs={12}
                    justifyContent={"flex-end"}
                  >
                    <SubmitButton disabled={isLoading || isSuccess}>
                      Submit
                    </SubmitButton>
                  </Grid>
                </ClaimPaperSubContainer>
              </Grid>
            </Grid>
          </Container>
        </form>
      </FormProvider>
    </Navbar>
  );
}

export default ClaimLSOForm;
