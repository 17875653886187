import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import api from "../api/api";
import { removeError } from "../modules/alert.slice";
import { convertToFormData } from "../utils/convertToFormData";
import { errorHandler } from "../utils/errorHandler";
import { useGetUser } from "./AuthenticationServices";

export const useGetPolicyCancellation = (booking_id) => {
  const { data } = useQuery(
    ["policy_cancellation", booking_id],
    () => {
      return api.post(`policy_cancellation/booking_detail/list`, {
        column_filters: [
          {
            id: "booking_id",
            value: booking_id,
          },
          {
            id: "selected_id",
            value: "policy_cancellation",
          },
        ],
      });
    },
    // queryClient.invalidateQueries("policy_cancellation"),
    { enabled: true }
  );

  return { policy_cancellation_list: data?.data?.return_data || [] };
};

export const useGetPolicyCancellationApproval = (booking_id) => {
  const { data } = useQuery(
    ["policy_cancellation", booking_id],
    () => {
      return api.post(`policy_cancellation_approval/booking_detail/list`, {
        column_filters: [
          {
            id: "booking_id",
            value: booking_id,
          },
          {
            id: "selected_id",
            value: "policy_cancellation",
          },
        ],
      });
    },
    // queryClient.invalidateQueries("policy_cancellation"),
    { enabled: true }
  );

  return { policy_cancellation_list: data?.data?.return_data || [] };
};

export const useGetCalculationPolicyCancellation = (booking_id) => {
  const { data } = useQuery(
    ["calculation_policy_cancellation", booking_id],
    () => {
      return api.post(`policy_cancellation_approval/calculation_list`, {
        booking_id,
      });
    },
    { enabled: true }
  );

  return { calculation_policy_cancellation: data?.data?.return_data || [] };
};

export const useGetPolicyCancellationRequestPDF = (booking_id) => {
  const { isError, isSuccess, data, error, reset } = useQuery(
    ["cancellation_request_pdf", booking_id],
    () => {
      return api.post(`/generatePolicyCancellationPdf`, {
        booking_id,
      });
    },
    { enabled: true }
  );
  const errList = errorHandler(error, data);

  return {
    cancellation_request_pdf: data?.data?.proposalPdf || {},
    isPDFSuccess: isSuccess,
    alertMaster: errList,
    isPDFError: isError,
    isPDFReset: reset,
  };
};

export const useGetIcLoginPolicyCancellationStatusPending = (booking_id) => {
  const { data, refetch } = useQuery(
    ["ic_policy_cancellation_status_pending", booking_id],
    () => {
      return api.post(`/policy_cancellation_approval/dealer_list`, {
        booking_id,
      });
    },
    { enabled: true }
  );

  return {
    ic_policy_cancellation_pending: data?.data?.return_data || {},
    refetchData: refetch,
  };
};

export const useGetIcLoginPolicyCancellationStatusNotPending = (booking_id) => {
  const { data } = useQuery(
    ["ic_policy_cancellation_status_not_pending", booking_id],
    () => {
      return api.post(`/policy_cancellation_approval/insurance_list`, {
        booking_id,
      });
    },
    { enabled: true }
  );

  return { ic_policy_cancellation_not_pending: data?.data?.return_data || {} };
};

export const useCreateApproval = (type) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isError, isSuccess, data, error, reset, isLoading } =
    useMutation(
      (data) => {
        const formData = convertToFormData(data);
        return api.post("/policy_cancellation/add", formData);
      },

      {
        onSuccess: (res) => {
          if (res.data.status && res.data.status !== 500) {
            const timer = setTimeout(() => {
              navigate(`/cancellation/${type}_cancellation`);
              queryClient.resetQueries(["master"]);
            }, 3500);
            return () => {
              if (timer) clearTimeout(timer);
            };
          }
        },
      }
    );
  const errList = errorHandler(error, data);

  return {
    createApproval: mutate,
    isSuccess: isSuccess && data?.data?.status === 200 ? true : false,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isLoading: isLoading,
  };
};

export const useApproveApproval = (type) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => {
      const formData = convertToFormData(data);
      return api.post("/policy_cancellation_approval/add", formData);
    },

    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            navigate(`/cancellation/${type}_cancellation_approval`);
            queryClient.resetQueries(["master"]);
          }, 3500);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    approveApproval: mutate,
    isSuccess: isSuccess && data?.data?.status === 200 ? true : false,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
  };
};

export const useGetDownloadCancellationService = (booking_id) => {
  const { data } = useQuery(
    ["cancellation_document", booking_id],
    () => {
      return api.post(`/policy_cancellation_approval/dealer_list`, {
        booking_id,
      });
    },
    { enabled: true }
  );
  return { path: data?.data?.return_data[0]?.document_upload };
};

export const useCreatePolicyStatusApproval = () => {
  const navigate = useNavigate();
  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => {
      const formData = convertToFormData(data);
      return api.post("policy_status/approve", formData);
    },

    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            navigate("../../cancellation_approval");
          }, 3500);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
        // queryClient.invalidateQueries(["policy_approval"]);
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    createPolicyStatusApproval: mutate,
    isSuccess: isSuccess && data?.data?.status === 200 ? true : false,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
  };
};

export const useCreatePolicyRefund = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => {
      const formData = convertToFormData(data);
      return api.post("cancellation_status/approve", formData);
    },

    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status === 200) {
          const timer = setTimeout(() => {
            navigate("/cancellation/policy_cancellation_refund");
            queryClient.resetQueries(["master"]);
          }, 3500);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    createPolicyRefund: mutate,
    isSuccess: isSuccess && data?.data?.status === 200 ? true : false,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
  };
};

export const useUpdateReinstatement = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { data: user_data } = useGetUser();

  const isDealer = user_data?.role_name?.toLowerCase() === "dealer admin";

  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => {
      if (isDealer) {
        return api.post("/policy_reinstatement/add_reinstate_request", data);
      } else {
        return api.post("/policy_reinstatement/add", data);
      }
    },
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            dispatch(removeError({ key: id || "test" }));
          }, 3500);
          queryClient.invalidateQueries([
            "policy_reinstatement/booking_detail",
          ]);
          queryClient.invalidateQueries(["master"]);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    createItem: mutate,
    isSuccess: isSuccess,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
  };
};

export const useCreateProposalCancellation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isError, isSuccess, data, error, reset, isLoading } =
    useMutation(
      (data) => api.post("/final_proposal_detail/cancellation", data),
      {
        onSuccess: (res) => {
          if (res.data.status && res.data.status !== 500) {
            const timer = setTimeout(() => {
              navigate("/cancellation/proposal_cancellation");
            }, 3500);
            queryClient.resetQueries(["report/proposal"]);

            return () => {
              if (timer) clearTimeout(timer);
            };
          }
        },
      }
    );
  const errList = errorHandler(error, data);

  return {
    createItem: mutate,
    isSuccess: isSuccess,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isLoading: isLoading,
  };
};
