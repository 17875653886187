import { removeDuplicatesFromArray } from "./removeDuplicatesFromArray";

export const errorHandler = (error, data) => {
  let errors = [];
  if (error) {
    errors.push(error?.response?.data?.message || error?.message);
    const errorDataa = error?.response?.data?.return_data || {};
    Object.keys(errorDataa).map((err) => {
      errors.push(errorDataa[err]?.[0]);
    });
    const errorData = error?.response?.data?.errors || {};
    if (Object.keys(errorData).length) {
      errors = [];
    }
    Object.keys(errorData).map((err) => {
      errors.push(errorData[err]?.[0]);
    });
  }
  if (data) {
    if (data?.data?.status === 500) {
      const errorData = data?.data?.errors || {};
      Object.keys(errorData).map((err) => {
        errors.push(errorData[err][0]);
      });
      if (!Object.keys(errorData).length)
        errors.push(
          data?.data?.message || data?.data?.return_data?.message || ""
        );
      else {
        Object.keys(errorData).map((err) => {
          errors.push(errorData[err][0]);
        });
      }
    } else {
      const errorData = data?.data?.errors || {};
      Object.keys(errorData).map((err) => {
        errors.push(errorData[err][0]);
      });

      if (!Object.keys(errorData).length)
        errors.push(
          data?.data?.message || data?.data?.return_data?.message || ""
        );
    }
  }
  const filteredErrors = removeDuplicatesFromArray(errors);

  return filteredErrors;
};

export const reduceObj = (obj, key) => {
  const keys = key.split(".");
  let temp = obj[keys[0]];
  for (let i = 1; i < keys.length; ++i) {
    if (temp) temp = temp[keys[i]];
    else return undefined;
  }
  return temp;
};
