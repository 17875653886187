import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  useGetPolicyPremiumCalculationFields,
  useSendIcDetails,
} from "../../../../../services/icConfigurator.service";
import MasterFormWrapper from "../../../../masters/components/MasterFormWrapper/MasterFormWrapper";
import {
  IcConfigPremiumCalculationContainer,
  // PremiumCalculationEditButton,
  PremiumCalculationTab,
} from "../../IcConfiguratorStyle";
import ICFormStepper from "../../components/ICFormStepper";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import ClearIcon from "@mui/icons-material/Clear";
import { useEffect } from "react";
import {
  allowOnlyMathFormula,
  allowOnlyNumbers,
} from "../../../../../InputUtils/onInput";
import AutoSuggestTextInput from "../../../../../components/InputComponents/AutoSuggestTextInput";
import Select from "../../../../../components/InputComponents/Select";
import TextInput from "../../../../../components/InputComponents/TextInput";

function PolicyPremiumCalculations({ values, isBrokerLogin }) {
  // const { id } = useParams();
  const { sendIcDetails, isSuccess, alertMaster, resetMaster, isError } =
    useSendIcDetails("ic_premium_calculation");

  const { policy_premium_fields, policy_premium_parameters } =
    useGetPolicyPremiumCalculationFields();

  const policyPremiumCalculationSchema = (policy_premium_fields) => {
    return yup.object().shape(
      policy_premium_fields?.reduce((schemaObj, field) => {
        const { field_slug, premium_field_name } = field;

        return {
          ...schemaObj,
          [field_slug]: yup
            .string()
            .required(`${premium_field_name} is required`),
        };
      }, {})
    );
  };

  function getDefaultEmptyStrings(policy_premium_fields) {
    const defaultValues = {};
    policy_premium_fields?.forEach((field) => {
      defaultValues[field?.field_slug] = ""; // Set the default value to an empty string.
    });
    return defaultValues;
  }

  const methods = useForm({
    resolver: yupResolver(
      policyPremiumCalculationSchema(policy_premium_fields)
    ),
    defaultValues: {
      od_discount: "Basic OD Premium * Rate ",
      ncb: "((Basic OD Premium - OD Discount ) + Elect Acc Premium + Non Elect Premium  + Bifuel Premium - Voluntory Excess - Anti Theft Device - AA Membership) * Rate",
      voluntary_excess: "(Basic OD Premium - OD Discount ) * Rate",
      anti_theft: "(Basic OD Premium - OD Discount ) * Rate",
      handicap:
        "((Basic OD Premium - OD Discount ) + Elect Acc Premium + Non Elect Premium  + Bifuel Premium - Voluntory Excess - Anti Theft Device - AA Membership - NCB) * Rate",
      aa_membership: "(Basic OD Premium - OD Discount ) * Rate",
      confined_to_site: "(Basic OD Premium - OD Discount ) * Rate",
      on_condition: getDefaultEmptyStrings(policy_premium_fields),
      // ...values,
    },
  });

  useEffect(() => {
    methods.reset({ ...values });
  }, [values]);

  const onSubmit = (data) => {
    // To remove flags property
    delete data.flags;
    sendIcDetails(data);
  };

  const defaultFlagsForEdit = policy_premium_fields?.reduce((prev, item) => {
    return {
      ...prev,
      [item.field_slug]: true,
    };
  }, {});

  useEffect(() => {
    methods.reset({
      flags: {
        ...defaultFlagsForEdit,
      },
      od_discount: "Basic OD Premium * Rate ",
      ncb: "((Basic OD Premium - OD Discount ) + Elect Acc Premium + Non Elect Premium  + Bifuel Premium - Voluntory Excess - Anti Theft Device - AA Membership) * Rate",
      voluntary_excess: "(Basic OD Premium - OD Discount ) * Rate",
      anti_theft: "(Basic OD Premium - OD Discount ) * Rate",
      handicap:
        "((Basic OD Premium - OD Discount ) + Elect Acc Premium + Non Elect Premium  + Bifuel Premium - Voluntory Excess - Anti Theft Device - AA Membership - NCB) * Rate",
      aa_membership: "(Basic OD Premium - OD Discount ) * Rate",
      confined_to_site: "(Basic OD Premium - OD Discount ) * Rate",
      on_condition: getDefaultEmptyStrings(policy_premium_fields),
      ...values,
    });
  }, [policy_premium_fields, values]);

  const suggestionsForCalculations = policy_premium_parameters?.map(
    (parameter) => parameter?.calculation_parameter_name
  );

  return (
    <FormProvider {...methods}>
      <MasterFormWrapper
        title={"Insurance Company Details"}
        description={"Policy premium calculations can be added here"}
        onSubmit={onSubmit}
        alert={alertMaster}
        reset={resetMaster}
        isError={isError}
        isSuccess={isSuccess}
        additionalContent={<ICFormStepper isBrokerLogin={isBrokerLogin} />}
      >
        {policy_premium_fields?.map((element) => {
          // const allowEdits = methods.watch(`flags.${element?.field_slug}`);
          return (
            <Grid item xs={12} key={element?.premium_field_id}>
              <IcConfigPremiumCalculationContainer
                container
                columnSpacing={3}
                rowSpacing={3}
              >
                <Grid item xxs={2}>
                  <PremiumCalculationTab>
                    {element?.premium_field_name}
                  </PremiumCalculationTab>
                </Grid>
                <Grid item xs={5.6} position={"relative"}>
                  <AutoSuggestTextInput
                    name={`${element?.field_slug}`}
                    // readOnly={id && allowEdits}
                    readOnly
                    onInput={(e) => {
                      allowOnlyMathFormula(e);
                    }}
                    suggestions={suggestionsForCalculations}
                  />
                  {/* {id && (
                    <>
                      {allowEdits ? (
                        <PremiumCalculationEditButton
                          key={element?.field_slug}
                          onClick={() =>
                            methods.setValue(
                              `flags.${element?.field_slug}`,
                              false
                            )
                          }
                        >
                          <EditOutlinedIcon style={{ fontSize: "12px" }} />
                        </PremiumCalculationEditButton>
                      ) : (
                        <PremiumCalculationEditButton
                          onClick={() =>
                            methods.setValue(
                              `flags.${element?.field_slug}`,
                              true
                            )
                          }
                        >
                          <ClearIcon style={{ fontSize: "12px" }} />
                        </PremiumCalculationEditButton>
                      )}
                    </>
                  )} */}
                </Grid>
                <Grid
                  style={{
                    padding: "2px 5px",
                    paddingLeft: "9px",
                    alignSelf: "flex-end",
                    justifyContent: "center",
                    fontSize: "13px",
                  }}
                  item
                  xxs={0.2}
                  xs={0.2}
                  md={0.2}
                  lg={0.2}
                >
                  {"Or"}
                </Grid>
                <Grid item xxs={1.6} xs={1.6} md={1.6} lg={1.6}>
                  <TextInput
                    name={`amount.${element?.field_slug}`}
                    placeholder="Amount"
                    onInput={allowOnlyNumbers}
                    inputProps={{
                      maxLength: 9,
                    }}
                    readOnly={isBrokerLogin}
                  />
                </Grid>
                <Grid item xxs={2.5} xs={2.5} md={2.5} lg={2.5}>
                  <Select
                    placeholder="On Condition"
                    name={`on_condition.${element?.field_slug}`}
                    options={[
                      {
                        code: "Which Ever is Less",
                        display_name: "Which Ever is Less",
                      },
                      {
                        code: "Which Ever is More",
                        display_name: "Which Ever is More",
                      },
                    ]}
                    readOnly={isBrokerLogin}
                  />
                </Grid>
              </IcConfigPremiumCalculationContainer>
            </Grid>
          );
        })}
      </MasterFormWrapper>
    </FormProvider>
  );
}

export default PolicyPremiumCalculations;
