import { Box, Paper, styled } from "@mui/material";
import React from "react";
import { SubmitButton } from "../../../../../components/InputComponents/InputComponents.style";
import { ButtonWrapper } from "../../../../masters/components/MasterStyle";
import ClaimCommonDocuments from "../../../components/ClaimCommonDocuments";
import { ClaimHeading, MainPaperContainer } from "../../../styles/claim.styles";
import AccidentDetailsForm from "../../claimIntimation/components/forms/AccidentDetailsForm";
import ContactDetailsForm from "../../claimIntimation/components/forms/ContactDetailsForm";

function ClaimIntimationForm({ alertMsg, isMobile }) {
  return (
    <Box>
      <MainPaperContainer>
        <ClaimHeading>Accident Details</ClaimHeading>
        <AccidentDetailsForm />

        {/* contact details */}
        <Box mt={2}>
          <ClaimHeading>Contact Details</ClaimHeading>
          <ContactDetailsForm />
        </Box>
      </MainPaperContainer>

      {isMobile && (
        <Paper
          elevation={0}
          sx={{
            borderRadius: "18px",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
            height: "fit-content",
            padding: "1.2rem",
            margin: "15px 0",
          }}
        >
          <ClaimCommonDocuments />
        </Paper>
      )}
      <ClaimButtonWrapper>
        <SubmitButton
          variant="contained"
          type="submit"
          disabled={alertMsg?.isLoading || alertMsg?.isSuccess}
        >
          Submit
        </SubmitButton>
      </ClaimButtonWrapper>
    </Box>
  );
}

export default ClaimIntimationForm;

const ClaimButtonWrapper = styled(ButtonWrapper)({
  display: "flex",
  gap: "10px",
  justifyContent: "center",
  alignItems: "center",
});
