import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  proposalData: [],
  paymentPopup: false,
  activeProposal: 0,
  activeTab: 0,
  activeSummary: 0,
  traceIdForClone: "",
  proposalUniqueIdForClone: "",
  openCloneProposalDialog: false,
  showMultipleProposalPayment: false,
  proposalMsg: {
    msg: "",
    type: "",
  },
  proposalSummaryPdfUrl: "",
};

const proposalSlice = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    addProposal(state, action) {
      state.proposalData = { ...state.proposalData, ...action.payload };
    },
    setShowPaymentPopup(state, action) {
      state.paymentPopup = action.payload;
    },
    setActiveProposal(state, action) {
      state.activeProposal = action.payload;
    },
    setActiveSummary(state, action) {
      state.activeSummary = action.payload;
    },
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    resetProposalStates(state) {
      state.proposalData = [];
      state.paymentPopup = false;
      state.activeProposal = 0;
      state.activeSummary = 0;
      state.proposalMsg = {};
    },
    setProposalMsg: (state, action) => {
      state.proposalMsg = action.payload;
    },
    setOpenCloneProposalDialog: (state, action) => {
      state.openCloneProposalDialog = action.payload;
    },
    setShowMultipleProposalPayment: (state, action) => {
      state.showMultipleProposalPayment = action.payload;
    },
    setTraceIdForClone: (state, action) => {
      state.traceIdForClone = action.payload;
    },
    setProposalUniqueIdForClone: (state, action) => {
      state.proposalUniqueIdForClone = action.payload;
    },
    setProposalSummaryPdfUrl: (state, action) => {
      state.proposalSummaryPdfUrl = action.payload;
    },
  },
});

export const {
  addProposal,
  setShowPaymentPopup,
  setActiveProposal,
  setActiveSummary,
  setActiveTab,
  setProposalMsg,
  resetProposalStates,
  setOpenCloneProposalDialog,
  setShowMultipleProposalPayment,
  setProposalUniqueIdForClone,
  setTraceIdForClone,
  setProposalSummaryPdfUrl,
} = proposalSlice.actions;

export default proposalSlice.reducer;
