import React from "react";
import { Papers } from "../masters/components/MasterStyle";
import { Box, Chip, Typography } from "@mui/material";
import moment from "moment";
import { capitalizeAndReplaceUnderscore } from "../../utils/underscoreToSpaceSeparation";

const FeedCard = ({ PreGetFeedFileList, getFeedExport }) => {
  return (
    <>
      {PreGetFeedFileList?.map((dateObject) => {
        const date = Object.keys(dateObject)[0];
        const data = dateObject[date];
        const masters = [];
        const transactions = [];

        data.forEach((item) => {
          if (item.master) {
            masters.push(...item.master);
          }
          if (item.transaction) {
            transactions.push(...item.transaction);
          }
        });

        if (masters.length === 0 && transactions.length === 0) {
          return null;
        }

        return (
          <Papers key={date} id={date}>
            <Typography
              variant="subtitle1"
              align="left"
              fontWeight="bold"
              id={`innerdate_${date}`}
            >
              Date: {moment(date).format("DD-MMM-YYYY")}
            </Typography>
            <Box sx={{ pb: 0.3, mb: 0.3 }}></Box>
            <Box display="flex" sx={{ gap: 2 }}>
              <Box sx={{ width: "100%" }}>
                {masters.length > 0 && (
                  <Typography variant="subtitle1" align="left">
                    <span style={{ fontWeight: "bold" }}>Masters</span> -
                    {masters?.map((master) => (
                      <Chip
                        key={master}
                        id={master}
                        label={capitalizeAndReplaceUnderscore(master)}
                        variant="outlined"
                        size="small"
                        style={{ margin: "5px" }}
                        onClick={() =>
                          getFeedExport({
                            from_date: date,
                            to_date: date,
                            table_name: master,
                          })
                        }
                      />
                    ))}
                  </Typography>
                )}
                {transactions.length > 0 && (
                  <Typography variant="subtitle1" align="left">
                    <span style={{ fontWeight: "bold" }}>Transactions</span> -
                    {transactions?.map((transaction) => (
                      <Chip
                        key={transaction}
                        id={transaction}
                        label={capitalizeAndReplaceUnderscore(transaction)}
                        variant="outlined"
                        size="small"
                        style={{ margin: "5px" }}
                        onClick={() =>
                          getFeedExport({
                            from_date: date,
                            to_date: date,
                            table_name: transaction,
                          })
                        }
                      />
                    ))}
                  </Typography>
                )}
              </Box>
            </Box>
          </Papers>
        );
      })}
    </>
  );
};

export default FeedCard;
