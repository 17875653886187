import { Outlet } from "react-router";
import withRouteProtection from "../../../hoc/withRouteProtection";
import Navbar from "../../../components/Navbar";

const FormsICConfigurator = () => {
  return (
    <Navbar>
      <Outlet />
    </Navbar>
  );
};

export default withRouteProtection(FormsICConfigurator);
// export default FormsICConfigurator;
