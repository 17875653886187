import React, { useEffect } from "react";
import {
  DocumentWrapper,
  RightDetailsContainer,
  RightDetailsSubContainer,
  TitleWithBlueBlock,
} from "../CancellationApproval/CancellationApprovalStyles";
import { FormProvider, useForm } from "react-hook-form";
import {
  ButtonWrapper,
  Heading,
} from "../../../masters/components/MasterStyle";
import Buttons from "../../../../components/BasicFormComponent/Buttons";
import { Box, Grid, styled } from "@mui/material";
import {
  useCreatePolicyRefund,
  useGetDownloadCancellationService,
  useGetIcLoginPolicyCancellationStatusPending,
} from "../../../../services/policy_cancellation.service";
import moment from "moment";
import { RightCancellationRefundValidationSchema } from "../CancellationApproval/RightCancellationApprovalValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import AlertMessage from "../../../../components/Alert/AlertMessage";
import TextInput from "../../../../components/InputComponents/TextInput";
import { allowOnlyCapitalizeAlphaNumeric } from "../../../../InputUtils/onInput";
import DateInput from "../../../../components/InputComponents/DateInput";

const RightCancellationApprovedSummary = ({ booking_id }) => {
  const methods = useForm({
    resolver: yupResolver(RightCancellationRefundValidationSchema),
  });
  const { createPolicyRefund, isSuccess, alertMaster, resetMaster, isError } =
    useCreatePolicyRefund();
  const { ic_policy_cancellation_pending, refetchData } =
    useGetIcLoginPolicyCancellationStatusPending(booking_id);
  // console.log("ic_policy_cancellation_pending", ic_policy_cancellation_pending);

  const onSubmit = (data) => {
    const payload = {
      ...data,
      policy_cancellation_id:
        ic_policy_cancellation_pending[0]?.policy_cancellation_id,
      refund_status: "Refunded",
    };
    createPolicyRefund(payload);
    // console.log("submitted data", payload);
  };
  const dateFormat = (date) => {
    const momentObj = moment(date, "YYYY-MM-DD");
    return momentObj.format("DD-MM-YYYY");
  };
  const { path } = useGetDownloadCancellationService(booking_id);

  const noRefund =
    ic_policy_cancellation_pending[0]?.refund_favour
      ?.toLowerCase()
      .replace(/\s|_/g, "") === "norefund" ||
    ic_policy_cancellation_pending[0]?.refund_method
      ?.toLowerCase()
      .replace(/\s|_/g, "") === "norefund";

  useEffect(() => {
    if (
      !ic_policy_cancellation_pending[0]?.refund_method ||
      !ic_policy_cancellation_pending[0]?.refund_favour
    )
      refetchData();
  }, []);

  return (
    <RightDetailsContainer>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <RightDetailsSubContainer>
            <Heading sx={{ fontSize: "17px" }}>
              Cancellation Approved Details
            </Heading>
            <Grid container spacing={2} columns={6} mt={0} px={2}>
              <AlertMessage
                alert={alertMaster}
                reset={resetMaster}
                isError={isError}
                isSuccess={isSuccess}
              />
              <Grid item xs={3}>
                <Item>Cancellation No.</Item>
                <Value>
                  {ic_policy_cancellation_pending[0]?.cancellation_no}
                </Value>
              </Grid>
              <Grid item xs={3}>
                <Item>Cancellation Date</Item>
                <Value>
                  {dateFormat(ic_policy_cancellation_pending[0]?.created_at)}
                </Value>
              </Grid>
              <Grid item xs={3}>
                <Item>PIS Status</Item>
                <Value>{ic_policy_cancellation_pending[0]?.pis_status}</Value>
              </Grid>
              <Grid item xs={3}>
                <Item>Vehicle Delivered from the Showroom</Item>
                <Value>
                  {ic_policy_cancellation_pending[0]?.vehicle_delivered_status}
                </Value>
              </Grid>
              <Grid item xs={3}>
                <Item>Cancellation Reason</Item>
                <Value>
                  {ic_policy_cancellation_pending[0]?.cancellation_reason
                    ?.replace(/_/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </Value>
              </Grid>
              <Grid item xs={3}>
                <Item>Proof of New Insurance submitted (For Part B)</Item>
                <Value>{ic_policy_cancellation_pending[0]?.proof}</Value>
              </Grid>
              <Grid item xs={3}>
                <Item>Refund In Favour of</Item>
                <Value>
                  {ic_policy_cancellation_pending[0]?.refund_favour
                    ?.replace(/_/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </Value>
              </Grid>
              {ic_policy_cancellation_pending[0]?.refund_favour
                ?.toLowerCase()
                .replace(/\s|_/g, "") === "norefund" ? (
                <Grid item xs={3}></Grid>
              ) : (
                <Grid item xs={3}>
                  <Item>Refund method</Item>
                  <Value>
                    {ic_policy_cancellation_pending[0]?.refund_method
                      ?.replace(/_/g, " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                  </Value>
                </Grid>
              )}
              <Grid item xs={3}>
                <Item>
                  OD Balance Days <br />{" "}
                  <span style={{ fontSize: "13px" }}>
                    (OD End Date - Cancellation Request Date)
                  </span>
                </Item>
                <Value>
                  {ic_policy_cancellation_pending[0]?.od_balance_day}
                </Value>
              </Grid>
              <Grid item xs={3}>
                <Item>
                  TP Balance Days <br />{" "}
                  <span style={{ fontSize: "13px" }}>
                    (TP End Date - Cancellation Request Date)
                  </span>
                </Item>
                <Value>
                  {ic_policy_cancellation_pending[0]?.tp_balance_day}
                </Value>
              </Grid>
              <Grid item xs={3}>
                <Item>1st Year Policy OD Premium to be Refunded (Rs.)</Item>
                <Value>
                  {Math.round(ic_policy_cancellation_pending[0]?.od_refund)}
                </Value>
              </Grid>
              <Grid item xs={3}>
                <Item>1st Year Policy TP Premium to be Refunded (Rs.)</Item>
                <Value>
                  {Math.round(ic_policy_cancellation_pending[0]?.tp_refund)}
                </Value>
              </Grid>
              <Grid item xs={3}>
                <Item>1st Year Policy GST to be Refunded (Rs.)</Item>
                <Value>
                  {Math.round(ic_policy_cancellation_pending[0]?.gst_refund)}
                </Value>
              </Grid>
              <Grid item xs={3}>
                <Item>Approved Refund Amount (Rs.)</Item>
                <Value>
                  {Math.round(
                    ic_policy_cancellation_pending[0]?.approve_amount
                  )}
                </Value>
              </Grid>
              <Grid item xs={3}>
                <Item>Remarks for Variance in Approved Ammount</Item>
                <Value>{ic_policy_cancellation_pending[0]?.remarks}</Value>
              </Grid>
              <Grid item xs={3}>
                <Item>64 VB Status</Item>
                <Value>{ic_policy_cancellation_pending[0]?.vb64}</Value>
              </Grid>

              <Grid container spacing={2} columns={6} mt={3} px={4}>
                <Grid md={12} sm={12} xs={12} xxs={12}>
                  <TitleWithBlueBlock>
                    Review Uploaded Documents
                  </TitleWithBlueBlock>

                  {path?.map((doc_path, idx) => {
                    return (
                      <Box sx={{ display: "flex", gap: "10px" }} key={idx}>
                        <a
                          rel="noreferrer"
                          target={"_blank"}
                          href={doc_path}
                          style={{
                            color: "#394d51",
                            display: "block",
                            margin: "5px 0",
                          }}
                        >
                          <DocumentWrapper style={{ cursor: "pointer" }}>
                            View
                          </DocumentWrapper>
                        </a>
                        <a
                          rel="noreferrer"
                          target={"_blank"}
                          href={doc_path}
                          style={{
                            color: "#394d51",
                            display: "block",
                            margin: "5px 0",
                          }}
                          download
                        >
                          <DocumentWrapper style={{ cursor: "pointer" }}>
                            Download
                          </DocumentWrapper>
                        </a>
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </RightDetailsSubContainer>
          {!noRefund && (
            <>
              <RightDetailsSubContainer sx={{ marginTop: "10px" }}>
                <Heading sx={{ fontSize: "17px" }}>Refund Details</Heading>
                <Grid container spacing={2} columns={6} mt={0} px={2}>
                  <Grid item xs={3}>
                    <TextInput
                      label="Mode of refund"
                      name="mode_of_refund"
                      required={true}
                      standard
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextInput
                      label="Refund in favour"
                      name="refund_in_favour"
                      required={true}
                      standard
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextInput
                      label="Cheque no. / Transaction Id"
                      name="payment_no"
                      required={true}
                      onInput={(e) => allowOnlyCapitalizeAlphaNumeric(e)}
                      inputProps={{ maxLength: 15 }}
                      standard
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DateInput
                      label="Refund date"
                      name="refund_date"
                      required={true}
                      variant={"standard"}
                    />
                  </Grid>
                </Grid>
              </RightDetailsSubContainer>
              <ButtonWrapper gap={2}>
                <Buttons
                  label={"Submit"}
                  type="submit"
                  id="submit_button_for_policy_cancellation_refund"
                  standard
                />
              </ButtonWrapper>{" "}
            </>
          )}
        </form>
      </FormProvider>
    </RightDetailsContainer>
  );
};

export default RightCancellationApprovedSummary;

const Value = styled(Box)(() => ({
  backgroundColor: "#dbe9ed",
  marginTop: "8px",
  color: "black",
  padding: "5px",
  minHeight: "36px",
  paddingTop: "auto",
  borderBottom: "1px solid rgb(0,0,0, 0.8)",
  height: "auto",
  wordWrap: "break-word",
  overflowWrap: "break-word",
}));

const Item = styled(Box)(() => ({
  fontSize: "14px",
}));
