import { Box } from "@mui/material";
import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useOnClickOutside } from "../../custom-hooks/useOutside";
import { SuggestionWrapper } from "./InputComponents.style";
import TextInput from "./TextInput";

export function findActiveWord(inputString, cursorPosition) {
  if (cursorPosition < 0) {
    // If the cursor is at the beginning of the string, return an empty string
    return {
      activeWord: "",
      startPosition: 0,
      endPosition: cursorPosition,
    };
  }

  for (let i = cursorPosition; i >= 0; i--) {
    if (/[-+*/\s()]/.test(inputString[i])) {
      // If a space is found, return the substring from the space to the cursor position
      return {
        activeWord: inputString.substring(i + 1, cursorPosition + 1),
        startPosition: i + 1,
        endPosition: cursorPosition,
      };
    }
  }

  // If no space is found, return the substring from the start of the string to the cursor position
  return {
    activeWord: inputString.substring(0, cursorPosition + 1),
    startPosition: 0,
    endPosition: cursorPosition,
  };
}

function AutoSuggestTextInput({
  name,
  readOnly,
  onInput,
  suggestions,
  ...otherProps
}) {
  const [activeWordData, setActiveWordData] = useState({});
  const [showSuggestions, setShowSuggestions] = useState(false);

  const { watch, setValue } = useFormContext();
  const value = watch(name);

  const filteredSuggestions = suggestions?.filter((parameter) => {
    return (
      !!activeWordData?.activeWord &&
      parameter
        ?.toLowerCase()
        ?.includes(activeWordData?.activeWord?.toLowerCase())
    );
  });

  const ref = useRef();

  useOnClickOutside(ref, () => setShowSuggestions(false));

  return (
    <Box position={"relative"}>
      <TextInput
        name={name}
        readOnly={readOnly}
        onInput={(e) => {
          onInput && onInput(e);
          const cursorPosition = e.target.selectionStart - 1;
          const { activeWord, startPosition, endPosition } = findActiveWord(
            e.target.value,
            cursorPosition
          );
          setActiveWordData({
            activeWord,
            startPosition,
            endPosition,
          });
          setShowSuggestions(true);
        }}
      />
      <SuggestionWrapper
        ref={ref}
        show={showSuggestions && filteredSuggestions?.length > 0}
      >
        {filteredSuggestions?.map((suggestion) => (
          <Box
            key={suggestion}
            style={{ cursor: "pointer" }}
            onClick={() => {
              const newValue =
                value?.slice(0, activeWordData.startPosition) +
                suggestion +
                " " +
                value?.slice(activeWordData.endPosition + 1);
              setShowSuggestions(false);
              return setValue(name, newValue);
            }}
          >
            {suggestion}
          </Box>
        ))}
      </SuggestionWrapper>
    </Box>
  );
}

export default AutoSuggestTextInput;
