import moment from "moment";
import React from "react";
import { dateFormat } from "../../../../../utils/minDateForEndDate";
import { StyledGrid } from "../../../../masters/components/MasterStyle";
import {
  SummaryChild,
  SummaryHeading,
  SummarySubContainer,
  SummarySubHeading,
} from "../../../../summary/summarystyle";

function DriverDetails({ claimDetails }) {
  return (
    <SummarySubContainer>
      <StyledGrid container columns={12} spacing={0}>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Driver Age</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.driver_age}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Driver License No.
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.driver_license_no}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">License Type</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.license_type}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">State</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.place_of_issue_state_name}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Place of Issue</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.place_of_issue_city_name}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Valid Upto</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {moment(claimDetails?.valid_upto, dateFormat).format("DD-MMM-YYYY")}
          </SummarySubHeading>
        </SummaryChild>
      </StyledGrid>
    </SummarySubContainer>
  );
}

export default DriverDetails;
