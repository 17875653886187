import React from "react";
import {
  SummaryChild,
  SummaryHeading,
  SummarySubContainer,
  SummarySubHeading,
} from "../../../../summary/summarystyle";
import { StyledGrid } from "../../../../masters/components/MasterStyle";
import moment from "moment";
import { dateFormat } from "../../../../../utils/minDateForEndDate";
import { ClaimHeading, MainPaperContainer } from "../../../styles/claim.styles";
import { capitalizeAndReplaceUnderscore } from "../../../../../utils/underscoreToSpaceSeparation";

function ClaimSettlementDetails({ claimDetails }) {
  return (
    <MainPaperContainer>
      <ClaimHeading>Claim Settlement Details</ClaimHeading>
      <SummarySubContainer>
        <StyledGrid container columns={12} spacing={0}>
          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Payment Mode</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.payment_mode}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Payment Date</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {moment(claimDetails?.payment_date, dateFormat).format(
                "DD-MMM-YYYY"
              )}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Payee Type</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.payee_type}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Payee Name</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.payee_name}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Workshop Code</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.settlement_workshop_code}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">
              Workshop Pincode
            </SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.settlement_workshop_pincode}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Workshop State</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.settlement_workshop_state}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Workshop City</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.settlement_workshop_city}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Settlement Mode</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {capitalizeAndReplaceUnderscore(claimDetails?.settlement_mode)}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Payment Type</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.payment_type}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">
              Payment Transaction ID
            </SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.payment_transaction_id}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Nominee Name</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.nominee_name}
            </SummarySubHeading>
          </SummaryChild>
          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Remark</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.claim_settlement_remark}
            </SummarySubHeading>
          </SummaryChild>
        </StyledGrid>
      </SummarySubContainer>
    </MainPaperContainer>
  );
}

export default ClaimSettlementDetails;
