import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#03cc83",
      secondary: "#52495a",
      contrastText: "#fff",
    },
    secondary: {
      main: "#007aff",
    },
    green: {
      main: "#2cd44a",
      dark: "#2cd44a",
    },
    danger: {
      main: "#f44336",
    },
  },
  overrides: {
    MuiRadio: {
      root: {
        color: "#f44336",
      },
      colorSecondary: {
        "&$checked": {
          color: "#f44336",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 480,
      sm: 640,
      md: 768,
      xmd: 900,
      lg: 1024,
      xl: 1280,
      xxl: 1536,
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: "rgba(0, 0, 0, 0.7)",
        },
      },
    },
  },
});

export default theme;
