import { Box, Card, IconButton, styled, Grid, Typography } from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: "20px",
  // boxShadow: theme.shadows[5],
  height: "150px",
  position: "relative",
}));

// quick access CSS
export const QucikAccessCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#E9F4FA",
  borderRadius: "10px",
  padding: "4px",
  width: "100%",
  // minWidth: "100px",
  aspectRatio: "1/1",
  height: "80px",
  cursor: "pointer",
  transition: "all 0.25s ease",
  "&:hover": {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const TransactionTracker = styled(Box)(({ theme }) => ({
  borderRadius: "10px",
  padding: "10px",
  transition: "all 0.25s ease",
  "&:hover": {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
  },
}));

const GridChild = styled(Grid)(() => ({}));

GridChild.defaultProps = {
  ...GridChild.defaultProps,
  xxs: 6,
  xs: 6,
  sm: 4,
  md: 4,
  item: true,
};

export { GridChild };
const GridChild2 = styled(Grid)(() => ({}));

GridChild2.defaultProps = {
  ...GridChild2.defaultProps,
  xxs: 6,
  xs: 6,
  sm: 4,
  md: 6,
  item: true,
};

export { GridChild2 };

export const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "10px",
  right: "0",
  padding: "5px",
  opacity: 0.3,
  "&:hover": {
    opacity: 1,
  },
});

export const SliderHead = styled(Typography)(() => ({
  whiteSpace: "nowrap",
  fontWeight: "bold",
  color: "#111",
  fontSize: "13px",
}));
export const SliderHeadValue = styled(Typography)(() => ({
  textAlign: "left",
  color: "#111",
  fontSize: "16px",
  fontWeight: "bold",
  marginTop: "5px",
  lineHeight: "16px",
}));
export const SliderTag = styled(Typography)(() => ({
  whiteSpace: "nowrap",
  textAlign: "left",
  color: "#111",
  fontSize: "13px",
}));
export const SliderTagValue = styled(Typography)(() => ({
  whiteSpace: "nowrap",
  textAlign: "left",
  color: "#111",
  fontSize: "13px",
}));

export const QuickAccessTitleContainer = styled(Box)({
  borderBottom: "1px solid #DBE9F1",
  paddingBottom: "5px",
  marginBottom: "10px",
});

export const QuickAccessMessage = styled(Typography)({
  fontSize: "10px",
  color: "#6e8185",
  margin: "5px",
});

export const WelcomeTitleHeading = styled(Typography)({
  textTransform: "capitalize",
  fontSize: "34px",
  lineHeight: "1.235",
});

export const PresetUnnamedCoverContainer = styled(Box)({
  display: "flex",
  gap: "20px",
});
