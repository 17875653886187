import * as yup from "yup";
import { documentUploadsLessThan2MBRequired } from "../../../../../../../../utils/Validations/documentUploadsValidations";

export const deliveryOrderSchema = ({ isPreviousDetailsAvailable }) =>
  yup.object().shape({
    delivery_order_no: yup
      .string()
      .nullable()
      .required("Delivery order no. is required"),
    approved_amount: yup
      .string()
      .nullable()
      .required("Approved amount is required")
      .test("check_value", "Enter valid amount", function (value) {
        return +value > 0;
      }),
    delivery_order_document:
      !isPreviousDetailsAvailable && documentUploadsLessThan2MBRequired(),
  });
