import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import api from "../api/api";
import FormData from "form-data";
import { errorHandler } from "../utils/errorHandler";
import { useNavigate } from "react-router";

export const useGetMaster = ({
  master_name,
  columnFilters,
  globalFilter,
  pageIndex,
  pageSize,
  sorting,
}) => {
  const { data, isLoading, refetch, isError, isFetching } = useQuery(
    [master_name, columnFilters, globalFilter, pageIndex, pageSize, sorting],
    () => {
      return api.post(`${master_name}/list`, {
        params: {
          pageIndex,
          pageSize,
          search: globalFilter || null,
          sorting: JSON.stringify(sorting),
          columnFilters: JSON.stringify(columnFilters),
        },
      });
    }
  );

  // const reducedMenu = reduceChild(data?.data?.return_data);

  return {
    table_list: data?.data?.return_data || [],
    isTableLoading: isLoading,
    refetchTable: refetch,
    isTableError: isError,
    isTableFetching: isFetching,
  };
};

export const useDeleteItemMutation = (master_name) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (id) => api.delete(`${master_name}/delete/${id}`),
    {
      onSuccess: (res) => {
        if (res.data.status === 500) {
          toast.error(res.data.message);
          return;
        }
        toast.success(res.data.message);
        queryClient.invalidateQueries([master_name]);
      },
    }
  );
  return {
    deleteItem: mutate,
  };
};
//insurance
export const useCreateMaster = (master_name) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (data) => api.post(`${master_name}/add`, data),
    {
      onSuccess: (data) => {
        if (data.data.status === 500) {
          toast.error(data.data.message);
        } else {
          toast.success(data.data.message);
        }
        queryClient.invalidateQueries([master_name]);
      },
    }
  );

  return {
    createItem: mutate,
  };
};
const reduceIndex = (item) => {
  const { child, ...remaining_items } = item;
  if (child.length) {
    const reducedArray = reduceChild(child);
    return [{ ...remaining_items }, ...reducedArray.flat()];
  } else return [{ ...remaining_items }];
};
const reduceChild = (array) => {
  if (!array) return null;
  const reducedArray = array.map((item) => reduceIndex(item));
  return reducedArray.flat();
};
export const useImportExcelMutation = (master_name) => {
  const queryClient = useQueryClient();
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const { mutate, isLoading } = useMutation(
    (data) => {
      const formData = new FormData();
      formData.append("excel", data);
      return api.post(`${master_name}/upload`, formData, config);
    },
    {
      onSuccess: (data) => {
        if (data.data.status === 500) {
          toast.error(data.data.message);
        } else {
          toast.success(data.data.message);
        }
        queryClient.invalidateQueries([master_name]);
      },
    }
  );

  return {
    uploadTable: mutate,
    isUploading: isLoading,
  };
};

export const useGetapd = (payload) => {
  const { data } = useQuery(["apdlisting", payload], () => {
    return api.post("apd_approval/dealer_list", {
      ic_approval_status: payload,
    });
  });
  return { allApd: data?.data?.return_data || [] };
};

export const useSendApprovalStatus = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate, isError, isSuccess, data, error, reset, isLoading } =
    useMutation(
      (data) => {
        return api.post("apd_approval/update", data);
      },
      {
        onSuccess: (res) => {
          if (res.data.status && res.data.status !== 500) {
            const timer = setTimeout(() => {
              navigate("/approval/apd_approval/");
              queryClient.resetQueries(["master"]);
            }, 3000);
            return () => {
              if (timer) clearTimeout(timer);
            };
          }
        },
      }
    );
  const errList = errorHandler(error, data);
  return {
    sendApprovalStatus: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
    errorMessage: error?.response?.data?.message,
    isLoading: isLoading,
    // dataMaster: data,
  };
};

export const useSendDiscountApprovalStatus = () => {
  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => {
      return api.post("discount_approval/update", data);
    }
  );
  const errList = errorHandler(error, data);
  return {
    sendDiscountApprovalStatus: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
    errorMessage: error?.response?.data?.message,
  };
};

export const useSearchApdDetailsForStatement = () => {
  const { mutate, isLoading, isError, isSuccess, data, error, reset } =
    useMutation((data) => {
      return api.post("apd/all_transactions/list", data);
    });
  const errList = errorHandler(error, data);
  return {
    searchApdDetails: mutate,
    isLoading,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
    column_data: data?.data?.column_head,
    row_data: data?.data?.return_data,
  };
};
