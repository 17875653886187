import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import Textfield from "../../../components/BasicFormComponent/TextFieldInput";
import SelectAutoComplete from "../../../components/BasicFormComponent/SelectAutoComplete";
import Buttons from "../../../components/BasicFormComponent/Buttons";
import {
  useProposalCreated,
  useUpdateRenewalLead,
} from "../../../services/renewallead.service";
import Docupload from "../../../components/BasicFormComponent/Docupload";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { GridChild, StyledGrid } from "../../masters/components/MasterStyle";
import { useGetSortedMaster } from "../../../services/master.service";
import swal from "sweetalert";
import { documentUploadsOnlyImageLessThan2MBRequired } from "../../../utils/Validations/documentUploadsValidations";

export const renewalLeadValidation = yup.object().shape({
  status: yup.string().required("Status is required"),
  remarks: yup.string().required("Remark is required"),
  cheque: yup.mixed().when("status", {
    is: "cheque_pickup_schedule",
    then: documentUploadsOnlyImageLessThan2MBRequired("Cheque"),
  }),
});

function RenewalLeadPopup({ lead_id, booking_id, policy_no, setOpen }) {
  const methods = useForm({
    resolver: yupResolver(renewalLeadValidation),
  });
  const { updateRenewalLead, success } = useUpdateRenewalLead();
  const {
    // proposalCreated,
    success: isProposalCreatedSuccess,
  } = useProposalCreated();
  const isChequePickup = methods.watch("status") === "cheque_pickup_schedule";
  // const isProposalCreated = methods.watch("status") === "proposal_created";

  const { table_list: broker_setting } = useGetSortedMaster({
    master_name: "broker_setting",
    sortBy: "setting_name",
  });

  const matchConfirmationBox = broker_setting.some(
    (obj) =>
      obj.setting_name === "display_confirmation_box" &&
      obj.setting_value === "Y"
  );

  const onSubmit = (data) => {
    // const renewalPayload = {
    //   ...data,
    //   lead_id,
    // };
    const draftPayload = {
      ...data,
      lead_id,
      booking_id,
      policy_no,
    };

    // if (isProposalCreated) {
    //   proposalCreated(draftPayload);
    // } else {
    if (matchConfirmationBox && isChequePickup) {
      swal({
        text: "Please ensure you have uploaded correct and complete document",
        buttons: true,
        icon: "info",
        dangerMode: false,
      }).then((willSave) => {
        if (willSave) {
          updateRenewalLead(draftPayload);
        }
      });
    } else {
      updateRenewalLead(draftPayload);
    }
    // }
  };

  const status_list = [
    // { code: "pending", display_name: "Pending" },

    { code: "lead_generated", display_name: "Lead Generated" },
    // { code: "Call Attempt 1", display_name: "Call Attempt 1" },
    // { code: "Call Attempt 2", display_name: "Call Attempt 2" },
    // { code: "Call Attempt 3", display_name: "Call Attempt 3" },
    // { code: "Lead Generated", display_name: "Lead Generated" },
    // { code: "Dealer Attempt 1", display_name: "Dealer Attempt 1" },
    // { code: "Dealer Attempt 2", display_name: "Dealer Attempt 2" },
    // { code: "Dealer Attempt 3", display_name: "Dealer Attempt 3" },

    // {
    //   code: "cheque_pickup_schedule",
    //   display_name: "Cheque Pick up Schedule",
    // },

    { code: "proposal_created", display_name: "Proposal Created" },

    // { code: "policy_issued", display_name: "Policy Issued" },
    // { code: "lead_closed", display_name: "Lead Closed" },
  ];

  React.useEffect(() => {
    if (success || isProposalCreatedSuccess) {
      methods.reset({
        status: "",
        remarks: "",
        cheque: "",
      });
      setOpen(false);
    }
  }, [success, isProposalCreatedSuccess]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <StyledGrid columns={6} columnSpacing={4}>
            <GridChild>
              <SelectAutoComplete
                name={"status"}
                label={"Status"}
                options={status_list}
                fullWidth
              />
            </GridChild>
            {isChequePickup && (
              <GridChild>
                <Docupload label={"Upload Cheque"} name={"cheque"} />
              </GridChild>
            )}
            <GridChild>
              <Textfield name={"remarks"} label={"Remark"} />
            </GridChild>

            <Grid
              item
              xs={12}
              sm={6}
              md={12}
              lg={12}
              sx={{
                textAlign: "end",
              }}
            >
              <Buttons type={"submit"} label={"Submit"}>
                Submit
              </Buttons>
            </Grid>
          </StyledGrid>
        </form>
      </FormProvider>
    </>
  );
}

export default RenewalLeadPopup;
