import { createSlice } from "@reduxjs/toolkit";

const dashboard = createSlice({
  name: "dashboard",
  initialState: {
    showPresetForm: false,
  },
  reducers: {
    setShowPresetForm: (state, action) => {
      state.showPresetForm = action.payload;
    },
  },
});

export const { setShowPresetForm } = dashboard.actions;
export default dashboard.reducer;
