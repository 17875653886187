import React from "react";
import {
  Checkbox as Check,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { spaceToUnderscoreSeparationForId } from "../../utils/underscoreToSpaceSeparation";

function CheckBox({
  name,
  size,
  disabled = false,
  indeterminate,
  showDisabledText,
  ...props
}) {
  const {
    control,
    watch,
    trigger,
    formState: { errors },
    setValue,
  } = useFormContext();

  const config = {
    ...props,
    onChange: (e) => {
      setValue(name, e.target.checked);
    },
  };
  const value = watch(name);
  return (
    <>
      <FormControlLabel
        id={spaceToUnderscoreSeparationForId(props?.label)}
        sx={(theme) => ({
          "& .MuiFormControlLabel-label": {
            color: showDisabledText ? "#cbcdcf" : theme.palette.text.secondary,
            fontWeight: "500",
            fontSize: `${size === "small" ? "12px" : "14px"}`,
          },
        })}
        {...config}
        control={
          <Controller
            name={name}
            control={control || {}}
            render={({ field }) => {
              return (
                <Check
                  id={name}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    props.handleChange && props.handleChange(e.target.checked);
                    trigger(name);
                  }}
                  checked={
                    "checked" in props ? props.checked : !!value || false
                  }
                  size={size}
                  sx={{
                    py: size === "small" && 0.6,
                    m: size === "small" && 0,
                  }}
                  disabled={disabled}
                  indeterminate={indeterminate}
                  disableRipple
                />
              );
            }}
          />
        }
      />
      <FormHelperText
        error={Boolean(errors[name])}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 10px",
          color: "red",
        }}
      >
        <span>{errors[name]?.message}</span>
      </FormHelperText>
    </>
  );
}

export default React.memo(CheckBox);
