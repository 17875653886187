import { createSlice } from "@reduxjs/toolkit";

const reportConfig = createSlice({
  name: "reportConfig",
  initialState: {
    static_booking_fields: [],
    segment_ids: [],
    report_name: null,
    report_status: "Y",
    right_bar_edited_field: null,
    transaction_stage: "",
  },
  reducers: {
    addBookingField: (state, action) => {
      state.static_booking_fields = [
        ...state.static_booking_fields,
        action.payload,
      ];
    },
    addSegments: (state, action) => {
      state.segment_ids = action.payload;
    },

    addTransaction: (state, action) => {
      state.transaction_stage = action.payload;
    },

    addReportName: (state, action) => {
      state.report_name = action.payload;
    },
    addReporStatus: (state, action) => {
      state.report_status = action.payload;
    },
    editCurrentDetails: (state, action) => {
      state.right_bar_edited_field = action.payload;
    },
    resetReportConfigStates: (state) => {
      state.static_booking_fields = [];
      state.segment_ids = [];
      state.report_name = null;
      state.right_bar_edited_field = null;
      state.transaction_stage = "";
    },
  },
});
export const {
  addBookingField,
  addSegments,
  addReportName,
  addReporStatus,
  addTransaction,
  editCurrentDetails,
  resetReportConfigStates,
} = reportConfig.actions;
export default reportConfig.reducer;
