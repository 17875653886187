import { Box, Paper, styled, Typography } from "@mui/material";

export const MainContainer = styled(Box)({
  display: "flex",
  gap: "10px",
  margin: "15px",
});

export const LeftDetailsContainer = styled(Paper)(({ theme }) => ({
  width: "450px",
  padding: "17px",
  boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
  borderRadius: "14px",
  height: "fit-content",
  position: "sticky",
  top: "79px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const RightDetailsContainer = styled(Box)(({ theme }) => ({
  width: "70%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const RightDetailsSubContainer = styled(Paper)({
  padding: "17px",
  boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
  borderRadius: "14px",
});

export const TitleWithBlueBlock = styled(Typography)({
  fontSize: "14px",
  fontWeight: "bold",
});

export const DetailsContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const DetailsHeading = styled(Typography)({
  color: "#5A5A5A",
  fontSize: "10px",
  fontWeight: "bold",
  whiteSpace: "nowrap",
  textAlign: "right",
});

export const Details1 = styled(Typography)({
  color: "#6f6f6f",
  fontSize: "11px",
  whiteSpace: "nowrap",
  marginBottom: "auto",
  paddingRight: "10px",
});

export const Details2 = styled(Typography)({
  color: "#5A5A5A",
  fontSize: "11px",
  fontWeight: "bold",
  textAlign: "right",
  whiteSpace: "pre-wrap",
});

export const HorizontalLine = styled("hr")({
  borderBottom: "1px dotted #4f6781",
  opacity: 0.34,
  marginBottom: "5px",
});

export const DocumentWrapper = styled(Box)({
  display: "flex",
  margin: "2px",
  justifyContent: "center",
  backgroundColor: "lightgrey",
  color: "#0c363e",
  width: "100px",
  borderRadius: "5px",
  padding: "5px",
  fontSize: "13px",
});
