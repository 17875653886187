import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { Box, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { allowOnlyNumbers } from "../../../../InputUtils/onInput";
import Buttons from "../../../../components/BasicFormComponent/Buttons";
import TextFieldInput from "../../../../components/BasicFormComponent/TextFieldInput";
import Navbar from "../../../../components/Navbar";
import withRouteProtection, {
  AuthContext,
} from "../../../../hoc/withRouteProtection";
import { ClaimActionButton } from "../../../Claim/claimStyledComponents";
import { HeadingLabel } from "../../../Configurator/DiscountConfigurator/DiscountConfiguratorStyle";
import MasterTable from "../../../masters/MasterTable/MasterTable";
import {
  AtleastOnefieldError,
  ButtonWrapper,
  FormContainer,
  Papers,
} from "../../../masters/components/MasterStyle";
import { ResetButton } from "../CancellationSearch/CancellationSearchStyle";
const SearchCancellationRefund = () => {
  const [searchParam, setSearchParam] = useState({});
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      policy_no: "",
      chassis_no: "",
      engine_no: "",
      vehicle_registration_no: "",
    },
  });

  const { writable } = useContext(AuthContext);

  const onSubmit = (data) => {
    const isAnyFieldSelected = Object?.values(data).some((item) => !!item);
    if (isAnyFieldSelected) {
      setSearchParam(data);
    } else {
      methods.setError("atleast_one_field", {
        type: "custom",
        message: "Atleast one field is required",
      });
    }
  };

  const handleReset = () => {
    setSearchParam({});
    methods.reset({
      policy_no: "",
      chassis_no: "",
      engine_no: "",
      vehicle_registration_no: "",
    });
  };

  //Atleast onefield required validation pending
  const err = methods.formState.errors?.atleast_one_field?.message;
  return (
    <Navbar>
      <Box>
        <FormContainer>
          <Papers elevation={3} style={{ width: "100%" }}>
            <HeadingLabel variant="h5" align="left">
              Policy Cancellation Refund
            </HeadingLabel>

            {err && <AtleastOnefieldError>{err}</AtleastOnefieldError>}
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={1} columnSpacing={3} mt={1}>
                  <Grid item xxs={12} xs={6} md={3} lg={3}>
                    <TextFieldInput
                      name={"policy_no"}
                      label={"Policy No."}
                      onInput={allowOnlyNumbers}
                      standard
                    />
                  </Grid>

                  <Grid item xxs={12} xs={6} md={3} lg={3}>
                    <TextFieldInput
                      name={"chassis_no"}
                      label={"Chassis No."}
                      standard
                    />
                  </Grid>

                  <Grid item xxs={12} xs={6} md={3} lg={3}>
                    <TextFieldInput
                      name={"engine_no"}
                      label={"Engine No."}
                      standard
                    />
                  </Grid>
                  <Grid item xxs={12} xs={6} md={3} lg={3}>
                    <TextFieldInput
                      name={"vehicle_registration_no"}
                      label={"Registration No."}
                      standard
                    />
                  </Grid>
                </Grid>
                <ButtonWrapper
                  style={{
                    gap: "10px",
                  }}
                >
                  <Buttons
                    type="submit"
                    label="Search"
                    id="search_button_for_policy_cancellation_refund"
                  />
                  <ResetButton
                    id="reset_button_for_policy_cancellation_refund"
                    onClick={(e) => handleReset(e)}
                  >
                    <ReplayRoundedIcon />
                    Reset
                  </ResetButton>
                </ButtonWrapper>
              </form>
            </FormProvider>
          </Papers>
        </FormContainer>
        <Box>
          <MasterTable
            master_name={"cancellation_approved"}
            writable={writable}
            columnFilters={Object.keys(searchParam).map((item) => ({
              id: item,
              value: searchParam[item],
            }))}
            customRowAction={({ row }) =>
              ["refunded", "no refund"].includes(
                row?.original?.refund_status?.toLowerCase()
              ) ? (
                <></>
              ) : (
                <ClaimActionButton
                  id="view_button_for_policy_cancellation_refund"
                  onClick={() => {
                    navigate(`./add/${row?.original?.booking_id}`);
                  }}
                >
                  view
                </ClaimActionButton>
              )
            }
          />
        </Box>
      </Box>
    </Navbar>
  );
};

export default withRouteProtection(SearchCancellationRefund);
