import { Box, Button, IconButton, styled, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import MaterialReactTable from "material-react-table";
import RefreshIcon from "@mui/icons-material/Refresh";
import defaultProps from "./default";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { downloadFile } from "../../../utils/downloadFile";
import { interpreter } from "../../../utils/interpreter";
import moment from "moment";
import { formatPrice } from "../../../utils/formatPrice";
import { truncateName } from "../../../utils/truncateName";
import {
  useGetAuditLog,
  useGetAuditLogExportExcel,
} from "../../../services/auditLog.service";

const AuditLogtable = ({ writable, ...props }) => {
  const { master_name } = useParams();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const slug = props.master_name || master_name;
  const [columnFilters, setColumnFilters] = useState(
    JSON.parse(params.get("column_filters")) || []
  );
  const [columnVisibility, setColumnVisibilty] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(params.get("search") || "");
  const [sorting, setSorting] = useState(
    JSON.parse(params.get("sorting")) || []
  );
  const [columnPinning] = useState(props.columnPinning || {});
  const [pagination, setPagination] = useState({
    pageIndex: params.get("page") || 0,
    pageSize: params.get("per_page") || 10,
  });
  const { exportFile, downloadURL } = useGetAuditLogExportExcel({
    master_name: slug,
    columnFilters,
    globalFilter: globalFilter,
    sorting,
    customSearch: props.customSearch,
    pageIndex: null,
    pageSize: null,
    pis_search: props.pis_search,
  });
  useEffect(() => {
    downloadURL && downloadFile(downloadURL, false, true);
  }, [downloadURL]);

  const initialColumnFilters = props?.columnFilters?.reduce((prev, item) => {
    return {
      ...prev,
      [item.id]: item.value,
    };
  }, {});

  const newColumnFilters = columnFilters?.reduce((prev, item) => {
    return {
      ...prev,
      [item.id]: item.value,
    };
  }, {});

  const combinedObject = {
    ...initialColumnFilters,
    ...newColumnFilters,
  };

  const uniqueColumnFilters = Object.keys(combinedObject)?.map((key) => {
    let obj = {};
    obj.id = key;
    obj.value = combinedObject[key];
    return obj;
  });

  useEffect(() => {
    if (props.columnFilters?.length) {
      setColumnFilters(props.columnFilters);
    }
  }, [JSON.stringify(props.columnFilters)]);

  const {
    table_list,
    isTableLoading,
    refetchTable,
    isTableError,
    isTableFetching,
    column_head,
    total_records,
    masterError,
  } = useGetAuditLog({
    master_name: slug,
    columnFilters: uniqueColumnFilters,
    globalFilter: globalFilter,
    sorting,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    customSearch: props.customSearch,
    pis_search: props.pis_search,
  });

  useEffect(() => {
    //update the same key in the url
    //replace the search query with the new one
    pagination.pageIndex && params.set("page", pagination.pageIndex || 1);
    pagination.pageIndex && params.set("per_page", pagination.pageSize || 10);
    props.pis_search && params.set("pis_search", props.pis_search || "");
    globalFilter && params.set("search", globalFilter || "");
    sorting.length && params.set("sorting", JSON.stringify(sorting) || "");
    columnFilters.length
      ? params.set("column_filters", JSON.stringify(columnFilters))
      : params.delete("column_filters");
    props.customSearch &&
      params.set("customSearch", JSON.stringify(props.customSearch) || "");
    navigate(`${location.pathname}?${params.toString()}`);
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    props.pis_search,
    globalFilter,
    JSON.stringify(sorting),
    JSON.stringify(columnFilters),
    JSON.stringify(props.customSearch || {}),
  ]);

  const [columnOrder, setColumnOrder] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const column_head_order = [
      ...(column_head?.map((item) => item.accessorKey) || []),
    ];
    if (slug === "menu") {
      column_head_order.unshift("mrt-row-expand");
    }
    // if (writable) {
    //   column_head_order.unshift("mrt-row-actions");
    // }
    setColumnOrder([...column_head_order]);
  }, [slug, JSON.stringify(column_head), writable]);

  useEffect(() => {
    return () => {
      setSorting(JSON.parse(params.get("sorting")) || []);
      setPagination({
        pageIndex: params.get("page") || 0,
        pageSize: params.get("per_page") || 10,
      });
      setGlobalFilter("");
      setColumnFilters(JSON.parse(params.get("column_filters")) || []);
    };
  }, [
    slug,
    JSON.stringify(props.customSearch || {}),
    JSON.stringify(props.columnFilters),
  ]);

  const updated_colum_head = useMemo(() => {
    return column_head?.map((i) => {
      let modifiedObj = {};
      modifiedObj = {
        Cell: ({ cell, row }) => {
          if (cell.getValue() && typeof cell.getValue() === "string") {
            if (cell.getValue().startsWith("f(x):")) {
              return (
                <span>
                  {interpreter(
                    cell.getValue().replace("f(x):", "").trim(),
                    row.original
                  )}
                </span>
              );
            } else {
              return <span>{cell.getValue()}</span>;
            }
          }
        },
      };

      if (i.type === "img") {
        modifiedObj = {
          Cell: ({ cell }) => {
            return <img src={cell.getValue()} style={{ width: "120px" }}></img>;
          },
        };
      }
      if (i.type === "href") {
        modifiedObj = {
          Cell: ({ cell }) => {
            return <a href={cell.getValue()}>Click here</a>;
          },
        };
      }
      if (i.type === "price") {
        modifiedObj = {
          Cell: ({ cell }) => {
            return <span>{formatPrice(cell.getValue())} </span>;
          },
        };
      }
      if (i.type === "date") {
        modifiedObj = {
          Cell: ({ cell }) => {
            return (
              cell.getValue() && (
                <span>
                  {moment(cell.getValue(), "DD-MMM-YYYY")
                    ? moment(cell.getValue(), "DD-MMM-YYYY").format(
                        "DD-MMM-YYYY"
                      )
                    : moment(cell.getValue(), "DD-MM-YYYY").format(
                        "DD-MMM-YYYY"
                      )}
                </span>
              )
            );
          },
        };
      }
      if (i.type === "datetime") {
        modifiedObj = {
          Cell: ({ cell }) => {
            // To separate dates in this format --> 2023-06-13 09:52:27 To 2024-06-12 23:59:59 <--  & to handle one date value as well
            const separatedDateTime = cell?.getValue()?.split(" To ");
            return (
              <span>
                {separatedDateTime?.length > 1
                  ? `${moment(separatedDateTime?.[0]).format(
                      "DD-MMM-YYYY hh:mm:ss"
                    )} To ${moment(separatedDateTime?.[1]).format(
                      "DD-MMM-YYYY hh:mm:ss"
                    )}`
                  : moment(separatedDateTime?.toString()).format(
                      "DD-MMM-YYYY hh:mm:ss"
                    )}
              </span>
            );
          },
        };
      }
      if (i.header.toLowerCase() == "status") {
        modifiedObj = {
          Cell: ({ cell }) => {
            return cell.getValue();
          },
        };
      }
      if (i.type === "remarks") {
        modifiedObj = {
          Cell: ({ cell }) => {
            return truncateName(cell.getValue(), 50);
          },
        };
      }
      modifiedObj = {
        Cell: ({ cell, row }) => {
          if (row?.original?.keys?.includes(i.accessorKey)) {
            return (
              <span
                style={{
                  // backgroundColor: "#dbe9ed",
                  // color: "black",
                  backgroundColor: "#03cc83",
                  color: "white",
                  padding: "4px 6px",
                  borderRadius: "6px",
                }}
              >
                {cell.getValue()}
              </span>
            );
          } else {
            return <span>{cell.getValue()}</span>;
          }
        },
      };

      if (props.cellModifier)
        modifiedObj = {
          ...modifiedObj,
          ...props?.cellModifier(i),
        };

      return {
        ...i,
        ...modifiedObj,
      };
    });
  }, [column_head]);

  const updated_table_list = props.updatedTable
    ? props.updatedTable(table_list)
    : table_list;

  return (
    <>
      <Box key={slug} width={"100%"} px={2} py={2}>
        <MaterialReactTable
          {...defaultProps}
          // enableRowActions={true}
          // {...props}

          // displayColumnDefOptions={{
          //   "mrt-row-actions": {
          //     header: actionColumnCustomName || "Actions", //To change header text of action column
          //   },
          // }}
          enablePinning
          columns={[...updated_colum_head]}
          enableExpanding={slug === "menu"}
          enablePagination={updated_table_list?.length > 0}
          positionActionsColumn={"first"}
          data={updated_table_list || []}
          padding="none" //data is undefined on first render
          muiToolbarAlertBannerProps={
            isTableError && masterError?.response?.data?.message !== ""
              ? {
                  color: "error",
                  children: masterError?.response?.data?.message,
                }
              : {
                  color: "error",
                  children: "Something went wrong",
                }
          }
          muiTableContainerProps={{
            sx: {
              padding: props?.columnPinning ? "0 0 0 20px" : "0 20px",
            },
          }}
          enableGlobalFilter={props?.customSearch ? false : true}
          // muiSearchTextFieldProps={{
          //   sx: { display: props.customSearch && "none" },
          // }}
          onColumnVisibilityChange={setColumnVisibilty}
          getSubRows={(row) => row.child}
          onColumnFiltersChange={(val) => {
            setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
            setColumnFilters(val);
          }}
          onGlobalFilterChange={setGlobalFilter}
          onColumnOrderChange={setColumnOrder}
          enableColumnDragging
          enableColumnOrdering
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderTopToolbarCustomActions={() => (
            <>
              <Box display={"flex"} gap={"12px"} alignItems="center">
                <Tooltip arrow title="Refresh Data">
                  <IconButton onClick={() => refetchTable()}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
                <MasterActionsButton onClick={() => exportFile()}>
                  <FileDownloadOutlinedIcon fontSize="small" />
                  Export
                </MasterActionsButton>
              </Box>
            </>
          )}
          // enableEditing
          // editingMode={props.editingMode}
          rowCount={total_records || 10}
          initialState={{
            columnVisibility: {
              "mrt-row-actions": false,
              "mrt-row-select": false,
              "mrt-row-expand": false,
            },
          }}
          state={{
            columnFilters,
            globalFilter,
            isTableLoading,
            pagination,
            showAlertBanner: isTableError,
            showProgressBars: isTableFetching,
            sorting,
            columnOrder,
            columnPinning,
            columnVisibility: {
              ...columnVisibility,
            },
          }}
        />
      </Box>
    </>
  );
};
export default AuditLogtable;

const MasterActionsButton = styled(Button)({
  color: "#475f63",
  border: "solid 1px #bdd5da",
  display: "flex",
  gap: "4px",
  textTransform: "unset",
  fontSize: "14px",
  padding: "0px 8px !important",
  lineHeight: "16px",
  height: "30px",
});

MasterActionsButton.defaultProps = {
  variant: "outlined",
  color: "secondary",
  size: "small",
};
