import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import {
  ButtonWrapper,
  Heading,
  Papers,
} from "../masters/components/MasterStyle";
import Radio from "../../components/InputComponents/Radio";
import Buttons from "../../components/BasicFormComponent/Buttons";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { ResetButton } from "../Claim/claimStyledComponents";
import DateInput from "../../components/InputComponents/DateInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { dateFormat, minDateForEndDate } from "../../utils/minDateForEndDate";
import moment from "moment";
import withRouteProtection, {
  AuthContext,
} from "../../hoc/withRouteProtection";
import AuditLogtable from "../masters/MasterTable/AuditLogTable";
import Navbar from "../../components/Navbar";

const auditLogValidationSchema = yup.object().shape({
  range: yup.string().nullable().required("Range is required"),
  from_date: yup
    .string()
    .nullable()
    .when(["range"], {
      is: (range) => (range === "custom" ? true : false),
      then: yup.string().nullable().required("From date is required"),
    }),
  // .test("from_date", "From date should be before to date", function (value) {
  //   if (!value) return true;
  //   const { to_date } = this.parent;
  //   return moment(value, dateFormat).isBefore(
  //     moment(to_date, dateFormat)
  //   );
  // }),
  to_date: yup
    .string()
    .nullable()
    .when(["range"], {
      is: (range) => (range === "custom" ? true : false),
      then: yup.string().nullable().required("To date is required"),
    }),
  // .test("to_date", "To date should be after from date", function (value) {
  //   if (!value) return true;
  //   const { from_date } = this.parent;
  //   return moment(value, dateFormat).isAfter(
  //     moment(from_date, dateFormat)
  //   );
  // }),
});

const AuditLogForm = () => {
  // const { master_name, id } = useParams();
  // const { values, isDataLoading } = useGetMasterById(master_name);
  const { writable } = useContext(AuthContext);

  const methods = useForm({
    resolver: yupResolver(auditLogValidationSchema),
    defaultValues: {
      range: "day",
    },
  });
  const range = methods.watch("range");
  const today = moment();
  const yesterday = today.clone().subtract(1, "days").format(dateFormat);
  const tomorrow = today.clone().add(1, "days").format(dateFormat);
  const lastWeekStart = today.clone().subtract(1, "week").format(dateFormat);
  const lastMonthStart = today.clone().subtract(1, "month").format(dateFormat);
  const day = range === "day";
  const week = range === "week";
  const month = range === "month";
  const custom = range === "custom";

  const [customSearch, setCustomSearch] = useState({
    from_date: yesterday,
    to_date: tomorrow,
  });

  const onSubmit = (data) => {
    //eslint-disable-next-line
    const { range, ...dataWithoutRangeKey } = data;
    setCustomSearch({
      ...dataWithoutRangeKey,
    });
  };
  const handleReset = (e) => {
    e.preventDefault();
    methods.reset({
      section: "",
      sub_section: "",
      from_date: "",
      to_date: "",
      range: "day",
    });
  };

  const sixMonthsFromStartDate = moment(
    methods.watch("from_date"),
    dateFormat
  ).add(6, "months"); // 6 months from from_date, to set max range of 6 months

  let maxDate = today.isAfter(sixMonthsFromStartDate)
    ? sixMonthsFromStartDate
    : today;

  if (maxDate.isAfter(today)) {
    maxDate = today; // Set maxDate to today's date if it is in the future
  }

  useEffect(() => {
    // for getting today's data also, used "tomorrow" instead of "today" in to_date
    if (day) {
      methods.setValue("from_date", yesterday);
      methods.setValue("to_date", tomorrow);
    }
    if (week) {
      methods.setValue("from_date", lastWeekStart);
      methods.setValue("to_date", tomorrow);
    }
    if (month) {
      methods.setValue("from_date", lastMonthStart);
      methods.setValue("to_date", tomorrow);
    }
    if (custom) {
      methods.setValue("from_date", "");
      methods.setValue("to_date", "");
    }
  }, [day, week, month, custom, range]);

  // const itemName = `${master_name}_name`;

  return (
    <Navbar>
      <Papers elevation={3}>
        <Heading variant="h5" align="left">
          Audit log
          {/* {isDataLoading
            ? ""
            : values?.[itemName]
            ? ` for ${values?.[itemName]}`
            : ` for ${values?.name}`} */}
        </Heading>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={1} columnSpacing={3} mt={1}>
              {custom && (
                <>
                  <Grid item xxs={12} xs={12} sm={6} md={6} lg={3}>
                    <DateInput
                      label="From Date"
                      name="from_date"
                      disableFuture
                    />
                  </Grid>
                  <Grid item xxs={12} xs={12} sm={6} md={6} lg={3}>
                    <DateInput
                      label="To Date"
                      name="to_date"
                      minDate={minDateForEndDate(methods.watch("from_date"))}
                      maxDate={maxDate}
                      disableFuture
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={1} columnSpacing={3} mt={1}>
              <Grid item xxs={12} xs={12} sm={12} md={12} lg={12}>
                <Radio
                  name={"range"}
                  label={"Range"}
                  row
                  required={true}
                  variant="standard"
                  options={[
                    {
                      code: "day",
                      display_name: "1 Day",
                    },
                    { code: "week", display_name: "1 Week" },
                    { code: "month", display_name: "1 Month" },
                    { code: "custom", display_name: "Custom" },
                  ]}
                />
              </Grid>
            </Grid>
            <ButtonWrapper
              style={{
                margin: 0,
                gap: "10px",
              }}
            >
              <Buttons type="submit" label="Submit" />
              <ResetButton onClick={(e) => handleReset(e)}>
                <ReplayRoundedIcon />
                Reset
              </ResetButton>
            </ButtonWrapper>
          </form>
        </FormProvider>
      </Papers>
      <Box>
        <AuditLogtable
          master_name={"history"}
          // customSearch={customSearch}
          writable={writable}
          columnFilters={Object.keys(customSearch).map((item) => {
            return {
              id: item,
              value: customSearch[item],
            };
          })}
        />
      </Box>
    </Navbar>
  );
};

export default withRouteProtection(AuditLogForm);
