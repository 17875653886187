import { useLocation, useNavigate } from "react-router";

const useUrlQuery = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const getUrlQuery = (key) => {
    return search.get(key);
  };

  const setUrlQuery = (key, value) => {
    search.set(key, value);
    return search.toString();
  };
  return { getUrlQuery, setUrlQuery };
};

export default useUrlQuery;
