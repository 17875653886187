import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, styled } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { allowOnlyNumbers } from "../../../../../InputUtils/onInput";
import TextInput from "../../../../../components/InputComponents/TextInput";
import { useSendIcDetails } from "../../../../../services/icConfigurator.service";
import MasterFormWrapper from "../../../../masters/components/MasterFormWrapper/MasterFormWrapper";
// import { StyledGrid } from "../../../../masters/components/MasterStyle";
import ICFormStepper from "../../components/ICFormStepper";
import { ClaimCategoryRangeValidation } from "./ClaimCategoryRangeValidation";

const ClaimCategoryRange = ({ values, isBrokerLogin }) => {
  const methods = useForm({
    resolver: yupResolver(ClaimCategoryRangeValidation),
    defaultValues: { ...values },
  });

  useEffect(() => {
    methods.reset({ ...values });
  }, [values]);

  const { sendIcDetails, isSuccess, alertMaster, resetMaster, isError } =
    useSendIcDetails("claim_category_range", true);

  const onSubmit = (data) => {
    sendIcDetails(data);
  };
  return (
    <>
      <FormProvider {...methods}>
        <MasterFormWrapper
          title={"Insurance Company Details"}
          description={"Add IC API Mapping Details"}
          onSubmit={onSubmit}
          alert={alertMaster}
          reset={resetMaster}
          isError={isError}
          isSuccess={isSuccess}
          additionalContent={<ICFormStepper isBrokerLogin={isBrokerLogin} />}
        >
          <StyledGrid>
            <Grid item xxs={2}>
              <PremiumCalculationTab>
                {"Claim Category A"}
              </PremiumCalculationTab>
            </Grid>
            <Grid item xxs={12} xs={6} md={6} lg={4}>
              <TextInput
                label={"Category A Minimum Claim Amount"}
                name={"min_a"}
                onInput={allowOnlyNumbers}
                inputProps={{ maxLength: 8 }}
                numberToWord={true}
                required={true}
              />
            </Grid>
            <Grid item xxs={12} xs={6} md={6} lg={4}>
              <TextInput
                label={"Category A Maximum Claim Amount"}
                name={"max_a"}
                onInput={allowOnlyNumbers}
                inputProps={{ maxLength: 8 }}
                numberToWord={true}
                required={true}
              />
            </Grid>
          </StyledGrid>
          <StyledGrid>
            <Grid item xxs={2}>
              <PremiumCalculationTab>
                {"Claim Category B"}
              </PremiumCalculationTab>
            </Grid>
            <Grid item xxs={12} xs={6} md={6} lg={4}>
              <TextInput
                label={"Category B Minimum Claim Amount"}
                name={"min_b"}
                onInput={allowOnlyNumbers}
                inputProps={{ maxLength: 8 }}
                numberToWord={true}
                required={true}
              />
            </Grid>
            <Grid item xxs={12} xs={6} md={6} lg={4}>
              <TextInput
                label={"Category B Maximum Claim Amount"}
                name={"max_b"}
                onInput={allowOnlyNumbers}
                inputProps={{ maxLength: 8 }}
                numberToWord={true}
                required={true}
              />
            </Grid>
          </StyledGrid>
          <StyledGrid>
            <Grid item xxs={2}>
              <PremiumCalculationTab>
                {"Claim Category C"}
              </PremiumCalculationTab>
            </Grid>
            <Grid item xxs={12} xs={6} md={6} lg={4}>
              <TextInput
                label={"Category C Minimum Claim Amount"}
                name={"min_c"}
                onInput={allowOnlyNumbers}
                inputProps={{ maxLength: 8 }}
                numberToWord={true}
                required={true}
              />
            </Grid>
            <Grid item xxs={12} xs={6} md={6} lg={4}>
              <TextInput
                label={"Category C Maximum Claim Amount"}
                name={"max_c"}
                onInput={allowOnlyNumbers}
                inputProps={{ maxLength: 8 }}
                numberToWord={true}
                required={true}
              />
            </Grid>
          </StyledGrid>
        </MasterFormWrapper>
      </FormProvider>
    </>
  );
};

export default ClaimCategoryRange;
const PremiumCalculationTab = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px",
  backgroundColor: "#d3d3d3",
  // minWidth: "150px",
  minHeight: "45px",
  width: "130px",
  pointerEvents: "none",
  fontSize: "12px",
});

const StyledGrid = styled(Grid)({});
StyledGrid.defaultProps = {
  container: true,
  spacing: 2,
  mt: 1,
  ml: 0.3,
};
