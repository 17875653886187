import moment from "moment";
import React from "react";
import { dateFormat } from "../../../../../utils/minDateForEndDate";
import { StyledGrid } from "../../../../masters/components/MasterStyle";
import {
  SummaryChild,
  SummaryHeading,
  SummarySubContainer,
  SummarySubHeading,
} from "../../../../summary/summarystyle";

function SurveyorDetails({ claimDetails }) {
  return (
    <SummarySubContainer>
      <StyledGrid container columns={12} spacing={0}>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Claim Registration No.
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.claim_intimation_no}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Total Estimation Cost
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.total_estimation}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Accident Location</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.place_of_accident}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Appointment Dealer
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.dealer_name}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Surveyor Name</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.surveyor_name}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Surveyor License No.
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.surveyor_license_no}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            License Expiry Date
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {moment(claimDetails?.license_expiry_date, dateFormat).format(
              "DD-MMM-YYYY"
            )}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Mobile No.</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.srv_contact_no}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Email ID</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.srv_email_id}
          </SummarySubHeading>
        </SummaryChild>
      </StyledGrid>
    </SummarySubContainer>
  );
}

export default SurveyorDetails;
