import { Box, FormHelperText, Typography } from "@mui/material";
import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import {
  RadioLabel,
  RadioWrapper,
  InputLabel,
  RadioButton,
  RadioInput,
} from "./InputComponents.style";
// Note : (color prop)
// By default the color is 'primary' and will default to 'primary'
// use 'opposite' where you want a mix of red and green.

const Radio = ({
  required,
  defaultValue,
  options = [],
  label,
  name,
  readOnly,
  color = "primary",
  disableWrap,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <Box width="100%">
        <InputLabel title={label} required={required}>
          {label}
        </InputLabel>
        <RadioWrapper color={color} disableWrap={disableWrap}>
          {options.map((option, index) => (
            <Fragment key={index}>
              <RadioInput
                type="radio"
                color={color}
                {...register(name)}
                disabled={readOnly}
                id={`${name}-${option.code}`}
                value={option.code}
                defaultChecked={defaultValue === option.code}
                {...props}
              />
              <RadioLabel
                length={options.length}
                htmlFor={`${name}-${option.code}`}
                sx={{
                  backgroundColor: readOnly && "#dbe9ed",
                  whiteSpace: "nowrap",
                }}
              >
                <RadioButton
                  index={index}
                  color={color}
                  className="radio-button"
                  id={`${name}_${option.code}`}
                />
                <Typography variant="body1">{option.display_name}</Typography>
              </RadioLabel>
            </Fragment>
          ))}
        </RadioWrapper>
        {errors[name] && (
          <FormHelperText error>{errors[name]?.message}</FormHelperText>
        )}
      </Box>
    </>
  );
};

export default Radio;
