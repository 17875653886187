import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Tooltip, useTheme } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { removeInputDetails } from "../../../modules/input.slice";
import {
  useAddToQuickAccess,
  useGetQuickAccessTabs,
} from "../../../services/dashboard.service";

const SubMenuItems = ({ list, parent }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showQuickAccess, setShowQuickAccess] = useState(false);
  const { addToQuickAccess } = useAddToQuickAccess();

  const { quickAccessTabs } = useGetQuickAccessTabs();

  const quickAccessTabsMenuIds = quickAccessTabs?.map((item) => {
    return +item?.menu_id;
  });

  const maxSelected = quickAccessTabs?.length === 5;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: "-25px",
          transform: "translateY(-100%)",
          px: 2,
          left: "-8px",
          maxHeight: "500px",
          animation: "move-in-from-bottom 0.4s ease-in-out",
          overflow: "auto",
          whiteSpace: "nowrap",
        }}
        className="popup-scroll"
        onMouseEnter={(e) => {
          e.stopPropagation();
          setShowQuickAccess(true);
        }}
        onMouseLeave={() => {
          setShowQuickAccess(false);
        }}
      >
        {list.map((item) => {
          const isAddedToQuickAccess = quickAccessTabsMenuIds?.includes(
            item?.menu_id
          );

          return (
            <Box
              sx={{
                // transform: `rotateZ(${
                //   (list.length - 1 - index) * 3
                // }deg) translateX(${(list.length - 1 - index) * 12}px)`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                whiteSpace: "no-wrap",
                padding: "6px 12px",
                color: "black",
                fontWeight: 500,
                backgroundColor: "white",
                mb: "6px",
                minWidth: "250px",
                cursor: "pointer",
                position: "relative",
                transition: "all 0.2s",
                textAlign: "left",
                borderRadius: "10px",
                fontSize: "13px",
                "&:hover": {
                  color: "white",
                  background: theme.palette.primary.main,
                },
              }}
              onClick={() => {
                if (parent === "policy") {
                  dispatch(removeInputDetails());
                }
                navigate(`/${parent}/${item?.menu_slug}`);
              }}
              key={item?.menu_slug}
              id={item?.menu_slug}
            >
              <Box key={item?.menu_slug}>{item?.menu_name}</Box>
              <Box>
                {showQuickAccess && (
                  <>
                    {showQuickAccess && !isAddedToQuickAccess ? (
                      <Tooltip
                        arrow
                        placement="right"
                        title={
                          maxSelected
                            ? "Maximum Shortcuts Added"
                            : "Add to Quick Access"
                        }
                      >
                        <AddIcon
                          sx={{
                            color: maxSelected
                              ? "#8a8888"
                              : showQuickAccess
                              ? "black"
                              : "black",
                            "&:hover": {
                              scale: maxSelected ? 1 : 1.1,
                            },
                            fontSize: "15px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            addToQuickAccess({
                              type: "Add",
                              menu_id: item?.menu_id,
                            });
                          }}
                        ></AddIcon>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        placement="right"
                        title="Remove from Quick Access"
                      >
                        <RemoveIcon
                          sx={{
                            color: "black",
                            fontSize: "15px",
                            "&:hover": {
                              scale: "1.1",
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            addToQuickAccess({
                              type: "Remove",
                              menu_id: item?.menu_id,
                            });
                          }}
                        ></RemoveIcon>
                      </Tooltip>
                    )}
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default SubMenuItems;
