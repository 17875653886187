/*

This file includes  base validations for mobile no. 
Utilize these & add custom validations afterwards in respective files

*/

import * as yup from "yup";
import { REGEX } from "../../constants/regex";

export const mobileNumberBasic = () => {
  return yup
    .string()
    .nullable()
    .matches(REGEX.MOBILE, "Please enter a valid mobile number");
};

export const mobileNumberRequired = () => {
  return yup
    .string()
    .nullable()
    .required("Mobile number is required")
    .matches(REGEX.MOBILE, "Please enter a valid mobile number");
};

export const communicationMobileNumber = () => {
  return yup
    .string()
    .nullable()
    .required("Communication mobile no. is required")
    .matches(REGEX.MOBILE, "Please enter a valid communication mobile no.");
};

export const mobileNumberRequiredForMasters = () => {
  return yup
    .string()
    .nullable()
    .required("Mobile no. is required")
    .matches(REGEX.MOBILE, "Please enter a valid mobile no.");
};

export const phoneNumberRequired = () => {
  return yup
    .string()
    .nullable()
    .required("Phone no. is required")
    .matches(REGEX.MOBILE, "Please enter a valid phone no.");
};
