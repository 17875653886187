import { Checkbox, Stack, Typography, styled } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useGetQuote } from "../../../../services/quote.service";
import { formatPrice } from "../../../../utils/formatPrice";

function QuotesTable({ productId, bundleId, selected, handleSelectOne }) {
  let discountkey = productId;
  if (bundleId) discountkey += `/${bundleId}`;
  const selected_discount = useSelector(
    (state) => state.quote.selected_discount
  );
  const selected_discount_by_product = useSelector(
    (state) => state.quote.selected_discount_by_product
  );
  const discount_for_current =
    selected_discount_by_product[discountkey]?.discount || selected_discount;
  const discount_conf_id =
    selected_discount_by_product[discountkey]?.discount_conf_id;

  const { isLoading, quoteData, additionalPremium, allAddons } = useGetQuote({
    productId,
    bundleId,
    discount: discount_for_current,
    discount_conf_id,
  });

  const addonIgstArray = [];
  allAddons?.map((ad) => {
    ad.addon_gst && addonIgstArray.push(ad.addon_gst);
  });
  const totalAddonIgst = addonIgstArray.reduce((acc, curr) => acc + curr, 0);

  const addonSgstArray = [];
  allAddons?.map((ad) => {
    ad.addon_sgst && addonSgstArray.push(ad.addon_sgst);
  });
  const totalAddonSgst = addonSgstArray.reduce((acc, curr) => acc + curr, 0);

  const addonCgstArray = [];
  allAddons?.map((ad) => {
    ad.addon_cgst && addonCgstArray.push(ad.addon_cgst);
  });
  const totalAddonCgst = addonCgstArray.reduce((acc, curr) => acc + curr, 0);

  const gstAmount =
    (quoteData?.premium_arr?.igst || 0) +
    (quoteData?.premium_arr?.sgst || 0) +
    (quoteData?.premium_arr?.cgst || 0) +
    totalAddonIgst +
    totalAddonSgst +
    totalAddonCgst;

  if (isLoading) return <div>Loading...</div>;

  // const finalPremiumAmount = formatPrice(
  //   quoteData.premium_arr.total_premium + additionalPremium + gstAmount
  // );

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          mt={1}
        >
          <Stack>
            <img
              src={quoteData?.insu_data?.ic_logo}
              alt="logo"
              height={50}
              width={50}
            />
          </Stack>
          <Stack>
            <Typography variant="body2">
              {quoteData?.insu_data?.ic_name?.length > 10
                ? quoteData?.insu_data?.ic_name?.slice(0, 25) + "..."
                : quoteData?.insu_data?.ic_name}
            </Typography>
            <Typography variant="body2">
              Policy Type : {quoteData?.product_data?.cover_type}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <PremiumButtonStyled>
            Premium :{" "}
            {formatPrice(
              quoteData.premium_arr.net_premium + additionalPremium + gstAmount
            )}{" "}
          </PremiumButtonStyled>
          <Stack>
            <Checkbox
              size="small"
              onChange={(e) => handleSelectOne(e, quoteData)}
              checked={selected
                .map(
                  (item) =>
                    String(item?.product_data?.addon_bundle_id || 0) +
                    String(item?.product_data?.product_id)
                )
                .includes(
                  String(quoteData?.product_data?.addon_bundle_id || 0) +
                    String(quoteData?.product_data?.product_id)
                )}
            />
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}
export default QuotesTable;

const PremiumButtonStyled = styled(Stack)(({ theme }) => ({
  backgroundColor: "#f5f5f5",
  borderRadius: "0.5rem",
  fontSize: "0.8rem",
  padding: "0.5rem",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
