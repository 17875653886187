import moment from "moment";
import * as yup from "yup";
import { mobileNumberRequired } from "../../../utils/Validations/mobileNumberValidations";
import { dateFormat } from "../../../utils/minDateForEndDate";

export const AppointSurveyorSchema = yup.object().shape({
  Claim_reg_no: yup.string().nullable().required("Claim Reg. no. is required"),
  total_estimation_cost: yup
    .string()
    .nullable()
    .required("Total Estimation Cost is required"),
  place_of_accident: yup
    .string()
    .nullable()
    .required("Accident Location is required"),
  appointment_dealer: yup
    .string()
    .nullable()
    .required("Appointment Dealer is required"),
  surveyor_id: yup.string().nullable().required("Surveyor Name is required"),
  surveyor_license_no: yup
    .string()
    .nullable()
    .required("Surveyor License No. is required"),
  srv_contact_no: mobileNumberRequired(),
  srv_email_id: yup
    .string()
    .email("Please Enter Valid Email ID")
    .nullable()
    .required("Email ID is required"),

  license_expiry_date: yup
    .string()
    .nullable()
    .required("License expiry date is required")
    .test("license_expiry_date", "Date should be from future.", (val) => {
      return moment(val, dateFormat).isAfter(moment().add(1, "day"));
    }),
});
