function splitTokensIntoTree(tokens) {
  let stack = [];
  let root = { expression: [], ifTrue: null, ifFalse: null };
  let currentNode = root;

  for (let i = 0; i < tokens.length; i++) {
    let token = tokens[i];

    if (token === "?") {
      currentNode.ifTrue = { expression: [], ifTrue: null, ifFalse: null };
      stack.push(currentNode);
      currentNode = currentNode.ifTrue;
    } else if (token === ":") {
      currentNode = stack.pop().ifFalse = {
        expression: [],
        ifTrue: null,
        ifFalse: null,
      };
    } else {
      currentNode.expression.push(token);
    }
  }

  return root;
}

const generateTokens = (expression) => {
  let tokens = [];
  let isInQuotes = false;
  let currentToken = "";

  for (let i = 0; i < expression.length; i++) {
    const char = expression[i];

    if (char === '"' && (i === 0 || expression[i - 1] !== "\\")) {
      // handle quotes
      isInQuotes = !isInQuotes;
      currentToken += char;
    } else if (char === " " && !isInQuotes) {
      // handle spaces
      if (currentToken !== "") {
        tokens.push(currentToken);
        currentToken = "";
      }
    } else {
      // handle other characters
      currentToken += char;
    }
  }

  if (currentToken !== "") {
    tokens.push(currentToken);
  }

  return tokens.map((token) => token.replace(/"/g, ""));
};
function evaluateNode(node, context) {
  let postfixExpr = convertToPostfix(node.expression, context);
  let result = parseExpression(postfixExpr);
  if (typeof result === "boolean") {
    if (result) {
      if (node.ifTrue) {
        return evaluateNode(node.ifTrue, context);
      } else {
        return true;
      }
    } else {
      if (node.ifFalse) {
        return evaluateNode(node.ifFalse, context);
      } else {
        return false;
      }
    }
  } else {
    return result;
  }
}

function convertToPostfix(expression, context) {
  let operators = {
    "==": 1,
    "!=": 1,
    ">": 2,
    "<": 2,
    ">=": 2,
    "<=": 2,
    "+": 3,
    "-": 3,
    "*": 4,
    "/": 4,
    "%": 4,
    "&&": 5,
    "||": 6,
    "!": 7,
  };

  let output = [];
  let operatorStack = [];

  for (let i = 0; i < expression.length; i++) {
    let token = expression[i];

    if (isVariable(token)) {
      let value = context[token.substring(1)];
      if (!value) output.push("");
      else output.push(value.toString());
    } else if (isOperator(token)) {
      while (
        operatorStack.length > 0 &&
        operators[operatorStack[operatorStack.length - 1]] >= operators[token]
      ) {
        output.push(operatorStack.pop());
      }
      operatorStack.push(token);
    } else if (token === "(") {
      operatorStack.push(token);
    } else if (token === ")") {
      while (operatorStack[operatorStack.length - 1] !== "(") {
        output.push(operatorStack.pop());
      }
      operatorStack.pop(); // remove '(' from stack
    } else {
      output.push(token);
    }
  }

  while (operatorStack.length > 0) {
    output.push(operatorStack.pop());
  }

  return output;

  function isVariable(token) {
    return /^[$a-zA-Z_][a-zA-Z0-9_]*$/.test(token);
  }

  function isOperator(token) {
    return token in operators;
  }
}
function parseExpression(postfixExpression) {
  const stack = [];

  postfixExpression.forEach((token) => {
    if (isArithmeticOperator(token)) {
      const operand2 = stack.pop();
      const operand1 = stack.pop();
      const result = applyArithmeticOperator(token, operand1, operand2);
      stack.push(result);
    } else if (isRelationalOperator(token)) {
      const operand2 = stack.pop();
      const operand1 = stack.pop();
      const result = applyRelationalOperator(token, operand1, operand2);
      stack.push(result);
    } else if (isLogicalOperator(token)) {
      const operand2 = stack.pop();
      const operand1 = stack.pop();
      const result = applyLogicalOperator(token, operand1, operand2);
      stack.push(result);
    } else {
      stack.push(token);
    }
  });

  return stack.pop();
}

function isLogicalOperator(token) {
  return token === "&&" || token === "||";
}

function applyLogicalOperator(operator, operand1, operand2) {
  switch (operator) {
    case "&&":
      return operand1 && operand2;
    case "||":
      return operand1 || operand2;
  }
}

function isRelationalOperator(token) {
  return (
    token === "==" ||
    token === "!=" ||
    token === ">" ||
    token === "<" ||
    token === ">=" ||
    token === "<="
  );
}

function applyRelationalOperator(operator, operand1, operand2) {
  switch (operator) {
    case "==":
      return operand1 === operand2;
    case "!=":
      return operand1 !== operand2;
    case ">":
      return operand1 > operand2;
    case "<":
      return operand1 < operand2;
    case ">=":
      return operand1 >= operand2;
    case "<=":
      return operand1 <= operand2;
  }
}

function isArithmeticOperator(token) {
  return token === "+" || token === "-" || token === "*" || token === "/";
}

function applyArithmeticOperator(operator, operand1, operand2) {
  switch (operator) {
    case "+":
      return operand1 + operand2;
    case "-":
      return operand1 - operand2;
    case "*":
      return operand1 * operand2;
    case "/":
      return operand1 / operand2;
  }
}

export const interpreter = (expression, context) => {
  const tokens = generateTokens(expression);
  const ast = splitTokensIntoTree(tokens);
  return evaluateNode(ast, context);
};
