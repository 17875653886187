import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import {
  getActiveStep,
  icConfigSteps,
} from "../../../../constants/icConfigConstants";

const ICFormStepper = ({ isBrokerLogin }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  let activeStep = getActiveStep();
  const dynamicSlug = isBrokerLogin ? "broker_ic_onboard" : "ic_onboard";

  return (
    <>
      <Box
        style={{
          marginTop: "15px",
        }}
      >
        <Stepper activeStep={activeStep + 1} orientation="vertical">
          {icConfigSteps.map((step) => (
            <Step
              key={step}
              onClick={() => {
                if (!id) return;
                if (step.path === "/") {
                  return navigate(
                    `/ic_configurator/${dynamicSlug}/update/${id}`
                  );
                }
                navigate(
                  `/ic_configurator/${dynamicSlug}/update/${id + step.path}`
                );
              }}
            >
              <StepLabel
                sx={{
                  padding: "0px",
                  cursor: "pointer !important",
                }}
              >
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );
};

export default ICFormStepper;
