import React from "react";
import { StyledGrid } from "../../../../masters/components/MasterStyle";
import {
  SummaryChild,
  SummaryHeading,
  SummarySubContainer,
  SummarySubHeading,
} from "../../../../summary/summarystyle";
import { ClaimHeading, MainPaperContainer } from "../../../styles/claim.styles";
import { DocumentWrapper } from "../../../../servicing/cancellation/CancellationApproval/CancellationApprovalStyles";
import { dateFormat } from "../../../../../utils/minDateForEndDate";
import moment from "moment";

function DeliveryOrderDetails({ claimDetails }) {
  return (
    <MainPaperContainer>
      <ClaimHeading>Delivery Order Details</ClaimHeading>
      <SummarySubContainer>
        <StyledGrid container columns={12} spacing={0}>
          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">
              Delivery Order No.
            </SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.delivery_order_no}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Approved Amount</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.approved_amount}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">
              Delivery Order Date
            </SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {moment(claimDetails?.delivery_order_date, dateFormat).format(
                "DD-MMM-YYYY"
              )}
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">
              Delivery Order Document
            </SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              <a
                rel="noreferrer"
                target={"_blank"}
                href={claimDetails?.delivery_order_document}
                style={{ color: "#394d51" }}
              >
                <DocumentWrapper>View</DocumentWrapper>
              </a>
            </SummarySubHeading>
          </SummaryChild>

          <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
            <SummaryHeading variant="subtitle1">Remarks</SummaryHeading>
            <SummarySubHeading variant="subtitle2">
              {claimDetails?.delivery_order_remark}
            </SummarySubHeading>
          </SummaryChild>
        </StyledGrid>
      </SummarySubContainer>
    </MainPaperContainer>
  );
}

export default DeliveryOrderDetails;
