import styled from "@emotion/styled";
import { Box, Grid, IconButton } from "@mui/material";

export const IcConfigPremiumCalculationContainer = styled(Grid)({
  display: "flex",
  alignItems: "start",
});

export const PremiumCalculationTab = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px",
  backgroundColor: "#d3d3d3",
  // minWidth: "150px",
  minHeight: "30px",
  width: "130px",
  pointerEvents: "none",
  fontSize: "12px",
});

export const PremiumCalculationInputContainer = styled(Box)({
  position: "relative",
});

export const PremiumCalculationEditButton = styled(IconButton)({
  position: "absolute",
  right: "-30px",
  top: "30px",
  padding: "5px",
});

export const MiscellaneousNoteContainer = styled(Grid)({
  color: "#4f6781",
  display: "flex",
  alignItems: "end",
  width: "100%",
  position: "relative",
});

export const MiscellaneousFieldsContainer = styled(Grid)({
  padding: "15px",
});

export const MiscellaneousFieldsNotes = styled(Box)({
  position: "absolute",
  padding: "10px 30px",
  top: "15px",
});
