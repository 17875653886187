import { Box, Button, IconButton, styled, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  useGetMaster,
  useGetFailedCount,
} from "../../../services/master.service";
import MaterialReactTable from "material-react-table";
import RefreshIcon from "@mui/icons-material/Refresh";
import defaultProps from "./default";
import { interpreter } from "../../../utils/interpreter";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const MasterTableForImportHistory = ({
  // writable,
  // actionColumnCustomName,
  ...props
}) => {
  const { master_name } = useParams();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const slug = props.master_name || master_name;
  const [columnFilters, setColumnFilters] = useState(
    JSON.parse(params.get("column_filters")) || []
  );
  const [columnVisibility, setColumnVisibilty] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(params.get("search") || "");
  const [sorting, setSorting] = useState(
    JSON.parse(params.get("sorting")) || []
  );
  const [columnPinning] = useState(props.columnPinning || {});
  const [pagination, setPagination] = useState({
    pageIndex: params.get("page") || +0,
    pageSize: params.get("per_page") || 10,
  });

  // const [tableRowsData, setTableRowsData] = useState([]);

  useEffect(() => {
    if (props.columnFilters?.length) {
      setColumnFilters((prev) => ({ ...prev, ...props.columnFilters }));
    }
  }, [JSON.stringify(props.columnFilters)]);

  const {
    table_list,
    isTableLoading,
    refetchTable,
    isTableError,
    isTableFetching,
    column_head,
    total_records,
    masterError,
  } = useGetMaster({
    master_name: slug,
    globalFilter: globalFilter,
    sorting,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    customSearch: props.customSearch,
    pis_search: props.pis_search,
    is_column: "Y",
  });

  useEffect(() => {
    navigate(`${location.pathname}`);
  }, []);

  const [columnOrder, setColumnOrder] = useState([]);
  const navigate = useNavigate();

  const { getImportHistory } = useGetFailedCount();

  useEffect(() => {
    const column_head_order = [
      ...(column_head?.map((item) => item.accessorKey) || []),
    ];
    if (slug === "menu") {
      column_head_order.unshift("mrt-row-expand");
    }
    // if (writable) {
    //   column_head_order.unshift("mrt-row-actions");
    // }
    setColumnOrder([...column_head_order]);
  }, [slug, JSON.stringify(column_head)]);

  useEffect(() => {
    return () => {
      setSorting([]);
      setPagination({
        pageIndex: +0,
        pageSize: 10,
      });
      setColumnFilters([]);
    };
  }, [
    slug,
    globalFilter,
    JSON.stringify(props.customSearch || {}),
    JSON.stringify(props.columnFilters),
  ]);

  useEffect(() => {
    return () => {
      setPagination({
        pageIndex: +0,
        pageSize: 10,
      });
      setGlobalFilter("");
    };
  }, []);

  // useEffect(() => {
  //   setTableRowsData(table_list);
  // }, [table_list]);

  const updated_colum_head = useMemo(() => {
    return column_head?.map((i) => {
      let modifiedObj = {};
      modifiedObj = {
        Cell: ({ cell, row }) => {
          if (cell.getValue() && typeof cell.getValue() === "string") {
            if (cell.getValue().startsWith("f(x):")) {
              return (
                <span>
                  {interpreter(
                    cell.getValue().replace("f(x):", "").trim(),
                    row.original
                  )}
                </span>
              );
            } else {
              return <span>{cell.getValue()}</span>;
            }
          }
        },
      };

      if (i.type === "href") {
        modifiedObj = {
          Cell: ({
            row,
            // cell
          }) => {
            return (
              <a
                style={{
                  color: "#03cc83",
                  //   color: "blue",
                  textDecoration: "underline",
                  //   border: "1px solid #03cc83",
                  borderRadius: "8px",
                  padding: "4px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  getImportHistory({
                    transaction_id: row?.original?.transaction_id,
                    slug_master: master_name,
                  })
                }
              >
                {row?.original?.failed_count}
              </a>
            );
          },
        };
      }

      if (props.cellModifier)
        modifiedObj = {
          ...modifiedObj,
          ...props?.cellModifier(i),
        };

      return {
        ...i,
        ...modifiedObj,
      };
    });
  }, [column_head]);

  const updated_table_list = props.updatedTable
    ? props.updatedTable(table_list)
    : table_list;

  return (
    <>
      <Box key={slug} width={"100%"} px={2} py={2}>
        <MaterialReactTable
          {...defaultProps}
          enableRowActions={true}
          // {...props}

          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "", //To change header text of action column
            },
          }}
          columns={[...updated_colum_head]}
          enableExpanding
          enablePagination={updated_table_list?.length > 0}
          positionActionsColumn={"first"}
          data={updated_table_list || []}
          padding="none" //data is undefined on first render
          muiToolbarAlertBannerProps={
            isTableError && masterError?.response?.data?.message !== ""
              ? {
                  color: "error",
                  children: masterError?.response?.data?.message,
                }
              : {
                  color: "error",
                  children: "Something went wrong",
                }
          }
          muiTableContainerProps={{
            sx: {
              padding: props?.columnPinning ? "0 0 0 20px" : "0 20px",
            },
          }}
          enableGlobalFilter
          onColumnVisibilityChange={setColumnVisibilty}
          getSubRows={(row) => row.child}
          onColumnFiltersChange={(val) => {
            setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
            setColumnFilters(val);
          }}
          onGlobalFilterChange={setGlobalFilter}
          onColumnOrderChange={setColumnOrder}
          enableColumnDragging
          enableColumnOrdering
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderTopToolbarCustomActions={() => (
            <>
              <Box display={"flex"} gap={"12px"} alignItems="center">
                <Tooltip arrow title="Refresh Data">
                  <IconButton onClick={() => refetchTable()}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>

                <MasterActionsButton
                  // onClick={() => navigate(`/master/${master_name}`)}
                  onClick={() => navigate("../")}
                >
                  <FormatListBulletedIcon fontSize="small" />
                  Master Listing
                </MasterActionsButton>
              </Box>
            </>
          )}
          enableEditing
          editingMode={props.editingMode}
          renderRowActions={
            props.customRowAction
              ? props.customRowAction
              : () => <Box sx={{ display: "flex", gap: "0", padding: 0 }}></Box>
          }
          rowCount={total_records || 10}
          state={{
            columnFilters,
            globalFilter,
            isTableLoading,
            pagination,
            showAlertBanner: isTableError,
            showProgressBars: isTableFetching,
            sorting,
            columnOrder,
            columnPinning,
            columnVisibility: {
              // "mrt-row-actions": !writable ? false : true,
              "mrt-row-expand": slug === "menu" ? true : false,
              ...columnVisibility,
            },
          }}
        />
      </Box>
    </>
  );
};

export default MasterTableForImportHistory;

const MasterActionsButton = styled(Button)({
  color: "#475f63",
  border: "solid 1px #bdd5da",
  display: "flex",
  gap: "4px",
  textTransform: "unset",
  fontSize: "14px",
  padding: "0px 8px !important",
  lineHeight: "16px",
  height: "30px",
});

MasterActionsButton.defaultProps = {
  variant: "outlined",
  color: "secondary",
  size: "small",
};
