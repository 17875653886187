import * as yup from "yup";
import { documentUploadsLessThan2MBRequired } from "../../../../../../../../utils/Validations/documentUploadsValidations";

export const surveyorAssessmentSchema = yup.object().shape({
  assessed_idv: yup.string().nullable().required("IDV is required"),
  assessed_vehicle_premium: yup
    .string()
    .nullable()
    .required("Vehicle premium is required"),
  assessed_voluntary_deductible: yup
    .string()
    .nullable()
    .required("Voluntary deductible is required"),
  assessed_loss_type: yup.string().nullable().required("Loss type is required"),
  assessed_labour_cost: yup
    .string()
    .nullable()
    .required("Labour cost is required"),
  assessed_parts_cost: yup
    .string()
    .nullable()
    .required("Parts cost is required"),
  assessed_metal_cost: yup
    .string()
    .nullable()
    .required("Metal cost is required"),
  assessed_paint_cost: yup
    .string()
    .nullable()
    .required("Paint cost is required"),
  assessed_total_other_cost: yup
    .string()
    .nullable()
    .required("Tota other cost is required"),
  assessed_total_estimation: yup
    .string()
    .nullable()
    .required("Total estimation is required"),
  assessed_document: documentUploadsLessThan2MBRequired(),
});
