import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router";
import { AuthContext } from "../../hoc/withRouteProtection";
import { useGetIcApiLogDownloadLink } from "../../services/auditLog.service";
import theme from "../../styles/Theme";
import { ClaimActionButton } from "../Claim/claimStyledComponents";
import MasterTable from "../masters/MasterTable/MasterTable";
import { Heading, Papers } from "../masters/components/MasterStyle";
import {
  BodyCell,
  CustomSkeleton,
  HeadCell,
  SortWrapper,
  TableWrapper,
} from "./IcApiMetricsStyle";

const IcApiMetricsTable = ({ icMetricsData, isLoading }) => {
  const moduleName = Object.keys(icMetricsData).map((i) => i);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "25px",
          padding: "16px",
        }}
      >
        {isLoading ? (
          <CustomSkeleton />
        ) : (
          Object.values(icMetricsData).map((statusObj, statusObjIndex) => {
            return (
              <Papers
                elevation={3}
                sx={{ margin: "0 0", width: "100%" }}
                key={statusObjIndex}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "25px",
                  }}
                >
                  {Object.values(statusObj).map(
                    (detailsObj, detailsObjIndex) => {
                      const status = Object.keys(statusObj).map((i) => i);

                      return (
                        <CommonTable
                          detailsObj={detailsObj}
                          moduleName={moduleName[statusObjIndex]}
                          status={status[detailsObjIndex]}
                          key={detailsObjIndex}
                        />
                      );
                    }
                  )}
                </div>
              </Papers>
            );
          })
        )}
      </div>
    </>
  );
};

export default IcApiMetricsTable;

const CommonTable = ({ detailsObj, moduleName, status }) => {
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [customSearch, setCustomSearch] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const { writable } = useContext(AuthContext);
  const { generateDownloadLink } = useGetIcApiLogDownloadLink();

  // eslint-disable-next-line
  const { all_total, total, ...restDetailsObj } = detailsObj;
  const icWiseDetails = Object.values(restDetailsObj);
  const [detailsArray, setDetailsArray] = useState(icWiseDetails || []);

  let sum = 0;
  let count = 0;

  for (const key in restDetailsObj) {
    sum += Number(restDetailsObj[key]["response_time"]);
    count++;
  }
  const average = count > 0 ? sum / count : 0;

  useEffect(() => {
    const sortedArr = [...icWiseDetails].sort((a, b) => {
      const sortOrder = sortingOrder === "asc" ? 1 : -1;
      return sortOrder * (a.response_time - b.response_time);
    });

    setDetailsArray(sortedArr);
  }, [JSON.stringify(detailsArray), sortingOrder]);

  const ConditionalColour = (item, status) => {
    // example string - 2630 of 2639 (100.00%)
    const count = item?.split("(")[0]?.trim()?.split("of")[0]?.trim();
    const total = item?.split("(")[0]?.trim()?.split("of")[1]?.trim();

    if (status == "success") {
      return count == total
        ? theme.palette.primary.main
        : theme.palette.danger.main;
    }
    if (status == "failure") {
      if (count == 0 && total > 0) {
        return theme.palette.primary.main;
      }
      return count != total || count == total
        ? theme.palette.danger.main
        : theme.palette.primary.main;
    }
  };

  const formatString = (string) => {
    return string?.replace(/_/g, " ").toUpperCase();
  };

  useEffect(() => {
    const totalDynamicFields = formatString(status + moduleName);

    const configMap = {
      SUCCESSCKYC: { alias: "CKYC", status_code: "200" },
      FAILURECKYC: { alias: "CKYC", status_code: "500" },
      SUCCESSPROPOSAL: { proposal: "SUCESS", status_code: "200" },
      FAILUREPROPOSAL: { proposal: "FAIL", status_code: "500" },
    };

    const customSearchConfig = configMap[totalDynamicFields];

    if (customSearchConfig) {
      setCustomSearch((prevSearch) => ({
        ...prevSearch,
        ...customSearchConfig,
      }));
    }
  }, [status, moduleName]);

  return (
    <div style={{ width: "100%" }}>
      <TableWrapper>
        <TableHead>
          <HeadCell
            colSpan="4"
            sx={{
              textAlign: "center",
              backgroundColor:
                status === "success" ? "rgb(237,247,237)" : "rgb(253,237,237)",
              color: status === "success" ? "rgb(66,137,70)" : "rgb(215,65,65)",
            }}
          >
            {formatString(status) + " " + formatString(moduleName)}
          </HeadCell>
        </TableHead>
        <TableHead>
          <HeadCell>Insurance company</HeadCell>
          <HeadCell>Insurance company {status} count</HeadCell>
          <HeadCell style={{ display: "flex", flexDirection: "column" }}>
            <span>Average {status} response time</span>

            <SortWrapper disabled={detailsArray?.length < 2}>
              Sort
              {sortingOrder === "asc" ? (
                <IconButton
                  onClick={() => setSortingOrder("dec")}
                  sx={{
                    padding: 0,
                  }}
                >
                  <ArrowDropUpIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => setSortingOrder("asc")}
                  sx={{
                    padding: 0,
                  }}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              )}
            </SortWrapper>
          </HeadCell>
        </TableHead>
        <TableBody>
          <TableRow>
            <BodyCell bold={true}>TOTAL</BodyCell>
            <BodyCell bold={true}>
              <IconButton
                id={formatString(status) + " " + formatString(moduleName)}
                onClick={() => {
                  setCustomSearch((prevSearch) => ({
                    ...prevSearch,
                    ic_id: "",
                  }));
                  setModalOpen(true);
                }}
                sx={{
                  marginRight: "5px",
                  padding: "5px",
                }}
              >
                <VisibilityIcon sx={{ fontSize: "16px", color: "#6e8185" }} />
              </IconButton>
              {total}
            </BodyCell>
            <BodyCell bold={true}>{average.toFixed(2)}</BodyCell>
          </TableRow>
          {detailsArray?.map((ic, idx) => {
            return (
              <TableRow key={idx}>
                <BodyCell>{formatString(ic?.ic_name)}</BodyCell>
                <BodyCell
                  style={{
                    color: ConditionalColour(ic?.[`${status}_count`], status),
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setCustomSearch((prevSearch) => ({
                        ...prevSearch,
                        ic_id: ic?.ic_id,
                      }));
                      setModalOpen(true);
                    }}
                    sx={{
                      marginRight: "5px",
                      padding: "5px",
                    }}
                  >
                    <VisibilityIcon
                      sx={{ fontSize: "16px", color: "#6e8185" }}
                    />
                  </IconButton>
                  {ic?.[`${status}_count`]}
                </BodyCell>
                <BodyCell
                  style={{
                    color: ConditionalColour(ic?.[`${status}_count`], status),
                  }}
                >
                  {Number(ic?.response_time)?.toFixed(2)}
                </BodyCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableWrapper>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "920px",
          },
        }}
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        setOpen={setModalOpen}
      >
        <Box>
          <DialogTitle>
            <Heading variant="h6" align="left">
              View Logs
            </Heading>
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => setModalOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <MasterTable
            master_name={"http_logs"}
            writable={writable}
            columnFilters={Object.keys(customSearch).map((item) => {
              return {
                id: item,
                value: customSearch[item],
              };
            })}
            customRowAction={({ row }) =>
              writable && (
                <>
                  <ClaimActionButton
                    onClick={() => {
                      Navigate(`./add/${row?.original?.http_logs_id}`);
                    }}
                  >
                    View
                  </ClaimActionButton>
                  <ClaimActionButton
                    onClick={() => {
                      generateDownloadLink({
                        http_logs_id: row?.original?.http_logs_id,
                        download: true,
                      });
                    }}
                  >
                    Download
                  </ClaimActionButton>
                </>
              )
            }
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
