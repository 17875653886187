import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../api/api";
import { errorHandler } from "../utils/errorHandler";

export const useSearchIcDealerMapData = () => {
  const { mutate, isLoading, isError, isSuccess, data, error, reset } =
    useMutation((data) => {
      return api.post("/ic_dealer_mapping/list", data);
    });
  const errList = errorHandler(error, data);

  return {
    sendIcDealerMapData: mutate,
    isLoading,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
    column_data: data?.data?.column_head,
    row_data: data?.data?.return_data,
  };
};

export const useSendIcDealerMapTableData = () => {
  const { mutate, isLoading, isError, isSuccess, data, error, reset } =
    useMutation((data) => {
      return api.post("/ic_dealer_mapping/update", data);
    });
  const errList = errorHandler(error, data);

  return {
    sendIcDealerMapTableData: mutate,
    isLoading,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};

export const useGetDealersFromMultipleFilters = (payload) => {
  const { data } = useQuery(
    ["dealers_from_ic_id", payload],
    () => {
      return api.post("ic/dealer_list", {
        ...payload,
      });
    },
    {
      enabled: !!payload.oem_id,
    }
  );
  return {
    dealer_items: data?.data?.return_data || [],
  };
};
