import React, { useState, useEffect, useContext } from "react";
import {
  AtleastOnefieldError,
  ButtonWrapper,
  FormContainer,
  Heading,
  Papers,
} from "../../../masters/components/MasterStyle";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Grid } from "@mui/material";
import Select from "../../../../components/InputComponents/Select";
import TextInput from "../../../../components/InputComponents/TextInput";
import Buttons from "../../../../components/BasicFormComponent/Buttons";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { useLocation, useNavigate } from "react-router";
import {
  allCapitalize,
  allowOnlyAplhabetNumber,
  allowOnlyNumbers,
  allowRegistrationNumber,
} from "../../../../InputUtils/onInput";
import { ResetButton } from "../../../Claim/claimStyledComponents";
import MasterTable from "../../../masters/MasterTable/MasterTable";
import withRouteProtection, {
  AuthContext,
} from "../../../../hoc/withRouteProtection";
import { proposalStageOptions2 } from "../../../../constants/options";
import Navbar from "../../../../components/Navbar";

const ProposalCancellationSearch = ({ values }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const columnFilters = searchParams.get("column_filters");
  const customSearchFromParam = JSON.parse(columnFilters)?.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.id]: curr.value,
    }),
    {}
  );
  const { writable } = useContext(AuthContext);

  const methods = useForm({
    defaultValues: {
      proposal_stage: "",
      proposal_no: "",
      registration_no: "",
      mobile_no: "",
      chassis_no: "",
      engine_no: "",
      ...values,
    },
  });

  const navigate = useNavigate();
  const [customSearch, setCustomSearch] = useState(customSearchFromParam || {});
  const allfields = methods.watch();
  useEffect(() => {
    const isAnyFieldSelectedSecond = Object?.values(allfields).some(
      (item) => item !== ""
    );
    if (isAnyFieldSelectedSecond) {
      methods.clearErrors();
    }
  }, [JSON.stringify(allfields)]);
  const onSubmit = (data) => {
    const isAnyFieldSelected = Object?.values(data).some((item) => item !== "");
    if (isAnyFieldSelected) {
      setCustomSearch(data);
    } else {
      methods.setError("atleast_one_field", {
        type: "custom",
        message: "Atleast one field is required",
      });
    }
  };

  const handleReset = (e) => {
    if (searchParams.get("column_filters")) {
      searchParams.delete("column_filters");
    }
    setCustomSearch({});
    e.preventDefault();
    methods.reset({
      proposal_stage: "",
      proposal_no: "",
      registration_no: "",
      mobile_no: "",
      chassis_no: "",
      engine_no: "",
    });
  };
  const err = methods.formState.errors?.atleast_one_field?.message;
  return (
    <Navbar>
      <FormContainer>
        <Papers elevation={3} sx={{ width: "100%" }}>
          <Heading variant="h5" align="left">
            Proposal Cancellation
          </Heading>
          {err && <AtleastOnefieldError>{err}</AtleastOnefieldError>}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={1} columnSpacing={3} mt={1}>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <Select
                    name="proposal_stage"
                    label="Proposal Stage"
                    options={proposalStageOptions2}
                    variant="standard"
                    saveDisplayName={false}
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Proposal No."}
                    name={"proposal_no"}
                    inputProps={{ maxLength: 20 }}
                    onInput={allowOnlyAplhabetNumber}
                    standard
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Registration No."}
                    name={"registration_no"}
                    inputProps={{ maxLength: 17 }}
                    onInput={allowRegistrationNumber}
                    standard
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Chassis No."}
                    name={"chassis_no"}
                    inputProps={{ maxLength: 25 }}
                    onInput={(e) => {
                      allCapitalize(e);
                      allowOnlyAplhabetNumber(e);
                    }}
                    standard
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Engine No."}
                    name={"engine_no"}
                    inputProps={{ maxLength: 25 }}
                    onInput={(e) => {
                      allCapitalize(e);
                      allowOnlyAplhabetNumber(e);
                    }}
                    standard
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Mobile No."}
                    name={"mobile_no"}
                    inputProps={{ maxLength: 10 }}
                    onInput={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    standard
                    style={{ paddingTop: "4px" }}
                  />
                </Grid>
              </Grid>
              <ButtonWrapper
                style={{
                  margin: 0,
                  gap: "10px",
                }}
              >
                <Buttons type="submit" label="Search" />
                <ResetButton onClick={(e) => handleReset(e)}>
                  <ReplayRoundedIcon />
                  Reset
                </ResetButton>
              </ButtonWrapper>
            </form>
          </FormProvider>
        </Papers>
      </FormContainer>
      <MasterTable
        master_name={"report/proposal"}
        writable={writable}
        cellModifier={(cellData) => {
          if (cellData.type === "href") {
            return {
              Cell: ({ row }) => {
                return (
                  <Button
                    onClick={() => {
                      navigate(
                        `./summary/${row?.original?.segment_slug?.toLowerCase()}/?traceId=${
                          row?.original?.trace_id
                        }&proposal_id=${row?.original?.proposal_unique_id}`
                      );
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cancel Proposal
                  </Button>
                );
              },
            };
          }
        }}
        columnFilters={[
          {
            id: "selected_id",
            value: "proposal_cancellation",
          },
          ...Object.entries(customSearch).map(([key, value]) => ({
            id: key,
            value: value,
          })),
        ]}
      />
    </Navbar>
  );
};

export default withRouteProtection(ProposalCancellationSearch);
