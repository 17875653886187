import { EMI_DURATION, IDV, NONE, SUM_INSURED } from "./AddonsConstants";
import {
  NEW_POLICY,
  NO_PREVIOUS_POLICY,
  RENEWAL,
  ROLL_OVER,
} from "./PolicyConstants";

export const statusOptions = [
  {
    code: "Y",
    display_name: "Active",
  },
  {
    code: "N",
    display_name: "Inactive",
  },
];

export const statusOptions2 = [
  { code: "Y", display_name: "Yes" },
  { code: "N", display_name: "No" },
];

export const statusOptions3 = [
  { code: "Yes", display_name: "Yes" },
  {
    code: "No",
    display_name: "No",
  },
];

export const bhseriesOption = [
  {
    code: "Onroll",
    display_name: "Yes",
  },
  { code: "Offroll", display_name: "No" },
];

export const addonTypeOptions = [
  { code: "OD", display_name: "OD" },
  { code: "TP", display_name: "TP" },
];

export const priorityOptions = [
  { code: 1, display_name: "1" },
  { code: 2, display_name: "2" },
  { code: 3, display_name: "3" },
];

export const discountTypeOptions = [
  { code: "2", display_name: "Amount" },
  { code: "1", display_name: "Percentage" },
];

export const appliedOnOptions = [
  { code: "pan_india", display_name: "Pan India" },
  { code: "zone", display_name: "Zone" },
  { code: "state", display_name: "State" },
  { code: "city", display_name: "City" },
  { code: "dealer", display_name: "Dealer" },
];

export const activeInactiveOptions = [
  { code: "Y", display_name: "Active" },
  { code: "N", display_name: "Inactive" },
  { code: "all", display_name: "All" },
];

export const imtTypeOptions = [
  { code: "Y", display_name: "Yes" },
  { code: "N", display_name: "No" },
  { code: "B", display_name: "Both" },
];

export const addonAppliedoptions = [
  { code: "EMI AMOUNT", display_name: "EMI AMOUNT" },
  {
    code: "EX-SHOWROOM PRICE",
    display_name: "EX-SHOWROOM PRICE",
  },
  { code: "IDV", display_name: "IDV" },
  {
    code: "ON ROAD PRICE",
    display_name: "ON ROAD PRICE",
  },
  {
    code: "VEHICLE PREMIUM",
    display_name: "VEHICLE PREMIUM (BEFORE DISCOUNT)",
  },
];

export const addonAppliedOptionsForImtConfigurator = [
  {
    code: "emi_amount",
    display_name: "EMI Amount",
  },
  {
    code: "exshowroom_price",
    display_name: "Ex-Showroom Price",
  },
  { code: "idv", display_name: "IDV" },
  {
    code: "on_road_price",
    display_name: "On Road Price",
  },
  {
    code: "vehicle_premium",
    display_name: "Vehicle Premium (Before Discount)",
  },
  {
    code: "accessories_idv",
    display_name: "Accessories Idv",
  },
];

export const amountTypeOptions = [
  { code: "percent", display_name: "Percent (%)" },
  { code: "amount", display_name: "Amount (Rs)" },
];

export const amountTypeOptionsWithSeatCapacity = [
  { code: "percent", display_name: "Percent (%)" },
  { code: "amount", display_name: "Amount (Rs)" },
  { code: "seat_capacity", display_name: "Amount on seat capacity" },
];

export const genderOptions = [
  { code: "M", display_name: "Male" },
  { code: "F", display_name: "Female" },
  { code: "O", display_name: "Other" },
];

export const agreementTypeOptions = [
  { code: "Hire Purchase", display_name: "Hire Purchase" },
  { code: "Hypothecation", display_name: "Hypothecation" },
  { code: "Lease agreement", display_name: "Lease agreement" },
];

export const relationWithInsuredOptions = [
  { code: "brother", display_name: "Brother" },
  { code: "daughter", display_name: "Daughter" },
  { code: "father", display_name: "Father" },
  { code: "mother", display_name: "Mother" },
  { code: "sister", display_name: "Sister" },
  { code: "son", display_name: "Son" },
  { code: "spouse", display_name: "Spouse" },
  { code: "other", display_name: "Other" },
];

export const relationWithGuardianOptions = [
  { code: "brother", display_name: "Brother" },
  { code: "father", display_name: "Father" },
  { code: "mother", display_name: "Mother" },
  { code: "sister", display_name: "Sister" },
  { code: "other", display_name: "Other" },
];

export const categoryoption = [
  { code: "A", display_name: "A" },
  { code: "B", display_name: "B" },
  { code: "C", display_name: "C" },
];

// TODO: remove
export const approval_status = [
  { code: "Approved", display_name: "Approve" },
  { code: "Rejected", display_name: "Reject" },
];

export const approval_status_temp = [
  { code: "approved", display_name: "Approve" },
  { code: "rejected", display_name: "Reject" },
];
export const approval_status_with_pending = [
  { code: "approved", display_name: "Approved" },
  { code: "rejected", display_name: "Rejected" },
  { code: "pending", display_name: "Pending" },
];

export const ncb_options = [
  { code: "0", display_name: "0%" },
  { code: "20", display_name: "20%" },
  { code: "25", display_name: "25%" },
  { code: "35", display_name: "35%" },
  { code: "45", display_name: "45%" },
  { code: "50", display_name: "50%" },
];

export const prev_ncb_options = [
  { code: "20", display_name: "20%" },
  { code: "25", display_name: "25%" },
  { code: "35", display_name: "35%" },
  { code: "45", display_name: "45%" },
  { code: "50", display_name: "50%" },
];

export const approval_options = [
  { code: "approved", display_name: "Approve" },
  { code: "rejected", display_name: "Reject" },
];

export const icConfig_options = [
  { code: "Online", display_name: "Online" },
  { code: "Offline", display_name: "Offline" },
];

export const docDaysoption = [
  { code: "1", display_name: "1" },
  { code: "2", display_name: "2" },
  { code: "3", display_name: "3" },
  { code: "4", display_name: "4" },
  { code: "5", display_name: "5" },
  { code: "6", display_name: "6" },
  { code: "7", display_name: "7" },
];

export const paginationOptions = [
  { code: "5", display_name: "5" },
  { code: "10", display_name: "10" },
  { code: "15", display_name: "15" },
  { code: "20", display_name: "20" },
  { code: "25", display_name: "25" },
  { code: "30", display_name: "30" },
  { code: "50", display_name: "50" },
  { code: "100", display_name: "100" },
];

export const icApprovalStatusOptions = [
  { code: "claim_detail", display_name: "Claim Details" },
  {
    code: "endorsement_cancellation",
    display_name: "Endorsement Cancellation",
  },
  { code: "policy_cancellation", display_name: "Policy Cancellation" },
  { code: "proposal_details", display_name: "Proposal Details" },
];

export const icTypeFlagList = [
  { code: "HO", display_name: "HO" },
  { code: "NODAL", display_name: "NODAL" },
  { code: "LSO", display_name: "LSO" },
];

export const calculationTypeOptions = [
  { code: "cc", display_name: "Cubic Capacity (CC)" },
  { code: "gvw", display_name: "Gross Vehicle Weight (GVW)" },
  { code: "kw", display_name: "kilowatt (kW)" },
  { code: "sc", display_name: "Seating Capacity" },
];

export const claimIntimatedByOptions = [
  {
    code: "owner",
    display_name: "Owner",
  },
  {
    code: "representative",
    display_name: "Representative",
  },
];

export const licenseTypeOptions = [
  {
    code: "learning driving license",
    display_name: "Learning driving license",
  },
  {
    code: "two wheeler driving license",
    display_name: "Two wheeler driving license",
  },
  {
    code: "three wheeler driving license",
    display_name: "Three wheeler driving license",
  },
  {
    code: "four wheeler driving license",
    display_name: "Four wheeler driving license",
  },
  {
    code: "heavy license",
    display_name: "Heavy license",
  },
];

export const schemeTypeOptions = [
  { code: "chassis_no", display_name: "Chassis No" },
  { code: "engine_no", display_name: "Engine No" },
  {
    code: "policy_count",
    display_name: "Policy Count",
  },
  {
    code: "registration_no",
    display_name: "Registration No",
  },
];

export const inspectionTypeOptions = [
  {
    code: "self",
    display_name: "Self",
  },
  {
    code: "surveyor",
    display_name: "Surveyor",
  },
];

export const paymentOptions = [
  { code: "3", display_name: "Online" },
  { code: "4", display_name: "Offline" },
  { code: "1", display_name: "APD" },
];

export const IdvOptions = [
  { code: "Highest", display_name: "Highest" },
  { code: "Lowest", display_name: "Lowest" },
  { code: "Recommended", display_name: "Recommended" },
];

export const discountOptions = [
  { code: "highest", display_name: "Highest" },
  { code: "lowest", display_name: "Lowest" },
  { code: "custom", display_name: "Custom" },
];

export const volunatryAccessOptions = [
  { code: 2500, display_name: "2,500" },
  { code: 5000, display_name: "5,000" },
  { code: 7500, display_name: "7,500" },
  { code: 15000, display_name: "15,000" },
];

export const waiverReasonOptions = [
  {
    code: "I do not have valid driving license.",
    display_name: "I do not have valid driving license.",
  },
  {
    code: "I have seperate general PA cover(Non Motor Product).",
    display_name: "I have seperate general PA cover (Non Motor Product).",
  },
  {
    code: "I have standalone PA cover(Motor Product).",
    display_name: "I have standalone PA cover (Motor Product).",
  },
];

export const ownerTypeOptions = [
  {
    code: "Individual",
    display_name: "Individual",
  },
  {
    code: "Company",
    display_name: "Company",
  },
];

export const policyStageOptions = [
  {
    code: "policy_issuance",
    display_name: "Policy Issued",
  },
  {
    code: "policy_cancelled",
    display_name: "Policy Cancelled",
  },
];

export const proposalStageOptions = [
  {
    code: "payment_failed",
    display_name: "Payment Failed",
  },
  {
    code: "ic_approval_pending",
    display_name: "IC Approval Pending",
  },
  {
    code: "self_inspection_initiated",
    display_name: "Self Inspection Initiated",
  },
];

export const proposalStageOptions2 = [
  {
    code: "policy_intiated",
    display_name: "Proposal Submitted",
  },
  {
    code: "payment_failed",
    display_name: "Payment Failed",
  },
];

export const solicitationTypeOptions = [
  {
    code: "authorised_verifier",
    display_name: "Authorised Verifier",
  },
  { code: "bqp", display_name: "BQP" },
  { code: "misp", display_name: "MISP" },
  { code: "posp", display_name: "POSP" },
];

export const solicitationTypeSuperAdminOptions = [
  {
    code: "authorised_verifier",
    display_name: "Authorised Verifier",
  },
  {
    code: "broker_certified",
    display_name: "Broker Certified",
  },
  {
    code: "insurance_company",
    display_name: "Insurance Company",
  },
  { code: "misp_dp", display_name: "MISP DP" },
  { code: "misp_sp", display_name: "MISP SP" },
  { code: "posp", display_name: "POSP" },
  { code: "other", display_name: "Other" },
];

export const solicitationTypeDealerAdminOptions = [
  { code: "misp_dp", display_name: "MISP DP" },
  { code: "misp_sp", display_name: "MISP SP" },
  { code: "posp", display_name: "POSP" },
];

export const dealerTypeOptions = [
  { code: "sales", display_name: "Sales" },
  { code: "workshop", display_name: "Workshop" },
];

export const payerTypeOptions = [
  { code: "Customer", display_name: "Customer" },
  { code: "Dealer", display_name: "Dealer" },
];

export const payerTypeOptions2 = [
  {
    code: "customer",
    display_name: "Customer",
  },
  { code: "dealer", display_name: "Dealer" },
];

export const paymentStatusOptions = [
  { code: "approved", display_name: "Approved" },
  {
    code: "pending",
    display_name: "Pending",
  },
];

export const refundInFavourOptions = [
  {
    code: "dealer",
    display_name: "Dealer",
  },
  {
    code: "insured",
    display_name: "Insured",
  },
  {
    code: "no_refund",
    display_name: "No Refund",
  },
];

export const Vb64Options = [
  {
    code: "pending",
    display_name: "Pending",
  },
  {
    code: "verified",
    display_name: "Verified",
  },
  {
    code: "not_verified",
    display_name: "Not Verified",
  },
];
export const allApprovalStatus = [
  { code: "pending", display_name: "Pending" },
  { code: "approved", display_name: "Approved" },
  { code: "rejected", display_name: "Rejected" },
];
export const pdfTemplateOptions = [
  {
    code: "fyntune",
    display_name: "Default",
  },
  {
    code: "broker",
    display_name: "Broker",
  },
];

export const policyTypes = [
  { code: NEW_POLICY, display_name: "New Policy" },
  { code: RENEWAL, display_name: "Renewal" },
  { code: ROLL_OVER, display_name: "Roll Over" },
  { code: NO_PREVIOUS_POLICY, display_name: "No Previous Policy" },
];

export const ownershipTypes = [
  { code: "Individual", display_name: "Individual" },
  { code: "Company", display_name: "Company" },
];

export const previousClaimOptions = [
  { code: "Y", display_name: "Claim In Previous Policy" },
  { code: "N", display_name: "No Claim In Previous Policy" },
];
export const displayPagesOptions = [
  { code: "dashboard", display_name: "Dashboard" },
  { code: "displayonallpages", display_name: "Display On All Pages" },
];
export const priorityOptionsForTicker = [
  { code: "low", display_name: "Low" },
  { code: "medium", display_name: "Medium" },
  { code: "high", display_name: "High" },
];
export const contactDetailsOnPolicy = [
  { code: "insurance", display_name: "Insurance" },
  { code: "broker", display_name: "Broker" },
  { code: "both", display_name: "Both" },
];
export const cancellationReason = [
  {
    code: "cheque_dishonour",
    display_name: "Cheque Dishonour",
  },
  {
    code: "customer_request",
    display_name: "Customer Request",
  },
  {
    code: "double_insurance",
    display_name: "Double Insurance",
  },
  {
    code: "non_receipt_of_the_payment",
    display_name: "Non Receipt of the Payment",
  },
  {
    code: "others",
    display_name: "Others",
  },
];
export const refundMethodforCancellationApproval = [
  { code: "pro_rate", display_name: "Pro Rate" },
  { code: "full_refund", display_name: "Full Refund" },
  { code: "no_refund", display_name: "No Refund" },
];

export const options = [
  { code: "pending", display_name: "Pending" },
  { code: "verified", display_name: "Verified" },
  { code: "not_verified", display_name: "Not Verified" },
];

export const refundModeOptions = [
  { code: "manual", display_name: "Manual" },
  { code: "apd", display_name: "APD" },
];

export const emiCoverAddonList = [
  {
    code: "emi_amount",
    display_name: "Emi amount",
  },
  {
    code: "ex_showroom_price",
    display_name: "Ex-showroom price",
  },
  { code: "idv", display_name: "IDV" },
  {
    code: "vehicle_premium",
    display_name: "Vehicle premium (before discount)",
  },
];
export const nonEmiCoverAddonList = [
  {
    code: "ex_showroom_price",
    display_name: "Ex-showroom price",
  },
  { code: "idv", display_name: "IDV" },
  {
    code: "vehicle_premium ",
    display_name: "Vehicle premium (before discount)",
  },
];
export const search_date_type_for_apd_approval = [
  {
    code: "transaction_date",
    display_name: "Transaction Date",
  },
  {
    code: "requested_date",
    display_name: "Requested Date",
  },
  {
    code: "approved_date",
    display_name: "Approved Date",
  },
];

export const additionalParameterForPremiumCalculationList = [
  { code: NONE, display_name: "None" },
  { code: IDV, display_name: "IDV" },
  { code: SUM_INSURED, display_name: "Sum Insured (SI)" },
  { code: EMI_DURATION, display_name: " EMI Duration (Number of EMI)" },
];

export const natureOfAccident = [
  {
    code: "Partial Loss",
    display_name: "Partial Loss",
  },
  {
    code: "Total Loss",
    display_name: "Total Loss",
  },
  {
    code: "Partial Theft",
    display_name: "Partial Theft",
  },
  {
    code: "Theft",
    display_name: "Theft",
  },
  {
    code: "Flood",
    display_name: "Flood",
  },
  {
    code: "Addon Claim",
    display_name: "Addon Claim",
  },
];

export const proposerTypeOptions = [
  { code: "individual", display_name: "Individual" },
  { code: "corporate", display_name: "Corporate" },
];

export const vehiclePartOptions = [
  {
    code: "Front",
    display_name: "Front",
  },
  {
    code: "Front Left",
    display_name: "Front Left",
  },
  {
    code: "Front Right",
    display_name: "Front Right",
  },
  {
    code: "Rear",
    display_name: "Rear",
  },
  {
    code: "Rear Left",
    display_name: "Rear Left",
  },
  {
    code: "Rear Right",
    display_name: "Rear Right",
  },
  {
    code: "Roof",
    display_name: "Roof",
  },
  {
    code: "Bottom",
    display_name: "Bottom",
  },
];

export const newPaymentOptions = [
  {
    code: "apd",
    display_name: "APD",
  },
  {
    code: "neft",
    display_name: "NEFT",
  },
  {
    code: "cheque",
    display_name: "Cheque",
  },
];

export const payeeOptions = [
  {
    code: "insured",
    display_name: "Insured",
  },
  {
    code: "workshop",
    display_name: "Workshop",
  },
  {
    code: "financer",
    display_name: "Financer",
  },
];

export const payementType = [
  {
    code: "cashless",
    display_name: "Cashless",
  },
  {
    code: "reimbursement",
    display_name: "Reimbursement",
  },
];

export const settlementOptions = [
  {
    code: "total_loss",
    display_name: "Total Loss",
  },
  {
    code: "partial_loss",
    display_name: "Partial Loss",
  },
  {
    code: "flood",
    display_name: "Flood",
  },
  {
    code: "theft",
    display_name: "Theft",
  },
  {
    code: "addon_claim",
    display_name: "Add-on Claim",
  },
];

export const lossType = [
  {
    code: "total_loss",
    display_name: "Total Loss",
  },
  {
    code: "partial_loss",
    display_name: "Partial Loss",
  },
];
