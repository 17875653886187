import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const quote = createSlice({
  name: "quote",
  initialState: {
    selected_addons: {},
    idv_range: {
      min: Infinity,
      max: 0,
    },
    discount_range: {
      min: Infinity,
      max: 0,
    },
    selected_idv: { name: "recommended" }, // for default (radio btn) selected IDV on quote page
    selected_discount: null,
    showComparePopUp: false,
    compare_list: [],
    selected_discount_by_product: {},
    pa_cover: {
      ownerDriver: true,
    },
    electrical_accessories: [],
    emiCoverDetails: {},
    confineToSiteDetails: {},
    non_electrical_accessories: [],
    bi_fuel: null,
    compareListFull: false,
    plan_type: "all",
    ncb: null,
    newNcb: null,
    geographical_extension: [],
    vehicleType: [],
    imt: [],
    voluntaryExcessAmountForInput: "",
    voluntaryExcessForInput: false,
    //------------------ New------------------
    customer_type: "Individual",
    isGst: true,
    allQuoteData: {},
    sortedQuotes: 1,
    additionalPremiumChange: {},
    ticketCardData: {},
    garagePopup: false,
    additionalPremiumCount: {},
    premiumPopup: null,
    compareQuote: [],
    specialSchemeTitleClicked: false,
    openDiscountPopUp: false,
    raiseDiscountRequestValue: "",
    raiseDiscountRequesLinkButtonClicked: false,
    selectedSpecialSchemeDiscount: {
      min_discount: "",
      max_discount: "",
    },
    riskStartDate: moment().startOf("day").format("DD-MM-YYYY HH:mm:ss"),
    riskStartDatePopUp: false,
    isFilterLoading: false,
    isDiscountResetted: false,
    shouldShowEditableInputPageOnQuote: false,
    without_ncb: true,
    discounts: {},
    ICdetaislForSumInsured: {},
    productWiseSumInsuredDetais: {},
  },
  reducers: {
    setAllQuoteData: (state, action) => {
      state.allQuoteData = action.payload;
    },

    setSelectedAddons: (state, action) => {
      state.selected_addons = action.payload;
    },
    updateSelectedAddons: (state, action) => {
      state.selected_addons = {
        ...state.selected_addons,
        ...action.payload,
      };
    },
    removeSelectedAddons: (state, action) => {
      delete state.selected_addons[action.payload];
    },
    setIdvRange: (state, action) => {
      state.idv_range.min = Math.min(action.payload.min, state.idv_range.min);
      state.idv_range.max = Math.max(action.payload.max, state.idv_range.max);
    },
    resetIdvRange: (state) => {
      state.idv_range.min = Infinity;
      state.idv_range.max = 0;
    },
    setDiscountRange: (state, action) => {
      state.discount_range.min = Math.min(
        action.payload.min,
        state.discount_range.min
      );
      state.discount_range.max = Math.max(
        action.payload.max,
        state.discount_range.max
      );
    },
    setSelectedIdv: (state, action) => {
      state.selected_idv = action.payload;
    },
    setSelectedDiscount: (state, action) => {
      state.selected_discount = action.payload;
    },
    setSelectedSpecialSchemeDiscountRange: (state, action) => {
      state.selectedSpecialSchemeDiscount = action.payload;
    },
    setComparePopUp: (state, action) => {
      state.showComparePopUp = action.payload;
    },
    setPremiumPopup: (state, action) => {
      state.premiumPopup = action.payload;
    },
    handleCompareList: (state, action) => {
      const productIndex = state.compare_list?.findIndex(
        (e) =>
          e.productId === action.payload.productId &&
          e.bundleId === action.payload.bundleId
      );

      if (productIndex !== -1) {
        state.compare_list.splice(productIndex, 1);
      } else {
        state.compare_list = [...state.compare_list, action.payload];
      }
    },
    emptyCompareList: (state) => {
      state.compare_list = [];
    },
    setpaCover: (state, action) => {
      state.pa_cover = action.payload;
    },
    setSelectedDiscountByProduct: (state, action) => {
      state.selected_discount_by_product = {
        ...state.selected_discount_by_product,
        ...action.payload,
      };
    },
    setelectricalAccessories: (state, action) => {
      state.electrical_accessories = action.payload.update
        ? action.payload.entries
        : action.payload.delete
        ? []
        : [...(state.electrical_accessories || []), action.payload];
    },
    setDeleteelectricalAccessories: (state, action) => {
      state.electrical_accessories = state.electrical_accessories.filter(
        (x) => x.id !== action.payload
      );
    },
    setnonElectricalAccessories: (state, action) => {
      state.non_electrical_accessories = action.payload.update
        ? action.payload.entries
        : action.payload.delete
        ? []
        : [...(state.non_electrical_accessories || []), action.payload];
    },
    setDeletenonElectricalAccessories: (state, action) => {
      state.non_electrical_accessories =
        state.non_electrical_accessories.filter((x) => x.id !== action.payload);
    },
    setBiFuel: (state, action) => {
      state.bi_fuel = action.payload;
    },
    setPlanType: (state, action) => {
      state.plan_type = action.payload;
    },

    setTicketCardData: (state, action) => {
      state.ticketCardData = action.payload;
    },

    setNcb: (state, action) => {
      state.ncb = action.payload;
    },
    setNewNcb: (state, action) => {
      state.newNcb = action.payload;
    },
    setGeographicalExtention: (state, action) => {
      state.geographical_extension = action.payload;
    },
    updateGeographicalExtention: (state, action) => {
      if (state.geographical_extension?.length > 0) {
        state.geographical_extension = [
          ...state.geographical_extension,
          action.payload,
        ];
      } else state.geographical_extension = [action.payload];
    },
    removeGeographicalExtention: (state, action) => {
      state.geographical_extension = state.geographical_extension?.filter(
        (x) => x !== action.payload
      );
    },
    setVehicleType: (state, action) => {
      if (state.vehicleType?.includes(action.payload)) {
        state.vehicleType = state.vehicleType?.filter(
          (x) => x !== action.payload
        );
      } else {
        state.vehicleType = [...state.vehicleType, action.payload];
      }
    },
    setIMT: (state, action) => {
      state.imt = [...state.imt, action.payload];
    },
    removeIMT: (state, action) => {
      state.imt = state.imt?.filter((x) => x !== action.payload);
    },

    // ---------------------------------New ui slice---------------------------------
    setCustomertype: (state, action) => {
      state.customer_type = action.payload;
    },
    setIsGst: (state, action) => {
      state.isGst = action.payload;
    },
    setSortedQuotes: (state, action) => {
      state.sortedQuotes = action.payload;
    },

    setCompareQuote: (state, action) => {
      state.compareQuote = action.payload;
    },

    setAdditionalPremiumChange: (state, action) => {
      state.additionalPremiumChange = {
        ...state.additionalPremiumChange,
        [action.payload.key]: action.payload.additionalPremium,
      };
      state.additionalPremiumCount = {
        ...state.additionalPremiumCount,
        [action.payload.key]: action.payload.additionalPremiumCount,
      };
    },
    setGaragePopup: (state, { payload }) => {
      state.garagePopup = payload;
    },
    setSpecialSchemeTitleClicked: (state, { payload }) => {
      state.specialSchemeTitleClicked = payload;
    },
    setOpenDiscountPopUp: (state, { payload }) => {
      state.openDiscountPopUp = payload;
    },
    setRaiseDiscountRequesLinkButtonClicked: (state, { payload }) => {
      state.raiseDiscountRequesLinkButtonClicked = payload;
    },
    setRaiseDiscountRequestValue: (state, { payload }) => {
      state.raiseDiscountRequestValue = payload;
    },
    setRiskStartDatePopUp: (state, { payload }) => {
      state.riskStartDatePopUp = payload;
    },
    setRiskStartDate: (state, { payload }) => {
      state.riskStartDate = payload;
    },
    setIsDiscountResetted: (state, action) => {
      state.isDiscountResetted = action.payload;
    },
    setVoluntaryExcessForInput: (state, action) => {
      state.voluntaryExcessForInput = action.payload;
    },
    setVoluntaryExcessAmountForInput: (state, action) => {
      state.voluntaryExcessAmountForInput = action.payload;
    },
    setShouldShowEditableInputPageOnQuote: (state, action) => {
      state.shouldShowEditableInputPageOnQuote = action.payload;
    },
    setEMICoverDetails: (state, action) => {
      state.emiCoverDetails = action.payload;
    },
    setConfineToSiteDetails: (state, action) => {
      state.confineToSiteDetails = action.payload;
    },
    handleModal: (state, action) => {
      state[action.payload] = !state[action.payload];
    },
    handleDiscountCheckboxClick: (state, { payload }) => {
      state.discounts[payload] = !state.discounts[payload];
    },
    setDiscountCheckboxes: (state, { payload }) => {
      state.discounts["aa_membership_discount"] =
        payload.aa_membership_discount;
      state.discounts["confine_to_site_discount"] =
        payload.confine_to_site_discount;
      state.discounts["tppd_cover_discount"] = payload.tppd_cover_discount;
      state.discounts["handicap_discount_discount"] =
        payload.handicap_discount_discount;
      state.discounts["anti_theft_discount"] = payload.anti_theft_discount;
    },
    setIsFilterLoading: (state, action) => {
      state.isFilterLoading = action.payload;
    },
    setICdetaislForSumInsured: (state, action) => {
      state.ICdetaislForSumInsured = action.payload;
    },
    setProductWiseSumInsuredDetais: (state, action) => {
      state.productWiseSumInsuredDetais = {
        ...state.productWiseSumInsuredDetais,
        ...action.payload,
      };
    },
    resetQuoteStates: (state) => {
      state.selected_addons = {};
      state.idv_range = {
        min: Infinity,
        max: 0,
      };
      state.discount_range = {
        min: Infinity,
        max: 0,
      };
      state.selected_idv = { name: "recommended" };
      state.selected_discount = null;
      state.showComparePopUp = false;
      state.compare_list = [];
      state.selected_discount_by_product = {};
      state.pa_cover = {
        ownerDriver: true,
      };
      state.electrical_accessories = [];
      state.non_electrical_accessories = [];
      state.bi_fuel = null;
      state.compareListFull = false;
      state.plan_type = "all";
      state.ncb = null;
      state.newNcb = null;
      state.geographical_extension = [];
      state.vehicleType = [];
      state.imt = [];
      state.customer_type = "Individual";
      state.isGst = true;
      state.allQuoteData = {};
      state.sortedQuotes = 1;
      state.additionalPremiumChange = {};
      state.ticketCardData = {};
      state.garagePopup = false;
      state.additionalPremiumCount = {};
      state.premiumPopup = null;
      state.compareQuote = [];
    },

    resetInputDetailsStates: (state) => {
      state.electrical_accessories = [];
      state.non_electrical_accessories = [];
      state.bi_fuel = {};
      state.geographical_extension = [];
      state.imt = [];
      state.discounts = {};
      state.emiCoverDetails = {};
    },
  },
});

export const {
  setSelectedAddons,
  setIdvRange,
  resetIdvRange,
  setDiscountRange,
  setSelectedIdv,
  setSelectedDiscount,
  setComparePopUp,
  setPremiumPopup,
  handleCompareList,
  emptyCompareList,
  setSelectedDiscountByProduct,
  updateSelectedAddons2,
  setpaCover,
  setelectricalAccessories,
  setDeleteelectricalAccessories,
  setnonElectricalAccessories,
  setDeletenonElectricalAccessories,
  setBiFuel,
  setPlanType,
  setNcb,
  setNewNcb,
  setGeographicalExtention,
  updateGeographicalExtention,
  removeGeographicalExtention,
  setVehicleType,
  setIMT,
  removeIMT,
  updateSelectedAddons,
  removeSelectedAddons,
  // ---------------------------------New ui slice---------------------------------
  setCompareQuote,
  setTicketCardData,
  setCustomertype,
  setIsGst,
  setAllQuoteData,
  setSortedQuotes,
  setAdditionalPremiumChange,
  setGaragePopup,
  resetQuoteStates,
  setSpecialSchemeTitleClicked,
  setOpenDiscountPopUp,
  setRaiseDiscountRequestValue,
  setRaiseDiscountRequesLinkButtonClicked,
  setSelectedSpecialSchemeDiscountRange,
  setRiskStartDate,
  setRiskStartDatePopUp,
  setIsFilterLoading,
  setIsDiscountResetted,
  setVoluntaryExcessForInput,
  setVoluntaryExcessAmountForInput,
  setShouldShowEditableInputPageOnQuote,
  setEMICoverDetails,
  handleModal,
  handleDiscountCheckboxClick,
  setDiscountCheckboxes,
  resetInputDetailsStates,
  setConfineToSiteDetails,
  setICdetaislForSumInsured,
  setProductWiseSumInsuredDetais,
} = quote.actions;
export default quote.reducer;
