export const PVT_CAR = "pvt_car";
export const TWO_WHEELER = "2w";
export const THREE_WHEELER = "3w_c1b";
export const PCV_C1 = "pcv_c1";
export const PCV_C2 = "pcv_c2";
export const GCV_A1 = "gcv_a1";
export const GCV_A2 = "gcv_a2";
export const GCV_A3 = "gcv_a3";
export const GCV_A4 = "gcv_a4";
export const MISC_D = "misc_d";
