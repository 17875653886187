import servicing from "../../../assets/images/servicing.png";
import claims from "../../../assets/images/claim.png";
import configurator from "../../../assets/images/configurator.png";
import policy from "../../../assets/images/policy.png";
import master from "../../../assets/images/master.png";
import reports from "../../../assets/images/reports.png";
import policy_support from "../../../assets/images/policy_support.png";
import approval from "../../../assets/images/approval.png";
import dashboard from "../../../assets/images/business-report.png";
import feed_search from "../../../assets/images/feed_file.png";
import log from "../../../assets/images/logs.png";
import policy_bulk_upload from "../../../assets/images/bulkUpload.png";
import cancellation from "../../../assets/images/cancellation.png";
import ic_configurator from "../../../assets/images/file-system.png";
export const IconMap = {
  servicing,
  claims,
  policy,
  master,
  configurator,
  reports,
  policy_support,
  approval,
  dashboard,
  feed_search,
  cancellation,
  ic_configurator,
  log,
  policy_bulk_upload,
};

export const BOTTOM_NAVIGATION_HEIGHT = "50px";
