import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import api from "../api/api";
import { removeError } from "../modules/alert.slice";
import { errorHandler } from "../utils/errorHandler";
import { toast } from "react-toastify";
import { useGetCommunication } from "./quote.service";

export const useGetProposalDetailsFromProposalId = (proposal_id) => {
  const { data } = useQuery(
    ["proposal_details", proposal_id],
    () => {
      return api.post("final_proposal_detail/insurance/proposal/list", {
        proposal_id,
      });
    },
    { enabled: Boolean(proposal_id) }
  );
  return { proposal_details: data?.data?.return_data || [] };
};

export const UseUpdateProposal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { proposal_id } = useParams();
  const queryClient = useQueryClient();
  const { createCommunication } = useGetCommunication();
  const { mutate, isError, isSuccess, data, error, reset, isLoading } =
    useMutation(
      (data) => api.post("proposal_approval/update", data),

      {
        onSuccess: (res) => {
          if (res.data.status && res.data.status === 200) {
            if (
              res?.data?.return_data?.[0]?.ic_inspection_status === "approved"
            ) {
              createCommunication({
                type: ["email", "sms"],
                alias: "inspection_approval",
                proposal_id: res?.data?.return_data?.[0]?.proposal_no,
              });
            }
            if (
              res?.data?.return_data?.[0]?.ic_inspection_status === "rejected"
            ) {
              createCommunication({
                type: ["email", "sms"],
                alias: "inspection_rejected",
                proposal_id: res?.data?.return_data?.[0]?.proposal_no,
              });
            }
            const timer = setTimeout(() => {
              navigate("../");
              dispatch(removeError({ key: proposal_id || "test" }));
              queryClient.invalidateQueries(["proposal_approval"]);
              queryClient.invalidateQueries(["master"]);
            }, 3500);
            return () => {
              if (timer) clearTimeout(timer);
            };
          }
        },
      }
    );
  const errList = errorHandler(error, data);

  return {
    updateProposal: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isLoading: isLoading,
  };
};

// This should be mutation
export const UseSendPolicyRenewalMaill = (mail_id) => {
  const { data } = useQuery(
    ["mail", mail_id],
    () => {
      return api.get(`send_renewal_notice/${mail_id}`, {
        mail_id,
      });
    },

    {
      onSuccess: (data) => {
        if (data?.data?.message) {
          toast.success("Please check your email");
        }
      },
      enabled: !!mail_id,
    }
  );

  return { mail: data?.data?.message || [] };
};

export const useGetApdApprovalDetailsFromApdId = (apd_id) => {
  const { data } = useQuery(["master", "apd_details", apd_id], () => {
    return api.post("apd/list", {
      apd_id,
    });
  });
  return { apd_details: data?.data?.return_data || [] };
};

export const useSendPolicyReinstatementApprovalStatus = () => {
  const queryClient = useQueryClient();
  const { mutate, isError, isSuccess, data, error, reset, isLoading } =
    useMutation((data) => api.post("policy_reinstatement/approve", data), {
      onSuccess: () => {
        queryClient.invalidateQueries(["master"]);
      },
    });

  const errList = errorHandler(error, data);

  return {
    sendApprovalDetails: mutate,
    isSuccess,
    isError,
    alertMaster: errList,
    resetMaster: reset,
    isLoading,
  };
};
