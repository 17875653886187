// import { useSelector } from "react-redux";

export const sortingFn = (
  quotes,
  endpoints,
  sortedQuotes,
  additionalPremiumChange,
  additionalPremiumCount,
  selected_addons,
  plan_type,
  presetList
) => {
  const endpointQueryMap = endpoints.reduce((acc, endpoint, i) => {
    acc[endpoint] = quotes[i];
    return acc;
  }, {});

  const sortedEndpoints = Object.keys(endpointQueryMap)
    .filter((quote) => {
      const query = endpointQueryMap[quote];
      if (query.isError) return false;
      if (query.isLoading) return true;
      if (plan_type === "all") return true;
      return query?.data?.data?.product_data?.alias_name === plan_type;
    })
    .sort((a, b) => {
      const queryA = endpointQueryMap[a];
      const queryB = endpointQueryMap[b];
      const a_data = a.split("/");
      const b_data = b.split("/");
      const [productId_a, bundleId_a] = [a_data[3], a_data[4]];
      const [productId_b, bundleId_b] = [b_data[3], b_data[4]];

      const additionalPremiumA =
        additionalPremiumChange[[productId_a, bundleId_a].join("/")] || 0;
      const additionalPremiumB =
        additionalPremiumChange[[productId_b, bundleId_b].join("/")] || 0;
      const addonsLengthA =
        additionalPremiumCount[[productId_a, bundleId_a].join("/")] || 0;
      const addonsLengthB =
        additionalPremiumCount[[productId_b, bundleId_b].join("/")] || 0;
      // console.log(
      //   presetList?.ic_id?.includes(queryA?.data?.data?.insu_data?.ic_id),
      //   presetList?.ic_id?.includes(queryB?.data?.data?.insu_data?.ic_id),
      //   queryA?.data?.data?.insu_data?.ic_id,
      //   queryB?.data?.data?.insu_data?.ic_id,
      //   "preset"
      // );
      if (queryA.isLoading !== queryB.isLoading) {
        return queryA.isLoading ? 1 : -1;
      } else if (queryA.isError !== queryB.isError) {
        return queryA.isError ? 1 : -1;
      } else if (queryA.isSuccess && queryB.isSuccess) {
        if (
          presetList?.status === "Y" &&
          presetList?.ic_id?.some(
            (item) => +item === +queryA?.data?.data?.insu_data?.ic_id
          ) &&
          !presetList?.ic_id?.some(
            (item) => +item === +queryB?.data?.data?.insu_data?.ic_id
          ) &&
          presetList?.ic_id !== null
        ) {
          return -1;
        }
        if (
          presetList?.status === "Y" &&
          presetList?.ic_id?.some(
            (item) => +item === +queryB?.data?.data?.insu_data?.ic_id
          ) &&
          !presetList?.ic_id?.some(
            (item) => +item === +queryA?.data?.data?.insu_data?.ic_id
          ) &&
          presetList?.ic_id !== null
        ) {
          return 1;
        }

        if (sortedQuotes === 3) {
          return (
            queryA?.data?.data?.premium_arr?.total_premium +
            additionalPremiumA -
            (queryB?.data?.data?.premium_arr?.total_premium +
              additionalPremiumB)
          );
        }
        if (sortedQuotes === 4) {
          return (
            queryB?.data?.data?.premium_arr?.total_premium +
            additionalPremiumB -
            (queryA?.data?.data?.premium_arr?.total_premium +
              additionalPremiumA)
          );
        }
        if (sortedQuotes === 1) {
          const includedAddonsLengthA =
            queryA?.data?.data?.addon_arr?.filter((item) => item.is_included)
              .length || 0;
          const includedAddonsLengthB =
            queryB?.data?.data?.addon_arr?.filter((item) => item.is_included)
              .length || 0;

          if (productId_a === productId_b) {
            if (!addonsLengthA && !addonsLengthB) {
              return includedAddonsLengthB - includedAddonsLengthA;
            }
            return addonsLengthB - addonsLengthA;
          }
          if (!addonsLengthA && !addonsLengthB) {
            return includedAddonsLengthB - includedAddonsLengthA;
          }
          if (addonsLengthA === addonsLengthB)
            return (
              queryA?.data?.data?.premium_arr?.total_premium +
              additionalPremiumA -
              (queryB?.data?.data?.premium_arr?.total_premium +
                additionalPremiumB)
            );
          else return addonsLengthB - addonsLengthA;
        }
      } else {
        // sort by order in original array
        return endpoints.indexOf(a) > endpoints.indexOf(b) ? 1 : -1;
      }
    });
  return sortedEndpoints;
};
