import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router";
import { errorHandler } from "../utils/errorHandler";
import { convertToFormData } from "../utils/convertToFormData";
import api from "../api/api";
import {
  getActiveStep,
  stepsOtherThanRoot,
} from "../constants/icConfigConstants";

export const useSendIcDetails = (ic_form_slug, isDocumentUpload) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { ic_slug: ic_slug_in_url, id } = useParams();
  const { mutate, isSuccess, isError, data, error, reset } = useMutation(
    (payload) => {
      const newData = id
        ? { ic_id: id, ...payload, ic_slug: ic_slug_in_url || payload.ic_slug }
        : { ...payload, ic_slug: ic_slug_in_url || payload.ic_slug };

      return api.post(
        id ? `${ic_form_slug}/update` : `/${ic_form_slug}/add`,
        isDocumentUpload ? convertToFormData(newData) : newData
      );
    },
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          queryClient.resetQueries(["master"]);
          const timer = setTimeout(() => {
            const ic_slug = ic_slug_in_url || res?.data.return_data?.ic_slug;
            const activeStep = getActiveStep();
            const nextPath = stepsOtherThanRoot[1 + activeStep]?.path;
            const url = id
              ? `/ic_configurator/ic_onboard/update/${id + nextPath}`
              : `/ic_configurator/ic_onboard/add${nextPath}/${ic_slug}`;

            if (res.data.return_data?.final) {
              return navigate(`/ic_configurator/ic_onboard`);
            }
            navigate(url);
          }, 1500);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );

  const errList = errorHandler(error, data);

  return {
    sendIcDetails: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};

export const useGetPolicyPremiumCalculationFields = () => {
  const { data } = useQuery(["policy_premium_calculation"], () => {
    return api.post("ic_premium_calculation/list");
  });
  return {
    policy_premium_fields: data?.data?.return_data?.fields || [],
    policy_premium_parameters: data?.data?.return_data?.parameters || [],
  };
};

export const useGetAllLsoDetails = () => {
  const { data } = useQuery(["all_lso_list"], () => {
    return api.post("all_lso/list");
  });
  return {
    workshop_fields: data?.data?.return_data?.workshop || [],
    sales_fields: data?.data?.return_data?.sales || [],
  };
};

export const useSearchLsoDealerMapData = () => {
  const { mutate, isLoading, isError, isSuccess, data, error, reset } =
    useMutation((data) => {
      return api.post("/lso_dealer_mapping/list", data);
    });
  const errList = errorHandler(error, data);

  return {
    sendLsoDealerMapData: mutate,
    isLoading,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
    column_data: data?.data?.column_head,
    row_data: data?.data?.return_data,
  };
};

export const useSendLsoDealerMapTableData = () => {
  const { mutate, isLoading, isError, isSuccess, data, error, reset } =
    useMutation((data) => {
      return api.post("/lso_dealer_mapping/update", data);
    });
  const errList = errorHandler(error, data);

  return {
    sendLsoDealerMapTableData: mutate,
    isLoading,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};

export const useGetProductsFromSegmentId = (payload) => {
  const { data } = useQuery(["product_from_segment", payload], () => {
    return api.post("product/segment_wise", {
      segment_id: payload,
    });
  });
  return { product_items: data?.data?.return_data || [] };
};

export const useGetSurveyorICMapping = (surveyor_id) => {
  const { data, isLoading } = useQuery(
    ["surveyor_ic_mapping", surveyor_id],
    () => {
      return api.post("surveyor_ic_mapping/list", {
        surveyor_id,
      });
    }
  );
  return {
    previousMappedIcs: data?.data?.return_data || {},
    isMappedIcsLoading: isLoading,
  };
};

export const useGetSurveyorDetailsFromSurveyorID = (surveyor_id) => {
  const { data } = useQuery(
    ["surveyor_data_from_surveyor_id", surveyor_id],
    () => {
      return api.post("surveyor/list", {
        surveyor_id,
      });
    }
  );
  return {
    surveyor_data: data?.data?.return_data,
  };
};

export const useGetOemFromBrokerId = (broker_id) => {
  const { data } = useQuery(["oem_from_broker", broker_id], () => {
    return api.post("oem/list", { broker_id });
  });

  return {
    oem_items_by_broker: data?.data?.return_data,
  };
};

export const useGetIcFromOem = (oem_id) => {
  const { data } = useQuery(["ic_from_oem", oem_id], () => {
    return api.post("insurance/list", { oem_id });
  });
  return {
    ic_items_by_oem: data?.data?.return_data,
  };
};
