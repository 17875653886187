import { Box, DialogContent, Grid, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export const Main = styled(Box)(({ showMorePlansButton }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "0px",
  position: "relative",
  paddingLeft: "16px",
  marginBottom: showMorePlansButton && "20px",
}));

export const QuoteCardStyle = styled(Box)({
  // padding: "4px",
  // paddingLeft: "10px",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  marginTop: "10px",
});

export const QuoteContainer = styled(Box)({
  backgroundColor: "white",
  // width: "49%",
  width: "100%",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px 0px #00000029",
  zIndex: "2",
  padding: "10px",
  marginBottom: "30px",
  position: "relative",
});

export const QuoteHeader = styled(Box)({
  textAlign: "center",
  color: "#4f6781",
  fontSize: "10px",
});

export const Leftside = styled(Box)({
  display: "flex",
  flexDirection: "column-reverse",
  gap: "10px",
  justifyContent: "flex-end",
});

export const Rightside = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
});

export const GreenButton = styled(Box)({
  height: "35px",
  width: "fit-content",
  padding: "0px 10px",
  border: "2px solid #03cc83",
  display: "flex",
  justifyContent: "center",
  borderRadius: "20px",
  alignItems: "center",
  backgroundColor: "#f3fffb",
  cursor: "pointer",
});

export const DiscountButton = styled(Box)({
  color: "white",
  // zIndex: "-1",
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  fontSize: "10px",
  cursor: "pointer",
});

export const DiscountText = styled(Box)({
  width: "fit-content",
  backgroundColor: "#ffbb05",
  padding: "3px 8px",
  borderRadius: "6px",
  position: "absolute",
  top: "30",
  paddingBottom: "25px",
});

export const MorePlanButton = styled(Box)({
  position: "absolute",
  cursor: "pointer",
  zIndex: -1,
  width: "120px",
  textAlign: "center",
  borderBottomRightRadius: "20px",
  borderBottomLeftRadius: "20px",
  left: "50%",
  bottom: 0,
  transform: "translate(-50%, 100%)",
  fontSize: "12px",
  backgroundColor: "#4f67814d",
  boxShadow: "0px 2px 3px 0px #4f678157",
});

export const Addons = styled(Box)({
  fontSize: "12px",
  color: "#4f6781",
});

export const AddonsWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "10px",
  flexWrap: "wrap",
  marginTop: "8px",
  // changed width, max-height ===== max-height  to limit only two rows ===== overflow hidden to hide remaining rows
  // border: "1px solid red",
  // width: "83%",
  flex: 1,
  overflow: "hidden",
  maxHeight: "100%",
  position: "relative",
}));

// added button 'more' to reveal remaining addons
export const MoreButton = styled(Box)({
  display: "flex",
  fontSize: "12px",
  justifyContent: "end",
  alignItems: "end",
  cursor: "pointer",
  // position: "relative",
  // bottom: "0",
  // right: "0",
  marginLeft: "10px",
  marginTop: "5px",
  color: "#03cc83",
});

export const AddonsSubWrapper = styled(Box)(({ theme, isAvailable }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: isAvailable
    ? alpha(theme.palette.primary.main, 0.42)
    : alpha("#ffb5b5", 0.42),
  width: "fit-content",
  borderRadius: "20px",
  padding: "5px 10px 5px 5px",
}));

export const AddonsCheckIconContainer = styled(Box)({
  backgroundColor: "white",
  borderRadius: "50%",
  padding: "2px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "5px",
});

export const AddonsSubText = styled(Box)({
  fontSize: "11px",
  color: "#4f6781",
});

export const CompareWrapper = styled(Box)({
  display: "flex",
  gap: "5px",
  marginBottom: "0px",
  marginTop: "5px",
  padding: "0px",
  borderRadius: "5px",
  alignItems: "center",
});

export const CashlessGaragesTitle = styled(Typography)({
  color: "#4f6781",
  fontSize: "10px",
  cursor: "pointer",
  marginTop: "2px",
  padding: "3px",
});

export const SpecialSchemeContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  border: "1px solid blue",
  width: "100%",
  padding: "5px",
  gap: 1,
  borderRadius: "5px",
  marginTop: "5px",
  height: "25px",
  background: "rgba(0, 0, 255, 0.1)",
  cursor: "pointer",
});

export const SpecialSchemePercentContainer = styled(Box)({
  backgroundColor: "blue",
  color: "white",
  borderRadius: "5px",
  height: "15px",
  width: "15px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const RaiseDiscountLinkButton = styled(Box)({
  color: "blue",
  textDecoration: "underline",
  textAlign: "end",
  cursor: "pointer",
});

export const RaiseDiscountPopupContent = styled(DialogContent)({
  minWidth: "200px",
});

export const SumInsuredEditButton = styled(EditOutlinedIcon)({
  fontSize: "12px",
  cursor: "pointer",
  color: "#4f6781",
  fontWeight: "bold",
});

export const SumInsuredHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
});

export const SumInsuredGridItem = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
  gap: "20px",
  margin: "30px 20px 20px",
  whiteSpace: "nowrap",
});

export const SumInsuredSubmitButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "15px",
});
