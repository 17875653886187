import { Grid } from "@mui/material";
import React from "react";
import ClaimDocumentUploadFields from "./DocumentFormFields";
import TextInput from "../../../../../../../../components/InputComponents/TextInput";
import DateInput from "../../../../../../../../components/InputComponents/DateInput";
import { allowOnlyNumbers } from "../../../../../../../../InputUtils/onInput";
import moment from "moment";

function ProformaFormFields() {
  return (
    <>
      <Grid container spacing={2} mt={0} px={2}>
        <Grid item lg={4} xxs={6}>
          <TextInput
            label="Proforma Invoice No."
            name="proforma_invoice_no"
            required
          />
        </Grid>
        <Grid item lg={4} xxs={6}>
          <DateInput
            label="Proforma Invoice Date"
            name="proforma_invoice_date"
            maxDate={moment()}
            required
          />
        </Grid>
        <Grid item lg={4} xxs={6}>
          <TextInput
            label="Proforma Invoice Amount"
            name="proforma_invoice_amount"
            onInput={allowOnlyNumbers}
            required
          />
        </Grid>
      </Grid>
      <ClaimDocumentUploadFields
        document_name={"proforma_invoice_document"}
        remark_name={"proforma_invoice_remark"}
      />
    </>
  );
}

export default ProformaFormFields;
