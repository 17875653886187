import React from "react";
import { useParams } from "react-router";
import { MainContainer } from "../CancellationApproval/CancellationApprovalStyles";
import LeftCancellationApprovalDetails from "../CancellationApproval/LeftCancellationApprovalDetails";
import RightCancellationApprovedSummary from "./RightCancellationApprovedSummary";
import withRouteProtection from "../../../../hoc/withRouteProtection";
import Navbar from "../../../../components/Navbar";

const CancellationRefund = ({ type }) => {
  const { booking_id } = useParams();

  return (
    <Navbar>
      <MainContainer>
        <LeftCancellationApprovalDetails booking_id={booking_id} type={type} />
        <RightCancellationApprovedSummary booking_id={booking_id} type={type} />
      </MainContainer>
    </Navbar>
  );
};

export default withRouteProtection(CancellationRefund);
