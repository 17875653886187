import React from "react";
import { useGetExceptionLogFromExceptionLogId } from "../../../../services/auditLog.service";
import { useParams } from "react-router";
import withRouteProtection from "../../../../hoc/withRouteProtection";
import Navbar from "../../../../components/Navbar";
import { Papers } from "../../../masters/components/MasterStyle";
import { MainContainer } from "../styles/exceptionLogs.styles";
import { Box } from "@mui/material";
import { Head, Value } from "../../IcApiLogView";
import moment from "moment";

function ExceptionLog() {
  const { exception_log_id } = useParams("exception_log_id");
  const { log } = useGetExceptionLogFromExceptionLogId(exception_log_id);

  const userData =
    log?.user_name && log?.user_id ? `${log?.user_name} (${log?.user_id})` : "";

  return (
    <Navbar>
      <Papers>
        <MainContainer>
          <Box>
            <Head>USER</Head>
            <Value>{userData || "---"}</Value>
          </Box>
          <Box>
            <Head>LOG CREATED AT</Head>
            <Value>
              {log?.created_at_format
                ? moment(log?.created_at_format).format("DD MMM YYYY hh:mm:ss")
                : "---"}
            </Value>
          </Box>
          <Box>
            <Head>LOG UPDATED AT</Head>
            <Value>
              {log?.updated_at_format
                ? moment(log?.created_at_format).format("DD MMM YYYY hh:mm:ss")
                : "---"}
            </Value>
          </Box>
          <Box>
            <Head>LOG EXCEPTION</Head>
            <Value>{log?.exception || "---"}</Value>
          </Box>
          <Box>
            <Head>LOG MESSAGE</Head>
            <Value>{log?.message || "---"}</Value>
          </Box>
          <Box>
            <Head>LOG FILE</Head>
            <Value>{log?.file || "---"}</Value>
          </Box>
          <Box>
            <Head>LOG TRACE</Head>
            <Value>{log?.trace || "---"}</Value>
          </Box>
        </MainContainer>
      </Papers>
    </Navbar>
  );
}

export default withRouteProtection(ExceptionLog);
