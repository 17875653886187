import { yupResolver } from "@hookform/resolvers/yup";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import AlertMessage from "../../../../../../../../components/Alert/AlertMessage";
import { SubmitButton } from "../../../../../../../../components/InputComponents/InputComponents.style";
import {
  useGetPolicyDetailsRegistration,
  useSendProformaDetails,
} from "../../../../../../../../services/claims.service";
import {
  AtleastOnefieldError,
  ButtonWrapper,
} from "../../../../../../../masters/components/MasterStyle";
import {
  ClaimHeading,
  MainPaperContainer,
} from "../../../../../../styles/claim.styles";
import ProformaFormFields from "../components/ProformaFormFields";
import { proformaInvoiceSchema } from "../validations/proformaInvoice.validations";
import { toast } from "react-toastify";

function ProformaForm() {
  const { claim_detail_id } = useParams("claim_detail_id");

  const { policy_details_list: claimDetailsFromClaimId } =
    useGetPolicyDetailsRegistration(claim_detail_id);

  const claim_details_from_claim_id = claimDetailsFromClaimId?.[0];

  const { sendProformaDetails, isSuccess, alertMaster, resetMaster, isError } =
    useSendProformaDetails();

  const previousSelectedValues = {
    proforma_invoice_no: claim_details_from_claim_id?.proforma_invoice_no || "",
    proforma_invoice_date:
      claim_details_from_claim_id?.proforma_invoice_date || "",
    proforma_invoice_amount:
      claim_details_from_claim_id?.proforma_invoice_amount || "",
    proforma_invoice_remark:
      claim_details_from_claim_id?.proforma_invoice_remark || "",
  };

  const isPreviousDetailsAvailable = Object?.values(
    previousSelectedValues
  ).some((item) => !!item);

  const methods = useForm({
    resolver: yupResolver(
      proformaInvoiceSchema({ isPreviousDetailsAvailable })
    ),
    defaultValues: {
      proforma_invoice_no:
        claim_details_from_claim_id?.proforma_invoice_no || "",
      proforma_invoice_date:
        claim_details_from_claim_id?.proforma_invoice_date || "",
      proforma_invoice_amount:
        claim_details_from_claim_id?.proforma_invoice_amount || "",
      proforma_invoice_remark:
        claim_details_from_claim_id?.proforma_invoice_remark || "",
    },
  });

  const {
    proforma_invoice_no,
    proforma_invoice_date,
    proforma_invoice_amount,
    proforma_invoice_remark,
    proforma_invoice_document,
  } = methods.getValues();

  const isDocumentSelected = proforma_invoice_document?.size > 0;

  const enteredValues = {
    proforma_invoice_no,
    proforma_invoice_date,
    proforma_invoice_amount,
    proforma_invoice_remark,
  };

  const onSubmit = (data) => {
    const isSameAsPreviousValues = isEqual(
      previousSelectedValues,
      enteredValues
    );
    if (isSameAsPreviousValues && !isDocumentSelected) {
      toast.info("No details have been changed!");
    }

    const payload = {
      ...data,
      claim_detail_id,
      claim_stage: claim_details_from_claim_id?.claim_stage,
    };
    sendProformaDetails(payload);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <MainPaperContainer>
          <ClaimHeading>Proforma Invoice</ClaimHeading>
          <AlertMessage
            alert={alertMaster}
            reset={resetMaster}
            isError={isError}
            isSuccess={isSuccess}
          />
          <ProformaFormFields />
          <ButtonWrapper>
            <SubmitButton variant="contained" type="submit">
              Submit
            </SubmitButton>
          </ButtonWrapper>
        </MainPaperContainer>
      </form>
    </FormProvider>
  );
}

export default ProformaForm;
