import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { matchPath, useLocation, useNavigate, useParams } from "react-router";
import api from "../api/api";
import useUrlQuery from "../custom-hooks/useUrlQuery";
import { removeError } from "../modules/alert.slice";
import { convertToFormData } from "../utils/convertToFormData";
import { errorHandler } from "../utils/errorHandler";

export const useCreateClaim = () => {
  const queryClient = useQueryClient();
  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => api.post("claim_detail/add", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["master", "claim"]);
        queryClient.invalidateQueries(["master", "claim_detail"]);
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    createItem: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
  };
};

export const useClaimNotification = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate, isError, isSuccess, data, error, reset, isLoading } =
    useMutation(
      (data) => {
        const formData = convertToFormData(data);
        return api.post("/claim_detail/add", formData);
      },
      {
        onSuccess: (res) => {
          if (res.data.status && res.data.status !== 500) {
            const timer = setTimeout(() => {
              navigate("/claims/claim_details");
            }, 3500);
            queryClient.invalidateQueries(["master"]);
            queryClient.invalidateQueries(["claim"]);
            queryClient.invalidateQueries(["claim_detail"]);
            queryClient.invalidateQueries(["policy_detail"]);
            queryClient.invalidateQueries(["claim_notification"]);
            return () => {
              if (timer) clearTimeout(timer);
            };
          }
        },
      }
    );
  const errList = errorHandler(error, data);

  return {
    createClaimNotification: mutate,
    isSuccess: isSuccess && data?.data?.status === 200 ? true : false,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isLoading: isLoading,
  };
};
// TODO: duplicate for `useGetSurveyorDetails`
export const useGetPolicyDetailsRegistration = (claim_detail_id) => {
  const { getUrlQuery } = useUrlQuery();
  const customer_link = getUrlQuery("customer_link");
  const { data } = useQuery(
    ["claim_detail", claim_detail_id],
    () => {
      const paramMatch = matchPath(
        {
          path: "/claims/:type/add/",
          end: false,
        },
        location.pathname
      );
      if (!claim_detail_id) return;
      return api.post(
        paramMatch?.params?.type
          ? `${paramMatch?.params?.type}/claim/list`
          : `/claim/list`,
        {
          claim_detail_id,
          ...(customer_link && { customer_link: !!customer_link }),
        }
      );
    },
    { enabled: true }
  );

  return {
    policy_details_list: data?.data?.return_data || [],
    road_side_assistance: data?.data?.road_side_assistance,
  };
};

export const useGetPolicyDetails = (booking_id) => {
  const { getUrlQuery } = useUrlQuery();
  const customer_link = getUrlQuery("customer_link");
  const location = useLocation();
  const paramMatch = matchPath(
    {
      path: "/claims/:type/add/",
      end: false,
    },
    location.pathname
  );

  const { data } = useQuery(
    ["policy_detail", booking_id],
    () => {
      return api.post(
        paramMatch?.params?.type
          ? `${paramMatch?.params?.type}/booking_detail/list`
          : `/booking_detail/list`,
        {
          column_filters: [
            {
              id: "booking_id",
              value: booking_id,
            },
          ],
          ...(customer_link && { customer_link: !!customer_link }),
          edit_claim: true,
        }
      );
    },
    { enabled: true }
  );

  return {
    policy_details_list: data?.data?.return_data || [],
  };
};
// TODO: duplicate for `useGetPolicyDetailsRegistration`
export const useGetSurveyorDetails = (claim_detail_id) => {
  const { data } = useQuery(
    ["surveyor_details", claim_detail_id],
    () => {
      return api.post(`surveyor_appointment/claim/list`, {
        // columnFilters: [
        //   {
        //     id: "claim_detail_id",
        //     value: claim_detail_id,
        //   },
        // ],
        claim_detail_id,
      });
    },
    {
      enabled: true,
    }
  );

  return { surveyor_details_list: data?.data?.return_data || [] };
};

export const useGetSurveyorDetailsByClaimId = (claim_detail_id) => {
  const { data } = useQuery(
    ["surveyor_details", claim_detail_id],
    () => {
      return api.post(`surveyor/list`, {
        claim_detail_id,
      });
    },
    {
      enabled: true,
    }
  );
  return { surveyor_name_options: data?.data?.return_data || [] };
};

export const useGetDetailsFromSurveyorName = (surveyor_id) => {
  const { data } = useQuery(
    ["surveyor_id", surveyor_id],
    () => {
      return api.post(`surveyor/list`, {
        surveyor_id: surveyor_id,
      });
    },
    { enabled: Boolean(surveyor_id) }
  );
  return { table_list: data?.data?.return_data };
};

export const useSendSurveyorDetails = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { mutate, isError, isSuccess, data, error, reset, isLoading } =
    useMutation((data) => api.post("/surveyor_update/update", data), {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            navigate("/claims/surveyor_appointment");
            dispatch(removeError({ key: id || "test" }));
          }, 3500);
          queryClient.invalidateQueries(["master"]);
          queryClient.invalidateQueries(["claim"]);
          queryClient.invalidateQueries(["claim_detail"]);
          queryClient.invalidateQueries(["policy_detail"]);
          queryClient.invalidateQueries(["assign_surveyor_details"]);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    });
  const errList = errorHandler(error, data);

  return {
    createItem: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isLoading: isLoading,
  };
};

export const useClaimRegistration = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isError, isSuccess, data, error, reset, isLoading } =
    useMutation(
      (data) => {
        const formData = convertToFormData(data);
        return api.post("/claim_detail/update", formData);
      },

      {
        onSuccess: (res) => {
          if (res.data.status && res.data.status !== 500) {
            const timer = setTimeout(() => {
              navigate(`/claims/claim_details`);
            }, 3500);
            queryClient.invalidateQueries(["master"]);
            queryClient.invalidateQueries(["claim"]);
            queryClient.invalidateQueries(["claim_detail"]);
            queryClient.invalidateQueries(["policy_detail"]);
            queryClient.invalidateQueries(["claim_registration"]);
            return () => {
              if (timer) clearTimeout(timer);
            };
          }
        },
      }
    );
  const errList = errorHandler(error, data);

  return {
    createClaimRegistration: mutate,
    isSuccess: isSuccess && data?.data?.status === 200 ? true : false,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isLoading: isLoading,
  };
};

export const useGetWorkShopDetails = (workshop_id) => {
  const { data } = useQuery(
    ["workshop_details", workshop_id],
    () => {
      return api.post(`/claim_detail/workshop`, {
        workshop_id: workshop_id,
      });
    },
    {
      enabled: true,
    }
  );

  return { workshop_details: data?.data?.return_data || [] };
};

export const useGetDealerListFromStateAndCity = (dealer_state, dealer_city) => {
  const { data } = useQuery(
    ["dealer_lists", dealer_state, dealer_city],
    () => {
      return api.post(`/claim_detail/dealerlist`, {
        state_id: dealer_state,
        city_id: dealer_city,
      });
    },
    {
      enabled: Boolean(dealer_state) && Boolean(dealer_city),
    }
  );

  return { dealer_list: data?.data?.return_data || [] };
};

export const useGetDealerDetails = (dealer_id) => {
  const { data } = useQuery(
    ["dealer_details", dealer_id],
    () => {
      return api.post(`/claim_detail/dealerid`, {
        dealer_id,
      });
    },
    {
      enabled: Boolean(dealer_id),
    }
  );

  return { dealer_details: data?.data?.return_data || [] };
};

export const useGetCurrentDetails = (user_email) => {
  const { data } = useQuery(
    ["current_user_list", user_email],
    () => {
      return api.post(`userList/list`, {
        email_id: user_email,
      });
    },
    {
      enabled: true,
    }
  );

  return { current_user_list: data?.data?.return_data || [] };
};

export const useGetDateTimeDeatils = () => {
  const queryClient = useQueryClient();
  const {
    mutate,
    data: dateTimeData,
    isError,
    isSuccess,
    error,
    reset,
  } = useMutation((data) => api.post("/claim_detail/DateOrTime", data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["claim_date_time"]);
    },
  });
  const errList = errorHandler(error, dateTimeData);

  return {
    createDateTime: mutate,
    isSuccessDateTime:
      isSuccess && dateTimeData?.data?.status === 200 ? true : false,
    isErrorDateTime: isError,
    alertMasterDateTime: errList,
    dataMasterDateTime: dateTimeData,
    resetMasterDateTime: reset,
  };
};

export const useGetClaimWithdwaw = () => {
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, error, reset, isLoading } =
    useMutation((data) => api.post("/claim_withdraw/withdrawn", data), {
      onSuccess: () => {
        queryClient.invalidateQueries(["master"]);
      },
    });
  const errList = errorHandler(error, data);

  return {
    createWithdraw: mutate,
    isSuccess: isSuccess && data?.data?.status === 200 ? true : false,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isLoading: isLoading,
  };
};

export const useSendClaimDocument = () => {
  const { getUrlQuery } = useUrlQuery();
  const customer_link = getUrlQuery("customer_link");
  const { mutate, data, isError, isSuccess, error, reset, isLoading } =
    useMutation((data) =>
      api.post("/share_document/share", convertToFormData(data), {
        enabled: !!customer_link,
      })
    );
  const errList = errorHandler(error, data);

  return {
    createClaimDocument: mutate,
    isSuccess: isSuccess && data?.data?.status === 200 ? true : false,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isLoading: isLoading,
  };
};

export const useGetSurveyorOptionsFromClaimId = (claim_detail_id) => {
  const { data } = useQuery(
    ["surveyor_options", claim_detail_id],
    () => {
      return api.post(`/surveyor/list`, {
        claim_detail_id,
        sorting: {
          id: "surveyor_name",
          value: "asc",
        },
      });
    },
    {
      enabled: true,
    }
  );

  return { surveyor_options: data?.data?.return_data || [] };
};

export const useEditClaimDetails = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, error, reset } = useMutation(
    (data) => {
      const convertedData = convertToFormData(data);
      return api.post("claim_detail/edit", convertedData);
    },
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            navigate("../../claims/claim_details");
          }, 3500);
          queryClient.invalidateQueries(["master"]);
          queryClient.invalidateQueries(["claim"]);
          queryClient.invalidateQueries(["claim_detail"]);
          queryClient.invalidateQueries(["policy_detail"]);
          queryClient.invalidateQueries(["claim_notification"]);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    editClaimDetails: mutate,
    isSuccessForEdit: isSuccess,
    isErrorForEdit: isError,
    alertMasterForEdit: errList,
    resetMasterForEdit: reset,
  };
};

export const useApproveClaim = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, data, isError, isSuccess, error, reset } = useMutation(
    (data) => api.post("claim_approval/add", data),
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            navigate("../../claims/claim_approval");
            queryClient.invalidateQueries(["master"]);
            queryClient.invalidateQueries(["claim"]);
            queryClient.invalidateQueries(["claim_detail"]);
            queryClient.invalidateQueries(["policy_detail"]);
            queryClient.invalidateQueries(["claim_notification"]);
          }, 3500);

          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    approveClaim: mutate,
    isSuccess,
    isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};

export const useSettleClaim = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, data, isError, isSuccess, error, reset } = useMutation(
    (data) => api.post("claim_settlement/add", data),
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            navigate("../../claims/claim_settlement");
            queryClient.invalidateQueries(["master"]);
            queryClient.invalidateQueries(["claim"]);
            queryClient.invalidateQueries(["claim_detail"]);
            queryClient.invalidateQueries(["policy_detail"]);
            queryClient.invalidateQueries(["claim_notification"]);
          }, 3500);

          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    settleClaim: mutate,
    isSuccess,
    isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};

export const useSendSurveyorAssesmentDetails = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, error, reset } = useMutation(
    (data) => {
      const convertedData = convertToFormData(data);
      return api.post("surveyor_assessment/add", convertedData);
    },
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            navigate("../../claims/surveyor_assessment");
            queryClient.invalidateQueries(["master"]);
            queryClient.invalidateQueries(["claim"]);
            queryClient.invalidateQueries(["claim_detail"]);
            queryClient.invalidateQueries(["policy_detail"]);
            queryClient.invalidateQueries(["claim_notification"]);
          }, 3500);

          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    sendSurveyorAssessmentDetails: mutate,
    isSuccess,
    isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};

export const useSendProformaDetails = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, error, reset } = useMutation(
    (data) => {
      const convertedData = convertToFormData(data);
      return api.post("proforma_invoice/add", convertedData);
    },
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            navigate("../../claims/proforma_invoice");
            queryClient.resetQueries(["master"]);
            queryClient.resetQueries(["claim"]);
            queryClient.resetQueries(["claim_detail"]);

            queryClient.resetQueries(["policy_detail"]);
            queryClient.resetQueries(["claim_notification"]);
          }, 3500);

          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    sendProformaDetails: mutate,
    isSuccess,
    isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};

export const useSendDeliveryOrderDetails = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, error, reset } = useMutation(
    (data) => {
      const convertedData = convertToFormData(data);
      return api.post("delivery_order/add", convertedData);
    },
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            navigate("../../claims/delivery_order");
            queryClient.invalidateQueries(["master"]);
            queryClient.invalidateQueries(["claim"]);
            queryClient.invalidateQueries(["claim_detail"]);
            queryClient.invalidateQueries(["policy_detail"]);
            queryClient.invalidateQueries(["claim_notification"]);
          }, 3500);

          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    sendDeliveryOrderDetails: mutate,
    isSuccess,
    isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};

export const useSendClaimInvoiceDetails = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, data, isError, isSuccess, error, reset } = useMutation(
    (data) => {
      const convertedData = convertToFormData(data);
      return api.post("claim_invoice/add", convertedData);
    },
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            navigate("../../claims/claim_invoice");
            queryClient.invalidateQueries(["master"]);
            queryClient.invalidateQueries(["claim"]);
            queryClient.invalidateQueries(["claim_detail"]);
            queryClient.invalidateQueries(["policy_detail"]);
            queryClient.invalidateQueries(["claim_notification"]);
          }, 3500);

          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    sendClaimInvoiceDetails: mutate,
    isSuccess,
    isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};
