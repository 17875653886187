import { useContext } from "react";
import { AuthContext } from "./withRouteProtection";

const LogAccessControl = ({ children }) => {
  const { logAccess } = useContext(AuthContext);
  if (logAccess) return children;
  else return <></>;
};

export default LogAccessControl;
