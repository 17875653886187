import { lazy } from "react";
import { Navigate, matchPath } from "react-router";
import PaymentError from "../components/BasicFormComponent/PaymentError";
import Error from "../components/Error";
import NotFound from "../components/NotFound";
import {
  GCV_A1,
  GCV_A2,
  GCV_A3,
  GCV_A4,
  MISC_D,
  PCV_C1,
  PCV_C2,
  PVT_CAR,
  THREE_WHEELER,
  TWO_WHEELER,
} from "../constants/segments";
import BulkImportQuotes from "../pages/BulkImport/BulkImportQuotes";
import ClaimDetailsView from "../pages/Claim/ClaimDetails/ClaimDeatilsView/ClaimDetailsView";
import AddOnConfigurator from "../pages/Configurator/AddOnConfig/AddOnConfigurator";
import FormsICConfigurator from "../pages/Configurator/ICConfigurator/FomsICConfigurator";
import ICConfigurator from "../pages/Configurator/ICConfigurator/ICConfigurator";
import AddonWordings from "../pages/Configurator/ICConfigurator/forms/AddonWording/AddonWordings";
import CompanyDetails from "../pages/Configurator/ICConfigurator/forms/CompanyDetails/CompanyDetails";
import IcDealerMappingSearch from "../pages/Configurator/ICConfigurator/forms/ICDealerMapping/IcDealerMappingSearch";
import Inspection from "../pages/Configurator/ICConfigurator/forms/Inspection/Inspection";
import ClaimLSOForm from "../pages/Configurator/ICConfigurator/forms/LSO/ClaimLSO/ClaimLSOForm";
import LSOForm from "../pages/Configurator/ICConfigurator/forms/LSO/PolicyLSO/LSOForm";
import PolicyWordings from "../pages/Configurator/ICConfigurator/forms/PolicyWordings/PolicyWordings";
import DiscountApprovalSearch from "../pages/IcApprovals/DiscountApproval/DiscountApprovalSearch";
import PolicyListing from "../pages/Listing/PolicyListing";
import RenewalLeadSearch from "../pages/RenewalLead/RenewalLeadSearch/RenewalLeadSearch";
import DealerReport from "../pages/Report/DealerReport";
import IcReport from "../pages/Report/IcReport";
import CkycRedirection from "../pages/ckyc-redirection/CkycRedirection";
import FeedSearch from "../pages/feedSearch/FeedSearch";
import AddBrokerMobileRequest from "../pages/masters/forms/BrokerMobileRequest/AddBrokerMobileRequest";
import BrokerMobileRequest from "../pages/masters/forms/BrokerMobileRequest/BrokerMobileRequest";
import AddMobileNoApproval from "../pages/masters/forms/MobileNoApproval/AddMobileNoApproval";
import MobileNoApproval from "../pages/masters/forms/MobileNoApproval/MobileNoApproval";
import RmMobileApproval from "../pages/masters/forms/RmMobileApproval/RmMobileApproval";
import PayInSlipPrintPreview from "../pages/payment/PayInSlip/PayInSlipPrintPreview";
import DealerMandateSearch from "../pages/servicing/OtpMandate/DealerMandateSearch";
import OtpMandateForm from "../pages/servicing/OtpMandate/OtpMandateForm";
import CancellationRefund from "../pages/servicing/cancellation/Refund/CancellationRefund";
import SearchCancellationRefund from "../pages/servicing/cancellation/Refund/SearchCancellationRefund";
const Editable = lazy(() => import("../hoc/Editable"));
const Proposal = lazy(() => import("../pages/proposal"));
const ODDiscountConfigurator = lazy(() =>
  import("../pages/Configurator/DiscountConfiguratorAdd/ODDiscountConfigurator")
);
const Quotepage = lazy(() => import("../pages/quotes/Quotepage"));
const AddOnConfig = lazy(() =>
  import("../pages/Configurator/AddOnConfig/AddOnConfig")
);
const ReportConfigurator = lazy(() =>
  import("../pages/Configurator/ReportConfigurator/reportConfigurator")
);
const PaymentSuccess = lazy(() => import("../pages/payment/payment-success"));
const Summary = lazy(() => import("../pages/summary/Summary"));
const DiscountConfigurator = lazy(() =>
  import("../pages/Configurator/DiscountConfigurator/DiscountConfigurator")
);

const ClaimSurveyorAppointment = lazy(() =>
  import("../pages/Claim/AppointSurveyor/ClaimSurveyorAppointment")
);
const CompareQuotes = lazy(() =>
  import("../pages/compareQuotes/compareQuotes")
);
const SearchSurveyorAppointmentForm = lazy(() =>
  import(
    "../pages/Claim/SearchSurveyorAppointment/SearchSurveyorAppointmentForm"
  )
);
const PayInSlipSearchForm = lazy(() =>
  import("../pages/payment/PayInSlip/PayInSlipSearchForm")
);
const ClaimDetailsSearch = lazy(() =>
  import("../pages/Claim/ClaimDetails/ClaimDetailsSearch")
);
const ViewReport = lazy(() => import("../pages/Report/ViewReport"));
const QuoteListing = lazy(() => import("../pages/Listing/QuoteListing"));
const ProposalListing = lazy(() => import("../pages/Listing/ProposalListing"));
const Endorsement = lazy(() => import("../pages/endorcement/Endrosement"));
const VB64 = lazy(() => import("../pages/servicing/VB64/VB64"));
const CancellationSearchForm = lazy(() =>
  import(
    "../pages/servicing/cancellation/CancellationSearch/CancellationSearchForm"
  )
);
const CancellationAdd = lazy(() =>
  import("../pages/servicing/cancellation/CancellationAdd/CancellationAdd")
);
const SpotIntimationSearchForm = lazy(() =>
  import(
    "../pages/Claim/forms/spotIntimation/components/SpotIntimationSearchForm"
  )
);
const ClaimIntimationSearchForm = lazy(() =>
  import(
    "../pages/Claim/forms/claimIntimation/components/ClaimIntimationSearchForm"
  )
);
const EndorsementType = lazy(() =>
  import("../pages/endorsementType/endorsementType")
);
const CancellationApprovalDetails = lazy(() =>
  import(
    "../pages/servicing/cancellation/CancellationApproval/CancellationApprovalDetails"
  )
);
const BillingReport = lazy(() => import("../pages/Report/BillingReport"));
const BookingReport = lazy(() => import("../pages/Report/BookingReport"));
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const ApdSearch = lazy(() =>
  import("../pages/apd/APD Form/apdTransaction/ApdSearch")
);
const PolicyRenewalListing = lazy(() =>
  import("../pages/Listing/PolicyRenewalListing")
);
const DraftedProposalListing = lazy(() =>
  import("../pages/Listing/DraftedProposalListing")
);
const PolicyReinstatement = lazy(() =>
  import("../pages/servicing/Reinstatement/PolicyReinstatement")
);
const SelfInspection = lazy(() =>
  import("../pages/self-inspection/SelfInspection")
);

const ApdListing = lazy(() =>
  import("../pages/apd/APD Form/apdApproval/ApdListing")
);
const EndorsementApprovalSearch = lazy(() =>
  import("../pages/endorcement/EndorsementApproval/EndorsementApprovalSearch")
);
const ProposalApproval = lazy(() =>
  import("../pages/IcApprovals/ProposalApproval")
);
const ProposalApprovalSummary = lazy(() =>
  import("../pages/IcApprovals/ProposalApprovalSummary")
);
const SelfInspectionHeader = lazy(() =>
  import("../pages/self-inspection/SelfInspectionHeader")
);
const ResetPassword = lazy(() =>
  import("../pages/masters/forms/UserResetPassword/resetPassword")
);

const MasterForm = lazy(() => import("../pages/masters/forms/MasterForm"));

import ICBrokerMapping from "../pages/Configurator/ICBrokerMapping/ICBrokerMapping";
import IcConfigMiscellaneousMappings from "../pages/Configurator/ICConfigurator/forms/MiscellaneousMappings/IcConfigMiscellaneousMappings";
import ICConfigPACover from "../pages/Configurator/ICConfigurator/forms/PACover/ICConfigPACover";
import PolicyPremiumCalculations from "../pages/Configurator/ICConfigurator/forms/PolicyPremiumCalculations/PolicyPremiumCalculations";
import IMTConfigurator from "../pages/Configurator/IMTConfigurator/IMTConfigurator";
import LSODealerMappingSearch from "../pages/Configurator/LSODealerMapping/LSODealerMappingSearch";
import IcApprovalStatusListing from "../pages/Listing/IcApprovalStatusListing";
import PolicyReinstateListing from "../pages/Listing/PolicyReinstateListing";
import AuditLogForm from "../pages/auditLog/AuditLogForm";
import ProposalCancellationSearch from "../pages/servicing/cancellation/ProposalCancellation/ProposalCancellationSearch";
const IcAPIMapping = lazy(() =>
  import("../pages/Configurator/ICConfigurator/forms/APIMapping/IcAPIMapping")
);
const PolicyInputForm = lazy(() => import("../pages/PolicyInput"));
const PolicyPDFNotes = lazy(() =>
  import(
    "../pages/Configurator/ICConfigurator/forms/PolicyPDFNotes/PolicyPDFNotes"
  )
);

import NewLogin from "../components/Login/NewLogin/NewLogin";
import BulkUpload from "../pages/BulkUpload/BulkUpload";
import ClaimCustomerDocumentUpload from "../pages/Claim/ClaimCustomerDocumentUpload/ClaimCustomerDocumentUpload";
import ClaimCategoryRange from "../pages/Configurator/ICConfigurator/forms/ClaimCategoryRange/ClaimCategoryRange";
import ICSurveyorMapping from "../pages/Configurator/ICSurveyorMapping/ICSurveyorMapping";
import MapICToSelectedSurveyor from "../pages/Configurator/ICSurveyorMapping/MapICToSelectedSurveyor";
import ReportConfiguratorList from "../pages/Configurator/ReportConfigurator/ReportConfiguratorList";
import IcApiLogSearch from "../pages/ICLogs/IcApiLogSearch";
import IcApiLogView from "../pages/ICLogs/IcApiLogView";
import IcApiMetricsSearch from "../pages/ICMetrics/IcApiMetricsSearch";
import ApdDetailsView from "../pages/apd/APD Form/apdApproval/ApdDetailsView";
import MasterPageForImportHistory from "../pages/masters/MasterPage/MasterPageForImportHistory";
import ClaimSpotIntimation from "../pages/Claim/forms/spotIntimation";
import ClaimIntimationn from "../pages/Claim/forms/claimIntimation";
const SwitchUser = lazy(() => import("../pages/switch-user"));
import ExceptionLogsSearch from "../pages/ICLogs/ExceptionLogsSearch";
import ExceptionLog from "../pages/ICLogs/ExceptionLogsSearch/components/ExceptionLog";
const SessionTimeout = lazy(() => import("../pages/SessionTimeout"));

const MasterPage = lazy(() => import("../pages/masters/MasterPage/MasterPage"));
const EndorsementDealerSearchForm = lazy(() =>
  import(
    "../pages/endorcement/EndorsementDealerSearch/EndorsementDealerSearchForm"
  )
);
const EndorsementDetailsIC = lazy(() =>
  import("../pages/endorcement/EndorsementApproval/EndorsementDetailsIC")
);
const EndorsementDetailsDealer = lazy(() =>
  import("../pages/endorcement/EndorsementApproval/EndorsementDetailsDealer")
);
const ReinstatementApproval = lazy(() =>
  import("../pages/IcApprovals/ReinstatementApproval")
);
const CustomersReport = lazy(() => import("../pages/Report/CustomersReport"));

const masterSlugs = {
  insurance: "Insurance",
  zone: "Zone",
  user: "User",
  menu: "Menu",
  pincode: "Pincode",
  rto: "RTO",
  bank: "Bank Record",
  dealer_group: "Dealer Group",
  state: "State",
  claim_type: "Claim Type",
  lso: "Policy LSO",
  claim_lso: "Claim LSO",
  city: "City",
  product: "Product",
  segment: "Segment",
  role: "Role",
  role_access: "Role Access",
  dealer: "Dealer",
  apd: "APD",
  addon: "Addons",
  broker: "Broker",
  oem: "OEM",
  surveyor: "Surveyor",
  template: "Template",
  finance: "Finance",
  manufacturer: "Manufacturer",
  motor_model: "Motor Model",
  motor_variants: "Motor Variants",
  motor_model_new: "New Motor Model",
  motor_variant_new: "New Motor Variant",
  mmv_request: "MMV Request",
  addon_configurator: "AddOn Configurator",
  odconfigurator: "OD Discount Configurator",
  policy: "Policy",
  ic_inspection: "IC Inspection",
  od_rate: "OD Rate",
  policy_cancellation_master: "Short Term Period",
  policy_wording: "Policy Wording",
  mobile: "Mobile Approval",
  addon_wording: "Addon Wording",
  tp_tariff_rate: "TP Tariff Rate",
  catastrophic: "catastrophic",
  oem_mmv_flag: "OEM MMV Flag",
  ifsc: "IFSC",
  policy_reinstate: "Policy Reinstate",
  ticker: "Ticker",
  dealer_role: "Dealer Role",
  dealer_role_access: "Dealer Role Access",
  body_type: "Body Type",
  fuel: "Fuel Type",
  campaign_management: "Campaign Management",
  pre_kyc_verification: "Pre KYC Verification",
  ic_credential: "IC Credentials",
  exception_log: "Exception Log",
};

export const policySlugs = {
  [PVT_CAR]: "Private Car",
  [TWO_WHEELER]: "Two Wheeler",
  [THREE_WHEELER]: "Three Wheeler",
  [PCV_C2]: "PCV (C2)",
  [PCV_C1]: "PCV (C1A)",
  [GCV_A1]: "GCV - Public Carrying",
  [GCV_A2]: "GCV - Private Carrying",
  [GCV_A3]: "GCV - Public Carrying",
  [GCV_A4]: "GCV - Private Carrying",
  [MISC_D]: "MISC - D",
};
export const routes = [
  {
    path: "/",
    errorElement: <Error />,

    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" />,
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        role: "read",
        slug: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "/master/:master_name/",
        slug: (params) => params?.master_name,
        name: (params) => `${masterSlugs[params?.master_name]} Master`,
        role: "read",

        children: [
          {
            index: true,
            element: <MasterPage />,
          },
          {
            path: "add/",
            name: (params) => `Add ${masterSlugs[params?.master_name]} Master`,
            role: "write",
            element: <MasterForm />,
          },
          {
            path: "update/:id",
            name: (params) =>
              `Update ${masterSlugs[params?.master_name]} Master`,
            role: "write",
            element: <Editable Component={MasterForm} />,
          },
          {
            path: "history/:id",
            name: (params) =>
              ` ${masterSlugs[params?.master_name]} Master History`,
            role: "read",
            element: <AuditLogForm />,
          },
          {
            path: "import-history",
            name: (params) =>
              ` ${masterSlugs[params?.master_name]} Master Import History`,
            role: "write",
            element: (
              <MasterPageForImportHistory master_name="import_history" />
            ),
          },
        ],
      },
      {
        path: "/log/http_logs/",
        name: "IC API log",
        role: "read",
        slug: "http_logs",
        children: [
          {
            index: true,
            element: <IcApiLogSearch />,
          },
          {
            name: "IC API log view",
            path: "add/:http_logs_id",
            role: "read",
            element: <IcApiLogView />,
          },
        ],
      },
      {
        path: "/log/http_metrics/",
        name: "IC API Metrics",
        role: "read",
        slug: "http_metrics",
        element: <IcApiMetricsSearch />,
      },
      {
        path: "/log/exception_log/",
        name: "Exception log",
        role: "read",
        slug: "exception_log",
        children: [
          {
            index: true,
            element: <ExceptionLogsSearch />,
          },
          {
            name: "Exception log view",
            path: "view/:exception_log_id",
            role: "read",
            element: <ExceptionLog />,
          },
        ],
      },
      {
        path: "/log/communication_log",
        name: "Communication Log",
        role: "read",
        slug: "communication_log",
        element: <MasterPage master_name="communication_log" />,
      },

      // ====================> Renewal Lead Routes Start <====================//

      // ====================> Renewal Lead Routes End <====================//

      // ====================> Configurator Routes Start <====================//
      {
        path: "/configurator/discount_od_configurator/",
        name: "Scheme Master",
        role: "read",
        slug: "discount_od_configurator",
        children: [
          {
            index: true,
            element: <DiscountConfigurator />,
          },
          {
            path: "add",
            name: "Add Scheme Master",
            role: "write",
            element: <ODDiscountConfigurator />,
          },
          {
            path: "update/:id",
            name: "Update Scheme Master",
            role: "write",
            element: (
              <Editable
                Component={ODDiscountConfigurator}
                slug="discount_od_configurator"
              />
            ),
          },
          {
            path: "history/:id",
            name: "Scheme Master History",
            role: "read",
            element: <AuditLogForm />,
          },
          {
            path: "import-history",
            name: `Scheme Master Import History`,
            role: "write",
            element: (
              <MasterPageForImportHistory
                master_name="import_history"
                slug="scheme_import_models"
              />
            ),
          },
        ],
      },
      {
        path: "/ic_configurator/surveyor_ic_mapping/",
        name: "IC Surveyor Mapping",
        role: "read",
        children: [
          {
            index: true,
            element: <ICSurveyorMapping />,
          },
          {
            name: "Select Categories",
            path: ":selected_surveyor",
            element: <MapICToSelectedSurveyor />,
          },
        ],
      },
      {
        path: "/ic_configurator/policy_lso",
        name: "Policy LSO",
        role: "read",
        children: [
          {
            index: true,
            element: <MasterPage master_name="policy_lso" />,
          },
          {
            path: "add",
            name: "Add Policy LSO",
            role: "write",
            element: <LSOForm />,
          },
          {
            path: "update/:id",
            name: "Update Policy LSO",
            role: "write",
            element: <Editable Component={LSOForm} slug={"policy_lso"} />,
          },
        ],
      },
      {
        path: "/ic_configurator/claim_lso",
        name: "Claim LSO",
        role: "read",
        children: [
          {
            index: true,
            element: <MasterPage master_name="claim_lso" />,
          },
          {
            path: "add",
            name: "Add Claim LSO",
            role: "write",
            element: <ClaimLSOForm />,
          },
          {
            path: "update/:id",
            name: "Update Claim LSO",
            role: "write",
            element: <Editable Component={ClaimLSOForm} slug={"claim_lso"} />,
          },
        ],
      },
      {
        path: "/ic_configurator/policy_wording",
        name: "Policy Wording",
        role: "read",
        children: [
          {
            index: true,
            element: <MasterPage master_name="policy_wording" />,
          },
          {
            path: "add",
            name: "Add ",
            role: "write",
            element: <PolicyWordings />,
          },
          {
            path: "update/:id",
            name: "Update Policy Wording",
            role: "write",
            element: (
              <Editable Component={PolicyWordings} slug={"policy_wording"} />
            ),
          },
        ],
      },
      {
        path: "/ic_configurator/addon_wording",
        name: "Addon Wording",
        role: "read",
        children: [
          {
            index: true,
            element: <MasterPage master_name="addon_wording" />,
          },
          {
            path: "add",
            name: "Add ",
            role: "write",
            element: <AddonWordings />,
          },
          {
            path: "update/:id",
            name: "Update Policy Wording",
            role: "write",
            element: (
              <Editable Component={AddonWordings} slug={"addon_wording"} />
            ),
          },
        ],
      },
      {
        slug: "ic_configurator",
        path: "/ic_configurator/ic_onboard/",
        name: "IC Configurator",
        role: "read",
        children: [
          {
            index: true,
            element: <ICConfigurator />,
          },
          {
            path: "add/",
            name: "Add IC Configurator",
            role: "write",
            element: <FormsICConfigurator />,
            children: [
              {
                index: true,
                element: <CompanyDetails />,
              },
              {
                path: "ic_inspection/:ic_slug/",
                name: "IC Inspection",
                role: "write",
                element: <Inspection />,
              },
              {
                path: "ic_api_mapping/:ic_slug/",
                name: "API Mapping",
                role: "write",
                element: <IcAPIMapping />,
              },
              {
                path: "ic_pa_cover/:ic_slug/",
                name: "CPA Cover",
                role: "write",
                element: <ICConfigPACover />,
              },
              {
                path: "policy_pdf_notes/:ic_slug/",
                name: "Policy PDF Notes",
                role: "write",
                element: <PolicyPDFNotes />,
              },
              {
                path: "ic_miscellaneous_mapping/:ic_slug/",
                name: "Miscellaneous",
                role: "write",
                element: <IcConfigMiscellaneousMappings />,
              },
              {
                path: "ic_premium_calculation/:ic_slug/",
                name: "Policy Premium Calculation",
                role: "write",
                element: <PolicyPremiumCalculations />,
              },
              {
                path: "claim_category_range/:ic_slug/",
                name: "Claim Category Range",
                role: "write",
                element: <ClaimCategoryRange />,
              },
            ],
          },
          {
            path: "update/:id/",
            name: "Update IC Configurator",
            role: "write",
            element: <FormsICConfigurator />,
            children: [
              {
                index: true,
                element: (
                  <Editable
                    Component={CompanyDetails}
                    slug="insurance"
                    isForIcConfig={true}
                  />
                ),
              },
              {
                path: "ic_api_mapping",
                name: "API Mapping",
                role: "write",
                element: (
                  <Editable
                    Component={IcAPIMapping}
                    slug="ic_api_mapping"
                    isForIcConfig={true}
                  />
                ),
              },
              {
                path: "ic_inspection",
                name: "IC Inspection",
                role: "write",
                element: (
                  <Editable
                    Component={Inspection}
                    slug="ic_inspection"
                    isForIcConfig={true}
                  />
                ),
              },
              {
                path: "ic_pa_cover",
                name: "CPA Cover",
                role: "write",
                element: (
                  <Editable
                    Component={ICConfigPACover}
                    slug="ic_pa_cover"
                    isForIcConfig={true}
                  />
                ),
              },
              {
                path: "policy_pdf_notes",
                name: "Policy PDF Notes",
                role: "write",
                element: (
                  <Editable
                    Component={PolicyPDFNotes}
                    slug="policy_pdf_notes"
                    isForIcConfig={true}
                  />
                ),
              },
              {
                path: "ic_premium_calculation",
                name: "Policy Premium Calculation",
                role: "write",
                element: (
                  <Editable
                    Component={PolicyPremiumCalculations}
                    slug="ic_premium_calculation"
                    isForIcConfig={true}
                  />
                ),
              },
              {
                path: "ic_miscellaneous_mapping",
                name: "Miscellaneous",
                role: "write",
                element: (
                  <Editable
                    Component={IcConfigMiscellaneousMappings}
                    slug="ic_miscellaneous_mapping"
                    isForIcConfig={true}
                  />
                ),
              },
              {
                path: "claim_category_range",
                name: "Claim Category Range",
                role: "write",
                element: (
                  <Editable
                    Component={ClaimCategoryRange}
                    slug="claim_category_range"
                    isForIcConfig={true}
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        slug: "broker_ic_onboard",
        path: "/ic_configurator/broker_ic_onboard/",
        name: "IC Configurator",
        role: "read",
        children: [
          {
            index: true,
            element: <ICConfigurator isBrokerLogin />,
          },
          {
            path: "update/:id/",
            name: "Update IC Configurator",
            role: "write",
            element: <FormsICConfigurator />,
            children: [
              {
                index: true,
                element: (
                  <Editable
                    Component={CompanyDetails}
                    slug="insurance"
                    isForIcConfig
                    isBrokerLogin
                  />
                ),
              },
              {
                path: "ic_api_mapping",
                name: "API Mapping",
                role: "write",
                element: (
                  <Editable
                    Component={IcAPIMapping}
                    slug="ic_api_mapping"
                    isForIcConfig={true}
                    isBrokerLogin
                  />
                ),
              },
              {
                path: "ic_inspection",
                name: "IC Inspection",
                role: "write",
                element: (
                  <Editable
                    Component={Inspection}
                    slug="ic_inspection"
                    isForIcConfig={true}
                    isBrokerLogin
                  />
                ),
              },
              {
                path: "ic_pa_cover",
                name: "CPA Cover",
                role: "write",
                element: (
                  <Editable
                    Component={ICConfigPACover}
                    slug="ic_pa_cover"
                    isForIcConfig={true}
                    isBrokerLogin
                  />
                ),
              },
              {
                path: "policy_pdf_notes",
                name: "Policy PDF Notes",
                role: "write",
                element: (
                  <Editable
                    Component={PolicyPDFNotes}
                    slug="policy_pdf_notes"
                    isForIcConfig={true}
                    isBrokerLogin
                  />
                ),
              },
              {
                path: "ic_premium_calculation",
                name: "Policy Premium Calculation",
                role: "write",
                element: (
                  <Editable
                    Component={PolicyPremiumCalculations}
                    slug="ic_premium_calculation"
                    isForIcConfig={true}
                    isBrokerLogin
                  />
                ),
              },
              {
                path: "ic_miscellaneous_mapping",
                name: "Miscellaneous",
                role: "write",
                element: (
                  <Editable
                    Component={IcConfigMiscellaneousMappings}
                    slug="ic_miscellaneous_mapping"
                    isForIcConfig={true}
                    isBrokerLogin
                  />
                ),
              },
              {
                path: "claim_category_range",
                name: "Claim Category Range",
                role: "write",
                element: (
                  <Editable
                    Component={ClaimCategoryRange}
                    slug="claim_category_range"
                    isForIcConfig={true}
                    isBrokerLogin
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        slug: "addon_configurator",
        path: "/configurator/addon_configurator/",
        name: "Addon Configurator",
        role: "read",
        children: [
          {
            index: true,
            element: <AddOnConfigurator />,
          },
          {
            path: "add",
            name: "Add Addon Configurator",
            role: "write",
            element: <AddOnConfig />,
          },
          {
            path: "update/:id",
            name: "Update Addon Configurator",
            role: "write",
            element: (
              <Editable Component={AddOnConfig} slug="addon_configurator" />
            ),
          },
          {
            path: "history/:id",
            name: "Addon Configurator History",
            role: "read",
            element: <AuditLogForm />,
          },
          {
            path: "import-history",
            name: `Addon Configurator Import History`,
            role: "write",
            element: (
              <MasterPageForImportHistory
                master_name="import_history"
                slug="addon_configurator"
              />
            ),
          },
        ],
      },
      {
        path: "/configurator/report_configurator/",
        slug: "report_configurator",
        name: "Report Configurator",
        role: "read",
        children: [
          {
            index: true,
            element: <ReportConfiguratorList />,
          },
          {
            path: "add",
            name: "Add Report Configurator",
            role: "write",
            element: <ReportConfigurator />,
          },
          {
            path: "update/:id",
            name: "Update Report Configurator",
            role: "write",
            element: (
              <Editable
                Component={ReportConfigurator}
                slug={"report_configurator"}
              />
            ),
          },
          {
            path: "history/:id",
            name: "Report Configurator History",
            role: "read",
            element: <AuditLogForm />,
          },
        ],
      },
      {
        path: "/ic_configurator/ic_dealer_mapping",
        name: "IC - Dealer Mapping",
        role: "read",
        slug: "ic_dealer_mapping",
        element: <IcDealerMappingSearch />,
      },
      {
        path: "/ic_configurator/lso_dealer_mapping",
        name: "LSO Dealer Mapping",
        role: "read",
        slug: "lso_dealer_mapping",
        element: <LSODealerMappingSearch />,
      },
      {
        path: "/configurator/imt_configurator/",
        name: "IMT Rate Configurator",
        role: "read",
        slug: "imt_configurator",
        children: [
          {
            index: true,
            element: <MasterPage master_name="imt_configurator" />,
          },
          {
            path: "add",
            name: "Add IMT Rate Configurator",
            role: "write",
            element: <IMTConfigurator />,
          },
          {
            path: "update/:id",
            name: "Update IMT Rate Configurator",
            role: "write",
            element: (
              <Editable Component={IMTConfigurator} slug="imt_configurator" />
            ),
          },
        ],
      },
      {
        path: "/ic_configurator/ic_broker_mapping",
        name: "IC - Broker Mapping",
        role: "read",
        element: <ICBrokerMapping type={"ic_broker"} />,
      },

      {
        path: "/ic_configurator/ic_oem_mapping",
        name: "IC - OEM Mapping",
        role: "read",
        element: <ICBrokerMapping type={"ic_oem"} />,
      },
      // ====================> Configurator Routes End <====================//
      // ====================> Reports Routes Start <====================//
      {
        path: "/reports/view_report",
        name: "View Reports",
        role: "read",
        slug: "view_report",
        element: <ViewReport />,
      },
      {
        path: "/reports/booking_report",
        name: "Booking Reports",
        role: "read",
        slug: "booking_report",
        element: <BookingReport />,
      },
      {
        path: "/reports/billing_report",
        name: "Billing Reports",
        role: "read",
        slug: "billing_report",
        element: <BillingReport />,
      },
      {
        path: "/reports/customers",
        name: "Customers",
        role: "read",
        slug: "customers",
        element: <CustomersReport />,
      },
      {
        path: "/invoicing/dealer_invoicing",
        name: "Dealer Reports",
        role: "read",
        slug: "dealer_invoicing",
        element: <DealerReport />,
      },
      {
        path: "/invoicing/ic_invoicing",
        name: "IC Reports",
        role: "read",
        slug: "ic_invoicing",
        element: <IcReport />,
      },

      // ====================> Reports Routes End <====================//
      // ====================> Policy Routes Start <====================//
      {
        path: "/policy/:segment_slug/",
        name: (params) => policySlugs[params?.segment_slug?.toLowerCase()],
        role: "read",
        slug: (params) => params?.segment_slug?.toLowerCase(),
        children: [
          {
            index: true,
            element: <PolicyInputForm />,
          },
          {
            path: "quotes",
            name: "Quotes",
            role: "read",
            element: <Quotepage />,
          },
          {
            path: "proposal",
            name: "Proposal",
            role: "read",
            element: <Proposal />,
          },
          {
            path: "summary",
            name: "Proposal Summary",
            role: "read",
            element: <Summary />,
          },
          {
            path: "compare-quotes",
            name: "Compare Quotes",
            role: "read",
            element: <CompareQuotes />,
          },
          {
            path: "thank-you/:pg_transaction_id",
            name: "Thank You",
            role: "read",
            element: <PaymentSuccess />,
          },
        ],
      },
      // route for multiple proposal single payment
      {
        path: "/policy/thank-you/:pg_transaction_id",
        name: "",
        role: "read",
        element: <PaymentSuccess />,
      },
      // ====================> Policy Routes End <====================//

      //====================> Policy Support Routes Start <====================//
      {
        path: "/policy_support/pay_in_slip/",
        name: "Pay In Slip",
        role: "read",
        slug: "pay_in_slip",
        children: [
          {
            index: true,
            element: <PayInSlipSearchForm />,
          },
          {
            path: "add/:pis_number",
            name: "Add Pay In Slip",
            role: "write",
            element: <PayInSlipSearchForm />,
          },
        ],
      },
      {
        path: "/policy_support/proposal_listing",
        name: "Proposal Listing",
        role: "read",
        element: <ProposalListing />,
        slug: "proposal_listing",
      },
      {
        path: "/policy_support/policy_listing",
        name: "Policy Listing",
        role: "read",
        element: <PolicyListing />,
        slug: "policy_listing",
      },
      {
        path: "/policy_support/drafted_proposal_listing",
        name: "Drafted Proposal Listing",
        role: "read",
        slug: "drafted_proposal_listing",
        element: <DraftedProposalListing />,
      },
      {
        path: "/policy_support/quote_listing",
        name: "Quote Listing",
        slug: "quote_listing",
        role: "read",
        element: <QuoteListing />,
      },
      {
        path: "/policy_support/policy_renewal",
        name: "Policy Renewal",
        role: "read",
        slug: "policy_renewal",
        element: <PolicyRenewalListing />,
      },
      {
        path: "/policy_support/endorsement_listing/",
        name: "Endorsement Listing",
        role: "read",
        slug: "endorsement_listing",
        children: [
          {
            index: true,
            element: <EndorsementDealerSearchForm />,
          },
          {
            path: "add/:policy_endorsement_id",
            name: "Add Endorsement",
            role: "write",
            element: <EndorsementDetailsDealer />,
          },
        ],
      },

      {
        path: "/policy_support/lead",
        name: "Renewal Lead",
        role: "read",
        slug: "lead",
        children: [
          {
            index: true,
            element: <RenewalLeadSearch />,
          },
        ],
      },
      {
        path: "policy_support/policy_reinstate",
        name: "policy Reinstate Listing",
        role: "read",
        slug: "policy_reinstate",
        children: [
          {
            index: true,
            element: <PolicyReinstateListing />,
          },
        ],
      },
      {
        path: "policy_support/ic_approval_pending",
        name: "IC Approval Status",
        role: "read",
        slug: "ic_approval_pending",
        children: [
          {
            index: true,
            element: <IcApprovalStatusListing />,
          },
        ],
      },
      //====================> Policy Support Routes End <====================//
      //====================> Claim  Routes Start <====================//
      {
        path: "/claims/claim_notification/",
        name: "Claim Intimation",
        role: "read",
        slug: "claim_notification",
        children: [
          {
            index: true,
            element: <SpotIntimationSearchForm />,
          },
          {
            path: "add/:booking_id",
            name: "Add Claim Intimation",
            slug: "claim_notification",
            role: "write",
            element: <ClaimSpotIntimation />,
          },
        ],
      },
      {
        path: "/claims/claim_registration/",
        name: "Claim Registration",
        role: "read",
        slug: "claim_registration",
        children: [
          {
            index: true,
            element: <ClaimIntimationSearchForm />,
          },
          // route for claim intimation when spot intimated by call center
          {
            path: "add/:claim_detail_id/:booking_id",
            name: "Add Claim Registration",
            role: "write",
            element: <ClaimIntimationn />,
          },
          // route for claim Registration when dealer is directly raising claim Registration (without spot Registration)
          {
            path: "add/:booking_id",
            name: "Add Claim Registration",
            role: "write",
            element: <ClaimIntimationn />,
          },
        ],
      },
      {
        path: "/claims/surveyor_appointment/",
        name: "Surveyor Appointment",
        role: "read",
        slug: "surveyor_appointment",
        children: [
          {
            index: true,
            element: <SearchSurveyorAppointmentForm />,
          },
          {
            path: "add/:claim_detail_id/:booking_id",
            name: "Add Surveyor Appointment",
            role: "write",
            element: <ClaimSurveyorAppointment />,
          },
        ],
      },
      {
        path: "/claims/claim_details/",
        name: "Claim Details",
        role: "read",
        slug: "claim_details",
        children: [
          {
            index: true,
            element: <ClaimDetailsSearch type="details" />,
          },
          {
            path: "add/:booking_id/:claim_detail_id",
            name: "View Claim Details",
            role: "read",
            element: <ClaimDetailsView />,
          },
        ],
      },
      {
        path: "/claims/claim_details/import-history",
        name: "Claim Details Import History",
        role: "write",
        element: (
          <MasterPageForImportHistory
            master_name="import_history"
            slug="claim_details"
          />
        ),
      },
      {
        path: "/claims/proforma_invoice/",
        name: "Proforma Invoice",
        role: "read",
        slug: "proforma_invoice",
        children: [
          {
            index: true,
            element: <ClaimDetailsSearch type="proforma_invoice" />,
          },
          {
            path: "add/:booking_id/:claim_detail_id",
            name: "View Proforma Invoice",
            role: "read",
            element: <ClaimDetailsView />,
          },
        ],
      },
      {
        path: "/claims/delivery_order/",
        name: "Delivery Order",
        role: "read",
        slug: "delivery_order",
        children: [
          {
            index: true,
            element: <ClaimDetailsSearch type="delivery_order" />,
          },
          {
            path: "add/:booking_id/:claim_detail_id",
            name: "View Delivery Order",
            role: "read",
            element: <ClaimDetailsView />,
          },
        ],
      },
      {
        path: "/claims/claim_invoice/",
        name: "Claim Invoice",
        role: "read",
        slug: "claim_invoice",
        children: [
          {
            index: true,
            element: <ClaimDetailsSearch type="invoice" />,
          },
          {
            path: "add/:booking_id/:claim_detail_id",
            name: "View Claim Invoice",
            role: "read",
            element: <ClaimDetailsView />,
          },
        ],
      },
      {
        path: "/claims/surveyor_assessment/",
        name: "Surveyor Assessment",
        role: "read",
        slug: "surveyor_assessment",
        children: [
          {
            index: true,
            element: <ClaimDetailsSearch type="surveyor_assessment" />,
          },
          {
            path: "add/:booking_id/:claim_detail_id",
            name: "View Surveyor Assessment",
            role: "read",
            element: <ClaimDetailsView />,
          },
        ],
      },
      {
        path: "/claims/claim_approval/",
        name: "Claim Approval",
        role: "read",
        slug: "claim_approval",
        children: [
          {
            index: true,
            element: <ClaimDetailsSearch type="approval" />,
          },
          {
            path: "add/:booking_id/:claim_detail_id",
            name: "View Claim Approval",
            role: "read",
            element: <ClaimDetailsView />,
          },
        ],
      },
      {
        path: "/claims/claim_settlement/",
        name: "Claim Settlement",
        role: "read",
        slug: "claim_settlement",
        children: [
          {
            index: true,
            element: <ClaimDetailsSearch type="settlement" />,
          },
          {
            path: "add/:booking_id/:claim_detail_id",
            name: "View Claim Settlement",
            role: "read",
            element: <ClaimDetailsView />,
          },
        ],
      },
      {
        path: "/claims/claim_withdraw",
        name: "Claim Withdraw",
        role: "read",
        slug: "claim_withdraw",
        element: <ClaimDetailsSearch type="withdraw" />,
      },
      {
        path: "/claims/claim_document/",
        name: "Claim Document",
        role: "read",
        slug: "claim_document",
        children: [
          {
            index: true,
            element: <ClaimDetailsSearch type="document" />,
          },
          {
            path: "add/:booking_id/:claim_detail_id",
            name: "Upload Claim Document",
            role: "read",
            element: <ClaimCustomerDocumentUpload />,
          },
        ],
      },
      //====================> Claim  Routes End <====================//
      //====================> Servicing  Routes Start <====================//
      {
        path: "/servicing/endorsement/",
        name: "Endorsement",
        slug: "endorsement",
        role: "read",
        children: [
          {
            index: true,
            element: <Endorsement />,
          },
          {
            path: "add/:booking_id",
            name: "Add Endorsement",
            role: "write",
            element: <EndorsementType />,
          },
        ],
      },
      {
        path: "/servicing/apd_list",
        slug: "apd_list",
        name: "APD Statement",
        role: "read",
        element: <ApdSearch />,
      },
      {
        slug: "policy_cancellation",
        path: "/cancellation/policy_cancellation/",
        name: "Policy Cancellation",
        role: "read",
        children: [
          {
            index: true,
            element: (
              <CancellationSearchForm
                type={"policy"}
                slug="policy_cancellation"
              />
            ),
          },
          {
            path: "add/:booking_id",
            name: "Add Policy Cancellation",
            role: "write",
            element: <CancellationAdd type="policy" />,
          },
          {
            path: "history/:booking_id",
            name: "Policy Cancellation History",
            role: "read",
            element: <AuditLogForm />,
          },
        ],
      },
      {
        slug: "proposal_cancellation",
        path: "/cancellation/proposal_cancellation/",
        name: "Proposal Cancellation",
        role: "read",
        children: [
          {
            index: true,
            element: <ProposalCancellationSearch />,
          },
          {
            path: "summary/:segment_slug",
            name: "Add Proposal Cancellation",
            role: "write",
            element: <Summary type="proposal_cancellation" />,
          },
        ],
      },
      {
        slug: "policy_cancellation_approval",
        path: "/cancellation/policy_cancellation_approval/",
        name: "Policy Cancellation Approval",
        role: "read",
        children: [
          {
            index: true,
            element: (
              <CancellationSearchForm
                login={"ic_login"}
                type={"policy"}
                slug={"policy_cancellation_approval"}
              />
            ),
          },
          {
            path: "add/:booking_id",
            name: "Add Cancellation Approval",
            role: "write",
            element: <CancellationApprovalDetails type={"policy"} />,
          },
          {
            path: "history/:booking_id",
            name: "Policy Cancellation Approval History",
            role: "read",
            element: <AuditLogForm />,
          },
        ],
      },

      {
        path: "/cancellation/endorsement_cancellation/",
        name: "Endorsement Cancellation",
        role: "read",
        slug: "endorsement_cancellation",
        children: [
          {
            index: true,
            element: <EndorsementApprovalSearch type={"cancellation"} />,
          },
          {
            path: "add/:policy_endorsement_id",
            name: "Add Endorsement Cancellation",
            role: "write",
            element: <EndorsementDetailsIC type={"cancellation"} />,
          },
          {
            path: "history/:policy_endorsement_id",
            name: "Endorsement Cancellation History",
            role: "read",
            element: <AuditLogForm />,
          },
        ],
      },
      {
        path: "/cancellation/policy_cancellation_refund/",
        name: "Policy Cancellation Refund",
        role: "read",
        slug: "policy_cancellation_refund",
        children: [
          {
            index: true,
            element: <SearchCancellationRefund />,
          },
          {
            path: "add/:booking_id",
            name: "Add Cancellation Refund",
            role: "write",
            element: <CancellationRefund />,
          },
        ],
      },

      {
        path: "/servicing/vb",
        name: "64 VB",
        role: "read",
        slug: "vb",
        element: <VB64 />,
      },
      {
        path: "/servicing/vb/history/:booking_id",
        name: "64 VB History",
        role: "read",
        element: <AuditLogForm />,
      },
      {
        slug: "policy_reinstatement",
        path: "/servicing/policy_reinstatement",
        name: "Policy Reinstatement Listing",
        role: "read",
        element: <PolicyReinstatement />,
      },
      {
        slug: "mobile_request",
        path: "/servicing/mobile_request",
        name: "Mobile Number Request",
        role: "read",
        children: [
          {
            index: true,
            element: <MobileNoApproval />,
          },
          {
            path: "add",
            name: "Add Mobile Number Request",
            role: "write",
            element: <AddMobileNoApproval />,
          },
        ],
      },
      {
        path: "/servicing/customer_mandate_form/",
        name: "Customer Mandate Form",
        slug: "customer_mandate_form",
        role: "read",
        children: [
          {
            index: true,
            element: <DealerMandateSearch />,
          },
          {
            path: "add",
            name: "Add",
            role: "write",
            element: <OtpMandateForm />,
          },
          {
            path: "update/:dealer_mandate_id_in_url",
            name: "Update",
            role: "write",
            element: <OtpMandateForm />,
          },
        ],
      },
      {
        slug: "broker_mobile_request",
        path: "/servicing/broker_mobile_request",
        name: "Broker Mobile request",
        role: "read",
        children: [
          {
            index: true,
            element: <BrokerMobileRequest />,
          },
          {
            path: "add",
            name: "Add Broker Mobile request",
            role: "write",
            element: <AddBrokerMobileRequest />,
          },
          {
            // path: "update/:dealer_mandate_id_in_url",
            name: "Update",
            role: "write",
            // element: <OtpMandateForm />,
          },
        ],
        // element: <BrokerMobileRequest />,
      },
      //====================> Servicing  Routes End <====================//
      //====================> Miscelleneous  Routes Start <====================//
      {
        path: "/session_timeout",
        name: "Switch User",
        role: "read",
        element: <SessionTimeout />,
      },
      {
        path: "/login/",
        name: "Login",
        role: "read",
        children: [
          {
            index: true,
            element: <NewLogin step={1} />,
          },
          {
            path: ":slug",
            element: <NewLogin step={2} />,
          },
        ],
      },
      {
        path: "/switch_user",
        name: "Switch User",
        role: "read",
        element: <SwitchUser />,
      },
      {
        path: "/payin-slip-print/:pis_number",
        name: "Pay In Slip Print",
        role: "read",
        element: <PayInSlipPrintPreview />,
      },
      {
        path: "/reset_password",
        name: "Reset Password",
        role: "read",
        element: <ResetPassword />,
      },
      {
        path: "/forgot_password",
        name: "Forgot Password",
        role: "read",
        element: <ResetPassword type="forgot_password" />,
      },
      {
        path: "/reset_password/:token",
        name: "Reset Password",
        role: "read",
        element: <ResetPassword type="create_password" />,
      },
      {
        path: "/feed_search",
        name: "Feed Search",
        role: "read",
        element: <FeedSearch />,
      },
      {
        path: "/self_inspection",
        name: "Self Inspection",
        role: "read",
        element: <SelfInspectionHeader />,
      },
      {
        path: "/capture",
        name: "Capture",
        role: "read",
        element: <SelfInspection />,
      },
      {
        path: "/payment_error",
        name: "Payment error",
        role: "read",
        element: <PaymentError />,
      },
      {
        path: "approval/mobile_approval/history/:mobile_number_id",
        name: "Mobile Approval History",
        role: "read",
        element: <AuditLogForm />,
      },
      {
        path: "/policy_bulk_upload",
        name: "Policy Bulk Upload",
        role: "read",
        element: <BulkUpload />,
      },
      //====================> Miscelleneous  Routes End <====================//
      //====================> Approval  Routes Start <====================//
      {
        path: "/approval/apd_approval",
        name: "APD Approval",
        role: "read",
        slug: "apd_approval",
        children: [
          {
            index: true,
            element: <ApdListing />,
          },
          {
            path: "add/:apd_id",
            name: "View Claim Details",
            role: "read",
            element: <ApdDetailsView />,
          },
        ],
      },
      {
        path: "/approval/apd_approval/history/:apd_id",
        name: "APD Approval History",
        role: "read",
        element: <AuditLogForm />,
      },
      {
        path: "/approval/endorsement_approval/",
        name: "Endorsement Approval",
        role: "read",
        slug: "endorsement_approval",
        children: [
          {
            index: true,
            element: <EndorsementApprovalSearch />,
          },
          {
            path: "add/:policy_endorsement_id",
            name: "Add Endorsement Approval",
            role: "write",
            element: <EndorsementDetailsIC />,
          },
          {
            path: "history/:policy_endorsement_id",
            name: "Endorsement Approval History",
            role: "read",
            element: <AuditLogForm />,
          },
        ],
      },
      {
        path: "/approval/proposal_approval/",
        name: "Proposal Approval",
        role: "read",
        slug: "proposal_approval",
        children: [
          {
            index: true,
            element: <ProposalApproval />,
          },
          {
            path: "add/:proposal_id",
            name: "Add Proposal Approval",
            role: "write",
            element: <ProposalApprovalSummary />,
          },
          {
            path: "history/:proposal_id",
            name: "Proposal Approval History",
            role: "read",
            element: <AuditLogForm />,
          },
        ],
      },
      {
        path: "/approval/discount_approval/",
        name: "Discount Approval",
        role: "read",
        slug: "discount_approval",
        element: <DiscountApprovalSearch />,
      },
      {
        path: "/approval/discount_approval/history/:ic_request_discount_id",
        name: "Discount Approval History",
        role: "read",
        slug: "discount_approval",
        element: <AuditLogForm />,
      },
      {
        slug: "rm_mobile_approval",
        path: "/approval/rm_mobile_approval",
        name: "Mobile Approval",
        role: "read",
        element: <RmMobileApproval />,
      },
      {
        path: "/approval/reinstatement_approval",
        name: "Policy Reinstatement Approval",
        role: "read",
        slug: "reinstatement_approval",
        children: [
          {
            index: true,
            element: <ReinstatementApproval />,
          },
          {
            path: "add/:reinstatement_approval_id",
            name: "Policy Reinstatement Approval",
            role: "read",
            element: <ApdDetailsView />,
          },
        ],
      },
    ],
  },

  //====================> Approval  Routes End <====================//

  //====================> Bulk Import  Routes Start <====================//
  {
    path: "/bulk_import/quotes_import",
    name: "Bulk Import Quotes",
    role: "read",
    slug: "quotes_import",
    element: <BulkImportQuotes />,
  },
  //====================> Bulk Import  Routes End <====================//

  //====================> Temp <====================//
  {
    path: "/ckyc",
    name: "CKYC",
    role: "read",
    slug: "ckyc",
    element: <CkycRedirection />,
  },

  //====================> Temp <====================//
  {
    path: "*",
    name: "404",
    role: "read",
    element: <NotFound />,
  },
];

// write a code to find the path from root nodes to the current node using backtracking
export const findPathToCurrentNode = (
  routes,
  targetPath,
  currentPath = "",
  pathToRoute = []
) => {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    const match = matchPath({ path: currentPath + route.path }, targetPath);
    const paramMatch = matchPath(
      { path: currentPath + route.path, end: false },
      targetPath
    );
    if (match) {
      pathToRoute.push({
        ...route,
        name:
          typeof route.name === "function"
            ? route.name(paramMatch?.params)
            : route.name,
        slug:
          typeof route.slug === "function"
            ? route.slug(paramMatch?.params)
            : route.slug,
        resolvedPath: paramMatch?.pathnameBase,
      });
      return pathToRoute;
    } else if (route.children) {
      pathToRoute.push({
        ...route,
        slug:
          typeof route.slug === "function"
            ? route.slug(paramMatch?.params)
            : route.slug,
        name:
          typeof route.name === "function"
            ? route.name(paramMatch?.params)
            : route.name,
        resolvedPath: paramMatch?.pathnameBase,
      });
      const foundPath = findPathToCurrentNode(
        route.children,
        targetPath,
        currentPath + route.path,
        pathToRoute
      );

      if (foundPath) {
        return foundPath;
      }
      pathToRoute.pop();
    }
  }
};
