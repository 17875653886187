// Note: Regex constants
// Some of these regex constants also accept empty string as valid input
// Need to make sure that we use it with yup.string().required()
// when we want to make it mandatory dont remove ^$ from regex

export const REGEX = {
  PAN: /^[A-Z]{3}[PFCHAT]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$|^$/,
  MOBILE: /^[6-9]\d{9}$|^$/,
  NAME: /^(\w+\s+\w+)$|^$/,
  FULL_NAME: /\w+\s\w*?\w+|^$/,
  FIRST_MIDDLE_LAST_NAME: /^([A-Za-z]+\s+[A-Za-z]+(\s+[A-Za-z]+)?)$/,
  FIRST_MIDDLE_LAST_NAME_STRICT: /^(\w+\s+\w+\s+\w+)$/,
  PINCODE: /^[1-9]{1}[0-9]{5}$|^$/,
  DRIVING_LICENSE: /^([A-Z]{2}[A-Z0-9_\s-]{0,18})?$/,
  AADHAR: /^[2-9]{1}[0-9]{11}$|^$/,
  CHASSIS: /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]{17,25}$|^$/,
  ENGINE: /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]{17,25}$|^$/,
  GSTIN:
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}$|^$/,
  // if only capital letters are allowed,
  //use with allowOnlyCapitalizeAlphaNumeric
  ALPHANUMERIC: /^[a-zA-Z0-9]*$|^$/,
  INCLUDE_ALPHABET: /.*[a-zA-Z].*/,
  CIN: /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$|^$/,
  // used for scheme_name where we need characters after special characters
  ENDS_IN_ALPHABET_INCLUDES_SPECIAL_CHARACTERS:
    /^(?=.*[a-zA-Z])[A-Za-z0-9_%!@()\s]$|^$/,
  // must include at least one alphabet and one number
  INCLUDE_ALPHABET_AND_NUMBER: /^(?=.*[a-zA-Z])(?=.*[0-9])$|^$/,
  IFSC_CODE: /^[A-Z]{4}0[A-Z0-9]{6}$|^$/,
  PUC_CERTIFICATE_NO: /^([a-zA-Z]{2}[0-9]{14})+$|^$/,
  EMAIL:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$|^$/,
  REGISTRATION_NO: /^[A-Z]{2}-\d{1,2}-([A-Z]{1,3}-)?\d{4}$|^$/,
  MILITARY_VEHICLE: /^[0-9]{2}-A|B|C|D|E|X-[0-9]{6}-[A-Z]{1}$|^$/,
  BH_SERIES_NO: /^\d{2}BH-[0-9]{4}-[A-Z]{2}$|^$/,
  LANDLINE_NO: /^\d{5}([- ]*)\d{6}$|^$/,
};
