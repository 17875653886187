import { Autocomplete, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  CrossIconButton,
  InputLabel,
  TextField,
} from "./InputComponents.style";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { reduceObj } from "../../utils/errorHandler";
import { DropdownImages } from "../../constants/DropdownImages";
function filterOptions(options, { inputValue }) {
  const normalizedInput = inputValue
    ?.toLowerCase()
    .replace(/[^a-zA-Z0-9-\s]/g, "");

  return options.filter((option) => {
    const normalizedOption = option?.display_name
      ?.toLowerCase()
      .replace(/[^a-zA-Z0-9-\s]/g, "");

    return normalizedOption.includes(normalizedInput);
  });
}
const Select = ({
  name,
  options = [],
  label,
  placeholder,
  required,
  readOnly,
  saveDisplayName = true,
  isLoading,
  removeClearOption,
  removeBorders = false,
  bordered,
  showImages,
  handleChange,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useFormContext();

  const value = watch(name);
  const reducedErrors = reduceObj(errors, name);

  // checking form value present in options or not
  const isValuePresentInOption = options?.some((option) => {
    return `${option?.code}` === `${value}`;
  });

  useEffect(() => {
    if (value && !isValuePresentInOption && options?.length > 0) {
      // Here, value is present in form state but, not available in options
      // Thus, resetting value
      setValue(name, "");
    }
  }, [isValuePresentInOption, value]);

  useEffect(() => {
    if (options.length === 1 && required) {
      setValue(name, options[0].code);
      trigger(name);
    }
  }, [JSON.stringify(options)]);

  const selectedOption = useMemo(() => {
    const foundOption = options.find((item) => item.code == value) ?? null;
    if (saveDisplayName && foundOption)
      setValue(`${name}_data`, foundOption?.display_name);
    return foundOption;
  }, [JSON.stringify(options), value]);

  return (
    <>
      <Box width="100%">
        {label && (
          <InputLabel title={label} required={required} bordered={bordered}>
            {label}
          </InputLabel>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, ref, ...field } }) => (
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option?.display_name ?? ""}
              getOptionSelected={(option, value) => option.code === value.code}
              filterOptions={filterOptions}
              loading={isLoading}
              value={selectedOption}
              id={name}
              onChange={(_, data) => {
                onChange(data.code);
                handleChange && handleChange(data.code);
              }}
              readOnly={readOnly}
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
              disableClearable
              loadingText="Loading..."
              sx={{ pointerEvents: readOnly && "none" }}
              renderOption={(props, option) => {
                return (
                  <Box
                    component="li"
                    sx={{
                      "& > img": { mr: 2, flexShrink: 0 },
                      fontSize: "11px",
                      "&.MuiAutocomplete-option": {
                        minHeight: "5px",
                        padding: { xs: "5px 16px" },
                      },
                    }}
                    {...props}
                  >
                    {showImages && (
                      <img
                        loading="lazy"
                        width="18"
                        srcSet={option?.image || DropdownImages[option?.code]}
                        src={option?.image || DropdownImages[option?.code]}
                      />
                    )}
                    {option.display_name}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  removeBorders={removeBorders}
                  bordered={bordered}
                  error={!!reducedErrors}
                  helperText={reducedErrors?.message}
                  inputRef={ref}
                  readOnly={readOnly}
                  {...field}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {(value || value === 0) &&
                          !readOnly &&
                          !removeClearOption &&
                          options?.length > 1 && (
                            <CrossIconButton onClick={() => setValue(name, "")}>
                              <ClearRoundedIcon sx={{ fontSize: "15px" }} />
                            </CrossIconButton>
                          )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        />
      </Box>
    </>
  );
};

export default Select;
