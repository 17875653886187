import { styled } from "@mui/material/styles";
import { Grid, Paper, Typography, Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Heading } from "../masters/components/MasterStyle";

export const SummaryWrapper = styled(Box)({
  borderRadius: "10px",
  backgroundColor: "white",
});

export const SummaryHeadContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
});

export const SummaryTitle = styled(Heading)({
  fontSize: "14px",
  height: "30px",
});

export const SummaryHeading = styled(Typography)({
  color: "#6e8185",
  fontSize: "13px",
  fontWeight: "bold",
  display: "flex",
  flex: 1,
  margin: "0 5px",
});

export const SummarySubHeading = styled(Typography)({
  color: "#6e8185",
  fontSize: "13px",
  wordBreak: "break-all",
  display: "flex",
  flex: 1,
  margin: "0 5px",
});

export const Declaration = styled(Typography)({
  color: "#6e8185",
  fontSize: "13px",
  margin: "15px 15px 3px 15px",
  fontWeight: "bold",
});

export const DeclarationText = styled(Typography)({
  color: "#6e8185",
  fontSize: "13px",
  margin: "0 15px 10px 15px",
  textAlign: "justify",
});

const SummaryChild = styled(Grid)(() => ({}));

SummaryChild.defaultProps = {
  ...SummaryChild.defaultProps,
  xs: 12,
  md: 4,
  item: true,
  marginBottom: "5px",
};

export { SummaryChild };

export const SummaryContainer = styled(Paper)(({ theme }) => ({
  padding: "0.8rem",
  width: "98%",
  margin: "0 0 15px 15px",
  borderRadius: "1rem",
  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
  [theme.breakpoints.down("md")]: {
    margin: "0 0 15px 0",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "0",
    width: "100%",
    margin: "0",
    borderRadius: "none",
    boxShadow: "none",
  },
}));

export const SummarySubContainer = styled(Box)({
  width: "100%",
  position: "relative",
  padding: "0 10px",
  gap: "10px",
});

export const PremiumSubContainer = styled(SummarySubContainer)(({ theme }) => ({
  display: "flex",
  width: "100%",
  marginTop: "10px",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

export const OdAndLiabilityContainer = styled(SummarySubContainer)(
  ({ theme }) => ({
    display: "flex",
    // width: "100%",
    gap: "10px",
    padding: "0",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("890")]: {
      flexDirection: "column",
    },
  })
);

export const PremiumChildContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "75%",
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

export const EditButton = styled(Box)({
  position: "absolute",
  right: 2,
  top: 2,
});

export const OwnDamageWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  whiteSpace: "nowrap",
});

export const MainContainer = styled(Box)({
  width: "100%",
  height: "auto",
  border: "1px solid #ced7e1",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const Details = styled(Typography)({
  color: "#5A5A5A",
  fontSize: "12px",
});

export const Details2 = styled(Typography)({
  color: "#5A5A5A",
  fontSize: "12px",
  fontWeight: "bold",
});

export const EndorsementFieldDescription = styled(Details2)({
  textTransform: "capitalize",
});

export const CardSubTitle = styled(Typography)({
  color: "#006eff",
  fontSize: "12px",
});

export const Total = styled(Typography)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#f3f8f9",
  padding: "10px",
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
});

export const NetPremiumContainer = styled(Box)({
  border: "1px solid #ced7e1",
  borderRadius: "10px",
  backgroundColor: "#f3f8f9",
  padding: "10px",
  marginTop: "10px",
});

export const CustomerSummaryHeading = styled(Typography)({
  fontSize: "13px",
  color: "#6e8185",
  fontWeight: "bold",
  marginTop: "15px",
  marginBottom: "0",
});

export const TabElement = styled(Tab)(({ value, position }) => ({
  fontSize: 11,
  fontWeight: "bold",
  color: value == position ? "white" : "",
  backgroundColor: value == position ? "#006eff" : "",
  borderRadius: "35px",
  minHeight: "0",
  maxHeight: "35px",
  margin: "0 5px",
  padding: "10px",
}));

export const DocumentContainerRenewal = styled(Box)({
  padding: "0 15px",
});
