import { Paper, styled, Box, Button } from "@mui/material";
import { ButtonWrapper } from "../../../masters/components/MasterStyle";

export const MainContainer = styled(Paper)({
  padding: "15px",
  margin: "30px 30px 15px 30px",
  borderRadius: "14px",
  boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
});

export const SearchContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
});

export const InputContainer = styled(Box)({
  width: "100%",
});

export const ResetButton = styled(Button)({
  opacity: "0.69",
  fontFamily: "Lato",
  fontSize: "12px",
  color: "#6e8185",
  background: "none",
  borderRadius: "10px",
  padding: "10px 15px",
  height: "36px",
});

export const ButtonWrappers = styled(ButtonWrapper)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const ButtonsSearch = styled(Button)({
  padding: "10px 20px",
  height: "40px",
  textTransform: "capitalize",
});
