import * as yup from "yup";
export const ICConfigPACoverValidationSchema = yup.object().shape({
  pa_cover: yup.array().of(
    yup.object().shape({
      segment_slug: yup
        .array()
        .test("check_others", "segment is required", function (value) {
          return value?.length > 0;
        }),
      premium_total: yup
        .string()
        .test("check_others", "price is required", function (value) {
          return value?.length > 0;
        }),
    })
  ),
});
