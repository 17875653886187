import React from "react";
import {
  SummaryChild,
  SummaryHeading,
  SummarySubContainer,
  SummarySubHeading,
} from "../../../../summary/summarystyle";
import { StyledGrid } from "../../../../masters/components/MasterStyle";

function EstimationDetails({ claimDetails }) {
  return (
    <SummarySubContainer>
      <StyledGrid container columns={12} spacing={0}>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">IDV</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.idv}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Vehicle Premium</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.vehicle_premium}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Voluntary Deductible
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.voluntary_access_foramount}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Labour Cost</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.labour_cost}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Part Cost</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.parts_cost}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Metal Cost</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.metal_cost}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Paint Cost</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.paint_cost}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Total Other Cost</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.total_other_cost}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Total Estimation</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.total_estimation}
          </SummarySubHeading>
        </SummaryChild>
      </StyledGrid>
    </SummarySubContainer>
  );
}

export default EstimationDetails;
