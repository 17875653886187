import React, { useState } from "react";
import { Box, styled, useMediaQuery } from "@mui/material";
import ClaimCustomerDocumentUploadForm from "./ClaimCustomerDocumentUploadForm";
import ClaimDetailsFooter from "../components/ClaimDetailsFooter";
import ClaimSummaryPopup from "../components/ClaimSummaryPopup";
import Navbar from "../../../components/Navbar";
import ClaimSummary from "../components/ClaimSummary";

const ClaimCustomerDocumentUpload = () => {
  const [openDetails, setOpenDetails] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Navbar>
      <Wrapper>
        <Container>
          {!isMobile ? (
            <ClaimSummary />
          ) : (
            <>
              <ClaimDetailsFooter
                openDetails={openDetails}
                setOpenDetails={setOpenDetails}
              ></ClaimDetailsFooter>
              {openDetails && (
                <>
                  <ClaimSummaryPopup
                    setOpenDetails={setOpenDetails}
                    openDetails={openDetails}
                  />
                  <div className="mobileProposalBlur"></div>
                </>
              )}
            </>
          )}
          <ClaimCustomerDocumentUploadForm />
        </Container>
      </Wrapper>
    </Navbar>
  );
};

export default ClaimCustomerDocumentUpload;

const Wrapper = styled(Box)(({ isSmallScreen }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",

  ...(isSmallScreen && {
    flexDirection: "column",
    alignItems: "center",
  }),
}));

const Container = styled(Box)(({ isSmallScreen }) => ({
  width: "100%",
  display: "flex",
  margin: "12px 15px",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "15px",
  ...(isSmallScreen && {
    flexDirection: "column",
    gap: "10px",
  }),
}));
