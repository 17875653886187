import * as React from "react";
import { styled } from "@mui/system";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  TextareaAutosize,
} from "@mui/material";
import { useFormContext } from "react-hook-form";

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme, error, standard, customHeight, removeBoxShadow, maxHeight }) => ({
    // marginTop: standard ? "3.5px" : "8px",
    fontFamily: "Lato, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    minHeight: customHeight ? customHeight : standard ? "12px" : "46px",
    maxHeight: maxHeight,
    borderRadius: standard ? "" : "7px",
    padding: standard ? "4px 0 1px 0" : "10px",
    border: standard ? "none" : error ? " 1px solid red " : "1px solid #bdd5da",
    borderBottom: standard
      ? "1px solid rgb(0,0,0, 0.4)"
      : error
      ? " 1px solid red "
      : "1px solid #bdd5da",
    borderBottomWidth: standard && "1.5px !important",
    boxShadow:
      standard || removeBoxShadow || error
        ? "none"
        : `0 2px 3px 0 rgba(189, 213, 218, 0.57)`,
    boxSizing: "border-box",
    "&:hover": {
      borderBottom: standard && `2px solid black`,
    },
    "&:focus": {
      borderColor: "none",
      outline: 0,
    },
    "&.Mui-focused": {
      borderBottom: standard && `2px solid ${theme.palette.primary.main}`,
    },
  })
);

const TextArea = React.forwardRef(
  (
    {
      label,
      required,
      name,
      readOnly,
      otherHelperText = false,
      lineBreak = false,
      maxHeight,
      ...otherProps
    },
    ref
  ) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();

    return (
      <Box component="div">
        <FormControl variant="standard" fullWidth>
          <FormLabel
            sx={{
              fontSize: "13px",
              color: "#6e8185",
            }}
          >
            {lineBreak ? (
              <>
                <span>{label.slice(0, lineBreak)}</span> <br />
                <span style={{ fontSize: "12px" }}>
                  {label.slice(lineBreak)}
                </span>
              </>
            ) : (
              label
            )}{" "}
            <span style={{ fontSize: "15px", color: "red" }}>
              {required && label ? "*" : ""}
            </span>
          </FormLabel>
          <StyledTextarea
            id={name}
            className="custom-scrollbar"
            aria-label="empty textarea"
            type="text"
            variant="standard"
            ref={ref}
            {...register(name)}
            {...otherProps}
            error={!!errors[name]}
            readOnly={readOnly}
            helperText={errors[name]?.message || otherHelperText}
            maxHeight={maxHeight}
          />
          <FormHelperText
            error={Boolean(errors[name])}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 10px",
              color: "red",
            }}
          >
            <span>{errors[name]?.message}</span>
          </FormHelperText>
        </FormControl>
      </Box>
    );
  }
);

export default React.memo(TextArea);
