export const formatPrice = (price, round = true) => {
  price = Number(price);

  if (!price) {
    return "₹ 0.00";
  } else if (!isFinite(price)) {
    return "N/A";
  } else {
    if (round) {
      return `₹ ${Math.round(price).toLocaleString("en-IN")}`;
    } else {
      if (isInt(price)) {
        return `₹ ${price.toLocaleString("en-IN")}.00`;
      } else {
        // here price is in fraction
        // no. of digits after decimal 1 then added 0
        if (price.toLocaleString().split(".").at(-1).length === 1) {
          return `₹ ${price.toLocaleString("en-IN")}0`;
        }
        return `₹ ${price.toLocaleString("en-IN")}`;
      }
    }
  }
};
export const formatPrice2 = (price) => {
  if (!price) return "";
  else return `₹ ${Math.round(price).toLocaleString("en-IN")}`;
};
function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}
