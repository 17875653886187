import { createSlice } from "@reduxjs/toolkit";

const payment = createSlice({
  name: "payment",
  initialState: {
    selectedProposalsForPayment: {},
  },
  reducers: {
    setSelectedProposalsForPayment: (state, action) => {
      state.selectedProposalsForPayment = action.payload;
    },
  },
});

export const { setSelectedProposalsForPayment } = payment.actions;

export default payment.reducer;
