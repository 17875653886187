import { Box } from "@mui/material";
import React from "react";
import { StyledGrid } from "../../../masters/components/MasterStyle";
import { DocumentWrapper } from "../../../servicing/cancellation/CancellationApproval/CancellationApprovalStyles";
import {
  SummaryChild,
  SummaryContainer,
  SummaryHeadContainer,
  SummaryHeading,
  SummarySubContainer,
  SummarySubHeading,
  SummaryTitle,
} from "../../../summary/summarystyle";
import DriverDetails from "./components/DriverDetails";
import EstimationDetails from "./components/EstimationDetails";

const ClaimDetailsViewRegistration = ({ claimDetails }) => {
  // props coming from ClaimDetailsViewRight
  const registrationDocuments =
    claimDetails?.registration_certificate ||
    claimDetails?.driver_license_certificate ||
    claimDetails?.estimate_certificate ||
    claimDetails?.fir_certificate ||
    claimDetails?.other_certificate;
  return (
    <SummaryContainer
      sx={{
        width: "100%",
        margin: "0 0 15px 0",
      }}
    >
      <Box
        sx={{
          padding: "0.8rem",
        }}
      >
        <SummaryHeadContainer>
          <SummaryTitle>Driver Details</SummaryTitle>
        </SummaryHeadContainer>
        <DriverDetails claimDetails={claimDetails} />
        <br />
        <SummaryHeadContainer>
          <SummaryTitle>Estimation Details</SummaryTitle>
        </SummaryHeadContainer>
        <EstimationDetails claimDetails={claimDetails} />
        {registrationDocuments && (
          <>
            <br />
            <SummaryHeadContainer>
              <SummaryTitle>Document Collected</SummaryTitle>
            </SummaryHeadContainer>
            <SummarySubContainer>
              <StyledGrid container columns={12} rowSpacing={1.5}>
                {claimDetails?.registration_certificate && (
                  <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
                    <SummaryHeading variant="subtitle1">
                      Registration Certificate
                    </SummaryHeading>
                    <SummarySubHeading variant="subtitle2">
                      <a
                        rel="noreferrer"
                        target={"_blank"}
                        href={claimDetails?.registration_certificate}
                        style={{ color: "#394d51" }}
                      >
                        <DocumentWrapper>View</DocumentWrapper>
                      </a>
                    </SummarySubHeading>
                  </SummaryChild>
                )}
                {claimDetails?.driver_license_certificate && (
                  <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
                    <SummaryHeading variant="subtitle1">
                      Driving License
                    </SummaryHeading>
                    <SummarySubHeading variant="subtitle2">
                      <a
                        rel="noreferrer"
                        target={"_blank"}
                        href={claimDetails?.driver_license_certificate}
                        style={{ color: "#394d51" }}
                      >
                        <DocumentWrapper>View</DocumentWrapper>
                      </a>
                    </SummarySubHeading>
                  </SummaryChild>
                )}
                {claimDetails?.estimate_certificate && (
                  <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
                    <SummaryHeading variant="subtitle1">
                      Estimated Certificate
                    </SummaryHeading>
                    <SummarySubHeading variant="subtitle2">
                      <a
                        rel="noreferrer"
                        target={"_blank"}
                        href={claimDetails?.estimate_certificate}
                        style={{ color: "#394d51" }}
                      >
                        <DocumentWrapper>View</DocumentWrapper>
                      </a>
                    </SummarySubHeading>
                  </SummaryChild>
                )}
                {claimDetails?.fir_certificate && (
                  <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
                    <SummaryHeading variant="subtitle1">
                      FIR Document
                    </SummaryHeading>
                    <SummarySubHeading variant="subtitle2">
                      <a
                        rel="noreferrer"
                        target={"_blank"}
                        href={claimDetails?.fir_certificate}
                        style={{ color: "#394d51" }}
                      >
                        <DocumentWrapper>View</DocumentWrapper>
                      </a>
                    </SummarySubHeading>
                  </SummaryChild>
                )}
                {claimDetails?.other_certificate && (
                  <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
                    <SummaryHeading variant="subtitle1">
                      Other Certificate
                    </SummaryHeading>
                    <SummarySubHeading variant="subtitle2">
                      <a
                        rel="noreferrer"
                        target={"_blank"}
                        href={claimDetails?.other_certificate}
                        style={{ color: "#394d51" }}
                      >
                        <DocumentWrapper>View</DocumentWrapper>
                      </a>
                    </SummarySubHeading>
                  </SummaryChild>
                )}
              </StyledGrid>
            </SummarySubContainer>
          </>
        )}
      </Box>
    </SummaryContainer>
  );
};

export default ClaimDetailsViewRegistration;
