import React, { useState } from "react";
import { Box } from "@mui/material";
import { StyledGrid, ButtonWrapper } from "../../components/MasterStyle";
import { FormProvider, useForm } from "react-hook-form";
import {
  Heading,
  HeadingLabel,
} from "../../../Configurator/DiscountConfigurator/DiscountConfiguratorStyle";
import { GridChild } from "../../../dashboard/Dashboard.style";
import withRouteProtection from "../../../../hoc/withRouteProtection"; // AuthContext,

import MasterTable from "../../MasterTable/MasterTable";

import Buttons from "../../../../components/BasicFormComponent/Buttons";
import { allowOnlyNumbers } from "../../../../InputUtils/onInput";
import TextField from "../../../../components/BasicFormComponent/TextFieldInput";
import {
  InputContainer,
  MainContainer,
  SearchContainer,
} from "../../../servicing/cancellation/CancellationSearch/CancellationSearchStyle";
import Navbar from "../../../../components/Navbar";

function BrokerMobileRequest({ values }) {
  const methods = useForm({
    // resolver: yupResolver(BrokerValidation),
    defaultValues: {
      mobile_number: "",
      ...values,
    },
  });

  const [searchParam, setSearchParam] = useState({});
  const onSubmit = (data) => {
    setSearchParam(data);
  };
  return (
    <Navbar>
      <>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <MainContainer>
              <Heading variant="h5" align="left">
                <HeadingLabel variant="h5" align="left">
                  Broker Mobile Request
                </HeadingLabel>
              </Heading>
              <SearchContainer>
                <InputContainer>
                  <StyledGrid columns={12}>
                    <GridChild sm={3} md={3} xs={12} xxs={12}>
                      <TextField
                        label={"Mobile No."}
                        name={"mobile_number"}
                        onInput={allowOnlyNumbers}
                        inputProps={{ maxLength: 10 }}
                        required={true}
                        standard
                      />
                    </GridChild>
                  </StyledGrid>
                </InputContainer>
                <ButtonWrapper gap={2}>
                  <Buttons label={"Search"} type="submit" standard />
                </ButtonWrapper>
              </SearchContainer>
            </MainContainer>
          </form>
        </FormProvider>
        <Box>
          <MasterTable
            master_name={"broker_mobile_request"}
            // customSearch={customSearch}
            columnFilters={Object.keys(searchParam).map((item) => ({
              id: item,
              value: searchParam[item],
            }))}
          />
        </Box>
      </>
    </Navbar>
  );
}
export default withRouteProtection(BrokerMobileRequest);
