import { Box, Button, styled, Typography } from "@mui/material";

export const Heading = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const HeadingLabel = styled(Typography)(() => ({
  fontWeight: "600",
  padding: "0.4rem 0.6rem 0.2rem 0.8rem",
  backgroundImage: "linear-gradient(to right, #03cc83, #fff 30px)",
  borderRadius: "8px",
  opacity: "0.8",
}));

export const AddButton = styled(Button)({
  color: "#475f63",
  border: "solid 1px #bdd5da",
  display: "flex",
  gap: "4px",
  fontSize: "14px",
  textTransform: "capitalize",
  padding: "0px 8px !important",
  lineHeight: "16px",
  height: "30px",
  margin: "2px !important",
});

AddButton.defaultProps = {
  variant: "outlined",
  color: "secondary",
  size: "small",
  margin: "2px !important",
};

export const SearchButton = styled(Button)({
  color: "#fff",
  display: "flex",
  gap: "4px",
  fontSize: "14px",
  textTransform: "capitalize",
  margin: "2px !important",
  padding: "8px 20px !important",
});

SearchButton.defaultProps = {
  variant: "contained",
  color: "primary",
  size: "large",
};

export const RefreshButton = styled(Button)({
  color: "#475f63",
  display: "flex",
  gap: "4px",
  fontSize: "14px",
  textTransform: "capitalize",
  lineHeight: "16px",
  margin: "2px !important",
  padding: "8px 20px !important",
  ":active": {
    border: "none",
    backgroundColor: "#fff",
  },
});

RefreshButton.defaultProps = {
  color: "secondary",
  size: "large",
};

export const ColumnLabel = styled(Typography)({
  fontSize: "15px",
  color: "#6e8185",
  margin: "10px 5px 0 5px",
});
