import { Grid } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import TextInput from "../../../../components/InputComponents/TextInput";
import {
  allowOnlyNumbers,
  allowOnlyUniqueAlphabetsNoSpace,
  toCapitalize,
} from "../../../../InputUtils/onInput";
import Select from "../../../../components/InputComponents/Select";
import DateInput from "../../../../components/InputComponents/DateInput";
import { useParams } from "react-router";
import {
  useGetDetailsFromSurveyorName,
  useGetSurveyorDetails,
  useGetSurveyorOptionsFromClaimId,
} from "../../../../services/claims.service";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { dateFormat } from "../../../../utils/minDateForEndDate";

function SurveyorDetailsForm() {
  const { watch, setValue } = useFormContext();
  const { claim_detail_id } = useParams();
  const { surveyor_details_list } = useGetSurveyorDetails(claim_detail_id);

  const surveyorDetails = surveyor_details_list[0];

  const { surveyor_options } =
    useGetSurveyorOptionsFromClaimId(claim_detail_id);

  const surveyor_list = useMemo(() => {
    return surveyor_options.map((item) => {
      return {
        code: item?.surveyor_id,
        display_name: item?.surveyor_name,
      };
    });
  }, [surveyor_options]);

  const { table_list: surveyor_value } = useGetDetailsFromSurveyorName(
    watch("surveyor_id")
  );

  useEffect(() => {
    if (surveyor_value) {
      setValue("surveyor_license_no", surveyor_value?.srv_license_no);
      setValue(
        "license_expiry_date",
        moment(surveyor_value?.license_exp_date, "YYYY-MM-DD hh:mm:ss").format(
          dateFormat
        )
      );
      setValue("srv_contact_no", surveyor_value?.srv_contact_no);
      setValue("srv_email_id", surveyor_value?.srv_email_id);
    }
  }, [surveyor_value]);

  useEffect(() => {
    if (surveyorDetails) {
      setValue("place_of_accident", surveyorDetails?.place_of_accident);
      setValue("Claim_reg_no", surveyorDetails?.claim_intimation_no);
      setValue("total_estimation_cost", surveyorDetails?.total_estimation);
      setValue("appointment_dealer", surveyorDetails?.dealer_name);
    }
  }, [surveyorDetails]);
  return (
    <Grid container spacing={2} mt={0} px={2}>
      <Grid item lg={4} xxs={6}>
        <TextInput
          name={"Claim_reg_no"}
          label={"Claim Reg. No."}
          onInput={allowOnlyNumbers}
          readOnly
          standard
        />
      </Grid>

      <Grid item lg={4} xxs={6}>
        <TextInput
          name={"total_estimation_cost"}
          label={"Total Estimation Cost"}
          onInput={allowOnlyNumbers}
          readOnly
          standard
        />
      </Grid>

      <Grid item lg={4} xxs={6}>
        <TextInput
          name={"place_of_accident"}
          label={"Accident Location"}
          readOnly
          standard
        />
      </Grid>

      <Grid item lg={4} xxs={6}>
        <TextInput
          name={"appointment_dealer"}
          label={"Appointment Dealer"}
          standard
          readOnly
          onInput={(e) => {
            toCapitalize(e);
            allowOnlyUniqueAlphabetsNoSpace(e);
          }}
        />
      </Grid>

      <Grid item lg={4} xxs={6}>
        <Select
          name={"surveyor_id"}
          label={"Surveyor Name"}
          variant="standard"
          options={surveyor_list}
        />
      </Grid>

      <Grid item lg={4} xxs={6}>
        <TextInput
          name={"surveyor_license_no"}
          label={"Surveyor License No."}
          onInput={allowOnlyNumbers}
          readOnly
          standard
        />
      </Grid>

      <Grid item lg={4} xxs={6}>
        <DateInput
          label="License Expiry Date"
          name="license_expiry_date"
          readOnly
          variant="standard"
        />
      </Grid>

      <Grid item lg={4} xxs={6}>
        <TextInput
          name={"srv_contact_no"}
          label={"Mobile No."}
          standard
          onInput={allowOnlyNumbers}
          readOnly
        />
      </Grid>

      <Grid item lg={4} xxs={6}>
        <TextInput
          name={"srv_email_id"}
          label={"Email ID   "}
          readOnly
          standard
        />
      </Grid>
    </Grid>
  );
}

export default SurveyorDetailsForm;
