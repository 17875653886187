/*

This file includes  base validations for GSTIN no. 
Utilize these & add custom validations afterwards in respective files

*/

import * as yup from "yup";
import { REGEX } from "../../constants/regex";

export const gstinNumberBasic = () => {
  return yup
    .string()
    .nullable()
    .matches(REGEX.GSTIN, "Please enter a valid GSTIN");
};

export const gstinNumberRequired = () => {
  return yup
    .string()
    .nullable()
    .required("GSTIN is required")
    .matches(REGEX.GSTIN, "Please enter a valid GSTIN");
};

export const gstinNoRequired = () => {
  return yup
    .string()
    .nullable()
    .required("GSTIN no. is required")
    .matches(REGEX.GSTIN, "Please enter a valid GSTIN no.");
};
