import { Box } from "@mui/material";
import React from "react";
import {
  SummaryContainer,
  SummaryHeadContainer,
  SummaryTitle,
} from "../../../summary/summarystyle";
import SurveyorDetails from "./components/SurveyorDetails";

const ClaimDetailsViewSurveyor = ({ claimDetails }) => {
  // props coming from ClaimDetailsViewRight
  return (
    <SummaryContainer
      sx={{
        width: "100%",
        margin: "0 0 15px 0",
      }}
    >
      <Box
        sx={{
          padding: "0.8rem",
        }}
      >
        <SummaryHeadContainer>
          <SummaryTitle>Surveyor Details</SummaryTitle>
        </SummaryHeadContainer>
        <SurveyorDetails claimDetails={claimDetails} />
      </Box>
    </SummaryContainer>
  );
};

export default ClaimDetailsViewSurveyor;
