import React, { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogContent, Stack, Typography } from "@mui/material";
import QuoteCheckbox from "../QuoteCheckBox/QuoteCheckbox";
import ShareFeatures from "./ShareFeatures";
import QuotesTable from "./QuotesTable";
import {
  useGetCommunication,
  useGetQuotes,
} from "../../../../services/quote.service";
import { useGetFilterService } from "../../../../services/dashboard.service";
import { useParams } from "react-router";
import ShareThankyou from "./ShareThankyou";
import {
  BootstrapDialog,
  HeaderStackStyled,
  SubHeaderActionStyled,
  SubHeaderPaperStyled,
  TabButtonStyled,
} from "./ShareQuotesStyle";
import Buttons from "../../../../components/BasicFormComponent/Buttons";

const BUTTONS = {
  INSURER: "Insurer",
  BASIC: "Basic",
  THANKYOU: "Thankyou",
};

function ShareQuotes({ open, setOpen }) {
  const [selectedButton, setSelectedButton] = React.useState("Insurer");
  const {
    createCommunication,
    isLoading,
    isSuccess,
    resetMaster,
    sentSuccessfullyMessage,
  } = useGetCommunication();
  const { quotes, quotesData } = useGetQuotes();
  const filteredQuotes =
    quotesData?.filter((item) => item !== undefined && item !== null) || [];

  const [selected, setSelected] = useState([]);
  const handleClose = () => {
    setOpen(false);
    setSelectedButton("Insurer");
  };
  const { segment_slug } = useParams();
  const { segment_logo } = useGetFilterService(segment_slug);

  const handleShareAll = (e) => {
    if (e.target.checked) {
      // const allQuotesId = quotes.reduce((acc, item) => {
      //   return [...acc, ...item.map((quote) => quote)];
      // }, []);

      setSelected(filteredQuotes);
    } else {
      setSelected([]);
    }
  };

  const handleSelectOne = (e, quote) => {
    let checkInclude = selected.includes(quote);
    if (!checkInclude) {
      setSelected([...selected, quote]);
    } else {
      setSelected(selected.filter((item) => item !== quote));
    }
  };

  const flatQuotes = quotes.reduce((acc, item) => {
    return [...acc, ...item.map((quote) => quote)];
  }, []);

  useEffect(() => {
    if (isSuccess) setSelected([]);
  }, [isSuccess]);
  return (
    <div>
      {" "}
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
        selectedButton={selectedButton}
        sx={{
          "& .MuiDialog-paper": {
            margin: 0.5,
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box display="flex" justifyContent="flex-end" width="100%">
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {selectedButton !== BUTTONS.THANKYOU && (
            <Box sx={{ px: { xs: 0, md: 0, lg: 2 } }} width="100%">
              <HeaderStackStyled
                direction={"row"}
                spacing={2}
                alignItems="center"
              >
                <img src={segment_logo} alt="logo" height={50} width={50} />
                <Typography variant="body1">
                  Hi, please choose the way you wish to share the quotes
                </Typography>
              </HeaderStackStyled>
              <SubHeaderActionStyled
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <SubHeaderPaperStyled elevation={5}>
                  <Stack direction="row">
                    <TabButtonStyled
                      variant={
                        selectedButton === BUTTONS.INSURER && "contained"
                      }
                      onClick={() => setSelectedButton(BUTTONS.INSURER)}
                    >
                      Select Insurer
                    </TabButtonStyled>
                    <TabButtonStyled
                      variant={selectedButton === BUTTONS.BASIC && "contained"}
                      onClick={() => setSelectedButton(BUTTONS.BASIC)}
                      disabled={selected.length === 0}
                    >
                      Basic Details
                    </TabButtonStyled>
                  </Stack>
                </SubHeaderPaperStyled>
                {selectedButton === BUTTONS.INSURER && (
                  <QuoteCheckbox
                    id="select_all_quotes"
                    label="Select All"
                    checked={selected?.length === filteredQuotes?.length}
                    onChange={(e) => handleShareAll(e)}
                  />
                )}
              </SubHeaderActionStyled>
            </Box>
          )}
        </DialogTitle>

        <DialogContent
          sx={{
            px: {
              xs: 0,
              md: 8,
            },
            p: 1.5,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {/* { ---------- Quotes maped here ----------}  */}
          {selectedButton === BUTTONS.INSURER && (
            <>
              {flatQuotes.map((group, index) => (
                <QuotesTable
                  key={index}
                  selected={selected}
                  handleSelectOne={handleSelectOne}
                  productId={group.productId}
                  bundleId={group.bundleId}
                />
              ))}
            </>
          )}

          {/* { ---------- Share Feature Here ----------}  */}

          {selectedButton === BUTTONS.BASIC && (
            <ShareFeatures
              selected={selected}
              setSelectedButton={setSelectedButton}
              setOpen={setOpen}
              createCommunication={createCommunication}
              isSuccess={isSuccess}
              isLoading={isLoading}
              resetMaster={resetMaster}
            />
          )}

          {selectedButton === BUTTONS.THANKYOU && (
            <ShareThankyou shareQuoteData={sentSuccessfullyMessage} />
          )}
        </DialogContent>

        {selectedButton !== BUTTONS.THANKYOU && (
          <Stack alignItems="center" sx={{ py: 3, background: "#F5F5F5" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              *Please note that the premium may vary in future.
            </Typography>
            {selectedButton === BUTTONS.INSURER && !!selected.length && (
              <Buttons
                onClick={() => {
                  setSelectedButton(BUTTONS.BASIC);
                }}
                label="Next"
                id="next_button_for_basic_details"
              ></Buttons>
            )}
          </Stack>
        )}
      </BootstrapDialog>
    </div>
  );
}

export default ShareQuotes;
