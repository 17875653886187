import { Box, IconButton, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { InputLabel, TextField } from "./InputComponents.style";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { reduceObj } from "../../utils/errorHandler";
import { inWords } from "../../utils/NumbersToWords";
import { Edit } from "@mui/icons-material";

const TextInput = ({
  label,
  required,
  name,
  readOnly,
  multiline,
  otherHelperText = false,
  renderCustomLabel,
  bordered,
  startAdornment,
  removeBottomMargin,
  numberToWord, // New prop for passing the numeric value
  fixedPart,
  isMasked,
  ...otherProps
}) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();
  const value = watch(name);

  const [show, setShow] = useState(false);
  const updatedInputProps = { ...otherProps?.inputProps, readOnly };

  const reducedErrors = reduceObj(errors, name);

  let numberInWords = "";
  if (value !== undefined && numberToWord === true) {
    numberInWords = inWords(value);
  }
  const [editMasked, setEditMasked] = useState(false);

  useEffect(() => {
    if (isMasked) setEditMasked(true);
  }, [isMasked]);
  const endAdornment = () => {
    if (
      ["password", "confirmPassword"].includes(name) ||
      otherProps.type === "password"
    ) {
      return (
        <InputAdornment position="end">
          <IconButton
            onMouseDown={() => setShow(true)}
            onMouseUp={() => setShow(false)}
          >
            {show ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      );
    }
    if (isMasked && editMasked && !readOnly) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={() => {
              setValue(name, "");
              setEditMasked(false);
            }}
          >
            <Edit />
          </IconButton>
        </InputAdornment>
      );
    }
    return <></>;
  };
  return (
    <>
      <Box width="100%">
        {(label || renderCustomLabel) && (
          <InputLabel
            hasCustomLabel={renderCustomLabel ? true : false}
            title={label}
            required={required}
            bordered={bordered}
            removeBottomMargin={removeBottomMargin}
          >
            {renderCustomLabel ? renderCustomLabel() : label}
          </InputLabel>
        )}
        <TextField
          name={name}
          id={name}
          bordered={bordered}
          error={!!reducedErrors}
          helperText={
            reducedErrors?.message || numberInWords || otherHelperText
          }
          multiline={multiline}
          value={value || fixedPart || ""}
          readOnly={readOnly || editMasked}
          {...(multiline && {
            rows: 4,
            rowsMax: 4,
          })}
          onBlur={(e) => {
            otherProps.onBlur && otherProps.onBlur(e);
            if (fixedPart) {
              if (e.target.value.indexOf(fixedPart) !== 0) {
                e.preventDefault();
                e.target.value = fixedPart;
              }
            }
          }}
          onKeyUp={(e) => {
            otherProps.onKeyUp && otherProps.onKeyUp(e);
            if (fixedPart) {
              if (e.target.value.indexOf(fixedPart) !== 0) {
                e.preventDefault();
                e.target.value = fixedPart;
              }
            }
          }}
          InputProps={{
            endAdornment: endAdornment(),
            startAdornment,
          }}
          onInput={(e) => {
            otherProps?.onInput && otherProps?.onInput(e);
          }}
          {...register(name)}
          {...otherProps}
          inputProps={updatedInputProps}
          type={show ? "text" : otherProps.type}
        />
      </Box>
    </>
  );
};

export default TextInput;
