/*

This file includes  base validations for uploaded document. 
Utilize these & add custom validations afterwards in respective files

*/

import * as yup from "yup";

export const documentUploadsOnlyImage = () => {
  return yup
    .mixed()
    .nullable()
    .test(
      "document_format",
      "Invalid file format (jpeg, jpg and png only)",
      (value) => {
        return value?.size
          ? value.type === "image/jpeg" ||
              value.type === "image/jpg" ||
              value.type === "image/png"
          : true;
      }
    );
};

export const documentUploadsImageAndPdf = () => {
  return yup
    .mixed()
    .nullable()
    .test(
      "document_format",
      "Invalid file format (jpeg, jpg, png and pdf only)",
      (value) => {
        return value?.size
          ? value.type === "image/jpeg" ||
              value.type === "image/jpg" ||
              value.type === "image/png" ||
              value.type === "application/pdf"
          : true;
      }
    );
};

// use when document is not required field, with 2 mb restriction
export const documentUploadsOnlyImageLessThan2MB = () => {
  return documentUploadsOnlyImage().test(
    "check_file_size",
    "Only documents up to 2MB are permitted.",
    (value) => {
      return value.size ? value.size <= 2097152 : true; //checking file size less than 2MB
    }
  );
};

// use when document is not required field, with 2 mb restriction
export const documentUploadsImageAndPdfLessThan2MB = () => {
  return documentUploadsImageAndPdf().test(
    "check_file_size",
    "Only documents up to 2MB are permitted.",
    (value) => {
      const isUpdate = location.pathname.split("/").includes("update");
      if (isUpdate || !value) return true;
      return value.size ? value.size <= 2097152 : true; //checking file size less than 2MB
    }
  );
};

// use when document is required field, with 2 mb and image restriction
export const documentUploadsOnlyImageLessThan2MBRequired = (key) => {
  return documentUploadsOnlyImageLessThan2MB().test(
    "document_required",
    `${key ? key : "Document"} is required`,
    (val) => {
      const isUpdate = location.pathname.split("/").includes("update");
      if (isUpdate) return true;
      if (!val.size) return false;
      else return true;
    }
  );
};

// use when document is required field, with 2 mb, image and pdf restriction
export const documentUploadsLessThan2MBRequired = (key) => {
  return documentUploadsImageAndPdfLessThan2MB().test(
    "document_required",
    `${key ? key : "Document"} is required`,
    (val) => {
      const isUpdate = location.pathname.split("/").includes("update");
      if (isUpdate) return true;
      if (!val?.size) return false;
      else return true;
    }
  );
};

////////////////// FOR MULTI DOCUMENT //////////////////

export const MultiDocumentUploadsImageAndPdfLessThan2MB = () => {
  return (
    yup
      .mixed()
      .nullable()
      // .required("Document is required")
      .test("fileSize", "Only documents up to 2MB are permitted.", (file) => {
        if (file && file?.length > 0) {
          for (let i = 0; i < file?.length; i++) {
            if (file && file[i]?.size > 2097152) {
              return false;
            }
          }
        }
        return true;
      })
      .test(
        "document_upload",
        "Invalid file format (jpeg, jpg, png and pdf only)",
        (value) => {
          if (value && value.length > 0) {
            for (let i = 0; i < value.length; i++) {
              if (
                value[i].type != "image/png" &&
                value[i].type != "image/jpg" &&
                value[i].type != "image/jpeg" &&
                value[i].type != "application/pdf"
              ) {
                return false;
              }
            }
          }
          return true;
        }
      )
  );
};

export const MultiDocumentUploadsImageAndPdfLessThan2MBRequired = (key) => {
  return MultiDocumentUploadsImageAndPdfLessThan2MB().test(
    "document_required",
    `${key ? key : "Document"} is required`,
    (val) => {
      if (!val?.length) return false;
      else return true;
    }
  );
};

export const documentUploadsOnlyExcel = () => {
  return yup
    .mixed()
    .test("check_file", "Browse file is required", function (value) {
      return value.size;
    })
    .test(
      "document_format",
      "Invalid file format (Excel sheets only)",
      (value) => {
        return value.size
          ? value.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          : true;
      }
    );
};
