import { useContext } from "react";
import withRouteProtection, {
  AuthContext,
} from "../../../hoc/withRouteProtection";
import MasterTableForImportHistory from "../MasterTable/MasterTableForImportHistory";
import { useParams } from "react-router";
import Navbar from "../../../components/Navbar";

const MasterPageForImportHistory = ({ showWithReadAccess, ...props }) => {
  const { writable } = useContext(AuthContext);
  const { master_name } = useParams();
  const slug = master_name;
  return (
    <>
      <Navbar>
        <MasterTableForImportHistory
          {...props}
          writable={writable}
          showWithReadAccess={showWithReadAccess}
          customSearch={{
            slug_master: slug || props?.slug,
          }}
        />
      </Navbar>
    </>
  );
};

export default withRouteProtection(MasterPageForImportHistory);
