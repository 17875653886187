import { Grid, Paper, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Papers = styled(Paper)(({ theme }) => ({
  padding: "1.5rem",
  margin: "1rem",
  borderRadius: "1rem",
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
  height: "fit-content",
  [theme.breakpoints.down("xs")]: {
    margin: "8px",
    padding: "18px",
  },
}));

export const Heading = styled(Typography)({
  fontWeight: "600",
  padding: "0.4rem 0.6rem 0.2rem 0.8rem",
  backgroundImage: "linear-gradient(to right, #03cc83, #fff 30px)",
  borderRadius: "8px",
  opacity: "0.8",
});

export const SubHeading = styled(Typography)({
  fontWeight: "600",
  padding: "0px 0px 0px 8px",
  marginLeft: "-14px",
  marginTop: "20px",
  borderLeft: "10px solid #017eff",
  fontSize: "20px",
  // backgroundImage: "linear-gradient(to right, blue, #fff 15px)",
  borderRadius: "8px",
  opacity: "0.8",
});

export const StyledGrid = styled(Grid)({});
StyledGrid.defaultProps = {
  container: true,
  spacing: 2,
  mt: 1,
};

const GridChild = styled(Grid)(() => ({}));

GridChild.defaultProps = {
  ...GridChild.defaultProps,
  xxs: 12,
  xs: 6,
  sm: 4,
  md: 3,
  item: true,
};

export { GridChild };

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: " 0.5rem",
  marginTop: "2.3rem",
  [theme.breakpoints.down("md")]: {
    marginTop: "0.3rem",
    marginBottom: "2.3rem",
  },
}));

///// Small Form /////

export const FormContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
  },
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  position: "absolute",
  top: "340px",
  right: "0",
  "& img": {
    width: "538px",
    height: "333px",
  },
  [theme.breakpoints.down("xl")]: {
    position: "static",
  },
}));

export const FormHeading = styled(Typography)({
  fontWeight: "600",
  padding: "0.4rem 0.6rem 0.2rem 0.8rem",
  backgroundImage: "linear-gradient(to right, #03cc83, #fff 25px)",
  borderRadius: "8px",
  opacity: "0.8",
});

export const InputContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%",
  marginTop: "1rem",
}));

export const InputWrapper = styled(Box)(({ fullWidth }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  width: fullWidth && "100%",
}));

export const AtleastOnefieldError = styled(Box)({
  color: "#D32f2f",
  fontSize: "0.8em",
  marginTop: "0.5em",
  marginLeft: "1em",
});

export const MasterTableContainer = styled(Box)({
  marginLeft: "16px",
  marginRight: "16px",
});

export const ApprovalStatus = styled(Box)({
  padding: "3px 5px",
});

export const SideButtonContainer = styled(Box)({
  position: "absolute",
  top: "83px",
  right: "5px",
  fontSize: "11px",
});

export const ClaimLsoPaper = styled(Paper)({
  padding: "20px",
  marginBottom: "20px",
});

export const ClaimPaperSubContainer = styled(ClaimLsoPaper)({
  borderRadius: "10px",
  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
});

export const ClaimLsoHeading = styled(Heading)({
  marginBottom: "15px",
  fontSize: "15px",
});

export const AddOrRemoveButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "10px",
});

export const AddNewFieldsButton = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  textTransform: "uppercase",
  textAlign: "end",
  cursor: "pointer",
  marginTop: "15px",
}));

export const RemoveAddedFieldButton = styled(AddNewFieldsButton)({
  color: "#6e8185",
});
