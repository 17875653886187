import React from "react";
import { Papers } from "../masters/components/MasterStyle";
import { Box, Chip, Paper, Typography } from "@mui/material";
import moment from "moment";
import { capitalizeAndReplaceUnderscore } from "../../utils/underscoreToSpaceSeparation";

const FeedCardOnButtonClick = ({
  feedFileList,
  isFeedLoading,
  getFeedExport,
}) => {
  if (isFeedLoading) {
    return (
      <Papers>
        <Typography variant="subtitle1" align="left" fontWeight="bold">
          Loading...
        </Typography>
      </Papers>
    );
  }

  return (
    <>
      {feedFileList?.map((dataObject) => {
        const fromDate = dataObject.from_date;
        const toDate = dataObject.to_date;
        const masters = dataObject?.data?.master || [];
        const transactions = dataObject?.data?.transaction || [];
        if (masters.length === 0 && transactions.length === 0) {
          return (
            <Papers key={`${fromDate}-${toDate}`}>
              <Typography variant="subtitle1" align="left" fontWeight="bold">
                Record Is Not Found For Selected Date
              </Typography>
            </Papers>
          );
        }

        return (
          <Papers key={`${fromDate}-${toDate}`}>
            <Typography variant="subtitle1" align="left" fontWeight="bold">
              {moment(fromDate).format("DD-MMM-YYYY")} to{" "}
              {moment(toDate).format("DD-MMM-YYYY")}
            </Typography>
            <Box sx={{ pb: 0.3, mb: 0.3 }}></Box>
            <Box display="flex" sx={{ gap: 2 }}>
              <Box sx={{ width: "100%" }}>
                {masters?.length > 0 && (
                  <Typography variant="subtitle1" align="left">
                    <span style={{ fontWeight: "bold" }}>Masters</span> -
                    {masters?.map((master) => (
                      <Chip
                        key={master}
                        label={capitalizeAndReplaceUnderscore(master)}
                        variant="outlined"
                        size="small"
                        style={{ margin: "5px" }}
                        onClick={() =>
                          getFeedExport({
                            from_date: fromDate,
                            to_date: toDate,
                            table_name: master,
                          })
                        }
                      />
                    ))}
                  </Typography>
                )}
                {transactions?.length > 0 && (
                  <Typography variant="subtitle1" align="left">
                    <span style={{ fontWeight: "bold" }}>Transactions</span> -
                    {transactions?.map((transaction) => (
                      <Chip
                        key={transaction}
                        label={capitalizeAndReplaceUnderscore(transaction)}
                        variant="outlined"
                        size="small"
                        style={{ margin: "5px" }}
                        onClick={() =>
                          getFeedExport({
                            from_date: fromDate,
                            to_date: toDate,
                            table_name: transaction,
                          })
                        }
                      />
                    ))}
                  </Typography>
                )}
              </Box>
            </Box>
          </Papers>
        );
      })}
    </>
  );
};

export default FeedCardOnButtonClick;
