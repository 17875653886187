import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const BootstrapButton = styled(Button)({
  boxShadow: " 0 6px 13px 0 rgba(189, 213, 218, 0.57)",
  textTransform: "none",
  fontSize: 12,
  padding: "0 20px",
  height: "36px",
  // backgroundColor: "colors.primary",
  backgroundColor: "#03cc83",
  borderRadius: "8px",
  color: "#fff",

  "&:hover": {
    backgroundColor: "#03cc83",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#03cc83",
  },
  "&:focus": {
    boxShadow: "colors.primary",
  },
});

export default function Buttons({ label, ...otherProps }) {
  return (
    <BootstrapButton variant="contained" disableRipple {...otherProps}>
      {label}
    </BootstrapButton>
  );
}
