import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useGetDealerDataFromDealerMandateId,
  useGetSortedMaster,
  useSendCustomerMandateData,
  useUpdateCustomerMandateData,
} from "../../../services/master.service";
import { useParams } from "react-router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Navbar from "../../../components/Navbar";
import Select from "../../../components/InputComponents/Select";
import MasterFormWrapper from "../../masters/components/MasterFormWrapper/MasterFormWrapper";
import { Box, Grid, styled } from "@mui/material";
import TextInput from "../../../components/InputComponents/TextInput";
import CheckBox from "../../../components/BasicFormComponent/CheckBox";
import InfoIcon from "@mui/icons-material/Info";

function OtpMandateForm() {
  const { dealer_mandate_id_in_url } = useParams();

  const {
    sendMandateData,
    isSuccess: isSuccessForAdd,
    alertMaster: alertMasterForAdd,
    resetMaster: resetMasterForAdd,
    isError: isErrorForAdd,
  } = useSendCustomerMandateData();

  const {
    updateMandateData,
    isSuccess: isSuccessForUpdate,
    alertMaster: alertMasterForUpdate,
    resetMaster: resetMasterForUpdate,
    isError: isErrorForUpdate,
  } = useUpdateCustomerMandateData();

  const { dealerData } = useGetDealerDataFromDealerMandateId({
    dealer_mandate_id: dealer_mandate_id_in_url,
  });

  const customerMandateSchema = yup.object().shape({
    dealer_id: yup.string().required("Dealer name is required"),
  });

  const methods = useForm({
    resolver: yupResolver(customerMandateSchema),
    defaultValues: {
      dealer_id: "",
      mandate_method: dealerData?.mandate_method || "Mandate",
      dealer_code: dealerData?.dealer_code || "",
    },
  });

  useEffect(() => {
    if (!dealer_mandate_id_in_url) {
      methods.setValue("otp", true);
    }
  }, [dealer_mandate_id_in_url]);

  useEffect(() => {
    if (dealerData?.mandate) {
      methods.setValue("mandate", true);
    }
  }, [dealerData?.mandate]);

  useEffect(() => {
    if (dealerData?.otp) {
      methods.setValue("otp", true);
    }
  }, [dealerData?.otp]);

  const dealer_id = methods.watch("dealer_id");

  const { table_list: dealer_list } = useGetSortedMaster({
    master_name: "dealer",
    sortBy: "dealer_name",
  });

  const dealer_group_list = useMemo(() => {
    return dealer_list.map((item) => {
      return {
        code: item.dealer_id,
        display_name: item.dealer_name,
      };
    });
  }, [dealer_list]);

  const selectedDealerCodeArray = useMemo(() => {
    return dealer_list?.filter((item) => item?.dealer_id === dealer_id);
  }, [dealer_id]);

  const selectedDealerCode = selectedDealerCodeArray[0]?.dealer_code;

  useEffect(() => {
    if (!!dealer_mandate_id_in_url === false) {
      methods.setValue("dealer_code", selectedDealerCode);
    }
  }, [selectedDealerCode, dealer_id]);

  useEffect(() => {
    methods.setValue("mandate_method", dealerData?.mandate_method);
  }, [dealerData]);

  useEffect(() => {
    methods.setValue("dealer_code", dealerData?.dealer_code);
    methods.setValue("dealer_id", dealerData?.dealer_id);
  }, [dealerData]);

  const { otp, mandate, both } = methods.watch();

  const onSubmit = (data) => {
    if (!(otp || mandate || both)) {
      methods.setError("atleast_one_mandate_method", {
        type: "custom",
        message: "Atleast one mandate method is required",
      });
    } else {
      if (dealer_mandate_id_in_url) {
        data.dealer_mandate_id = dealer_mandate_id_in_url;
        updateMandateData(data);
      } else {
        sendMandateData(data);
      }
    }
  };

  return (
    <Navbar>
      <FormProvider {...methods}>
        <MasterFormWrapper
          title={"Customer Mandate Form"}
          description={"Add customer mandate form"}
          onSubmit={onSubmit}
          alert={
            dealer_mandate_id_in_url ? alertMasterForUpdate : alertMasterForAdd
          }
          reset={
            dealer_mandate_id_in_url ? resetMasterForUpdate : resetMasterForAdd
          }
          isError={dealer_mandate_id_in_url ? isErrorForUpdate : isErrorForAdd}
          isSuccess={
            dealer_mandate_id_in_url ? isSuccessForUpdate : isSuccessForAdd
          }
        >
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <Select
              name={"dealer_id"}
              label={"Dealer"}
              required={true}
              readOnly={!!dealer_mandate_id_in_url}
              options={dealer_group_list}
            />
          </Grid>
          <Grid item xxs={12} xs={6} md={6} lg={4}>
            <TextInput
              name={"dealer_code"}
              label={"Dealer Code"}
              readOnly={true}
              required={true}
            />
          </Grid>

          <Grid item xxs={12}>
            <Grid container>
              <Grid item lg={4}>
                <NormalTextInputTitle>
                  {"Mandate Method"}{" "}
                  <span style={{ color: "#d32f2f", paddingLeft: "2px" }}>
                    *
                  </span>
                </NormalTextInputTitle>

                <p
                  style={{
                    color: "#d32f2f",
                    fontSize: "12px",
                  }}
                >
                  {
                    methods.formState.errors?.atleast_one_mandate_method
                      ?.message
                  }
                </p>
              </Grid>
              <Grid>
                <Box>
                  <CheckBox name="otp" label="OTP" size={"small"} />
                </Box>
              </Grid>
              <Grid>
                <Box>
                  <CheckBox name="mandate" label="Mandate" size={"small"} />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {mandate && (
            <Grid item xxs={12}>
              <Grid container>
                <Grid item lg={8}>
                  <Message>
                    <InfoIcon
                      fontSize="small"
                      style={{ paddingRight: "3px" }}
                    />
                    {
                      "Note : Mandate form will be available only till today Midnight 12:00"
                    }
                  </Message>
                </Grid>
              </Grid>
            </Grid>
          )}
        </MasterFormWrapper>
      </FormProvider>
    </Navbar>
  );
}

export default OtpMandateForm;

const NormalTextInputTitle = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "top",
  borderRadius: "5px",
  minHeight: "20px",
  width: "130px",
  pointerEvents: "none",
  fontSize: "12px",
  color: "rgba(0, 0, 0, 0.6)",
});

const Message = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  fontSize: "12px",
  color: "rgba(0, 0, 0, 0.6)",
  backgroundColor: "rgb(229, 246, 253)",
  borderRadius: "6px",
  padding: "5px 0px",
  paddingLeft: "5px",
});
