import { NotificationAdd, PersonOutlineRounded } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { Box, Button, TextField, styled } from "@mui/material";

export const ShareQuoteButton = styled(Button)(({ theme }) => ({
  border: "1px solid #03CC83",
  color: "#4f6781",
  padding: "4px 10px",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#03CC83",
    color: "#fff",
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
export const IconBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "6px 6px",
  borderRadius: "50%",
  boxShadow: "0 3px 6px 0 rgba(0, 75, 131, 0.14)",
}));
export const ProfileViewBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "4px",
}));

export const TimeSection = styled(Box)(() => ({
  marginTop: "5px",
  fontSize: "12px",
}));

export const ProfileViewText = styled(Box)(() => ({
  display: "flex",
  flex: 2,
  flexDirection: "column",
  alignItems: "flex-start",
  textAlign: "left",
}));

export const ProfileIconWrapper = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  alignItems: "center",
}));
export const LogIconWrapper = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  justifyContent: "flex-end",
  alignItems: "center",
}));

export const NotificationAddIcon = styled(NotificationAdd)(() => ({
  color: "#4f6781",
}));

export const PersonOutlineRoundedIcon = styled(PersonOutlineRounded)(() => ({
  color: "#4f6781",
}));

export const ProfileView = styled(Box)(() => ({
  fontSize: "14px",
  // width: "180px",
}));
export const LoginTextfield = styled(TextField)({
  margin: "10px 0",
});

export const LogoutUserIcon = styled(LogoutIcon)({
  backgroundColor: "var(--primary-color)",
  borderRadius: "50%",
  padding: "3px",
  cursor: "pointer",
  fontSize: "28px",
  color: "white",
});

export const SwitchUserIcon = styled(SyncAltIcon)({
  backgroundColor: "var(--primary-color)",
  borderRadius: "50%",
  padding: "3px",
  cursor: "pointer",
  color: "white",
  fontSize: "28px",
});
