/*

This file includes  base validations for Landline no. 
Utilize these & add custom validations afterwards in respective files

*/

import * as yup from "yup";
import { REGEX } from "../../constants/regex";

export const landlineNumberBasic = () =>
  yup
    .string()
    .nullable()
    .matches(REGEX.LANDLINE_NO, "Please enter valid landline no.");

export const landlineNumberRequired = () =>
  yup
    .string()
    .nullable()
    .required("Landline no. is required")
    .matches(REGEX.LANDLINE_NO, "Please enter valid landline number");

export const lsolandlineNumberBasic = () =>
  yup
    .string()
    .nullable()
    .matches(REGEX.LANDLINE_NO, "Please enter valid lso landline no.");
