import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const Prompt = ({
  onAgree,
  onCancel,
  open,
  title,
  description,
  cancelText,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {description && (
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {!onCancel ? (
          ""
        ) : (
          <Button id="cancel_button_for_force_login" onClick={onCancel}>
            {cancelText ? cancelText : "Cancel"}
          </Button>
        )}
        <Button
          id="ok_button_for_force_login"
          variant="contained"
          onClick={() => {
            onAgree();
            onCancel();
          }}
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default Prompt;
