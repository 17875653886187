import { Container, Grid, Paper, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import AlertMessage from "../../../../components/Alert/AlertMessage";
import {
  ResetButton,
  SubmitButton,
} from "../../../../components/InputComponents/InputComponents.style";
import {
  getActiveStep,
  stepsOtherThanRoot,
} from "../../../../constants/icConfigConstants";

const MasterFormWrapper = ({
  title,
  description,
  children,
  onSubmit,
  onReset,
  alert,
  reset,
  isError,
  isSuccess,
  additionalContent,
  showSkipButton,
  customSubmission,
  isLoading,
}) => {
  // For IC config form skips
  const { id } = useParams();
  const navigate = useNavigate();
  const activeStep = getActiveStep();
  const nextPath = stepsOtherThanRoot[1 + activeStep]?.path;
  const url = `/ic_configurator/ic_onboard/update/${id + nextPath}`;
  const handleSkip = () => navigate(url);

  const { handleSubmit } = useFormContext();
  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={2} mt={2}>
          <Grid item xs={3}>
            <Typography variant="h4" align="left">
              {title}
            </Typography>
            <Typography variant="body1" align="left" color={"text.secondary"}>
              {description}
            </Typography>
            {additionalContent}
          </Grid>
          <Grid item xs={9}>
            <Paper
              sx={{
                padding: "20px",
              }}
              component={"form"}
              onSubmit={handleSubmit(onSubmit)}
              elevation={3}
            >
              <AlertMessage
                alert={alert}
                reset={reset}
                isError={isError}
                isSuccess={isSuccess}
              />
              <Grid container spacing={2}>
                {children}
                {!customSubmission && (
                  <Grid
                    display="flex"
                    gap="15px"
                    item
                    xs={12}
                    justifyContent={"flex-end"}
                  >
                    {onReset && (
                      <ResetButton onClick={onReset}>Reset</ResetButton>
                    )}
                    {showSkipButton && id && (
                      <ResetButton onClick={handleSkip}>Skip</ResetButton>
                    )}
                    <SubmitButton disabled={isLoading || isSuccess}>
                      Submit
                    </SubmitButton>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default MasterFormWrapper;
