/**
1. Takes a paramaeter & returns if available
2. TODO: If required add condition to remove slash (/)
**/

export const conditionalParam = (param) => {
  if (param) {
    return `/${param}`;
  }
  return "";
};
