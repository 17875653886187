import { Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import TextArea from "../../../../../../../../components/BasicFormComponent/TextArea";
import {
  CLAIM_INVOICE_RAISED,
  DELIVERY_ORDER_RECEIVED,
  PROFORMA_INVOICE_RECEIVED,
} from "../../../../../../../../constants/claimStage";
import { useGetPolicyDetailsRegistration } from "../../../../../../../../services/claims.service";
import { DocumentWrapper } from "../../../../../../../servicing/cancellation/CancellationApproval/CancellationApprovalStyles";
import SummaryDocupload from "../../../../../../../summary/SummaryDocupload";
import {
  SummaryHeading,
  SummarySubHeading,
} from "../../../../../../../summary/summarystyle";

function ClaimDocumentUploadFields({ document_name, remark_name }) {
  const { claim_detail_id } = useParams("claim_detail_id");

  const { policy_details_list: claimDetailsFromClaimId } =
    useGetPolicyDetailsRegistration(claim_detail_id);

  const claim_details_from_claim_id = claimDetailsFromClaimId?.[0];

  const hideRequriedMark = [
    PROFORMA_INVOICE_RECEIVED,
    DELIVERY_ORDER_RECEIVED,
    CLAIM_INVOICE_RAISED,
  ].includes(claim_details_from_claim_id?.claim_stage?.toLowerCase());

  const documentUrl = claim_details_from_claim_id[document_name];

  return (
    <Grid container spacing={2} mt={0} px={2}>
      <Grid item lg={4} xxs={12} xs={6}>
        <SummaryDocupload
          label="Document"
          name={`${document_name}`}
          required={!hideRequriedMark || !documentUrl}
        />
      </Grid>
      {documentUrl && (
        <Grid item lg={4} xxs={12} xs={6}>
          <SummaryHeading variant="subtitle1" sx={{ whiteSpace: "nowrap" }}>
            Previous Document
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            <a
              rel="noreferrer"
              target={"_blank"}
              href={documentUrl}
              style={{ color: "#394d51" }}
            >
              <DocumentWrapper>View</DocumentWrapper>
            </a>
          </SummarySubHeading>
        </Grid>
      )}
      <Grid item lg={4} xxs={12} xs={6}>
        <TextArea
          label="Remarks"
          name={`${remark_name}`}
          standard
          maxLength={300}
        />
      </Grid>
    </Grid>
  );
}

export default ClaimDocumentUploadFields;
