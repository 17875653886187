import { Box, Paper, styled } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SubmitButton } from "../../../components/InputComponents/InputComponents.style";
import { ButtonWrapper } from "../../masters/components/MasterStyle";
import { useSendClaimDocument } from "../../../services/claims.service";
import AlertMessage from "../../../components/Alert/AlertMessage";
import { useParams } from "react-router";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import { yupResolver } from "@hookform/resolvers/yup";
import { claimDocumentValidation } from "../validations/claimDocumentValidation";
import ClaimCommonDocuments from "../components/ClaimCommonDocuments";

const ClaimCustomerDocumentUploadForm = () => {
  const { claim_detail_id } = useParams();
  const methods = useForm({
    resolver: yupResolver(claimDocumentValidation),
    defaultValues: {},
  });

  const {
    createClaimDocument,
    isSuccess,
    isError,
    alertMaster,
    resetMaster,
    isLoading,
  } = useSendClaimDocument();

  const { getUrlQuery } = useUrlQuery();
  const customer_link = getUrlQuery("customer_link");

  const onSubmit = (data) => {
    const isAnyFieldSelected = Object?.values(data).some((item) => {
      if (!item.size) return false;
      else return true;
    });
    const payload = {
      ...data,
      claim_detail_id: claim_detail_id,
    };
    if (isAnyFieldSelected) {
      createClaimDocument(payload);
    } else {
      methods.setError("atleast_one_field", {
        type: "custom",
        message: "Atleast one field is required",
      });
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Paper
            elevation={0}
            sx={{
              borderRadius: "18px",
              boxShadow: "0 2px 3px 0 rgba(189, 213, 218, 0.57)",
              width: "100%",
            }}
          >
            <Box
              sx={{
                padding: "15px",
              }}
            >
              <AlertMessage
                alert={alertMaster}
                reset={resetMaster}
                isError={isError}
                isSuccess={isSuccess}
              />
              <ClaimCommonDocuments
                title="Upload Documents"
                customerSubmits={true}
              />
              <p
                style={{
                  color: "#d32f2f",
                  fontSize: "12px",
                  marginLeft: "16px",
                  marginTop: "16px",
                }}
              >
                {methods.formState.errors?.atleast_one_field?.message}
              </p>
            </Box>
          </Paper>
          <ClaimButtonWrapper>
            <SubmitButton
              variant="contained"
              type="submit"
              disabled={!customer_link || isLoading || isSuccess}
            >
              Submit
            </SubmitButton>
          </ClaimButtonWrapper>
        </form>
      </FormProvider>
    </Box>
  );
};

export default ClaimCustomerDocumentUploadForm;

const ClaimButtonWrapper = styled(ButtonWrapper)({
  display: "flex",
  gap: "10px",
  justifyContent: "center",
  alignItems: "center",
});
