import React from "react";
import {
  GridChild,
  Heading,
  StyledGrid,
} from "../../masters/components/MasterStyle";
import Docupload from "../../../components/InputComponents/DocUpload";

const ClaimCommonDocuments = ({ title, customerSubmits }) => {
  return (
    <>
      <Heading
        sx={{
          fontSize: "17px",
        }}
        align="left"
      >
        {title ? title : "Documents Collected"}
      </Heading>
      <StyledGrid mt={-1} px={2}>
        <GridChild
          xxs={12}
          xs={12}
          sm={customerSubmits ? 6 : 12}
          md={12}
          lg={customerSubmits ? 6 : 12}
        >
          <Docupload
            label={"Registration Certificate"}
            name="registration_certificate"
          />
        </GridChild>
        <GridChild
          xxs={12}
          xs={12}
          sm={customerSubmits ? 6 : 12}
          md={12}
          lg={customerSubmits ? 6 : 12}
        >
          <Docupload label={"Driving License"} name="driving_license" />
        </GridChild>
        <GridChild
          xxs={12}
          xs={12}
          sm={customerSubmits ? 6 : 12}
          md={12}
          lg={customerSubmits ? 6 : 12}
        >
          <Docupload
            label={"Estimated Certificate"}
            name="estimated_certificate"
          />
        </GridChild>
        <GridChild
          xxs={12}
          xs={12}
          sm={customerSubmits ? 6 : 12}
          md={12}
          lg={customerSubmits ? 6 : 12}
        >
          <Docupload label={"FIR Document"} name="fir_certificate" />
        </GridChild>
        <GridChild
          xxs={12}
          xs={12}
          sm={customerSubmits ? 6 : 12}
          md={12}
          lg={customerSubmits ? 6 : 12}
        >
          <Docupload label={"Other Certificate"} name="other_certificate" />
        </GridChild>
      </StyledGrid>
    </>
  );
};

export default ClaimCommonDocuments;
