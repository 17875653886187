import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function QuoteCheckbox({
  label,
  name,
  onChange,
  value,
  checked,
  size,
  id,
  disabled = false,
}) {
  return (
    <FormGroup>
      <FormControlLabel
        sx={(theme) => ({
          "& .MuiFormControlLabel-label": {
            color: theme.palette.text.secondary,
            fontWeight: "500",
            fontSize: `${size === "small" ? "12px" : "14px"}`,
          },
        })}
        label={label}
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            name={name}
            id={id}
            value={value}
            size={size}
            disabled={disabled}
            sx={{
              py: size === "small" && 0.6,
              m: 0,
            }}
          />
        }
      />
    </FormGroup>
  );
}

export default QuoteCheckbox;
