import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../api/api";
import { useNavigate, useParams } from "react-router";
import { errorHandler } from "../utils/errorHandler";

export const getBookingFields = () => {
  const { data } = useQuery(["booking_fields"], () =>
    api.post("report_configurator/booking_fields/list")
  );
  return {
    bookingFields: data?.data?.return_data,
  };
};

export const getSegments = () => {
  const { data } = useQuery(["segments"], () =>
    api.post("/segment/list", {
      sorting: {
        id: "segment_name",
        value: "asc",
      },
    })
  );
  return {
    allSegments: data?.data?.return_data,
  };
};

export const addReport = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  // const { mutate } = useMutation((data) => {
  //   return api.post("/report/add", data);
  // });
  const { mutate, data, error, reset, isError, isSuccess, isLoading } =
    useMutation((data) => api.post("report_configurator/add", data), {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries(["reportView"]);
          navigate("/reports/view_report");
        }, 3000);
      },
    });
  const errList = errorHandler(error, data);
  return {
    addReportDetails: mutate,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isError: isError,
    isSuccess: isSuccess,
    isLoading: isLoading,
  };
};

export const updateReport = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  // const { mutate } = useMutation((data) => {
  //   return api.post("/report/add", data);
  // });
  const { mutate, data, error, reset, isError, isSuccess, isLoading } =
    useMutation((data) => api.post("report_configurator/update", data), {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries(["reportView"]);
          navigate("/reports/view_report");
        }, 3000);
      },
    });
  const errList = errorHandler(error, data);
  return {
    updateReportDetails: mutate,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isError: isError,
    isSuccess: isSuccess,
    isLoading: isLoading,
  };
};

export const getReportDetail = () => {
  // const { mutate, data } = useMutation((data) => api.post("report_configurator/list", data));
  const { id: report_id } = useParams();
  const { data } = useQuery([report_id], () => {
    return api.post(`report_configurator/list`, {
      report_id,
    });
  });
  return {
    reportDetails: data?.data?.return_data,
  };
};

export const getTransaction = () => {
  const { data } = useQuery(["transaction"], () =>
    api.post("/transaction_stages/list")
  );
  return {
    allTransactions: data?.data?.return_data,
  };
};

export const getReport = () => {
  const { data } = useQuery(["reportView"], () => api.post("view_report/list"));
  return {
    allReport: data?.data?.return_data || [],
  };
};
