import React, { useMemo } from "react";
import {
  ButtonWrapper,
  FormContainer,
  Heading,
  Papers,
} from "../masters/components/MasterStyle";
import { FormProvider, useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import Select from "../../components/InputComponents/Select";
import Buttons from "../../components/BasicFormComponent/Buttons";
import { useGetSortedMaster } from "../../services/master.service";
import IcApiMetricsTable from "./IcApiMetricsTable";
import DateInput from "../../components/InputComponents/DateInput";
import withRouteProtection from "../../hoc/withRouteProtection";
import { useGetIcApiMetricsDetails } from "../../services/auditLog.service";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Navbar from "../../components/Navbar";

const icApiMatricsSearchValidation = yup.object().shape({
  segment_id: yup.string().required("Segment is required"),
  from_date: yup.string().required("From date is required"),
  to_date: yup.string().required("To date is required"),
});

const IcApiMetricsSearch = () => {
  const methods = useForm({
    resolver: yupResolver(icApiMatricsSearchValidation),
  });
  const { table_list: state_items } = useGetSortedMaster({
    master_name: "segment",
    sortBy: "segment_name",
  });
  const { searchApiMetrics, icMetricsData, isLoading } =
    useGetIcApiMetricsDetails();

  const segment_list = useMemo(() => {
    return state_items?.map((item) => ({
      code: item?.segment_id,
      display_name: item?.segment_name,
    }));
  }, [state_items]);

  const onSubmit = (data) => {
    searchApiMetrics(data);
  };
  return (
    <Navbar>
      <FormContainer>
        <Papers elevation={3} sx={{ width: "100%" }}>
          <Heading variant="h5" align="left">
            API metrics
          </Heading>
          {/* {err && <AtleastOnefieldError>{err}</AtleastOnefieldError>} */}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={1} columnSpacing={3} mt={1} rowGap={2}>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <Select
                    name="segment_id"
                    label="Segment"
                    options={segment_list}
                    variant="standard"
                    saveDisplayName={false}
                    required={true}
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <DateInput
                    name="from_date"
                    label="Start Date"
                    variant="standard"
                    required={true}
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <DateInput
                    name="to_date"
                    label="End Date"
                    variant="standard"
                    required={true}
                  />
                </Grid>
              </Grid>
              <ButtonWrapper marginLeft="auto">
                <Buttons type="submit" label="Search"></Buttons>
              </ButtonWrapper>
            </form>
          </FormProvider>
        </Papers>
      </FormContainer>
      {icMetricsData && (
        <IcApiMetricsTable
          icMetricsData={icMetricsData}
          isLoading={isLoading}
        />
      )}
    </Navbar>
  );
};

export default withRouteProtection(IcApiMetricsSearch);
