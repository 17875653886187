/*
- This hook is used to prevent multiple sessions when user is logged in

- Following conditions are checked;
1. user is logged-in 
2. multiple sessions are preset 
3. Broker setting is set to prevent multiple sessions

- TODO: Add specific routes to alow specific pages visibility
*/

import { useEffect, useState } from "react";
import api from "../api/api";

export const useCheckMultiSessionSetting = () => {
  const isUserLoggedIn = localStorage.getItem("token");
  const [isMultipleSession, setIsMultipleSession] = useState(false);
  const [isMultipleSessionAllowed, setIsMultipleSessionAllowed] =
    useState(false);

  async function getBrokerSettingForMultiLogin() {
    api.post("broker/list").then((res) => {
      const brokerSetting = res?.data?.return_data?.[0];
      const _isMultipleSessionAllowed =
        brokerSetting?.allow_multiple_sessions === "Y";
      setIsMultipleSessionAllowed(_isMultipleSessionAllowed);
    });
  }

  getBrokerSettingForMultiLogin();

  useEffect(() => {
    localStorage.openpages = Date.now();
    const checkPageAlreadyOpen = (e) => {
      if (e.key == "openpages") {
        // Listen if anybody else is opening the same page!
        localStorage.page_available = Date.now();
      }
      if (e.key == "page_available") {
        setIsMultipleSession(true);
      }
    };
    window.addEventListener("storage", checkPageAlreadyOpen, false);
    return () => {
      window.removeEventListener("storage", checkPageAlreadyOpen);
    };
  }, []);

  const restrictMultipleSessions =
    isUserLoggedIn && !isMultipleSessionAllowed && isMultipleSession;

  return { restrictMultipleSessions };
};
