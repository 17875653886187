import { useMutation } from "@tanstack/react-query";
import api from "../api/api";
import { convertToFormData } from "../utils/convertToFormData";
import { errorHandler } from "../utils/errorHandler";

export const useBulkUploadFile = () => {
  const { mutate, isError, isSuccess, data, error, reset, isLoading } =
    useMutation((data) => {
      const form_data = convertToFormData(data);
      return api.post("quote/import", form_data);
    });
  const errList = errorHandler(error, data);
  return {
    uploadBulkFile: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    errorMessage: error?.response?.data?.message,
    isLoading: isLoading,
  };
};

export const useBulkUploadExportFile = () => {
  const { mutate, data, isError, isSuccess, isLoading, error, reset } =
    useMutation((data) => api.post("quote/export", data), {
      onSuccess: (res) => {
        window.open(res?.data?.return_data?.path);
      },
    });
  const errList = errorHandler(error, data);
  return {
    bulkUploadExportFile: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    errorMessage: error?.response?.data?.message,
    isLoading: isLoading,
  };
};
