import * as yup from "yup";

const InspectionSchema = yup.object().shape({
  segment: yup.array().nullable().required("Segment is required"),
  inspection_type: yup.string().when(["type"], {
    is: (type) => (type === "inspection" ? true : false),
    then: yup.string().required("Inspection type is required"),
    otherwise: yup.string().nullable(),
  }),
  less_45: yup.string().when(["type"], {
    is: (type) => (type === "waiver" ? true : false),
    then: yup.string().required("Required"),
  }),
  greater_45: yup.string().when(["type"], {
    is: (type) => (type === "waiver" ? true : false),
    then: yup.string().required("Required"),
  }),
  greater_90: yup.string().when(["type"], {
    is: (type) => (type === "waiver" ? true : false),
    then: yup.string().required("Required"),
  }),
});
export default InspectionSchema;
