import React from "react";
import Navbar from "../../../../components/Navbar";
import withRouteProtection from "../../../../hoc/withRouteProtection";
import ClaimIntimation from "./components/ClaimIntimation";

const ClaimSpotIntimation = () => {
  return (
    <Navbar>
      <ClaimIntimation />
    </Navbar>
  );
};

export default withRouteProtection(ClaimSpotIntimation);
