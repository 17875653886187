import * as yup from "yup";

export const estimationDetailsSchema = yup.object().shape({
  idv: yup.string().nullable().required("IDV is required"),
  vehicle_premium: yup
    .string()
    .nullable()
    .required("Vehicle premium is required"),
  voluntary_deductible: yup
    .string()
    .nullable()
    .required("Voluntary deductable is required"),
  loss_type: yup.string().nullable().required("Loss type is required"),
  labour_cost: yup.string().nullable().required("Labour cost is required"),
  parts_cost: yup.string().nullable().required("Parts cost is required"),
  metal_cost: yup.string().nullable().required("Metal cost is required"),
  paint_cost: yup.string().nullable().required("Paint cost is required"),
  total_other_cost: yup
    .string()
    .nullable()
    .required("Total other cost is required"),
  total_estimation: yup
    .string()
    .nullable()
    .required("Total other cost is required")
    .test(
      "total_estimation",
      "Please provide proper total estimation",
      function (value) {
        if (!value) return true;
        return Number(value) > 0;
      }
    )
    .test(
      "total_estimation",
      "Total estimation should not exceed the IDV price",
      function (value) {
        if (!value) return true;
        const { idv } = this.parent;
        return Number(value) <= Number(idv);
      }
    ),
});
