import { yupResolver } from "@hookform/resolvers/yup";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { Box, Grid } from "@mui/material";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import Buttons from "../../../components/BasicFormComponent/Buttons";
import Select from "../../../components/InputComponents/Select";
import TextInput from "../../../components/InputComponents/TextInput";
import withRouteProtection, {
  AuthContext,
} from "../../../hoc/withRouteProtection";
import { useGetSortedMaster } from "../../../services/master.service";
import { dateFormat } from "../../../utils/minDateForEndDate";
import MasterTable from "../../masters/MasterTable/MasterTable";
import {
  AtleastOnefieldError,
  ButtonWrapper,
  Papers,
} from "../../masters/components/MasterStyle";
import { ResetButton } from "../../servicing/cancellation/CancellationSearch/CancellationSearchStyle";
import {
  Heading,
  HeadingLabel,
} from "../DiscountConfigurator/DiscountConfiguratorStyle";
import Navbar from "../../../components/Navbar";

const reportConfiguratorSchema = yup.object().shape({
  effective_date_from: yup
    .string()
    .test(
      "check_dates",
      "Effective from date should be equal or before effective to date",
      function (date) {
        if (!this.parent.effective_date_to) return true;
        return moment(date, dateFormat).isBefore(
          moment(this.parent.effective_date_to, dateFormat).add(1, "day")
        );
      }
    ),
  effective_date_to: yup
    .string()
    .test(
      "check_dates",
      "Effective to date should be equal or after effective from date",
      function (date) {
        if (!this.parent.effective_date_from) return true;
        return moment(date, dateFormat).isAfter(
          moment(this.parent.effective_date_from, dateFormat).subtract(1, "day")
        );
      }
    ),
});

function ReportConfiguratorList() {
  const { writable } = useContext(AuthContext);
  const [customSearch, setCustomSearch] = useState({});
  const methods = useForm({
    resolver: yupResolver(reportConfiguratorSchema),
    defaultValues: {
      report_name: "",
      effective_date_from: "",
      effective_date_to: "",
      segment_id: "",
    },
  });
  const { table_list: segment_id } = useGetSortedMaster({
    master_name: "segment",
    sortBy: "segment_name",
  });

  const segment_list = useMemo(() => {
    return segment_id.map((item) => {
      return {
        code: item.segment_id,
        display_name: item.segment_name,
      };
    });
  }, [segment_id]);
  const handleReset = (e) => {
    setCustomSearch({});
    e.preventDefault();
    methods.reset({
      report_name: "",
      segment_id: "",
    });
  };

  const onSubmit = (data) => {
    const isAnyFieldSelected = Object?.values(data).some((item) => item !== "");
    if (isAnyFieldSelected) {
      setCustomSearch({ ...data });
    } else {
      methods.setError("atleast_one_field", {
        type: "custom",
        message: "Atleast one field is required",
      });
    }
  };

  const err = methods.formState.errors?.atleast_one_field?.message;
  return (
    <Navbar>
      <Box>
        <Papers elevation={3}>
          <Heading variant="h5" align="left">
            <HeadingLabel variant="h5" align="left">
              Report Configurator
            </HeadingLabel>
          </Heading>
          {err && <AtleastOnefieldError>{err}</AtleastOnefieldError>}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={1} columnSpacing={3} my={1}>
                <Grid item xxs={12} xs={6} md={4} lg={3}>
                  <TextInput
                    label="Report Name"
                    name="report_name"
                    // onInput={allowOnlyAlphabetsNumberSpace}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Grid>
                <Grid item xxs={12} xs={6} md={4} lg={3}>
                  <Select
                    label="Segment"
                    name="segment_id"
                    options={segment_list}
                    saveDisplayName={false}
                  />
                </Grid>
                {/* <Grid item xxs={12} xs={6} md={4} lg={3}>
                  <DateInput
                    label="Effective From Date"
                    name="effective_date_from"
                    // minDate={moment()}
                  />
                </Grid> */}
                {/* <Grid item xxs={12} xs={6} md={4} lg={3}>
                  <DateInput
                    label="Effective To Date"
                    name="effective_date_to"
                    // minDate={moment()}
                  />
                </Grid> */}
              </Grid>
              <ButtonWrapper
                style={{
                  gap: "10px",
                  margin: 0,
                }}
              >
                <Buttons type="submit" label="Search" />
                <ResetButton onClick={(e) => handleReset(e)}>
                  <ReplayRoundedIcon />
                  Reset
                </ResetButton>
              </ButtonWrapper>
            </form>
          </FormProvider>
        </Papers>
      </Box>
      <MasterTable
        master_name={"report_configurator"}
        writable={writable}
        customSearch={customSearch}
      />
    </Navbar>
  );
}

export default withRouteProtection(ReportConfiguratorList);
