import { Grid } from "@mui/material";
import React from "react";
import TextInput from "../../../../../../components/InputComponents/TextInput";
import { allowOnlyNumbers } from "../../../../../../InputUtils/onInput";
import Select from "../../../../../../components/InputComponents/Select";
import { lossType } from "../../../../../../constants/options";

function EstimationForm() {
  return (
    <Grid container spacing={2} mt={0} px={2}>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="IDV"
          name="idv"
          required
          standard
          readOnly
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Vehicle Premium"
          name="vehicle_premium"
          standard
          required
          readOnly
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Voluntary Deductible"
          name="voluntary_deductible"
          required
          standard
          readOnly
          inputProps={{ maxLength: 10 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <Select
          label="Loss Type"
          name="loss_type"
          options={lossType}
          required
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Labour Cost"
          name="labour_cost"
          required
          standard
          inputProps={{ maxLength: 8 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Parts Cost"
          name="parts_cost"
          required
          standard
          inputProps={{ maxLength: 8 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Metal Cost"
          name="metal_cost"
          required
          standard
          inputProps={{ maxLength: 8 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Paint Cost"
          name="paint_cost"
          required
          standard
          inputProps={{ maxLength: 8 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Total Other Cost"
          name="total_other_cost"
          required
          standard
          inputProps={{ maxLength: 10 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Total Estimation"
          otherHelperText="(Sum of labour cost, parts cost, metal cost, paint cost and total other cost)"
          name="total_estimation"
          required
          standard
          readOnly
          inputProps={{ maxLength: 10 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
    </Grid>
  );
}

export default EstimationForm;
