import React from "react";
import {
  SummaryChild,
  SummaryHeading,
  SummarySubContainer,
  SummarySubHeading,
} from "../../../../summary/summarystyle";
import { StyledGrid } from "../../../../masters/components/MasterStyle";

function ContactDetails({ claimDetails }) {
  return (
    <SummarySubContainer>
      <StyledGrid container columns={12} spacing={0}>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Claim Intimated By
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.claim_intimated_by}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Intimator Name</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.intimator_name}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Intimator Mobile No.
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.intimator_mobile_no}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Customer&apos;s Current Mobile No.
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.customer_current_mobile_no}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Customer&apos;s Current Email ID
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.customer_current_email_id}
          </SummarySubHeading>
        </SummaryChild>

        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Driver Name</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.driver_name}
          </SummarySubHeading>
        </SummaryChild>
      </StyledGrid>
    </SummarySubContainer>
  );
}

export default ContactDetails;
