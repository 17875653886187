import React, { useContext, useEffect, useState, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  AtleastOnefieldError,
  ButtonWrapper,
  FormContainer,
  Heading,
  Papers,
} from "../masters/components/MasterStyle";
import { Grid } from "@mui/material";
import TextInput from "../../components/InputComponents/TextInput";
import Select from "../../components/InputComponents/Select";
import DateInput from "../../components/InputComponents/DateInput";
import { allowOnlyNumbers } from "../../InputUtils/onInput";
import { statusOptions2 } from "../../constants/options";
import Buttons from "../../components/BasicFormComponent/Buttons";
import MasterTable from "../masters/MasterTable/MasterTable";
import withRouteProtection, {
  AuthContext,
} from "../../hoc/withRouteProtection";
import Radio from "../../components/InputComponents/Radio";
import { ClaimActionButton } from "../Claim/claimStyledComponents";
import { useNavigate } from "react-router";
import { useGetIcApiLogDownloadLink } from "../../services/auditLog.service";
import { useGetSortedMaster } from "../../services/master.service";
import { downloadFile } from "../../utils/downloadFile";
import Navbar from "../../components/Navbar";

const IcApiLogSearch = () => {
  const [customSearch, setCustomSearch] = useState({});
  const { writable } = useContext(AuthContext);
  const navigate = useNavigate();
  const { generateDownloadLink, downloadLink } = useGetIcApiLogDownloadLink();

  useEffect(() => {
    downloadLink && downloadFile(downloadLink, false, true);
  }, [downloadLink]);

  const methods = useForm({
    defaultValues: {},
  });

  const { table_list: state_items } = useGetSortedMaster({
    master_name: "segment",
    sortBy: "segment_name",
  });
  const segment_list = useMemo(() => {
    return state_items?.map((item) => ({
      code: item?.segment_id,
      display_name: item?.segment_name,
    }));
  }, [state_items]);

  const { table_list: ic_items } = useGetSortedMaster({
    master_name: "insurance",
  });
  const ic_list = useMemo(() => {
    return ic_items?.map((item) => ({
      code: item?.insurance_id,
      display_name: item?.insurance_name,
    }));
  }, [ic_items]);

  const onSubmit = (data) => {
    const isAnyFieldSelected = Object?.values(data).some((item) => item !== "");
    if (isAnyFieldSelected) {
      setCustomSearch(data);
    } else {
      methods.setError("atleast_one_field", {
        type: "custom",
        message: "Atleast one field is required",
      });
    }
  };
  const err = methods.formState.errors?.atleast_one_field?.message;

  return (
    <Navbar>
      <FormContainer>
        <Papers elevation={3} sx={{ width: "100%" }}>
          <Heading variant="h5" align="left">
            API logs
          </Heading>
          {err && <AtleastOnefieldError>{err}</AtleastOnefieldError>}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={1} columnSpacing={3} mt={1} rowGap={2}>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <Select
                    name="segment"
                    label="Segment"
                    options={segment_list}
                    variant="standard"
                    saveDisplayName={false}
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Trace Id"}
                    name={"trace_id"}
                    inputProps={{ maxLength: 10 }}
                    onInput={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    standard
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Alias"}
                    name={"alias"}
                    inputProps={{ maxLength: 10 }}
                    standard
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Mobile No."}
                    name={"mobile_no"}
                    inputProps={{ maxLength: 10 }}
                    onInput={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    standard
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Policy No."}
                    name={"policy_no"}
                    inputProps={{ maxLength: 10 }}
                    onInput={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    standard
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Proposal No."}
                    name={"proposal_no"}
                    // inputProps={{ maxLength: 10 }}
                    // onInput={(e) => {
                    //   allowOnlyNumbers(e);
                    // }}
                    standard
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput label={"Email Id"} name={"email_id"} standard />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Registration No."}
                    name={"registration_no"}
                    // inputProps={{ maxLength: 10 }}
                    // onInput={(e) => {
                    //   allowOnlyNumbers(e);
                    // }}
                    standard
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Inspection No."}
                    name={"inspection_no"}
                    // inputProps={{ maxLength: 10 }}
                    // onInput={(e) => {
                    //   allowOnlyNumbers(e);
                    // }}
                    standard
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <DateInput
                    name="from_date"
                    label="Start Date"
                    variant="standard"
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <DateInput
                    name="to_date"
                    label="End Date"
                    variant="standard"
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <Select
                    name="ic"
                    label="Insurance Company"
                    options={ic_list}
                    variant="standard"
                    saveDisplayName={false}
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <TextInput
                    label={"Transaction Type"}
                    name={"transaction_type"}
                    standard
                  />
                </Grid>
                <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                  <Radio
                    name="with_headers"
                    label="With Headers?"
                    color="opposite"
                    required={true}
                    options={statusOptions2}
                  />
                </Grid>
              </Grid>
              <ButtonWrapper marginLeft="auto">
                <Buttons type="submit" label="Search"></Buttons>
              </ButtonWrapper>
            </form>
          </FormProvider>
        </Papers>
      </FormContainer>

      <MasterTable
        master_name={"http_logs"}
        writable={writable}
        columnFilters={Object.keys(customSearch).map((item) => {
          return {
            id: item,
            value: customSearch[item],
          };
        })}
        customRowAction={({ row }) =>
          writable && (
            <>
              <ClaimActionButton
                onClick={() => {
                  navigate(`./add/${row?.original?.http_logs_id}`);
                }}
              >
                View
              </ClaimActionButton>
              <ClaimActionButton
                onClick={() => {
                  generateDownloadLink({
                    http_logs_id: row?.original?.http_logs_id,
                    download: true,
                  });
                }}
              >
                Download
              </ClaimActionButton>
            </>
          )
        }
      />
    </Navbar>
  );
};

export default withRouteProtection(IcApiLogSearch);
