import { createSlice } from "@reduxjs/toolkit";

/**
 * 
 Note:
 This slice logic is moved to quote slice with respective payload 
 * 
 */

const initialState = {
  without_ncb: true,
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    handleModal: (state, action) => {
      if (state[action.payload]) {
        state[action.payload] = !state[action.payload];
      } else {
        state[action.payload] = true;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { handleModal } = dialogSlice.actions;

export default dialogSlice.reducer;

export const modalCheckedValues = (state) => state.dialog;
