import { Alert, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const AlertMessage = ({
  alert,
  reset,
  isSuccess,
  isLogin,
  disableAlertClose = false,
  forcedClose = false, // closes alert component without reset function
}) => {
  const [alertMsg, setAlerMsg] = useState([]);
  const alertRef = useRef();
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    if (alert) {
      setAlerMsg(alert.filter((item) => !!item));
    }
  }, [alert]);

  useEffect(() => {
    if (alertMsg?.length && !isLogin && !forcedClose) {
      alertRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [alertMsg?.length, isLogin]);
  return (
    <>
      {!!alertMsg?.length && isShow && (
        <Box
          ref={alertRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            margin: "10px 0",
            position: "relative",
            borderRadius: "10px",
            padding: "5px 5px",
            bgcolor: !isSuccess ? "rgb(253,237,237)" : "rgb(237,247,237)",
          }}
          spacing={0}
        >
          {!disableAlertClose && (
            <HighlightOffIcon
              sx={{
                zIndex: 1,
                position: "absolute",
                top: "8px",
                right: "6px",
                fontSize: "19px",
                color: !isSuccess ? "rgb(215,65,65)" : "rgb(66,137,70)",
                cursor: "pointer",
              }}
              onClick={() => {
                if (forcedClose) {
                  setAlerMsg([]);
                } else if (isLogin) {
                  setIsShow((prev) => !prev);
                } else {
                  reset();
                }
              }}
            />
          )}
          {alertMsg?.map((err, index) => {
            if (err?.length >= 1)
              return (
                <Alert
                  key={index}
                  severity={!isSuccess ? "error" : "success"}
                  sx={{
                    display: "flex",
                    padding: "0px 2px",
                    width: "100%",
                    alignItems: "center",
                    height: "auto",
                    lineHeight: "16px",
                    //   border: "1px solid black",
                    bgcolor: "transparent",
                    margin: "2px 0",
                    "& .MuiAlert-icon": {
                      fontSize: "19px",
                      position:
                        alertMsg?.length === 1 ? "absolute" : "relative",
                      top: alertMsg?.length === 1 ? "2px" : "",
                      left: alertMsg?.length === 1 ? "6px" : "",
                    },
                    "& .MuiAlert-message": {
                      padding: alertMsg?.length === 1 ? "5px 25px" : "0",
                    },
                  }}
                  // onClose={() => {
                  //   dispatch(removeError({ key: id || "test" }));
                  // }}
                >
                  {err}
                </Alert>
              );
          })}
          <div className="alertProgressBar"></div>
        </Box>
      )}
    </>
  );
};

export default AlertMessage;
