import React from "react";
import { Navigate, useParams } from "react-router";
import { useGetUser } from "../services/AuthenticationServices";

const withLoginChecks =
  (Component) =>
  ({ ...props }) => {
    const {
      isAuthorized,
      isLoading,
      accessControlData,
      data,
      isTransactionBlocked,
    } = useGetUser();
    const { token } = useParams();

    if (!isLoading && isAuthorized && data?.is_first_login !== null && !token) {
      return <Navigate to="/" />;
    }

    return <Component {...props} />;
  };

export default withLoginChecks;
