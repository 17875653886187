import { useEffect } from "react";
import { useNavigate } from "react-router";
import api from "../api/api";
import { useGetUser } from "../services/AuthenticationServices";
import { useGetBrokeSettings } from "../services/role.service";

export const useSessionTimout = () => {
  // to prevent session timeout during development
  if (process.env.NODE_ENV !== "production") return;

  const { isAuthorized } = useGetUser();
  const navigate = useNavigate();

  const { broker_settings } = useGetBrokeSettings();
  const [setting] = broker_settings;

  const default_duration = 20 * 1000 * 60; // 20 minutes

  const session_timeout_duration =
    +setting?.session_timeout_duration * 1000 * 60 || default_duration;

  let timeout = null;
  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      if (isAuthorized) {
        api.post("/user/logout");
        navigate("/session_timeout");
      }
    }, session_timeout_duration);
  };

  const onActivity = () => {
    restartAutoReset();
  };
  useEffect(() => {
    // initiate timeout
    restartAutoReset();

    // listen for mouse events
    window.addEventListener("mousemove", onActivity);
    window.addEventListener("keydown", onActivity);
    // cleanup
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener("mousemove", onActivity);
        window.removeEventListener("keydown", onActivity);
      }
    };
    // Initally empty array as we wanted it to run it once.
    // But, timer value coming from api hence, re-running with fresh value
  }, [session_timeout_duration]);
};
