import moment from "moment";
import * as yup from "yup";

export const claimSettlementSchema = yup.object().shape({
  payment_mode: yup.string().nullable().required("Payment mode is required"),
  payment_date: yup
    .string()
    .nullable()
    .required("Payment date is required")
    .test("check_future_date", "Enter valid payment date", function (date) {
      return moment(date).isBefore(moment());
    }),
  payee_type: yup.string().nullable().required("Payee type is required"),
  workshop_code: yup.string().nullable().required("Workshop code is required"),
  workshop_pincode: yup
    .string()
    .nullable()
    .required("Workshop pincode is required"),
  workshop_state: yup
    .string()
    .nullable()
    .required("Workshop state is required"),
  workshop_city: yup.string().nullable().required("Workshop city is required"),
  settlement_mode: yup
    .string()
    .nullable()
    .required("Settlement mode is required"),
  payment_type: yup.string().nullable().required("Payer type is required"),
  payee_name: yup.string().nullable().required("Payer name is required"),
  claim_settled_amount: yup
    .string()
    .nullable()
    .required("Settled amount is required"),
  payment_transaction_id: yup
    .string()
    .nullable()
    .required("Payment transaction ID is required"),
  nominee_name: yup.string().nullable().required("Nominee name is required"),
});
