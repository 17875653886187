import { Grid } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import AlertMessage from "../../../../../../../components/Alert/AlertMessage";
import TextArea from "../../../../../../../components/BasicFormComponent/TextArea";
import { SubmitButton } from "../../../../../../../components/InputComponents/InputComponents.style";
import Radio from "../../../../../../../components/InputComponents/Radio";
import { approval_status_temp } from "../../../../../../../constants/options";
import { useApproveClaim } from "../../../../../../../services/claims.service";
import { ButtonWrapper } from "../../../../../../masters/components/MasterStyle";
import {
  ClaimHeading,
  MainPaperContainer,
} from "../../../../../styles/claim.styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { claimApprovalSchema } from "./validations/claimApproval.valiations";

function ApprovalForm() {
  const { claim_detail_id } = useParams("claim_detail_id");

  const { approveClaim, isSuccess, alertMaster, resetMaster, isError } =
    useApproveClaim();

  const methods = useForm({
    resolver: yupResolver(claimApprovalSchema),
    defaultValues: {
      //Note: Approval status not given intentionally
      approval_remark: "",
    },
  });

  const onSubmit = (data) => {
    data.claim_detail_id = claim_detail_id;
    approveClaim(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <MainPaperContainer>
          <AlertMessage
            alert={alertMaster}
            reset={resetMaster}
            isError={isError}
            isSuccess={isSuccess}
          />
          <ClaimHeading>Claim Approval</ClaimHeading>
          <Grid container spacing={2} mt={0} px={2}>
            <Grid item xxs={12} md={5}>
              <Radio
                label="Status"
                name="approval_status"
                color="opposite"
                options={approval_status_temp}
                required
                row
              />
            </Grid>
            <Grid item xxs={12} md={7}>
              <TextArea
                label="Remark"
                name="approval_remark"
                standard
                maxLength={300}
              />
            </Grid>
          </Grid>
          <ButtonWrapper>
            <SubmitButton variant="contained" type="submit">
              Submit
            </SubmitButton>
          </ButtonWrapper>
        </MainPaperContainer>
      </form>
    </FormProvider>
  );
}

export default ApprovalForm;
