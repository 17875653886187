import { Box } from "@mui/material";
import TickerCard from "./TickerCard";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useGetNotifications } from "../../../../services/dashboard.service";
import { useEffect, useRef } from "react";
const FloatingTickerCard = ({ isTickerOpen, setTickerOpen }) => {
  const { ticker, isLoading } = useGetNotifications();
  const firstMount = useRef(true);
  const shouldRenderFloatingTickerCard = ticker?.filter(
    (item) => item?.display_page === "displayonallpages"
  );

  useEffect(() => {
    if (!isLoading) {
      if (
        localStorage.getItem("tickerClosed") === "true" &&
        !firstMount.current
      )
        setTickerOpen(true);
      else {
        firstMount.current = false;
      }
    }
  }, [ticker.length, isLoading]);
  return shouldRenderFloatingTickerCard?.length > 0 ? (
    <Box
      sx={{
        position: "fixed",
        left: "0",
        top: "200px",
        zIndex: "3",
      }}
    >
      <Box
        sx={{
          background: "#4f6781",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
          color: "white",
          width: "250px",
          transition: "left 0.2s",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          gap: "20px",
          left: isTickerOpen ? "0" : "-210px",
          "& p": {
            width: "210px",
            textAlign: "center",
          },
        }}
        onClick={() => {
          setTickerOpen(!isTickerOpen);
          if (isTickerOpen) {
            localStorage.setItem("tickerClosed", "true");
          }
        }}
      >
        <p>Updates</p>
        <ChevronRightIcon
          sx={{
            transform: isTickerOpen && "rotate(-180deg)",
            transition: "transform 0.1s",
          }}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: "18px",
          width: "240px",
          transition: "all 0.2s",
          transform: isTickerOpen ? "translateX(0)" : "translateX(-100%)",
        }}
      >
        <TickerCard />
      </Box>
    </Box>
  ) : null;
};
export default FloatingTickerCard;
