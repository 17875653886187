import { Box, styled } from "@mui/material";
import React from "react";
import { AutoSizer, Table, Column } from "react-virtualized";

const CustomTable = ({
  column_head,
  row_data,
  customRowAction,
  customColumnAction,
  staticHeight = 310,
  staticPadding = 20,
}) => {
  // for table

  return (
    <>
      <TableWrapper staticPadding={staticPadding} staticHeight={staticHeight}>
        <AutoSizer>
          {({ width }) => (
            <Table
              rowClassName="table-row"
              width={width}
              height={staticHeight}
              headerHeight={60}
              rowHeight={50}
              rowCount={row_data?.length}
              noRowsRenderer={() => (
                <NoContentWrapper>No record found</NoContentWrapper>
              )}
              rowGetter={({ index }) => row_data[index] || []}
            >
              {(column_head || [])?.map((i) => (
                <Column
                  key={i?.accessorKey}
                  label={i?.header}
                  dataKey={i?.accessorKey}
                  width={width}
                  cellRenderer={i?.cellRenderer}
                  disableSort
                />
              ))}
              {customRowAction && (
                <Column
                  label="Action"
                  dataKey="action"
                  width={width * 0.25}
                  cellRenderer={customRowAction}
                  headerRenderer={customColumnAction && customColumnAction}
                />
              )}
            </Table>
          )}
        </AutoSizer>
      </TableWrapper>
    </>
  );
};

export default CustomTable;

const TableWrapper = styled(Box)(({ staticPadding, staticHeight }) => ({
  boxShadow: "0 20px 45px 0 rgba(189, 213, 218, 0.57)",
  borderRadius: "20px",
  backgroundColor: "white",
  padding: staticPadding,
  width: "100%",
  height: staticHeight + 2 * staticPadding,
}));
const NoContentWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  height: "100%",
  margin: "auto",
  color: "grey",
}));
