import { yupResolver } from "@hookform/resolvers/yup";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import AlertMessage from "../../../../../../../../components/Alert/AlertMessage";
import { SubmitButton } from "../../../../../../../../components/InputComponents/InputComponents.style";
import {
  useGetPolicyDetailsRegistration,
  useSendClaimInvoiceDetails,
} from "../../../../../../../../services/claims.service";
import {
  AtleastOnefieldError,
  ButtonWrapper,
} from "../../../../../../../masters/components/MasterStyle";
import {
  ClaimHeading,
  MainPaperContainer,
} from "../../../../../../styles/claim.styles";
import ClaimInvoiceFormFields from "../components/ClaimInvoiceFormFields";
import { claimInvoiceSchema } from "../validations/claimInvoice.validations";
import { toast } from "react-toastify";

function ClaimInvoiceForm() {
  const { claim_detail_id } = useParams("claim_detail_id");

  const { policy_details_list: claimDetailsFromClaimId } =
    useGetPolicyDetailsRegistration(claim_detail_id);

  const claim_details_from_claim_id = claimDetailsFromClaimId?.[0];

  const {
    sendClaimInvoiceDetails,
    isSuccess,
    alertMaster,
    resetMaster,
    isError,
  } = useSendClaimInvoiceDetails();

  const previousSelectedValues = {
    claim_invoice_no: claim_details_from_claim_id?.claim_invoice_no || "",
    claim_invoice_date: claim_details_from_claim_id?.claim_invoice_date || "",
    claim_invoice_amount:
      claim_details_from_claim_id?.claim_invoice_amount || "",
    claim_invoice_remark:
      claim_details_from_claim_id?.claim_invoice_remark || "",
  };

  const isPreviousDetailsAvailable = Object?.values(
    previousSelectedValues
  ).some((item) => !!item);

  const methods = useForm({
    resolver: yupResolver(claimInvoiceSchema({ isPreviousDetailsAvailable })),
    defaultValues: {
      claim_invoice_no: claim_details_from_claim_id?.claim_invoice_no || "",
      claim_invoice_date: claim_details_from_claim_id?.claim_invoice_date || "",
      claim_invoice_amount:
        claim_details_from_claim_id?.claim_invoice_amount || "",
      claim_invoice_remark:
        claim_details_from_claim_id?.claim_invoice_remark || "",
    },
  });

  const {
    claim_invoice_no,
    claim_invoice_date,
    claim_invoice_amount,
    claim_invoice_remark,
    claim_invoice_document,
  } = methods.getValues();

  const isDocumentSelected = claim_invoice_document?.size > 0;

  const enteredValues = {
    claim_invoice_no,
    claim_invoice_date,
    claim_invoice_amount,
    claim_invoice_remark,
  };

  const onSubmit = (data) => {
    const isSameAsPreviousValues = isEqual(
      previousSelectedValues,
      enteredValues
    );
    if (
      isSameAsPreviousValues &&
      !isDocumentSelected &&
      isPreviousDetailsAvailable
    ) {
      toast.info("No details have been changed!");
    }

    const payload = {
      ...data,
      claim_detail_id,
      claim_stage: claim_details_from_claim_id?.claim_stage,
    };
    sendClaimInvoiceDetails(payload);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <MainPaperContainer>
          <ClaimHeading>Claim Invoice</ClaimHeading>
          <AlertMessage
            alert={alertMaster}
            reset={resetMaster}
            isError={isError}
            isSuccess={isSuccess}
          />
          <ClaimInvoiceFormFields />
          <ButtonWrapper>
            <SubmitButton variant="contained" type="submit">
              Submit
            </SubmitButton>
          </ButtonWrapper>
        </MainPaperContainer>
      </form>
    </FormProvider>
  );
}

export default ClaimInvoiceForm;
