import React, { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  ExpiryDayContainer,
  ExpiryDayField,
  NewInput,
  RadioContainer,
  RadioTabInspection,
  RadioTabWaiver,
} from "./Inspection.style";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "../../../../../components/InputComponents/Select";
import { Grid } from "@mui/material";
import Radio from "../../../../../components/InputComponents/Radio";
import MultiSelect from "../../../../../components/InputComponents/MultiSelect";
import MasterFormWrapper from "../../../../masters/components/MasterFormWrapper/MasterFormWrapper";
import { getSegments } from "../../../../../services/reportConfig.service";
import {
  docDaysoption,
  inspectionTypeOptions,
  statusOptions2,
} from "../../../../../constants/options";
import { allowOnlyNumbers } from "../../../../../InputUtils/onInput";
import validation from "./validations";
import ICFormStepper from "../../components/ICFormStepper";
import { useSendIcDetails } from "../../../../../services/icConfigurator.service";

function Inspection({ values, isBrokerLogin }) {
  const methods = useForm({
    resolver: yupResolver(validation),
    defaultValues: {
      addon_inspection: "N",
      less_45: "",
      greater_45: "",
      greater_90: "",
      inspection_doc_days: "1",
      ...values,
      insurance_company: values?.ic_id || "",
      segment: values?.segment_id || "",

      type: values?.ic_inspection_type || "inspection",
    },
  });

  useEffect(() => {
    methods.reset({
      less_45: "",
      greater_45: "",
      greater_90: "",
      inspection_doc_days: "1",
      addon_inspection: "N",
      ...values,
      insurance_company: values?.ic_id || "",
      segment: values?.segment_id || "",
      segment_data: values?.segment_data || values?.segment_name,
      type: values?.ic_inspection_type || "inspection",
    });
  }, [values]);
  const type = methods.watch("type");

  const { allSegments } = getSegments();

  const segment_list = useMemo(() => {
    return allSegments?.map((item) => {
      return {
        code: item?.segment_id,
        display_name: item?.segment_name,
      };
    });
  }, [allSegments]);

  const { sendIcDetails, isSuccess, alertMaster, isError } =
    useSendIcDetails("ic_inspection");

  const onSubmit = (data) => {
    data.type = type;
    data.addon_inspection = data.addon_inspection ? "Y" : "N";
    sendIcDetails(data);
  };

  useEffect(() => {
    if (isSuccess) {
      methods.reset({
        insurance_company: "",
        segment: "",
        inspection: "",
        less_45: "",
        greater_45: "",
        greater_90: "",
        addon_inspection: "N",
        type: "inspection",
      });
    }
  }, [isSuccess]);

  return (
    <FormProvider {...methods}>
      <MasterFormWrapper
        title={"Insurance Company Details"}
        description={" Add IC Inspection Master Details"}
        onSubmit={onSubmit}
        alert={alertMaster}
        isError={isError}
        isSuccess={isSuccess}
        additionalContent={<ICFormStepper isBrokerLogin={isBrokerLogin} />}
        showSkipButton
      >
        <Grid
          item
          xxs={12}
          xs={12}
          md={12}
          lg={12}
          style={{ marginBottom: "15px" }}
        >
          <RadioContainer>
            <RadioTabInspection
              id={"type_inspection"}
              type={type}
              onClick={() => {
                methods.setValue("type", "inspection");
              }}
            >
              Inspection
            </RadioTabInspection>
            <RadioTabWaiver
              id={"type_waiver"}
              type={type}
              onClick={() => {
                methods.setValue("type", "waiver");
              }}
            >
              Waiver
            </RadioTabWaiver>
          </RadioContainer>
        </Grid>

        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <MultiSelect
            label="Segment"
            name="segment"
            required={true}
            options={segment_list}
          />
        </Grid>

        {type === "inspection" ? (
          <>
            <Grid item xxs={12} xs={6} md={6} lg={4}>
              <Select
                name={"inspection_type"}
                label={"Inspection Type"}
                required={true}
                options={inspectionTypeOptions}
              />
            </Grid>
            <Grid item xxs={12} xs={6} md={6} lg={4}>
              <Select
                name="inspection_doc_days"
                label={"Inspection Doc Days"}
                options={[...docDaysoption]}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xxs={12} xs={6} md={6} lg={12}>
              <ExpiryDayContainer>
                Break in under 45 days: T +
                <ExpiryDayField>
                  <NewInput
                    id={"less_45"}
                    {...methods.register("less_45")}
                    standard
                    required={true}
                    inputProps={{
                      maxLength: 1,
                    }}
                    onInput={allowOnlyNumbers}
                  />
                </ExpiryDayField>
                days
              </ExpiryDayContainer>
            </Grid>
            <Grid item xxs={12} xs={6} md={6} lg={12}>
              <ExpiryDayContainer>
                Break in between 45 and 90 days: T +
                <ExpiryDayField>
                  <NewInput
                    id={"greater_45"}
                    {...methods.register("greater_45")}
                    standard
                    inputProps={{
                      maxLength: 1,
                    }}
                    onInput={allowOnlyNumbers}
                  />
                </ExpiryDayField>
                days
              </ExpiryDayContainer>
            </Grid>
            <Grid item xxs={12} xs={6} md={6} lg={12}>
              <ExpiryDayContainer>
                Break in above 90 days: T +
                <ExpiryDayField>
                  <NewInput
                    id={"greater_90"}
                    {...methods.register("greater_90")}
                    standard
                    required={true}
                    inputProps={{
                      maxLength: 1,
                    }}
                    onInput={allowOnlyNumbers}
                  />
                </ExpiryDayField>
                days
              </ExpiryDayContainer>
            </Grid>
          </>
        )}

        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <Radio
            label="Addon Inspection"
            name="addon_inspection"
            row
            color="opposite"
            required={true}
            options={statusOptions2}
          />
        </Grid>
      </MasterFormWrapper>
    </FormProvider>
  );
}

export default Inspection;
