import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import Slider from "react-slick";
import { useGetNotifications } from "../../../../services/dashboard.service";
import {
  QuickAccessMessage,
  QuickAccessTitleContainer,
} from "../../Dashboard.style";
import theme from "../../../../styles/Theme";

const TickerCard = ({ showUpdatesLabel }) => {
  const { ticker } = useGetNotifications();
  const location = useLocation();
  const shouldRenderTickerCard = ticker && ticker.length > 0;

  const showOnDashboard = ticker.filter(
    (item) => item?.display_page === "dashboard"
  );

  const showOnAllPages = ticker.filter(
    (item) => item?.display_page === "displayonallpages"
  );

  const showOnBoth = [...showOnDashboard, ...showOnAllPages];
  const isOnDashboard = location.pathname === "/dashboard";
  const numberOfTickersToShow = showOnDashboard?.length > 1 ? 2 : 1;

  const settings = {
    dots: false,
    infinite: true,
    speed: 1800,
    slidesToShow: isOnDashboard ? numberOfTickersToShow : showOnAllPages.length,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
    vertical: true,
    arrows: false,
  };

  const shouldDisplayOnDashboard = location.pathname === "/dashboard";

  let itemsToMap = shouldDisplayOnDashboard ? showOnBoth : showOnAllPages;

  return shouldRenderTickerCard ? (
    <>
      {showUpdatesLabel && (
        <>
          <QuickAccessTitleContainer>
            <Typography variant="h6">Updates</Typography>
          </QuickAccessTitleContainer>
        </>
      )}
      <Slider {...settings}>
        {itemsToMap.map((item, index) => (
          <div style={{ minHeight: "50%" }} key={index}>
            <div
              elevation={3}
              style={{
                height: "70px",
                display: "flex",
                alignItems: "center",
                padding: "8px",
                marginBottom: "5px",
                borderRadius: "7px",
                backgroundColor: "#fff",
                boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 4px",
              }}
            >
              <Box
                style={{
                  borderRadius: "2.5px",
                  height: "10px",
                  width: "5px",
                  backgroundColor:
                    item?.priority === "low"
                      ? "yellow"
                      : item?.priority === "medium"
                      ? "green"
                      : item?.priority === "high"
                      ? "red"
                      : "transparent",
                }}
              />
              <QuickAccessMessage> {item?.message}</QuickAccessMessage>
            </div>
          </div>
        ))}
      </Slider>
    </>
  ) : null;
};

export default TickerCard;
