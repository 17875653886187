import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { insuranceValidationSchema } from "./validations";

import swal from "sweetalert";
import { Grid } from "@mui/material";
import { useGetSortedMaster } from "../../../../../services/master.service";
import { useGetStateCityFromPincode } from "../../../../../services/pincode.service";
import MasterFormWrapper from "../../../../masters/components/MasterFormWrapper/MasterFormWrapper";
import TextInput from "../../../../../components/InputComponents/TextInput";
import {
  addressValidationOnly,
  allowOnlyAlphabetsSpace,
  allowOnlyAlphabetsUnderscoreNumber,
  allowOnlyCapitalizeAlphaNumeric,
  allowOnlyNumbers,
} from "../../../../../InputUtils/onInput";
import Select from "../../../../../components/InputComponents/Select";
import Docupload from "../../../../../components/InputComponents/DocUpload";
import { icTypeFlagList } from "../../../../../constants/options";
import ICFormStepper from "../../components/ICFormStepper";
import { useSendIcDetails } from "../../../../../services/icConfigurator.service";
import { useParams } from "react-router";
import { usePincodeValidations } from "../../../../../custom-hooks/usePincodeValidations";

function CompanyDetails({ values, isBrokerLogin }) {
  const { id } = useParams();

  const methods = useForm({
    resolver: yupResolver(insuranceValidationSchema(values, id)),
    defaultValues: {
      status: "Y",
      ...values,
    },
  });

  const state_id = methods.watch("state_id");
  const city_id = methods.watch("city_id");

  const { table_list: broker_setting } = useGetSortedMaster({
    master_name: "broker_setting",
    sortBy: "setting_name",
  });

  const matchConfirmationBox = broker_setting.some(
    (obj) =>
      obj.setting_name === "display_confirmation_box" &&
      obj.setting_value === "Y"
  );

  useEffect(() => {
    methods.reset({ ...values });
  }, [values]);

  useEffect(() => {
    methods.setValue("address2", values?.address2 || "");
    methods.setValue("address3", values?.address3 || "");
  }, [values]);
  const {
    getCityState,
    state_list,
    city_list,
    isStateCitySuccess,
    resetStateCity,
  } = useGetStateCityFromPincode(methods.watch("pincode"));
  const pincode = methods.watch("pincode_id");

  useEffect(() => {
    if (pincode?.length === 6) {
      getCityState(pincode);
    } else {
      resetStateCity();
    }
  }, [pincode]);

  usePincodeValidations({
    pincode_field_name: "pincode_id",
    pincode,
    state_list,
    city_list,
    formMethods: methods,
    isSuccess: isStateCitySuccess,
  });

  const { sendIcDetails, isSuccess, alertMaster, resetMaster, isError } =
    useSendIcDetails("insurance", true);

  const onSubmit = (data) => {
    if (matchConfirmationBox) {
      swal({
        text: "Please ensure you have uploaded correct and complete document",
        buttons: true,
        icon: "info",
        dangerMode: false,
      }).then((willSave) => {
        if (willSave) {
          sendIcDetails(data);
        }
      });
    } else {
      sendIcDetails(data);
    }
  };

  // To clear errors if state & city available for given picode
  useEffect(() => {
    if (
      state_list?.length > 0 &&
      city_list?.length > 0 &&
      pincode?.length === 6
    ) {
      methods.clearErrors(["pincode_id"]);
      if (state_list?.length > 0 && state_id) methods.clearErrors(["state_id"]);
      if (city_list?.length > 0 && city_id) methods.clearErrors(["city_id"]);
      return;
    }
  }, [state_id, city_id]);

  // To remove state & city if pincode is removed
  useEffect(() => {
    if (pincode === "") {
      methods.setValue("state_id", "");
      methods.setValue("city_id", "");
      return;
    }
  }, [pincode]);

  useEffect(() => {
    if (isSuccess) {
      methods.reset({
        insurance_name: "",
        ic_slug: "",
        email: "",
        mobile_no: "",
        landline_no: "",
        address1: "",
        address2: "",
        address3: "",
        city_id: "",
        state_id: "",
        pincode_id: "",
        pan_number: "",
        gstin: "",
        irda_registration_number: "",
        cin_number: "",
        tollfree_number: "",
        ic_website: "",
      });
    }
  }, [isSuccess]);

  return (
    <FormProvider {...methods}>
      <MasterFormWrapper
        title="Insurance Company Details"
        alert={alertMaster}
        reset={resetMaster}
        isError={isError}
        isSuccess={isSuccess}
        description={"Add Insurance Company Details"}
        onSubmit={onSubmit}
        additionalContent={<ICFormStepper isBrokerLogin={isBrokerLogin} />}
      >
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="Insurance Name"
            name="insurance_name"
            required
            inputProps={{ maxLength: 100 }}
            onInput={allowOnlyAlphabetsSpace}
            readOnly={isBrokerLogin}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="Insurance Display Name"
            name="insurance_display_name"
            required
            inputProps={{ maxLength: 100 }}
            onInput={allowOnlyAlphabetsSpace}
            readOnly={isBrokerLogin}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="IC Slug"
            name="ic_slug"
            required
            inputProps={{ maxLength: 100 }}
            onInput={allowOnlyAlphabetsUnderscoreNumber}
            readOnly={isBrokerLogin}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput label="Email ID" name="email" required />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="Mobile No."
            name="mobile_no"
            required
            onInput={allowOnlyNumbers}
            inputProps={{ maxLength: 10, inputMode: "numeric" }}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="Landline No."
            name="landline_no"
            required
            onInput={allowOnlyNumbers}
            inputProps={{ maxLength: 11, inputMode: "numeric" }}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="Address Line 1"
            name="address1"
            required
            onInput={addressValidationOnly}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="Address Line 2"
            name="address2"
            onInput={addressValidationOnly}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="Address Line 3"
            name="address3"
            onInput={addressValidationOnly}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="Pincode"
            name="pincode_id"
            onInput={allowOnlyNumbers}
            inputProps={{ maxLength: 6 }}
            required
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <Select
            label="State Name"
            name="state_id"
            required
            options={state_list}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <Select
            label="City Name"
            name="city_id"
            required
            options={city_list}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="PAN Card No."
            name="pan_number"
            required
            inputProps={{ maxLength: 10 }}
            onInput={(e) => {
              allowOnlyCapitalizeAlphaNumeric(e);
            }}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="GSTIN."
            name="gstin"
            required
            inputProps={{ maxLength: 15 }}
            onInput={(e) => {
              allowOnlyCapitalizeAlphaNumeric(e);
            }}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="IRDA Registration No."
            name="irda_registration_number"
            required
            onInput={(e) => {
              allowOnlyNumbers(e);
            }}
            inputProps={{ maxLength: 3 }}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="CIN No."
            name="cin_number"
            required
            inputProps={{ maxLength: 21 }}
            onInput={(e) => {
              allowOnlyCapitalizeAlphaNumeric(e);
            }}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput
            label="Tollfree No."
            name="tollfree_number"
            required
            inputProps={{ maxLength: 12, inputMode: "numeric" }}
            onInput={(e) => {
              allowOnlyNumbers(e);
            }}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <TextInput label="Insurance Website" name="ic_website" required />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <Select
            label="Policy Issuance From"
            name="ic_type_flag"
            required
            options={icTypeFlagList}
          />
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <Docupload
            label="Insurance Logo"
            name="ic_logo"
            required={!id && true}
            // accept="image/*"
          ></Docupload>
        </Grid>
        <Grid item xxs={12} xs={6} md={6} lg={4}>
          <Docupload
            label="Insurance Signature"
            name="ic_signature"
            required={!id && true}
            readOnly={isBrokerLogin}
            // accept="image/*"
          ></Docupload>
        </Grid>
      </MasterFormWrapper>
    </FormProvider>
  );
}

export default CompanyDetails;
