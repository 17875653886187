import React from "react";
import { useParams } from "react-router";
import { useGetFilterService } from "../../../../services/dashboard.service";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import { Box, Paper, Stack, Typography } from "@mui/material";

function ShareThankyou({ shareQuoteData }) {
  const { segment_slug } = useParams();
  const { segment_logo } = useGetFilterService(segment_slug);

  const isMail = shareQuoteData?.toLowerCase()?.includes("email");
  const isSms = shareQuoteData?.toLowerCase()?.includes("sms");
  const isMailAndSms = isMail && isSms;
  // const isWhatsapp = shareQuoteData?.includes("Whatsapp");
  // const isAll = isMail && isSms && isWhatsapp;

  const successMessage = isMail
    ? "Mail"
    : isSms
    ? "SMS"
    : isMailAndSms
    ? "Mail and SMS"
    : "";

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={"center"}
      alignItems={"center"}
      marginBottom={2}
    >
      <Box display="flex" justifyContent={"center"} alignItems={"center"}>
        <DoneSharpIcon fontSize="large" color="success" fontWeight="bold" />
      </Box>
      <Stack
        direction="row"
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            px: 2,
            py: 2,
            backgroundColor: "#03CC83",
            borderRadius: "1rem",
          }}
          elevation={8}
        >
          <img src={segment_logo} alt="logo" height={50} width={50} />
        </Paper>
        <Typography variant="body1" textAlign="center">
          Thank you! Your quote(s) have been sent through&nbsp;
          {successMessage} Successfully.
        </Typography>
      </Stack>
    </Box>
  );
}

export default ShareThankyou;
