import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Controller, useFormContext } from "react-hook-form";
import { TextField, InputLabel, CalendarHeader } from "./InputComponents.style";
import { Box } from "@mui/system";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useEffect, useMemo, useState } from "react";
// import CloseIcon from "@mui/icons-material/Close";
// import { IconButton, InputAdornment } from "@mui/material";
const monthMap = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};
const generateMonths = (minDate, maxDate, selectedDate, options) => {
  let months = [];
  const minMonth = minDate?.month();
  const maxMonth = maxDate?.month();
  const minYear = minDate?.year();
  const maxYear = maxDate?.year();
  const selectedYear = selectedDate?.year() || moment().year();
  if (
    minDate &&
    maxDate &&
    selectedYear === minYear &&
    selectedYear === maxYear
  ) {
    for (let i = minMonth; i <= maxMonth; i++) {
      months.push(i);
    }
  } else if (minDate && selectedYear === minYear) {
    for (let i = minMonth; i <= 11; i++) {
      months.push(i);
    }
  } else if (maxDate && selectedYear === maxYear) {
    for (let i = 0; i <= maxMonth; i++) {
      months.push(i);
    }
  } else {
    for (let i = 0; i <= 11; i++) {
      months.push(i);
    }
  }
  if (options?.disablePast && selectedYear === moment().year()) {
    months = months.filter((item) => item >= moment().month());
  }
  if (options?.disableFuture && selectedYear === moment().year()) {
    months = months.filter((item) => item <= moment().month());
  }
  const monthsinWords = months.map((item) =>
    moment().month(item).format("MMMM")
  );
  return monthsinWords;
};
const generateYears = (minDate, maxDate, options) => {
  const years = [];
  let tempMaxDate = maxDate || moment().year(2060);
  let tempMinDate = minDate || moment().year(1900);
  if (options?.disableFuture) {
    tempMaxDate = moment();
  }
  if (options?.disablePast) {
    tempMinDate = moment();
  }

  for (let i = tempMinDate.year(); i <= tempMaxDate.year(); i++) {
    years.push(i);
  }
  return years;
};
const DateInput = ({
  label,
  name,
  required,
  outputFormat = "YYYY-MM-DD",
  readOnly,
  bordered,
  removeBottomMargin,
  modifyInvalidDate = false, // disablig modifyInvalidDate functionality for now,
  //  as it was also modifying the correct dates coming from API
  preventTyping = false,
  ...otherProps
}) => {
  const {
    watch,
    control,
    trigger,
    formState: { errors },
    setValue,
  } = useFormContext();
  const value = watch(name);

  const [selectedMonth, setSelectedMonth] = useState(
    moment(value).month() || moment().month()
  );
  const [selectedYear, setSelectedYear] = useState(
    moment(value).year() || moment().year()
  );
  const minDateStartOfDay =
    otherProps?.minDate && moment(otherProps?.minDate).startOf("day");
  const maxDateStartOfDay =
    otherProps?.maxDate && moment(otherProps?.maxDate).startOf("day");

  const generatedYears = useMemo(
    () =>
      generateYears(
        minDateStartOfDay,
        maxDateStartOfDay,
        moment(value, outputFormat)
      ),
    [
      value,
      outputFormat,
      JSON.stringify(minDateStartOfDay),
      JSON.stringify(maxDateStartOfDay),
    ]
  );
  const generatedMonths = useMemo(
    () =>
      generateMonths(
        minDateStartOfDay,
        maxDateStartOfDay,
        moment(value, outputFormat)
      ),
    [
      value,
      outputFormat,
      JSON.stringify(minDateStartOfDay),
      JSON.stringify(maxDateStartOfDay),
    ]
  );

  useEffect(() => {
    if (
      modifyInvalidDate &&
      moment(value, outputFormat)
        .startOf("day")
        .isAfter(moment(maxDateStartOfDay, outputFormat))
    ) {
      setValue(
        name,
        moment(maxDateStartOfDay || moment(), outputFormat)
          .month(generatedMonths[0])
          .format(outputFormat)
      );
    }
    if (
      modifyInvalidDate &&
      moment(value, outputFormat).isBefore(
        moment(minDateStartOfDay, outputFormat)
      )
    ) {
      setValue(
        name,
        moment(minDateStartOfDay || moment(), outputFormat)
          .month(generatedMonths[0])
          .format(outputFormat)
      );
    }
  }, [
    generatedMonths,
    value,
    JSON.stringify(minDateStartOfDay),
    JSON.stringify(maxDateStartOfDay),
    modifyInvalidDate,
  ]);

  useEffect(() => {
    if (value) {
      setSelectedMonth(moment(value, outputFormat).month());
      setSelectedYear(moment(value, outputFormat).year());
    }
  }, [value]);

  const hideTodayButton =
    moment(otherProps?.maxDate, outputFormat).isSameOrBefore(moment()) ||
    moment(otherProps?.minDate, outputFormat).isSameOrAfter(moment());

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box width="100%">
          <InputLabel
            title={label}
            required={required}
            bordered={bordered}
            removeBottomMargin={removeBottomMargin}
          >
            {label}
          </InputLabel>
          <Controller
            name={name}
            control={control || {}}
            render={({ field: { onChange, ref } }) => (
              <DatePicker
                inputRef={ref}
                readOnly={readOnly}
                value={value ? moment(value, outputFormat) : null}
                renderInput={(params) => (
                  <Box display="flex" position="relative">
                    <TextField
                      id={name}
                      readOnly={readOnly}
                      helperText={errors[name]?.message}
                      bordered={bordered}
                      {...params}
                      error={!!errors[name]}
                      onKeyDown={(e) => preventTyping && e.preventDefault()}
                    />
                    {/* <IconButton
                      onClick={() => {
                        setValue(name, undefined);
                      }}
                      sx={{
                        position: "absolute",
                        right: 40,
                        padding: 0.5,
                        top: bordered ? 9 : 4,
                      }}
                    >
                      <CloseIcon sx={{ fontSize: "16px" }} />
                    </IconButton> */}
                  </Box>
                )}
                inputFormat="DD-MM-YYYY"
                PaperProps={{
                  sx: {
                    "& .MuiPickersCalendarHeader-root": {
                      display: "none",
                    },
                  },
                }}
                onChange={(date) => {
                  onChange(date ? moment(date).format(outputFormat) : "");
                  trigger(name);
                }}
                onOpen={() => {
                  const currentSelectedDate = value
                    ? moment(value, outputFormat)
                    : moment(value);
                  if (
                    currentSelectedDate
                      .startOf("day")
                      .isAfter(moment(maxDateStartOfDay, outputFormat))
                  ) {
                    setValue(
                      name,
                      moment(
                        maxDateStartOfDay || moment(),
                        outputFormat
                      ).format(outputFormat)
                    );
                  }
                  if (
                    currentSelectedDate.isBefore(
                      moment(minDateStartOfDay, outputFormat)
                    )
                  ) {
                    setValue(
                      name,
                      moment(
                        minDateStartOfDay || moment(),
                        outputFormat
                      ).format(outputFormat)
                    );
                  }
                }}
                components={{
                  OpenPickerIcon: CalendarMonthIcon,
                  PaperContent: ({ children }) => (
                    <>
                      <CalendarHeader>
                        <select
                          value={monthMap[selectedMonth]}
                          onChange={(e) => {
                            setValue(
                              name,
                              moment(value || moment(), outputFormat)
                                .month(e.target.value)
                                .date(1) // sets the date to 1st day of the selected month
                                .format(outputFormat)
                            );
                          }}
                          className="month-select"
                        >
                          {generatedMonths.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                        <select
                          value={selectedYear}
                          className="year-select"
                          onChange={(e) => {
                            setValue(
                              name,
                              moment(value || moment(), outputFormat)
                                .year(e.target.value)
                                .format(outputFormat)
                            );
                          }}
                        >
                          {generatedYears.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </CalendarHeader>

                      {children}
                    </>
                  ),
                }}
                componentsProps={{
                  actionBar: !hideTodayButton && {
                    actions: ["today"],
                  },
                }}
                {...otherProps}
              />
            )}
          />
        </Box>
      </LocalizationProvider>
    </>
  );
};

export default DateInput;
