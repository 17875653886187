import moment from "moment";
import React from "react";
import { Box, IconButton } from "@mui/material";
import {
  useGetCalculationPolicyCancellation,
  useGetPolicyCancellationApproval,
} from "../../../../services/policy_cancellation.service";
import {
  LeftDetailsContainer,
  TitleWithBlueBlock,
  DetailsContainer,
  Details1,
  Details2,
  HorizontalLine,
} from "./CancellationApprovalStyles";
import {
  COVER_TYPE_SAOD,
  COVER_TYPE_SATP,
} from "../../../../constants/coverTypeSlugs";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LeftCancellationPremiumTable from "./LeftCancellationPremiumTable";

function LeftCancellationApprovalDetails({
  booking_id,
  type,
  setOpenDetails,
  openDetails,
}) {
  const { policy_cancellation_list } = useGetPolicyCancellationApproval(
    booking_id,
    type
  );

  const { calculation_policy_cancellation } =
    useGetCalculationPolicyCancellation(booking_id);
  const ownerIndividual =
    policy_cancellation_list[0]?.owner_type?.toLowerCase() === "individual";

  const CoverTypeOD =
    policy_cancellation_list[0]?.cover_type_slug === COVER_TYPE_SAOD;
  const CoverTypeTP =
    policy_cancellation_list[0]?.cover_type_slug === COVER_TYPE_SATP;

  return (
    <LeftDetailsContainer>
      <Box sx={{ display: "flex" }}>
        <div className="Rectangle-4109"></div>
        <TitleWithBlueBlock>Policy Details</TitleWithBlueBlock>
        <IconButton
          sx={{
            display: { xxs: "flex", md: "none" },
            position: "absolute",
            right: "8px",
            top: 0,
            cursor: "pointer",
          }}
          onClick={() => setOpenDetails(!openDetails)}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </Box>

      <Box sx={{ padding: "5px 15px" }}>
        <DetailsContainer>
          <Details1>Policy No.</Details1>
          <Details2>{policy_cancellation_list[0]?.policy_no}</Details2>
        </DetailsContainer>

        <DetailsContainer>
          <Details1>Policy Type</Details1>
          <Details2>{policy_cancellation_list[0]?.policy_type}</Details2>
        </DetailsContainer>

        {policy_cancellation_list[0]?.endorsement_stage ===
          "endorsement_issuance" && (
          <DetailsContainer>
            <Details1>Endorsement No</Details1>
            <Details2>
              {policy_cancellation_list[0]?.policy_endorsement_no}
            </Details2>
          </DetailsContainer>
        )}

        <DetailsContainer>
          <Details1>Insurance Company</Details1>
          <Details2>{policy_cancellation_list[0]?.insurance_name}</Details2>
        </DetailsContainer>

        <DetailsContainer>
          <Details1>Cover Type</Details1>
          <Details2>{policy_cancellation_list[0]?.cover_type}</Details2>
        </DetailsContainer>

        {!CoverTypeTP && (
          <>
            <DetailsContainer>
              <Details1>OD Start Date</Details1>
              <Details2>
                {moment(
                  policy_cancellation_list[0]?.od_policy_start_date
                ).format(`DD-MMM-YYYY`)}
              </Details2>
            </DetailsContainer>

            <DetailsContainer>
              <Details1>OD End Date</Details1>
              <Details2>
                {moment(policy_cancellation_list[0]?.od_policy_end_date).format(
                  `DD-MMM-YYYY`
                )}
              </Details2>
            </DetailsContainer>
          </>
        )}
        {!CoverTypeOD && (
          <>
            <DetailsContainer>
              <Details1>TP Start Date</Details1>
              <Details2>
                {moment(
                  policy_cancellation_list[0]?.tp_policy_start_date
                ).format(`DD-MMM-YYYY`)}
              </Details2>
            </DetailsContainer>

            <DetailsContainer>
              <Details1>TP End Date</Details1>
              <Details2>
                {moment(policy_cancellation_list[0]?.tp_policy_end_date).format(
                  `DD-MMM-YYYY`
                )}
              </Details2>
            </DetailsContainer>
          </>
        )}

        <DetailsContainer>
          <Details1>Proposal Type</Details1>
          <Details2>
            {policy_cancellation_list[0]?.proposal_stage
              ?.replace(/_/g, " ")
              .replace(/\b\w/g, (c) => c.toUpperCase())}
          </Details2>
        </DetailsContainer>
        {ownerIndividual ? (
          <DetailsContainer>
            <Details1>Insured Name</Details1>
            <Details2>
              {policy_cancellation_list[0]?.proposer_full_name}
            </Details2>
          </DetailsContainer>
        ) : (
          <DetailsContainer>
            <Details1>Company Name</Details1>
            <Details2>{policy_cancellation_list[0]?.company_name}</Details2>
          </DetailsContainer>
        )}

        <DetailsContainer>
          {ownerIndividual ? (
            <Details1>Insured Address</Details1>
          ) : (
            <Details1>Company Address</Details1>
          )}
          <Details2
          // sx={{ textAlign: "right", whiteSpace: "pre-wrap" }}
          >
            <span>{policy_cancellation_list[0]?.address1}, </span>
            {policy_cancellation_list[0]?.address2 && (
              <span>{policy_cancellation_list[0]?.address2}, </span>
            )}
            {policy_cancellation_list[0]?.address3 && (
              <span>{policy_cancellation_list[0]?.address3}, </span>
            )}
            {policy_cancellation_list[0]?.city_name && (
              <span>{policy_cancellation_list[0]?.city_name}, </span>
            )}
            {policy_cancellation_list[0]?.state_name && (
              <span>{policy_cancellation_list[0]?.state_name}, </span>
            )}
            {policy_cancellation_list[0]?.pincode && (
              <span>{policy_cancellation_list[0]?.pincode}</span>
            )}
          </Details2>
        </DetailsContainer>

        {/* <DetailsContainer>
          <Details1>Premium Source</Details1>
          <Details2>{policy_cancellation_list[0]?.policy_source}</Details2>
        </DetailsContainer> */}
      </Box>
      <HorizontalLine />
      <Box sx={{ display: "flex" }}>
        <div className="Rectangle-4109"></div>
        <TitleWithBlueBlock>Vehicle Details</TitleWithBlueBlock>
      </Box>
      <Box sx={{ padding: "5px 15px" }}>
        <DetailsContainer>
          <Details1>Manufacturer</Details1>
          <Details2>{policy_cancellation_list[0]?.make_name}</Details2>
        </DetailsContainer>
        <DetailsContainer>
          <Details1>Model</Details1>
          <Details2>{policy_cancellation_list[0]?.model_name}</Details2>
        </DetailsContainer>
        <DetailsContainer>
          <Details1>Variant</Details1>
          <Details2>{policy_cancellation_list[0]?.variant_name}</Details2>
        </DetailsContainer>
        <DetailsContainer>
          <Details1>Chassis No.</Details1>
          <Details2>{policy_cancellation_list[0]?.chassis_no}</Details2>
        </DetailsContainer>
        <DetailsContainer>
          <Details1>Engine No.</Details1>
          <Details2>{policy_cancellation_list[0]?.engine_no}</Details2>
        </DetailsContainer>
        <DetailsContainer>
          <Details1>Vehicle IDV</Details1>
          <Details2>{policy_cancellation_list[0]?.idv}</Details2>
        </DetailsContainer>
        <DetailsContainer>
          <Details1>Registration No.</Details1>
          <Details2>
            {policy_cancellation_list[0]?.vehicle_registration_no}
          </Details2>
        </DetailsContainer>
        {policy_cancellation_list[0]?.claim_status && (
          <DetailsContainer>
            <Details1>Claim Availed</Details1>
            <Details2>
              {policy_cancellation_list[0]?.claim_status
                ?.replace(/_/g, " ")
                .replace(/\b\w/g, (c) => c.toUpperCase())}
            </Details2>
          </DetailsContainer>
        )}
      </Box>
      <HorizontalLine />
      <Box sx={{ padding: "5px 15px" }}>
        <LeftCancellationPremiumTable
          policy_cancellation_list={policy_cancellation_list}
          calculation_policy_cancellation={calculation_policy_cancellation}
        />
      </Box>
    </LeftDetailsContainer>
  );
}

export default LeftCancellationApprovalDetails;
