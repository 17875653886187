import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import error from "../assets/images/Error.png";
import SessionExpired from "../assets/images/session_expired.png";
import Loader from "../app/Loader";
import { useQueryClient } from "@tanstack/react-query";
import Navbar from "./Navbar";

const NotFound = ({ isTransactionBlocked, isErrorLinkExpired }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [falseDelay, setFalseDelay] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    let timer;
    if (isTransactionBlocked) {
      setFalseDelay(true);
      setTimeout(() => {
        setFalseDelay(false);
      }, 500);
    }

    return () => {
      if (isTransactionBlocked) clearTimeout(timer);
    };
  }, [location.pathname, isTransactionBlocked]);
  const errorMessage = isTransactionBlocked
    ? "Access Blocked"
    : isErrorLinkExpired
    ? "Oops! Link expired"
    : "Oops! Page not found";

  const errorMessageTwo = isTransactionBlocked
    ? "Your Pay-in-slip is pending for Generation. please Generate Pay-in-slip"
    : isErrorLinkExpired
    ? "To reset your password, return to login page and generate a new link again"
    : " We are sorry but the page you are looking for does not exist";

  const errorImage = isTransactionBlocked ? error : error;

  const handleRetry = () => {
    if (isTransactionBlocked) {
      navigate("/policy_support/pay_in_slip");
    } else if (isErrorLinkExpired) {
      localStorage.removeItem("token");
      queryClient.invalidateQueries(["user"]);
      navigate("/login");
    } else {
      navigate("/");
    }
  };

  const buttonLabel = isTransactionBlocked
    ? "Generate Pay-in-slip"
    : isErrorLinkExpired
    ? "Go to Login page"
    : "Go to Homepage";
  if (falseDelay) return <Loader />;
  return (
    <Navbar>
      <Box
        height="95vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          direction="row"
          alignItems="center"
          width="80%"
          height="80%"
          borderRadius="8px"
          // direction="column"
          justifyContent="center"
          // alignItems="center"
          spacing={2}
        >
          <Box width="40%">
            <img
              src={!isErrorLinkExpired ? errorImage : SessionExpired}
              alt="errorImg"
            />
          </Box>
          <Box p={4} flex="1">
            <Typography variant="h3" fontWeight="bold" fontSize="23px" mb={2}>
              {errorMessage}
            </Typography>
            <Box
              boxShadow={theme.shadows[20]}
              width="100%"
              height="100%"
              borderRadius="8px"
              padding={"15px 10px 10px 15px"}
            >
              <Typography
                variant="subtitle1"
                fontSize="20px"
                color="#4f6781"
                mb={2}
              >
                {errorMessageTwo}
              </Typography>
              <Button variant="contained" onClick={handleRetry}>
                {buttonLabel}
              </Button>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Navbar>
  );
};

export default NotFound;
