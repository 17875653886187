import { Box, Paper, styled } from "@mui/material";
import { TextField } from "../../../../../components/InputComponents/InputComponents.style";
import { Heading } from "../../../../masters/components/MasterStyle";

export const MainContainer = styled(Paper)({
  margin: "15px",
  padding: "15px",
  borderRadius: "14px",
  width: "45%",
  boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
});

export const SubContainer = styled(Box)({
  padding: "0 10px",
});

export const CardHeading = styled(Heading)({
  fontSize: "15px",
  fontWeight: "bold",
});

export const RadioContainer = styled(Box)({
  display: "flex",
  gap: "15px",
  marginTop: "15px",
});

export const RadioTabInspection = styled(Paper)(({ type }) => ({
  width: "90px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "40px",
  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16);",
  cursor: "pointer",
  border: type === "inspection" ? "2px solid #006eff" : "",
  color: type === "inspection" ? "#006eff" : "",
  backgroundColor: type === "inspection" ? "#f5fbff" : "",
}));

export const RadioTabWaiver = styled(Paper)(({ type }) => ({
  width: "90px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "40px",
  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16);",
  cursor: "pointer",
  border: type === "waiver" ? "2px solid #006eff" : "",
  color: type === "waiver" ? "#006eff" : "",
  backgroundColor: type === "waiver" ? "#f5fbff" : "",
}));

export const ExpiryDayContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  color: "#2d393e",
});

export const ExpiryDayField = styled(Box)({
  width: "40px",
  margin: "0 3px 4px 3px",
});

export const NewInput = styled(TextField)({
  width: "35px",
  "& .MuiInputBase-input": {
    textAlign: "center",
  },
});
