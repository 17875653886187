import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import MasterTable from "../masters/MasterTable/MasterTable";
import withRouteProtection from "../../hoc/withRouteProtection";
import {
  ButtonWrapper,
  FormContainer,
  Heading,
  Papers,
} from "../masters/components/MasterStyle";
import { FormProvider, useForm } from "react-hook-form";
import DateInput from "../../components/InputComponents/DateInput";
import moment from "moment";
import Buttons from "../../components/BasicFormComponent/Buttons";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { ResetButton } from "../Claim/claimStyledComponents";
import TextInput from "../../components/InputComponents/TextInput";
import {
  allCapitalize,
  allowOnlyAplhabetNumber,
  allowOnlyAplhabetNumberHyphen,
} from "../../InputUtils/onInput";
import { useLocation } from "react-router";
import Navbar from "../../components/Navbar";

const PolicyReinstateListing = () => {
  const methods = useForm({
    defaultValues: {
      from_date: "",
      chassis_no: "",
      policy_no: "",
      vehicle_registration_no: "",
      to_date: "",
    },
  });
  const [customSearch, setCustomSearch] = useState({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const onSubmit = (data) => {
    setCustomSearch(data);
  };
  const handleReset = (e) => {
    if (searchParams.get("customSearch")) {
      searchParams.delete("customSearch");
    }
    setCustomSearch({});
    e.preventDefault();
    methods.reset({
      from_date: "",
      chassis_no: "",
      policy_no: "",
      vehicle_registration_no: "",
      to_date: "",
    });
  };
  return (
    <Navbar>
      <Box>
        <FormContainer>
          <Papers elevation={3} sx={{ width: "100%" }}>
            <Heading variant="h5" align="left">
              Policy Reinstate Listing
            </Heading>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={1} columnSpacing={3} mt={1}>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <TextInput
                      label={"Chassis No."}
                      name={"chassis_no"}
                      inputProps={{ maxLength: 25 }}
                      onInput={(e) => {
                        allCapitalize(e);
                        allowOnlyAplhabetNumber(e);
                      }}
                      standard
                    />
                  </Grid>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <TextInput
                      label={"Policy No."}
                      name={"policy_no"}
                      inputProps={{ maxLength: 10 }}
                      onInput={(e) => {
                        allCapitalize(e);
                        allowOnlyAplhabetNumber(e);
                      }}
                      standard
                    />
                  </Grid>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <TextInput
                      label={"Registration No."}
                      name={"vehicle_registration_no"}
                      inputProps={{ maxLength: 13 }}
                      onInput={(e) => {
                        allCapitalize(e);
                        allowOnlyAplhabetNumberHyphen(e);
                      }}
                      standard
                    />
                  </Grid>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <DateInput
                      name="from_date"
                      label="From Date"
                      variant="standard"
                      minDate={moment().subtract(6, "months").startOf("")}
                      maxDate={moment()}
                    />
                  </Grid>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <DateInput
                      name="to_date"
                      label="To Date"
                      variant="standard"
                      minDate={moment().subtract(6, "months").startOf("")}
                      maxDate={moment()}
                    />
                  </Grid>
                </Grid>
                <ButtonWrapper
                  style={{
                    gap: "10px",
                    margin: 0,
                  }}
                >
                  <Buttons
                    id="search_button_reinstatement"
                    type="submit"
                    label="Search"
                  />
                  <ResetButton onClick={(e) => handleReset(e)}>
                    <ReplayRoundedIcon />
                    Reset
                  </ResetButton>
                </ButtonWrapper>
              </form>
            </FormProvider>
          </Papers>
        </FormContainer>
      </Box>
      <Box>
        <MasterTable
          master_name={"policy_reinstate"}
          customSearch={customSearch}
        />
      </Box>
    </Navbar>
  );
};

export default withRouteProtection(PolicyReinstateListing);
