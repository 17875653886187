import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import paymentFail from "../../assets/images/failLogo.jpg";
import { useNavigate } from "react-router";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import Navbar from "../Navbar";
const PaymentError = () => {
  const navigate = useNavigate();
  const { getUrlQuery } = useUrlQuery();
  const flag = getUrlQuery("flag");

  return (
    <Navbar>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#fff",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <Box width={"200px"}>
            <img src={paymentFail} alt="" />
          </Box>
          <Box sx={{ fontSize: 30, fontWeight: "bold", color: "#FF0000" }}>
            Payment Failed
          </Box>
          <Typography sx={{ fontSize: 20, textAlign: "center" }}>
            Oh no! <br />
            Something went wrong
          </Typography>
          <Typography sx={{ fontSize: 20, textAlign: "center" }}>
            We are not able to process your payment please try again
          </Typography>
          <button
            style={{
              border: "2px solid #03cc83",
              fontSize: "13px",
              color: "#03cc83",
              fontFamily: "Lato",
              padding: "8px 10px",
              fontWeight: "500",
              borderRadius: "5px",
              margin: "15px 0",
            }}
            // if online payment fails during proposal payment then flag === "policy" & incase of endorsement flag === "endorsement"
            // Incase redirections needs to be mapped for more than 2 routes, Add respective condition in ternary operator
            onClick={() =>
              flag === "policy"
                ? navigate("/policy_support/proposal_listing")
                : navigate("/policy_support/endorsement_listing")
            }
          >
            {flag === "policy"
              ? "Go to Policy Listing"
              : "Go to Endorsement Listing"}
          </button>
        </Paper>
      </Box>
    </Navbar>
  );
};

export default PaymentError;
