import * as yup from "yup";
import { REGEX } from "../../../constants/regex";
import {
  mobileNumberBasic,
  mobileNumberRequired,
} from "../../../utils/Validations/mobileNumberValidations";

export const contactDetailsSchema = yup.object().shape({
  claim_intimated_by: yup.string().required("Claim intimated by is required"),
  intimator_name: yup
    .string()
    .nullable()
    .required("Intimator name is required")
    .matches(REGEX?.FULL_NAME, "Enter full name")
    .matches(
      REGEX?.FIRST_MIDDLE_LAST_NAME,
      "Only first name, middle name and last name is accepted"
    ),
  intimator_mobile_no: mobileNumberRequired(),
  customer_mobile_no: mobileNumberBasic(),
  customer_current_mobile_no: mobileNumberRequired(),
  customer_current_email_id: yup
    .string()
    .nullable()
    .required("Customer's current email id is required")
    .email("Please enter a valid email id"),
  driver_name: yup
    .string()
    .nullable()
    .required("Driver name is required")
    .matches(REGEX?.FULL_NAME, "Enter full name")
    .matches(
      REGEX?.FIRST_MIDDLE_LAST_NAME,
      "Only first name, middle name and last name is accepted"
    ),
});
