/*
title : AuthenticationServices.js
Description : It includes all the services related to authentication
used-in : Login.js, LoginShow.js, Navbar.js, withRouteProtection.js, Role.js, RoleAccess.js, RoleAccessForm.js


====
changes done in the access control should be repeated for breadCrumbHelper.js as well to make the back and other working as well.
====
*/

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router";

import api from "../api/api";
import { useGetRoleAccessByRoleId } from "./role.service";
import { useMemo } from "react";
import { findPathToCurrentNode, routes } from "../routes/Routes";
import { errorHandler } from "../utils/errorHandler";

export const useGetLoginOTP = () => {
  const { slug } = useParams();

  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => {
      return api.post("user/login", {
        ...data,
        user_type: slug,
      });
    }
  );
  const errList = errorHandler(error, data);

  return {
    login: mutate,
    isLoginSuccess: isSuccess,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
  };
};
export const useGetLogin = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => {
      return api.post("user/login", data);
    },
    {
      onSuccess: (data) => {
        localStorage.setItem(
          "token",
          data.data?.return_data?.authorisation?.token
        );
        queryClient.setQueryData(["user"], data);
        queryClient.clear();
        navigate("/");
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    loginWithoutOTP: mutate,
    isLoginSuccess: isSuccess,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isSuccess: isSuccess && data?.data?.status !== 500,
    alreadyLoggedIn: error?.response?.data?.already_logged_in,
  };
};
export const useVerifyOTP = ({ switch_user }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { slug } = useParams();
  const { data: user_data } = useGetUser();

  const user_type = user_data?.user_type;

  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => {
      if (switch_user) {
        return api.post("/switch_user/verify", {
          ...data,
          user_type: user_type || "other",
          current_login: user_data?.email_id,
        });
      }
      return api.post("user/verify_otp", {
        ...data,
        user_type: slug,
      });
    },
    {
      onSuccess: (data) => {
        localStorage.setItem(
          "token",
          data.data?.return_data?.authorisation?.token
        );
        localStorage.removeItem("tickerClosed");
        queryClient.setQueryData(["user"], data);
        queryClient.clear();
        navigate("/");
        localStorage.removeItem("pathname");
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    verifyOTP: mutate,
    isErrorOTP: isError,
    alertMasterOTP: errList,
    dataMasterOTP: data,
    resetMasterOTP: reset,
    isSuccessOTP: isSuccess && data?.data?.status !== 500,
    alreadyLoggedIn: error?.response?.data?.already_logged_in,
  };
};

export const useGetUser = () => {
  const { data, isSuccess, isLoading } = useQuery(
    ["user"],
    () => api.get("/user"),
    { enabled: !!localStorage.getItem("token") }
  );
  const { access, isAccessLoading, policy_access } = useGetRoleAccessByRoleId(
    data?.data?.return_data?.user?.role_id,
    data?.data?.return_data?.user?.custom_role
  );

  const location = useLocation();
  const accessControlData = useMemo(() => {
    return isPathAccessible(location, access);
  }, [location.pathname, access]);

  const isTransactionBlocked =
    data?.data?.return_data?.user?.is_transaction_blocked;

  return {
    isAuthorized: isSuccess,
    isLoading: localStorage.getItem("token")
      ? isLoading || (isAccessLoading && data?.data?.return_data?.user?.role_id)
      : false,
    access: access,
    data: data?.data?.return_data?.user,
    policy_access,
    accessControlData,
    isTransactionBlocked,
  };
};

const isPathAccessible = (location, access) => {
  const path = findPathToCurrentNode(routes[0].children, location.pathname);
  if (!path) {
    return false;
  }
  const currentMenu = path[0]?.slug;
  const currentRole = path[path.length - 1]?.role;
  const currentAccess = access[currentMenu];
  if (!currentMenu) {
    return { pathAccess: true, writable: true };
  }
  if (!currentAccess) {
    return { pathAccess: false, writable: false };
  }
  return {
    pathAccess: currentRole === "read" ? currentAccess[0] : currentAccess[1],
    writable: currentAccess[1],
    logAccess: currentAccess[3],
  };
};

export const useResetPasswordMutation = (type) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => {
      return api.post("user/forget-password", data);
    },
    {
      onSuccess: () => {
        type === "create_password" && localStorage.removeItem("token");
        queryClient.invalidateQueries(["user"]);
        const timer = setTimeout(() => {
          type !== "create_password"
            ? navigate("/", {
                replace: true,
              })
            : navigate("/login");
        }, 3500);
        return () => {
          if (timer) clearTimeout(timer);
        };
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    resetPassword: mutate,
    isError: isError,
    isSuccess: isSuccess && data?.data?.status !== 500,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
  };
};

export const useForgotPasswordMutation = () => {
  // const navigate = useNavigate();
  // const queryClient = useQueryClient();
  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => {
      return api.post("user/send-token", data);
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["user"]);
        // navigate("/", {
        //   replace: true,
        // });
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    forgotPassword: mutate,
    isErrorFP: isError,
    isSuccessFP: isSuccess && data?.data?.status !== 500,
    alertMasterFP: errList,
    dataMasterFP: data,
    resetMasterFP: reset,
  };
};

export const useSwitchUser = () => {
  const { mutate, isError, isSuccess, data, error, reset, isLoading } =
    useMutation((data) => {
      return api.post("/switch_user", data);
    });
  const errList = errorHandler(error, data);

  return {
    switchUser: mutate,
    isError,
    isSuccess,
    alert: errList,
    reset,
    isLoading,
    switchData: data?.data?.return_data,
  };
};

export const useSessionTimingMutation = () => {
  const { mutate, isError } = useMutation(() => {
    return api.post("/user/session-expired");
  });

  return {
    sessionTiming: mutate,
    isErrorLinkExpired: isError,
  };
};

export const useLogoutUser = (options) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const url = new URL(window.location.href);
  const pathWithQuery = url?.pathname + url?.search;
  const { mutate } = useMutation(
    () => {
      api.post("/user/logout");
    },
    {
      onSettled: () => {
        if (options?.isForcedLogout) {
          localStorage.setItem("pathname", pathWithQuery);
        }
        localStorage.removeItem("token");
        queryClient.clear();
        navigate("/login");
      },
    }
  );

  return {
    logout: mutate,
  };
};
