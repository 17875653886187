import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import * as yup from "yup";
import {
  allowOnlyAlphabetsNumberSpecialcharacterForEmail,
  allowOnlyAlphabetsSpace,
  allowOnlyNumbers,
} from "../../../../InputUtils/onInput";
import { SubmitButton } from "../../../../components/InputComponents/InputComponents.style";
import TextInput from "../../../../components/InputComponents/TextInput";
import useUrlQuery from "../../../../custom-hooks/useUrlQuery";
import { useGetQuote } from "../../../../services/quote.service";
import { mobileNumberBasic } from "../../../../utils/Validations/mobileNumberValidations";
import { premiumCalculations } from "../../../../utils/premiumCalculations";
import { AtleastOnefieldError } from "../../../masters/components/MasterStyle";
import { REGEX } from "../../../../constants/regex";

const shareFeaturesSchema = yup.object().shape({
  mobile: mobileNumberBasic(),
  email_id: yup
    .string()
    .test("email_id", "Please enter a valid email id", (val) => {
      if (val.length === 1) return true;
      return yup
        .string()
        .email("Please enter a valid email id")
        .isValidSync(val);
    }),
  name: yup
    .string()
    .required("Name is required")
    .matches(REGEX.FULL_NAME, "Enter valid full name"),
});

function ShareFeatures({
  selected,
  setSelectedButton,
  setOpen,
  createCommunication,
  isSuccess,
  isLoading,
  resetMaster,
}) {
  const methods = useForm({
    resolver: yupResolver(shareFeaturesSchema),
  });
  // const isMobile = methods.watch("mobile");
  const [communicationType, setCommunicationType] = useState([]);
  const eMail = methods.watch("email_id");
  const mobileNumber = methods.watch("mobile");
  const userName = methods.watch("name");

  useEffect(() => {
    if (!eMail && mobileNumber) {
      setCommunicationType(["sms"]);
    } else if (!mobileNumber && eMail) {
      setCommunicationType(["email"]);
    } else if (mobileNumber && eMail) {
      setCommunicationType(["email", "sms"]);
    } else {
      setCommunicationType([]);
    }
  }, [eMail, mobileNumber]);

  const location = useLocation();
  const { pathname } = location;
  const segment_id = pathname.split("/")[2];
  const { getUrlQuery } = useUrlQuery();
  const trace_id = getUrlQuery("traceId");

  const selected_discount = useSelector(
    (state) => state.quote.selected_discount
  );
  const selected_discount_by_product = useSelector(
    (state) => state.quote.selected_discount_by_product
  );

  const specificQuoteData = selected?.map((item) => {
    let productId = item?.product_data?.product_id;
    let bundleId = item?.product_data?.addon_bundle_id;
    let discountkey = productId;
    if (bundleId) discountkey += `/${bundleId}`;
    const discount_for_current =
      selected_discount_by_product[discountkey]?.discount || selected_discount;
    const discount_conf_id =
      selected_discount_by_product[discountkey]?.discount_conf_id;
    const { additionalPremium, allAddons, additionalPremiumWithIncluded } =
      useGetQuote({
        productId,
        bundleId,
        discount: discount_for_current,
        discount_conf_id,
      });
    const {
      totalAddonIgst,
      totalAddonSgst,
      totalAddonCgst,
      totalODwithDiscount,
      totalTP,
    } = premiumCalculations(item, additionalPremiumWithIncluded, allAddons);
    const gstAmount =
      (item?.premium_arr?.igst || 0) +
      (item?.premium_arr?.sgst || 0) +
      (item?.premium_arr?.cgst || 0) +
      totalAddonIgst +
      totalAddonSgst +
      totalAddonCgst;

    return (
      item && {
        finalPremium: Math.round(
          item?.premium_arr?.net_premium + additionalPremium + gstAmount
        ),
        finalPremiumNoGst: Math.round(
          item?.premium_arr?.net_premium + additionalPremium
        ),
        igst: item?.premium_arr?.igst + totalAddonIgst,
        cgst: item?.premium_arr?.cgst + totalAddonCgst,
        sgst: item?.premium_arr?.sgst + totalAddonSgst,
        product_id: item?.product_data?.product_id,
        idv: item?.premium_arr?.idv_value,
        premium: totalODwithDiscount + totalTP,
        // premiumWithGst: item.premiumWithGst,
      }
    );
  });

  const handleMailShare = () => {
    createCommunication({
      name: userName,
      email_id: eMail,
      mobile: mobileNumber,
      notificationType: "email",
      segment_id: segment_id,
      type: communicationType,
      alias: "share_quotes",
      trace_id: trace_id,
      quotes: specificQuoteData,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setSelectedButton("Thankyou");
      setTimeout(() => {
        setOpen(false);
        setSelectedButton("Insurer");
        resetMaster();
      }, 3000);
    }
  }, [isSuccess]);

  const err = methods.formState.errors?.atleast_one_field?.message;

  useEffect(() => {
    if (eMail) {
      methods.trigger("email_id", { shouldFocus: true });
    }
  }, [eMail]);

  useEffect(() => {
    if (mobileNumber) {
      methods.trigger("mobile", { shouldFocus: true });
    }
  }, [mobileNumber]);

  const onSubmit = (data) => {
    // eslint-disable-next-line
    const { name, ...fields } = data;
    const isAnyFieldSelected = Object?.values(fields).some((item) => !!item);
    if (userName && isAnyFieldSelected) {
      handleMailShare();
    } else {
      methods.setError("atleast_one_field", {
        type: "custom",
        message: "Atleast one field is required along with name",
      });
    }
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={1}
            columnSpacing={3}
            style={{ maxWidth: "350px" }}
          >
            <Grid item xs={12}>
              <TextInput
                label="Name."
                name="name"
                onInput={allowOnlyAlphabetsSpace}
                inputProps={{
                  maxLength: 20,
                }}
                bordered
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Mobile No."
                name="mobile"
                onInput={allowOnlyNumbers}
                inputProps={{
                  maxLength: 10,
                }}
                bordered
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Email ID"
                name="email_id"
                onInput={allowOnlyAlphabetsNumberSpecialcharacterForEmail}
                bordered
              />
            </Grid>
          </Grid>
          {err && (
            <AtleastOnefieldError style={{ margin: "10px 0" }}>
              {err}
            </AtleastOnefieldError>
          )}
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px",
            }}
          >
            <SubmitButton
              id="share_button_for_all_quote"
              disabled={isLoading || isSuccess}
            >
              Share
            </SubmitButton>
          </Box>
        </form>
      </FormProvider>
    </div>
  );
}

export default ShareFeatures;
