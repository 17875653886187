import { createSlice } from "@reduxjs/toolkit";
import { insideBoxAlertUrls } from "../constants/alertTypeUrls";

let intervalId;
const alertSlice = createSlice({
  name: "alertSlice",
  initialState: {
    alert: {},
  },
  reducers: {
    addError: (state, action) => {
      const { key, msg, type, alertType } = action.payload;
      state.alert[key] = { msg, type, alertType };
    },
    removeError: (state, action) => {
      const { key } = action.payload;
      delete state.alert[key];
    },
  },
});

export const { addError, removeError, statusNormalization } =
  alertSlice.actions;

export const setMessageAndClear = (data) => (dispatch) => {
  clearTimeout(intervalId);
  dispatch(addError(data));
  if (!data?.alertType) {
    intervalId = setTimeout(() => {
      dispatch(removeError({ key: data.key }));
    }, 5000);
  }
};

export const typeOfAlert = (endpoint) => {
  const split = endpoint?.split("/");
  if (
    insideBoxAlertUrls?.includes(endpoint) ||
    split?.includes("add") ||
    split?.includes("update") ||
    split?.includes("upload")
  )
    return "insideBox";
};

export default alertSlice.reducer;
