import {
  CLAIM_APPROVED,
  CLAIM_INTIMATED,
  CLAIM_INVOICE_RAISED,
  CLAIM_REGISTRERED,
  CLAIM_REJECTED,
  CLAIM_SETTLED,
  CLAIM_WITHDRAW,
  DELIVERY_ORDER_RECEIVED,
  PROFORMA_INVOICE_RECEIVED,
  SURVEYOR_APPOINTED,
  SURVEYOR_ASSESSMENT,
} from "../../../constants/claimStage";
import { useGetUser } from "../../../services/AuthenticationServices";

export const useClaimStage = ({ claim_stage }) => {
  const { data } = useGetUser();

  const isClaimAdmin = data?.role_name?.toLowerCase() === "claim admin";

  const isInsuranceCompany =
    data?.role_group_name?.toLowerCase() === "insurance company" ||
    data?.role_name?.toLowerCase() === "ins admin";

  const isDealer = ["dealer admin", "dealer user"].includes(
    data?.role_name?.toLowerCase()
  );

  const isOnSurveyorAssessment = location.pathname
    ?.split("/")
    ?.includes("surveyor_assessment");
  const isOnClaimDetails = location.pathname
    ?.split("/")
    ?.includes("claim_details");

  const isStatusNil = claim_stage?.toLowerCase() === "nil";

  const showClaimIntimationDetails = [
    CLAIM_INTIMATED,
    CLAIM_REGISTRERED,
    SURVEYOR_APPOINTED,
    SURVEYOR_ASSESSMENT,
    PROFORMA_INVOICE_RECEIVED,
    CLAIM_APPROVED,
    CLAIM_REJECTED,
    DELIVERY_ORDER_RECEIVED,
    CLAIM_INVOICE_RAISED,
    CLAIM_SETTLED,
    CLAIM_WITHDRAW,
  ].includes(claim_stage);

  const showClaimRegistrationDetails = [
    CLAIM_REGISTRERED,
    SURVEYOR_APPOINTED,
    SURVEYOR_ASSESSMENT,
    PROFORMA_INVOICE_RECEIVED,
    CLAIM_APPROVED,
    CLAIM_REJECTED,
    DELIVERY_ORDER_RECEIVED,
    CLAIM_INVOICE_RAISED,
    CLAIM_SETTLED,
    CLAIM_WITHDRAW,
  ].includes(claim_stage);

  const showSurveyorAppointmentDetails = [
    SURVEYOR_APPOINTED,
    SURVEYOR_ASSESSMENT,
    PROFORMA_INVOICE_RECEIVED,
    CLAIM_APPROVED,
    CLAIM_REJECTED,
    DELIVERY_ORDER_RECEIVED,
    CLAIM_INVOICE_RAISED,
    CLAIM_SETTLED,
    CLAIM_WITHDRAW,
  ].includes(claim_stage);

  const showSurveyorAssessmentDetails = [
    SURVEYOR_ASSESSMENT,
    PROFORMA_INVOICE_RECEIVED,
    CLAIM_APPROVED,
    CLAIM_REJECTED,
    DELIVERY_ORDER_RECEIVED,
    CLAIM_INVOICE_RAISED,
    CLAIM_SETTLED,
    CLAIM_WITHDRAW,
  ].includes(claim_stage);

  const showClaimSettlementForm =
    isInsuranceCompany &&
    !isOnClaimDetails &&
    [CLAIM_INVOICE_RAISED].includes(claim_stage);

  const showProformaInvoiceDetails = [
    (isInsuranceCompany || isClaimAdmin) && PROFORMA_INVOICE_RECEIVED,
    CLAIM_APPROVED,
    CLAIM_REJECTED,
    DELIVERY_ORDER_RECEIVED,
    CLAIM_INVOICE_RAISED,
    CLAIM_SETTLED,
    CLAIM_WITHDRAW,
  ].includes(claim_stage);

  const showClaimHoldStatusForm = [
    CLAIM_INTIMATED,
    CLAIM_REGISTRERED,
    SURVEYOR_APPOINTED,
    SURVEYOR_ASSESSMENT,
    PROFORMA_INVOICE_RECEIVED,
  ].includes(claim_stage);

  const showClaimApprovalDetails = [
    CLAIM_APPROVED,
    CLAIM_REJECTED,
    DELIVERY_ORDER_RECEIVED,
    CLAIM_INVOICE_RAISED,
    CLAIM_SETTLED,
  ].includes(claim_stage);

  const showDeliveryOrderDetails = [
    DELIVERY_ORDER_RECEIVED,
    CLAIM_INVOICE_RAISED,
    CLAIM_SETTLED,
  ].includes(claim_stage);

  const showClaimInvoiceDetails = [
    CLAIM_INVOICE_RAISED,
    CLAIM_SETTLED,
  ].includes(claim_stage);

  const showClaimSettlementDetails = [CLAIM_SETTLED].includes(claim_stage);

  const showClaimApprovalForm =
    isInsuranceCompany &&
    !isOnClaimDetails &&
    [PROFORMA_INVOICE_RECEIVED].includes(claim_stage);

  const showClaimInvoiceForm =
    isDealer &&
    !isOnClaimDetails &&
    [DELIVERY_ORDER_RECEIVED, CLAIM_INVOICE_RAISED].includes(claim_stage);

  const showDeliveryOrderForm =
    isInsuranceCompany &&
    !isOnClaimDetails &&
    [CLAIM_APPROVED, DELIVERY_ORDER_RECEIVED].includes(claim_stage);

  const showProformaForm =
    isDealer &&
    !isOnClaimDetails &&
    [SURVEYOR_ASSESSMENT, PROFORMA_INVOICE_RECEIVED].includes(claim_stage);

  const showSurveyorAssessmentForm =
    isDealer && isOnSurveyorAssessment && !isOnClaimDetails;

  return {
    component_visibility_conditions: {
      showClaimIntimationDetails,
      showClaimRegistrationDetails,
      showSurveyorAppointmentDetails,
      showSurveyorAssessmentDetails,
      showClaimSettlementForm,
      showProformaInvoiceDetails,
      showClaimHoldStatusForm,
      showClaimApprovalDetails,
      showDeliveryOrderDetails,
      showClaimInvoiceDetails,
      showClaimSettlementDetails,
      showClaimApprovalForm,
      showClaimInvoiceForm,
      showDeliveryOrderForm,
      showProformaForm,
      showSurveyorAssessmentForm,
      isStatusNil,
    },
    roles: {
      isClaimAdmin,
      isInsuranceCompany,
      isDealer,
    },
  };
};
