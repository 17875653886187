import ReplayIcon from "@mui/icons-material/Replay";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import Buttons from "../../../components/BasicFormComponent/Buttons";
import MultiSelect from "../../../components/InputComponents/MultiSelect";
import Select from "../../../components/InputComponents/Select";
import { SURVEYOR_CATEGORY_LIST } from "../../../constants/SurveyorConstants";
import withRouteProtection from "../../../hoc/withRouteProtection";
import {
  useGetSurveyorDetailsFromSurveyorID,
  useGetSurveyorICMapping,
} from "../../../services/icConfigurator.service";
import {
  useCreateMaster,
  useFilterIcSurveyorMapData,
  useGetMaster,
} from "../../../services/master.service";
import { formatPrice } from "../../../utils/formatPrice";
import { ContentWrap } from "../../Claim/AppointSurveyor/AppointSurveyorStyled";
import { ResetButton } from "../../Claim/claimStyledComponents";
import { ButtonWrapper, Heading } from "../../masters/components/MasterStyle";
import {
  SurveyorDetailsNameContainer,
  SurveyorDetailsValueContainer,
} from "./ICSurveyorMappingStyle";
import AlertMessage from "../../../components/Alert/AlertMessage";
import Navbar from "../../../components/Navbar";

const MapICToSelectedSurveyor = () => {
  const { selected_surveyor } = useParams();
  const { table_list: insurance_items, isTableLoading } = useGetMaster({
    master_name: "insurance",
  });

  const { surveyor_data } =
    useGetSurveyorDetailsFromSurveyorID(selected_surveyor);

  const { previousMappedIcs } = useGetSurveyorICMapping(selected_surveyor);
  const {
    createItem,
    alertMaster,
    isSuccess: isCreatedSuccessfully,
    isError,
    resetMaster,
  } = useCreateMaster("surveyor_ic_mapping");
  const {
    filterIcSuveyorMapData,
    dataMaster,
    isSuccess,
    alertMaster: listAlert,
    isError: isListError,
    resetMaster: listReset,
  } = useFilterIcSurveyorMapData("surveyor_ic_mapping");

  const objkeys = dataMaster ? Object?.keys(dataMaster) : [];

  const [mappedICs, setMappedICs] = useState({});

  const filterInsuranceList = insurance_items.filter(
    (item) => item.insurance_id == objkeys
  );

  const methods = useForm({
    defaultValues: {
      ic_id: "",
      category: [],
    },
  });

  const allFields = methods.watch();
  // removed category condition as to be able to search without it
  const isEveryFieldNonEmpty = !!allFields?.ic_id;

  const conditionalInsuranceList =
    isSuccess && isEveryFieldNonEmpty ? filterInsuranceList : insurance_items;

  // ic list
  const ic_list = useMemo(() => {
    return insurance_items.map((item) => {
      return {
        code: item.insurance_id,
        display_name: item.insurance_name,
      };
    });
  }, [insurance_items]);

  // surveyor category
  const surveyor_category_list = SURVEYOR_CATEGORY_LIST.map((item) => {
    return {
      code: item,
      display_name: item,
    };
  });

  useEffect(() => {
    if (insurance_items)
      setMappedICs(
        insurance_items?.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.insurance_id]: previousMappedIcs[curr.insurance_id] || "N/A",
          };
        }, {})
      );
  }, [JSON.stringify(insurance_items), JSON.stringify(previousMappedIcs)]);

  const onSubmit = () => {
    createItem({
      mapped_ics: mappedICs,
      surveyor_id: selected_surveyor,
    });
  };

  const onSubmitForFilter = (data) => {
    filterIcSuveyorMapData(data);
  };

  const handleReset = () => {
    methods.reset({
      ic_id: "",
      category: [],
    });
  };

  // For checkbox tooltip content
  const toolTipContent = (category, data) => {
    if (category === "A") {
      return `Min: ${formatPrice(data?.min_a)} - Max.: ${formatPrice(
        data?.max_a
      )}`;
    }
    if (category === "B") {
      return `Min: ${formatPrice(data?.min_b)} - Max.: ${formatPrice(
        data?.max_b
      )}`;
    }
    if (category === "C") {
      return `Min: ${formatPrice(data?.min_c)} - Max.: ${formatPrice(
        data?.max_c
      )}`;
    }
  };

  // To disable radio button if category amount range is not set
  const conditionallyDisableRadioButton = (category, data) => {
    if (category === "A") {
      return !data?.min_a && !data?.max_a;
    }
    if (category === "B") {
      return !data?.min_b && !data?.max_b;
    }
    if (category === "C") {
      return !data?.min_c && !data?.max_c;
    }
  };

  return (
    <>
      <Navbar>
        <Grid container spacing={2} style={{ padding: "15px" }}>
          <Grid item xs={4}>
            <Paper style={{ padding: "15px" }}>
              <Heading variant="h5" align="left" mb={1} fontSize={"15px"}>
                Surveyor Details
              </Heading>

              <ContentWrap>
                <SurveyorDetailsNameContainer>
                  Name
                </SurveyorDetailsNameContainer>
                <SurveyorDetailsValueContainer>
                  {surveyor_data?.surveyor_name}
                </SurveyorDetailsValueContainer>
              </ContentWrap>

              <ContentWrap>
                <SurveyorDetailsNameContainer>
                  Email-ID
                </SurveyorDetailsNameContainer>
                <SurveyorDetailsValueContainer>
                  {surveyor_data?.srv_email_id}
                </SurveyorDetailsValueContainer>
              </ContentWrap>

              <ContentWrap>
                <SurveyorDetailsNameContainer>
                  Contact No.
                </SurveyorDetailsNameContainer>
                <SurveyorDetailsValueContainer>
                  {surveyor_data?.srv_contact_no}
                </SurveyorDetailsValueContainer>
              </ContentWrap>

              <ContentWrap>
                <SurveyorDetailsNameContainer>
                  License No.
                </SurveyorDetailsNameContainer>
                <SurveyorDetailsValueContainer>
                  {surveyor_data?.srv_license_no}
                </SurveyorDetailsValueContainer>
              </ContentWrap>

              <ContentWrap>
                <SurveyorDetailsNameContainer>
                  License Expiry Date
                </SurveyorDetailsNameContainer>
                <SurveyorDetailsValueContainer>
                  {moment(surveyor_data?.license_exp_date).format(
                    "DD-MMM-YYYY"
                  )}
                </SurveyorDetailsValueContainer>
              </ContentWrap>

              <ContentWrap>
                <SurveyorDetailsNameContainer>
                  Category
                </SurveyorDetailsNameContainer>
                <SurveyorDetailsValueContainer>
                  {surveyor_data?.category}
                </SurveyorDetailsValueContainer>
              </ContentWrap>

              <ContentWrap>
                <SurveyorDetailsNameContainer>
                  Zone
                </SurveyorDetailsNameContainer>
                <SurveyorDetailsValueContainer>
                  {surveyor_data?.zone_name}
                </SurveyorDetailsValueContainer>
              </ContentWrap>

              <ContentWrap>
                <SurveyorDetailsNameContainer>
                  State
                </SurveyorDetailsNameContainer>
                <SurveyorDetailsValueContainer>
                  {surveyor_data?.state_name}
                </SurveyorDetailsValueContainer>
              </ContentWrap>

              <ContentWrap>
                <SurveyorDetailsNameContainer>
                  City
                </SurveyorDetailsNameContainer>
                <SurveyorDetailsValueContainer>
                  {surveyor_data?.city_name}
                </SurveyorDetailsValueContainer>
              </ContentWrap>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <>
              {isTableLoading ? (
                <Paper>
                  <Box py={6} display="flex" justifyContent={"center"}>
                    <CircularProgress />
                  </Box>
                </Paper>
              ) : (
                <>
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmitForFilter)}>
                      <Paper style={{ padding: "15px", marginBottom: "20px" }}>
                        <AlertMessage
                          alert={alertMaster}
                          reset={resetMaster}
                          isError={isError}
                          isSuccess={isCreatedSuccessfully}
                        />
                        {isListError && (
                          <AlertMessage
                            alert={listAlert}
                            reset={listReset}
                            isError={isListError}
                            isSuccess={isSuccess}
                          />
                        )}
                        <Grid container spacing={1} columnSpacing={3} mt={1}>
                          <Grid item xxs={12} xs={12} sm={6} md={4} lg={4}>
                            <Select
                              name="ic_id"
                              label="Insurance Company"
                              options={ic_list}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xxs={12} xs={12} sm={6} md={4} lg={4}>
                            <MultiSelect
                              name="category"
                              label="Category"
                              options={surveyor_category_list}
                              variant="standard"
                              saveDisplayName={false}
                            />
                          </Grid>
                          <Grid item xxs={12} xs={12} sm={6} md={4} lg={4}>
                            <ButtonWrapper sx={{ marginTop: 0 }} gap={2}>
                              <Buttons
                                label={"Search"}
                                type="submit"
                                standard
                              />
                              <ResetButton onClick={handleReset}>
                                <ReplayIcon />
                                Reset
                              </ResetButton>
                            </ButtonWrapper>
                          </Grid>
                        </Grid>
                      </Paper>
                    </form>
                  </FormProvider>
                  <Paper>
                    <Table>
                      <TableHead
                        sx={{
                          padding: "10px 10px",
                          fontWeight: 500,
                          boxShadow: "4px 0 8px rgba(0, 0, 0, 0.1)",
                          background: "#dbe9ed",
                          border: 0,
                        }}
                      >
                        <TableRow>
                          <TableCell>Insurance Company</TableCell>
                          <TableCell>A</TableCell>
                          <TableCell>B</TableCell>
                          <TableCell>C</TableCell>
                          <TableCell>N/A</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody
                        sx={{
                          "& .MuiTableCell-body": {
                            padding: "8px 12px",
                          },
                          "& .MuiRadio-root": {
                            padding: 0,
                          },
                        }}
                      >
                        {conditionalInsuranceList?.map((item) => (
                          <>
                            <TableRow>
                              <TableCell>{item?.insurance_name}</TableCell>
                              {SURVEYOR_CATEGORY_LIST.map((category) => {
                                return (
                                  <TableCell key={category}>
                                    <Tooltip
                                      title={toolTipContent(category, item)}
                                      TransitionProps={{ timeout: 200 }}
                                      placement="top"
                                      arrow
                                    >
                                      <Radio
                                        name={item?.ic_slug}
                                        value={category}
                                        checked={
                                          mappedICs?.[item?.insurance_id] ===
                                            category || false
                                        }
                                        disabled={conditionallyDisableRadioButton(
                                          category,
                                          item
                                        )}
                                        onChange={(e) => {
                                          setMappedICs((prev) => ({
                                            ...prev,
                                            [item?.insurance_id]:
                                              e.target.value,
                                          }));
                                        }}
                                      />
                                    </Tooltip>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                  <Box width="100%" padding={"15px"} textAlign={"center"}>
                    <Button onClick={onSubmit} variant="contained">
                      Submit
                    </Button>
                  </Box>
                </>
              )}
            </>
          </Grid>
        </Grid>
      </Navbar>
    </>
  );
};
export default withRouteProtection(MapICToSelectedSurveyor);
