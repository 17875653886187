import { useContext } from "react";
import { AuthContext } from "./withRouteProtection";

const WriteAccessControl = ({ children, showWithReadAccess }) => {
  const { writable } = useContext(AuthContext);
  if (writable || showWithReadAccess) return children;
  else return <></>;
};

export default WriteAccessControl;
