import React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import ClaimSummary from "./ClaimSummary";

const ClaimSummaryPopup = ({
  setOpenDetails,
  openDetails,
  methods,
  alertMsg,
  submitForm,
}) => {
  return (
    <PopupWrapper className="popup-scroll">
      <ClaimSummary
        methods={methods}
        alertMsg={alertMsg}
        submitForm={submitForm}
        setOpenDetails={setOpenDetails}
        openDetails={openDetails}
      />
    </PopupWrapper>
  );
};

export default ClaimSummaryPopup;

const PopupWrapper = styled(Box)(({ theme }) => ({
  position: "fixed",
  left: 0,
  right: 0,
  top: "100px",
  display: "flex",
  width: "94%",
  borderRadius: "15px",
  margin: "auto",
  backgroundColor: "white",
  zIndex: 2,
  [theme.breakpoints.down("md")]: {
    maxWidth: "480px",
    maxHeight: "75vh",
    overflowY: "auto",
  },
}));
