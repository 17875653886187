import * as yup from "yup";

export const lsoValidationSchema = yup.object().shape({
  ic_id: yup.string().nullable().required("Insurance company is required"),
  lso_name: yup.string().nullable().required("LSO name is required"),
  lso_code: yup.string().nullable().required("LSO code is required"),
  address1: yup
    .string()
    .nullable()
    .required("Address line 1 is required")
    .min(5, "Address line 1 must contain at least five characters"),
  lso_state_id: yup.string().nullable().required("LSO state name is required"),
  lso_city_id: yup.string().nullable().required("LSO city name is required"),
  lso_pincode: yup
    .string()
    .nullable()
    .required("LSO pincode is required")
    .matches(/^[1-9]{1}[0-9]{5}$/, "Please enter a valid pincode"),
  oem_id: yup.array().test("check_any", "OEM is required", function (value) {
    if (this.parent.is_nodal_office === "N") return true;
    return value?.length > 0;
  }),
});
