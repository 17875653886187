import React from "react";
import {
  SummaryChild,
  SummaryHeading,
  SummarySubContainer,
  SummarySubHeading,
} from "../../../../summary/summarystyle";
import { StyledGrid } from "../../../../masters/components/MasterStyle";
import moment from "moment";

function AccidentDetails({ claimDetails }) {
  const vehiclePartArray = claimDetails?.vehicle_part?.split(",");
  const vehiclePart = vehiclePartArray?.map(
    (part, index) => (index ? ", " : "") + part
  );
  return (
    <SummarySubContainer>
      <StyledGrid container columns={12} spacing={0}>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Nature of Accident
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.nature_of_accident}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Third Party Loss</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.is_tp_loss}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Accident City Pincode
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.pincode_of_accident}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Accident State</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.accident_state_name}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Accident City</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.accident_city_name}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Place of Accident</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.place_of_accident}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Date and Time of Accident
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {moment(claimDetails?.date_of_accident).format(
              "DD-MMM-YYYY hh:mm A"
            )}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">
            Was Vehicle Parked?
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.is_vehicle_parked}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Catastrophic Code</SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.catastrophic_code_name || "---"}
          </SummarySubHeading>
        </SummaryChild>
        <SummaryChild item xmd={4} xxs={6} xs={6} sm={6} md={6}>
          <SummaryHeading variant="subtitle1">Part of Vehicle</SummaryHeading>
          <p
            style={{
              wordWrap: "break-word",
              overflowWrap: "break-word",
              color: "#6e8185",
              fontSize: "13px",
              margin: "0 5px",
            }}
          >
            {vehiclePart}
          </p>
        </SummaryChild>
        <SummaryChild item lg={4} xxs={12} xs={12} sm={12} md={12} xmd={8}>
          <SummaryHeading variant="subtitle1">
            Brief Description of Accident
          </SummaryHeading>
          <SummarySubHeading variant="subtitle2">
            {claimDetails?.description_of_accident}
          </SummarySubHeading>
        </SummaryChild>
      </StyledGrid>
    </SummarySubContainer>
  );
}

export default AccidentDetails;
