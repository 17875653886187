import { createSlice } from "@reduxjs/toolkit";

const addOnConfig = createSlice({
  name: "addOnConfig",
  initialState: {
    config_list: [],
    edit_config: null,
    config_status: "Y",
  },
  reducers: {
    addCalculation: (state, action) => {
      state.config_list = [...state.config_list, action.payload];

      // return { ...state, config_list: [...state.config_list, ...action.payload] };
    },
    removeCalculation: (state, action) => {
      state.config_list = state.config_list.filter(
        (item, idx) => idx !== action.payload
      );
    },
    removeAllCalculation: (state) => {
      state.config_list = [];
    },
    editCalculation: (state, action) => {
      state.edit_config = action.payload;
    },
    updateCalculation: (state, action) => {
      state.config_list[state.edit_config || 0] = {
        ...state.config_list[state.edit_config || 0],
        ...action.payload,
      };
      state.edit_config = null;
    },
    setConfigList: (state, action) => {
      state.config_list = action.payload;
    },
    setConfigStatus: (state, action) => {
      state.config_status = action.payload;
    },
    resetAddonConfigStates: (state) => {
      state.config_list = [];
      state.edit_config = null;
    },
  },
});
export const {
  addCalculation,
  removeCalculation,
  removeAllCalculation,
  editCalculation,
  updateCalculation,
  setConfigList,
  setConfigStatus,
  resetAddonConfigStates,
} = addOnConfig.actions;
export default addOnConfig.reducer;
