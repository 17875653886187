import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, styled, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { setShowPresetForm } from "../../modules/dashboard.slice";
import { useDispatch } from "react-redux";

function DialogBox({
  title = "Bi-Fuel Kit (CNC/LPG)",
  open,
  setOpen,
  onCancel,
  isforPreset,
  ...props
}) {
  const dispatch = useDispatch();
  const handleClose = (event) => {
    event.stopPropagation();
    onCancel && onCancel();
    setOpen && setOpen(false);
    isforPreset && dispatch(setShowPresetForm(false));
  };
  const lessThan567 = useMediaQuery("(max-width: 576px)");

  return (
    <Dialog
      fullScreen={
        lessThan567 && (title === "Addons" || title === "Filters")
          ? true
          : false
      }
      open={open}
      onClose={handleClose}
    >
      <DialogTitles>
        <DialogText>{title} </DialogText>
        <CrossIcon onClick={handleClose}>
          <CloseIcon
            fontSize="small"
            sx={{
              color: "#000",
              fontWeight: "bolder",
            }}
          />
        </CrossIcon>
      </DialogTitles>
      <DialogContent>{props.children && props.children}</DialogContent>
    </Dialog>
  );
}

export default DialogBox;

export const DialogTitles = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const DialogText = styled(Box)({
  fontSize: "15px",
  fontWeight: "600",
  color: "#000",
  padding: "0.4rem 0.6rem 0.2rem 0.8rem",
  backgroundImage: "linear-gradient(to right, #03cc83, #fff 30px)",
  borderRadius: "8px",
});

export const CrossIcon = styled(Box)({
  backgroundColor: "rgba(170, 174, 194, 0.47)",
  borderRadius: "50%",
  opacity: "0.37",
  width: "28px",
  height: "28px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
});

export const DialogButtonWrapper = styled(DialogActions)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const SubmitButton = styled(Button)({
  backgroundColor: "#03cc83",
  color: "#fff",
  fontWeight: "600",
  borderRadius: "20px",
  padding: "0.4rem 1.5rem",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#03cc83",
  },
});

export const SummaryPaymentButton = styled(SubmitButton)({
  whiteSpace: "nowrap",
});

export const ResetButton = styled(Button)({
  backgroundColor: "#fff",
  fontWeight: "600",
  borderRadius: "20px",
  padding: "0.4rem 1.5rem",
  textTransform: "capitalize",
});

export const DialogContentContainer = styled(DialogContent)({
  display: "flex",
  padding: 0,
  paddingTop: "0",
  paddingBottom: "0",
  gap: "0.7rem",
  justifyContent: "center",
  alignItems: "center",
});

export const DialogContentTextWrapper = styled(DialogContentText)({
  width: "100%",
  height: "100%",
});
