import { Link } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useGlobalSearch } from "./hooks/useGlobalSearch";
import {
  GlobalSearchContainer,
  SearchInput,
  SuggestionItem,
  SuggestionWrapper,
} from "./styles/globalsearch.styles";

const GlobalSearch = () => {
  const {
    onSearch,
    filteredOptions,
    showSuggestions,
    handleClick,
    keyboardControl: { handleKeyDown, currentSelectedSuggestion },
    ref: { searchInputRef, suggestionBoxRef, containerRef },
    handleFocus,
  } = useGlobalSearch();
  return (
    <GlobalSearchContainer ref={containerRef}>
      <SearchInput
        placeholder="Search..."
        onChange={onSearch}
        onKeyDown={handleKeyDown}
        inputRef={searchInputRef}
        onFocus={handleFocus}
        fullWidth
        id="search_text_input"
      />
      <SuggestionWrapper
        display={!showSuggestions && "none"}
        ref={suggestionBoxRef}
      >
        {filteredOptions.length > 0 ? (
          filteredOptions.map((item, index) => (
            <SuggestionItem
              key={item.menu_slug}
              id={`global-suggestion-item-${index}`}
              active={index === currentSelectedSuggestion}
              onClick={(e) => handleClick(e, item)}
            >
              <Link /> {item.menu_name}
            </SuggestionItem>
          ))
        ) : (
          <Typography align="center" color="text.secondary" pt="8px">
            No Record Found
          </Typography>
        )}
      </SuggestionWrapper>
    </GlobalSearchContainer>
  );
};
export default GlobalSearch;
