/*
This file is responsible for both IC-OEM mapping and IC-Broker mapping
It consists of table which uses react-virtualized library
*/

import React, { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MainContainer, ResetButton } from "../../Claim/claimStyledComponents";
import { ButtonWrapper, Heading } from "../../masters/components/MasterStyle";
import AlertMessage from "../../../components/Alert/AlertMessage";
import { Box, Grid, Skeleton } from "@mui/material";
import {
  useGetIcBrokerlisting,
  useGetMaster,
  useGetOemList,
  useGetSortedMaster,
  useUpdateIcBrokerlisting,
} from "../../../services/master.service";
import Select from "../../../components/InputComponents/Select";
import ReplayIcon from "@mui/icons-material/Replay";
import Buttons from "../../../components/BasicFormComponent/Buttons";
import CustomTable from "../../masters/MasterTable/CustomTable";
import CheckBox from "../../../components/BasicFormComponent/CheckBox";
import { useLocation } from "react-router";
import { icBrokerMappingValidationSchema } from "./ICBrokerMappingValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import Navbar from "../../../components/Navbar";
import _ from "lodash";

const ICBrokerMapping = ({ type }) => {
  const location = useLocation();
  const {
    sendIcBrokerrMapData,
    column_data,
    row_data,
    isLoading,
    isSuccess,
    resetMaster,
  } = useGetIcBrokerlisting(type);
  const {
    updateIcBrokerrMapData,
    isSuccess: isSuccessForTableData,
    isError: isErrorForTableData,
    alertMaster: alertMasterForTableData,
    resetMaster: resetMasterForTableData,
    isLoading: isLoadingForTableData,
  } = useUpdateIcBrokerlisting(type);
  const methodsSearch = useForm({
    resolver: yupResolver(icBrokerMappingValidationSchema),
    defaultValues: {
      select: "",
      ic_id: "",
      broker_id: "",
      oem_id: "",
      mapping_by: "",
    },
    shouldUnregister: true,
  });
  const handleReset = () => {
    methodsSearch.reset({
      select: "",
      ic_id: "",
      broker_id: "",
      oem_id: "",
      mapping_by: "",
    });
  };

  const ic = methodsSearch.watch("mapping_by") === "ic";
  const broker = methodsSearch.watch("mapping_by") === "broker";
  const oem = methodsSearch.watch("mapping_by") === "oem";

  const methodsTable = useForm({
    defaultValues: {},
    shouldUnregister: false,
  });

  const dynamicComparisionKey = (item, type) => {
    if (type === "ic_broker" && item?.broker_name) {
      return item?.broker_name;
    }
    if (type === "ic_oem" && item?.oem_name) {
      return item?.oem_name;
    }
    if (item?.insurance_name) {
      return item?.insurance_name;
    }
  };
  const dynamicCheckboxKey = (item, type) => {
    if (type === "ic_broker" && ic) {
      return String(item?.broker_id);
    } else if (type === "ic_oem" && ic) {
      return String(item?.oem_id);
    } else {
      return String(item?.insurance_id);
    }
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      methodsTable.reset({
        ...row_data?.reduce((acc, item) => {
          return {
            ...acc,
            [(type === "ic_broker" && ic && item?.broker_id) ||
            (type === "ic_oem" && ic && item?.oem_id) ||
            item?.insurance_id]: item?.is_mapped,
          };
        }, {}),
      });
    }
  }, [isLoading, isSuccess]);

  const { table_list: insurance_items } = useGetMaster({
    master_name: "insurance",
  });
  const { table_list: broker_items } = useGetSortedMaster({
    master_name: "broker",
    sortBy: "broker_name",
  });
  const { oem_items } = useGetOemList();

  const insurance_list = useMemo(() => {
    return insurance_items
      ?.map((item) => {
        return {
          code: item?.insurance_id,
          display_name: item?.insurance_name,
        };
      })
      .sort((a, b) => a?.display_name.localeCompare(b?.display_name));
  }, [insurance_items]);
  const broker_list = useMemo(() => {
    return broker_items
      ?.map((item) => {
        return {
          code: item?.broker_id,
          display_name: item?.broker_name,
        };
      })
      .sort((a, b) => a?.display_name.localeCompare(b?.display_name));
  }, [broker_items]);
  const oem_list = useMemo(() => {
    return oem_items
      ?.map((item) => {
        return {
          code: item?.oem_id,
          display_name: item?.oem_name,
        };
      })
      .sort((a, b) => a?.display_name.localeCompare(b?.display_name));
  }, [oem_items]);

  const onSearchSubmit = (data) => {
    sendIcBrokerrMapData(data);
  };

  const onTableSubmit = (data) => {
    delete data.parent;
    broker &&
      updateIcBrokerrMapData({
        mapping: data,
        broker_id: methodsSearch.watch("broker_id"),
      });
    oem &&
      updateIcBrokerrMapData({
        mapping: data,
        oem_id: methodsSearch.watch("oem_id"),
      });
    ic &&
      updateIcBrokerrMapData({
        mapping: data,
        ic_id: methodsSearch.watch("ic_id"),
      });
  };

  useEffect(() => {
    if (isSuccessForTableData) {
      setTimeout(() => {
        handleReset();
        resetMaster();
        resetMasterForTableData();
      }, 3500);
    }
  }, [isSuccessForTableData, location?.pathname]);

  useEffect(() => {
    handleReset();
    resetMaster();
    resetMasterForTableData();
  }, [location?.pathname]);

  // handling indeterminate state for parent checkbox
  const actionParent = methodsTable.watch("parent");
  const rowKey = Object.keys(_.omit(methodsTable.watch(), "parent"));
  const rowValue = Object.values(_.omit(methodsTable.watch(), "parent"));

  const indeterminateState =
    rowValue?.some((item) => item === true) &&
    rowValue?.some((item) => item === false);

  useEffect(() => {
    if (rowValue?.length) {
      if (rowValue?.every((item) => item === true)) {
        methodsTable.setValue("parent", true);
      } else if (rowValue?.every((item) => item === false)) {
        methodsTable.setValue("parent", false);
      } else {
        methodsTable.setValue("parent", null);
      }
    }
  }, [JSON.stringify(rowValue)]);

  useEffect(() => {
    if (actionParent === true || actionParent === false) {
      rowKey?.map((item) => {
        return methodsTable.setValue(item, actionParent);
      });
    }
  }, [actionParent]);

  return (
    <Navbar>
      <FormProvider {...methodsSearch}>
        <form onSubmit={methodsSearch.handleSubmit(onSearchSubmit)}>
          <MainContainer style={{ margin: "30px 30px 0 30px" }}>
            <Heading variant="h5" align="left" mb={2}>
              {type === "ic_broker"
                ? "IC - Broker Mapping"
                : "IC - OEM Mapping"}
            </Heading>
            <AlertMessage
              alert={alertMasterForTableData}
              reset={resetMasterForTableData}
              isError={isErrorForTableData}
              isSuccess={isSuccessForTableData}
            />
            <Grid container columnSpacing={4} rowSpacing={2}>
              <Grid item xxs={12} xs={6} md={4} lg={4}>
                <Select
                  label={"Mapping By"}
                  name={"mapping_by"}
                  options={
                    type === "ic_broker"
                      ? [
                          { code: "ic", display_name: "Insurance Company" },
                          { code: "broker", display_name: "Broker" },
                        ]
                      : [
                          { code: "ic", display_name: "Insurance Company" },
                          { code: "oem", display_name: "OEM" },
                        ]
                  }
                  required
                  saveDisplayName={false}
                />
              </Grid>
              {ic && (
                <Grid item xxs={12} xs={6} md={4} lg={4}>
                  <Select
                    label={"Insurance Company"}
                    name={"ic_id"}
                    options={insurance_list}
                    required
                    saveDisplayName={false}
                  />
                </Grid>
              )}
              {broker && (
                <Grid item xxs={12} xs={6} md={4} lg={4}>
                  <Select
                    label={"Broker"}
                    name={"broker_id"}
                    options={broker_list}
                    required
                    saveDisplayName={false}
                  />
                </Grid>
              )}
              {oem && (
                <Grid item xxs={12} xs={6} md={4} lg={4}>
                  <Select
                    label={"OEM"}
                    name={"oem_id"}
                    options={oem_list}
                    required
                    saveDisplayName={false}
                  />
                </Grid>
              )}
              <Grid item xxs={12} xs={6} md={4} lg={4}>
                <Select
                  label={"Mapping type"}
                  name={"mapping_type"}
                  options={[
                    { code: "all", display_name: "All" },
                    { code: "mapped", display_name: "Mapped" },
                    { code: "unmapped", display_name: "Un Mapped" },
                  ]}
                  required
                  saveDisplayName={false}
                />
              </Grid>
            </Grid>
            <ButtonWrapper style={{ gap: "15px" }}>
              <Buttons
                id={"ic_mapping_search_button"}
                label={"Search"}
                type="submit"
                standard
              />
              <ResetButton id={"ic_mapping_reset_button"} onClick={handleReset}>
                <ReplayIcon />
                Reset
              </ResetButton>
            </ButtonWrapper>
          </MainContainer>
        </form>
      </FormProvider>
      <FormProvider {...methodsTable}>
        <form onSubmit={methodsTable.handleSubmit(onTableSubmit)}>
          {isLoading ? (
            <>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "auto",
                  margin: "3rem 2rem 1rem 2rem",
                  gap: "5px",
                }}
              >
                <Skeleton variant="rounded" height={50} />
                <Skeleton variant="rounded" height={50} />
                <Skeleton variant="rounded" height={50} />
                <Skeleton variant="rounded" height={50} />
                <Skeleton variant="rounded" height={50} />
                <Skeleton variant="rounded" height={50} />
              </Box>
            </>
          ) : (
            row_data && (
              <>
                <Box
                  style={{
                    width: "auto",
                    margin: "2rem 2rem 1rem 2rem",
                  }}
                >
                  <CustomTable
                    column_head={column_data}
                    row_data={row_data?.sort((a, b) =>
                      (dynamicComparisionKey(a, type) || "").localeCompare(
                        dynamicComparisionKey(b, type) || ""
                      )
                    )}
                    customRowAction={({ rowData }) => {
                      return (
                        <CheckBox
                          id={dynamicCheckboxKey(rowData, type)}
                          name={dynamicCheckboxKey(rowData, type)}
                        />
                      );
                    }}
                    customColumnAction={() => (
                      <>
                        <p>Action</p>
                        {!!row_data?.length && (
                          <CheckBox
                            name={"parent"}
                            indeterminate={indeterminateState}
                          />
                        )}
                      </>
                    )}
                  />
                </Box>
                {!!row_data?.length && (
                  <ButtonWrapper
                    style={{
                      justifyContent: "center",
                      marginTop: "0px",
                    }}
                  >
                    <Buttons
                      id={"ic_mapping_table_submit_button"}
                      disabled={isLoadingForTableData || isSuccessForTableData}
                      label={"submit"}
                      type="Submit"
                      standard
                    />
                  </ButtonWrapper>
                )}
              </>
            )
          )}
        </form>
      </FormProvider>
    </Navbar>
  );
};

export default ICBrokerMapping;
