import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Loader from "./app/Loader";
import InternetStatus from "./components/InternetStatus/InternetStatus";
import MultipleLoginRestriction from "./components/MultipleLoginRestriction";
import { useCheckMultiSessionSetting } from "./custom-hooks/useCheckMultipleSessionSetting";
import { routes } from "./routes/Routes";
import theme from "./styles/Theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      // keepPreviousData: false,
      // staleTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  },
});
const router = createBrowserRouter(routes);
function App() {
  // To check multi-session login setting
  const { restrictMultipleSessions } = useCheckMultiSessionSetting();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          {restrictMultipleSessions ? (
            <MultipleLoginRestriction />
          ) : (
            <Suspense fallback={<Loader />}>
              <RouterProvider router={router} fallbackElement={<></>} />
            </Suspense>
          )}

          <ReactQueryDevtools />
        </QueryClientProvider>
      </ThemeProvider>
      <InternetStatus />
      <ToastContainer limit={3} closeOnClick />
    </div>
  );
}

export default App;
