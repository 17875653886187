import { Autocomplete, Box, Checkbox, Chip } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { TextField, InputLabel } from "./InputComponents.style";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { reduceObj } from "../../utils/errorHandler";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const MultiSelect = ({
  name,
  label,
  placeholder,
  required,
  readOnly,
  options = [],
  isSelectAll = true,
  saveDisplayName = true,
  defaultSelectedValues = [],
  bordered,
}) => {
  const {
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useFormContext();
  const value = watch(name) || [];
  const reducedErrors = reduceObj(errors, name);

  useEffect(() => {
    if (typeof value === "string") {
      setValue(
        name,
        value
          ?.split(",")
          .map((val) => val?.trim())
          .filter((item) => {
            if (!options.some((option) => option.code == item)) return false;
            else return true;
          })
      );
    }
  }, [value]);

  const selectedOption = useMemo(() => {
    if (!(value instanceof Array)) return [];

    return (
      isSelectAll && options.length
        ? [{ code: "-1", display_name: "Select All" }, ...options]
        : options
    ).filter((item) => value.some((innerItem) => innerItem == item.code));
  }, [JSON.stringify(value), JSON.stringify(options)]);

  useEffect(() => {
    if (saveDisplayName && value && value?.length)
      setValue(
        `${name}_data`,
        options.filter((item) =>
          value.some((innerItem) => innerItem == item.code)
        )
      );
  }, [JSON.stringify(value), JSON.stringify(options)]);

  const isAllSelected = selectedOption.length >= options.length;

  return (
    <>
      <Box width="100%">
        <InputLabel title={label} required={required} bordered={bordered}>
          {label}
        </InputLabel>
        <Autocomplete
          getLimitTagsText={(number) => `+${number} more`}
          readOnly={readOnly}
          multiple
          id={name}
          options={
            isSelectAll && options.length
              ? [{ code: "-1", display_name: "Select All" }, ...options]
              : options
          }
          limitTags={2}
          renderInput={(params) => (
            <TextField
              error={!!reducedErrors}
              helperText={reducedErrors?.message}
              placeholder={placeholder}
              readOnly={readOnly}
              {...params}
              bordered={bordered}
              onKeyDown={(event) => {
                if (event.key === "Backspace") {
                  event.stopPropagation();
                }
              }}
            />
          )}
          getOptionDisabled={(option) => {
            if (defaultSelectedValues?.includes(option?.code) || readOnly)
              return true;
            return option.code !== "0" && value.some((item) => item === "0");
          }}
          disableClearable={defaultSelectedValues?.length > 0 || readOnly}
          disableCloseOnSelect
          onChange={(e, value) => {
            let selectAll = false;
            if (value.some((item) => item.code === "0")) {
              setValue(name, ["0"]);
              return;
            }

            const data = value.map((item) => {
              if (item.code === "-1" && isSelectAll) {
                selectAll = true;
              }
              return item.code;
            });
            if (selectAll && isAllSelected) {
              defaultSelectedValues?.length > 0 || readOnly
                ? ""
                : setValue(name, []);
            } else {
              setValue(
                name,
                selectAll ? options.map((item) => item.code) : data
              );
            }
            clearErrors(name);
          }}
          value={selectedOption}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => {
              const isDefaultValueSelected = defaultSelectedValues?.includes(
                option?.code
              );
              return (
                <Chip
                  {...getTagProps({ index })}
                  label={option.display_name}
                  deleteIcon={
                    <CancelIcon
                      sx={{
                        display: isDefaultValueSelected && "none",
                      }}
                    />
                  }
                  key={index}
                  sx={{
                    borderRadius: "5px",
                    paddingRight: "5px",
                    maxWidth: "200px",
                    maxHeight: bordered ? "" : "21px",
                    "& .MuiChip-deleteIcon": {
                      fontSize: bordered ? "" : "15px",
                    },
                  }}
                />
              );
            });
          }}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          getOptionLabel={(option) => option.display_name}
          renderOption={(props, option, { selected }) => (
            <li {...props} style={{ fontSize: "11px" }}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={option.code === "-1" ? isAllSelected : selected}
                indeterminate={
                  option.code === "-1"
                    ? selectedOption.length > 0 &&
                      selectedOption.length < options.length
                    : false
                }
                value={option.code}
                sx={{
                  padding: { xxs: "0px", xs: "5px" },
                  margin: { xxs: "0px" },
                }}
              />
              {option.display_name}
            </li>
          )}
        />
      </Box>
    </>
  );
};

export default MultiSelect;
