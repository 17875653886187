import { yupResolver } from "@hookform/resolvers/yup";
import { Box, useMediaQuery } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import * as yup from "yup";
import AlertMessage from "../../../../../components/Alert/AlertMessage";
import {
  useClaimRegistration,
  useEditClaimDetails,
  useGetPolicyDetails,
  useGetPolicyDetailsRegistration,
} from "../../../../../services/claims.service";
import { Container, Wrapper } from "../../../claimStyledComponents";
import ClaimDetailsFooter from "../../../components/ClaimDetailsFooter";
import ClaimSummary from "../../../components/ClaimSummary";
import ClaimSummaryPopup from "../../../components/ClaimSummaryPopup";
import { useClaimStage } from "../../../hooks/useClaimStage";
import { useDefaultValues } from "../../../hooks/useDefaultValues";
import RegistrationAddForm from "./ClaimIntimationForm";
import { accidentDetailsSchema } from "../../../validations/accidentDetails.validations";
import { contactDetailsSchema } from "../../../validations/contactDetails.validations";
import { driverDetailsSchema } from "../../../validations/driverDetails.validations";
import { estimationDetailsSchema } from "../../../validations/estimationDetails.validations";
import { AppointSurveyorSchema } from "../../../AppointSurveyor/AppointSurveyorValidation";
import { surveyorAssessmentSchema } from "./forms/SurveyorAssessmentForm/validations/surveyorAssessmentFormValidations";
import { proformaInvoiceSchema } from "./forms/DocumentForm/validations/proformaInvoice.validations";
import { claimInvoiceSchema } from "./forms/DocumentForm/validations/claimInvoice.validations";
import { claimHoldStatusSchema } from "../../../validations/claimHoldStatus.validations";

const ClaimIntimation = () => {
  const { claim_detail_id, booking_id } = useParams();
  const [showEditableFields, setShowEditableFields] = useState(false);

  const { policy_details_list: details_from_booking_id } =
    useGetPolicyDetails(booking_id);

  const { policy_details_list: details_from_claim_details_id } =
    useGetPolicyDetailsRegistration(claim_detail_id);

  const claimDetails = details_from_claim_details_id[0];

  const conditionalClaimStage = () => {
    if (claim_detail_id) {
      return details_from_claim_details_id[0]?.claim_stage;
    } else return details_from_booking_id[0]?.claim_stage;
  };

  const claimStage = conditionalClaimStage();

  const {
    component_visibility_conditions: {
      showClaimIntimationDetails,
      showClaimRegistrationDetails,
      showSurveyorAppointmentDetails,
      showSurveyorAssessmentDetails,
      showProformaInvoiceDetails,
      showClaimHoldStatusForm,
      showClaimInvoiceDetails,
      isStatusNil,
    },
    roles: { isClaimAdmin, isDealer },
  } = useClaimStage({
    claim_stage: claimStage,
  });

  const isOnClaimRegistrationPage = location.pathname
    .split("/")
    .includes("claim_registration");

  // TODO: change into readable form
  const combinedClaimIntimationValidation = yup.object().shape({
    ...(isClaimAdmin && !showEditableFields
      ? {
          ...(showClaimHoldStatusForm && claimHoldStatusSchema.fields),
        }
      : {
          ...(showEditableFields &&
            isStatusNil &&
            showClaimIntimationDetails &&
            accidentDetailsSchema.fields),
          ...(showEditableFields &&
            isStatusNil &&
            showClaimIntimationDetails &&
            contactDetailsSchema.fields),
          ...(showEditableFields &&
            isStatusNil &&
            isOnClaimRegistrationPage &&
            showClaimRegistrationDetails &&
            driverDetailsSchema.fields),
          ...(showEditableFields &&
            isStatusNil &&
            isOnClaimRegistrationPage &&
            showClaimRegistrationDetails &&
            estimationDetailsSchema.fields),
          ...(showEditableFields &&
            showSurveyorAppointmentDetails &&
            AppointSurveyorSchema.fields),
          ...(showEditableFields &&
            showSurveyorAssessmentDetails &&
            surveyorAssessmentSchema.fields),
          ...(showEditableFields &&
            showProformaInvoiceDetails &&
            proformaInvoiceSchema.fields),
          ...(showEditableFields &&
            showClaimInvoiceDetails &&
            claimInvoiceSchema.fields),
          ...(showEditableFields &&
            isClaimAdmin &&
            showClaimHoldStatusForm &&
            claimHoldStatusSchema.fields),
        }),
  });

  const methods = useForm({
    defaultValues: {
      dealer_by: "accident_place",
      isAuthorised: "Yes",
      claim_status: "open",
    },
    resolver: yupResolver(combinedClaimIntimationValidation),
    shouldUnregister: true,
  });
  const { watch, setValue, trigger, handleSubmit, formState, clearErrors } =
    methods;

  // To check while testing
  console.log("zz", methods.formState.errors);

  const {
    createClaimRegistration,
    isSuccess,
    alertMaster,
    resetMaster,
    isError,
    isLoading,
  } = useClaimRegistration();

  const {
    editClaimDetails,
    isSuccessForEdit,
    isErrorForEdit,
    alertMasterForEdit,
    resetMasterForEdit,
  } = useEditClaimDetails();

  // To reset to default & selected values
  useDefaultValues({ formMethods: methods, showEditableFields });

  const labourCost = watch("labour_cost");
  const partsCost = watch("parts_cost");
  const metalCost = watch("metal_cost");
  const paintCost = watch("paint_cost");
  const totalOtherCost = watch("total_other_cost");

  useEffect(() => {
    setValue(
      "total_estimation",
      parseInt(labourCost || 0) +
        parseInt(partsCost || 0) +
        parseInt(metalCost || 0) +
        parseInt(paintCost || 0) +
        parseInt(totalOtherCost || 0) || "0"
    );
  }, [labourCost, partsCost, metalCost, paintCost, totalOtherCost]);

  const idvPrice = watch("idv");
  const totalEstimation = watch("total_estimation");
  useEffect(() => {
    if (
      totalEstimation > idvPrice ||
      (formState.submitCount > 0 && totalEstimation == 0)
    ) {
      trigger("total_estimation");
    } else {
      clearErrors("total_estimation");
    }
  }, [
    idvPrice,
    totalEstimation,
    labourCost,
    partsCost,
    metalCost,
    paintCost,
    totalOtherCost,
    formState.submitCount,
  ]);

  useEffect(() => {
    if (Object.keys(formState.errors).length) {
      trigger("registration_certificate");
      trigger("driving_license");
      trigger("fir_certificate");
      trigger("estimated_certificate");
      trigger("other_certificate");
      trigger("atLeastOneOf");
    }
  }, [Object.keys(formState.errors).length]);

  const onSubmit = (data) => {
    let claimregistrationData = {
      ...data,
      claim_detail_id,
      booking_id,
      date_of_accident: moment(data?.date_of_accident).format(
        "DD-MM-YYYY hh:mm A"
      ),
      claim_stage: claimStage,
    };
    if (showEditableFields && isClaimAdmin) {
      return editClaimDetails(claimregistrationData);
    } else if (!showEditableFields && isClaimAdmin) {
      const newPayload = {
        claim_status: data.claim_status,
        remark: data.remark,
        claim_detail_id,
      };
      return editClaimDetails(newPayload);
    }
    createClaimRegistration(claimregistrationData);
  };

  const [openDetails, setOpenDetails] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const showDocumentsection = true;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <Container>
            {!isMobile ? (
              <ClaimSummary
                showDocumentsection={showDocumentsection}
                isMobile={isMobile}
              />
            ) : (
              <>
                <ClaimDetailsFooter
                  openDetails={openDetails}
                  setOpenDetails={setOpenDetails}
                />
                {openDetails && (
                  <>
                    <ClaimSummaryPopup
                      setOpenDetails={setOpenDetails}
                      openDetails={openDetails}
                      showDocumentsection={showDocumentsection}
                      isMobile={isMobile}
                    />
                    <div className="mobileProposalBlur" />
                  </>
                )}
              </>
            )}

            <Box>
              <AlertMessage
                alert={alertMaster}
                reset={resetMaster}
                isError={isError}
                isSuccess={isSuccess}
              />
              {/* TODO: rename component */}
              <RegistrationAddForm
                claimAdminUser={isClaimAdmin}
                isDealer={isDealer}
                isMobile={isMobile}
                alertMsg={{
                  isSuccess,
                  isLoading,
                }}
                isSuccessForEdit={isSuccessForEdit}
                isErrorForEdit={isErrorForEdit}
                alertMasterForEdit={alertMasterForEdit}
                resetMasterForEdit={resetMasterForEdit}
                showEditableFields={showEditableFields}
                setShowEditableFields={setShowEditableFields}
                claimStage={claimStage}
                claimDetails={claimDetails}
              />
            </Box>
          </Container>
        </Wrapper>
      </form>
    </FormProvider>
  );
};

export default ClaimIntimation;
