import { Grid } from "@mui/material";
import React from "react";
import AlertMessage from "../../../../../../components/Alert/AlertMessage";
import TextArea from "../../../../../../components/BasicFormComponent/TextArea";
import Radio from "../../../../../../components/InputComponents/Radio";
import {
  ClaimHeading,
  MainPaperContainer,
} from "../../../../styles/claim.styles";

function StatusForm({
  isSuccessForEdit,
  isErrorForEdit,
  alertMasterForEdit,
  resetMasterForEdit,
}) {
  return (
    <MainPaperContainer>
      <ClaimHeading>Claim Status</ClaimHeading>

      <AlertMessage
        alert={alertMasterForEdit}
        reset={resetMasterForEdit}
        isError={isErrorForEdit}
        isSuccess={isSuccessForEdit}
      />

      <Grid container spacing={2} mt={0} px={2}>
        <Grid item xxs={12} md={5}>
          <Radio
            label="Status"
            name="claim_status"
            row
            color="opposite"
            required
            options={[
              { code: "open", display_name: "Open" },
              { code: "hold", display_name: "Hold" },
            ]}
          />
        </Grid>
        <Grid item xxs={12} md={7}>
          <TextArea
            label="Remark"
            name="hold_remark"
            standard
            maxLength={300}
          />
        </Grid>
      </Grid>
    </MainPaperContainer>
  );
}

export default StatusForm;
