import { Button, ButtonGroup, styled } from "@mui/material";

export const PolicyListingButtonGroup = styled(ButtonGroup)({
  "& .MuiButtonGroup-grouped": {
    padding: "1px 5px",
    height: "25px",
    fontSize: "10px",
  },
});

export const PlicyListingRetryPdfButton = styled(Button)({
  border: "1px solid rgba(3, 204, 131, 0.5)",
  padding: "1px 5px",
  height: "25px",
  fontSize: "10px",
});
