import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import {
  allowOnlyAlphabetsSpaceFirstLetterCapital,
  allowOnlyNumbers,
} from "../../../../../../InputUtils/onInput";
import Select from "../../../../../../components/InputComponents/Select";
import TextInput from "../../../../../../components/InputComponents/TextInput";
import { claimIntimatedByOptions } from "../../../../../../constants/options";
import { useFormContext } from "react-hook-form";
import {
  useGetPolicyDetails,
  useGetPolicyDetailsRegistration,
} from "../../../../../../services/claims.service";
import { useParams } from "react-router";

function ContactDetails() {
  const { watch, setValue } = useFormContext();

  const { booking_id, claim_detail_id } = useParams();

  const { policy_details_list } = useGetPolicyDetails(booking_id);
  const { policy_details_list: details_from_claim_details_id } =
    useGetPolicyDetailsRegistration(claim_detail_id);

  const claim_details_from_booking_id = policy_details_list?.[0];
  const claim_details_from_claim_id = details_from_claim_details_id?.[0];

  const claim_intimated_by = watch("claim_intimated_by");

  useEffect(() => {
    if (claim_intimated_by === "owner") {
      setValue("intimator_name", claim_details_from_booking_id?.insured_name);
      setValue("intimator_mobile_no", claim_details_from_booking_id?.mobile);
    }
    if (claim_intimated_by === "representative") {
      setValue(
        "intimator_name",
        claim_details_from_claim_id?.intimator_name || ""
      );
      setValue(
        "intimator_mobile_no",
        claim_details_from_claim_id?.intimator_mobile_no || ""
      );
    }
  }, [claim_intimated_by, JSON.stringify(claim_details_from_booking_id)]);

  return (
    <Grid container spacing={2} mt={0} px={2}>
      <Grid item lg={4} xxs={6}>
        <Select
          label="Claim Intimated By"
          name="claim_intimated_by"
          variant="standard"
          required={true}
          options={claimIntimatedByOptions}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Intimator Name"
          name="intimator_name"
          required={true}
          standard
          onInput={allowOnlyAlphabetsSpaceFirstLetterCapital}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Intimator Mobile No."
          name="intimator_mobile_no"
          required={true}
          standard
          onInput={allowOnlyNumbers}
          inputProps={{ maxLength: 10 }}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Customer's Current Mobile No."
          name="customer_current_mobile_no"
          standard
          required={true}
          onInput={allowOnlyNumbers}
          inputProps={{ maxLength: 10 }}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Customer's Current Email ID"
          name="customer_current_email_id"
          required={true}
          standard
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Driver Name"
          name="driver_name"
          required={true}
          standard
          onInput={allowOnlyAlphabetsSpaceFirstLetterCapital}
        />
      </Grid>
    </Grid>
  );
}

export default ContactDetails;
