import { yupResolver } from "@hookform/resolvers/yup";
import ReplayIcon from "@mui/icons-material/Replay";
import { Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { allowOnlyNumbers } from "../../../InputUtils/onInput";
import AlertMessage from "../../../components/Alert/AlertMessage";
import Buttons from "../../../components/BasicFormComponent/Buttons";
import Select from "../../../components/InputComponents/Select";
import TextInput from "../../../components/InputComponents/TextInput";
import {
  useGetAllLsoDetails,
  useSearchLsoDealerMapData,
  useSendLsoDealerMapTableData,
} from "../../../services/icConfigurator.service";
import { useGetDealersFromMultipleFilters } from "../../../services/icMapping.service";
import {
  useGetDealerGroupFromOEM,
  useGetIcBrokerlisting,
  useGetSortedMaster,
} from "../../../services/master.service";
import { useGetCityFromState } from "../../../services/pincode.service";
import { MainContainer, ResetButton } from "../../Claim/claimStyledComponents";
import { ButtonWrapper, Heading } from "../../masters/components/MasterStyle";
import IcDealerMappingSkeleton from "../ICConfigurator/forms/ICDealerMapping/Components/IcDealerMappingSkeleton";
import LSODealerMappingTable from "./LSODealerMappingTable";
import withRouteProtection from "../../../hoc/withRouteProtection";
import { solicitationTypeOptions } from "../../../constants/options";
import Navbar from "../../../components/Navbar";

function LSODealerMappingSearch() {
  const {
    sendLsoDealerMapData,
    column_data,
    row_data,
    isLoading,
    isSuccess,
    resetMaster,
  } = useSearchLsoDealerMapData();

  const {
    sendLsoDealerMapTableData,
    isSuccess: isSuccessForTableData,
    isError: isErrorForTableData,
    alertMaster: alertMasterForTableData,
    resetMaster: resetMasterForTableData,
  } = useSendLsoDealerMapTableData();

  const { workshop_fields, sales_fields } = useGetAllLsoDetails();

  const defaultValuesForLso = row_data?.reduce((prev, item) => {
    return {
      ...prev,
      [item.dealer_id]: item?.lso_id || "",
    };
  }, {});

  const searchFormSchema = yup.object().shape({
    insurance_company: yup.string().required("Insurance company is required"),
    oem_id: yup.string().required("OEM is required"),
  });

  const methodsForSearchForm = useForm({
    resolver: yupResolver(searchFormSchema),
    defaultValues: {
      insurance_company: "",
      dealer_state: "",
      dealer_city: "",
      solicitation_type: "",
      dealer_code: "",
      dealer_name: "",
    },
  });

  const ic_id = methodsForSearchForm.watch("insurance_company");
  const oem_id = methodsForSearchForm.watch("oem_id");
  const dealer_state = methodsForSearchForm.watch("dealer_state");
  const dealer_city = methodsForSearchForm.watch("dealer_city");
  const solicitation_type = methodsForSearchForm.watch("solicitation_type");
  const dealer_code = methodsForSearchForm.watch("dealer_code");
  const dealer_group = methodsForSearchForm.watch("dealer_group");

  const { sendIcBrokerrMapData, row_data: oem_items } =
    useGetIcBrokerlisting("ic_oem");
  useEffect(() => {
    ic_id &&
      sendIcBrokerrMapData({
        ic_id: ic_id,
        mapping_by: "ic",
        mapping_type: "mapped",
      });
  }, [ic_id]);

  const { table_list: dealer_group_items } = useGetDealerGroupFromOEM(oem_id);
  // dealer list
  const { dealer_items } = useGetDealersFromMultipleFilters({
    ic_id,
    oem_id,
    dealer_state,
    dealer_city,
    solicitation_type,
    dealer_code,
    dealer_group,
  });

  const dealer_list = useMemo(() => {
    return dealer_items?.map((item) => ({
      code: item?.dealer_id,
      display_name: item?.dealer_name,
    }));
  }, [dealer_items]);

  // methods for table
  const methodsForIcDealerMappingTable = useForm({
    defaultValues: { ...defaultValuesForLso },
  });

  useEffect(() => {
    methodsForIcDealerMappingTable.reset({ ...defaultValuesForLso });
  }, [row_data, workshop_fields, sales_fields]);

  // data from masters
  const { table_list: insurance_items } = useGetSortedMaster({
    master_name: "insurance",
    sortBy: "insurance_name",
  });

  const { table_list: state_items } = useGetSortedMaster({
    master_name: "state",
    sortBy: "state_name",
  });

  const { table_list: city_items } = useGetCityFromState(
    methodsForSearchForm.watch("dealer_state")
  );

  // insurance company list
  const insurance_list = useMemo(() => {
    return insurance_items?.map((item) => {
      return {
        code: item.insurance_id,
        display_name: item.insurance_name,
      };
    });
  }, [insurance_items]);

  const oem_list = useMemo(() => {
    return oem_items?.map((item) => {
      return {
        code: item?.oem_id,
        display_name: item?.oem_name,
      };
    });
  }, [oem_items]);

  // state list
  const state_list = useMemo(() => {
    return state_items?.map((item) => {
      return {
        code: item.state_id,
        display_name: item.state_name,
      };
    });
  }, [state_items]);

  // city list
  const city_list = useMemo(() => {
    return city_items?.map((item) => {
      return {
        code: item.city_id,
        display_name: item.city_name,
      };
    });
  }, [city_items]);

  // LSO lists
  const workshop_list = useMemo(() => {
    return workshop_fields?.map((item) => {
      return {
        code: item.lso_id,
        display_name: item.lso_name,
      };
    });
  }, [workshop_fields]);

  const sales_list = useMemo(() => {
    return sales_fields?.map((item) => {
      return {
        code: item.lso_id,
        display_name: item.lso_name,
      };
    });
  }, [sales_fields]);

  const dealer_group_list = useMemo(() => {
    return dealer_group_items?.map((item) => {
      return {
        code: item?.dealer_group_id,
        display_name: item?.groups_name,
      };
    });
  }, [dealer_group_items]);

  const onSubmitForSearchForm = (data) => {
    sendLsoDealerMapData(data);
  };

  const onSubmitForIcDealerMappingTable = (data) => {
    data.ic_id = ic_id;
    sendLsoDealerMapTableData(data);
  };

  const handleReset = () => {
    methodsForSearchForm.reset({
      insurance_company: "",
      dealer_state: "",
      dealer_city: "",
      solicitation_type: "",
      dealer_code: "",
      dealer_name: "",
    });
  };
  useEffect(() => {
    if (isSuccessForTableData) {
      setTimeout(() => {
        handleReset();
        resetMaster();
        resetMasterForTableData();
      }, 2500);
    }
  }, [isSuccessForTableData]);

  return (
    <Navbar>
      <FormProvider {...methodsForSearchForm}>
        <form
          onSubmit={methodsForSearchForm.handleSubmit(onSubmitForSearchForm)}
        >
          <MainContainer style={{ margin: "30px 30px 0 30px" }}>
            <Heading variant="h5" align="left" mb={2}>
              LSO Dealer Mapping
            </Heading>
            <AlertMessage
              alert={alertMasterForTableData}
              reset={resetMasterForTableData}
              isError={isErrorForTableData}
              isSuccess={isSuccessForTableData}
            />
            <Grid container columnSpacing={4} rowSpacing={2}>
              <Grid item xxs={12} xs={6} md={3} lg={4}>
                <Select
                  name={"insurance_company"}
                  label={"Insurance Company"}
                  options={insurance_list}
                  required
                  saveDisplayName={false}
                />
              </Grid>
              <Grid item xxs={12} xs={6} md={3} lg={4}>
                <Select
                  name={"oem_id"}
                  label={"OEM"}
                  options={oem_list}
                  required
                  saveDisplayName={false}
                />
              </Grid>
              <Grid item xxs={12} xs={6} md={3} lg={4}>
                <Select
                  name={"dealer_state"}
                  label={"Dealer State"}
                  options={state_list}
                  saveDisplayName={false}
                />
              </Grid>
              <Grid item xxs={12} xs={6} md={3} lg={4}>
                <Select
                  name={"dealer_city"}
                  label={"Dealer City"}
                  options={city_list}
                  saveDisplayName={false}
                />
              </Grid>
              <Grid item xxs={12} xs={6} md={3} lg={4}>
                <Select
                  name={"solicitation_type"}
                  label={"Solicitation Type"}
                  options={solicitationTypeOptions}
                  saveDisplayName={false}
                />
              </Grid>
              <Grid item xxs={12} xs={6} md={3} lg={4}>
                <Select
                  name={"dealer_group"}
                  label={"Dealer Group"}
                  options={dealer_group_list}
                  saveDisplayName={false}
                />
              </Grid>
              <Grid item xxs={12} xs={6} md={3} lg={4}>
                <Select
                  name={"dealer_name"}
                  label={"Dealer Name"}
                  options={dealer_list}
                  saveDisplayName={false}
                />
              </Grid>
              <Grid item xxs={12} xs={6} md={3} lg={4}>
                <TextInput
                  name={"dealer_code"}
                  label={"Dealer Code"}
                  onInput={allowOnlyNumbers}
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>
            </Grid>
            <ButtonWrapper style={{ gap: "15px" }}>
              <Buttons label={"Search"} type="submit" standard />

              <ResetButton onClick={handleReset}>
                <ReplayIcon />
                Reset
              </ResetButton>
            </ButtonWrapper>
          </MainContainer>
        </form>
      </FormProvider>
      <FormProvider {...methodsForIcDealerMappingTable}>
        <form
          onSubmit={methodsForIcDealerMappingTable.handleSubmit(
            onSubmitForIcDealerMappingTable
          )}
        >
          {isLoading ? (
            <IcDealerMappingSkeleton />
          ) : (
            isSuccess && (
              <LSODealerMappingTable
                column_data={column_data}
                row_data={row_data}
                workshop_list={workshop_list}
                sales_list={sales_list}
              />
            )
          )}
        </form>
      </FormProvider>
    </Navbar>
  );
}

export default withRouteProtection(LSODealerMappingSearch);
