import React, { useEffect } from "react";
import { Box, Grid, IconButton, Tab, Typography } from "@mui/material";
import LoginLogo from "../../../assets/images/login.png";
import TabPanel from "../../TabPanel/TabPanel";
import LoginWithMobileNo from "../LoginWithMobileNo";
import LoginWihEmailId from "../LoginWihEmailId";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../../modules/proposal.slice";
import { ArrowBackOutlined } from "@mui/icons-material";

import BrokerUser from "../../../assets/images/brokerEmployee.png";
import DealerUser from "../../../assets/images/Dealer.png";
import IcUser from "../../../assets/images/insuranceCompany.png";
import CustomerUserr from "../../../assets/images/Customer.png";
import OtherUser from "../../../assets/images/Other.png";
import {
  BorderedWrapper,
  Container,
  HelperText,
  LeftHeading,
  LeftSection,
  LoginFormSection,
  RightSection,
  StyledTabs,
  UserTypeCard,
  UserTypeIcon,
  UserTypeName,
  UserTypeSection,
} from "./NewLoginStyles";
import { useNavigate, useParams } from "react-router";
import withLoginChecks from "../../../hoc/withLoginChecks";

const userTypeArray = [
  {
    type: "Broker Employee",
    navigate: "/broker-employee ",
    color: "#959708",
    bgcolor: "#fbfcb78c",
    loginForm: true,
    logo: BrokerUser,
    slug: "broker-employee",
  },
  {
    type: "Dealer",
    navigate: "/dealer",
    color: "#0A74D6",
    bgcolor: "#D3EAFF",
    loginForm: true,
    logo: DealerUser,
    slug: "dealer",
  },
  {
    type: "Insurance Company",
    navigate: "/insurance-company",
    color: "#C60F25",
    bgcolor: "#FFD3D9",
    loginForm: true,
    logo: IcUser,
    slug: "insurance-company",
  },
  {
    type: "Customer",
    navigate: "/customer",
    color: "#041364",
    bgcolor: "#CED6FF",
    loginForm: true,
    logo: CustomerUserr,
    slug: "customer",
  },
  {
    type: "Other",
    navigate: "/other",
    color: "#088859",
    bgcolor: "#deffe1a8",
    loginForm: true,
    logo: OtherUser,
    slug: "other",
  },
];
const NewLogin = ({ step }) => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { activeTab } = useSelector((state) => state.proposal);
  const handleChange = (event, newValue) => {
    dispatch(setActiveTab(newValue));
  };
  useEffect(() => {
    dispatch(setActiveTab(0));
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <LeftSection>
          <LeftHeading>Welcome to Fyntune&apos;s OEM Portal</LeftHeading>
          <Box
            style={{
              objectFit: "contain",
              maxHeight: "60%",
              width: "auto",
              marginTop: "auto",
            }}
          >
            <img
              src={LoginLogo}
              alt="login logo"
              style={{ margin: "auto", height: "100%", width: "100%" }}
            />
          </Box>
        </LeftSection>
        <RightSection>
          <BorderedWrapper className="custom-scrollbar" step={step}>
            {step !== 1 && (
              <IconButton
                sx={{ position: "absolute", left: "6px", top: "6px" }}
                onClick={() => {
                  dispatch(setActiveTab(0));
                  navigate("../");
                }}
              >
                <ArrowBackOutlined />
              </IconButton>
            )}
            <Typography variant="h7" fontWeight={"bold"} color={"#727272"}>
              Step {step} of 2
            </Typography>
            {step !== 2 ? (
              <UserTypeSection>
                <Typography variant="h4" fontWeight={"bold"} color={"808080"}>
                  You are
                </Typography>
                <Grid container spacing={2} justifyContent={"center"}>
                  {userTypeArray?.map((user, index) => {
                    return (
                      <Grid item xxs={6} key={index}>
                        <UserTypeCard
                          id={user?.type?.toLowerCase().replace(/\s+/g, "_")}
                          user={user}
                          onClick={() => {
                            if (user?.loginForm) {
                              navigate(`.${user?.navigate}`);
                            }
                          }}
                        >
                          <UserTypeIcon>
                            <img
                              src={user?.logo}
                              alt=""
                              style={{
                                height: "100%",
                                width: "auto",
                                margin: "auto",
                                objectFit: "contain",

                                mixBlendMode: "multiply",
                                // mixBlendMode: "darken",
                              }}
                            />
                          </UserTypeIcon>
                          <UserTypeName color={user?.color}>
                            {user?.type}
                          </UserTypeName>
                        </UserTypeCard>
                      </Grid>
                    );
                  })}
                </Grid>
                <HelperText>
                  Click on the role which you&apos;ve been assigned
                </HelperText>
              </UserTypeSection>
            ) : (
              <LoginFormSection>
                <Typography
                  variant="h4"
                  fontWeight={"bold"}
                  color={"#1F1F1F"}
                  textAlign={"center"}
                  margin={"0 auto 10px auto"}
                >
                  {slug
                    ?.replace(/_/g, " ")
                    ?.replace(/-/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}{" "}
                  Login
                </Typography>
                <StyledTabs
                  value={activeTab}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="fullWidth"
                  centered
                >
                  <Tab
                    label="Mobile"
                    id="mobileIdInTab"
                    sx={{
                      fontSize: { xxs: 18 },
                      fontWeight: "bold",
                      color: activeTab == 0 ? "#006eff" : "",
                    }}
                  />
                  <Tab
                    label="Email"
                    id="emailIdInTab"
                    sx={{
                      fontSize: { xxs: 18 },
                      fontWeight: "bold",
                      color: activeTab == 1 ? "#006eff" : "",
                    }}
                  />
                </StyledTabs>
                <TabPanel value={activeTab} index={0} padding="0px 30px">
                  <LoginWithMobileNo />
                </TabPanel>
                <TabPanel value={activeTab} index={1} padding="0px 30px">
                  <LoginWihEmailId />
                </TabPanel>
              </LoginFormSection>
            )}
          </BorderedWrapper>
        </RightSection>
      </Container>
    </>
  );
};

export default withLoginChecks(NewLogin);
