import { Box } from "@mui/material";
import React from "react";
import {
  SummaryContainer,
  SummaryHeadContainer,
  SummaryTitle,
} from "../../../summary/summarystyle";
import AccidentDetails from "./components/AccidentDetails";
import ContactDetails from "./components/ContactDetails";

const ClaimDetailsViewNotification = ({ claimDetails }) => {
  // props coming from ClaimDetailsViewRight

  return (
    <SummaryContainer
      sx={{
        width: "100%",
        margin: "0 0 15px 0",
      }}
    >
      <Box
        sx={{
          padding: "0.8rem",
        }}
      >
        <SummaryHeadContainer>
          <SummaryTitle>Accident Details</SummaryTitle>
        </SummaryHeadContainer>
        <AccidentDetails claimDetails={claimDetails} />
        <br />
        <SummaryHeadContainer>
          <SummaryTitle>Contact Details</SummaryTitle>
        </SummaryHeadContainer>
        <ContactDetails claimDetails={claimDetails} />
        <br />
      </Box>
    </SummaryContainer>
  );
};

export default ClaimDetailsViewNotification;
