import * as yup from "yup";
import { mobileNumberRequiredForMasters } from "../../../../../utils/Validations/mobileNumberValidations";
import { documentUploadsOnlyImageLessThan2MBRequired } from "../../../../../utils/Validations/documentUploadsValidations";
import { panCardNumberRequired } from "../../../../../utils/Validations/panNumberValidations";
import { gstinNumberRequired } from "../../../../../utils/Validations/gstinValidations";
import { cinNumberRequired } from "../../../../../utils/Validations/cinNumberValidation";

const website =
  /^((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const insuranceValidationSchema = () =>
  yup.object().shape({
    ic_slug: yup.string().required("IC slug is required"),
    // .test("name", "IC slug character length exceeded", function (value) {
    //   const { insurance_name } = this.parent;
    //   return value?.length <= insurance_name?.length;
    // }),
    insurance_name: yup.string().required("Insurance name is required"),
    insurance_display_name: yup
      .string()
      .required("Insurance display name is required"),
    email: yup
      .string()
      .required("Email ID is required")
      .email("Please enter a valid email ID"),
    mobile_no: mobileNumberRequiredForMasters(),
    landline_no: yup
      .string()
      .required("Landline no. is required")
      .matches(/^[0-9]\d{10}$/, "Please enter a valid landline no."),

    address1: yup
      .string()
      .required("Address line 1 is required")
      .min(5, "Address must contain at least five characters"),
    // address2: yup.string().required("Address 2 is required"),
    // address3: yup.string().required("Address 3 is required"),
    city_id: yup.string().required("City name is required"),
    state_id: yup.string().required("State name is required"),
    ic_website: yup
      .string()
      .required("Insurance website is required")
      .matches(website, "Enter valid website"),
    pincode_id: yup
      .string()
      .required("Pincode is required")
      .matches(/^[1-9]{1}[0-9]{5}$/, "Please enter a valid pincode"),
    // ...(values?.ic_logo === null && {
    ic_logo: documentUploadsOnlyImageLessThan2MBRequired("Insurance logo"),
    // }),
    // ...(values?.ic_signature === null && {
    ic_signature: documentUploadsOnlyImageLessThan2MBRequired(
      "Insurance signature"
    ),
    // .test("ic_signature", "Insurance signature is required", (val) => val),
    // }),
    pan_number: panCardNumberRequired(),

    gstin: gstinNumberRequired().test(
      "compare_PAN",
      "Enter valid GSTIN no.",
      function (val) {
        const { pan_number } = this.parent;
        const panInGstin = val.slice(2, 12);
        if (!pan_number) return true;
        return pan_number === panInGstin;
      }
    ),
    irda_registration_number: yup
      .string()
      .nullable()
      .required("IRDA registration no. is required")
      .min(3, "IRDA registration no. should have 3 digits")
      .max(3, "IRDA registration no. should have 3 digits"),
    cin_number: cinNumberRequired(),
    tollfree_number: yup
      .string()
      .nullable()
      .min(11, "Tollfree no must be either 11 digit or 12 digit")
      .required("Tollfree no. is required"),
    ic_type_flag: yup.string().required("Policy issuance from is required"),
  });
