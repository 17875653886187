import React, { useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  IcDealerMapTableCell,
  IcDealerMapTableHeadCell,
  IcDealerMapTableMainContainer,
  IcDealerMapTableNoRecordsContainer,
  IcDealerMapTablePaymentCell,
  IcDealerMapTablePaymentHeadCell,
} from "./IcDealerMappingStyled";
import CheckBox from "../../../../../components/BasicFormComponent/CheckBox";
import { ButtonWrapper } from "../../../../masters/components/MasterStyle";
import Buttons from "../../../../../components/BasicFormComponent/Buttons";

function IcDealerMappingTable({
  column_data,
  row_data,
  methodsForIcDealerMappingTable,
  map_by_ic,
}) {
  // handling indeterminate state of IC Dealer Mapping checkbox
  const icDealerArray = Object.values(
    methodsForIcDealerMappingTable.watch("quote_access")
  );
  useEffect(() => {
    if (icDealerArray?.length) {
      if (icDealerArray?.every((item) => item == true)) {
        methodsForIcDealerMappingTable.setValue("parent_quote_access", true);
      }
      if (icDealerArray?.every((item) => item == false)) {
        methodsForIcDealerMappingTable.setValue("parent_quote_access", false);
      }
    }
  }, [JSON.stringify(icDealerArray)]);

  // handling indeterminate state of payment checkboxes
  const newPaymentKeyWithArray = {};
  const renewPaymentKeyWithArray = {};
  const combinedPaymentData = {
    new_payment_mode: newPaymentKeyWithArray,
    renew_payment_mode: renewPaymentKeyWithArray,
  };
  const getPaymentWiseCheckedData = (object, newArray) => {
    const arrayFormat = Object.values(object);
    arrayFormat?.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (!newArray[key]) {
          newArray[key] = [];
        }
        newArray[key].push(item[key]);
      });
    });
  };
  getPaymentWiseCheckedData(
    methodsForIcDealerMappingTable.watch().payment?.new_payment_mode,
    newPaymentKeyWithArray
  );
  getPaymentWiseCheckedData(
    methodsForIcDealerMappingTable.watch().payment?.renew_payment_mode,
    renewPaymentKeyWithArray
  );

  useEffect(() => {
    column_data?.map((column) => {
      if (column?.subHeader?.length > 0) {
        return column?.subHeader?.map((subItem) => {
          return Object.keys(combinedPaymentData)?.map((paymentmode) => {
            if (
              combinedPaymentData[paymentmode][subItem?.payment_type_id]?.every(
                (item) => item == true
              )
            ) {
              methodsForIcDealerMappingTable.setValue(
                `payment.parent_payment_mode.${paymentmode}.${subItem?.accessorKey}`,
                true
              );
            }
            if (
              combinedPaymentData[paymentmode][subItem?.payment_type_id]?.every(
                (item) => item == false
              )
            ) {
              methodsForIcDealerMappingTable.setValue(
                `payment.parent_payment_mode.${paymentmode}.${subItem?.accessorKey}`,
                false
              );
            }
          });
        });
      }
    });
  }, [column_data, JSON.stringify(combinedPaymentData)]);

  const indeterminateCheck = (array) => {
    return (
      array?.some((item) => item === true) &&
      array?.some((item) => item === false)
    );
  };

  return (
    <IcDealerMapTableMainContainer>
      {row_data?.length > 0 ? (
        <>
          <TableContainer style={{ maxHeight: "400px" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {column_data?.map((item, index) => {
                    if (item?.subHeader?.length > 0) {
                      return (
                        <IcDealerMapTableHeadCell key={item?.header}>
                          {item?.header}
                          <TableContainer>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  {item?.subHeader?.map((item) => {
                                    return (
                                      <IcDealerMapTablePaymentHeadCell
                                        key={item?.header}
                                      >
                                        {item?.header}
                                      </IcDealerMapTablePaymentHeadCell>
                                    );
                                  })}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  {item?.subHeader?.map((item) => {
                                    return (
                                      <IcDealerMapTablePaymentCell
                                        key={`payment.parent_payment_mode.${item?.paymentMode}.${item?.accessorKey}`}
                                      >
                                        <CheckBox
                                          style={{
                                            padding: 0,
                                            margin: 0,
                                          }}
                                          name={`payment.parent_payment_mode.${item?.paymentMode}.${item?.accessorKey}`}
                                          indeterminate={indeterminateCheck(
                                            combinedPaymentData[
                                              item?.paymentMode
                                            ][item?.payment_type_id]
                                          )}
                                        />
                                      </IcDealerMapTablePaymentCell>
                                    );
                                  })}
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </IcDealerMapTableHeadCell>
                      );
                    } else if (item?.accessorKey === "quote_access") {
                      return (
                        <IcDealerMapTableHeadCell key={item?.accessorKey}>
                          <Box>{item?.header}</Box>
                          <CheckBox
                            style={{
                              padding: 0,
                              margin: 0,
                            }}
                            name={"parent_quote_access"}
                            indeterminate={indeterminateCheck(icDealerArray)}
                          />
                        </IcDealerMapTableHeadCell>
                      );
                    } else {
                      return (
                        <IcDealerMapTableHeadCell
                          key={`${item?.header}.${index}`}
                        >
                          {item?.header}
                        </IcDealerMapTableHeadCell>
                      );
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {row_data?.map((item) => {
                  return (
                    <TableRow
                      key={`quote_access.${
                        map_by_ic ? item?.dealer_id : item?.insurance_id
                      }`}
                    >
                      <IcDealerMapTableCell>
                        <CheckBox
                          style={{
                            padding: 0,
                            margin: 0,
                          }}
                          name={`quote_access.${
                            map_by_ic ? item?.dealer_id : item?.insurance_id
                          }`}
                        />
                      </IcDealerMapTableCell>
                      {item?.insurance_name && (
                        <IcDealerMapTableCell>
                          {item?.insurance_name}
                        </IcDealerMapTableCell>
                      )}
                      {item?.groups_name && (
                        <IcDealerMapTableCell>
                          {item?.groups_name}
                        </IcDealerMapTableCell>
                      )}
                      {item?.dealer_name && (
                        <IcDealerMapTableCell>
                          {item?.dealer_name}
                        </IcDealerMapTableCell>
                      )}
                      {item?.dealer_code && (
                        <IcDealerMapTableCell>
                          {item?.dealer_code}
                        </IcDealerMapTableCell>
                      )}
                      {item?.dealer_type && (
                        <IcDealerMapTableCell>
                          {item?.dealer_type}
                        </IcDealerMapTableCell>
                      )}
                      {item?.state_name && (
                        <IcDealerMapTableCell>
                          {item?.state_name}
                        </IcDealerMapTableCell>
                      )}
                      {item?.city_name && (
                        <IcDealerMapTableCell>
                          {item?.city_name}
                        </IcDealerMapTableCell>
                      )}

                      {/* new payment methods */}
                      <IcDealerMapTableCell>
                        <TableContainer>
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                {item?.new_payment_mode?.map((subItem) => {
                                  return (
                                    <IcDealerMapTablePaymentCell
                                      key={`payment.new_payment_mode.${
                                        map_by_ic
                                          ? item?.dealer_id
                                          : item?.insurance_id
                                      }.${subItem?.payment_type_id}`}
                                    >
                                      <CheckBox
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                        }}
                                        name={`payment.new_payment_mode.${
                                          map_by_ic
                                            ? item?.dealer_id
                                            : item?.insurance_id
                                        }.${subItem?.payment_type_id}`}
                                      />
                                    </IcDealerMapTablePaymentCell>
                                  );
                                })}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </IcDealerMapTableCell>

                      {/* renew payment methods */}
                      <IcDealerMapTableCell>
                        <TableContainer>
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                {item?.renew_payment_mode?.map((subItem) => {
                                  return (
                                    <IcDealerMapTablePaymentCell
                                      key={`payment.renew_payment_mode.${
                                        map_by_ic
                                          ? item?.dealer_id
                                          : item?.insurance_id
                                      }.${subItem?.payment_type_id}`}
                                    >
                                      <CheckBox
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                        }}
                                        name={`payment.renew_payment_mode.${
                                          map_by_ic
                                            ? item?.dealer_id
                                            : item?.insurance_id
                                        }.${subItem?.payment_type_id}`}
                                      />
                                    </IcDealerMapTablePaymentCell>
                                  );
                                })}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </IcDealerMapTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <ButtonWrapper
            style={{
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Buttons
              id={"ic_mapping_table_submit_button"}
              label={"submit"}
              type="submit"
              standard
            />
          </ButtonWrapper>
        </>
      ) : (
        <IcDealerMapTableNoRecordsContainer>
          No Records Found
        </IcDealerMapTableNoRecordsContainer>
      )}
    </IcDealerMapTableMainContainer>
  );
}

export default IcDealerMappingTable;
