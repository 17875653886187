import { useMutation } from "@tanstack/react-query";
import api from "./../api/api";
export const useUploadBulkQuotesMutation = () => {
  const { mutate, isLoading } = useMutation((data) =>
    api.post("/quote/import", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  );
  return {
    uploadBulkQuotes: mutate,
    isUploading: isLoading,
  };
};
