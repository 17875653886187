import * as yup from "yup";

export const ClaimCategoryRangeValidation = yup.object().shape({
  min_a: yup
    .string()
    .required("Category A minimum claim amount is required")
    .test(
      "less_than_max",
      "Minimum claim amount must be less than maximum claim amount",
      function (value) {
        if (this.parent.max_a > this.parent.min_a) return true;
        return +this.parent.max_a > +value;
      }
    )
    .test(
      "one_greater_than_max_b",
      " Category A Minimum Claim Amount must be exactly one greater than Category B Maximum Claim Amount.",
      function (value) {
        return +value === +this.parent.max_b + 1;
      }
    ),
  max_a: yup
    .string()
    .required("Category A maximum claim amount is required")
    .test(
      "more_than_min",
      "Maximum claim amount must be more than minimum claim amount",
      function (value) {
        if (this.parent.max_a > this.parent.min_a) return true;
        return +this.parent.min_a < +value;
      }
    ),
  min_b: yup
    .string()
    .required("Category B minimum claim amount is required")
    .test(
      "less_than_max",
      "Minimum claim amount must be less than maximum claim amount",
      function (value) {
        if (this.parent.max_b > this.parent.min_b) return true;
        return +this.parent.max_b > +value;
      }
    )
    .test(
      "one_greater_than_max_c",
      "Category B Minimum Claim Amount must be exactly one greater than Category C Maximum Claim Amount.",
      function (value) {
        return +value === +this.parent.max_c + 1;
      }
    ),
  max_b: yup
    .string()
    .required("Category B maximum claim amount is required")
    .test(
      "more_than_max",
      "Maximum claim amount must be more than minimum claim amount",
      function (value) {
        if (this.parent.min_b < this.parent.max_b) return true;
        return +this.parent.min_b < +value;
      }
    ),
  min_c: yup
    .string()
    .required("Category C minimum claim amount is required")
    .test(
      "less_than_max",
      "Minimum claim amount must be less than maximum claim amount",
      function (value) {
        if (this.parent.max_c > this.parent.min_c) return true;
        return +this.parent.max_c > +value;
      }
    ),
  max_c: yup
    .string()
    .required("Category C maximum claim amount is required")
    .test(
      "more_than_max",
      "Maximum claim amount must be more than minimum claim amount",
      function (value) {
        if (this.parent.min_c < this.parent.max_c) return true;
        return +this.parent.min_c < +value;
      }
    ),
});
