import React from "react";
import { Box } from "@mui/material";
import { Heading, Papers } from "../masters/components/MasterStyle";
import { FormProvider, useForm } from "react-hook-form";
import Docupload from "../../components/BasicFormComponent/Docupload";
import { useUploadBulkQuotesMutation } from "../../services/bulk-import.service";
import Buttons from "../../components/BasicFormComponent/Buttons";
import MasterTable from "../masters/MasterTable/MasterTable";
import withRouteProtection from "../../hoc/withRouteProtection";
import { useGetSortedMaster } from "../../services/master.service.js";
import swal from "sweetalert";
import Navbar from "../../components/Navbar";

const BulkImportQuotes = () => {
  const methods = useForm();
  const { uploadBulkQuotes } = useUploadBulkQuotesMutation();
  const { table_list: broker_setting } = useGetSortedMaster({
    master_name: "broker_setting",
    sortBy: "setting_name",
  });

  const matchConfirmationBox = broker_setting.some(
    (obj) =>
      obj.setting_name === "display_confirmation_box" &&
      obj.setting_value === "Y"
  );

  const onSubmit = (data) => {
    if (matchConfirmationBox) {
      swal({
        text: "Please ensure you have uploaded correct and complete document",
        buttons: true,
        icon: "info",
        dangerMode: false,
      }).then((willSave) => {
        if (willSave) {
          uploadBulkQuotes(data);
        }
      });
    } else {
      uploadBulkQuotes(data);
    }
  };
  return (
    <>
      <Navbar>
        <Box>
          <Papers elevation={3}>
            <Heading>Bulk Import Quotes</Heading>

            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Box mt={2}>
                  <Docupload
                    name={"quote"}
                    label={"Please upload the excel sheet"}
                  />
                </Box>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Buttons type={"submit"} label="Upload"></Buttons>
                </Box>
              </form>
            </FormProvider>
          </Papers>
          <MasterTable master_name={"import_proposal"} writable={false} />
        </Box>
      </Navbar>
    </>
  );
};

export default withRouteProtection(BulkImportQuotes, true);
