import * as yup from "yup";

export const policyWordingsValidationSchema = yup.object().shape({
  ic_id: yup.string().required("Insurance company is required"),
  segment_id: yup.string().required("Segment is required"),
  product_id: yup.string().required("Product is required"),
  limits_of_liability_clause: yup
    .string()
    .required("Limits of liability clause is required"),
  drivers_clause: yup.string().required("Driver's clause is required"),
  important_notice: yup.string().required("Important notice is required"),
  limitations_as_to_use: yup
    .string()
    .required("Limitations as to use is required"),
  drivers_clause_details: yup
    .string()
    .required("Driver's clause description is required"),
  ncb_clause: yup.string().required("NCB clause is required"),
  important_notice_details: yup
    .string()
    .required("Important notice description is required"),
});
