import { createSlice } from "@reduxjs/toolkit";

const endorsement = createSlice({
  name: "endorsement",
  initialState: {
    activeMainSection: 0,
    activeUpdateSection: 0,
    selectedEndorsement: [],
    endorcementData: [],
    endorcementReturnData: [],
    showApproval: true,
  },
  reducers: {
    setActiveMainSection: (state, action) => {
      state.activeMainSection = action.payload;
    },
    setActiveUpdateSection: (state, action) => {
      state.activeUpdateSection = action.payload;
    },
    setSelectedEndorsement: (state, action) => {
      state.selectedEndorsement = action.payload;
    },
    addEndorcement(state, action) {
      state.endorcementData = { ...state.endorcementData, ...action.payload };
    },
    addReturnEndorcement(state, action) {
      state.endorcementReturnData = {
        ...state.endorcementReturnData,
        ...action.payload,
      };
    },
    setShowApproval: (state, action) => {
      state.showApproval = action.payload;
    },
    resetEndorsementStates: (state) => {
      state.activeMainSection = 0;
      state.activeUpdateSection = 0;
      state.selectedEndorsement = [];
      state.endorcementData = [];
      state.endorcementReturnData = [];
      state.showApproval = true;
    },
  },
});

export const {
  setActiveUpdateSection,
  setSelectedEndorsement,
  addEndorcement,
  addReturnEndorcement,
  setActiveMainSection,
  setShowApproval,
  resetEndorsementStates,
} = endorsement.actions;

export default endorsement.reducer;
