import * as yup from "yup";
import { documentUploadsLessThan2MBRequired } from "../../../../../../../../utils/Validations/documentUploadsValidations";

export const proformaInvoiceSchema = ({ isPreviousDetailsAvailable }) =>
  yup.object().shape({
    proforma_invoice_no: yup
      .string()
      .nullable()
      .required("Proforma invoice no. is required"),
    proforma_invoice_date: yup
      .string()
      .nullable()
      .required("Proforma invoice date is required"),
    proforma_invoice_amount: yup
      .string()
      .nullable()
      .required("Proforma invoice amount is required")
      .test("check_value", "Enter valid amount", function (value) {
        return +value > 0;
      }),
    proforma_invoice_document:
      !isPreviousDetailsAvailable && documentUploadsLessThan2MBRequired(),
  });
