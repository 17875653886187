import { Box, Button, Typography } from "@mui/material";
import withRouteProtection from "../../hoc/withRouteProtection";
import { useNavigate } from "react-router";
import Navbar from "../../components/Navbar";

const CKYCRedirection = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar>
        <Box p={2}>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Typography variant="body1">
              It seems that you have not completed your CKYC. Please complete
              your CKYC to proceed further.
            </Typography>
            <Typography gutterBottom variant="body1">
              If you have already completed your CKYC, please wait for 24 hours
              for the CKYC to be updated in our system.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/policy_support/proposal_listing");
              }}
            >
              Go to Proposal Listing
            </Button>
          </Box>
        </Box>
      </Navbar>
    </>
  );
};

export default withRouteProtection(CKYCRedirection, true);
