import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import React from "react";
import { TitleWithBlueBlock } from "./CancellationApprovalStyles";
import {
  GCV_A1,
  GCV_A2,
  PCV_C1,
  PCV_C2,
  THREE_WHEELER,
} from "../../../../constants/segments";
import {
  COVER_TYPE_1OD_5TP,
  COVER_TYPE_COMPREHENSIVE,
  COVER_TYPE_SAOD,
  COVER_TYPE_SATP,
} from "../../../../constants/coverTypeSlugs";
import { formatPrice } from "../../../../utils/formatPrice";

const LeftCancellationPremiumTable = ({
  policy_cancellation_list,
  calculation_policy_cancellation,
}) => {
  const isIGST = !!calculation_policy_cancellation?.gst?.first_year_gst?.gst;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <div className="Rectangle-4109"></div>
        <TitleWithBlueBlock>Premium Details</TitleWithBlueBlock>
      </Box>
      <Table>
        <TableHead>
          <StyledTableCell>&nbsp;</StyledTableCell>
          <StyledTableCell>Part A</StyledTableCell>
          <StyledTableCell>Part B</StyledTableCell>
          {isIGST ? (
            <StyledTableCell>IGST</StyledTableCell>
          ) : (
            <>
              <StyledTableCell>CGST</StyledTableCell>
              <StyledTableCell>SGST</StyledTableCell>
            </>
          )}
          <StyledTableCell>Total Premium</StyledTableCell>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell style={{ textAlign: "left" }}>
              1st Year
            </StyledTableCell>
            <StyledTableCell>
              {formatPrice(
                policy_cancellation_list[0]?.total_od_premium,
                false
              )}
            </StyledTableCell>
            <StyledTableCell>
              {formatPrice(
                calculation_policy_cancellation?.first?.first_premium,
                false
              )}
            </StyledTableCell>
            {isIGST ? (
              <StyledTableCell>
                {formatPrice(
                  calculation_policy_cancellation?.gst?.first_year_gst?.gst,
                  false
                )}
              </StyledTableCell>
            ) : (
              <>
                <StyledTableCell>
                  {formatPrice(
                    calculation_policy_cancellation?.gst?.first_year_gst?.cgst,
                    false
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {formatPrice(
                    calculation_policy_cancellation?.gst?.first_year_gst?.sgst,
                    false
                  )}
                </StyledTableCell>
              </>
            )}
            <StyledTableCell>
              {formatPrice(
                calculation_policy_cancellation?.first
                  ?.first_year_total_premium,
                false
              )}
            </StyledTableCell>
          </TableRow>
          {policy_cancellation_list[0]?.segment_slug !== THREE_WHEELER &&
            policy_cancellation_list[0]?.segment_slug !== PCV_C2 &&
            policy_cancellation_list[0]?.segment_slug !== PCV_C1 &&
            policy_cancellation_list[0]?.segment_slug !== GCV_A1 &&
            policy_cancellation_list[0]?.segment_slug !== GCV_A2 &&
            policy_cancellation_list[0]?.cover_type_slug !== COVER_TYPE_SAOD &&
            policy_cancellation_list[0]?.cover_type_slug !== COVER_TYPE_SATP &&
            policy_cancellation_list[0]?.cover_type_slug !==
              COVER_TYPE_COMPREHENSIVE && (
              <>
                <TableRow>
                  <StyledTableCell style={{ textAlign: "left" }}>
                    2nd Year
                  </StyledTableCell>
                  <StyledTableCell>{formatPrice(0)}</StyledTableCell>
                  <StyledTableCell>
                    {formatPrice(
                      calculation_policy_cancellation?.second?.second_premium,
                      false
                    )}
                  </StyledTableCell>
                  {isIGST ? (
                    <StyledTableCell>
                      {formatPrice(
                        calculation_policy_cancellation?.gst?.second_year_gst
                          ?.gst,
                        false
                      )}
                    </StyledTableCell>
                  ) : (
                    <>
                      <StyledTableCell>
                        {formatPrice(
                          calculation_policy_cancellation?.gst?.second_year_gst
                            ?.cgst,
                          false
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatPrice(
                          calculation_policy_cancellation?.gst?.second_year_gst
                            ?.sgst,
                          false
                        )}
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell>
                    {formatPrice(
                      calculation_policy_cancellation?.second
                        ?.second_year_total_premium,
                      false
                    )}
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell style={{ textAlign: "left" }}>
                    3rd Year
                  </StyledTableCell>
                  <StyledTableCell>{formatPrice(0)}</StyledTableCell>
                  <StyledTableCell>
                    {formatPrice(
                      calculation_policy_cancellation?.third?.third_premium,
                      false
                    )}
                  </StyledTableCell>
                  {isIGST ? (
                    <StyledTableCell>
                      {formatPrice(
                        calculation_policy_cancellation?.gst?.third_year_gst
                          ?.gst,
                        false
                      )}
                    </StyledTableCell>
                  ) : (
                    <>
                      <StyledTableCell>
                        {formatPrice(
                          calculation_policy_cancellation?.gst?.third_year_gst
                            ?.cgst,
                          false
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatPrice(
                          calculation_policy_cancellation?.gst?.third_year_gst
                            ?.sgst,
                          false
                        )}
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell>
                    {formatPrice(
                      calculation_policy_cancellation?.third
                        ?.third_year_total_premium,
                      false
                    )}
                  </StyledTableCell>
                </TableRow>
                {policy_cancellation_list[0]?.cover_type_slug ===
                  COVER_TYPE_1OD_5TP && (
                  <>
                    <TableRow>
                      <StyledTableCell style={{ textAlign: "left" }}>
                        4th Year
                      </StyledTableCell>
                      <StyledTableCell>{formatPrice(0)}</StyledTableCell>
                      <StyledTableCell>
                        {formatPrice(
                          calculation_policy_cancellation?.fourth
                            ?.fourth_premium,
                          false
                        )}
                      </StyledTableCell>
                      {isIGST ? (
                        <StyledTableCell>
                          {formatPrice(
                            calculation_policy_cancellation?.gst
                              ?.fourth_year_gst?.gst,
                            false
                          )}
                        </StyledTableCell>
                      ) : (
                        <>
                          <StyledTableCell>
                            {formatPrice(
                              calculation_policy_cancellation?.gst
                                ?.fourth_year_gst?.cgst,
                              false
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {formatPrice(
                              calculation_policy_cancellation?.gst
                                ?.fourth_year_gst?.sgst,
                              false
                            )}
                          </StyledTableCell>
                        </>
                      )}
                      <StyledTableCell>
                        {formatPrice(
                          calculation_policy_cancellation?.fourth
                            ?.fourth_year_total_premium,
                          false
                        )}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell style={{ textAlign: "left" }}>
                        5th Year
                      </StyledTableCell>
                      <StyledTableCell>{formatPrice(0)}</StyledTableCell>
                      <StyledTableCell>
                        {formatPrice(
                          calculation_policy_cancellation?.fifth?.fifth_premium,
                          false
                        )}
                      </StyledTableCell>
                      {isIGST ? (
                        <StyledTableCell>
                          {formatPrice(
                            calculation_policy_cancellation?.gst?.fifth_year_gst
                              ?.gst,
                            false
                          )}
                        </StyledTableCell>
                      ) : (
                        <>
                          <StyledTableCell>
                            {formatPrice(
                              calculation_policy_cancellation?.gst
                                ?.fifth_year_gst?.cgst,
                              false
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {formatPrice(
                              calculation_policy_cancellation?.gst
                                ?.fifth_year_gst?.sgst,
                              false
                            )}
                          </StyledTableCell>
                        </>
                      )}
                      <StyledTableCell>
                        {formatPrice(
                          calculation_policy_cancellation?.fifth
                            ?.fifth_year_total_premium,
                          false
                        )}
                      </StyledTableCell>
                    </TableRow>
                  </>
                )}
              </>
            )}
        </TableBody>
      </Table>
    </>
  );
};

export default LeftCancellationPremiumTable;

const StyledTableCell = styled(TableCell)(() => ({
  color: "#5A5A5A",
  fontSize: "11px",
  fontWeight: "bold",
  textAlign: "right",
  padding: "5px",
  border: "none",
  whiteSpace: "nowrap",
}));
