import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Papers } from "../masters/components/MasterStyle";
import { Box, IconButton, Tooltip, styled } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useGetIcApiLogDetails } from "../../services/auditLog.service";
import Navbar from "../../components/Navbar";

const IcApiLogView = () => {
  const { http_logs_id } = useParams();
  const { icLogData } = useGetIcApiLogDetails(http_logs_id);
  const [copySuccess, setCopySuccess] = useState(null);

  useEffect(() => {
    copySuccess &&
      setInterval(() => {
        setCopySuccess(null);
      }, 2000);
  }, [copySuccess]);

  // console.log("zzz", icLogData);
  const copyToClipBoard = async (value) => {
    try {
      await navigator.clipboard.writeText(value);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  const formatResponse = (value) => {
    return value && JSON.stringify(JSON.parse(value), null, 4);
  };

  const parsedURL = (url) => {
    const parsedUrl = new URL(url);
    const baseUrl = parsedUrl?.origin + parsedUrl?.pathname;
    const queryParams = Object.fromEntries(parsedUrl?.searchParams.entries());
    return {
      baseUrl: baseUrl,
      queryParams: queryParams,
    };
  };
  const result = icLogData?.url ? parsedURL(icLogData?.url) : null;
  return (
    <Navbar>
      <Papers>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box>
            <Head>LOG TIME</Head>
            <Value>{icLogData?.timing || "---"}</Value>
          </Box>
          <Box>
            <Head>LOG TYPE</Head>
            <Value>{"---"}</Value>
          </Box>
          <Box>
            <Head>METHOD</Head>
            <Value>{icLogData?.method || "---"}</Value>
          </Box>
          <Box>
            <Head>URL</Head>
            {icLogData?.alias?.toLowerCase()?.includes("sms") ? (
              <>
                <Value>{result?.baseUrl}</Value>
                <Value>
                  <table style={{ textAlign: "left" }}>
                    {Object.entries(result?.queryParams).map(([key, value]) => (
                      <tr key={key}>
                        <td
                          style={{
                            paddingRight: "20px",
                            verticalAlign: "text-top",
                            fontWeight: 500,
                          }}
                        >
                          {key}
                        </td>
                        <td>{value}</td>
                      </tr>
                    ))}
                  </table>
                </Value>
              </>
            ) : (
              <Value>{icLogData?.url || "---"}</Value>
            )}
          </Box>
          <Box>
            <Head>STATUS CODE</Head>
            <Value>{icLogData?.status_code || "---"}</Value>
          </Box>
          <Box>
            <Head>RESPONSE TIME</Head>
            <Value>{icLogData?.response_time || "---"}</Value>
          </Box>
          <Box>
            <Head>MESSAGE</Head>
            <Value>{"---"}</Value>
          </Box>
          <Box>
            <Head>
              <p>REQUEST HEADERS</p>
              <Tooltip
                arrow
                placement="right"
                title={copySuccess ? copySuccess : "Copy to clipboard"}
                onClick={() => {
                  copyToClipBoard(icLogData?.request_header || "");
                }}
              >
                <IconButton>
                  <ContentCopyIcon sx={{ fontSize: "14px" }} />
                </IconButton>
              </Tooltip>
            </Head>
            <PreValue>
              {formatResponse(icLogData?.request_header) || "---"}
            </PreValue>
          </Box>
          <Box>
            <Head>
              <p>RESPONSE</p>
              <Tooltip
                arrow
                placement="right"
                title={copySuccess ? copySuccess : "Copy to clipboard"}
                onClick={() => {
                  copyToClipBoard(icLogData?.response_body || "");
                }}
              >
                <IconButton>
                  <ContentCopyIcon sx={{ fontSize: "14px" }} />
                </IconButton>
              </Tooltip>
            </Head>
            <PreValue>
              {formatResponse(icLogData?.response_body) || "---"}
            </PreValue>
          </Box>
          <Box>
            <Head>
              <p>RESPONSE HEADERS</p>
              <Tooltip
                arrow
                placement="right"
                title={copySuccess ? copySuccess : "Copy to clipboard"}
                onClick={() => {
                  copyToClipBoard(icLogData?.response_header || "");
                }}
              >
                <IconButton>
                  <ContentCopyIcon sx={{ fontSize: "14px" }} />
                </IconButton>
              </Tooltip>
            </Head>
            <PreValue>
              {formatResponse(icLogData?.response_header) || "---"}
            </PreValue>
          </Box>
        </Box>
      </Papers>
    </Navbar>
  );
};

export default IcApiLogView;

export const Head = styled(Box)(() => ({
  display: "flex",
  fontWeight: "bold",
  gap: "10px",
}));

export const Value = styled(Box)(() => ({}));

export const PreValue = styled("pre")(() => ({
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
}));
