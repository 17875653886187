import {
  TextField as _TextField,
  InputLabel as _InputLabel,
  styled,
  IconButton,
  Grid,
  Button,
  alpha,
  Box,
  Checkbox,
} from "@mui/material";
export const TextField = styled(_TextField)(
  ({ size, multiline, removeBorders, bordered, theme, readOnly }) => ({
    "& .MuiInputBase-input": {
      height: bordered
        ? size === "small" && !multiline
          ? "1.7375em"
          : ""
        : "",
      padding: bordered
        ? size === "small" && !multiline && !bordered
          ? "6px 0 2px !important"
          : "6px 6px 6px !important"
        : "2px 0",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: readOnly && "#dbe9ed",
    },
    "& .MuiInputBase-adornedStart": {
      padding: "0",
    },
    "& fieldset": {
      border: removeBorders && "none",
      ...(!bordered && {
        borderLeftWidth: "0 !important",
        borderRightWidth: "0 !important",
        borderTopWidth: "0 !important",
        borderRadius: "0 !important",
        borderBottomWidth: "1px !important",
        borderBottomColor: "rgb(0,0,0, 0.4) !important",
      }),
    },
    "& .Mui-focused": {
      "& fieldset": {
        ...(!bordered && {
          borderBottomColor: `${theme.palette.primary.main} !important`,
        }),
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: "0 !important",
      paddingRight: "30px !important",
    },
  })
);

TextField.defaultProps = {
  size: "small",
  fullWidth: true,
};

export const InputLabel = styled(_InputLabel)(
  ({ bordered, hasCustomLabel, removeBottomMargin }) => ({
    fontSize: "12px",
    marginBottom: bordered && !removeBottomMargin ? "5px" : 0,
    fontWeight: "500",
    position: "relative",
    display: "inline-block",

    "& .MuiInputLabel-asterisk": {
      color: "red",
      ...(hasCustomLabel && {
        position: "absolute",
        top: "0",
        right: "12px",
      }),
    },
  })
);

export const CrossIconButton = styled(IconButton)({
  color: "#6e8185",
  padding: 0,
});

export const InputGrid = styled(Grid);

InputGrid.defaultProps = {
  item: true,
  xs: 12,
  md: 6,
  lg: 4,
};

export const SubmitButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  background: theme.palette.primary.main,
  borderRadius: "30px",
}));
SubmitButton.defaultProps = {
  id: "submit",
  type: "submit",
  variant: "contained",
  size: "large",
};
export const ResetButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "30px",
  "&:hover": {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    background: alpha(theme.palette.primary.main, 0.1),
  },
}));

ResetButton.defaultProps = {
  type: "button",
  variant: "outlined",
  size: "large",
};

export const CalendarHeader = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: "20px",
  "& select": {
    border: "none",
    outline: "none",
    fontSize: "16px",
    fontWeight: "500",
    color: "#305056",
    cursor: "pointer",
  },
});

export const RadioLabel = styled("label")(({ length }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: length > 3 && "100px",
  flex: length < 3 ? "1 1 0" : "unset",
  padding: "0 16px",
  border: "1px solid #e5e7eb",
  borderRadius: "5px",
  height: "35.79px",
  gap: "10px",
  transition: "all 0.3s ease",
}));
export const RadioInput = styled("input")(({ theme, color }) => ({
  display: "none",
  "&:checked + label ": {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  "&:checked + label > .radio-button": {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  ...(color === "opposite" && {
    "&:checked + label:nth-of-type(1) > .radio-button ": {
      backgroundColor: "#26BFA6",
      borderColor: "#26BFA6",
    },
    "&:checked + label:nth-of-type(2) > .radio-button ": {
      backgroundColor: "#FF5744",
      borderColor: "#FF5744",
    },
    "&:checked + label:nth-of-type(1)": {
      backgroundColor: "#D9FFF2",
    },
    "&:checked + label:nth-of-type(2)": {
      backgroundColor: "#FFEEEC",
    },
  }),
}));
export const RadioWrapper = styled(Box)(({ disableWrap }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
  flexWrap: !disableWrap && "wrap",
}));

export const RadioButton = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  border: `1px solid #e5e7eb`,
  transition: "all 0.3s ease",
  boxShadow: `0px 4px 4px 0px ${alpha("#e5e7eb", 0.25)}`,
  "&:after": {
    content: '""',
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "white",

    display: "block",
  },
}));

export const TinyCheckbox = styled(Checkbox)(() => ({
  padding: 0,
  "& .MuiSvgIcon-root": {
    fontSize: "14px",
    marginRight: "5px",
  },
}));

export const SuggestionWrapper = styled(Box)(({ show }) => ({
  position: "absolute",
  top: "30px",
  left: 0,
  backgroundColor: "#fff",
  display: !show && "none",
  zIndex: 5,
  border: "1px solid #d3d3d3",
  padding: "2px 10px",
  borderRadius: "5px",
}));
