import { Skeleton, Table, TableCell, styled } from "@mui/material";

export const CustomSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        gap: "25px",
        width: "100%",
        padding: "25px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"100%"}
          height={120}
        />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "2rem" }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "2rem" }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "2rem" }} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"100%"}
          height={120}
        />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "2rem" }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "2rem" }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "2rem" }} />
      </div>
    </div>
  );
};

export const TableWrapper = styled(Table)(() => ({
  border: "0.5px solid #ebf2f6",
  borderRadius: "5px",
}));

export const HeadCell = styled(TableCell)(() => ({
  color: "#6e8185",
  fontSize: "14px",
  padding: "8px 8px",
  textAlign: "left",
  borderBottom: "none",
  fontWeight: 600,
  backgroundColor: "#dbe9ed",
  paddingBottom: "5px",
  verticalAlign: "top",
}));

export const BodyCell = styled(TableCell)(({ bold }) => ({
  gap: "10px",
  color: "#6e8185",
  fontSize: "14px",
  padding: "16px 8px",
  borderBottom: "1px solid #dbe9ed",
  fontWeight: bold && 900,
  // whiteSpace: "nowrap",
}));

export const SortWrapper = styled("span")(({ disabled }) => ({
  display: "flex",
  flexDirection: "row",
  width: "fit-content",
  backgroundColor: disabled ? "rgb(237,247,237)" : "white",
  padding: "0px 4px",
  gap: "5px",
  pointerEvents: disabled && "none",
}));
