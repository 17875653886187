import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useEffect } from "react";

import { isEmpty } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import api from "../api/api";
import useUrlQuery from "../custom-hooks/useUrlQuery";
import {
  setAdditionalPremiumChange,
  setDiscountRange,
  setIdvRange,
  setRaiseDiscountRequesLinkButtonClicked,
} from "../modules/quote.slice";
import { errorHandler } from "../utils/errorHandler";
import { premiumCalculations } from "../utils/premiumCalculations";
import { sortingFn } from "../utils/sorting";
import { useGetUser } from "./AuthenticationServices";
import { useGetFilterService, useGetPresetList } from "./dashboard.service";
import { useGetLeadData } from "./lead.service";
import { useGetMaster } from "./master.service";
import { conditionalParam } from "../utils/conditionalParam";

export const useGetQuotes = () => {
  const { endpoints } = useGetLeadData();
  const dispatch = useDispatch();
  const {
    sortedQuotes,
    additionalPremiumChange,
    selected_discount_by_product,
    selected_discount,
    additionalPremiumCount,
    selected_addons,
    plan_type,
    isDiscountResetted,
    productWiseSumInsuredDetais,
  } = useSelector((state) => state.quote);

  const { presetList } = useGetPresetList();
  const { data: userData } = useGetUser();

  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const isOnSummaryPage = location.pathname.split("/").includes("summary");
  const shouldAddProposalId = proposal_id && isOnSummaryPage;
  const flattened_endpoints = Object.keys(endpoints).flatMap(
    (key) => endpoints[key]
  );

  const quotes = useQueries({
    queries: flattened_endpoints.map((item) => {
      const path = item.split("/");
      const productId = path[3];
      const bundleId = path[4];
      let discountkey = productId;

      const key = productId + conditionalParam(bundleId);

      const selectedSumInsured = productWiseSumInsuredDetais[key]?.sum_insured;

      const addonConfiguratorIdForSumInsured =
        productWiseSumInsuredDetais[key]?.addon_configurator_id;

      if (bundleId) discountkey += `/${bundleId}`;

      const discount_for_current = isDiscountResetted
        ? ""
        : selected_discount_by_product[discountkey]?.discount ||
          selected_discount;

      const discount_conf_id = isDiscountResetted
        ? ""
        : selected_discount_by_product[discountkey]?.discount_conf_id;

      return {
        queryKey: [
          "quotes",
          item,
          discount_for_current,
          discount_conf_id,
          proposal_id,
          shouldAddProposalId,
          isDiscountResetted,
          selectedSumInsured,
        ],
        queryFn: () => {
          const sumInsuerdParams = () => {
            if (selectedSumInsured) {
              let paramPrefix = "&";
              if (!discount_for_current) {
                paramPrefix = "?";
              }
              return `${paramPrefix}sum_insured=${productWiseSumInsuredDetais[key]?.sum_insured}&addon_configurator_id=${addonConfiguratorIdForSumInsured}`;
            }
          };

          const url = isDiscountResetted
            ? `${item}`
            : `${item}${
                discount_for_current
                  ? `?discount=${discount_for_current}${
                      discount_conf_id ? `&discount_id=${discount_conf_id}` : ""
                    }`
                  : ""
              }${selectedSumInsured ? sumInsuerdParams() : ""}`;
          return api.get(url);
        },
        onSuccess: (res) => {
          res?.data?.premium_arr?.discount_conf_id &&
            dispatch(
              setDiscountRange({
                min: res.data.premium_arr.min_discount,
                max: res.data.premium_arr.max_discount,
              })
            );
          const min_idv = res?.data?.premium_arr?.min_idv;
          const max_idv =
            userData?.solicitation_type === "POSP"
              ? Math.min(5000000, res?.data?.premium_arr?.max_idv)
              : res?.data?.premium_arr?.max_idv;
          dispatch(
            setIdvRange({
              min: min_idv || Infinity,
              max: max_idv || 0,
            })
          );
        },
      };
    }),
  });

  const sortedEndpoints = sortingFn(
    quotes,
    flattened_endpoints,
    sortedQuotes,
    additionalPremiumChange,
    additionalPremiumCount,
    selected_addons,
    plan_type,
    presetList
  );

  const searchKeyForEndpoint = (value) => {
    return Object.keys(endpoints).find((key) => endpoints[key].includes(value));
  };
  const sortedAndGroupedEndpointsMap = sortedEndpoints.reduce((acc, str) => {
    const key = searchKeyForEndpoint(str);
    if (acc.has(key)) {
      acc.get(key).push(str);
    } else {
      acc.set(key, [str]);
    }
    return acc;
  }, new Map());
  const sortedAndGroupedEndpointsArray = Array.from(
    sortedAndGroupedEndpointsMap.values()
  );

  const allQuotes = sortedAndGroupedEndpointsArray?.map((group) => {
    return group.map((item) => {
      const [baseUrl, path, trace_id, productId, bundleId] = item.split("/");
      return {
        baseUrl,
        path,
        productId,
        bundleId,
        trace_id,
      };
    });
  });

  const quotesWithoutError = quotes.filter((item) => !item.isError);

  const currentTotalPlansCount =
    sortedAndGroupedEndpointsArray.flat(Infinity)?.length;

  return {
    quotes: allQuotes,
    quoteLength: quotesWithoutError.length,
    quotesData: quotes.map((item) => item.data?.data),
    currentTotalPlansCount,
  };
};

export const useGetQuote = ({
  productId,
  bundleId,
  discount,
  discount_conf_id,
}) => {
  const { getUrlQuery } = useUrlQuery();
  const { vehicleDetails } = useGetLeadData();
  const dispatch = useDispatch();
  const location = useLocation();
  const trace_id = getUrlQuery("traceId");
  const proposal_id = getUrlQuery("proposal_id");
  const isOnSummaryPage = location.pathname.split("/").includes("summary");
  const shouldAddProposalId = proposal_id && isOnSummaryPage;
  const { isFilterLoading, isDiscountResetted, productWiseSumInsuredDetais } =
    useSelector((state) => state.quote);
  // addons map
  const { table_list } = useGetMaster({
    master_name: "addon",
  });

  const addon_id_map = table_list.reduce((prev, curr) => {
    return { ...prev, [curr.addon_id]: curr.addon_name };
  }, {});

  const key = productId + conditionalParam(bundleId);

  const selectedSumInsured = productWiseSumInsuredDetais[key]?.sum_insured;

  const addonConfiguratorIdForSumInsured =
    productWiseSumInsuredDetais[key]?.addon_configurator_id;

  const { generateProposalId } = useGenerateProposalId();
  let url = `/get_quote_card/${trace_id}/${productId}`;
  let query = new URLSearchParams();

  if (bundleId) {
    url += `/${bundleId}`;
  }
  if (!isDiscountResetted && discount) {
    query.append("discount", discount);
    query.append("discount_id", discount_conf_id);
  }

  if (selectedSumInsured) {
    query.append("sum_insured", productWiseSumInsuredDetais[key]?.sum_insured);
    query.append("addon_configurator_id", addonConfiguratorIdForSumInsured);
  }

  if (shouldAddProposalId) {
    query.append("proposal_id", proposal_id);
  }

  const { data, isLoading, isError, isSuccess, isFetching } = useQuery(
    [
      "quotes",
      url,
      discount,
      discount_conf_id,
      proposal_id,
      shouldAddProposalId,
      isDiscountResetted,
      selectedSumInsured,
    ],
    () => {
      const newUrl = isDiscountResetted ? url : url + "?" + query?.toString();
      return api.get(newUrl);
    },
    {
      enabled: productId ? true : false,
    }
  );

  const selected_addons = useSelector((state) => state.quote.selected_addons);

  //This will be used for premium calculation
  const available_addons =
    data?.data?.addon_arr
      ?.filter((item) => selected_addons[item.addon_id] && !item.is_included)
      ?.map((item) => ({
        ...item,
        addon_id: item.addon_id,
        isAvailable: true,
      })) || [];

  const bundle = data?.data?.addon_arr?.find((item) => item.is_included);
  const bundle_list = bundle?.bundle_list || [];

  const unavailable_addons = Object.keys(selected_addons)
    .filter(
      (item) =>
        !data?.data?.addon_arr?.some((addon) => +addon?.addon_id === +item) &&
        !bundle_list[item] &&
        !isNaN(+item) &&
        selected_addons[item]
    )
    .map((item) => {
      if (addon_id_map[item]) {
        return {
          ...item,
          addon_id: item,
          addon_name: addon_id_map[item],
          isAvailable: false,
        };
      }
    })
    ?.filter((item) => !isEmpty(item));

  //This will always be displayed on quotecard
  const included_addons =
    data?.data?.addon_arr
      ?.filter((item) => item.is_included)
      .map((item) => ({
        ...item,
        addon_id: item.addon_id,
        isAvailable: true,
      })) || [];

  //This will display on quote card based on selection
  const selected_addons_excluding_included =
    Object.keys(selected_addons)
      .map((item) => ({
        addon_id: item,
        addon_name: selected_addons[item],
        isAvailable: available_addons.some(
          (addon) => +addon.addon_id === +item
        ),
      }))
      .filter(
        (item) =>
          !included_addons.some((addon) => addon.addon_id === item.addon_id)
      ) || [];

  const additionalPremium = available_addons.reduce(
    (prev, curr) => (!curr.is_included ? Number(curr.addon_prem) : 0) + prev,
    0
  );

  useEffect(() => {
    if (additionalPremium || Object.keys(selected_addons).length) {
      dispatch(
        setAdditionalPremiumChange({
          key: [productId, bundleId].join("/"),
          additionalPremium,
          additionalPremiumCount:
            data?.data?.addon_arr?.filter(
              (item) => selected_addons[item.addon_id]
            )?.length +
            Object.keys(bundle_list || {}).filter(
              (item) => selected_addons[item]
            )?.length,
        })
      );
    } else {
      dispatch(
        setAdditionalPremiumChange({
          key: [productId, bundleId].join("/"),
          additionalPremium: 0,
          additionalPremiumCount: 0,
        })
      );
    }
  }, [additionalPremium, Object.keys(selected_addons).length]);

  const allAddons = [...available_addons, ...included_addons];

  const additionalPremiumWithIncluded = allAddons.reduce(
    (prev, curr) => Number(curr.addon_prem) + prev,
    0
  );

  const handleBuyNow = () => {
    generateProposalId({
      quote_data: data?.data,
      addons: [...available_addons, ...included_addons],
      additionalPremium,
      discount_conf_id,
      prev_policy_ic_id: data?.data?.product_data?.prev_policy_ic_id,
      prev_policy_ic_id_data: data?.data?.product_data?.prev_policy_ic_id_data,
      prev_policy_number: data?.data?.product_data?.prev_policy_number,
      depretiation_percentage: data?.data?.premium_arr?.depretiation_percentage,
      owner_type: data?.data?.product_data?.owner_type,
      fuel_type: vehicleDetails?.fuel_type,
    });
  };

  return {
    quoteData: data?.data || {},
    isLoading: isLoading || isFilterLoading || isFetching,
    isError: isError,
    allAddons,
    allAdonsAvailable: data?.data?.addon_arr,
    additionalPremium,
    handleBuyNow,
    selected_addons_excluding_included: selected_addons_excluding_included,
    additionalPremiumWithIncluded,
    unavailable_addons,
    included_addons,
    productData: data?.data?.product_data || {},
    isSuccess,
  };
};

const useGenerateProposalId = () => {
  const {
    registerationDetails,
    vehicleDetails,
    excess_discount,
    ncb_carry_forward,
    claimInPreviousPolicy,
    inputData,
    returnData,
    discount,
  } = useGetLeadData();
  const { getUrlQuery, setUrlQuery } = useUrlQuery();
  const trace_id = getUrlQuery("traceId");
  const proposal_id = getUrlQuery("proposal_id");
  const { segment_slug } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { unNamedCoverAmount } = useSelector((state) => state.quote.pa_cover);
  const { filter_list } = useGetFilterService(segment_slug);
  const { riskStartDate } = useSelector((state) => state.quote);

  const { mutate } = useMutation(
    ({
      quote_data,
      addons,
      additionalPremium,
      discount_conf_id,
      additionalPremiumWithIncluded,
      prev_policy_ic_id,
      prev_policy_ic_id_data,
      prev_policy_number,
      depretiation_percentage,
      fuel_type,
    }) => {
      const { idv_value, net_premium, od_premium, tp_premium } =
        quote_data.premium_arr;

      const allAddons = addons;
      const quoteData = quote_data;
      const {
        totalAddonIgst,
        totalAddonSgst,
        totalAddonCgst,
        totalTP,
        additionalOD,
        allDiscounts,
      } = premiumCalculations(
        quoteData,
        additionalPremiumWithIncluded,
        allAddons
      );

      const addonPrem = allAddons.reduce(
        (prev, curr) => Number(curr.addon_prem) + Number(prev),
        0
      );

      const iGst = quote_data?.premium_arr?.igst + totalAddonIgst || 0;
      const cGst = quote_data?.premium_arr?.cgst + totalAddonCgst || 0;
      const sGst = quote_data?.premium_arr?.sgst + totalAddonSgst || 0;

      const req = {
        trace_id,
        proposal_id,
        segment_slug,
        policy_no: registerationDetails?.policy_no,
        registration_no: registerationDetails?.registration_no,
        selected_addons:
          (addons &&
            addons?.length &&
            addons?.map((item) => ({
              addon_id: item?.addon_id,
              addon_name: item?.addon_name,
              addon_premium: item?.addon_prem,
              addon_gst: item?.addon_gst,
              addon_configurator_id: item?.addon_configurator_id,
              bundle_list: item?.bundle_list,
              is_included: item?.is_included,
            }))) ||
          [],
        od_premium,
        tp_premium,
        calc_od_premium:
          quoteData?.premium_arr?.od_premium +
          addonPrem +
          additionalOD -
          allDiscounts,
        calc_tp_premium: totalTP,
        discount_conf_id:
          discount_conf_id || quote_data?.premium_arr?.discount_conf_id,
        product_id: quote_data.product_data.product_id,
        bundle_product_id: quote_data.product_data.addon_bundle_id,
        idv: idv_value,
        total_premium: (
          net_premium +
          additionalPremium +
          iGst +
          cGst +
          sGst
        )?.toFixed(2),
        pa_cover_unnamed_person:
          quote_data?.premium_arr?.pa_cover?.unnamed_person,
        pa_cover_unnamed_person_amount:
          unNamedCoverAmount ||
          inputData?.pa_cover?.unnamed_person_cover_amount,
        pa_cover_owner_driver: quote_data?.premium_arr?.pa_cover?.owner_driver,
        pa_cover_paid_driver: quote_data?.premium_arr?.pa_cover?.paid_driver,
        paid_driver_sum_insured: inputData?.pa_cover?.paid_driver_sum_insured,
        igst: iGst?.toFixed(2),
        sgst: sGst?.toFixed(2),
        cgst: cGst?.toFixed(2),
        tppd_discount: quote_data?.premium_arr?.pa_cover?.tppd_discount,
        legal_liability_driver: filter_list?.ll_paid_driver_conductor_cleaner
          ? quote_data?.premium_arr?.pa_cover?.legal_liability_driver
          : quote_data?.premium_arr?.pa_cover?.legal_liability_driver,
        legal_liability_employee:
          quote_data?.premium_arr?.pa_cover?.legal_liability_employee,
        legal_liability_paid_conductor_cleaner:
          Number(
            quote_data?.premium_arr?.pa_cover
              ?.legal_liability_paid_conductor_cleaner
          ) > 0
            ? "Y"
            : "N",
        legal_liability_paid_conductor_cleaner_value:
          quote_data?.premium_arr?.pa_cover
            ?.legal_liability_paid_conductor_cleaner_value,
        legal_liability_paid_conductor_cleaner_amount:
          quote_data?.premium_arr?.pa_cover
            ?.legal_liability_paid_conductor_cleaner,
        electrical_accessories:
          quote_data?.premium_arr?.accessories?.electrical_accessories,
        non_electrical_accessories:
          quote_data?.premium_arr?.accessories?.non_electrical_accessories,
        bi_fuel_amount: quote_data?.premium_arr?.accessories?.bi_fuel?.amount,
        bi_fuel_type: quote_data?.premium_arr?.accessories?.bi_fuel?.type,
        bi_fuel_invoice_date:
          quote_data?.premium_arr?.accessories?.bi_fuel?.invoice_date,
        bi_fuel_od_premium:
          quote_data?.premium_arr?.accessories?.bi_fuel?.bi_fuel_od_prem,
        bi_fuel_tp_premium:
          quote_data?.premium_arr?.accessories?.bi_fuel?.bi_fuel_tp_prem,
        ncb: quote_data?.premium_arr?.ncb_per,
        ncb_carry_forward: !!ncb_carry_forward || false,
        ncb_discount: quote_data?.premium_arr?.ncb_discount_amt,
        discount: quote_data?.premium_arr?.discount,
        voluntary_access_foramount: excess_discount?.voluntoryExcessAmount,
        selected_imt: quote_data?.premium_arr?.selected_imt || [],
        anti_theft:
          Number(quote_data?.premium_arr?.discounts?.anti_theft) > 0
            ? "Y"
            : "N",
        anti_theft_amount: Number(
          quote_data?.premium_arr?.discounts?.anti_theft
        ),

        confine_to_site_discount:
          Number(quoteData?.premium_arr?.discounts?.confine_to_site_discount) >
          0,
        confine_to_site_discount_amount: Number(
          quoteData?.premium_arr?.discounts?.confine_to_site_discount
        ),
        confine_to_site_details: discount?.confine_to_site_details,

        handicap_discount:
          Number(quote_data?.premium_arr?.discounts?.handicap_discount) > 0
            ? "Y"
            : "N",
        handicap_discount_amount: Number(
          quote_data?.premium_arr?.discounts?.handicap_discount
        ),
        aa_membership:
          Number(quote_data?.premium_arr?.discounts?.aa_membership) > 0
            ? "Y"
            : "N",
        aa_membership_amount: Number(
          quote_data?.premium_arr?.discounts?.aa_membership
        ),
        voluntary_excess:
          Number(quote_data?.premium_arr?.discounts?.voluntary_excess) > 0
            ? "Y"
            : "N",
        voluntary_deductible:
          quote_data?.premium_arr?.discounts?.voluntary_excess,
        is_vehicle_new: vehicleDetails?.is_vehicle_new,
        exshowroom_price: registerationDetails?.exshowroom_price,
        od_expiry_date: registerationDetails?.od_expiry_date,
        tp_expiry_date: registerationDetails?.tp_expiry_date,
        registration_date: registerationDetails?.registration_date,
        motor_model_id: vehicleDetails?.motor_model_id,
        manufacturer_id: vehicleDetails?.manufacturer_id,
        owner_type: inputData?.owner_type,
        countries_tp_premium: quote_data?.premium_arr?.country_tp_prem,
        countries_od_premium: quote_data?.premium_arr?.country_od_prem,
        countries: (quote_data?.premium_arr?.countries || []).join(","),
        vehicle_type: (returnData?.vehicle_type || [])?.join(","),
        prev_policy_ic_id,
        prev_policy_ic_id_data,
        prev_policy_number,
        depretiation_percentage,
        is_prev_claim: claimInPreviousPolicy || null,
        risk_start_date:
          (vehicleDetails?.is_vehicle_new ||
            inputData?.policy_type === "No Previous Policy") &&
          moment(
            registerationDetails?.risk_start_date || riskStartDate,
            "YYYY-MM-DD"
          )
            .startOf("day")
            .format("DD-MM-YYYY HH:mm:ss"),
        fuel_type,
      };
      return api.post("draft_proposal/add", req);
    },
    {
      onSuccess: (res) => {
        const isFirstRenewal = res?.data?.is_first_renewal;
        const isHandicap = res?.data?.handicap;
        const isNcb = res?.data?.ncbcertificate;
        const isSaleDeed = res?.data?.saledeeddocument;
        const isBreakinInspection = res?.data?.breakin || res?.data?.inspection;
        navigate(
          {
            pathname: "./",
            search: setUrlQuery(
              "proposal_id",
              res.data.return_data.proposal_id
            ),
          },
          {
            replace: true,
          }
        );
        if (
          (res?.data?.is_renewal === "Renewal" ||
            res?.data?.return_data?.policy_type === "Renewal") &&
          !isFirstRenewal &&
          !isHandicap &&
          !isNcb &&
          !isSaleDeed &&
          !isBreakinInspection
        ) {
          navigate({
            pathname: "../summary",
            search: window.location.search,
          });
        } else
          navigate({
            pathname: "../proposal",
            search: window.location.search,
          });
        queryClient.invalidateQueries(["plancard"]);
        // queryClient.resetQueries(["quotes"]);
      },
    }
  );
  return {
    generateProposalId: mutate,
  };
};

// -------------------- Share Quotes --------------------

export const useGetCommunication = () => {
  const queryClient = useQueryClient();
  const { mutate, data, error, reset, isError, isSuccess, isLoading } =
    useMutation((data) => api.post("/communication", data), {
      onSuccess: () => {
        queryClient.invalidateQueries(["communication"]);
      },
    });
  const errList = errorHandler(error, data);

  return {
    createCommunication: mutate,
    isSuccess: isSuccess,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
    sentSuccessfullyMessage: data?.data?.message,
    isLoading: isLoading,
  };
};

export const useSendDiscountRequest = () => {
  const dispatch = useDispatch();
  const { mutate, error, data, reset, isError, isSuccess } = useMutation(
    (data) => api.post("/discount_approval/add", data),

    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            dispatch(setRaiseDiscountRequesLinkButtonClicked(false));
          }, 3000);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    sendDiscountRequest: mutate,
    isSuccess,
    alertMaster: errList,
    resetMaster: reset,
    isError: isError,
  };
};

export const useGetSumInsuredListFromIcId = (payload) => {
  const { data } = useQuery(["sum_insured_list", payload], () => {
    return api.post("sum_insured_list", { ...payload });
  });
  return {
    sum_insured_items: data?.data?.return_data || [],
  };
};
