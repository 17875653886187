import HistoryIcon from "@mui/icons-material/History";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { allowOnlyNumbers } from "../../../../InputUtils/onInput";
import AlertMessage from "../../../../components/Alert/AlertMessage";
import Buttons from "../../../../components/BasicFormComponent/Buttons";
import Select from "../../../../components/InputComponents/Select";
import TextInput from "../../../../components/InputComponents/TextInput";
import Navbar from "../../../../components/Navbar";
import { allApprovalStatus } from "../../../../constants/options";
import withRouteProtection, {
  AuthContext,
} from "../../../../hoc/withRouteProtection";
import { useSendMobileApprovalStatus } from "../../../../services/addmobilenoapproval.service";
import { ClaimActionButton } from "../../../Claim/claimStyledComponents";
import {
  InputContainer,
  MainContainer,
  ResetButton,
  SearchContainer,
} from "../../../servicing/cancellation/CancellationSearch/CancellationSearchStyle";
import MasterTable from "../../MasterTable/MasterTable";
import {
  ButtonWrapper,
  GridChild,
  Heading,
  StyledGrid,
} from "../../components/MasterStyle";

const RmMobileApproval = () => {
  let approval_status = "";
  const methods = useForm({
    defaultValues: {
      approval_status: "pending",
    },
  });
  const [customSearch, setCustomSearch] = useState({
    approval_status: "pending",
  });
  const {
    sendMobileApprovalStatus,
    alertMaster,
    resetMaster,
    isError,
    isSuccess,
    errorMessage,
  } = useSendMobileApprovalStatus();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(["rm_mobile_approval"]);
      setTimeout(() => {
        resetMaster();
      }, 3000);
    }
  }, [isSuccess]);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const onUpdate = (id, mobileNo, count) => {
    sendMobileApprovalStatus({
      mobile_number_id: id,
      mobile_number: mobileNo,
      count: count,
      approval_status,
    });
  };
  const onSubmit = (data) => {
    setCustomSearch(data);
  };

  const handleReset = () => {
    if (searchParams.get("customSearch")) {
      searchParams.delete("customSearch");
      // Creating new search string
      const newSearch = searchParams.toString();
      // Using navigate to update the URL
      navigate(`${location.pathname}?${newSearch}`);
    }
    setCustomSearch({});
    methods.reset({
      mobile: "",
      approval_status: "Pending",
    });
  };
  const { writable, logAccess } = useContext(AuthContext);
  return (
    <Navbar>
      <div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <MainContainer>
              <Heading variant="h5" align="left">
                Mobile Number Approval
              </Heading>
              <AlertMessage
                alert={alertMaster}
                reset={resetMaster}
                isError={isError}
                isSuccess={isSuccess}
                errorMessage={errorMessage}
              />
              <SearchContainer>
                <InputContainer>
                  <StyledGrid columns={12}>
                    <GridChild sm={3} md={3} xs={12} xxs={12}>
                      <TextInput
                        label={"Mobile No."}
                        name={"mobile"}
                        onInput={allowOnlyNumbers}
                        inputProps={{ maxLength: 10 }}
                        required={true}
                        standard
                      />
                    </GridChild>
                    <GridChild sm={3} md={3} xs={12} xxs={12}>
                      <Select
                        label={"Approval Status"}
                        name={"approval_status"}
                        options={allApprovalStatus}
                        standard
                      />
                    </GridChild>
                  </StyledGrid>
                </InputContainer>
                <ButtonWrapper gap={2}>
                  <Buttons label={"Search"} type="submit" standard />

                  <ResetButton onClick={handleReset}>
                    <ReplayIcon />
                    Reset
                  </ResetButton>
                </ButtonWrapper>
              </SearchContainer>
            </MainContainer>
          </form>
        </FormProvider>
        <Box>
          <MasterTable
            writable={writable}
            logAccess={logAccess}
            master_name={"rm_mobile_approval"}
            customSearch={customSearch}
            customRowAction={({ row }) => (
              <>
                {writable && row?.original?.approval_status === "Pending" && (
                  <>
                    <label htmlFor="mobile_approval">Approval Status: </label>
                    <select
                      id="mobile_approval"
                      // Temporary fix. Will be changed
                      onChange={(e) => (approval_status = e.target.value)}
                      style={{
                        textAlign: "center",
                        padding: "3px",
                        margin: "0 5px",
                        outline: "none",
                        borderRadius: "5px",
                      }}
                    >
                      <option value="" selected hidden>
                        Select
                      </option>
                      <option value="approved">Approve</option>
                      <option value="rejected">Reject</option>
                    </select>
                    <ClaimActionButton
                      id="submit_button_for_rm_approval"
                      sx={{ p: 0 }}
                      onClick={() => {
                        onUpdate(
                          row?.original?.mobile_number_id,
                          row?.original?.mobile_number,
                          row?.original?.count
                        );
                      }}
                    >
                      submit
                    </ClaimActionButton>
                  </>
                )}
                {logAccess && (
                  <Tooltip arrow placement="right" title="History">
                    <IconButton
                      color="#475f63"
                      onClick={() => {
                        navigate(
                          `./history/${row?.original?.mobile_number_id}`
                        );
                      }}
                    >
                      <HistoryIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          />
        </Box>
      </div>
    </Navbar>
  );
};

export default withRouteProtection(RmMobileApproval);
