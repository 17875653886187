import { Paper, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const MainContainer = styled(Box)({
  display: "flex",
  gap: "14px",
  padding: "15px 33px",
});

export const AppointContainer = styled(Paper)({
  width: "950px",
  height: "100%",
  padding: "17px",
  borderRadius: "14px",
  boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
  fontSize: "12px",
});

export const AppointInfoContainer = styled(Box)({
  // margin: "9px 13px",
  padding: "0 16px",
  color: "#6e8185",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const DetailsContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  whiteSpace: "nowrap",
});

export const AppointInfoSubContainer = styled(Box)({
  color: "black",
  textAlign: "right",
});

export const AssignContainer = styled(Paper)({
  width: "100%",
  height: "100%",
  padding: "17px",
  borderRadius: "14px",
  boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
  backgroundColor: "#fff",
});

export const AssignInfoContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

export const ContentWrap = styled(Box)({
  display: "flex",
  gap: "10px",
  alignItems: "center",
  justifyContent: "space-between",
});

export const Text = styled(Typography)({
  color: "#6f6f6f",
  fontSize: "13px",
  whiteSpace: "nowrap",
  marginBottom: "auto",
});

export const Value = styled(Typography)({
  fontSize: "13px",
  fontWeight: "bold",
  textAlign: "right",
});

// export const ResetButton = styled(Button)({
//   opacity: "0.69",
//   fontFamily: "Lato",
//   fontSize: "12px",
//   color: "#6e8185",
//   background: "none",
//   borderRadius: "10px"
// });
