export const convertToFormData = (obj) => {
  const formData = new FormData();
  Object.keys(obj).forEach((item) => {
    if (obj[item] instanceof Array) {
      formData.append(item, JSON.stringify(obj[item]));
    } else if (obj[item] instanceof FileList) {
      for (let i = 0; i < obj[item].length; ++i) {
        formData.append(`${item}[]`, obj[item][i]);
      }
    } else if (obj[item]) formData.append(item, obj[item]);
  });
  return formData;
};
