import { useEffect, useRef } from "react";
import { toast } from "react-toastify";

const InternetStatus = () => {
  const toastId = useRef();
  useEffect(() => {
    function onlineHandler() {
      toast.dismiss(toastId.current);
    }

    function offlineHandler() {
      toastId.current = toast.error(
        "Unable to reach the server. Please check your internet connections.",
        {
          autoClose: false,
          closeButton: false,
          closeOnClick: false,
          toastId: "network-error",
        }
      );
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return <></>;
};

export default InternetStatus;
