import {
  GCV_A1,
  GCV_A2,
  GCV_A3,
  GCV_A4,
  MISC_D,
  PCV_C1,
  PCV_C2,
  PVT_CAR,
  THREE_WHEELER,
  TWO_WHEELER,
} from "./segments";
import privateCarImage from "../assets/images/car.png";
import newPolicyImage from "../assets/images/NewPolicy.png";
import renewalImage from "../assets/images/Renewal.png";
import rollOverImage from "../assets/images/RollOver.png";
import noPreviousPolicyImage from "../assets/images/NoPreviousPolicy.png";
import individualImage from "../assets/images/businessman.png";
import companyImage from "../assets/images/office-building.png";
import notFoundImage from "../assets/images/search2.png";
import twoWheelerImage from "../assets/images/2w.png";
import threeWheelerImage from "../assets/images/3w_c1b.png";
import pcvC1Image from "../assets/images/pcv_c1.png";
import pcvC2Image from "../assets/images/pcv_c2.png";
import gcva1Image from "../assets/images/gcv_a1.png";
import gcva2Image from "../assets/images/gcv_a2.png";
import gcva3Image from "../assets/images/gcv_a3.png";
import gcva4Image from "../assets/images/gcv_a4.png";
import miscDImage from "../assets/images/miscD.png";

import {
  COMPANY,
  INDIVIDUAL,
  NEW_POLICY,
  NO_PREVIOUS_POLICY,
  RENEWAL,
  ROLL_OVER,
} from "./PolicyConstants";

/*

1. This file is used to provide images for respective option in dropdown
2. Option code will be the key & value will be respective image as a source

*/

export const DropdownImages = {
  [PVT_CAR]: privateCarImage,
  [NEW_POLICY]: newPolicyImage,
  [RENEWAL]: renewalImage,
  [ROLL_OVER]: rollOverImage,
  [NO_PREVIOUS_POLICY]: noPreviousPolicyImage,
  [INDIVIDUAL]: individualImage,
  [COMPANY]: companyImage,
  [TWO_WHEELER]: twoWheelerImage,
  [THREE_WHEELER]: threeWheelerImage,
  [PCV_C1]: pcvC1Image,
  [PCV_C2]: pcvC2Image,
  [GCV_A1]: gcva1Image,
  [GCV_A2]: gcva2Image,
  [GCV_A3]: gcva3Image,
  [GCV_A4]: gcva4Image,
  [MISC_D]: miscDImage,
  ["not_found"]: notFoundImage, //Temporary
};
