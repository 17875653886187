// policy types
export const NEW_POLICY = "new_policy";
export const RENEWAL = "renewal";
export const ROLL_OVER = "roll_over";
export const NO_PREVIOUS_POLICY = "no_previous_policy";

// ownership types
export const INDIVIDUAL = "Individual";
export const COMPANY = "Company";

export const PREMIUM_AMOUNT_FOR_MANDATORY_PANCARD = 50000;
