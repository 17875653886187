import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../api/api";
import FormData from "form-data";
import { useNavigate, useParams } from "react-router";
import { convertToFormData } from "../utils/convertToFormData";
import useUrlQuery from "../custom-hooks/useUrlQuery";
import { errorHandler } from "../utils/errorHandler";
export const useGetMaster = ({
  master_name,
  columnFilters,
  solicitation_city_id,
  solicitation_state_id,
  solicitation_type,
  globalFilter,
  pageIndex,
  pageSize,
  sorting,
  customSearch,
  page,
  per_page,
  segmentId,
  enabled = true,
  pis_search,
  is_column,
}) => {
  const { segment_id, segment_slug } = useParams();

  const { data, isLoading, refetch, isError, isFetching, error } = useQuery(
    [
      "master",
      master_name,
      columnFilters,
      globalFilter,
      solicitation_city_id,
      solicitation_state_id,
      solicitation_type,
      pageIndex,
      pageSize,
      sorting,
      customSearch,
      segmentId || segment_id || 0,
      segment_slug,
      is_column,
    ],
    () => {
      return api.post(`${master_name}/list`, {
        page_index: pageIndex + 1,
        page_size: pageSize,
        solicitation_city_id,
        solicitation_state_id,
        solicitation_type,
        custom_search: customSearch,
        pis_search,
        search: globalFilter || null,
        sorting: sorting?.length
          ? { id: sorting[0].id, value: sorting[0].desc ? "desc" : "asc" }
          : null,
        column_filters: columnFilters?.length ? columnFilters : null,
        is_column: is_column,
        segment_id: segmentId || segment_id,
        page,
        per_page,
        segment_slug,
      });
    },
    {
      enabled,
      staleTime: 60 * 1000,
    }
  );

  return {
    total_records: data?.data?.total_records,
    table_list: data?.data?.return_data || [],
    column_head:
      data?.data?.column_head || error?.response?.data?.column_head || [],
    isTableLoading: isLoading,
    refetchTable: refetch,
    isTableError: isError,
    isTableFetching: isFetching,
    masterData: data,
    masterError: error,
  };
};

export const useDeleteItemMutation = (master_name) => {
  const queryClient = useQueryClient();
  const { mutate, isSuccess, reset, isError, error } = useMutation(
    ({ id, segmentId }) => {
      return api.post(`${master_name}/delete`, {
        [`${master_name}_id`]: id,
        segment_slug: segmentId,
      });
    },
    {
      onSuccess: () => {
        queryClient.resetQueries([master_name]);
      },
    }
  );
  return {
    deleteItem: mutate,
    deleteSuccess: isSuccess,
    deleteFail: isError,
    deleteError: error?.response?.data,
    deleteReset: reset,
  };
};

export const useGetDownloadService = ({ master_name, enabled }) => {
  const { data } = useQuery(
    ["downloadExcel", master_name],
    () => {
      return api.post(`/${master_name}/sample/excel`);
    },
    {
      enabled,
    }
  );
  return { path: data?.data?.return_data?.path };
};

export const useCreateMaster = (
  master_name,
  isDocumentUpload,
  options = {}
) => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();
  const { mutate, isError, isSuccess, data, error, reset, isLoading } =
    useMutation(
      (data) => {
        const newData =
          id && !options.isAddForUpdate
            ? { [`${master_name}_id`]: id, ...data }
            : data;

        return api({
          method: "POST",
          data: isDocumentUpload ? convertToFormData(newData) : newData,
          url: id ? `${master_name}/update` : `${master_name}/add`,
          ...(isDocumentUpload
            ? { headers: { "Content-Type": "multipart/form-data" } }
            : {}),
        });
      },
      {
        onSuccess: (res) => {
          if (res.data.status && res.data.status !== 500) {
            setTimeout(() => {
              navigate("../");
              queryClient.resetQueries(["master"]);
            }, 3000);
          }
        },
      }
    );

  const errList = errorHandler(error, data);

  return {
    createItem: mutate,
    errorItem: isError,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isLoading: isLoading,
  };
};

export const useFilterIcSurveyorMapData = (master_name) => {
  const { selected_surveyor } = useParams("selected_surveyor");
  const { mutate, isError, isSuccess, data, error, reset, isLoading } =
    useMutation((data) => {
      return api({
        method: "POST",
        data: { ...data, surveyor_id: selected_surveyor },
        url: `${master_name}/list`,
      });
    });

  const errList = errorHandler(error, data);

  return {
    filterIcSuveyorMapData: mutate,
    errorItem: isError,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    dataMaster: data?.data?.return_data,
    resetMaster: reset,
    isLoading: isLoading,
  };
};

export const useGetMasterById = (master_name, isForIcConfig) => {
  const { id } = useParams();
  const { getUrlQuery } = useUrlQuery();
  const segmentId = getUrlQuery("segmentId");
  const {
    data,
    isLoading,
    //  refetch, isError, isFetching
  } = useQuery(["master", master_name, id, segmentId], () => {
    return api.post(`${master_name}/list`, {
      segment_slug: segmentId,
      [isForIcConfig ? "ic_id" : `${master_name}_id`]: id,
    });
  });

  return {
    values: data?.data?.return_data,
    isDataLoading: isLoading,
  };
};

const reduceIndex = (item) => {
  const { child, ...remaining_items } = item;
  if (child.length) {
    const reducedArray = reduceChild(child);
    return [{ ...remaining_items }, ...reducedArray.flat()];
  } else return [{ ...remaining_items }];
};
const reduceChild = (array) => {
  if (!array) return null;
  const reducedArray = array.map((item) => reduceIndex(item));
  return reducedArray.flat();
};
export const useImportExcelMutation = (master_name) => {
  const queryClient = useQueryClient();
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const { mutate, isLoading, data, error, reset } = useMutation(
    (data) => {
      const formData = new FormData();
      formData.append("excel", data);
      return api.post(`${master_name}/upload`, formData, config);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([master_name]);
      },
    }
  );
  const errList = errorHandler(error, data) || [];

  return {
    uploadTable: mutate,
    isUploading: isLoading,
    importError: error?.response?.data?.errors
      ? errList
      : error?.response?.data?.message,
    importData: data?.data?.message,
    importReset: reset,
  };
};

export const useExportExcel = ({
  master_name,
  columnFilters,
  globalFilter,
  pageIndex,
  pageSize,
  sorting,
  customSearch,
  page,
  per_page,
  segmentId,
  pis_search,
}) => {
  const { segment_slug } = useParams();
  const {
    data,
    mutate,
    //  refetch, isError, isFetching
  } = useMutation(
    [
      master_name,
      columnFilters,
      globalFilter,
      pageIndex,
      pageSize,
      sorting,
      customSearch,
      segmentId || segment_slug || 0,
    ],

    () => {
      return api.post(`${master_name}/export`, {
        page_index: pageIndex + 1,
        page_size: pageSize,
        custom_search: customSearch,
        pis_search,
        search: globalFilter || null,
        sorting: sorting?.length
          ? { id: sorting[0].id, value: sorting[0].desc ? "desc" : "asc" }
          : null,
        column_filters: columnFilters?.length ? columnFilters : null,
        is_column: "Y",
        segment_id: segmentId || segment_slug,
        page,
        per_page,
      });
    }
  );
  return {
    exportFile: mutate,
    downloadURL: data?.data?.return_data?.path,
  };
};

export const useGetDealerCode = (dealer_group_id) => {
  const { data } = useQuery(
    ["dealer_code", dealer_group_id],
    () => api.post("/dealer/list", { dealer_group_id }),
    {
      enabled: !!dealer_group_id,
    }
  );

  return {
    dealer_code: data?.data,
  };
};

export const useGetBrokerCode = () => {
  const { data } = useQuery(["broker_code"], () =>
    api.post("/broker/broker_code")
  );

  return {
    broker_code: data?.data?.return_data,
  };
};

export const useGetDealerGroupFromOEM = (oem_id) => {
  const { data } = useQuery(
    ["dealer_group", oem_id],
    () => {
      return api.post(`dealer_group/list`, {
        oem_id,
        sorting: {
          id: "groups_name",
          value: "asc",
        },
      });
    },
    { enabled: Boolean(oem_id) }
  );
  return { table_list: data?.data?.return_data || [] };
};

export const useGetInsuranceListFromOEM = (oem_id) => {
  const { data } = useQuery(
    [("insurance", oem_id)],
    () => {
      return api.post(`discount_od_configurator/insurance_list`, {
        oem_id,
      });
    },
    { enabled: Boolean(oem_id) }
  );
  // console.log("DATA", data);
  return { table_list: data?.data || [] };
};

export const useGetInsuranceListFromOemAndSegment = (oem_id, segment_id) => {
  const { data } = useQuery(
    ["insurance", oem_id, segment_id],
    () => {
      return api.post(`discount_od_configurator/insurance_list`, {
        oem_id,
        segment_id,
        sorting: {
          id: "insurance_name",
          value: "asc",
        },
      });
    },
    { enabled: Boolean(oem_id) && Boolean(segment_id) }
  );
  // console.log("DATA", data);
  return { table_list: data?.data || [] };
};

export const useGetDealerFromDealerGroup = (dealer_group_id) => {
  const { data } = useQuery(
    ["dealer", dealer_group_id],
    () => {
      return api.post(`dealer/get_list`, {
        dealer_group_id,
        sorting: {
          id: "dealer_name",
          value: "asc",
        },
      });
    },
    { enabled: Boolean(dealer_group_id) }
  );
  return { table_list: data?.data?.return_data || [] };
};

export const useGetSegmentFromBroker = (broker_id) => {
  const { data } = useQuery(
    ["segment", broker_id],
    () => {
      return api.post(`/segment/by_broker_id`, {
        broker_id,
        sorting: {
          id: "segment_name",
          value: "asc",
        },
      });
    },
    { enabled: Boolean(broker_id) }
  );
  // console.log("SEGMENT_FROM_BROKER", data);
  return { table_list: data?.data?.return_data || [] };
};

export const useGetSegmentFromOem = (oem_id) => {
  const { data } = useQuery(
    ["segment", oem_id],
    () => {
      return api.post(`segment/by_oem_id`, {
        oem_id,
        sorting: {
          id: "segment_name",
          value: "asc",
        },
      });
    },
    { enabled: Boolean(oem_id) }
  );

  return { table_list: data?.data?.return_data || [] };
};

export const useGetFlagsFromOemMmv = (oem_id) => {
  const { data } = useQuery(
    ["oemflags", oem_id],
    () => {
      return api.post(`/oem_mmv_flag/get_flag_by_oem`, {
        oem_id,
        sorting: {
          id: "oem_id",
          value: "asc",
        },
      });
    },
    { enabled: Boolean(oem_id) }
  );
  return { table_list: data?.data?.return_data || [] };
};

export const useGetRoleFromRoleGroup = (role_group_id) => {
  const { data } = useQuery(
    ["role", role_group_id],
    () => {
      return api.post(`roles/list`, {
        role_group_id,
        sorting: {
          id: "role_name",
          value: "asc",
        },
      });
    },
    { enabled: Boolean(role_group_id) }
  );

  return { table_list: data?.data?.return_data || [] };
};

export const socialGroup = (solicitation_type) => {
  const { data } = useQuery(
    ["solicitation_type", solicitation_type],
    () => {
      return api.post(`user/groups`, {
        solicitation_type,
        sorting: {
          id: "role_group_name",
          value: "asc",
        },
      });
    },
    { enabled: Boolean(solicitation_type) }
  );

  return { table_list: data?.data?.return_data || [] };
};

export const roleGroup = () => {
  const { data } = useQuery(["rolegroup"], () => {
    return api.post("/user/groups", {
      sorting: {
        id: "role_group_name",
        value: "asc",
      },
    });
  });

  return { table_list: data?.data?.return_data || [] };
};

export const useGetAddonFromSegment = (segment_id) => {
  const { data } = useQuery(
    ["addon", segment_id],
    () => {
      return api.post(`addon_configurator/addon_list`, {
        segment_id,
        sorting: {
          id: "addon_name",
          value: "asc",
        },
      });
    },
    { enabled: Boolean(segment_id) }
  );

  return { table_list: data?.data?.return_data || [] };
};

export const useGetSchemeCodeFromSegmentAndMaxDiscount = (
  max_discount,
  segment_id
) => {
  const { data } = useQuery(
    ["scheme_code", max_discount, segment_id],
    () => {
      return api.post(`discount_od_configurator/scheme_code`, {
        max_discount,
        segment_id,
      });
    },
    { enabled: Boolean(max_discount) && Boolean(segment_id) }
  );
  return { scheme_code: data?.data?.return_data };
};

export const useGetBodyTypeFromSegment = (segmentSlug) => {
  const { data } = useQuery(
    ["body_type", segmentSlug],
    () => {
      return api.post(`body_type/list`, {
        segment_slug: segmentSlug,
        sorting: {
          id: "body_type",
          value: "asc",
        },
      });
    },
    { enabled: Boolean(segmentSlug) }
  );

  return { table_list: data?.data?.return_data || [] };
};

export const useGetCoverTypeFromSegment = (segment_id, type) => {
  const { data } = useQuery(
    ["cover_type", segment_id, type],
    () => {
      return api.post(`cover_type/list`, {
        segment_id,
        type,
        sorting: {
          id: "cover_type",
          value: "asc",
        },
      });
    },
    { enabled: Boolean(segment_id) }
  );

  return { table_list: data?.data?.return_data || [] };
};

export const useUpdateStatus = (master_name) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, isSuccess, data, error, reset } =
    useMutation(
      (data) => {
        return api.post(`${master_name}/update`, data);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["master"]);
        },
      }
    );
  const errList = errorHandler(error, data);

  return {
    updateStatus: mutate,
    isUploading: isLoading,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
    errorMessage: error?.response?.data?.message,
    updateResponse: data?.data?.message,
  };
};

export const useGetAddonConfigDetaisFromAddonConfigId = (
  addon_configurator_id
) => {
  const { data } = useQuery(
    ["addon_configurator_details", addon_configurator_id],
    () => {
      return api.post(
        `addon_configurator/list
      `,
        {
          addon_configurator_id,
        }
      );
    },
    { enabled: Boolean(addon_configurator_id) }
  );

  return { table_list: data?.data?.return_data || [] };
};

export const useGetRtoFromState = (rto_state_id) => {
  const { data } = useQuery(
    ["rto_from_state", rto_state_id],
    () => {
      return api.post(`configurator/rto/list`, {
        rto_state_id,
        sorting: {
          id: "rto",
          value: "asc",
        },
      });
    },
    { enabled: Boolean(rto_state_id?.length) }
  );

  return { rto_items: data?.data?.return_data || [] };
};

export const useGetAddonDetaisFromSegmentIdAndIcId = (payload) => {
  const { data } = useQuery(["endorsement_addons", payload], () => {
    return api.post("policyendorsement/ic_wise_addons", payload);
  });
  return { table_list: data?.data?.return_data || [] };
};

export const useGetDealerDataFromDealerMandateId = (payload) => {
  const { data } = useQuery(
    ["master", "dealer_data_from_mandate_id", payload],
    () => {
      return api.post("customer_mandate_form/list", payload);
    }
  );
  return { dealerData: data?.data?.return_data };
};

export const useSearchCustomerMandateData = () => {
  const { mutate, isSuccess, isError, data, error, reset } = useMutation(
    (payload) => api.post("customer_mandate_form/list", payload)
  );
  const errList = errorHandler(error, data);
  return {
    searchMandateData: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
    dealerData: data?.data?.return_data,
  };
};

export const useSendCustomerMandateData = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate, isSuccess, isError, data, error, reset } = useMutation(
    (payload) => api.post("customer_mandate_form/add", payload),
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            queryClient.resetQueries(["master"]);
            navigate("../../servicing/customer_mandate_form");
          }, 3500);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );

  const errList = errorHandler(error, data);
  return {
    sendMandateData: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};

export const useUpdateCustomerMandateData = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isError, data, error, reset } = useMutation(
    (payload) => api.post("customer_mandate_form/update", payload),
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            queryClient.resetQueries(["master"]);
            navigate("../../servicing/customer_mandate_form");
          }, 3500);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );

  const errList = errorHandler(error, data);
  return {
    updateMandateData: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};

export const useGetSortedMaster = ({ master_name, sortBy, columnFilters }) => {
  const res = useGetMaster({
    master_name,
    sorting: sortBy
      ? [
          {
            id: sortBy,
            desc: false,
          },
        ]
      : null,
    columnFilters: columnFilters?.length
      ? [
          ...columnFilters,
          // {
          //   id: "status",
          //   value: "Y",
          // },
          // backend implementation remaining for status with columnFilters
        ]
      : [
          {
            id: "status",
            value: "Y",
          },
        ],
  });

  return { ...res };
};

export const useGetDealerFromOEM = (oem_id) => {
  const { data } = useQuery(
    ["dealer_from_oem", oem_id],
    () => {
      return api.post(`dealer/list`, {
        oem_id,
      });
    },
    { enabled: Boolean(oem_id) }
  );
  return { table_list: data?.data?.return_data || [] };
};

export const useGetDealerNameListFromDealerId = (payload) => {
  const { data } = useQuery(
    ["master", "dealer_name_list", payload],
    () => {
      return api.post("dealer/list", {
        broker_id: payload.broker_id,
        solicitation_type: payload.solicitation_type,
      });
    },
    { enabled: !!payload?.solicitation_type }
  );
  return {
    dealer_name_list: data?.data?.return_data || [],
  };
};

export const useGetDiscountsFromSegment = (segment_slug) => {
  const { data } = useQuery(
    ["discount_list", segment_slug],
    () => {
      return api.post("setting/list", {
        segment_slug,
      });
    },
    { enabled: !!segment_slug }
  );
  return {
    table_list: data?.data?.filters_options?.discounts || [],
  };
};

export const useGetUniqueManufacturerForMmvRequest = () => {
  const { data } = useQuery(["manufacturer_for_mmv_request"], () => {
    return api.post("mmv_request/unique_manufacturer");
  });
  return { manf_items: data?.data?.return_data || [] };
};

export const useGetUniqueModelForMmvRequest = (payload) => {
  const { data } = useQuery(["model_for_mmv_request", payload], () => {
    return api.post("/mmv_request/unique_model", {
      manufacturer_data: payload.manufacturer_data,
      model_id: payload.model_id,
      segment_id: payload.segment_id,
    });
  });
  return { model_items: data?.data || [] };
};

export const useGetManufacturerFromMultipleSegments = (segmentId, OemId) => {
  const { data } = useQuery(
    ["manufacturer_from_multiple_segments", segmentId, OemId],
    () => {
      return api.post("mmv_request/manufacturer", {
        segment_id: segmentId,
        oem_id: OemId,
      });
    }
  );
  return { manf_items: data?.data?.return_data || [] };
};

export const useGetModelsFromManufacturer = (payload) => {
  const { data } = useQuery(["models_from_manufacturer", payload], () => {
    return api.post("mmv_request/model", {
      manufacturer_data: payload.manufacturer_data,
      model_id: payload.model_id,
      segment_id: payload.segment_id,
    });
  });
  return { model_items: data?.data?.return_data || [] };
};

export const useGetModelsFromOemAndSegment = (payload) => {
  const { data } = useQuery(
    ["models_from_oem_segment", payload],
    () => {
      return api.post("motor_model_new/list", {
        oem_id: payload.oem_id,
        segment_id: payload.segment_id,
      });
    },
    { enabled: !!payload.oem_id && !!payload.segment_id }
  );
  return { model_items: data?.data?.return_data || [] };
};

export const useGetBodyTypeFromMultipleSegments = (payload) => {
  const { data } = useQuery(
    ["body_type_from_multiple_segments", payload],
    () => {
      return api.post("mmv_request/body_type", {
        segment_id: payload,
      });
    }
  );
  return { body_type: data?.data?.return_data || [] };
};

export const useSendMmvData = () => {
  const navigate = useNavigate();
  const { mutate, isSuccess, isError, data, error, reset } = useMutation(
    (payload) => api.post("/mmv_request/add", payload),
    {
      onSuccess: (res) => {
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            navigate("/master/mmv_request");
          }, 3500);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);
  return {
    sendMmvData: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
    dealerData: data?.data?.return_data,
  };
};

export const useGetOemFlagsForMmvRequest = () => {
  const { data } = useQuery(["oem_flags"], () => {
    return api.post("mmv_request/oem_flag");
  });
  return { oem_flag_items: data?.data?.return_data || [] };
};

export const useGetLsoFromDealerType = (payload) => {
  const { data } = useQuery(["lso_from_dealer_type", payload], () => {
    return api.post("dealer/get_lso", {
      dealer_type: payload,
    });
  });
  return { lso_items: data?.data?.return_data || [] };
};

export const useGetSolicitationTypeFromMaster = (payload) => {
  const { data } = useQuery(["solicitation_from_type", payload], () => {
    return api.post("solicitation/list", {
      type: payload,
      sorting: {
        id: "solicitation_type",
        value: "asc",
      },
    });
  });
  return { solicitation_list: data?.data?.return_data || [] };
};

export const useGetMispTypeFromRole = (payload) => {
  const { data } = useQuery(["misp_from_role", payload], () => {
    return api.post("user/solicitation_user_type/list", {
      role_id: payload,
    });
  });
  return { misp_items: data?.data?.return_data || [] };
};

export const useGetZoneFromPincode = (payload) => {
  const { data } = useQuery(["zone_from_pincode", payload], () => {
    return api.post("dealer/list", {
      pincode: payload,
    });
  });
  return { zone_items: data?.data?.return_data || [] };
};

export const useGetIcBrokerlisting = (type) => {
  const {
    mutate,
    isLoading,
    isError,
    isSuccess,
    isFetching,
    data,
    error,
    reset,
  } = useMutation((data) => {
    return type === "ic_broker"
      ? api.post("/ic_broker_mapping/list", { ...data, is_column: "Y" })
      : api.post("/ic_oem_mapping/list", { ...data, is_column: "Y" });
  });
  const errList = errorHandler(error, data);

  return {
    sendIcBrokerrMapData: mutate,
    isLoading: isLoading || isFetching,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
    column_data: data?.data?.column_head,
    row_data: data?.data?.return_data,
  };
};

export const useUpdateIcBrokerlisting = (type) => {
  const { mutate, isLoading, isError, isSuccess, data, error, reset } =
    useMutation((data) => {
      return type === "ic_broker"
        ? api.post("/ic_broker_mapping/add", data)
        : api.post("/ic_oem_mapping/add", data);
    });
  const errList = errorHandler(error, data);

  return {
    updateIcBrokerrMapData: mutate,
    isLoading,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};

export const useGetAddonConfigPackageFromIc = (ic_id, oemId) => {
  const { data } = useQuery(
    ["addon_package", ic_id, oemId],
    () => {
      return api.post(`discount_od_configurator/ic_package`, {
        ic_id,
        oem_id: oemId,
      });
    },
    { enabled: Boolean(ic_id) && Boolean(oemId) }
  );
  return { table_list: data?.data?.return_data || [] };
};

export const useGetFailedCount = () => {
  const { mutate, isSuccess, isError, data, error, reset } = useMutation(
    (payload) => api.post("import_history/export", payload),
    {
      onSuccess: (res) => {
        window.open(res?.data?.return_data?.path);
      },
    }
  );
  const errList = errorHandler(error, data);
  return {
    getImportHistory: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};

export const useGetBodyTypeFromSegmentSlug = (payload) => {
  const { data } = useQuery(["body_type_from_segment_slug", payload], () => {
    return api.post("/body_type/list", {
      segment_slug: payload,
    });
  });
  return { body_type_items: data?.data?.return_data || [] };
};

export const useGetOemList = () => {
  const { data } = useQuery(["oem_list"], () => {
    return api.post("oem/list", {
      sorting: {
        id: "oem_name",
        value: "asc",
      },
    });
  });
  return { oem_items: data?.data?.return_data || [] };
};

export const useGetSegmentsFromOEM = (oem_id) => {
  const { data } = useQuery(["segment_from_oem", oem_id], () => {
    return api.post(`oem/segment_list`, {
      oem_id,
    });
  });
  return { segment_items: data?.data?.return_data || [] };
};

export const useGetSegmentsFromModel = (model_id) => {
  const { data } = useQuery(["segment_from_model", model_id], () => {
    return api.post("motor_model_new/segment_list", {
      model_id,
    });
  });
  return { segment_items: data?.data?.return_data || [] };
};

export const useGetModelFromOEM = (oem_id) => {
  const { id } = useParams();
  const { data } = useQuery(["model_from_oem", oem_id], () => {
    return api.post("motor_model_new/list", {
      operation_id: id || "",
      oem_id,
    });
  });
  return { model_items: data?.data?.return_data || [] };
};

export const useGetBodyTypeFromSegments = (segment_slug) => {
  const { data } = useQuery(["segment_from_model", segment_slug], () => {
    return api.post("motor_variant_new/body_type_list", {
      segment_slug,
    });
  });
  return { body_type_items: data?.data?.return_data || [] };
};

export const useGetBankNameFromId = () => {
  const { data } = useQuery(["bank_name"], () => {
    return api.post("bank/list");
  });
  return { bank_name_items: data?.data?.return_data || [] };
};

export const useGetDynamicStatusLink = () => {
  const { mutate, isSuccess, isError, data, error, reset } = useMutation(
    (payload) => api.post("bulk_upload/status", payload),
    {
      onSuccess: (res) => {
        window.open(res?.data?.return_data?.path);
      },
    }
  );
  const errList = errorHandler(error, data);
  return {
    getBulkUploadStatus: mutate,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    resetMaster: reset,
  };
};

export const useGetModelForSelectedPolicyType = (payload) => {
  const { data } = useQuery(["model_for_policy_type", payload], () => {
    return api.post("motor_model_new/list", {
      ...payload,
    });
  });
  return { model_items: data?.data?.return_data || [] };
};

export const useGetVariantForSelectedPolicyType = (payload) => {
  const { data } = useQuery(["variant_for_policy_type", payload], () => {
    return api.post("motor_variant_new/list", {
      ...payload,
    });
  });
  return { variant_items: data?.data?.return_data || [] };
};

export const useGetFuelTypesWithModelAndSegmentSlug = (payload) => {
  const { data } = useQuery(
    ["fuel_type_with_model_and_segment_slug", payload],
    () => {
      return api.post("motor_model_new/fuel_list", {
        ...payload,
      });
    }
  );
  return { fuel_items: data?.data?.return_data || [] };
};

export const useGetRoleWiseUser = (payload) => {
  const { data } = useQuery(["role_wise_user", payload], () => {
    return api.post("user/list", {
      column_filters: payload?.columnFilters,
      sorting: {
        id: payload?.sortBy,
        value: "asc",
      },
      oem_id: payload?.oem_id,
      page_index: null,
      search: null,
    });
  });
  return {
    users: data?.data?.return_data || [],
  };
};

export const useGetDealerNameFromSolicitationType = (
  solicitation_type,
  broker_id
) => {
  const { data } = useQuery(
    ["master", "dealer_name_list", solicitation_type, broker_id],
    () => {
      return api.post("dealer/list", {
        solicitation_type,
        broker_id,
      });
    }
  );
  return {
    dealer_name_list_from_solicitation: data?.data?.return_data || [],
  };
};

export const useGetIcFromBrokerId = (payload) => {
  const { data } = useQuery(["ic_list_from_broker", payload], () =>
    api.post("ic_broker_mapping/ic_list", payload)
  );
  return {
    ic_items: data?.data?.return_data,
  };
};
