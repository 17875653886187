export const downloadFile = (url, options, isTarget) => {
  const link = document.createElement("a");
  if (options) link.setAttribute("href", `options${encodeURIComponent(url)}`);
  if (isTarget) {
    link.setAttribute("target", `_blank`);
  }
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
