import moment from "moment";
import * as yup from "yup";
import { dateFormat } from "../../../../utils/minDateForEndDate";

export const addonWordingsValidationSchema = yup.object().shape({
  ic_id: yup.string().nullable().required("Insurance company is required"),
  oem_id: yup.string().nullable().required("OEM is required"),
  segment_id: yup.string().nullable().required("Segment is required"),
  addon_type: yup.string().nullable().required("Addon name is required"),
  effective_from: yup
    .string()
    .nullable()
    .required("Effective from date is required")
    .test("date_range", "Enter valid effective from date", function (date) {
      return moment(date, dateFormat).isAfter(moment().subtract(1, "days"));
    })
    .test(
      "check_dates",
      "Effective from date should be equal or before effective to date",
      function (date) {
        if (!this.parent.effective_to) return true;
        return moment(date, dateFormat).isBefore(
          moment(this.parent.effective_to, dateFormat).add(1, "days")
        );
      }
    ),
  effective_to: yup
    .string()
    .nullable()
    .required("Effective to date is required")
    .test(
      "check_dates",
      "Effective to date should be equal or after effective from date",
      function (date) {
        if (!this.parent.effective_from) return true;
        return moment(date, dateFormat).isAfter(
          moment(this.parent.effective_from, dateFormat).subtract(1, "days")
        );
      }
    ),
  addon_wording_details: yup
    .string()
    .nullable()
    .required("Addon wording is required"),
});
