import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import {
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  useGetLoginOTP,
  useVerifyOTP,
} from "../../services/AuthenticationServices";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { allowOnlyNumbers } from "../../InputUtils/onInput";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AlertMessage from "../Alert/AlertMessage";
import { mobileNumberRequired } from "../../utils/Validations/mobileNumberValidations";
import Prompt from "../Prompt/Prompt";
import { OTP_TIMER } from "../../constants/OtpTimer";
import { formatedTimer } from "../../utils/formatTimer";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { OtpButtonWrapper } from "./NewLogin/NewLoginStyles";
import { LoginTextfield } from "../Navbar/components/AuthorizedView/styles/Authorized.styles";

const LoginWithMobileNo = ({ switch_user = false }) => {
  const {
    login,
    isLoginSuccess,
    // isSuccess,
    // alertMaster,
    // resetMaster,
    // isError,
  } = useGetLoginOTP();
  const [showOTP, setShowOTP] = useState(false);
  const [otpTextShow, setOtpTextShow] = useState(true);
  const [prompt, setPrompt] = useState(false);
  const [resendOTPTimer, setResendOTPTimer] = useState(OTP_TIMER);

  const {
    verifyOTP,
    isErrorOTP,
    alertMasterOTP,
    resetMasterOTP,
    isSuccessOTP,
    alreadyLoggedIn,
  } = useVerifyOTP({ switch_user });

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(
      yup.object().shape({
        mobile: mobileNumberRequired(),
      })
    ),
  });
  const mobileNumber = watch("mobile");

  const onSubmit = (data) => {
    login(data);
    setShowOTP(true);
    setResendOTPTimer(OTP_TIMER);
  };
  const onSubmitOTP = (data) => {
    verifyOTP({
      otp: data?.otp,
      mobile: mobileNumber,
    });
  };
  const otpRef = useRef();
  const mobileRef = useRef();
  const {
    register: registerOTP,
    formState: { errors: errorsOTP },
    handleSubmit: handleSubmitOTP,
    watch: watchOTP,
    reset: resetOTP,
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(
      yup.object().shape({
        otp: yup.string().required("OTP is required"),
      })
    ),
  });
  useEffect(() => {
    if (showOTP)
      setTimeout(() => {
        otpRef.current?.focus();
      }, 200);
  }, [registerOTP, showOTP, isLoginSuccess]);

  useEffect(() => {
    alreadyLoggedIn ? setPrompt(true) : setPrompt(false);
  }, [alreadyLoggedIn]);

  const handleForceLogOut = () => {
    verifyOTP({
      mobile: mobileNumber,
      otp: watchOTP("otp"),
      force_login: true,
    });
  };

  const handleChangeNumber = () => {
    reset();
    resetOTP();
    resetMasterOTP();
    setShowOTP(false);
    mobileRef.current?.focus();
  };
  const handleResendOTP = () => {
    resetOTP();
    resetMasterOTP();
    setShowOTP(false);
    setTimeout(() => {
      setShowOTP(true);
      login({ mobile: mobileNumber, resend_otp: true });
      setResendOTPTimer(OTP_TIMER);
    }, 300);
  };
  useEffect(() => {
    let timer;
    if (showOTP) {
      timer = setInterval(() => {
        setResendOTPTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [showOTP]);

  return (
    <>
      {/* <AlertMessage
        alert={alertMaster}
        reset={resetMaster}
        isError={isError}
        isSuccess={isSuccess}
      /> */}
      {showOTP && (
        <>
          <AlertMessage
            isLogin
            isSuccess
            alert={["If your mobile number is correct you will receive an OTP"]}
          />
        </>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        // sx={{ padding: "10px 20px", margin: "auto" }}
      >
        <LoginTextfield
          fullWidth
          {...register("mobile")}
          inputRef={mobileRef}
          id="mobile"
          label="Mobile Number"
          name="mobile"
          autoComplete="off"
          autoFocus
          onInput={allowOnlyNumbers}
          InputProps={{
            readOnly: showOTP,
            endAdornment: showOTP && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="change number"
                  onClick={handleChangeNumber}
                  edge="end"
                  color="primary"
                >
                  <EditOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: 10,
          }}
        />
        <FormHelperText data-error-id="mobile" error={Boolean(errors.mobile)}>
          {errors.mobile && errors.mobile.message}
        </FormHelperText>
        <Box style={{ textAlign: "center", marginTop: "10px" }}>
          <Button
            style={{
              boxShadow: !showOTP && "1px 5px 16px #03cc836b",
            }}
            type="submit"
            id="loginPageGetOtpForMobile"
            variant="contained"
            disabled={showOTP}
          >
            Get OTP
          </Button>
        </Box>
      </Box>
      {showOTP && (
        <>
          <AlertMessage
            alert={alertMasterOTP}
            reset={resetMasterOTP}
            isError={isErrorOTP}
            isSuccess={isSuccessOTP}
          />
          <Box
            component={"form"}
            style={{ padding: "10px 0px" }}
            onSubmit={handleSubmitOTP(onSubmitOTP)}
          >
            <LoginTextfield
              onInput={allowOnlyNumbers}
              fullWidth
              name="otp"
              {...registerOTP("otp")}
              inputRef={otpRef}
              label="OTP"
              type={otpTextShow ? "password" : "text"}
              id="otp"
              inputProps={{
                maxLength: 6,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setOtpTextShow(!otpTextShow)}
                      onMouseDown={() => setOtpTextShow(!otpTextShow)}
                      edge="end"
                      color="primary"
                    >
                      {otpTextShow ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText error={Boolean(errorsOTP.otp)}>
              {errorsOTP.otp && errorsOTP.otp.message}
            </FormHelperText>{" "}
            <OtpButtonWrapper>
              <Button
                id="loginPageResendOtp"
                type="button"
                variant="text"
                disabled={resendOTPTimer}
                onClick={handleResendOTP}
              >
                Resend OTP
                {resendOTPTimer ? " : " + formatedTimer(resendOTPTimer) : ""}
              </Button>
              <Button
                style={{ boxShadow: "1px 5px 16px #03cc836b" }}
                type="submit"
                id="loginPageVerifyOtpForMobile"
                variant="contained"
                sx={{ bgcolor: "#2cd44a", color: "white" }}
              >
                Verify OTP
              </Button>
            </OtpButtonWrapper>
          </Box>
        </>
      )}
      <Prompt
        open={prompt}
        description={
          "You are already logged in another device. Are you sure you want to continue here?"
        }
        onAgree={() => handleForceLogOut()}
        onCancel={() => setPrompt(false)}
      />
    </>
  );
};

export default LoginWithMobileNo;
