import React from "react";
import { Box, Grid } from "@mui/material";
import {
  ButtonWrapper,
  FormContainer,
  Papers,
  StyledGrid,
} from "../../../masters/components/MasterStyle";
import { FormProvider, useForm } from "react-hook-form";
import TextInput from "../../../../components/InputComponents/TextInput";
import { useParams } from "react-router";
import { useGetApdApprovalDetailsFromApdId } from "../../../../services/icApproval.service";
import {
  SummaryChild,
  SummaryHeadContainer,
  SummaryHeading,
  SummarySubContainer,
  SummarySubHeading,
  SummaryTitle,
} from "../../../summary/summarystyle";
import Radio from "../../../../components/InputComponents/Radio";
import { approval_options } from "../../../../constants/options";
import Buttons from "../../../../components/BasicFormComponent/Buttons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSendApprovalStatus } from "../../../../services/apd.service";
import AlertMessage from "../../../../components/Alert/AlertMessage";
import { DocumentWrapper } from "../../../servicing/cancellation/CancellationApproval/CancellationApprovalStyles";
import Navbar from "../../../../components/Navbar";
const ApdDetailsView = () => {
  const { apd_id } = useParams();

  const { apd_details } = useGetApdApprovalDetailsFromApdId(apd_id);

  const schema = yup.object().shape({
    approval_status: yup
      .string()
      .nullable()
      .required("Approval status is required"),
    remarks: yup
      .string()
      .test("check_if_rejected", "Remarks is required", function (val) {
        const { approval_status } = this.parent;

        return approval_status === "rejected" && !val ? false : true;
      }),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      remarks: "",
    },
  });

  const {
    sendApprovalStatus,
    isSuccess,
    isLoading,
    isError,
    alertMaster,
    resetMaster,
  } = useSendApprovalStatus();

  const onSubmit = (data) => {
    data.apd_id = apd_id;
    sendApprovalStatus(data);
  };

  const approvalStatus = methods.watch("approval_status");

  return (
    <Navbar>
      <Box>
        <FormContainer>
          <Papers elevation={3} style={{ width: "100%" }}>
            <SummaryHeadContainer sx={{ display: { xxs: "none", xs: "flex" } }}>
              <SummaryTitle> Apd Approval Details</SummaryTitle>
            </SummaryHeadContainer>
            <SummarySubContainer>
              <StyledGrid container columns={12} spacing={0}>
                <SummaryChild item xxs={12} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">
                    Dealer Name
                  </SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.dealer_name}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={12} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">
                    Insurance Company Name
                  </SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.insurance_name}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">Bank Name</SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.bank_name}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">
                    Account No.
                  </SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.account_no}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">Bank City</SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.city_name}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">URN No.</SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.urn_no}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">
                    Transaction Date
                  </SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.transaction_date}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">Amount</SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.amount}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">
                    Payment Mode
                  </SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.payment_mode}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">Remarks</SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.remarks}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">
                    IC Approval Status
                  </SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.ic_approval_status}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">
                    Approved By
                  </SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.insurance_name}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">
                    Requested Date
                  </SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.requested_date}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">
                    Approved Date
                  </SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    {apd_details?.approved_date}
                  </SummarySubHeading>
                </SummaryChild>
                <SummaryChild item xxs={6} xs={6} sm={6} md={3} lg={3}>
                  <SummaryHeading variant="subtitle1">
                    Payment Document
                  </SummaryHeading>
                  <SummarySubHeading variant="subtitle2">
                    <DocumentWrapper
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(apd_details?.payment_document, "_blank");
                      }}
                    >
                      View
                    </DocumentWrapper>
                  </SummarySubHeading>
                </SummaryChild>
              </StyledGrid>
            </SummarySubContainer>
          </Papers>
        </FormContainer>
        {(apd_details?.ic_approval_status === "pending" ||
          apd_details?.ic_approval_status === "Pending") && (
          <FormContainer>
            <Papers elevation={3} style={{ width: "100%" }}>
              <SummaryHeadContainer
                sx={{ display: { xxs: "none", xs: "flex" } }}
              >
                <SummaryTitle>Approval</SummaryTitle>
              </SummaryHeadContainer>
              <AlertMessage
                alert={alertMaster}
                reset={resetMaster}
                isError={isError}
                isSuccess={isSuccess}
              />
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Grid container spacing={1} columnSpacing={3} mt={1}>
                    <Grid item xxs={12} xs={6} md={3} lg={3}>
                      <Radio
                        name={"approval_status"}
                        label={"Approval Status"}
                        standard
                        options={approval_options}
                        required={true}
                      />
                    </Grid>
                    <Grid item xxs={12} xs={6} md={3} lg={4}>
                      <TextInput
                        name={"remarks"}
                        label={"Remarks"}
                        required={approvalStatus === "rejected" ? true : false}
                        standard
                      />
                    </Grid>
                  </Grid>
                  <Box>
                    <ButtonWrapper gap={2} display="flex" alignItems="center">
                      <Buttons
                        label={"Submit"}
                        type="submit"
                        disabled={isLoading || isSuccess}
                        standard
                      />
                    </ButtonWrapper>
                  </Box>
                </form>
              </FormProvider>
            </Papers>
          </FormContainer>
        )}
      </Box>
    </Navbar>
  );
};

export default ApdDetailsView;
