import { ArrowBackOutlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { useNavigate, useParams } from "react-router";
import fynlogo from "../../assets/images/fynLogo.png";
import { DropdownImages } from "../../constants/DropdownImages";
import MobileMenu from "../../layouts/MobileMenu/MobileMenu";
import FloatingTickerCard from "../../pages/dashboard/components/TickerCard/FloatingTickerCard";
import { useGetUser } from "../../services/AuthenticationServices";
import BottomNavigation from "./components/BottomNavigation/BottomNavigation";
import useNavbar from "./hooks/useNavbar";
import {
  BackNavigationBox,
  Container,
  LocationName,
  MobileMenuIcon,
  NavAppBar,
  NavBox,
  NavToolBox,
  NavToolbar,
  NavTypography,
  NavbarLogo,
  SegmentImageContainer,
} from "./styles/navbar.style";
import { useSessionTimout } from "../../custom-hooks/useSessionTimeout";
import AuthorizedView from "./components/AuthorizedView";

export default function Navbar({ children }) {
  const navigate = useNavigate();
  const { isAuthorized } = useGetUser();
  const { segment_slug } = useParams();
  const {
    toggleMenu,
    breadCrumbs,
    handleNavbarBackButton,
    showSegmentImage,
    showMobileMenu,
    tickerState: { isTickerOpen, setIsTickerOpen },
  } = useNavbar();
  const isMobile = useMediaQuery("(max-width:560px)");

  // for session timout
  useSessionTimout();

  return (
    <>
      <NavBox>
        <NavAppBar className={"printout"}>
          <NavToolbar>
            <NavToolBox>
              {isAuthorized && (
                <MobileMenuIcon onClick={toggleMenu}>
                  <MenuIcon />
                </MobileMenuIcon>
              )}
              <NavbarLogo
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                <img src={fynlogo} alt="logo" />
              </NavbarLogo>
              <NavTypography component="div">
                {location?.pathname !== "/dashboard" &&
                  location?.pathname !== "/login" && (
                    <BackNavigationBox onClick={handleNavbarBackButton}>
                      <ArrowBackOutlined
                        sx={{
                          color: "#006eff",
                        }}
                        fontSize="small"
                      />
                    </BackNavigationBox>
                  )}
                {showSegmentImage && (
                  <SegmentImageContainer>
                    <img src={DropdownImages[segment_slug]} width="30px" />
                  </SegmentImageContainer>
                )}
                <Box display={"flex"} alignItems={"center"}>
                  {breadCrumbs?.map((breadCrumb, index) => {
                    return (
                      <>
                        <LocationName
                          onClick={() => {
                            navigate(breadCrumb?.resolvedPath);
                          }}
                          primary={!index}
                        >
                          {breadCrumb?.name}
                        </LocationName>
                      </>
                    );
                  })}
                </Box>
              </NavTypography>
            </NavToolBox>
            <Box display={"flex"}>
              {isAuthorized &&
              !location?.pathname?.includes("reset_password") ? (
                <AuthorizedView />
              ) : null}
            </Box>
          </NavToolbar>
        </NavAppBar>
        {showMobileMenu && (
          <>
            <MobileMenu toggleMenu={toggleMenu} showMenu={showMobileMenu} />
            <div className="mobileMenuBlur" />
          </>
        )}
      </NavBox>

      <Container>{children}</Container>
      {!isMobile && <BottomNavigation />}
      {location.pathname !== "/dashboard" && (
        <FloatingTickerCard
          isTickerOpen={isTickerOpen}
          setTickerOpen={setIsTickerOpen}
        />
      )}
    </>
  );
}
