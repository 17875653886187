import { yupResolver } from "@hookform/resolvers/yup";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import AlertMessage from "../../../../../../../../components/Alert/AlertMessage";
import { SubmitButton } from "../../../../../../../../components/InputComponents/InputComponents.style";
import {
  useGetPolicyDetailsRegistration,
  useSendDeliveryOrderDetails,
} from "../../../../../../../../services/claims.service";
import {
  AtleastOnefieldError,
  ButtonWrapper,
} from "../../../../../../../masters/components/MasterStyle";
import {
  ClaimHeading,
  MainPaperContainer,
} from "../../../../../../styles/claim.styles";
import DeliveryOrderFormFields from "../components/DeliveryOrderFormFields";
import { deliveryOrderSchema } from "../validations/deliveryOrder.validations";
import { toast } from "react-toastify";

function DeliveryOrderForm() {
  const { claim_detail_id } = useParams("claim_detail_id");

  const { policy_details_list: claimDetailsFromClaimId } =
    useGetPolicyDetailsRegistration(claim_detail_id);

  const claim_details_from_claim_id = claimDetailsFromClaimId?.[0];

  const {
    sendDeliveryOrderDetails,
    isSuccess,
    alertMaster,
    resetMaster,
    isError,
  } = useSendDeliveryOrderDetails();

  const previousSelectedValues = {
    delivery_order_no: claim_details_from_claim_id?.delivery_order_no || "",
    approved_amount: claim_details_from_claim_id?.approved_amount || "",
    delivery_order_amount:
      claim_details_from_claim_id?.delivery_order_amount || "",
    delivery_order_remark:
      claim_details_from_claim_id?.delivery_order_remark || "",
  };

  const isPreviousDetailsAvailable = Object?.values(
    previousSelectedValues
  ).some((item) => !!item);
  const methods = useForm({
    resolver: yupResolver(deliveryOrderSchema({ isPreviousDetailsAvailable })),
    defaultValues: {
      delivery_order_no: claim_details_from_claim_id?.delivery_order_no || "",
      approved_amount: claim_details_from_claim_id?.approved_amount || "",
      delivery_order_amount:
        claim_details_from_claim_id?.delivery_order_amount || "",
      delivery_order_remark:
        claim_details_from_claim_id?.delivery_order_remark || "",
    },
  });

  const {
    delivery_order_no,
    approved_amount,
    delivery_order_amount,
    delivery_order_remark,
    delivery_order_document,
  } = methods.getValues();

  const isDocumentSelected = delivery_order_document?.size > 0;

  const enteredValues = {
    delivery_order_no,
    approved_amount,
    delivery_order_amount,
    delivery_order_remark,
  };

  const onSubmit = (data) => {
    const isSameAsPreviousValues = isEqual(
      previousSelectedValues,
      enteredValues
    );

    if (
      isSameAsPreviousValues &&
      !isDocumentSelected &&
      isPreviousDetailsAvailable
    ) {
      toast.info("No details have been changed!");
    }

    const payload = {
      ...data,
      claim_detail_id,
      claim_stage: claim_details_from_claim_id?.claim_stage,
    };
    sendDeliveryOrderDetails(payload);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <MainPaperContainer>
          <ClaimHeading>Delivery Order</ClaimHeading>
          <AlertMessage
            alert={alertMaster}
            reset={resetMaster}
            isError={isError}
            isSuccess={isSuccess}
          />
          <DeliveryOrderFormFields />
          <ButtonWrapper>
            <SubmitButton variant="contained" type="submit">
              Submit
            </SubmitButton>
          </ButtonWrapper>
        </MainPaperContainer>
      </form>
    </FormProvider>
  );
}

export default DeliveryOrderForm;
