import React from "react";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import { useReadNotification } from "../../../../services/dashboard.service";
import ClearIcon from "@mui/icons-material/Clear";

const Notification = ({ notification, handleClose, open }) => {
  const { readNotification } = useReadNotification();
  const unreadNotifications = notification.filter(
    (item) => item.read_status === "N"
  );

  const markAllAsRead = () => {
    const updatedUnreadNotifications = unreadNotifications
      ?.map((item) => ({
        ...item,
        read_status: "Y",
      }))
      .map((item) => item.notification_id);
    readNotification(updatedUnreadNotifications);
  };

  return (
    <>
      {unreadNotifications.length > 0 && (
        <Menu
          sx={{
            "& ul": {
              padding: "0px",
            },
            "& .MuiMenu-paper": {
              borderRadius: "10px",
              overflow: "overlay",
            },
            maxHeight: "408px",
          }}
          anchorEl={open}
          open={Boolean(open)}
          onClose={handleClose}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "2px 10px",
              borderBottom: "1px solid rgba(168, 169, 173, 0.5)",
              minWidth: "340px",
            }}
          >
            <Box sx={{ fontSize: "16px", fontWeight: "500" }}>
              Notifications
            </Box>

            <Button
              style={{
                textTransform: "capitalize",
                fontSize: "14px",
              }}
              onClick={markAllAsRead}
            >
              Mark All As Read
            </Button>
          </Box>
          <Box sx={{ padding: "10px", paddingBottom: "0px" }}>
            {unreadNotifications?.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  if (item?.data?.link) {
                    return window.open(item?.data?.link);
                  }
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                  borderRadius: "8px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <MarkUnreadChatAltIcon color="primary" />
                <Box
                  sx={{
                    ml: 2,
                    fontSize: "14px",
                    color: "#333",
                  }}
                >
                  {item.message}
                </Box>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    readNotification([item.notification_id]);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </MenuItem>
            ))}
          </Box>
        </Menu>
      )}
    </>
  );
};

export default Notification;
