import { Button, styled, Paper, Box } from "@mui/material";

export const ResetButton = styled(Button)(({ theme }) => ({
  opacity: "0.69",
  fontFamily: "Lato",
  fontSize: "12px",
  color: "#6e8185",
  background: "none",
  borderRadius: "10px",
  padding: "10px 15px",
  height: "36px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
    padding: "8px 12px",
    height: "30px",
  },
}));

export const MainContainer = styled(Paper)(({ theme }) => ({
  padding: "15px",
  margin: "30px",
  borderRadius: "14px",
  boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
  [theme.breakpoints.up("sm")]: {
    margin: "30px",
  },
  [theme.breakpoints.up("md")]: {
    margin: "30px",
  },
}));

export const SearchContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  padding: "0 15px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "10px",
  },
}));

export const InputContainer = styled(Box)({
  margin: "0",
  width: "100%",
});

export const TableContainer = styled(Box)({
  margin: "0 15px",
});

export const ClaimActionButton = styled(Button)({
  margin: "5px",
  padding: "2px 10px",
  borderRadius: "999px",
  border: "solid 0.6px #03cc83",
  backgroundColor: "rgba(205, 255, 237, 0.42)",
});

export const ButtonContainer = styled(Box)(({ theme }) => ({
  // position: "relative",
  position: "absolute",
  right: "0",
  bottom: "0",
  padding: "0",
  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column ",
    position: "relative",
    // justifyContent: "center",
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  // width: "100%",
  display: "flex",
  gap: "15px",
  margin: "12px 15px",
  alignItems: "flex-start",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));
