import { useQuery } from "@tanstack/react-query";
import api from "../api/api";

export const useGetRoleAccessByRoleId = (
  role_id,
  custom_role,
  options = {}
) => {
  const { data, isLoading, isSuccess } = useQuery(
    ["access", role_id],
    () => {
      const payload = custom_role
        ? {
            dealer_role_id: role_id,
          }
        : { role_id: role_id };

      return api.post("role_access/get_access", payload);
    },
    {
      enabled: !!role_id,
      ...options,
    }
  );

  return {
    access: data?.data?.return_data?.access || data?.data?.return_data || {},
    policy_access: data?.data?.return_data?.policy_access || {},
    isAccessLoading: isLoading,
    isAccessSuccess: isSuccess,
  };
};

export const useGetRoleAccessByRoleIdForMaster = (
  role_id,
  options = {},
  dealerRoleAccessMaster
) => {
  const { data, isLoading, isSuccess } = useQuery(
    ["role_access_master", role_id],
    () => {
      const payload = dealerRoleAccessMaster
        ? {
            dealer_role_id: role_id,
          }
        : { role_id: role_id };

      return api.post("role_access/get_access", payload);
    },
    {
      enabled: !!role_id,
      ...options,
    }
  );

  return {
    access: data?.data?.return_data?.access || data?.data?.return_data || {},
    policy_access: data?.data?.return_data?.policy_access || {},
    isAccessLoading: isLoading,
    isAccessSuccess: isSuccess,
  };
};

export const useGetBrokeSettings = () => {
  const { data } = useQuery(["broker_setting"], () => api.post("broker/list"));
  return {
    broker_settings: data?.data?.return_data || [],
  };
};
