import React, { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  ButtonWrapper,
  Papers,
  StyledGrid,
} from "../../../masters/components/MasterStyle";
import Buttons from "../../../../components/BasicFormComponent/Buttons";
import { HeadingLabel } from "../../../Configurator/DiscountConfigurator/DiscountConfiguratorStyle";
import { useGetSortedMaster } from "../../../../services/master.service";
import AlertMessage from "../../../../components/Alert/AlertMessage";
import { allowOnlyNumbers } from "../../../../InputUtils/onInput";
import { useGetAddMobileNoApprovalForbroker } from "../../../../services/addmobilenoapproval.service";
import { Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { BrokerValidation } from "./BrokerValidation";
import TextInput from "../../../../components/InputComponents/TextInput";
import MultiSelect from "../../../../components/InputComponents/MultiSelect";
import {
  InputContainer,
  SearchContainer,
} from "../../../servicing/cancellation/CancellationSearch/CancellationSearchStyle";
import Navbar from "../../../../components/Navbar";

const AddBrokerMobileRequest = ({ values }) => {
  const methods = useForm({
    resolver: yupResolver(BrokerValidation),
    defaultValues: {
      dealer_id: [],
      mobile_number: "",
      count: "",
      ...values,
    },
  });

  const { table_list: dealer_list } = useGetSortedMaster({
    master_name: "dealer",
    sortBy: "dealer_name",
  });

  const dealer_group_list = useMemo(() => {
    return dealer_list.map((item) => {
      return {
        code: item.dealer_id,
        display_name: item.dealer_name,
      };
    });
  }, [dealer_list]);

  const { addMobileNoApproval, alertMaster, resetMaster, isError, isSuccess } =
    useGetAddMobileNoApprovalForbroker();

  const onSubmit = (data) => {
    addMobileNoApproval(data);
  };

  return (
    <Navbar>
      <Papers elevation={3} style={{ width: "55%" }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <HeadingLabel variant="h5" align="left">
              Add Broker Mobile Request
            </HeadingLabel>
            <AlertMessage
              alert={alertMaster}
              reset={resetMaster}
              isError={isError}
              isSuccess={isSuccess}
            />
            <SearchContainer>
              <InputContainer>
                <StyledGrid columns={12}>
                  <Grid item xxs={12} xs={6} md={4} lg={4}>
                    <TextInput
                      label={"Mobile No."}
                      name={"mobile_number"}
                      onInput={allowOnlyNumbers}
                      inputProps={{ maxLength: 10 }}
                      required={true}
                    />
                  </Grid>
                  <Grid item xxs={12} xs={6} md={4} lg={4}>
                    <MultiSelect
                      label={"Dealer"}
                      name={"dealer_id"}
                      options={[
                        // { code: "0", display_name: "Select All" },
                        ...dealer_group_list,
                      ]}
                      // isSelectAll={false}
                      required={true}
                    />
                  </Grid>
                  <Grid item xxs={12} xs={6} md={4} lg={4}>
                    <TextInput
                      label={"Count"}
                      name={"count"}
                      onInput={allowOnlyNumbers}
                      inputProps={{ maxLength: 2 }}
                      required={true}
                    />
                  </Grid>
                </StyledGrid>
                <ButtonWrapper gap={2}>
                  <Buttons
                    id="submit_button_for_mobile_request"
                    label={"Submit"}
                    type="submit"
                    standard
                  />
                </ButtonWrapper>
              </InputContainer>
            </SearchContainer>
          </form>
        </FormProvider>
      </Papers>
    </Navbar>
  );
};

export default AddBrokerMobileRequest;
