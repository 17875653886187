import { useMutation, useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router";
import api from "../api/api";

export const useGetAuditLog = ({
  // master_name,
  columnFilters,
  globalFilter,
  page_index,
  page_size,
  sorting,
  customSearch,
  page,
  per_page,
  segmentId,
  enabled = true,
  pis_search,
}) => {
  const { segment_id, segment_slug, id, master_name } = useParams();
  const location = useLocation();
  const param = useParams();
  const { data, isLoading, refetch, isError, isFetching, error } = useQuery(
    [
      master_name,
      columnFilters,
      globalFilter,
      page_index,
      page_size,
      sorting,
      customSearch,
      segmentId || segment_id || 0,
      segment_slug,
      id,
      param,
      location,
    ],
    () => {
      return api.post(`history/list`, {
        page_index: page_index + 1,
        page_size,
        customSearch,
        pis_search,
        search: globalFilter || null,
        sorting: sorting?.length
          ? { id: sorting[0].id, value: sorting[0].desc ? "desc" : "asc" }
          : null,
        column_filters: columnFilters?.length ? columnFilters : null,
        is_column: "Y",
        segment_id: segmentId || segment_id,
        page,
        per_page,
        segment_slug,
        id: id ? id : param[Object.keys(param)[0]],
        table_name: master_name
          ? master_name
          : location?.pathname
              ?.split("/history")[0]
              ?.split("/")
              ?.reverse()?.[0],
      });
    },
    {
      enabled,
    }
  );

  return {
    total_records: data?.data?.total_records,
    table_list: data?.data?.return_data || [],
    column_head:
      data?.data?.column_head || error?.response?.data?.column_head || [],
    isTableLoading: isLoading,
    refetchTable: refetch,
    isTableError: isError,
    isTableFetching: isFetching,
    masterData: data,
    masterError: error,
  };
};

export const useGetAuditLogExportExcel = ({
  columnFilters,
  globalFilter,
  page_index,
  page_size,
  sorting,
  customSearch,
  page,
  per_page,
  segmentId,
  pis_search,
}) => {
  const { segment_slug, id, master_name } = useParams();
  const param = useParams();
  const {
    data,
    mutate,
    //  refetch, isError, isFetching
  } = useMutation(
    [
      master_name,
      columnFilters,
      globalFilter,
      page_index,
      page_size,
      sorting,
      customSearch,
      segmentId || segment_slug || 0,
      id,
    ],

    () => {
      return api.post(`history/export`, {
        page_index: page_index + 1,
        page_size,
        custom_search: customSearch,
        pis_search,
        search: globalFilter || null,
        sorting: sorting?.length
          ? { id: sorting[0].id, value: sorting[0].desc ? "desc" : "asc" }
          : null,
        column_filters: columnFilters?.length ? columnFilters : null,
        is_column: "Y",
        segment_id: segmentId || segment_slug,
        page,
        per_page,
        id: id ? id : param[Object.keys(param)[0]],
        table_name: master_name
          ? master_name
          : location?.pathname
              ?.split("/history")[0]
              ?.split("/")
              ?.reverse()?.[0],
      });
    }
  );
  return {
    exportFile: mutate,
    downloadURL: data?.data?.return_data?.path,
  };
};

export const useGetIcApiLogDetails = (http_logs_id) => {
  const { data } = useQuery(
    ["dealer_code", http_logs_id],
    () => api.post("/http_logs/list", { http_logs_id }),
    {
      enabled: !!http_logs_id,
    }
  );
  return {
    icLogData: data?.data?.return_data || [],
  };
};

export const useGetIcApiLogDownloadLink = () => {
  const { mutate, data } = useMutation((data) => {
    return api.post("/http_logs/list", { ...data });
  });
  return {
    downloadLink: data?.data?.return_data || "",
    generateDownloadLink: mutate,
  };
};

export const useGetIcApiMetricsDetails = () => {
  const { mutate, data, isLoading } = useMutation((data) => {
    return api.post("/http_logs/metrics", { ...data });
  });
  return {
    icMetricsData: data?.data?.return_data || [],
    searchApiMetrics: mutate,
    isLoading: isLoading,
  };
};

export const useGetExceptionLogFromExceptionLogId = (exception_log_id) => {
  const { data } = useQuery(["exception_log_from_id", exception_log_id], () =>
    api.post("exception_log/list", { exception_log_id })
  );

  return {
    log: data?.data?.return_data,
  };
};
