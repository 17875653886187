import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  InputContainer,
  MainContainer,
  ResetButton,
  SearchContainer,
} from "../servicing/cancellation/CancellationSearch/CancellationSearchStyle";
import {
  ButtonWrapper,
  GridChild,
  Heading,
  StyledGrid,
} from "../masters/components/MasterStyle";
import ReplayIcon from "@mui/icons-material/Replay";
import Buttons from "../../components/BasicFormComponent/Buttons";
import { Box } from "@mui/material";
import FeedCard from "./FeedCard";
import {
  useFeedFileExport,
  useGetFeedFileOne,
  usePreGetFeedFile,
} from "../../services/feedFile.service";
import FeedCardOnButtonClick from "./FeedCardOnButtonClick";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import withRouteProtection from "../../hoc/withRouteProtection";
import DateInput from "../../components/InputComponents/DateInput";
import { dateFormat } from "../../utils/minDateForEndDate";
import Navbar from "../../components/Navbar";
const schema = yup.object().shape({
  from_date: yup
    .string()
    .required("From Date is required")
    .test("from_date", "Invalid date", function (val) {
      const pastday = moment().subtract(1, "day");
      return moment(val, dateFormat).isBefore(pastday, moment());
    }),
  to_date: yup
    .string()
    .required("To Date is required")
    .test("from_date", "Invalid date", function (val) {
      const pastday = moment().subtract(1, "day");
      return moment(val, dateFormat).isBefore(pastday, moment());
    }),
});

const FeedSearch = () => {
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const { getFeed, feedFileList, isFeedLoading } = useGetFeedFileOne();
  const { PreGetFeedFileList } = usePreGetFeedFile();
  const { getFeedExport } = useFeedFileExport();

  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const onSubmit = (data) => {
    const { from_date, to_date } = data;
    if (from_date && to_date) {
      getFeed(data);
      setIsSearchClicked(true);
    }
  };

  const handleReset = () => {
    methods.reset();
    setIsSearchClicked(false);
  };

  return (
    <Navbar>
      <div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <MainContainer>
              <Heading variant="h5" align="left">
                Feed Search
              </Heading>

              <SearchContainer>
                <InputContainer>
                  <StyledGrid columns={12}>
                    <GridChild>
                      <DateInput
                        name={"from_date"}
                        label={"From Date"}
                        variant={"standard"}
                        required={true}
                        disableFuture={true}
                      />
                    </GridChild>

                    <GridChild>
                      <DateInput
                        name={"to_date"}
                        label={"To Date"}
                        variant={"standard"}
                        required={true}
                        disableFuture={true}
                      />
                    </GridChild>
                  </StyledGrid>
                </InputContainer>
                <ButtonWrapper gap={2}>
                  <Buttons label={"Search"} type="submit" standard />

                  <ResetButton onClick={handleReset}>
                    <ReplayIcon />
                    Reset
                  </ResetButton>
                </ButtonWrapper>
              </SearchContainer>
            </MainContainer>
          </form>
        </FormProvider>
        <Box
          sx={{
            px: "20px",
            marginTop: "20px",
            borderRadius: "14px",
            boxShadow: "0 19px 46px 0 rgba(189, 213, 218, 0.57)",
            width: "100%",
            height: "100%",
          }}
        >
          {!isSearchClicked && (
            <FeedCard
              PreGetFeedFileList={PreGetFeedFileList}
              getFeedExport={getFeedExport}
            />
          )}
          {isSearchClicked && (
            <FeedCardOnButtonClick
              feedFileList={feedFileList}
              isFeedLoading={isFeedLoading}
              getFeedExport={getFeedExport}
            />
          )}
        </Box>
      </div>
    </Navbar>
  );
};

export default withRouteProtection(FeedSearch);
