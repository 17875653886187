import React, { useContext, useState, useEffect } from "react";
import withRouteProtection, {
  AuthContext,
} from "../../hoc/withRouteProtection";
import { FormProvider, useForm } from "react-hook-form";
import MasterTable from "../masters/MasterTable/MasterTable";
import {
  InputContainer,
  MainContainer,
  ResetButton,
  SearchContainer,
} from "../Claim/claimStyledComponents";
import {
  ButtonWrapper,
  Heading,
  MasterTableContainer,
} from "../masters/components/MasterStyle";
import { Box, Grid } from "@mui/material";
import Select from "../../components/InputComponents/Select";
import Buttons from "../../components/BasicFormComponent/Buttons";
import ReplayIcon from "@mui/icons-material/Replay";
import { icApprovalStatusOptions } from "../../constants/options";
import { isEmpty } from "lodash";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router";
import Navbar from "../../components/Navbar";

function IcApprovalStatusListing() {
  const [customSearch, setCustomSearch] = useState({});
  const { writable } = useContext(AuthContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const icApprovalStatusSchema = yup.object().shape({
    ic_approval_status: yup.string().required("IC approval status is required"),
  });

  const columnFilters = JSON.parse(searchParams.get("column_filters") || "[]");
  const dynamicDefaultValues = {
    ic_approval_status:
      columnFilters?.find((item) => item?.id === "ic_approval_status")?.value ||
      "",
  };
  if (columnFilters?.some((item) => item?.id === "ic_status")) {
    dynamicDefaultValues.ic_status = columnFilters?.find(
      (item) => item?.id === "ic_status"
    )?.value;
  }
  if (columnFilters?.some((item) => item.id === "refund_status")) {
    dynamicDefaultValues.refund_status = columnFilters?.find(
      (item) => item?.id === "refund_status"
    )?.value;
  }

  const methods = useForm({
    resolver: yupResolver(icApprovalStatusSchema),
    defaultValues: dynamicDefaultValues,
    shouldUnregister: false,
  });
  const onSubmit = (data) => {
    setCustomSearch({ ...data });
  };

  const handleReset = (e) => {
    setCustomSearch({});
    e.preventDefault();
    methods.reset({
      ic_approval_status: "",
    });
  };
  useEffect(() => {
    if (methods.watch("ic_approval_status")) {
      onSubmit(methods.watch());
    }
  }, []);
  return (
    <Navbar>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MainContainer style={{ width: "50%" }}>
            <Heading variant="h5" align="left">
              IC Approval Status
            </Heading>
            <SearchContainer>
              <InputContainer>
                <Grid container spacing={1} columnSpacing={3} mt={1}>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={10}>
                    <Select
                      name="ic_approval_status"
                      label="IC Approval status"
                      options={icApprovalStatusOptions}
                      variant="standard"
                      saveDisplayName={false}
                      required={true}
                    />
                  </Grid>
                </Grid>
              </InputContainer>
              <Box>
                <ButtonWrapper gap={2} alignItems="center" display={"flex"}>
                  <Buttons label={"Search"} type="submit" standard />

                  <ResetButton onClick={(e) => handleReset(e)}>
                    <ReplayIcon />
                    Reset
                  </ResetButton>
                </ButtonWrapper>
              </Box>
            </SearchContainer>
          </MainContainer>

          {!isEmpty(customSearch) &&
            customSearch?.ic_approval_status !== "" && (
              <MasterTableContainer>
                <MasterTable
                  master_name={"ic_approval_pending"}
                  writable={writable}
                  columnFilters={Object.keys(customSearch).map((item) => ({
                    id: item,
                    value: customSearch[item],
                  }))}
                />
              </MasterTableContainer>
            )}
        </form>
      </FormProvider>
    </Navbar>
  );
}

export default withRouteProtection(IcApprovalStatusListing);
