import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../api/api";

export const useGetFeedFileOne = () => {
  const { mutate, data, isSuccess, isLoading } = useMutation((formData) => {
    return api.post("/feed_file/list", formData);
  });

  return {
    getFeed: mutate,
    feedFileList: isSuccess ? data?.data : [],
    isFeedLoading: isLoading,
  };
};

export const usePreGetFeedFile = () => {
  const { data } = useQuery(["pregetfeedfile"], () => {
    return api.post("/feed_file/list");
  });
  return {
    PreGetFeedFileList: data?.data,
  };
};

export const useFeedFileExport = () => {
  const { mutate, data, isSuccess } = useMutation(
    (formExport) => api.post("/feedfile/export", formExport),
    {
      onSuccess: (data) => {
        window.open(data?.data?.return_data?.path);
      },
    }
  );

  return {
    getFeedExport: mutate,
    feedFileExportList: isSuccess ? data?.data?.return_data?.path : [],
  };
};
