const defaultProps = {
  muiTablePaperProps: {
    sx: {
      width: "100%",
      borderRadius: "20px",
    },
  },
  muiTableHeadRowProps: {
    sx: {
      background: "#dbe9ed",
      borderRadius: "20px",
    },
  },
  // muiTableContainerProps: {
  //   sx: {
  //     padding: "0 20px",
  //   },
  // },
  muiTopToolbarProps: {
    sx: {
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
    },
  },
  muiBottomToolbarProps: {
    sx: {
      borderBottomLeftRadius: "20px",
      borderBottomRightRadius: "20px",
    },
  },

  initialState: { showColumnFilters: false, density: "compact" },
  manualFiltering: true,
  manualPagination: true,
  manualSorting: true,
  enableStickyHeader: true,
  enableFullScreenToggle: true,
};
export default defaultProps;
