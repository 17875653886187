import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import {
  ButtonWrapper,
  FormContainer,
  Heading,
  Papers,
} from "../masters/components/MasterStyle";
import { FormProvider, useForm } from "react-hook-form";
import MasterTable, {
  MasterActionsButton,
} from "../masters/MasterTable/MasterTable";
import withRouteProtection, {
  AuthContext,
} from "../../hoc/withRouteProtection";
import Docupload from "../../components/InputComponents/DocUpload";
import Buttons from "../../components/BasicFormComponent/Buttons";
import {
  useBulkUploadExportFile,
  useBulkUploadFile,
} from "../../services/bulkupload.service";
import AlertMessage from "../../components/Alert/AlertMessage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { documentUploadsOnlyExcel } from "../../utils/Validations/documentUploadsValidations";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Navbar from "../../components/Navbar";

const schema = yup.object().shape({
  quote: documentUploadsOnlyExcel(),
});

const BulkUpload = () => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { uploadBulkFile, isSuccess, isError, alertMaster, resetMaster } =
    useBulkUploadFile();
  const { bulkUploadExportFile } = useBulkUploadExportFile();
  const onSubmit = (data) => {
    uploadBulkFile(data);
  };
  const { writable } = useContext(AuthContext);
  return (
    <Navbar>
      <Box>
        <FormContainer>
          <Papers elevation={3} sx={{ width: "100%" }}>
            <Heading variant="h5" align="left">
              Policy Bulk Upload
            </Heading>
            <AlertMessage
              alert={alertMaster}
              reset={resetMaster}
              isError={isError}
              isSuccess={isSuccess}
            />
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={1} columnSpacing={3} mt={1}>
                  <Grid item xxs={12} xs={12} sm={6} md={4} lg={3}>
                    <Docupload
                      label="Browse File"
                      name="quote"
                      required={true}
                    />
                  </Grid>
                </Grid>
                <Box>
                  <ButtonWrapper sx={{ marginTop: 0 }} gap={2}>
                    <Buttons label={"Submit"} type="submit" standard />
                  </ButtonWrapper>
                </Box>
              </form>
            </FormProvider>
          </Papers>
        </FormContainer>
        <Box>
          <MasterTable
            writable={writable}
            master_name={"quote"}
            customSearch={{ slug_master: "quote_details" }}
            customRowAction={({ row }) => (
              <MasterActionsButton
                onClick={() => {
                  bulkUploadExportFile({
                    transaction_id: row?.original?.transaction_id,
                    slug_master: "quotes",
                  });
                }}
              >
                <FileDownloadOutlinedIcon fontSize="small" />
                Export
              </MasterActionsButton>
            )}
          />
        </Box>
      </Box>
    </Navbar>
  );
};

export default withRouteProtection(BulkUpload);
