import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  styled,
  Tooltip,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  useDeleteItemMutation,
  useExportExcel,
  useGetDownloadService,
  useGetMaster,
  useImportExcelMutation,
} from "../../../services/master.service";
import defaultProps from "./default";
// import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Prompt from "../../../components/Prompt/Prompt";

import Clear from "@mui/icons-material/Clear";
import HistoryIcon from "@mui/icons-material/History";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import LogAccessControl from "../../../hoc/LogAccessControl";
import { AuthContext } from "../../../hoc/withRouteProtection";
import WriteAccessControl from "../../../hoc/WriteAccessControl";
import { setSelectedProposalsForPayment } from "../../../modules/payment.slice";
import { setShowMultipleProposalPayment } from "../../../modules/proposal.slice";
import { useBulkUploadExportFile } from "../../../services/bulkupload.service";
import { downloadFile } from "../../../utils/downloadFile";
import { formatPrice } from "../../../utils/formatPrice";
import { interpreter } from "../../../utils/interpreter";
import { truncateName } from "../../../utils/truncateName";
const hideActions = [
  "booking_report",
  "billing_report",
  "view_report/generation",
  "mobile_request",
  "broker_mobile_request",
];
const MasterTable = ({
  writable,
  logAccess: logAccessForCustom,
  showWithReadAccess,
  actionColumnCustomName,
  checkboxColumnCustomName,
  enableRowSelection,
  isBrokerLogin,
  ...props
}) => {
  const { bulkUploadExportFile } = useBulkUploadExportFile();
  const hideImportandSample = [
    "manufacturer",
    "role",
    "role_access",
    "motor_model",
    "claim_type",
    "claimNotification",
    "claim_detail",
    "payment_options/pis_by_ic",
    "claimupdate",
    "report",
    "booking_report",
    "billing_report",
    "policy_renewal",
    "proposal_approval",
    "claim_registration/claim",
    "claim_withdraw/claim",
    "surveyor_appointment/claim",
    "report/proposal",
    "report/endorsement",
    "view_report/generation",
    "ic_configurator",
    "claim_notification/booking_detail",
    "booking_detail",
    "ic_invoicing",
    "quote",
    "discount_approval",
    "registration",
    "apd",
    "cancellation_approved",
    "lead",
    "mmv_request",
    "oem_mmv_flag",
    "catastrophic",
    "menu",
    "surveyor_ic_mapping",
    "segment",
    "insurance",
    "oem",
    "rto",
    "product",
    "zone",
    "broker",
    "addon",
  ];

  const hideExport = [
    "report",
    "proposal_approval",
    "claim_registration/claim",
    "claim_withdraw/claim",
    "surveyor_appointment/claim",
    "claimNotification",
    "ic_configurator",
    "ic_invoicing",
    "discount_approval",
    "ifsc",
    "rm_mobile_approval",
    "customer_mandate_form",
    "policy_cancellation/booking_detail",
    "policy_cancellation_approval/booking_detail",
    "surveyor_ic_mapping",
    "http_logs",
    "menu",
    "role_access",
    "dealer_role_access",
    "dealer_role",
    "role",
    "ic_credential",
    "quote",
  ];

  const hideAdd = [
    "claimNotification",
    "claim_detail",
    "payment_options/pis_by_ic",
    "claimupdate",
    "vb",
    "view_report/generation",
    "booking_report",
    "billing_report",
    "policy_renewal",
    "proposal_approval",
    "claim_registration/claim",
    "surveyor_appointment/claim",
    "claim_details/claim",
    "claim_withdraw/claim",
    "report/proposal",
    "report/endorsement",
    "claim_notification/booking_detail",
    "booking_detail",
    "ic_invoicing",
    "quote",
    "discount_approval",
    "registration",
    "cancellation_approved",
    "lead",
    "endorsement_approval",
    "rm_mobile_approval",
    "policy_cancellation/booking_detail",
    "policy_cancellation_approval/booking_detail",
    "drafted_proposal_listing",
    "quote_listing",
    "policy_listing",
    "policy_renewal/booking_detail",
    "surveyor_ic_mapping",
    "http_logs",
    isBrokerLogin ? "insurance" : "",
    "endorsement/booking_detail",
    "pay_in_slip",
    "policy_reinstatement/booking_detail",
    "exception_log",
    "proforma_invoice/claim",
    "surveyor_assessment/claim",
    "claim_approval/claim",
    "delivery_order/claim",
    "claim_invoice/claim",
    "claim_settlement/claim",
    "customers",
  ];

  // const hideDelete = [
  //   "lso",
  //   "rm_mobile_approval",
  //   "od_rate",
  //   "manufacturer",
  //   "apd",
  //   "mmv_request",
  //   "catastrophic",
  //   "mobile_request",
  //   "surveyor_ic_mapping",
  //   "role",
  //   "role_access",
  //   "dealer_role",
  //   "dealer_role_access",
  //   "policy_listing",
  //   "drafted_proposal_listing",
  //   "report/proposal",
  //   "ic_configurator/broker_ic_onboard",
  //   isBrokerLogin ? "insurance" : "",
  // ];

  const hideEdit = [
    "apd",
    "rm_mobile_approval",
    "mobile_request",
    "surveyor_ic_mapping",
    "policy_listing",
    "drafted_proposal_listing",
    "report/proposal",
  ];

  const showDropDown = ["manufacturer", "motor_variants", "motor_model"];

  const shouldHide = (master_name) => {
    return hideImportandSample.includes(master_name);
  };
  const shouldHideAdd = (master_name) => {
    return hideAdd.includes(master_name);
  };
  // const shouldHideDelete = (master_name) => {
  //   return hideDelete.includes(master_name);
  // };
  const shouldHideEdit = (master_name) => {
    return hideEdit.includes(master_name);
  };
  const shouldHideExport = (master_name) => {
    return hideExport.includes(master_name);
  };

  const shouldShowDropDown = (master_name) => {
    return showDropDown.includes(master_name);
  };
  const shouldHideActions = (master_name) => {
    return hideActions.includes(master_name);
  };
  const { master_name } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const search = location.search;
  const params = new URLSearchParams(search);
  const slug = props.master_name || master_name;
  const [columnFilters, setColumnFilters] = useState(
    JSON.parse(params.get("column_filters")) || []
  );
  const { logAccess } = useContext(AuthContext);
  const controlLogAccess = logAccessForCustom || logAccess;
  const [columnVisibility, setColumnVisibilty] = useState([]);

  const [globalFilter, setGlobalFilter] = useState(params.get("search") || "");
  const [sorting, setSorting] = useState(
    JSON.parse(params.get("sorting")) || []
  );
  const [columnPinning] = useState(props.columnPinning || {});
  const [pagination, setPagination] = useState({
    pageIndex: params.get("page") || +0,
    pageSize: params.get("per_page") || 10,
  });
  // eslint-disable-next-line no-unused-vars
  const [selectedSegment, setSelectedSegment] = useState();
  const [tableRowsData, setTableRowsData] = useState([]);
  // const { getBulkUploadStatus } = useGetDynamicStatusLink();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedId, setSelectedId] = useState();
  const { exportFile, downloadURL } = useExportExcel({
    master_name: slug,
    columnFilters,
    globalFilter: globalFilter,
    sorting,
    customSearch: props.customSearch,
    pageIndex: null,
    pageSize: null,

    pis_search: props.pis_search,
  });
  useEffect(() => {
    downloadURL && downloadFile(downloadURL, false, true);
  }, [downloadURL]);

  const initialColumnFilters = props?.columnFilters?.reduce((prev, item) => {
    return {
      ...prev,
      [item.id]: item.value,
    };
  }, {});

  const newColumnFilters = columnFilters?.reduce((prev, item) => {
    return {
      ...prev,
      [item.id]: item.value,
    };
  }, {});

  const combinedObject = {
    ...initialColumnFilters,
    ...newColumnFilters,
  };

  const { selectedProposalsForPayment } = useSelector((state) => state.payment);

  const isMultiPaymentSelected = !isEmpty(selectedProposalsForPayment);

  const uniqueColumnFilters = Object.keys(combinedObject)?.map((key) => {
    let obj = {};
    obj.id = key;
    obj.value = combinedObject[key];
    return obj;
  });

  useEffect(() => {
    if (props.columnFilters?.length) {
      setColumnFilters((prev) => [...prev, ...(props.columnFilters || [])]);
    }
  }, [JSON.stringify(props.columnFilters)]);
  useEffect(() => {
    if (props.globalFilter) {
      setGlobalFilter(props.globalFilter);
    }
  }, [props.globalFilter]);

  const onDelete = () => {
    deleteItem({ id: selectedId, segmentId: selectedSegment });
    if (tableRowsData?.length === 1) {
      setPagination({
        pageIndex: +0,
        pageSize: 10,
      });
      setGlobalFilter("");
      return;
    }
  };

  const {
    table_list,
    isTableLoading,
    refetchTable,
    isTableError,
    isTableFetching,
    column_head,
    total_records,
    masterError,
  } = useGetMaster({
    master_name: slug,
    columnFilters: uniqueColumnFilters,
    globalFilter: globalFilter,
    sorting,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    customSearch: props.customSearch,
    pis_search: props.pis_search,
    is_column: "Y",
  });

  useEffect(() => {
    navigate(`${location.pathname}`, {
      replace: true,
    });
  }, []);

  const [rowSelection, setRowSelection] = useState({});
  const { showMultipleProposalPayment } = useSelector(
    (state) => state.proposal
  );

  useEffect(() => {
    enableRowSelection &&
      showMultipleProposalPayment &&
      dispatch(setSelectedProposalsForPayment(rowSelection));
  }, [rowSelection, enableRowSelection, showMultipleProposalPayment, dispatch]);

  useEffect(() => {
    setRowSelection({});
    dispatch(setSelectedProposalsForPayment({}));
  }, [table_list[0]?.insurance_name]);

  const [columnOrder, setColumnOrder] = useState([]);
  const { uploadTable, isUploading, importError, importData, importReset } =
    useImportExcelMutation(slug);
  const { deleteItem, deleteSuccess, deleteReset, deleteFail, deleteError } =
    useDeleteItemMutation(slug);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (deleteSuccess || deleteFail) {
      queryClient.invalidateQueries([slug]);
      setTimeout(() => {
        deleteReset();
      }, 3000);
    }
    if (importError || importData) {
      queryClient.invalidateQueries([slug]);
      setTimeout(() => {
        importReset();
      }, 3000);
    }
  }, [deleteSuccess, deleteFail, importError, importData]);

  useEffect(() => {
    const column_head_order = [
      ...(column_head?.map((item) => item.accessorKey) || []),
    ];
    if (slug === "menu") {
      column_head_order.unshift("mrt-row-expand");
    }
    if ((writable || controlLogAccess) && !shouldHideActions(slug)) {
      column_head_order.unshift("mrt-row-actions");
    }
    if (enableRowSelection && showMultipleProposalPayment) {
      column_head_order.unshift("mrt-row-select");
    }
    setColumnOrder([...column_head_order]);
  }, [
    slug,
    JSON.stringify(column_head),
    writable,
    controlLogAccess,
    enableRowSelection,
    showMultipleProposalPayment,
  ]);

  useEffect(() => {
    return () => {
      setGlobalFilter("");
    };
  }, [slug]);

  useEffect(() => {
    return () => {
      setSorting([]);
      setPagination({
        pageIndex: +0,
        pageSize: 10,
      });
      // setGlobalFilter("");
      setColumnFilters([]);
    };
  }, [
    slug,
    globalFilter,
    JSON.stringify(props.customSearch || {}),
    JSON.stringify(props.columnFilters),
  ]);

  useEffect(() => {
    return () => {
      setPagination({
        pageIndex: +0,
        pageSize: 10,
      });
      setGlobalFilter("");
    };
  }, []);

  useEffect(() => {
    setTableRowsData(table_list);
  }, [table_list]);

  const { path } = useGetDownloadService({
    master_name: slug,
    enabled: !shouldHide(slug),
  });

  const downloadSample = () => {
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = path;
    link.click();
  };

  const updated_colum_head = useMemo(() => {
    return column_head?.map((i) => {
      let modifiedObj = {};
      modifiedObj = {
        Cell: ({ cell, row }) => {
          if (cell.getValue() && typeof cell.getValue() === "string") {
            if (cell.getValue().startsWith("f(x):")) {
              return (
                <span>
                  {interpreter(
                    cell.getValue().replace("f(x):", "").trim(),
                    row.original
                  )}
                </span>
              );
            } else {
              return <span>{cell.getValue()}</span>;
            }
          }
        },
      };

      if (i.type === "img") {
        modifiedObj = {
          Cell: ({ cell }) => {
            return <img src={cell.getValue()} style={{ width: "120px" }}></img>;
          },
        };
      }
      if (i.type === "href") {
        modifiedObj = {
          Cell: ({ cell }) => {
            return (
              <a
                href={cell.getValue()}
                style={{
                  color: "#03cc83",
                  border: "1px solid #03cc83",
                  borderRadius: "8px",
                  padding: "4px",
                }}
              >
                Click here
              </a>
            );
          },
        };
      }
      if (i.type === "price") {
        modifiedObj = {
          Cell: ({ cell }) => {
            return <span>{formatPrice(cell.getValue())} </span>;
          },
        };
      }
      if (i.type === "date") {
        modifiedObj = {
          Cell: ({ cell }) => {
            return (
              cell.getValue() && (
                <span>
                  {moment(cell.getValue(), "YYYY-MM-DD").format(
                    "DD-MMM-YYYY"
                  ) ||
                    moment(cell.getValue(), "YYYY-MM-DD hh:mm:ss").format(
                      "DD-MMM-YYYY"
                    )}
                </span>
              )
            );
          },
        };
      }
      if (i.type === "datetime") {
        modifiedObj = {
          Cell: ({ cell }) => {
            // To separate dates in this format --> 2023-06-13 09:52:27 To 2024-06-12 23:59:59 <--  & to handle one date value as well
            const separatedDateTime = cell?.getValue()?.split(" To ");
            return (
              <span>
                {separatedDateTime?.length > 1
                  ? `${moment(separatedDateTime?.[0]).format(
                      "DD-MMM-YYYY hh:mm:ss"
                    )} To ${moment(separatedDateTime?.[1]).format(
                      "DD-MMM-YYYY hh:mm:ss"
                    )}`
                  : moment(separatedDateTime?.toString()).format(
                      "DD-MMM-YYYY hh:mm:ss"
                    )}
              </span>
            );
          },
        };
      }
      if (i.header.toLowerCase() == "status") {
        modifiedObj = {
          Cell: ({ cell }) => {
            return cell.getValue();
            // sx={{
            //   color:
            //     cell.getValue() === "Active" ||
            //     cell.getValue() === "Verified"
            //       ? "#03cc83"
            //       : "#ff5e5e",
            //   bgcolor: cell.getValue() === "Active" ? "#e8fff7" : "#ffecec",
            // }}
          },
        };
      }
      if (i.type === "remarks") {
        modifiedObj = {
          Cell: ({ cell }) => {
            return truncateName(cell.getValue(), 50);
          },
        };
      }
      if (i.type === "dynamic_status") {
        modifiedObj = {
          Cell: ({
            row,
            // cell
          }) => {
            return (
              <a
                style={{
                  color: "#03cc83",
                  //   color: "blue",
                  textDecoration: "underline",
                  //   border: "1px solid #03cc83",
                  borderRadius: "8px",
                  padding: "4px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  bulkUploadExportFile({
                    transaction_id: row?.original?.transaction_id,
                    slug_master: "quotes",
                  })
                }
              >
                {row?.original?.dynamic_status}
              </a>
            );
          },
        };
      }
      if (props.cellModifier)
        modifiedObj = {
          ...modifiedObj,
          ...props?.cellModifier(i),
        };

      return {
        ...i,
        ...modifiedObj,
      };
    });
  }, [column_head, isMultiPaymentSelected]);

  // to removed clone & payment-redirection button columns in proposal list if multi-payment checkbox is seleted
  const filteredColumnsWhenMultiplePaymentSelected = updated_colum_head?.filter(
    (item) => {
      if (
        isMultiPaymentSelected &&
        ["clone_proposal", "proposal_url"].includes(item?.accessorKey)
      )
        return;
      return item;
    }
  );

  const updated_table_list = props.updatedTable
    ? props.updatedTable(table_list)
    : table_list;
  const { table_list: segment_items } = useGetMaster({
    master_name: "segment",
  });
  const segment_list = useMemo(() => {
    return segment_items.map((item) => {
      return {
        code: item.segment_id,
        display_name: item.segment_name,
      };
    });
  }, [segment_items]);

  useEffect(() => {
    if (!updated_table_list?.length) {
      dispatch(setShowMultipleProposalPayment(false));
      return;
    }
    const sameIC = updated_table_list?.every(
      (obj) => obj?.insurance_name === updated_table_list[0]?.insurance_name
    );
    dispatch(
      setShowMultipleProposalPayment(
        sameIC && updated_table_list?.length > 1 && enableRowSelection
      )
    );
  }, [updated_table_list, dispatch]);
  const showHistory = location?.pathname?.split("/")[1] === "master"; // temporary, may change later

  return (
    <>
      <Box key={slug} width={"100%"} px={2} py={2}>
        <MaterialReactTable
          {...defaultProps}
          enableRowActions={true}
          // {...props}
          muiTableHeadProps={{
            sx: {
              transform: "translateZ(0)",
              ":-webkit-transform": "translateZ(0)",
            },
          }}
          muiSearchTextFieldProps={{
            variant: "outlined",
            size: "small",
            value: globalFilter,
            onChange: (e) => {
              setGlobalFilter(e.target.value);
            },
            InputProps: {
              endAdornment: (
                <>
                  <Clear
                    sx={{
                      cursor: "pointer",
                      color: "rgba(0,0,0,0.5)",
                    }}
                    onClick={() => {
                      setGlobalFilter("");
                    }}
                  />
                </>
              ),
            },
          }}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: actionColumnCustomName || "Actions", //To change header text of action column
            },
            // "mrt-row-select": {
            //   header: checkboxColumnCustomName || "Select", //To change header text of checkbox column
            // },
          }}
          columns={
            isMultiPaymentSelected
              ? filteredColumnsWhenMultiplePaymentSelected
              : updated_colum_head
          }
          enableRowSelection={(row) =>
            enableRowSelection &&
            showMultipleProposalPayment &&
            (row.original.proposal_stage === "Policy Submitted" ||
              row.original.proposal_stage === "Payment Failed")
              ? true
              : false
          }
          enableSelectAll={enableRowSelection && showMultipleProposalPayment}
          selectAllMode="page"
          getRowId={(row) => props?.getAllDataAsKey && JSON.stringify(row)}
          onRowSelectionChange={setRowSelection}
          positionToolbarAlertBanner={enableRowSelection && "none"}
          enableExpanding
          enablePagination={updated_table_list?.length > 0}
          positionActionsColumn={"first"}
          data={updated_table_list || []}
          padding="none" //data is undefined on first render
          muiToolbarAlertBannerProps={
            isTableError && masterError?.response?.data?.message !== ""
              ? {
                  color: "error",
                  children: masterError?.response?.data?.message,
                }
              : deleteSuccess
              ? {
                  color: "success",
                  children: "Record deleted successfully",
                }
              : deleteFail
              ? {
                  color: "error",
                  children: deleteError?.message,
                }
              : importData
              ? {
                  color: "success",
                  children: importData,
                }
              : importError?.length > 0
              ? {
                  color: "error",
                  children: (Array.isArray(importError)
                    ? importError
                    : [importError]
                  )?.map((err) => err),
                }
              : {
                  color: "error",
                  children: "Something went wrong",
                }
          }
          muiTableContainerProps={{
            sx: {
              padding: props?.columnPinning ? "0 0 0 20px" : "0 20px",
            },
          }}
          muiTableBodyCellProps={{
            // to maintain same height after hiding buttons
            sx: {
              height: "45px",
            },
          }}
          enableGlobalFilter
          onColumnVisibilityChange={setColumnVisibilty}
          getSubRows={(row) => row.child}
          onColumnFiltersChange={(val) => {
            setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
            setColumnFilters(val);
          }}
          onGlobalFilterChange={setGlobalFilter}
          onColumnOrderChange={setColumnOrder}
          enableColumnDragging
          enableColumnOrdering
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderTopToolbarCustomActions={() => (
            <>
              <Box display={"flex"} gap={"12px"} alignItems="center">
                <Tooltip arrow title="Refresh Data">
                  <IconButton id="refresh_icon" onClick={() => refetchTable()}>
                    <RefreshIcon id="refresh_button" />
                  </IconButton>
                </Tooltip>
                {!shouldHideAdd(slug) && (
                  <WriteAccessControl showWithReadAccess={showWithReadAccess}>
                    <MasterActionsButton
                      id="add_button"
                      onClick={() => {
                        navigate("./add");
                      }}
                    >
                      <AddBoxIcon fontSize="small" />
                      Add
                    </MasterActionsButton>
                  </WriteAccessControl>
                )}
                <input
                  hidden
                  id="import-file"
                  multiple
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e) => {
                    const data = e.target.files[0];
                    uploadTable(data);
                  }}
                />
                {((!shouldHide(slug) && path) || slug === "vb") && (
                  <WriteAccessControl>
                    <MasterActionsButton
                      id="import_button"
                      component="label"
                      htmlFor="import-file"
                    >
                      {isUploading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          {" "}
                          <FileUploadOutlinedIcon fontSize="small" /> Import
                        </>
                      )}
                    </MasterActionsButton>
                  </WriteAccessControl>
                )}
                {!shouldHideExport(slug) && (
                  <MasterActionsButton
                    id="export_button"
                    onClick={() => exportFile()}
                  >
                    <FileDownloadOutlinedIcon fontSize="small" />
                    Export
                  </MasterActionsButton>
                )}

                {shouldShowDropDown(slug) && (
                  <MasterActionsButton>
                    <label htmlFor="">Segment:</label>
                    <select
                      name="segment_id"
                      id="segment_id"
                      onChange={(e) => {
                        const newFilters = columnFilters.filter(
                          (x) => x?.id !== "segment_id"
                        );
                        setColumnFilters([
                          ...newFilters,
                          {
                            id: "segment_id",
                            value: e.target.value,
                          },
                        ]);
                      }}
                    >
                      <option value="all">All</option>
                      {segment_list?.map((segment, index) => (
                        <option value={segment?.code} key={index}>
                          {segment?.display_name}
                        </option>
                      ))}
                    </select>
                  </MasterActionsButton>
                )}

                {!shouldHide(slug) && path && (
                  <WriteAccessControl>
                    <MasterActionsButton
                      id="download_sample"
                      disabled={path === undefined}
                      onClick={() => downloadSample()}
                    >
                      <FileDownloadOutlinedIcon fontSize="small" />
                      Download Sample
                    </MasterActionsButton>
                  </WriteAccessControl>
                )}

                {((!shouldHide(slug) && path) || slug === "vb") && (
                  <WriteAccessControl>
                    <MasterActionsButton
                      id="import_history"
                      onClick={() => navigate(`./import-history`)}
                    >
                      <HistoryIcon fontSize="small" />
                      Import History
                    </MasterActionsButton>
                  </WriteAccessControl>
                )}
              </Box>
            </>
          )}
          enableEditing
          editingMode={props.editingMode}
          renderRowActions={
            props.customRowAction
              ? props.customRowAction
              : ({ row }) => (
                  <Box sx={{ display: "flex", gap: "0", padding: 0 }}>
                    {!shouldHideEdit(slug) && (
                      <WriteAccessControl>
                        <Tooltip arrow placement="left" title="Edit">
                          <IconButton
                            color="#475f63"
                            onClick={() => {
                              navigate(
                                `./update/${
                                  row?.original[
                                    `${
                                      slug === "report"
                                        ? "report_configurator"
                                        : slug
                                    }_id`
                                  ]
                                }${
                                  slug === "manufacturer" ||
                                  slug === "motor_model" ||
                                  slug === "motor_variants"
                                    ? `?segmentId=${row.original.segment_slug}`
                                    : ""
                                }      `
                              );
                            }}
                          >
                            <EditOutlinedIcon id="edit_button" />
                          </IconButton>
                        </Tooltip>
                      </WriteAccessControl>
                    )}
                    {/* {!shouldHideDelete(slug) && (
                      <WriteAccessControl>
                        <Tooltip arrow placement="top" title="Delete">
                          <IconButton
                            color="#475f63"
                            onClick={() => {
                              setShowDeletePrompt(true);
                              setSelectedId(
                                row.original[
                                  `${
                                    slug === "report"
                                      ? "report_configurator"
                                      : slug
                                  }_id`
                                ]
                              );
                              slug === "manufacturer" ||
                              slug === "segment" ||
                              slug === "motor_model" ||
                              slug === "motor_variants"
                                ? setSelectedSegment(
                                    row.original["segment_slug"]
                                  )
                                : "";
                            }}
                          >
                            <DeleteOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </WriteAccessControl>
                    )} */}
                    {showHistory && (
                      <LogAccessControl>
                        <Tooltip arrow placement="right" title="History">
                          <IconButton
                            color="#475f63"
                            onClick={() => {
                              navigate(
                                `./history/${
                                  row?.original[
                                    `${
                                      slug === "report"
                                        ? "report_configurator"
                                        : slug
                                    }_id`
                                  ]
                                }${
                                  slug === "manufacturer" ||
                                  slug === "motor_model" ||
                                  slug === "motor_variants"
                                    ? `?segmentId=${row.original.segment_slug}`
                                    : ""
                                }      `
                              );
                            }}
                          >
                            <HistoryIcon />
                          </IconButton>
                        </Tooltip>
                      </LogAccessControl>
                    )}
                  </Box>
                )
          }
          rowCount={total_records || 10}
          state={{
            columnFilters,
            globalFilter,
            isTableLoading,
            pagination,
            showAlertBanner:
              isTableError ||
              deleteSuccess ||
              deleteFail ||
              importError ||
              importData,
            showProgressBars: isTableFetching,
            sorting,
            columnOrder,
            columnPinning,
            rowSelection,
            columnVisibility: {
              "mrt-row-actions":
                (!writable && !controlLogAccess) || shouldHideActions(slug)
                  ? false
                  : true,
              "mrt-row-expand": slug === "menu" ? true : false,
              "mrt-row-select":
                enableRowSelection && showMultipleProposalPayment
                  ? true
                  : false,
              ...columnVisibility,
            },
          }}
        />
      </Box>
      <Prompt
        open={showDeletePrompt}
        title="Do you want to delete this?"
        onAgree={onDelete}
        onCancel={() => {
          setShowDeletePrompt(false);
        }}
      />
    </>
  );
};
export default MasterTable;

export const MasterActionsButton = styled(Button)({
  color: "#475f63",
  border: "solid 1px #bdd5da",
  display: "flex",
  gap: "4px",
  textTransform: "unset",
  fontSize: "14px",
  padding: "0px 8px !important",
  lineHeight: "16px",
  height: "30px",
});

MasterActionsButton.defaultProps = {
  variant: "outlined",
  color: "secondary",
  size: "small",
};
