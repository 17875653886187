import { styled } from "@mui/material/styles";

export const MenuAccordianWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  //   minHeight: "100%",
  maxHeight: "90vh",
  overflowY: "scroll",
  alignItems: "flexStart",
  position: "absolute",
  padding: "5px",
  backgroundColor: "#f4f9fd",
  zIndex: "5",
  border: "solid 1px #e6f2f2",
  [theme.breakpoints.up("560")]: {
    display: "none",
  },
}));

export const AccordionChildWrapper = styled("div")(({ theme, hasParent }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",

  marginLeft: hasParent && "-5px",
  marginTop: hasParent && "-4px",
  marginBottom: hasParent && "15px",

  backgroundColor: !hasParent && "white",
  paddingLeft: !hasParent && "43px",
  paddingBottom: !hasParent && "7px",
  paddingTop: !hasParent && "7px",
  borderTop: !hasParent && `1px solid ${theme.palette.divider}`,
  borderLeft: !hasParent && `1px solid ${theme.palette.divider}`,
  borderRight: !hasParent && `1px solid ${theme.palette.divider}`,
}));

export const AccordionChildWrapperNoChild = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  backgroundColor: "white",
  paddingLeft: "43px",
  paddingBottom: "7px",
  paddingTop: "7px",
  borderTop: `1px solid ${theme.palette.divider}`,
  borderLeft: `1px solid ${theme.palette.divider}`,
  borderRight: `1px solid ${theme.palette.divider}`,
}));

export const AccordionChild = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "5px",
  cursor: "pointer",
}));
