import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../api/api";

export const useGetCityFromState = (state_id) => {
  const { data } = useQuery(
    ["city", state_id],
    () => {
      return api.post(`pincode/detail`, {
        state_id,
        sorting: {
          id: "city_name",
          value: "asc",
        },
      });
    },
    { enabled: Boolean(state_id) }
  );
  return { table_list: data?.data?.return_data || [] };
};
export const useGetStateFromZone = (zone_id) => {
  const { data } = useQuery(
    ["state", zone_id],
    () => {
      return api.post(`pincode/detail`, {
        zone_id,
        sorting: [
          {
            id: "state_name",
            value: "asc",
          },
        ],
      });
    },
    { enabled: Boolean(zone_id) }
  );
  return { table_list: data?.data?.return_data || [] };
};
export const useGetStateCityFromPincode = () => {
  const { data, mutate, reset, isSuccess } = useMutation((pincode) => {
    if (pincode) {
      return api.post(`pincode/detail`, {
        pincode,
      });
    }
  });

  return {
    getCityState: mutate,
    zone_list: data?.data?.return_data?.state_id
      ? [
          {
            code: data?.data?.return_data?.state?.zone?.zone_id,
            display_name: data?.data?.return_data?.state?.zone?.zone_name,
          },
        ]
      : [],
    state_list: data?.data?.return_data?.state_id
      ? [
          {
            code: data?.data?.return_data?.state?.state_id,
            display_name: data?.data?.return_data?.state?.state_name,
          },
        ]
      : [],
    city_list:
      data?.data?.return_data?.city?.map((item) => ({
        code: item.city_id,
        display_name: item.city_name,
      })) || [],
    resetStateCity: reset,
    isStateCitySuccess: isSuccess,
  };
};

export const useGetStateName = () => {
  const { data } = useQuery(["state"], () => {
    return api.post(`state/list`, {
      sorting: {
        id: "state_name",
        value: "asc",
      },
    });
  });
  return { table_list: data?.data?.return_data || [] };
};
