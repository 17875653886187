import React from "react";
import carloader2 from "../assets/images/carloader2.gif";
import { Box } from "@mui/material";

const Loader = () => {
  return (
    <Box>
      <Box className="middle relative">
        <img src={carloader2} alt="loader" />
        <p
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "darkgray",

            position: "absolute",
            top: "50%",
            left: "20px",
          }}
        >
          Loading..
        </p>
      </Box>
    </Box>
  );
};

export default Loader;
