import { Grid } from "@mui/material";
import React from "react";
import { allowOnlyNumbers } from "../../../../../../../InputUtils/onInput";
import Select from "../../../../../../../components/InputComponents/Select";
import TextInput from "../../../../../../../components/InputComponents/TextInput";
import { lossType } from "../../../../../../../constants/options";
import SummaryDocupload from "../../../../../../summary/SummaryDocupload";

function SurveyorAssesmentFormFields() {
  return (
    <Grid container spacing={2} mt={0} px={2}>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="IDV"
          name="assessed_idv"
          required
          standard
          readOnly
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Vehicle Premium"
          name="assessed_vehicle_premium"
          standard
          required
          readOnly
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Voluntary Deductible"
          name="assessed_voluntary_deductible"
          required
          standard
          readOnly
          inputProps={{ maxLength: 10 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <Select
          label="Loss Type"
          name="assessed_loss_type"
          options={lossType}
          required
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Labour Cost"
          name="assessed_labour_cost"
          required
          standard
          inputProps={{ maxLength: 8 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Parts Cost"
          name="assessed_parts_cost"
          required
          standard
          inputProps={{ maxLength: 8 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Metal Cost"
          name="assessed_metal_cost"
          required
          standard
          inputProps={{ maxLength: 8 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Paint Cost"
          name="assessed_paint_cost"
          required
          standard
          inputProps={{ maxLength: 8 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Total Other Cost"
          name="assessed_total_other_cost"
          required
          standard
          inputProps={{ maxLength: 10 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <TextInput
          label="Total Estimation"
          otherHelperText="(Sum of labour cost, parts cost, metal cost, paint cost and total other cost)"
          name="assessed_total_estimation"
          required
          standard
          readOnly
          inputProps={{ maxLength: 10 }}
          onInput={allowOnlyNumbers}
        />
      </Grid>
      <Grid item lg={4} xxs={6}>
        <SummaryDocupload
          label="Surveyor Assessment Report"
          name="assessed_document"
          required
        />
      </Grid>
    </Grid>
  );
}

export default SurveyorAssesmentFormFields;
