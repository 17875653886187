import React, { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  InputContainer,
  MainContainer,
  ResetButton,
  SearchContainer,
} from "../../../servicing/cancellation/CancellationSearch/CancellationSearchStyle";
import {
  ButtonWrapper,
  GridChild,
  Heading,
  StyledGrid,
} from "../../components/MasterStyle";
import Buttons from "../../../../components/BasicFormComponent/Buttons";
import { allowOnlyNumbers } from "../../../../InputUtils/onInput";
import Textfield from "../../../../components/BasicFormComponent/TextFieldInput";
import MasterTable from "../../MasterTable/MasterTable";
import withRouteProtection, {
  AuthContext,
} from "../../../../hoc/withRouteProtection";
import { mobileNumberRequired } from "../../../../utils/Validations/mobileNumberValidations";
import { useLocation, useNavigate } from "react-router";
import Navbar from "../../../../components/Navbar";
const schema = yup.object().shape({
  mobile: mobileNumberRequired(),
});

const MobileNoApproval = () => {
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const [customSearch, setCustomSearch] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const onSubmit = (data) => {
    setCustomSearch({ ...data });
  };

  const handleReset = () => {
    if (searchParams.get("customSearch")) {
      searchParams.delete("customSearch");
      // Creating new search string
      const newSearch = searchParams.toString();
      // Using navigate to update the URL
      navigate(`${location.pathname}?${newSearch}`);
    }
    setCustomSearch({});
    methods.reset({
      mobile: "",
    });
  };
  const { writable } = useContext(AuthContext);
  return (
    <Navbar>
      <>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <MainContainer>
              <Heading variant="h5" align="left">
                Mobile Number Request
              </Heading>
              <SearchContainer>
                <InputContainer>
                  <StyledGrid columns={12}>
                    <GridChild sm={3} md={3} xs={12} xxs={12}>
                      <Textfield
                        label={"Mobile No."}
                        name={"mobile"}
                        onInput={allowOnlyNumbers}
                        inputProps={{ maxLength: 10 }}
                        required={true}
                        standard
                      />
                    </GridChild>
                  </StyledGrid>
                </InputContainer>
                <ButtonWrapper gap={2}>
                  <Buttons label={"Search"} type="submit" standard />

                  <ResetButton onClick={handleReset}>
                    <ReplayIcon />
                    Reset
                  </ResetButton>
                </ButtonWrapper>
              </SearchContainer>
            </MainContainer>
          </form>
        </FormProvider>
        <Box>
          <MasterTable
            writable={writable}
            master_name={"mobile_request"}
            customSearch={customSearch}
          />
        </Box>
      </>
    </Navbar>
  );
};

export default withRouteProtection(MobileNoApproval);
