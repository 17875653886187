/*

This file includes  base validations for PAN no. 
Utilize these & add custom validations afterwards in respective files

*/

import * as yup from "yup";
import { REGEX } from "../../constants/regex";

export const panNumberBasic = () =>
  yup.string().nullable().matches(REGEX.PAN, "Enter valid PAN No.");

export const panNumberRequired = () =>
  yup
    .string()
    .nullable()
    .required("PAN no. is required")
    .matches(REGEX.PAN, "Enter valid PAN No.");

export const panCardNumberRequired = () =>
  yup
    .string()
    .nullable()
    .required("PAN card no. is required")
    .matches(REGEX.PAN, "Enter valid PAN No.");
