import { FormProvider, useForm } from "react-hook-form";
import {
  ClaimActionButton,
  InputContainer,
  MainContainer,
  ResetButton,
  SearchContainer,
} from "../../Claim/claimStyledComponents";
import {
  ApprovalStatus,
  ButtonWrapper,
  GridChild,
  Heading,
  MasterTableContainer,
  StyledGrid,
} from "../../masters/components/MasterStyle";
import ReplayIcon from "@mui/icons-material/Replay";
import MasterTable from "../../masters/MasterTable/MasterTable";
import Buttons from "../../../components/BasicFormComponent/Buttons";
import SelectAutoComplete from "../../../components/BasicFormComponent/SelectAutoComplete";
import withRouteProtection, {
  AuthContext,
} from "../../../hoc/withRouteProtection";
import { useMemo, useState, useContext } from "react";
import { useGetSortedMaster } from "../../../services/master.service";
import { useSendDiscountApprovalStatus } from "../../../services/apd.service";
import AlertMessage from "../../../components/Alert/AlertMessage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router";
import { Box, IconButton, Tooltip } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import Navbar from "../../../components/Navbar";

function DiscountApproval() {
  let approval_status = "";
  const [searchParam, setSearchParam] = useState({});
  const { writable, logAccess } = useContext(AuthContext);
  const { table_list: dealer_list } = useGetSortedMaster({
    master_name: "dealer",
    sortBy: "dealer_name",
  });
  const {
    sendDiscountApprovalStatus,
    isSuccess,
    alertMaster,
    resetMaster,
    isError,
  } = useSendDiscountApprovalStatus();

  const dealer_name_list = useMemo(() => {
    return dealer_list.map((item) => {
      return {
        code: item.dealer_id,
        display_name: item.dealer_name,
      };
    });
  }, [dealer_list]);

  const { table_list: segment_id } = useGetSortedMaster({
    master_name: "segment",
    sortBy: "segment_name",
  });

  const segment_list = useMemo(() => {
    return segment_id.map((item) => {
      return {
        code: item.segment_id,
        display_name: item.segment_name,
      };
    });
  }, [segment_id]);

  const discountApprovalSchema = yup.object().shape({
    dealer_id: yup.string().required("Dealer name is required"),
  });

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const methods = useForm({
    resolver: yupResolver(discountApprovalSchema),
    defaultValues: {
      dealer_id: "",
      segment_id: "",
    },
  });

  const handleReset = () => {
    if (searchParams.get("column_filters")) {
      searchParams.delete("column_filters");
      // Creating new search string
      const newSearch = searchParams.toString();
      // Using navigate to update the URL
      navigate(`${location.pathname}?${newSearch}`);
      setSearchParam({});
    }
    methods.reset({
      dealer_id: "",
      segment_id: "",
    });
  };

  const onSubmit = (data) => {
    setSearchParam(data);
  };

  const onUpdate = (id) => {
    sendDiscountApprovalStatus({
      discount_id: id,
      approval_status,
    });
  };

  return (
    <Navbar>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MainContainer style={{ width: "70%" }}>
            <Heading variant="h5" align="left">
              Discount Approval
            </Heading>
            <AlertMessage
              alert={alertMaster}
              reset={resetMaster}
              isError={isError}
              isSuccess={isSuccess}
            />
            <SearchContainer>
              <InputContainer>
                <StyledGrid spacing={0} columns={6} columnSpacing={4}>
                  <GridChild>
                    <SelectAutoComplete
                      name={"dealer_id"}
                      label={"Dealer Name"}
                      variant={"standard"}
                      options={dealer_name_list}
                      saveDisplayName={false}
                      required
                    />
                  </GridChild>

                  <GridChild>
                    <SelectAutoComplete
                      name={"segment_id"}
                      label={"Segment Name"}
                      variant={"standard"}
                      options={segment_list}
                      saveDisplayName={false}
                    />
                  </GridChild>
                </StyledGrid>
              </InputContainer>
              <ButtonWrapper gap={2} alignItems="center" display={"flex"}>
                <Buttons label={"Search"} type="submit" standard />

                <ResetButton onClick={handleReset}>
                  <ReplayIcon />
                  Reset
                </ResetButton>
              </ButtonWrapper>
            </SearchContainer>
          </MainContainer>

          <MasterTableContainer>
            <MasterTable
              writable={writable}
              logAccess={logAccess}
              master_name={"discount_approval"}
              columnFilters={Object.keys(searchParam).map((item) => ({
                id: item,
                value: searchParam[item],
              }))}
              actionColumnCustomName={"Approval"}
              customRowAction={({ row }) =>
                ["Approved", "Rejected"].includes(
                  row?.original?.approval_status
                ) ? (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <ApprovalStatus>
                      {row?.original?.approval_status}
                    </ApprovalStatus>
                    {logAccess && (
                      <Tooltip arrow placement="right" title="History">
                        <IconButton
                          color="#475f63"
                          onClick={() => {
                            navigate(
                              `./history/${row?.original?.ic_request_discount_id}`
                            );
                          }}
                        >
                          <HistoryIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {writable && (
                      <>
                        <select
                          id="discount_approval"
                          // Temporary fix. Will be changed
                          onChange={(e) => (approval_status = e.target.value)}
                          style={{
                            padding: "3px 0",
                            outline: "none",
                            borderRadius: "3px",
                          }}
                        >
                          <option value="" selected hidden>
                            Select
                          </option>
                          <option value="Approved">Approved</option>
                          <option value="Rejected">Rejected</option>
                        </select>
                        <ClaimActionButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            //TODO will confirm with gurminder
                            onUpdate(row?.original?.ic_request_discount_id);
                          }}
                        >
                          submit
                        </ClaimActionButton>
                      </>
                    )}
                    {logAccess && (
                      <Tooltip arrow placement="right" title="History">
                        <IconButton
                          color="#475f63"
                          onClick={() => {
                            navigate(
                              `./history/${row?.original?.ic_request_discount_id}`
                            );
                          }}
                        >
                          <HistoryIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                )
              }
            />
          </MasterTableContainer>
        </form>
      </FormProvider>
    </Navbar>
  );
}

export default withRouteProtection(DiscountApproval);
