import * as yup from "yup";

export const icBrokerMappingValidationSchema = yup.object().shape({
  mapping_by: yup.string().required("Mapping by is required"),
  ic_id: yup
    .string()
    .test("ic_id", "Insurance company is required", function (value) {
      const { mapping_by } = this.parent;
      if (mapping_by === "ic") return value;
      else return true;
    }),
  broker_id: yup.string().test("ic_id", "Broker is required", function (value) {
    const { mapping_by } = this.parent;
    if (mapping_by === "broker") return value;
    else return true;
  }),
  oem_id: yup.string().test("ic_id", "OEM is required", function (value) {
    const { mapping_by } = this.parent;
    if (mapping_by === "oem") return value;
    else return true;
  }),
  mapping_type: yup.string().required("Mapping type is required"),
});
