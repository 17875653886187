import { FormProvider, useForm } from "react-hook-form";
import withRouteProtection from "../../../hoc/withRouteProtection";
import { MainContainer } from "../../Report/ReportsStyled";
import { ButtonWrapper, Heading } from "../../masters/components/MasterStyle";
import { Box, Button, Grid, Radio } from "@mui/material";
import Buttons from "../../../components/BasicFormComponent/Buttons";
import TextInput from "../../../components/InputComponents/TextInput";
import { useState } from "react";
import MasterTable from "../../masters/MasterTable/MasterTable";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Navbar from "../../../components/Navbar";
const ICSurveyorMapping = () => {
  const methodsSearch = useForm({
    resolver: yupResolver(
      yup.object().shape({
        surveyor_details: yup.string().required("Surveyor details is required"),
      })
    ),
    defaultValues: {
      surveyor_details: "",
      status: "Y",
    },
  });
  const [surveyorDetailToSearch, setSurveyorDetailToSearch] = useState("");
  const [selectedSurveyor, setSelectedSurveyor] = useState(null);
  const onSearchSubmit = (data) => {
    setSurveyorDetailToSearch(data.surveyor_details);
  };

  const navigate = useNavigate();
  return (
    <>
      <Navbar>
        <FormProvider {...methodsSearch}>
          <form onSubmit={methodsSearch.handleSubmit(onSearchSubmit)}>
            <MainContainer style={{ margin: "30px 30px 0 30px" }}>
              <Heading variant="h5" align="left" mb={2}>
                IC-Surveyor Mapping
              </Heading>

              <Grid container columnSpacing={4} rowSpacing={2}>
                <Grid item xxs={12} xs={6} md={3} lg={4}>
                  <TextInput
                    name="surveyor_details"
                    label={"Surveyor Details"}
                    placeholder="Name/Phone/License"
                    required={true}
                  />
                </Grid>
              </Grid>
              <ButtonWrapper style={{ gap: "15px" }}>
                <Buttons label={"Search"} type="submit" standard />
              </ButtonWrapper>
            </MainContainer>
          </form>
        </FormProvider>
        {surveyorDetailToSearch && (
          <MasterTable
            master_name="surveyor_ic_mapping"
            writable={true}
            actionColumnCustomName={"Select"}
            globalFilter={surveyorDetailToSearch}
            columnFilters={[{ id: "status", value: "Y" }]}
            customRowAction={({ row }) => {
              return (
                <>
                  <Radio
                    onClick={() => {
                      if (row.original.surveyor_id === selectedSurveyor) {
                        setSelectedSurveyor(null);
                        return;
                      }
                      setSelectedSurveyor(row.original.surveyor_id);
                    }}
                    checked={row.original.surveyor_id === selectedSurveyor}
                  />
                </>
              );
            }}
          />
        )}
        {selectedSurveyor && (
          <Box width={"100%"} mb={2} textAlign={"center"}>
            <Button
              onClick={() => {
                navigate(`./${selectedSurveyor}`);
              }}
              variant="contained"
            >
              Proceed with selected surveyor
            </Button>
          </Box>
        )}
      </Navbar>
    </>
  );
};

export default withRouteProtection(ICSurveyorMapping);
