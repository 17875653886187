import React, { useEffect } from "react";
import SurveyorAssesmentFormFields from "./SurveyorAssessmentFormFields";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ClaimHeading,
  MainPaperContainer,
} from "../../../../../styles/claim.styles";
import { ButtonWrapper } from "../../../../../../masters/components/MasterStyle";
import { SubmitButton } from "../../../../../../../components/InputComponents/InputComponents.style";
import AlertMessage from "../../../../../../../components/Alert/AlertMessage";
import { useParams } from "react-router";
import { useSendSurveyorAssesmentDetails } from "../../../../../../../services/claims.service";
import { surveyorAssessmentSchema } from "./validations/surveyorAssessmentFormValidations";

function SurveyorAssesmentForm({ claimDetails }) {
  const { claim_detail_id } = useParams("claim_detail_id");

  const {
    sendSurveyorAssessmentDetails,
    isSuccess,
    alertMaster,
    resetMaster,
    isError,
  } = useSendSurveyorAssesmentDetails();

  const methods = useForm({
    resolver: yupResolver(surveyorAssessmentSchema),
    defaultValues: {
      // initial values from surveyor appointment details
      assessed_idv: claimDetails?.idv || "",
      assessed_vehicle_premium: claimDetails?.vehicle_premium || "",
      assessed_voluntary_deductible: claimDetails?.voluntary_deductible || "",
      assessed_loss_type: claimDetails?.loss_type || "",
      assessed_labour_cost: claimDetails?.labour_cost || "",
      assessed_parts_cost: claimDetails?.parts_cost || "",
      assessed_metal_cost: claimDetails?.metal_cost || "",
      assessed_paint_cost: claimDetails?.paint_cost || "",
      assessed_total_other_cost: claimDetails?.total_other_cost || "",
      assessed_total_estimation: claimDetails?.total_estimation || "",
    },
  });

  useEffect(() => {
    methods.reset({
      // initial values from surveyor appointment details
      assessed_idv: claimDetails?.idv || "",
      assessed_vehicle_premium: claimDetails?.vehicle_premium || "",
      assessed_voluntary_deductible: claimDetails?.voluntary_deductible || "",
      assessed_loss_type: claimDetails?.loss_type || "",
      assessed_labour_cost: claimDetails?.labour_cost || "",
      assessed_parts_cost: claimDetails?.parts_cost || "",
      assessed_metal_cost: claimDetails?.metal_cost || "",
      assessed_paint_cost: claimDetails?.paint_cost || "",
      assessed_total_other_cost: claimDetails?.total_other_cost || "",
      assessed_total_estimation: claimDetails?.total_estimation || "",
      assessed_document: claimDetails?.assessed_document,
    });
  }, [claimDetails]);

  const labourCost = methods.watch("assessed_labour_cost");
  const partsCost = methods.watch("assessed_parts_cost");
  const metalCost = methods.watch("assessed_metal_cost");
  const paintCost = methods.watch("assessed_paint_cost");
  const totalOtherCost = methods.watch("assessed_total_other_cost");

  useEffect(() => {
    methods.setValue(
      "assessed_total_estimation",
      parseInt(labourCost || 0) +
        parseInt(partsCost || 0) +
        parseInt(metalCost || 0) +
        parseInt(paintCost || 0) +
        parseInt(totalOtherCost || 0) || "0"
    );
  }, [labourCost, partsCost, metalCost, paintCost, totalOtherCost]);

  const onSubmit = (data) => {
    data.claim_detail_id = claim_detail_id;
    sendSurveyorAssessmentDetails(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <MainPaperContainer>
          <AlertMessage
            alert={alertMaster}
            reset={resetMaster}
            isError={isError}
            isSuccess={isSuccess}
          />
          <ClaimHeading>Surveyor Assessment Details</ClaimHeading>
          <SurveyorAssesmentFormFields />
          <ButtonWrapper>
            <SubmitButton variant="contained" type="submit">
              Submit
            </SubmitButton>
          </ButtonWrapper>
        </MainPaperContainer>
      </form>
    </FormProvider>
  );
}

export default SurveyorAssesmentForm;
