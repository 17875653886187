import ShareIcon from "@mui/icons-material/Share";
import {
  Badge,
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tooltip,
  useMediaQuery,
  Zoom,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ShareQuotes from "../../../../pages/quotes/component/ShareQuotes/ShareQuotes";
import {
  useGetUser,
  useLogoutUser,
} from "../../../../services/AuthenticationServices";
import { useGetNotifications } from "../../../../services/dashboard.service";
import GlobalSearch from "../GlobalSearch";
import Notification from "../Notification/Notification";
import {
  IconBox,
  LogIconWrapper,
  LogoutUserIcon,
  NotificationAddIcon,
  PersonOutlineRoundedIcon,
  ProfileIconWrapper,
  ProfileView,
  ProfileViewBox,
  ProfileViewText,
  ShareQuoteButton,
  SwitchUserIcon,
} from "./styles/Authorized.styles";
const AuthorizedView = () => {
  const location = useLocation();
  const { pathname } = location;
  const [openShareQuote, setOpenShareQuote] = useState(false);
  const [open, setOpen] = useState(false);
  const { notifications } = useGetNotifications();
  const unreadNotifications = notifications?.filter(
    (item) => item.read_status === "N"
  );
  const { data } = useGetUser();
  const superAdmin = data?.role_name === "Super Admin";
  const anchorRef = useRef();
  const [openNotification, setOpenNotification] = useState(false);
  const isLessThan820 = useMediaQuery("(max-width : 820px)");
  const isQuotePath = pathname.split("/")?.includes("quotes");
  const handleShowNotification = (e) => {
    setOpenNotification(e.currentTarget);
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const { logout } = useLogoutUser();
  const navigate = useNavigate();
  return (
    <>
      <Box display={"flex"} position={"relative"} alignItems={"center"}>
        <GlobalSearch />
        {!isLessThan820 && isQuotePath && (
          <>
            <ShareQuoteButton
              // variant="contained"
              // color="primary"
              onClick={() => {
                setOpenShareQuote(true);
              }}
              startIcon={<ShareIcon />}
              sx={{
                textTransform: "capitalize",
              }}
              size="medium"
              id="share_quotes"
            >
              Share Quotes
            </ShareQuoteButton>
            <ShareQuotes open={openShareQuote} setOpen={setOpenShareQuote} />
          </>
        )}
        <Tooltip
          arrow
          placement="bottom"
          title={"Notification"}
          TransitionComponent={Zoom}
        >
          <IconButton
            disabled={unreadNotifications?.length === 0}
            onClick={handleShowNotification}
          >
            <Badge badgeContent={unreadNotifications?.length} color="error">
              <IconBox>
                <NotificationAddIcon fontSize="small" />
              </IconBox>
            </Badge>
          </IconButton>
        </Tooltip>
        <IconButton
          ref={anchorRef}
          onClick={() => {
            handleToggle();
          }}
        >
          {!open ? (
            <Tooltip
              arrow
              placement="bottom"
              title={"Account"}
              TransitionComponent={Zoom}
            >
              <IconBox>
                <PersonOutlineRoundedIcon id="Account" fontSize="small" />
              </IconBox>
            </Tooltip>
          ) : (
            <IconBox>
              <PersonOutlineRoundedIcon id="Accountlogo" fontSize="small" />
            </IconBox>
          )}

          <Stack direction="row" spacing={2}>
            <div>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-end"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-end"
                          ? "right top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                          sx={{
                            padding: "9px",
                            width: "200px",
                            cursor: "auto",
                          }}
                        >
                          <ProfileView>
                            <ProfileViewBox>
                              <ProfileIconWrapper>
                                <PersonOutlineRoundedIcon
                                  fontSize="large"
                                  sx={{ color: "var(--primary-color)" }}
                                />
                              </ProfileIconWrapper>
                              <ProfileViewText>
                                {data?.name?.length > 13 ? (
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={data?.name}
                                  >
                                    <Box
                                      sx={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {data?.name?.slice(0, 13) + "..."}
                                    </Box>
                                  </Tooltip>
                                ) : (
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {data?.name}
                                  </Box>
                                )}

                                {data?.solicitation_type?.length > 10 ? (
                                  <Tooltip
                                    arrow
                                    placement="bottom"
                                    title={data?.solicitation_type}
                                  >
                                    <Box
                                      sx={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {data?.solicitation_type?.slice(0, 10) +
                                        "..."}
                                    </Box>
                                  </Tooltip>
                                ) : (
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {data?.solicitation_type}
                                  </Box>
                                )}
                              </ProfileViewText>
                              <Tooltip
                                arrow
                                placement="bottom-start"
                                title={"Logout"}
                              >
                                <IconButton>
                                  <LogIconWrapper onClick={() => logout()}>
                                    <LogoutUserIcon id="logout" />
                                  </LogIconWrapper>
                                </IconButton>
                              </Tooltip>
                            </ProfileViewBox>
                            {superAdmin && (
                              <ProfileViewBox>
                                <ProfileIconWrapper>
                                  <PersonOutlineRoundedIcon
                                    fontSize="large"
                                    sx={{
                                      color: "var(--primary-color)",
                                    }}
                                  />
                                </ProfileIconWrapper>
                                <ProfileViewText my={"auto"}>
                                  Switch User
                                </ProfileViewText>
                                <Tooltip
                                  arrow
                                  placement="bottom-start"
                                  title={"Switch user"}
                                >
                                  <IconButton>
                                    <LogIconWrapper
                                      onClick={() => {
                                        navigate("/switch_user");
                                      }}
                                    >
                                      <SwitchUserIcon id="switchUser" />
                                    </LogIconWrapper>
                                  </IconButton>
                                </Tooltip>
                              </ProfileViewBox>
                            )}
                          </ProfileView>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Stack>
        </IconButton>
        <Notification
          open={openNotification}
          handleClose={handleCloseNotification}
          notification={notifications}
        />
      </Box>
    </>
  );
};

export default AuthorizedView;
