import * as yup from "yup";
import { documentUploadsImageAndPdfLessThan2MB } from "../../../utils/Validations/documentUploadsValidations";
export const claimDocumentValidation = yup.object().shape({
  registration_certificate: documentUploadsImageAndPdfLessThan2MB(),

  driving_license: documentUploadsImageAndPdfLessThan2MB(),
  estimated_certificate: documentUploadsImageAndPdfLessThan2MB(),
  fir_certificate: documentUploadsImageAndPdfLessThan2MB(),
  other_certificate: documentUploadsImageAndPdfLessThan2MB(),
});
