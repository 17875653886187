import { createSlice } from "@reduxjs/toolkit";
/*Active Vechicle Details are Make,Model,Fuel Type,Variant,Discount */
const input = createSlice({
  name: "input",
  initialState: {
    policyTypeDetails: "",
    activeVehicleDetails: 0,
    activeDetails: 0,
    vehicleDetails: {},
    registerationDetails: {},
    claimDetails: {},
    savedSegmentId: "",
    //states for comparison
    prevPolicyTypeDetails: "",
    prevVehicleDetails: {},
    prevRegisterationDetails: {},
    prevClaimDetails: {},
    previousPolicyNumber: "",
    policyTypeForComparison: "",
  },
  reducers: {
    setPolicyTypeDetails: (state, action) => {
      state.policyTypeDetails = action.payload;
    },
    setActiveVehicleDetails: (state, action) => {
      state.activeVehicleDetails = action.payload;
    },
    setActiveDetails: (state, action) => {
      state.activeDetails = action.payload;
    },
    setVehicleDetails: (state, action) => {
      state.vehicleDetails = {
        ...state.vehicleDetails,
        ...action.payload,
      };
    },
    setRegistrationDetails: (state, action) => {
      state.registerationDetails = { ...action.payload };
      state.activeDetails = action.payload.rollOver
        ? state.activeDetails + 1
        : state.activeDetails;
    },
    setClaimDetails: (state, action) => {
      state.claimDetails = action.payload;
    },
    setSavedSegmentId: (state, action) => {
      state.savedSegmentId = action.payload;
    },

    //reducers for comparison states

    setPrevPolicyTypeDetails: (state, action) => {
      state.prevPolicyTypeDetails = action.payload;
    },
    setPrevVehicleDetails: (state, action) => {
      state.prevVehicleDetails = {
        ...state.prevVehicleDetails,
        ...action.payload,
      };
    },
    setPrevRegistrationDetails: (state, action) => {
      state.prevRegisterationDetails = { ...action.payload };
      state.activeDetails = action.payload.rollOver
        ? state.activeDetails + 1
        : state.activeDetails;
    },
    setPrevClaimDetails: (state, action) => {
      state.prevClaimDetails = action.payload;
    },
    setPreviousPolicyNumber: (state, action) => {
      state.previousPolicyNumber = action.payload;
    },
    setPolicyTypeForComparison: (state, action) => {
      state.policyTypeForComparison = action.payload;
    },
    removeInputDetails: (state) => {
      state.activeDetails = 0;
      state.activeVehicleDetails = 0;
      state.registerationDetails = {};
      state.vehicleDetails = {};
      state.claimDetails = {};
      state.policyTypeDetails = "";
    },
  },
});

export const {
  setPolicyTypeDetails,
  setActiveDetails,
  setActiveVehicleDetails,
  setClaimDetails,
  setRegistrationDetails,
  setVehicleDetails,
  removeInputDetails,
  setSavedSegmentId,
  setPrevPolicyTypeDetails,
  setPrevVehicleDetails,
  setPrevRegistrationDetails,
  setPrevClaimDetails,
  setPreviousPolicyNumber,
  setPolicyTypeForComparison,
} = input.actions;

export default input.reducer;
