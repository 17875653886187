import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import api from "../api/api";
import { errorHandler } from "../utils/errorHandler";
import { useGetUser, useLogoutUser } from "./AuthenticationServices";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { setShowPresetForm } from "../modules/dashboard.slice";
import { useParams } from "react-router";
export const useGetMenuService = () => {
  const { data, isLoading } = useQuery(["menu", "user"], () => {
    return api.post("/main_menu/list");
  });
  return { menuItems: data?.data?.return_data || [], isMenuLoading: isLoading };
};

export const useGetFilterService = (segment_slug) => {
  // const { segment_slug } = useParams();
  const { data } = useQuery(["filter", segment_slug], () => {
    return api.post("/setting/list", { segment_slug });
  });
  return {
    filter_list: data?.data?.filters_options,
    segment_logo: data?.data?.segment_logo,
    additional_filters: data?.data?.additional_filters,
  };
};

export const useGetImtList = (segment_slug) => {
  const { data } = useQuery(["imt", segment_slug], () => {
    return api.post("/imt_configurator/list", { segment_slug });
  });
  return {
    imt_list: data?.data?.return_data,
  };
};

export const useGetDashboardtracker = () => {
  const { data } = useQuery(["tracker"], () => {
    return api.post("/dashboard/trackers");
  });
  return {
    tracker_list: data?.data?.return_data,
  };
};

export const useReorderMenuMutation = () => {
  const { mutate } = useMutation((data) => {
    return api.post(`menu/sequence`, data);
  });

  return {
    reorderMenu: mutate,
  };
};

export const useGetDashboardEndpoints = () => {
  const { data, isLoading } = useQuery(
    ["dashboard", "endpoints", "user"],
    () => {
      return api.post("/dashboard");
    }
  );

  return {
    endpoints: data?.data?.endpoints,
    preset: data?.data?.preset,
    isLoading: isLoading,
  };
};

export const useGetStatistic = (period) => {
  const { data, isLoading } = useQuery(["statistics", period], () => {
    return api.post("/dashboard/statistics", {
      filter: period,
    });
  });

  return {
    statistic: data?.data?.return_data || [],
    isStatisticLoading: isLoading,
  };
};

export const useGetQuickAccessTabs = () => {
  const { data } = useQuery(["getQuickAccessTabs"], () => {
    return api.post("menu/get_shortcuts");
  });
  return {
    quickAccessTabs: data?.data?.return_data,
  };
};

export const useAddToQuickAccess = () => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(
    (data) => {
      return api.post(`/menu/shortcuts`, data);
    },
    {
      onSuccess: (res) => {
        queryClient.setQueryData(["getQuickAccessTabs"], res);
      },
    }
  );

  return {
    addToQuickAccess: mutate,
    msg: data?.data?.message || [],
    res: data,
  };
};

export const useSavePreset = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => api.post("dashboard/preset/add", data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["preset"]);
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            dispatch(setShowPresetForm(false));
          }, 1500);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    savePreset: mutate,
    isPresetsucess: isSuccess,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
  };
};

export const useResetPreset = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, isError, isSuccess, data, error, reset } = useMutation(
    (data) => api.post("dashboard/preset/reset", data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["preset"]);
        if (res.data.status && res.data.status !== 500) {
          const timer = setTimeout(() => {
            dispatch(setShowPresetForm(false));
          }, 1500);
          return () => {
            if (timer) clearTimeout(timer);
          };
        }
      },
    }
  );
  const errList = errorHandler(error, data);

  return {
    resetPreset: mutate,
    isResetSuccess: isSuccess,
    isResetError: isError,
    alertMasterForReset: errList,
    resetMasterForReset: reset,
  };
};

export const useGetPresetList = () => {
  const { segment_slug } = useParams();
  const { data, isLoading } = useQuery(["preset", segment_slug], () => {
    return api.post("/dashboard/preset/list");
  });
  return {
    presetList: data?.data?.return_data,
    isPresetLoading: isLoading,
  };
};

export const useGetNotifications = () => {
  const { logout } = useLogoutUser({ isForcedLogout: true });
  const { isAuthorized } = useGetUser();

  const { data, isLoading } = useQuery(
    ["notifications"],
    () => api.post("/notification/list"),
    {
      refetchInterval: 10000,
      enabled: !!localStorage.getItem("token") && !!isAuthorized,
      onError: (err) => {
        if (err?.response?.status === 401) {
          swal("", "You have been logged out of this account!!", "info").then(
            () => {
              logout();
            }
          );
        }
      },
    }
  );

  return {
    notifications: data?.data?.return_data?.notifications || [],
    ticker: data?.data?.return_data?.ticker || [],
    isLoading: isLoading,
  };
};

export const useReadNotification = () => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(
    (data) => {
      return api.post("/notification/update", {
        notification_id: data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications"]);
      },
    }
  );
  return {
    readNotification: mutate,
    data: data,
  };
};

export const useRaiseTicket = () => {
  const queryClient = useQueryClient();
  const { mutate, data, isSuccess, isError, error, reset } = useMutation(
    (data) => {
      return api.post("/ticket/add", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ticket"]);
      },
    }
  );
  const errList = errorHandler(error, data);
  return {
    raiseTicket: mutate,
    alertMaster: errList,
    isSuccess,
    isError,
    reset,
  };
};

export const useGetTicketList = () => {
  const { data } = useQuery(["ticket"], () => {
    return api.post("/ticket/list");
  });
  return {
    ticketList: data?.data?.return_data,
  };
};
