import { Box, IconButton, Paper, Typography, styled } from "@mui/material";

export const MainPaperContainer = styled(Paper)({
  borderRadius: "18px",
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
  height: "fit-content",
  padding: "1.2rem",
  marginBottom: "10px",
});

export const ClaimHeading = styled(Typography)({
  fontWeight: "600",
  padding: "0.4rem 0.6rem 0.2rem 0.8rem",
  backgroundImage: "linear-gradient(to right, #03cc83, #fff 30px)",
  borderRadius: "8px",
  opacity: "0.8",
  fontSize: "15px",
});

export const EditButtonContainer = styled(IconButton)({
  position: "fixed",
  top: "85px",
  right: "25px",
  zIndex: 2,
});

export const ClaimSummaryTitle = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "grey",
  fontWeight: 700,
  fontSize: "15px",
  marginBottom: "5px",
});
