import { Grid } from "@mui/material";
import React from "react";
import {
  allowOnlyAplhabetNumber,
  allowOnlyNumbers,
} from "../../../../../../../../InputUtils/onInput";
import TextInput from "../../../../../../../../components/InputComponents/TextInput";
import ClaimDocumentUploadFields from "./DocumentFormFields";

function DeliveryOrderFormFields() {
  return (
    <>
      <Grid container spacing={2} mt={0} px={2}>
        <Grid item lg={4} xxs={6}>
          <TextInput
            label="Delivery Order No."
            name="delivery_order_no"
            onInput={allowOnlyAplhabetNumber}
            required
          />
        </Grid>
        <Grid item lg={4} xxs={6}>
          <TextInput
            label="Approved Amount"
            name="approved_amount"
            onInput={allowOnlyNumbers}
            required
          />
        </Grid>
      </Grid>
      <ClaimDocumentUploadFields
        document_name={"delivery_order_document"}
        remark_name={"delivery_order_remark"}
      />
    </>
  );
}

export default DeliveryOrderFormFields;
